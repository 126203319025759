import { iBaseObject } from "@quasar_dynamics/basic-designsystem";

export interface iFungible {
    id: number,
    code?: string,
    description?: string,
    note?: string,
}

export class iFungible extends iBaseObject {
    static convertFromCollection(ob: any): Array<iFungible> {
        let iFungibleCollection: Array<iFungible> = [];
        ob.forEach((element: any) => {
            iFungibleCollection.push(this.convertFromObject(element));
        });
        return iFungibleCollection;
    }

    static convertFromObject(ob: any): iFungible {
        let iFungibleObj = new iFungible();
        iFungibleObj.id = ob.id;
        iFungibleObj.code = ob.code;
        iFungibleObj.description = ob.description;
        iFungibleObj.note = ob.note;
        return iFungibleObj;
    }

    static createEmptyObject(): iFungible {
        let iFungibleObj = new iFungible();
        iFungibleObj.code = '';
        iFungibleObj.description = '';
        iFungibleObj.note = '';
        return iFungibleObj;
    }
}

export interface iFungibleFilter {
    num_devoluciones: number;
    num_pagina: number;
    genericFilter?: string;
    code?: string,
    description?: string,
    note?: string,
}

export interface iFungibleCreate {
    code?: string,
    description?: string,
    note?: string,
}