import { Component, OnInit } from '@angular/core';
import { iOptionsSelector } from '@quasar_dynamics/basic-designsystem';
import { ChartConfiguration } from 'chart.js';
@Component({
  selector: 'consumoProductos',
  templateUrl: './ConsumoProductos.component.html',
  styleUrls: ['./ConsumoProductos.component.scss']
})
export class ConsumoProductosComponent implements OnInit {
  consumo: any = null;
  data: any = {
    familia: null,
    subFamilia: null,
    producto: null,
    fechaIni: null,
    fechaFin: null
  }
  opcionesConsumo: iOptionsSelector = {
    items: ["Consumo en KG", "Consumo en L"],
    clearable: true,
    search: false,
    placeholder: "Consumo",
    append: true,
  }
  opcionesFamilia: iOptionsSelector = {
    items: ["Familia test"],
    clearable: true,
    search: true,
    placeholder: "Familia",
    append: true,
  }
  opcionesSubfamilia: iOptionsSelector = {
    items: ["Subfamilia test"],
    clearable: true,
    search: true,
    placeholder: "Subfamilia",
    append: true,
  }
  opcionesProductos: iOptionsSelector = {
    items: ["Producto 1"],
    clearable: true,
    search: true,
    placeholder: "Productos",
    append: true,
  }
  public barChartLegend = false;
  public barChartPlugins = [];

  public barChartData: ChartConfiguration<'bar'>['data'] = {
    labels: [ 'Familia 1', 'Familia 2', 'Familia 3', 'Familia 4', 'Familia 5', 'Familia 6', 'Familia 7', 'Familia 8', 'Familia 9', 'Familia 10' ],
    datasets: [
      {
        data: [ 65, 59, 80, 81, 56, 55, 40, 14, 9, 18 ],
        barThickness: 40,
        backgroundColor: '#FF979F',
        hoverBackgroundColor: '#FF979F',
        borderWidth: 2,
        borderColor: '#FF5664',
        hoverBorderColor: '#FF5664',
        borderSkipped: false,
        borderRadius: 32
      },

    ]
  };

  public barChartOptions: ChartConfiguration<'bar'>['options'] = {
    responsive: true,

    scales: {
      x: {
        grid: {
          lineWidth: 0,
        }, ticks: {
          color: "#000000",

        },
      },
      y: {
        min: 0,
        grid: {
          color: '#dfe0f5',
          drawBorder: false,
        },
      }
    },
    plugins: {
      legend: {
        display: false,
      }
    }

  };
  constructor() { }

  ngOnInit() {
  }

}
