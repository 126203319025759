<div class="popupGeneralContainer">
  <div class="header">
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
    <p *ngIf="modalData.accion=='editar'">Editar Previsión de pago</p>
    <p *ngIf="modalData.accion=='crear'">Crear Previsión de pago</p>
    <p *ngIf="modalData.accion=='ver'">Ver Previsión de pago</p>
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
  </div>
  <div class="body">
    <CustomInput [readonly]="true" classList="fullWidth tall special" placeholder="Nº Pedido" label="Nº Pedido"
      [(ngModel)]="data.nPedido" (change)="checkIsCompleted()"></CustomInput>
    <div class="customContainer">
      <label for="Estado">Estado</label>
      <selector [options]="opcionesEstados" (change)="getFromSelector($event, 'estado')" [(ngModel)]="data.estado"></selector>
    </div>
    <div class="customContainer">
      <label for="Fecha factura">Fecha factura</label>
      <datepicker (dataFromPicker)="data.fechaFactura = $event"></datepicker>
    </div>
    <div class="customContainer">
      <label for="Fecha pago">Fecha pago</label>
      <datepicker (dataFromPicker)="data.fechaPago = $event"></datepicker>
    </div>
    <div class="customContainer">
      <label for="Cliente">Cliente</label>
      <selector [options]="opcionesCliente" (change)="getFromSelector($event, 'cliente')" [(ngModel)]="data.cliente"></selector>
    </div>
    <CustomInput [readonly]="modalData.accion=='ver'" classList="fullWidth tall special" placeholder="Base imponible"
    label="Base imponible" [(ngModel)]="data.baseImponible" (change)="getCounting(data.baseImponible)"></CustomInput>
    <CustomInput [readonly]="true" classList="fullWidth tall special" placeholder="IVA" label="IVA"
    [(ngModel)]="data.iva"></CustomInput>
    <CustomInput [readonly]="true" classList="fullWidth tall special" placeholder="Recargo de equivalencia" label="Recargo de equivalencia"
    [(ngModel)]="data.recargo"></CustomInput>
    <div class="customContainer">
      <label for="Añadir documento">Añadir documento</label>
      <button class="generalbutton" [multiple]="true" fileInput [accept]="['image/png', 'image/jpeg', 'application/pdf']"
      [(ngModel)]="document" (change)="addDocument(document)"><mat-icon>attach_file</mat-icon>AÑADIR DOCUMENTO </button>
    </div>
    <div class="center">
      <p>{{documentLoaded}}</p>
    </div>

  </div>
  <div class="footer" *ngIf="modalData.accion!='ver'">

    <btn classList="secundario" (click)="forceClosePopup()">
      <ng-container>
        Cancelar
      </ng-container>
    </btn>
    <btn classList="secundaryButton" (click)="closePopup()" [class.disabled]="!isCompleted">
      <ng-container *ngIf="modalData.accion=='crear'">
        Crear
      </ng-container>
      <ng-container *ngIf="modalData.accion=='editar'">
        Editar
      </ng-container>
    </btn>
  </div>
</div>