import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { iDataTableColumns, iDataTableOptions, iUnsubscribeDestroy, PopupService } from '@quasar_dynamics/basic-designsystem';
import { Subject, takeUntil } from 'rxjs';
import { ConfirmPopupComponent } from 'src/app/Popups/ConfirmPopup/ConfirmPopup.component';
import { NuevoFungiblePopupComponent } from 'src/app/Popups/NuevoFungible-Popup/NuevoFungible-Popup.component';
import { NuevoTipoIncidenciaPopupComponent } from 'src/app/Popups/NuevoTipoIncidencia-Popup/NuevoTipoIncidencia-Popup.component';
import { IncidentService } from 'src/app/Services/Api/Incident.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';
import { iIncident, iIncidentFilter } from 'src/app/Shared/Interfaces/iIncident';
import * as XLSX from 'xlsx';

@Component({
  selector: 'erpMantenimientosDetallesTipoIncidencia',
  templateUrl: './ERP-Mantenimientos-Detalles-TipoIncidencia.component.html',
  styleUrls: ['./ERP-Mantenimientos-Detalles-TipoIncidencia.component.scss']
})
export class ERPMantenimientosDetallesTipoIncidenciaComponent extends iUnsubscribeDestroy implements OnInit {

  isLoading: boolean = false;

  //Tabla
  displayedColumnsTabla: Array<iDataTableColumns> = [
    { label: 'Nombre', property: 'name' },
    { label: 'Descripción', property: 'description' },
  ];

  dataForTable = []
  forceUpdateWidth = new Subject()
  totalPaginacion: number = 0;

  optionsTabla: iDataTableOptions = {
    isSelectable: true,
    isHovered: true,
    canSee: true,
    canEdit: true,
    canDelete: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc',
    moreIconsHead: [
      {
        icon: 'download',
        callback(element) {

        },
      }
    ]
  }
  dataForTableFiltered: Array<any> = this.dataForTable;

  filter: iIncidentFilter = {
    num_devoluciones: 20,
    num_pagina: 1,
    description: "",
    genericFilter: "",
    name: "",
  }
  constructor(private router: Router, private staticUtilitiesSE: StaticUtilitiesService, private popupSE: PopupService, private incidentSE: IncidentService) {
    super()
  }

  ngOnInit() {
    this.getAllIncidents();
  }

  /**
   * GETTER && SETTER
   */

  search(event){
    if(!event){this.filter.genericFilter = ""; this.getAllIncidents(); return}
    this.filter.genericFilter = event;
    this.getAllIncidents();
  }

  /**
   * FUNCTIONALITIES
   */
  edit(event: any) {
    this.popupSE.openPopup(NuevoTipoIncidenciaPopupComponent, { data: event, accion: "editar" });
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe((data) => {
      if(!data){return}
      this.ngOnInit()
      this._unsubInd2.next("")
    })
  }
  create() {
    this.popupSE.openPopup(NuevoTipoIncidenciaPopupComponent, { data: null, accion: "crear" });
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe((data) => {
      if(!data){return}
      this.ngOnInit()
      this._unsubInd2.next("")
    })
  }
  see(event: any) {
    this.popupSE.openPopup(NuevoTipoIncidenciaPopupComponent, { data: event, accion: "ver" });

  }
  delete(event: any, type: String) {
    let ids: number[] = []
    switch (type) {
      case "single":
        ids = [event.id]
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'el tipo de incidencia' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe((data) => {
          if(!data){return}
          this.deleteIncident(ids)
          this._unsubInd2.next("")
        })
        break;
      case "selected":
        ids = event.map((e: any) => e.id)
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'los tipos de incidencias seleccionadas' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe((data) => {
          if(!data){return}
          this.deleteIncident(ids)
          this._unsubInd2.next("")
        })
        break;
      case "all":
        ids = this.dataForTable.map((e: any) => e.id)
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'todas los tipos de incidencias' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe((data) => {
          if(!data){return}
          this.deleteIncident(ids)
          this._unsubInd2.next("")
        })
        break;
    }
  }
  changePagination(event: any) {
    if (event === 'next') {
      this.filter.num_pagina++;
      this.getAllIncidents();
    } else {
      this.filter.num_pagina--;
      this.getAllIncidents();
    }
  }


    /**
* DESCARGA DE PARTES EN EXCEL
*/

exportexcel(): void {
  let table = document.createElement("table")
  table.append((document.querySelector("dataTable .tableHeader thead") as HTMLElement).cloneNode(true))
  table.querySelector("thead .iconsActions")?.remove();
  table.querySelector("thead .checkboxContainer")?.remove();
  let rows = Array.from(document.querySelectorAll("dataTable .tableBody tbody tr "));
  let filteredRows = rows.filter((row) => {
    return row.querySelector('.checkboxContainer mat-checkbox ')!.getAttribute('ng-reflect-checked') === "true"
  })
  if (filteredRows.length > 0) {
    filteredRows.forEach((row) => {
      let newRow = row.cloneNode(true) as HTMLElement;
      newRow.querySelector('td:last-of-type')?.remove();
      newRow.querySelector('td:first-of-type')?.remove();
      table.append(newRow)
    })
  } else {
    table.append((document.querySelector("dataTable .tableBody tbody") as HTMLElement).cloneNode(true))
    table.querySelectorAll("tbody tr td:last-of-type").forEach(element => {
      element.remove();
    })
    table.querySelectorAll("tbody tr td:first-of-type").forEach(element => {
      element.remove();
    })
  }
  /* pass here the table id */
  const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table);

  /* generate workbook and add the worksheet */
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  /* save to file */
  XLSX.writeFile(wb, 'tiposDeIncidencias.xlsx');

}

  /**
   * CALLS TO API
   */

  getAllIncidents() {
    this.isLoading = true;
    this.incidentSE.getAll(this.filter);
    this.incidentSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe((res) => {
      if (!res) { return }
      let { data, total } = res.data;
      this.totalPaginacion = total;
      this.dataForTable = data;
      this.dataForTableFiltered = this.dataForTable;
      setTimeout(() => {
        this.forceUpdateWidth.next(true)
      }, 1)
      this.isLoading = false;
      this._unsubInd.next('')
    })
  }
  deleteIncident(ids:number[]){
    this.incidentSE.delete(ids);
    this.incidentSE.getResultDelete().pipe(takeUntil(this._unsubInd3)).subscribe((res) => {
      if (!res) { return }
      StaticUtilitiesService.showFeedback("Se ha eliminado el tipo de incidencia correctamente")
      this.ngOnInit()
      this._unsubInd3.next('')
    })
    this.incidentSE.getResultDeleteError().pipe(takeUntil(this._unsubInd3)).subscribe((res) => {
      if (!res) { return }
      StaticUtilitiesService.showFeedback("No se ha podido eliminar el tipo de incidencia")
      this.ngOnInit()
      this._unsubInd3.next('')
    })
  }

}
