import { iBaseObject } from "@quasar_dynamics/basic-designsystem";

export interface iWayToPay {
    id: number,
    code?: string,
    description?: string,
    days?: number,
}

export class iWayToPay extends iBaseObject {
    static convertFromCollection(ob: any): Array<iWayToPay> {
        let iWayToPayCollection: Array<iWayToPay> = [];
        ob.forEach((element: any) => {
            iWayToPayCollection.push(this.convertFromObject(element));
        });
        return iWayToPayCollection;
    }

    static convertFromObject(ob: any): iWayToPay {
        let iWayToPayObj = new iWayToPay();
        iWayToPayObj.id = ob.id;
        iWayToPayObj.code = ob.code;
        iWayToPayObj.description = ob.description;
        iWayToPayObj.days = ob.days;
        return iWayToPayObj;
    }

    static createEmptyObject(): iWayToPay {
        let iWayToPayObj = new iWayToPay();
        iWayToPayObj.code = '';
        iWayToPayObj.description = '';
        iWayToPayObj.days = -1;
        return iWayToPayObj;
    }
}

export interface iWayToPayFilter {
    num_devoluciones: number;
    num_pagina: number;
    genericFilter?: string;
    code?: string,
    description?: string,
    days?: number,
}