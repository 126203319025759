import { iBaseObject, iDate } from "@quasar_dynamics/basic-designsystem";
import { iOrder } from "./iOrder";
import { iCompany } from "./iCompany";
import { iTypesIva } from "./iTypesIva";

export interface iBill {
    id?: number,
    creationDate?: iDate,
    paymentDate?: iDate,
    base?: number,
    state?: string,
        attacheds?: [],
    amount?: number,
    ordered?: iOrder,
    company?: iCompany,
    typeIva?: iTypesIva,
}

export class iBill extends iBaseObject {
    static convertFromCollection(ob: any): Array<iBill> {
        let iBillCollection: Array<iBill> = [];
        ob.forEach((element: any) => {
            iBillCollection.push(this.convertFromObject(element));
        });
        return iBillCollection;
    }

    static convertFromObject(ob: any): iBill {
        let iBillObj = new iBill();
        iBillObj.id = ob.id;
        iBillObj.creationDate = iDate.phpConvert(ob.creationDate);
        iBillObj.paymentDate = iDate.phpConvert(ob.paymentDate);
        iBillObj.base = ob.base;
        iBillObj.state = ob.state;
        iBillObj.attacheds = ob.attacheds;
        iBillObj.amount = ob.amount;
        iBillObj.ordered = ob.ordered ? iOrder.convertFromObject(ob.ordered) : new iOrder();
        iBillObj.company = ob.company ? iCompany.convertFromObject(ob.company) : iCompany.createEmptyObject();
        iBillObj.typeIva = ob.typeIva ? iTypesIva.convertFromObject(ob.typeIva) : iTypesIva.createEmptyObject();
        return iBillObj;
    }

    static createEmptyObject(): iBill {
        let iBillObj = new iBill();
        iBillObj.creationDate = iDate.javascriptConvert(new Date());
        iBillObj.paymentDate = iDate.javascriptConvert(new Date());
        iBillObj.base = -1;
        iBillObj.state = '';
        iBillObj.attacheds = [];
        iBillObj.amount = -1;
        iBillObj.ordered = iOrder.createEmptyObject();
        iBillObj.company = iCompany.createEmptyObject();
        iBillObj.typeIva = iTypesIva.createEmptyObject();
        return iBillObj;
    }
}

export interface iBillFilter {
    num_devoluciones: number;
    num_pagina: number;
    genericFilter?: string;
    creationDate?: string,
    paymentDate?: string,
    base?: number,
    state?: string,
    attacheds?: number,
    amount?: number,
    ordered?: number,
    company?: number,
    typeIva?: number,
}