<div class="fullAccordeon">
  <filterActions (addNew)="create()" (searchSend)="search($event)" [disableFilter]="true">
    <ng-container filterContent>
      <div class="containerSelectores">
      </div>
    </ng-container>
  </filterActions>
  <pill class="tablePil" classList="tablePill">
    <dataTable [data]="dataForTable" [isLoading]="isLoading" [options]="optionsTabla"
      [displayedColumns]="displayedColumnsTabla" (edit)="edit($event)" (delete)="delete($event,'simple')"
      (deleteAll)="delete(null,'all')" (deleteSelected)="delete($event,'selected')" [forceUpdateWidth]="forceUpdateWidth"></dataTable>
  </pill>
</div>