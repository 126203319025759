import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { iDataTableColumns, iDataTableOptions, iUnsubscribeDestroy, PopupService, slideNavigationTypes } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { ConfirmPopupComponent } from 'src/app/Popups/ConfirmPopup/ConfirmPopup.component';
import { NuevoClientePopupComponent } from 'src/app/Popups/NuevoCliente-Popup/NuevoCliente-Popup.component';
import { CompanyService } from 'src/app/Services/Api/Company.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';
import { iCompanyFilter } from 'src/app/Shared/Interfaces/iCompany';
import * as XLSX from 'xlsx';


@Component({
  selector: 'ERP-Clientes-CLientesLista',
  templateUrl: './ERP-Clientes-CLientesLista.component.html',
  styleUrls: ['./ERP-Clientes-CLientesLista.component.scss']
})
export class ERPClientesCLientesListaComponent extends iUnsubscribeDestroy implements OnInit {

  isLoading: boolean = false;

  @Input() refresh:boolean = false
  @Input() search:any = null

  Tabla
  displayedColumnsTabla: Array<iDataTableColumns> = [
    { label: 'Código', property: 'code' },
    { label: 'Nombre', property: 'name', align: 'center' },
    { label: 'NIF', property: 'nif' },
  ];

  dataForTable:any = []
  totalPaginacion: number = 10;

  filter:iCompanyFilter = {
    num_devoluciones:20,
    num_pagina:1,
    isClient:true,

  }

  optionsTabla: iDataTableOptions = {
    isSelectable: true,
    canEdit: true,
    canSee: true,
    moreIconsHead: [{ callback: (event) => { this.exportexcel() }, icon: "download" }],
    canDelete: true,
    isHovered: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc'
  }


  constructor(private router: Router, private staticUtilitiesSE: StaticUtilitiesService, private popupSE: PopupService, private companySE:CompanyService) {
    super();
  }

  ngOnInit() {
    this.getAllCLients()
  }

  ngOnChanges(changes:SimpleChanges){
    if(changes['refresh'] ){
      this.getAllCLients()
    }
    if(changes['search'] ){
      if (changes['search'].currentValue) {
        this.searcher(changes['search'].currentValue)
      }else{
        this.searcher(null)
      }
    }
  }

  /**
   * GETTERS && SETTERS
   */

  searcher(event){
    if(!event){delete this.filter.genericFilter; this.getAllCLients(); return}
    this.filter.genericFilter = event
    this.getAllCLients()
  }

  /**
 * Edit
 */

  edit(event) {
    this.popupSE.openPopup(NuevoClientePopupComponent, { data: event, accion: 'editar' })
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
      if (!res) { return };
      this._unsubInd2.next('');
    })
  }

  /**
   * Delete
   */

  delete(event, key) {
    let ids:any[] = []
    switch (key) {
      case 'simple':
        ids = [event.id]
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'el cliente' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
          if (!res) { return };
          this.deleteClient(ids)
          this._unsubInd2.next('');
        })
        break;
      case 'all':
        ids = this.dataForTable.map((item) => { return item.id })
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'todos los clientes' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
          if (!res) { return };
          this.deleteClient(ids)
          this._unsubInd2.next('');
        })

        break;
      case 'selected':
        ids = event.map((item) => { return item.id})
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'los clientes seleccionados' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
          if (!res) { return };
          this.deleteClient(ids)
          this._unsubInd2.next('');
        })
        break;
      default:
        break;
    }
  }

    /**
* DESCARGA DE PARTES EN EXCEL
*/

exportexcel(): void {
  let table = document.createElement("table")
  table.append((document.querySelector("dataTable .tableHeader thead") as HTMLElement).cloneNode(true))
  table.querySelector("thead .iconsActions")?.remove();
  table.querySelector("thead .checkboxContainer")?.remove();
  let rows = Array.from(document.querySelectorAll("dataTable .tableBody tbody tr "));
  let filteredRows = rows.filter((row) => {
    return row.querySelector('.checkboxContainer mat-checkbox ')!.getAttribute('ng-reflect-checked') === "true"
  })
  if (filteredRows.length > 0) {
    filteredRows.forEach((row) => {
      let newRow = row.cloneNode(true) as HTMLElement;
      newRow.querySelector('td:last-of-type')?.remove();
      newRow.querySelector('td:first-of-type')?.remove();
      table.append(newRow)
    })
  } else {
    table.append((document.querySelector("dataTable .tableBody tbody") as HTMLElement).cloneNode(true))
    table.querySelectorAll("tbody tr td:last-of-type").forEach(element => {
      element.remove();
    })
    table.querySelectorAll("tbody tr td:first-of-type").forEach(element => {
      element.remove();
    })
  }
  /* pass here the table id */
  const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table);

  /* generate workbook and add the worksheet */
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  /* save to file */
  XLSX.writeFile(wb, 'clientes.xlsx');

}


  /**
   * See cliente
   */

  see(event){
    this.staticUtilitiesSE.goTo('/erp/clientes/detalles-cliente', slideNavigationTypes.slideToTop, {cliente: event.id})
  }

  /**
   * PAGINATION
   */

  changePagination(event: any) {
    if (event === 'next') {
      this.filter.num_pagina++;
      this.getAllCLients();
    } else {
      this.filter.num_pagina--;
      this.getAllCLients();
    }
  }

  /**
   * API CALLS
   */

  getAllCLients(){
    this.isLoading = true;
    this.companySE.getClientOrProveedor(this.filter);
    this.companySE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(res=>{
      if(!res){return};
      let {data, total } = res.data;
      this.totalPaginacion = total;
      this.dataForTable = data;
      this.isLoading = false;
      this._unsubInd.next('');
    })
  }

  deleteClient(ids:number[]){
    this.companySE.delete(ids);
    this.companySE.getResultDelete().pipe(takeUntil(this._unsubInd3)).subscribe(res=>{
      if(!res){return};
      StaticUtilitiesService.showFeedback('Cliente eliminado correctamente')
      this.getAllCLients()
      this._unsubInd3.next('');
    })
    this.companySE.getResultDeleteError().pipe(takeUntil(this._unsub)).subscribe(res=>{
      if(!res){return};
      StaticUtilitiesService.showError('El cliente no pudo ser eliminado')
      this.getAllCLients()
      this._unsub.next('');
    })
  }
}
