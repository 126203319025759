<FullContainer>
  <div class="fullView">
    <div class="headerAndArchivar">
      <div class="header">
        <button class="goBack" (click)="goBack()">
          <ng-container>
            <mat-icon>
              arrow_back
            </mat-icon>
          </ng-container>
        </button>
        <p>PARTES DE PRODUCCION<span> / {{codeParte}}</span></p>
      </div>
      <div class="buttons">
        <span class="button" (click)="cierreDeFabricacion()">
          <ng-container>
            <mat-icon>
              cancel
            </mat-icon>
          </ng-container>
          <p>CIERRE DE FABRICACIÓN</p>
        </span>
        <span class="button" (click)="printScreen()">
          <ng-container>
            <mat-icon>
              print
            </mat-icon>
          </ng-container>
          <p>IMPRIMIR</p>
        </span>
      </div>
    </div>
    <div class="archivador">
      <accordion [open]="openAccordeon" [title]="'FICHA'">
        <ng-container>
          <ERP-PP-PP-DV-Ficha [parteId]="parteId" (codeProductionReport)="codeProductionReport($event)" class="fullWidth"></ERP-PP-PP-DV-Ficha>
        </ng-container>
      </accordion>
    </div>

    <div class="accordionInformacionComercial">
      <accordion [open]="openAccordeon" class="" [title]="'FÓRMULA'">
        <ERP-PP-PP-DV-Formula [parteId]="parteId" class="fullWidth"></ERP-PP-PP-DV-Formula>
      </accordion>
    </div>

    <div class="accordionInformacionComercial">
      <accordion [open]="openAccordeon" class="" [title]="'PLANIFICACIÓN ENVASADO'">
        <ERP-PP-PP-DV-PlanificacionEnvasado [parteId]="parteId" class="fullWidth"></ERP-PP-PP-DV-PlanificacionEnvasado>
      </accordion>
    </div>

    <div class="accordionInformacionComercial">
      <accordion [open]="openAccordeon" class="" [title]="'ESPECIFICACIONES DE PRODUCTO'">
        <ERP-PP-PP-DV-EspecificacionesDeProducto [parteId]="parteId" class="fullWidth"></ERP-PP-PP-DV-EspecificacionesDeProducto>
      </accordion>
    </div>

    <div class="accordionInformacionComercial">
      <accordion [open]="openAccordeon" class="" [title]="'ESPECIFICACIONES DE LOTE'">
        <ERP-PP-PP-DV-EspecificacionesDeLote [parteId]="parteId" class="fullWidth"></ERP-PP-PP-DV-EspecificacionesDeLote>
      </accordion>
    </div>

  </div>
</FullContainer>