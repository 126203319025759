import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';
import { Router } from '@angular/router';
import { iUnsubscribeDestroy } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { StaticDataHandlerService } from 'src/app/Services/Utils/StaticDataHandler.service';


@Component({
  selector: 'ClientesMapa',
  templateUrl: './ClientesMapa.component.html',
  styleUrls: ['./ClientesMapa.component.scss']
})
export class ClientesMapaComponent extends iUnsubscribeDestroy implements OnInit {

  @ViewChild(MapInfoWindow) infoWindow!: MapInfoWindow;


  @Input("dataMap") dataMap: any[] = [];
  @Input("estados") estados: any[] = [
    'Clientes',
    'C.Potenciales',
    'C.Antiguos',
  ];

  // Flag

  flag: number = 0;

  // Data

  dataST: any[] = [];

  // Filtros

  filterPerson = "";
  filterStatus = "";
  filterMapActive = '0';

  // Array de técnicos

  tecnicosArray: any[] = [];


  // Data para el popup

  openedWindow: boolean = false;
  STToShow: any = { concepto: "Cargando", encargadoNombre: "Cargando", ubicacion: "Cargando" }

  markersNoFilter: any[] = [];

  center = { lat: 39.5, lng: -0.4 };
  zoom = 12;
  markers: any = [];
  optionsMap: google.maps.MapOptions = {
    center: { lat: 39.5, lng: -0.4 },
    zoom: 12,
    styles: StaticDataHandlerService.getMapStyle(),
    mapTypeControl: false,
    fullscreenControl: false,
    streetViewControl: false
  };
  styleMap = "calc(100vh - 78px - 10em - 106px)";

  constructor(private router: Router,) {
    super();
  }

  ngOnInit() {
    // this.getDataST();
    this.setPointers('d')
  }





  getStyleMapSection() {
    let ob: any = {};
    ob['min-height.px'] = "500";
    ob['height'] = this.styleMap;
    return ob;
  }

  changeFilterPerson(type: any) {
    if (this.filterPerson == type) { this.filterPerson = ""; }
    else { this.filterPerson = type }
    this.getDataST()
  }

  changeFilterStatus(type: any) {

    if (this.filterStatus == type) { this.filterStatus = ""; }
    else { this.filterStatus = type }
    this.getDataST()

  }



  abrirSeguimientoTecnico(id: any) {
    this.router.navigate([`admin/seguimiento/` + id])

  }

  /**
   * SET POINTERS
   */

  setPointers(data: any) {
    this.markers = [];
    let fakeData:any = [
      {lng:-0.37,lat:39.46, id:1, icon:'/assets/Icons/googleMapsIcons/iconoMapsNaranja.png', title:'Título de prueba'},
      {lng:-0.37,lat:38.46, id:2, icon:'/assets/Icons/googleMapsIcons/iconoMapsNaranja.png', title:'Título de prueba'},
      {lng:-0.37,lat:37.46, id:3, icon:'/assets/Icons/googleMapsIcons/iconoMapsNaranja.png', title:'Título de prueba'},
      {lng:-0.37,lat:36.46, id:4, icon:'/assets/Icons/googleMapsIcons/iconoMapsRojo.png', title:'Título de prueba'},
      {lng:-0.37,lat:35.46, id:5, icon:'/assets/Icons/googleMapsIcons/iconoMapsRojo.png', title:'Título de prueba'},
      {lng:-0.37,lat:34.46, id:6, icon:'/assets/Icons/googleMapsIcons/iconoMapsRojo.png', title:'Título de prueba'},
      {lng:-0.37,lat:33.46, id:7, icon:'/assets/Icons/googleMapsIcons/iconoMapsRojo.png', title:'Título de prueba'},
      {lng:-0.37,lat:32.46, id:8, icon:'/assets/Icons/googleMapsIcons/iconoMapsVerde.png', title:'Título de prueba'},
      {lng:-0.37,lat:31.46, id:9, icon:'/assets/Icons/googleMapsIcons/iconoMapsVerde.png', title:'Título de prueba'},
      {lng:-0.37,lat:30.46, id:10, icon:'/assets/Icons/googleMapsIcons/iconoMapsVerde.png', title:'Título de prueba'},
    ];

    fakeData.forEach((element: any) => {
      let marker = new google.maps.Marker(
        {
          position: new google.maps.LatLng(element.lat, element.lng),
          icon: element.icon,
          title: element.id,
        },
      );
      this.markers.push(marker);
      this.markersNoFilter.push(marker);
    })
  }

  /**
   * GET ARRAY TECNICOS
   */

  getArrayTecnicos(data: any) {
    this.tecnicosArray = data.map((ST: any) => {
      return ST.encargadoNombre;
    });
    this.tecnicosArray = [...new Set(this.tecnicosArray)];
  }

  openInfo(marker: any) {
    let ST = this.dataST.filter((ST: any) => {
      return ST.id == marker.marker.title
    });
    this.STToShow = ST[0]
    this.infoWindow.open(marker);
  }

  /**
   * API CALLS
   */

  getDataST(): void {
    // this.seguimientoTecnicoSE.getAllTabla(1, 50, "", this.filterPerson, "", this.filterStatus);
    // this.seguimientoTecnicoSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe((res: any) => {
    //   if (!res) { return }
    //   let { data } = res.data;
    //   console.log(data)
    //   this.dataST = data;
    //   console.log(this.dataST)
    //   if (this.flag == 0) {
    //     this.getArrayTecnicos(data);
    //   }
    //   this.setPointers(data);
    //   this.flag++;
    //   this._unsubInd.next("");
    // })
  }



}
