<div class="fullAccordeon">
  <filterActions (addNew)="create()" [disableFilter]="true" (searchSend)="search($event,'genericFilter')">
    <ng-container filterContent>
      <div class="containerSelectores">
        <div>
          <selector></selector>
        </div>
        <div>
          <selector></selector>
        </div>
        <div>
          <selector></selector>
        </div>
        <div>
        <selector></selector>
      </div>
    </div>
  </ng-container>
</filterActions>
<Pagination [paginacionTotal]="totalPaginacion" (change)="changePagination($event)"></Pagination>
<pill class="tablePil" classList="tablePill">
  <dataTable [data]="dataForTable" [isLoading]="isLoading" [options]="optionsTabla" [displayedColumns]="displayedColumnsTabla" (edit)="edit($event)" (delete)="delete($event,'simple')" (deleteAll)="delete(null,'all')" (deleteSelected)="delete($event,'selected')" (clickRow)="see($event)" (see)="see($event)"></dataTable>
</pill>
</div>
