import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  iDataTableColumns,
  iDataTableOptions,
  iUnsubscribeDestroy,
  slideNavigationTypes,
} from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { MaintenanceService } from 'src/app/Services/Api/Maintenance.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'app-ERP-Mantenimientos',
  templateUrl: './ERP-Mantenimientos.component.html',
  styleUrls: ['./ERP-Mantenimientos.component.scss'],
})
export class ERPMantenimientosComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  isLoading: boolean = false;
  filters = {
    num_devoluciones: 5000,
    num_pagina: 1,
    genericFilter: '',
  };

  //Tabla
  displayedColumnsTabla: Array<iDataTableColumns> = [
    { label: 'Mantenimientos', property: 'category' },
  ];

  dataForTable: any = [];

  optionsTabla: iDataTableOptions = {
    isHovered: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc',
  };
  dataForTableFiltered: Array<any> = this.dataForTable;

  filter: string = '';
  constructor(
    private router: Router,
    private staticUtilitiesSE: StaticUtilitiesService,
    private maintenanceSE: MaintenanceService
  ) {
    super();
  }

  ngOnInit() {
    this.getAllMaintenance();
  }

  /**
   * GETTERS && SETTERS
   */
  getFilter(event?) {
    if (!event) {
      this.filters.genericFilter = '';
      this.getAllMaintenance();
      return;
    }
    if (event) {
      this.filters.genericFilter = event.target.value;
      this.getAllMaintenance();
      return;
    }
  }

  getRow(event) {
    localStorage.setItem('mantenimiento', event.category);
    this.staticUtilitiesSE.goTo(
      '/erp/mantenimientos/detalles-mantenimiento',
      slideNavigationTypes.slideToTop,
      { mantenimiento: event.id }
    );
  }

  /**
   * CALLS TO API
   */

  getAllMaintenance() {
    this.isLoading = true;
    this.maintenanceSE.getAll(this.filters);
    this.maintenanceSE
      .getResult()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res: any) => {
        if (res == null) {
          return;
        }
        let { data, total } = res.data;
        this.dataForTable = data;
        this.dataForTableFiltered = this.dataForTable.filter(
          (e) => e.category !== 'Agentes'
        );
        this.isLoading = false;
        this._unsubInd.next('');
      });
  }
}
