import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { iUnsubscribeDestroy, PopupService } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { NuevoParteDeProduccionPopupComponent } from 'src/app/Popups/NuevoParteDeProduccion-Popup/NuevoParteDeProduccion-Popup.component';
import { ProductionReportService } from 'src/app/Services/Api/ProductionReport.service';

@Component({
  selector: 'ERP-PP-PP-DV-PlanificacionEnvasado',
  templateUrl: './ERP-PP-PP-DV-PlanificacionEnvasado.component.html',
  styleUrls: ['./ERP-PP-PP-DV-PlanificacionEnvasado.component.scss']
})
export class ERPPPPPDVPlanificacionEnvasadoComponent extends iUnsubscribeDestroy implements OnInit {

  @Input() parteId!: number;


  data:any = [  ]

  constructor(private popupSE:PopupService, private productionReportSE:ProductionReportService) {
    super();
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['parteId']) {
      if (changes['parteId'].currentValue) {
        this.getplanificacionEnvasado()
      }
    }
  }


    /**
   * edit
   */

    edit(section) {
      this.popupSE.openPopup(NuevoParteDeProduccionPopupComponent, { data: 'data', accion: 'editar', parteId: this.parteId },'fullSizePopup');
      this.popupSE.returnData().pipe(takeUntil(this._unsubInd)).subscribe(res => {
        if (!res) { return };
        this.getplanificacionEnvasado();
        this._unsubInd.next('');
      })
    }

    /**
     * API CALLS
     */

    getplanificacionEnvasado() {
      this.productionReportSE.getPackagingId(this.parteId);
      this.productionReportSE.getResultPackagingId().pipe(takeUntil(this._unsubInd)).subscribe((res: any) => {
        if (!res) { return }
        let { data } = res;
        this.data = data;
        this._unsubInd.next('')
      })
    }

}
