import { Component, OnInit } from '@angular/core';
import { iOptionsSelector } from '@quasar_dynamics/basic-designsystem';
import { ChartConfiguration } from 'chart.js';

@Component({
  selector: 'conversion',
  templateUrl: './Conversion.component.html',
  styleUrls: ['./Conversion.component.scss']
})
export class ConversionComponent implements OnInit {
  consumo: any = null;
  data: any = {
    fechaIni: null,
    fechaFin: null
  }
  opcionesConsumo: iOptionsSelector = {
    items: ["Facturación en KG/€", "Facturación en L/€"],
    clearable: true,
    search: false,
    placeholder: "Facturación",
    append: true,
  }
  public barChartLegend = false;
  public barChartPlugins = [];

  public barChartData: ChartConfiguration<'bar'>['data'] = {
    labels: [ 'Contacto', 'Cliente potencial', 'Cliente'],
    datasets: [
      {
        data: [ 65, 59, 80 ],
        barThickness: 80,
        backgroundColor: '#FF979F',
        hoverBackgroundColor: '#FF979F',
        borderWidth: 2,
        borderColor: '#FF5664',
        hoverBorderColor: '#FF5664',
        borderSkipped: false,
        borderRadius: 42
      },

    ]
  };

  public barChartOptions: ChartConfiguration<'bar'>['options'] = {
    responsive: true,

    scales: {
      x: {
        grid: {
          lineWidth: 0,
        }, ticks: {
          color: "#000000",

        },
      },
      y: {
        min: 0,
        grid: {
          color: '#dfe0f5',
          drawBorder: false,
        },
      }
    },
    plugins: {
      legend: {
        display: false,
      }
    }

  };

  constructor() { }

  ngOnInit() {
  }

}
