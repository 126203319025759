<FullContainer>
  <div class="alertasGeneralContainer">
    <div class="header">
      <p>ALERTAS</p>
    </div>
    <pill>
      <ng-container >
        <div class="alertasContainer">
          <div class="alertas">
            <div class="alerta" *ngFor="let alerta of alertas">
              <div>
                <div [ngStyle]="{'background': alerta.color}"></div>
                <span>{{alerta.nombre}}</span>
              </div>
              <mat-icon>visibility</mat-icon>
            </div>
          </div>
          <div class="filtros">
            <div class="customContainer">
              <label for="familia">FILTRAR POR</label>
              <selector [options]="opcionesFiltros" [(ngModel)]="filtro" ></selector>
            </div>
          </div>
        </div>
      </ng-container>
    </pill>
  </div>
</FullContainer>
