import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { PopupService, iUnsubscribeDestroy } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { NuevaFormulaPopupComponent } from 'src/app/Popups/NuevaFormula-Popup/NuevaFormula-Popup.component';
import { ProductionReportService } from 'src/app/Services/Api/ProductionReport.service';

@Component({
  selector: 'ERP-PP-PP-DV-Formula',
  templateUrl: './ERP-PP-PP-DV-Formula.component.html',
  styleUrls: ['./ERP-PP-PP-DV-Formula.component.scss']
})
export class ERPPPPPDVFormulaComponent extends iUnsubscribeDestroy implements OnInit {

  @Input() parteId!: number;


  total: number = 0;
  observacionesFinales: string = `Cargando...  `
  dataForTable: any = [  ]
  data: any = []

  constructor(private popupSE: PopupService, private productionReportSE: ProductionReportService) {
    super();
  }

  ngOnInit() {
    this.getTotal()
  }

  getTotal() {
    this.dataForTable.forEach(element => {
      this.total += element.cantidad
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['parteId']) {
      if (changes['parteId'].currentValue) {
        this.getFormulaProductionReport()
      }
    }
  }

  /**
   * EDIT
   */

  edit() {
    this.popupSE.openPopup(NuevaFormulaPopupComponent, { data: 'data', accion: 'editar' }, 'fullSizePopup')
  }

  /**
   * API CALLS
   */

  getFormulaProductionReport() {
    this.productionReportSE.getFormulaTableId(this.parteId);
    this.productionReportSE.getResultFormulaTableId().pipe(takeUntil(this._unsubInd)).subscribe((res: any) => {
      if (!res) { return }
      let { data } = res;
      this.observacionesFinales = data['formula'][0].finalObservation;
      this.total = data['formula'][0].ProductionReportTotal;
      this.dataForTable = data['batchLine'];
      this._unsubInd.next('')
    })
  }

}
