import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  iUnsubscribeDestroy,
  PopupService,
} from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';
import { ConfirmPopupComponent } from '../ConfirmPopup/ConfirmPopup.component';
import { CompanyService } from 'src/app/Services/Api/Company.service';
import { FormulaService } from 'src/app/Services/Api/Formula.service';
import { OrderService } from 'src/app/Services/Api/Order.service';

@Component({
  selector: 'app-Documentos-Popup',
  templateUrl: './Documentos-Popup.component.html',
  styleUrls: ['./Documentos-Popup.component.scss'],
})
export class DocumentosPopupComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  editar: boolean = false;
  documents: any[] = [];

  constructor(
    public dialogRef: MatDialogRef<DocumentosPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private popupSE: PopupService,
    private companySE: CompanyService,
    private formulaSE: FormulaService,
    private orderSE: OrderService
  ) {
    super();
  }

  ngOnInit() {
    this.editar = this.modalData.editar;
    this.setter();
  }

  /**
   * GETTERS && SETTERS
   */

  setter() {
    this.documents = this.modalData.documentos;
  }

  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }

  delete(event) {
    this.popupSE.openPopup(ConfirmPopupComponent, {
      accion: 'Eliminar',
      elemento: 'el documento',
    });
    this.popupSE
      .returnData()
      .pipe(takeUntil(this._unsubInd4))
      .subscribe((data) => {
        if (!data) {
          return;
        }
        if (this.modalData.proveedorId) {
          this.deleteDocument(this.modalData.proveedorId, event);
        }
        if (this.modalData.pedidoId) {
          this.deleteOrderPdf(event);
        }
        if (this.modalData.formulaId) {
          this.deletePdfFormula(event);
        }
        this._unsubInd4.next('');
      });
  }

  deleteDocument(idProveedor, indicePdf) {
    this.companySE.deletePdf(idProveedor, indicePdf);
    this.companySE
      .getResultDelete()
      .pipe(takeUntil(this._unsubInd5))
      .subscribe((data) => {
        if (!data) {
          return;
        }
        StaticUtilitiesService.showFeedback(
          'Se ha eliminado el documento correctamente'
        );
        this.closePopup('refresh');
        this._unsubInd5.next('');
      });
    this.companySE
      .getResultDeleteError()
      .pipe(takeUntil(this._unsub))
      .subscribe((data) => {
        if (!data) {
          return;
        }
        StaticUtilitiesService.showError(
          'No se ha podido eliminar el documento'
        );
        this._unsub.next('');
      });
  }

  deletePdfFormula(indicePdf) {
    this.formulaSE.deletePdf(this.modalData.formulaId, indicePdf);
    this.formulaSE
      .getResultDelete()
      .pipe(takeUntil(this._unsubInd9))
      .subscribe((data) => {
        if (!data) {
          return;
        }
        StaticUtilitiesService.showFeedback(
          'Se ha eliminado el documento correctamente'
        );
        this.closePopup('refresh');
        this._unsubInd9.next('');
      });
    this.formulaSE
      .getResultDeleteError()
      .pipe(takeUntil(this._unsub))
      .subscribe((data) => {
        if (!data) {
          return;
        }
        StaticUtilitiesService.showError(
          'No se ha podido eliminar el documento'
        );
        this._unsub.next('');
      });
  }
  deleteOrderPdf(indicePdf) {
    this.orderSE.deleteOrderPdf(this.modalData.pedidoId, indicePdf);
    this.orderSE
      .getResultDelete()
      .pipe(takeUntil(this._unsubInd9))
      .subscribe((data) => {
        if (!data) {
          return;
        }
        StaticUtilitiesService.showFeedback(
          'Se ha eliminado el documento correctamente'
        );
        this.closePopup('refresh');
        this._unsubInd9.next('');
      });
    this.orderSE
      .getResultDeleteError()
      .pipe(takeUntil(this._unsub))
      .subscribe((data) => {
        if (!data) {
          return;
        }
        StaticUtilitiesService.showError(
          'No se ha podido eliminar el documento'
        );
        this._unsub.next('');
      });
  }
}
