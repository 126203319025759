import { Component, OnInit } from '@angular/core';
import {
  iOptionsSelector,
  iUnsubscribeDestroy,
  PopupService,
} from '@quasar_dynamics/basic-designsystem';
import { Subject, takeUntil } from 'rxjs';
import { NuevaPrevisionDeCobrosPopupComponent } from 'src/app/Popups/NuevaPrevisionDeCobros-Popup/NuevaPrevisionDeCobros-Popup.component';
import { NuevaPrevisionDePagosPopupComponent } from 'src/app/Popups/NuevaPrevisionDePagos-Popup/NuevaPrevisionDePagos-Popup.component';
import { AccountingService } from 'src/app/Services/Api/Accounting.service';

@Component({
  selector: 'ERP-Contabilidad',
  templateUrl: './ERP-Contabilidad.component.html',
  styleUrls: ['./ERP-Contabilidad.component.scss'],
})
export class ERPContabilidadComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  _reloadTableWidth = new Subject();

  selected = 0;
  headerData: any = {
    toCollect: 'Cargando...',
    toPay: 'Cargando...',
    balance: 'Cargando...',
  };
  opcionFecha: any = null;
  filters: any = {
    fecha: null,
    fechaIni: null,
    fechaFin: null,
  };
  filtersProveedores: any = {};
  filtersClients: any = {};
  opcionesFecha: iOptionsSelector = {
    items: [
      { id: 1, label: 'Pedido' },
      { id: 2, label: 'Pago' },
    ],
    clearable: true,
    search: true,
    placeholder: 'Fecha',
    append: true,
  };
  opcionesFechaCliente: iOptionsSelector = {
    items: [
      { id: 1, label: 'Factura' },
      { id: 2, label: 'Pago' },
    ],
    clearable: true,
    search: true,
    placeholder: 'Fecha',
    append: true,
  };
  selectores = [
    {
      label: 'Proveedores',
      icon: 'none',
      image: 'none',
      selected: 0,
      before: 'yes',
      color: 'verde',
    },
    // { label: 'Clientes', icon: 'none', image: 'none', selected: 1, before: 'yes', color: 'verde' },
  ];

  constructor(
    private popupSE: PopupService,
    private accountingSE: AccountingService
  ) {
    super();
  }

  ngOnInit() {
    this.getHeaderAccounting();
  }

  selectedOption(event) {
    this.selected = event;
  }

  search(event) {
    if (this.selected === 0) {
      if (!event) {
        delete this.filtersProveedores['genericFilter'];
        this.filtersProveedores = { ...this.filtersProveedores };
        return;
      }
      this.filtersProveedores['genericFilter'] = event;
      this.filtersProveedores = { ...this.filtersProveedores };
    }
    if (this.selected === 1) {
      if (!event) {
        delete this.filtersClients['genericFilter'];
        this.filtersClients = { ...this.filtersClients };
        return;
      }
      this.filtersClients['genericFilter'] = event;
      this.filtersClients = { ...this.filtersClients };
    }
  }

  create() {
    if (this.selected == 0) {
      this.popupSE.openPopup(NuevaPrevisionDePagosPopupComponent, {
        accion: 'crear',
        data: null,
      });
      return;
    }
    if (this.selected == 1) {
      this.popupSE.openPopup(NuevaPrevisionDeCobrosPopupComponent, {
        accion: 'crear',
        data: null,
      });
      return;
    }
  }

  getDataFromPicker(event, key, filterType) {
    if (filterType === 'filtersProveedores') {
      this.filtersProveedores[key] = event;
      this.filtersProveedores = { ...this.filtersProveedores };
    }
    if (filterType === 'filtersClients') {
      this.filtersClients[key] = event;
      this.filtersClients[key] = { ...this.filtersClients };
    }
  }

  /**
   * API CALLS
   */

  getHeaderAccounting() {
    this.accountingSE.getGlobalAccounting();
    this.accountingSE
      .getResultGlobalAccounting()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        let { data } = res;
        this.headerData = data;
        this._unsubInd.next('');
      });
  }
}
