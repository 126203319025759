<div class="popupGeneralContainer">
  <div class="header">
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
    <p>APADIR PEDIDO</p>
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
  </div>
  <div class="body">
    <div class="customContainer">
      <label for="Pais">Pedido</label>
      <selector [options]="opcionesPedido" [(ngModel)]="pedido" (change)="getPedido($event)"></selector>
    </div>
  </div>
  <div class="footer">
    <btn classList="secundario" (click)="forceClosePopup()">
      <ng-container>
        Cancelar
      </ng-container>
    </btn>
    <btn classList="secundaryButton" (click)="closePopup()" [ngClass]="{disabled: !isCompleted}">
      <ng-container>
        Finalizar
      </ng-container>
    </btn>
  </div>
</div>