import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { AnimationControllerService, LoaderService, NotificationsService, slideNavigationTypes } from '@quasar_dynamics/basic-designsystem';
import { filter, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StaticUtilitiesService {

  constructor(private router: Router, private loader: LoaderService, private activatedRoute: ActivatedRoute, private animationControllerSE: AnimationControllerService) { }

  static showFeedback(message: string) {
    const notificaciones = new NotificationsService();
    notificaciones.showFeedBack(message);
  }

  static showWarning(message: string) {
    const notificaciones = new NotificationsService();
    notificaciones.showWarning(message);
  }

  static showError(message: string) {
    const notificaciones = new NotificationsService();
    notificaciones.showError(message);
  }

  static isCompleteObject(obj: any) {
    return Object.values(obj).filter((element: any) => { return element === null || element === undefined || element === "" || element.length === 0 }).length == 0;
  }
  static isEmptyArray(array: any) {
    return array.filter(element=>{
     return StaticUtilitiesService.isCompleteObject(element)
    }).length == 0
  }
  static isNullObject(obj: any) {
    return obj == null || obj == undefined;
  }

  openLoader() {
    this.loader.open();
  }

  closeLoader() {
    this.loader.closeSlow();
  }

  goTo(url: string, animation?: slideNavigationTypes, params: any = null) {
    params == null ? this.router.navigate([url]) : this.router.navigate([url], { queryParams: params });
    if (animation != undefined) {
      this.animationControllerSE.setCurrentAnimation(animation);
    }

  }

  getParams() {
    let sendParams: any = null
    this.activatedRoute.queryParamMap.subscribe(params => {
      sendParams = params['params'];
    })
    return sendParams;
  }

  getActualRoute() {
    return this.router.url;
  }

}
