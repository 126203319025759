import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { iDataTableColumns, iDataTableOptions, iDate, iUnsubscribeDestroy, PopupService, slideNavigationTypes } from '@quasar_dynamics/basic-designsystem';
import { ConfirmPopupComponent } from 'src/app/Popups/ConfirmPopup/ConfirmPopup.component';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';
import { takeUntil } from 'rxjs/operators';
import { NuevaPrevisionDePagosPopupComponent } from 'src/app/Popups/NuevaPrevisionDePagos-Popup/NuevaPrevisionDePagos-Popup.component';
import { BillService } from 'src/app/Services/Api/Bill.service';
import { iBillFilter } from 'src/app/Shared/Interfaces/iBill';
import * as XLSX from 'xlsx';
import { AccountingService } from 'src/app/Services/Api/Accounting.service';
import { CambiarEstadoPedidoProveedorPopupComponent } from 'src/app/Popups/CambiarEstadoPedidoProveedor-Popup/CambiarEstadoPedidoProveedor-Popup.component';

@Component({
  selector: 'ERP-Contabilidad-Proveedores',
  templateUrl: './ERP-Contabilidad-Proveedores.component.html',
  styleUrls: ['./ERP-Contabilidad-Proveedores.component.scss']
})
export class ERPContabilidadProveedoresComponent extends iUnsubscribeDestroy implements OnInit {

  @Input() filtersProveedores: any = {}

  isLoading: boolean = false;
  total: any = {
    baseImponible: 0,
    iva: 0,
    irpf: 0,
  }
  filter: any = {
    num_devoluciones: 20,
    num_pagina: 1,
    cliente: false,
  }

  //Tabla
  displayedColumnsTabla: Array<iDataTableColumns> = [
    { label: 'Nº Pedido', property: 'orderNumber' },
    { label: 'Fecha pedido / Fecha pago', property: 'fechaFacturaPago', format: 'lineBreak' },
    { label: 'Proveedor', property: 'supplier', },
    { label: 'Base Imponible', property: 'base', align: 'center' },
    { label: 'IVA', property: 'iva', align: 'center' },
    { label: 'IRPF', property: 'irpf', align: 'center' },
    { label: 'Estado', property: 'state', align: 'center', classList: 'tableEstado tableEstadoPedidos' },
  ];

  dataForTable:any = []
  totalPaginacion: number = 0;
  optionsTabla: iDataTableOptions = {
    isSelectable: true,
    canEdit: true,
    moreIconsHead: [{ callback: (event) => { this.exportexcel() }, icon: "download" }],
    canDelete: false,
    isHovered: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc'
  }


  constructor(private router: Router, private staticUtilitiesSE: StaticUtilitiesService, private popupSE: PopupService, private billSE: BillService, private accountingSE:AccountingService) {
    super();
  }

  ngOnInit() {
    this.getAllContabilidadProveedor()
  }

  ngOnChanges(changes:SimpleChanges){
    if(changes['filtersProveedores']){
      if(changes['filtersProveedores']?.currentValue){
        this.filter = {...this.filter,...changes['filtersProveedores']?.currentValue}
        this.getAllContabilidadProveedor()
      }
      if(!changes['filtersProveedores'].currentValue?.genericFilter){
        delete this.filter['genericFilter']
        delete this.filter['odate_ini']
        delete this.filter['odate_fin']
        delete this.filter['paydate_ini']
        delete this.filter['paydate_fin']
        this.getAllContabilidadProveedor()
      }
    }
  }

  getCuentas() {
    this.dataForTable.forEach(element => {
      this.total.baseImponible += Number(element.base);
      this.total.iva += element.iva;
      this.total.irpf += element.irpf;
    })
  }

    /**
  * PAGINATION
  */

    changePagination(event: any) {
      if (event === 'next') {
        this.filter.num_pagina++;
        this.getAllContabilidadProveedor();
      } else {
        this.filter.num_pagina--;
        this.getAllContabilidadProveedor();
      }
    }
  

  /**
   * EDIT
   */

  edit(event) {
    this.popupSE.openPopup(CambiarEstadoPedidoProveedorPopupComponent, { accion: 'editar', data: event });
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
      if (!res) { return };
      this.getAllContabilidadProveedor()
      this._unsubInd2.next('');
    })
  }

    /**
 * DESCARGA DE PARTES EN EXCEL
 */

    exportexcel(): void {
      let table = document.createElement("table")
      table.append((document.querySelector("dataTable .tableHeader thead") as HTMLElement).cloneNode(true))
      table.querySelector("thead .iconsActions")?.remove();
      table.querySelector("thead .checkboxContainer")?.remove();
      let rows = Array.from(document.querySelectorAll("dataTable .tableBody tbody tr "));
      let filteredRows = rows.filter((row) => {
        return row.querySelector('.checkboxContainer mat-checkbox ')!.getAttribute('ng-reflect-checked') === "true"
      })
      if (filteredRows.length > 0) {
        filteredRows.forEach((row) => {
          let newRow = row.cloneNode(true) as HTMLElement;
          newRow.querySelector('td:last-of-type')?.remove();
          newRow.querySelector('td:first-of-type')?.remove();
          table.append(newRow)
        })
      } else {
        table.append((document.querySelector("dataTable .tableBody tbody") as HTMLElement).cloneNode(true))
        table.querySelectorAll("tbody tr td:last-of-type").forEach(element => {
          element.remove();
        })
        table.querySelectorAll("tbody tr td:first-of-type").forEach(element => {
          element.remove();
        })
      }
      /* pass here the table id */
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table);
  
      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
      /* save to file */
      XLSX.writeFile(wb, 'ContabilidadProveedores.xlsx');
  
    }
  


  /**
   * Delete
   */

  delete(event, key) {
    switch (key) {
      case 'simple':
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'la prevision de pagos' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
          if (!res) { return };
          this._unsubInd2.next('');
        })
        break;
      case 'all':
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'todas las previsiones de pagos' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
          if (!res) { return };
          this._unsubInd2.next('');
        })

        break;
      case 'selected':
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'las previsiones de pagos seleccionadas' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
          if (!res) { return };
          this._unsubInd2.next('');
        })
        break;
      default:
        break;
    }
  }

  /**
   * See cliente
   */

  see(event) {
    this.staticUtilitiesSE.goTo('/erp/proveedores/detalles-pedido', slideNavigationTypes.slideToTop, {pedido: event.orderNumber})

  }

  /**
   * API CALLS
   */

  getAllContabilidadProveedor() {
    this.isLoading = true;
    this.accountingSE.getAllAccountingSupplier(this.filter);
    this.accountingSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(res => {
      if (!res) { return };
      let { data, total } = res.data;
      this.totalPaginacion = total;
      setTimeout(() => {
        this.getCuentas()
      }, 1);
      this.dataForTable = data.map(element => {return{...element, fechaFacturaPago: `${iDate.javascriptConvert(new Date(element.orderDate)).toStringDate('EU')} \n  ${iDate.javascriptConvert(new Date(element?.payDate)).toStringDate('EU')}`}});
      this.isLoading = false;
      this._unsubInd.next('');
    })
  }

}
