<div class="fichaGeneralContainer">
  <pill>
    <ng-container>
      <div class="info">
        <span class="material-icons icon" (click)="edit($event, 'datos')">
          edit
        </span>
        <div class="row">
          <div class="column">
            <label for="">Código:</label>
            <span>{{ data?.code }}</span>
          </div>
          <div class="column">
            <label for="">Nombre:</label>
            <span>{{ data?.name }}</span>
          </div>
        </div>
        <div class="row">
          <div class="column">
            <label for="">Densidad:</label>
            <span>{{ data?.density }}</span>
          </div>
          <div class="column">
            <label for="">Unidad de medida:</label>
            <span>{{ data?.measurementUnit?.unit }}</span>
          </div>
        </div>
        <div class="row">
          <div class="column">
            <label for="">Familia:</label>
            <span>{{ data?.family?.description }}</span>
          </div>
          <div class="column">
            <label for="">Subfamilia:</label>
            <span>{{ data?.subfamily?.description }}</span>
          </div>
        </div>
        <div class="row">
          <div class="column">
            <label for="">Código secundario:</label>
            <span>{{ data?.secondaryCode }}</span>
          </div>
          <div class="column">
            <label for="">Stock mínimo:</label>
            <span>{{ data?.minStock }}</span>
          </div>
        </div>
      </div>
    </ng-container>
  </pill>
  <pill>
    <ng-container>
      <div class="info">
        <span class="material-icons icon" (click)="edit($event, 'proveedores')">
          edit
        </span>
        <div class="row">
          <div class="column special">
            <label for="">Precio última compra:</label>
            <span>{{ data?.lastPrice }} </span>
          </div>
        </div>
        <div class="row">
          <div class="column special">
            <label for="">Proveedor 1: </label>
            <span>{{ data?.rawMaterialCompany || "-" }}</span>
          </div>
        </div>
        <div class="row">
          <div class="column special">
            <label for="">Proveedor 2: </label>
            <span>{{ data?.rawMaterialCompany || "-" }}</span>
          </div>
        </div>
        <div class="row">
          <div class="column special">
            <label for="">Proveedor 3: </label>
            <span>{{ data?.rawMaterialCompany || "-" }}</span>
          </div>
        </div>
        <div class="row">
          <div class="column special">
            <label for="">Proveedor 4: </label>
            <span>{{ data?.rawMaterialCompany || "-" }}</span>
          </div>
        </div>
      </div>
    </ng-container>
  </pill>
  <pill>
    <ng-container>
      <div class="info">
        <span
          class="material-icons icon"
          (click)="edit($event, 'observaciones')"
        >
          edit
        </span>
        <div class="row">
          <div class="column special">
            <label for="">Observaciones:</label>
            <span>{{ data?.description }}</span>
          </div>
        </div>
      </div>
    </ng-container>
  </pill>
  <pill>
    <ng-container>
      <div class="info">
        <div class="icons">
          <span class="material-icons icon" (click)="subirDocumento()">
            add
          </span>
          <span
            class="material-icons icon"
            (click)="verDocumentos(true)"
            [class.disabled]="documentos?.length == 0"
          >
            edit
          </span>
        </div>

        <div class="row">
          <div class="column special">
            <label for="">Adjuntos:</label>
            <div class="documentos">
              <div
                class="documento"
                *ngFor="let document of documentos?.slice(0,2); let index = index"
              >
                <label for="">Documento {{ index + 1 }}</label>
                <a href='{{ document }}' target="_blank">
                  <img src="/assets/Icons/pdf.svg" alt="" />
                  <span> Documento {{ index + 1 }} </span>
                </a>
              </div>
            </div>
            <p class="noData" *ngIf="documentos?.length == 0">No hay adjuntos</p>
          </div>
        </div>
      </div>
    </ng-container>
  </pill>
</div>
