<FullContainer>
  <div class="fullView">
    <div class="headerAndArchivar">
      <div class="header">
        <button class="goBack" (click)="goBack()">
          <ng-container>
            <mat-icon>
              arrow_back
            </mat-icon>
          </ng-container>
        </button>
        <p>CLIENTES POTENCIALES / <span>INDUSMETAL TORRES, S.L.</span></p>
      </div>
      <div class="buttonArchivar">
        <btn classList="transformarEnCAntiguo" (click)="transformar('archivar')">ARCHIVAR</btn>
        <btn classList="transformarEnCliente" (click)="transformar('transformar en cliente')">TRANSFORMAR EN CLIENTE</btn>
      </div>
    </div>
    <div class="archivador">
      <accordion [title]="'INFORMACION DEL CLIENTE'">
        <ng-container>
          <InformacionDeCliente class="fullWidth"></InformacionDeCliente>
        </ng-container>
      </accordion>
    </div>

    <div class="accordionInformacionComercial">
      <accordion class="" [title]="'INFORMACION COMERCIAL'">
        <InformacionComercial class="fullWidth"></InformacionComercial>
      </accordion>
    </div>

    <div class="accordionInformacionComercial">
      <accordion class="borderTopBottom" [title]="'ACCIONES PENDIENTES'">
      </accordion>
    </div>

  </div>
</FullContainer>