import { Component } from '@angular/core';
import { ActivatedRoute, ChildrenOutletContexts, NavigationEnd, Router } from '@angular/router';
import { AnimationControllerService, DefaultBaseService, iUnsubscribeDestroy, NotificationsService, slideNavigationFunctions, slideNavigationTypes, TokenManagmentService, VariablesDesignSystemService } from '@quasar_dynamics/basic-designsystem';
import { transition, trigger } from '@angular/animations';
import { Title } from '@angular/platform-browser';
import { filter, map, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StaticUtilitiesService } from './Services/Utils/StaticUtilities.service';
import { LoginService } from './Services/Api/Login.service';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('routerTransition', [
      transition("* => slideToLeft", slideNavigationFunctions.getAnimation(slideNavigationTypes.slideToLeft)),
      transition("* => slideToRight", slideNavigationFunctions.getAnimation(slideNavigationTypes.slideToRight)),
      transition("* => slideToTop", slideNavigationFunctions.getAnimation(slideNavigationTypes.slideToTop)),
      transition("* => slideToBottom", slideNavigationFunctions.getAnimation(slideNavigationTypes.slideToBottom)),
      transition("* => slideToLeftDuplicate", slideNavigationFunctions.getAnimation(slideNavigationTypes.slideToLeft)),
      transition("* => slideToRightDuplicate", slideNavigationFunctions.getAnimation(slideNavigationTypes.slideToRight)),
      transition("* => slideToTopDuplicate", slideNavigationFunctions.getAnimation(slideNavigationTypes.slideToTop)),
      transition("* => slideToBottomDuplicate", slideNavigationFunctions.getAnimation(slideNavigationTypes.slideToBottom)),
    ])
  ],

})
export class AppComponent extends iUnsubscribeDestroy {
  title = 'Mopasa';

  constructor(private contexts: ChildrenOutletContexts, private router: Router, private titleSE: Title, public animService: AnimationControllerService, private loginSE: LoginService) {
    super()
  }

  ngOnInit() {
    VariablesDesignSystemService.setIsRelease(environment.production);
    VariablesDesignSystemService.setUrlApi(environment.urlApi);

    TokenManagmentService.listenerTokenLost().pipe(takeUntil(this._unsub)).subscribe(res => {
      StaticUtilitiesService.showError("Ha habido un problema, intentalo más tarde")
      this.loginSE.logOut();
    })
    
    TokenManagmentService.listenerStartRefreshToken().pipe(takeUntil(this._unsub)).subscribe(res => {
      if (!location.pathname.includes("login")) { this.loginSE.refreshToken(); }
    })


    this.animService.setCurrentAnimation(slideNavigationTypes.slideToTop);
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd), map(() => {
      let route: ActivatedRoute = this.router.routerState.root;
      let routeTitle = '';
      while (route!.firstChild) {
        route = route.firstChild;
      }
      if (route.snapshot.data['title']) {
        routeTitle = route!.snapshot.data['title'];
      }
      return routeTitle;
    })
    ).subscribe((title: string) => {
      if (title) {
        this.titleSE.setTitle(`Mopasa - ${title}`);
      }
    });

  }

  getRouteAnimationData() {
    return this.contexts.getContext('primary')?.route?.snapshot?.data?.['animation'];
  }

}
