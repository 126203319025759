<div class="popupGeneralContainer">
  <div class="header">
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
    <p>Editar estado</p>
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
  </div>
  <div class="body">
    <div class="customContainer">
      <label for="estado">Estado</label>
      <selector [options]="opcionesEstados" [(ngModel)]="updateObject.state" (change)="edit()"></selector>
    </div>
  </div>
  <div class="footer" *ngIf="modalData.accion!='ver'">
    <btn classList="secundaryButton" (click)="closePopup()">
      <ng-container>
        Finalizar
      </ng-container>
    </btn>
  </div>
</div>