<pill class="tablePil" classList="tablePill">
  <div class="headerTable">
    <p class="headerTableP">Descripción</p>
    <p class="headerTableP">Valor</p>
  </div>
  <div class="bodyTable" *ngFor="let spec of specs; index as index; first as first">
    <div class="contenedor">
      <selector [options]="opcionesCaracteristicas" (change)="checkIsCompleted()" [(ngModel)]="spec.id"></selector>
    </div>
    <div class="contenedor">
      <CustomInput classList="fullWidth tall special" placeholder="  Valor" [(ngModel)]="spec.value"
        (change)="checkIsCompleted()"></CustomInput>
    </div>
    <span *ngIf="!first">
      <mat-icon (click)="removeThisCharacteristicLine(index)" >close</mat-icon>
    </span>
  </div>
</pill>