import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultBaseService, iResultHttp, iStaticUtilities } from '@quasar_dynamics/basic-designsystem';
import { BehaviorSubject, Observable, forkJoin } from 'rxjs';
import { iProductionReport, iProductionReportFilter } from 'src/app/Shared/Interfaces/iProductionReport';

@Injectable({
  providedIn: 'root'
})
export class ProductionReportService extends DefaultBaseService {

  res: iResultHttp = new iResultHttp();

  protected _restultLastParteProduccion: BehaviorSubject<any> = new BehaviorSubject<any>(null)
  getResultLastParteProduccion() { return this._restultLastParteProduccion }
  clearResultLastParteProduccion() { this._restultLastParteProduccion.next(null) }
  protected _restultEncabezadoId: BehaviorSubject<any> = new BehaviorSubject<any>(null)
  getResultEncabezadoId() { return this._restultEncabezadoId }
  clearResultEncabezadoId() { this._restultEncabezadoId.next(null) }
  protected _restultPackagingId: BehaviorSubject<any> = new BehaviorSubject<any>(null)
  getResultPackagingId() { return this._restultPackagingId }
  clearResultPackagingId() { this._restultPackagingId.next(null) }
  protected _restultFungiblesId: BehaviorSubject<any> = new BehaviorSubject<any>(null)
  getResultFungiblesId() { return this._restultFungiblesId }
  clearResultFungiblesId() { this._restultFungiblesId.next(null) }
  protected _restultCharacteristicsId: BehaviorSubject<any> = new BehaviorSubject<any>(null)
  getResultCharacteristicsId() { return this._restultCharacteristicsId }
  clearResultCharacteristicsId() { this._restultCharacteristicsId.next(null) }
  protected _restultBatchCharacteristicsId: BehaviorSubject<any> = new BehaviorSubject<any>(null)
  getResultBatchCharacteristicsId() { return this._restultBatchCharacteristicsId }
  clearResultBatchCharacteristicsId() { this._restultBatchCharacteristicsId.next(null) }
  protected _restultFormulaTableId: BehaviorSubject<any> = new BehaviorSubject<any>(null)
  getResultFormulaTableId() { return this._restultFormulaTableId }
  clearResultFormulaTableId() { this._restultFormulaTableId.next(null) }
  protected _restultColumns: BehaviorSubject<any> = new BehaviorSubject<any>(null)
  getResultColumns() { return this._restultColumns }
  clearResultColumns() { this._restultColumns.next(null) }

  constructor(private http: HttpClient) {
    super();
  }

  getAll(queryParams: iProductionReportFilter) {
    this.http.get(this.urlApi + '/api/production/report' + this.optionsGet(queryParams), this.getHeader()).subscribe({
      next: (data: any) => {
        let normalizedProductionReport = iProductionReport.convertFromCollection(iStaticUtilities.normalizeNamesCollection(data.data));
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: { data: normalizedProductionReport, total: data.total } });
        this.sendNextResult('result', this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultError', this.res, { method: this.getAll, args: [queryParams] });
        this.checkStatusError(error);
      },
    });
  }
  getAllTable(queryParams: iProductionReportFilter) {
    this.http.get(this.urlApi + '/api/production/report/tabla' + this.optionsGet(queryParams), this.getHeader()).subscribe({
      next: (data: any) => {
        let normalizedProductionReport = iStaticUtilities.normalizeNamesCollection(data.data);
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: { data: normalizedProductionReport, total: data.total } });
        this.sendNextResult('result', this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultError', this.res, { method: this.getAllTable, args: [queryParams] });
        this.checkStatusError(error);
      },
    });
  }
  getAllColumns() {
    this.http.get(this.urlApi + '/api/production/report/columns' , this.getHeader()).subscribe({
      next: (data: any) => {
        let normalizedProductionReport = data;
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: normalizedProductionReport});
        this.sendNextObservable(this._restultColumns, this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextObservable(this._restultColumns, this.res,true, { method: this.getAllColumns, args: [] });
        this.checkStatusError(error);
      },
    });
  }

  getSingle(id: number) {
    this.http.get(this.urlApi + '/api/production/report/' + id, this.getHeader()).subscribe({
      next: (data: any) => {
        let normalizedProductionReport = iProductionReport.convertFromObject(iStaticUtilities.normalizeNames(data));
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: normalizedProductionReport });
        this.sendNextResult('resultIndividual', this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultIndividualError', this.res, { method: this.getSingle, args: [id] });
        this.checkStatusError(error);
      },
    });
  }
  getPackagingId(id: number) {
    this.http.get(this.urlApi + '/api/production/report/packaging/' + id, this.getHeader()).subscribe({
      next: (data: any) => {
        let normalizedProductionReport = data;
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: normalizedProductionReport });
        this.sendNextObservable(this._restultPackagingId, this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextObservable(this._restultPackagingId, this.res, true, { method: this.getPackagingId, args: [id] });
        this.checkStatusError(error);
      },
    });
  }
  getFungiblesId(id: number) {
    this.http.get(this.urlApi + '/api/production/report/fungible/' + id, this.getHeader()).subscribe({
      next: (data: any) => {
        let normalizedProductionReport = data;
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: normalizedProductionReport });
        this.sendNextObservable(this._restultFungiblesId, this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextObservable(this._restultFungiblesId, this.res, true, { method: this.getFungiblesId, args: [id] });
        this.checkStatusError(error);
      },
    });
  }
  getFormulaTableId(id: number) {
    this.http.get(this.urlApi + '/api/production/report/table/' + id, this.getHeader()).subscribe({
      next: (data: any) => {
        let normalizedProductionReport = data;
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: normalizedProductionReport });
        this.sendNextObservable(this._restultFormulaTableId, this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextObservable(this._restultFormulaTableId, this.res, true, { method: this.getFormulaTableId, args: [id] });
        this.checkStatusError(error);
      },
    });
  }
  getCharacteristicsId(id: number) {
    this.http.get(this.urlApi + '/api/production/report/formula/characteristics/' + id, this.getHeader()).subscribe({
      next: (data: any) => {
        let normalizedProductionReport = data;
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: normalizedProductionReport });
        this.sendNextObservable(this._restultCharacteristicsId, this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextObservable(this._restultCharacteristicsId, this.res, true, { method: this.getCharacteristicsId, args: [id] });
        this.checkStatusError(error);
      },
    });
  }
  getBatchCharacteristicsId(id: number) {
    this.http.get(this.urlApi + '/api/production/report/batch/characteristics/' + id, this.getHeader()).subscribe({
      next: (data: any) => {
        let normalizedProductionReport = data;
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: normalizedProductionReport });
        this.sendNextObservable(this._restultBatchCharacteristicsId, this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextObservable(this._restultBatchCharacteristicsId, this.res, true, { method: this.getBatchCharacteristicsId, args: [id] });
        this.checkStatusError(error);
      },
    });
  }
  getEncabezadoId(id: number) {
    this.http.get(this.urlApi + '/api/production/report/header/' + id, this.getHeader()).subscribe({
      next: (data: any) => {
        let normalizedProductionReport = iStaticUtilities.normalizeNames(data);
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: normalizedProductionReport });
        this.sendNextObservable(this._restultEncabezadoId, this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextObservable(this._restultEncabezadoId, this.res, true, { method: this.getEncabezadoId, args: [id] });
        this.checkStatusError(error);
      },
    });
  }
  getLastParteProduccion() {
    this.http.get(this.urlApi + '/api/production/report/last/', this.getHeader()).subscribe({
      next: (data: any) => {
        let normalizedProductionReport = data;
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: normalizedProductionReport });
        this.sendNextObservable(this._restultLastParteProduccion, this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextObservable(this._restultLastParteProduccion, this.res, true, { method: this.getLastParteProduccion, args: [] });
        this.checkStatusError(error);
      },
    });
  }
  getNumberReportToday() {
    this.http.get(this.urlApi + '/api/production/report/today/', this.getHeader()).subscribe({
      next: (data: any) => {
        let normalizedProductionReport = data + 1;
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: normalizedProductionReport });
        this.sendNextObservable(this._restultLastParteProduccion, this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextObservable(this._restultLastParteProduccion, this.res, true, { method: this.getLastParteProduccion, args: [] });
        this.checkStatusError(error);
      },
    });
  }

  create(productionreport: iProductionReport) {
    this.http.post(this.urlApi + "/api/production/report", productionreport, this.getHeader()).subscribe({
      next: (data: any) => {
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: data["data"] });
        this.sendNextResult('resultUpdate', this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultUpdateError', this.res, { method: this.create, args: [productionreport] });
        this.checkStatusError(error);
      },
    });
  }
  closeProductionReport(productionreport: iProductionReport, id: number) {
    this.http.post(this.urlApi + "/api/production/report/"+id, productionreport, this.getHeader()).subscribe({
      next: (data: any) => {
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: data["data"] });
        this.sendNextResult('resultUpdate', this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultUpdateError', this.res, { method: this.closeProductionReport, args: [productionreport, id] });
        this.checkStatusError(error);
      },
    });
  }

  update(productionreport: iProductionReport) {
    this.http.put(this.urlApi + "/api/production/report/" + productionreport.id, productionreport, this.getHeader()).subscribe({
      next: (data: any) => {
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: data["data"] });
        this.sendNextResult('resultUpdate', this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultUpdateError', this.res, { method: this.update, args: [productionreport] });
        this.checkStatusError(error);
      },
    });
  }

  updateColumns(productionreport:any) {
    this.http.put(this.urlApi + "/api/production/report/columns" , productionreport, this.getHeader()).subscribe({
      next: (data: any) => {
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: data["data"] });
        this.sendNextResult('resultUpdate', this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultUpdateError', this.res, { method: this.updateColumns, args: [productionreport] });
        this.checkStatusError(error);
      },
    });
  }

  delete(ids: Array<number | string>) {
    let idsObjs = this.convertArrayObject(ids, "ids");
    this.http.delete(this.urlApi + "/api/production/report", this.sendBodyOptions(idsObjs)).subscribe({
      next: (data: any) => {
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: data["data"] });
        this.sendNextResult('resultDelete', this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultDeleteError', this.res, { method: this.delete, args: [ids] });
        this.checkStatusError(error);
      },
    });
  }

}
