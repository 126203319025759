import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { iDataTableColumns, iDataTableOptions, iDate, iUnsubscribeDestroy, PopupService, slideNavigationTypes } from '@quasar_dynamics/basic-designsystem';
import { Subject, takeUntil } from 'rxjs';
import { ConfirmPopupComponent } from 'src/app/Popups/ConfirmPopup/ConfirmPopup.component';
import { OrderService } from 'src/app/Services/Api/Order.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';
import { iOrderFilter } from 'src/app/Shared/Interfaces/iOrder';
import * as XLSX from 'xlsx';


@Component({
  selector: 'erpProveedoresDetallesHistoricoPedidos',
  templateUrl: './ERP-Proveedores-Detalles-HistoricoPedidos.component.html',
  styleUrls: ['./ERP-Proveedores-Detalles-HistoricoPedidos.component.scss']
})
export class ERPProveedoresDetallesHistoricoPedidosComponent extends iUnsubscribeDestroy implements OnInit {

  isLoading: boolean = false;

  _reloadTableWidth = new Subject()

  @Input() data:any = [];

  proveedorId:number =0 
  triggerFilters: boolean = false;

  filter: iOrderFilter = {
    num_devoluciones:20,
    num_pagina:1,
  }

  //Tabla
  displayedColumnsTabla: Array<iDataTableColumns> = [
    { label: 'Nº Pedido', property: 'numPedido' },
    { label: 'Fecha', property: 'fecha', align: 'center' },
    { label: 'Adjuntos', property: 'adjuntos', align: 'center', },
    { label: 'Estado', property: 'state', align: 'center', classList: 'tableEstado tableEstadoPedidos' },

  ];

  dataForTable = []
  totalPaginacion:number = 0;
  optionsTabla: iDataTableOptions = {
    isSelectable: true,
    canSee: true,
    moreIconsHead: [{ callback: (event) => { this.exportexcel() }, icon: "download" }],

    isHovered: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc'
  }


  constructor(private router: Router, private staticUtilitiesSE: StaticUtilitiesService, private popupSE: PopupService, private orderSE: OrderService) {
    super();
  }

  ngOnInit() {
  }
  
  ngOnChanges(changes: SimpleChanges) {
    if (changes['data']) {
      if (Object.keys(changes['data'].currentValue).length > 0) {
        this.data = changes['data'].currentValue;
        this.proveedorId = this.data.id;
        this.getHistoricoDePedidos()
        setTimeout(() => {
          
          this.triggerFilters = true;
        }, 2000);
      }
    }
  }


  /**
 * Edit
 */

  edit(event) {

  }

  /**
   * 
   * Pagination
   */

  changePagination(event: any) {
    if (event === 'next') {
      this.filter.num_pagina++;
      this.getHistoricoDePedidos();
    } else {
      this.filter.num_pagina--;
      this.getHistoricoDePedidos();
    }
  }

  /**
   * See cliente
   */

  see(event) {
    // this.staticUtilitiesSE.goTo('/erp/clientes/detalles-cliente', slideNavigationTypes.slideToTop, {cliente: event.id})

  }

  /**
   * SEARCH
   */

  search(event){
    if(!event){delete this.filter.genericFilter; this.getHistoricoDePedidos(); return}
    this.filter.genericFilter = event;
    this.getHistoricoDePedidos();
  }

    /**
* DESCARGA DE PARTES EN EXCEL
*/

exportexcel(): void {
  let table = document.createElement("table")
  table.append((document.querySelector("dataTable .tableHeader thead") as HTMLElement).cloneNode(true))
  table.querySelector("thead .iconsActions")?.remove();
  table.querySelector("thead .checkboxContainer")?.remove();
  let rows = Array.from(document.querySelectorAll("dataTable .tableBody tbody tr "));
  let filteredRows = rows.filter((row) => {
    return row.querySelector('.checkboxContainer mat-checkbox ')!.getAttribute('ng-reflect-checked') === "true"
  })
  if (filteredRows.length > 0) {
    filteredRows.forEach((row) => {
      let newRow = row.cloneNode(true) as HTMLElement;
      newRow.querySelector('td:last-of-type')?.remove();
      newRow.querySelector('td:first-of-type')?.remove();
      table.append(newRow)
    })
  } else {
    table.append((document.querySelector("dataTable .tableBody tbody") as HTMLElement).cloneNode(true))
    table.querySelectorAll("tbody tr td:last-of-type").forEach(element => {
      element.remove();
    })
    table.querySelectorAll("tbody tr td:first-of-type").forEach(element => {
      element.remove();
    })
  }
  /* pass here the table id */
  const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table);

  /* generate workbook and add the worksheet */
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  /* save to file */
  XLSX.writeFile(wb, 'HistóricoPedidos.xlsx');

}

  /**
   * 
   * CLickRow
   */

clickRow(event) {
  this.staticUtilitiesSE.goTo("/erp/proveedores/detalles-pedido", slideNavigationTypes.slideToTop, { pedido: event.numPedido })

}

  /**
   * add
   */

  create() { }

  /**
   * API CALLS
   */

  getHistoricoDePedidos() {
    this.isLoading = true;
    this.orderSE.getAllOrdersOneCompany(this.filter, this.proveedorId);
    this.orderSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(res => {
      if(!res){return}
      let {data,total} = res.data;
      this.totalPaginacion = total;
      this.dataForTable = data.map(item => {return {...item, adjuntos:item.adjunto.length, fecha: iDate.javascriptConvert(new Date(item.fechaPedido)).toStringDate('EU'),  }});
      setTimeout(() => {
        this._reloadTableWidth.next(true)
      }, 1);
      this.isLoading = false;
      this._unsubInd.next('');
    })
  }
}
