import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  iOptionsSelector,
  iUnsubscribeDestroy,
} from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { ContainerService } from 'src/app/Services/Api/Container.service';

@Component({
  selector: 'nuevaFormulaStep3',
  templateUrl: './NuevaFormula-Step3.component.html',
  styleUrls: ['./NuevaFormula-Step3.component.scss'],
})
export class NuevaFormulaStep3Component
  extends iUnsubscribeDestroy
  implements OnInit, OnChanges
{
  @Input() envaseFlag: boolean = false;
  @Input() step3Data: any = null;
  @Input() toEditData: any = null;
  @Output() isCompleted: EventEmitter<any> = new EventEmitter<any>();

  isEditing: boolean = false;
  observaciones: string = '';
  materialSelected: any = null;
  total: number = 0;
  totalKg: number = 0;
  totalL: number = 0;
  arrayLineasTabla: Array<any> = [
    // { id: 8,codigo: '001', descripcion: 'Granel', cantidad: 0, ocupacion: 0.01},
  ];
  opcionesEnvases: iOptionsSelector = {
    items: [],
    clearable: false,
    search: true,
    placeholder: 'Envases',
    append: true,
    bindLabel: 'descripcion',
  };
  constructor(private containerSE: ContainerService) {
    super();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['envaseFlag'] != undefined) {
      if (
        changes['envaseFlag'].currentValue != null &&
        changes['envaseFlag'].previousValue != undefined
      ) {
        this.generateRow();
      }
    }
    if (changes['toEditData']) {
      if (!changes['toEditData'].currentValue) return;
      this.isEditing = true;
      this.arrayLineasTabla = changes['toEditData'].currentValue.container.map(
        (item: any) => {
          return {
            id: item.id,
            codigo: item.code,
            descripcion: item.description,
            cantidad: item.occLiters,
            ocupacion: item.occKg,
          };
        }
      );
      this.checkIsCompleted();
    }
  }

  ngOnInit() {
    this.step3Data != null ? (this.arrayLineasTabla = this.step3Data) : null;
    this.getEnvases();
  }

  /**
   * GETTERS & SETTERS
   */
  getEnvaseSelected(event, linea) {
    linea = event;
  }

  /**
   * FUNCTIONALITIES
   */
  checkIsCompleted() {
    this.isCompleted.emit(this.arrayLineasTabla);
  }
  generateRow() {
    this.arrayLineasTabla.push({
      id: null,
      codigo: '',
      descripcion: '',
      cantidad: null,
      ocupacion: null,
    });
  }

  removeRow(linea) {
    this.arrayLineasTabla.splice(this.arrayLineasTabla.indexOf(linea), 1);
    this.checkIsCompleted();
  }

  /**
   * API CALLS
   */

  getEnvases() {
    this.containerSE.getAll({ num_devoluciones: 20000, num_pagina: 1 });
    this.containerSE
      .getResult()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        let { data, total } = res.data;

        this.opcionesEnvases.items = data.map((item: any) => {
          return {
            id: item.id,
            codigo: item.code,
            descripcion: item.description,
            cantidad: item.occLiters,
            ocupacion: item.occKg,
          };
        });
        this._unsubInd.next('');
      });
  }
}
