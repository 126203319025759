import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { PopupAlbaranService } from 'src/app/Services/Api/PopupAlbaran.service';

@Component({
  selector: 'NuevoAlbaranPopupSeccion3',
  templateUrl: './NuevoAlbaranPopupSeccion3.component.html',
  styleUrls: ['./NuevoAlbaranPopupSeccion3.component.scss']
})
export class NuevoAlbaranPopupSeccion3Component implements OnInit {

  @Input() dataSeccion3: any = null;

  constructor(private alabaranesSE: PopupAlbaranService) { }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['dataSeccion3']) {
       
    }
  }

}
