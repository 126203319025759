import { Component, OnInit } from '@angular/core';
import { iOptionsSelector, iUnsubscribeDestroy } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { RatesCustomerService } from 'src/app/Services/Api/RatesCustomer.service';
import { iRatesCustomer, iRatesCustomerFilter } from 'src/app/Shared/Interfaces/iRatesCustomer';

@Component({
  selector: 'app-ERP-Tarifas',
  templateUrl: './ERP-Tarifas.component.html',
  styleUrls: ['./ERP-Tarifas.component.scss']
})
export class ERPTarifasComponent extends iUnsubscribeDestroy implements OnInit {
  selected = 0;
  selectores = [
    { label: 'Polvo', image:'none', icon: 'none',selected: 0 ,before:'yes', color: "azul-verdoso"},
    { label: 'Cl.polvo', icon: 'none', image:'none', selected: 1, before:'yes', color: "azul-verdoso" },
    { label: 'Líquida', icon: 'none', image:'none', selected: 2, before:'yes' , color: "azul-verdoso"},
    { label: 'Cl.Líquida', icon: 'none', image:'none', selected: 3, before:'yes' , color: "azul-verdoso"},
  ]
  filter:iRatesCustomerFilter={
    num_pagina: 1,
    num_devoluciones:20,
  }
  maxFactor:number = 0.65;
  minAporte:number = 1.40;


  constructor() {
    super();
  }

  ngOnInit() {
  }

  selectedOption(event) {
    this.selected = event;
  }


}
