import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultBaseService, iResultHttp, iStaticUtilities } from '@quasar_dynamics/basic-designsystem';
import { BehaviorSubject } from 'rxjs';
import { iArticle, iArticleFilter } from 'src/app/Shared/Interfaces/iArticle';

@Injectable({
  providedIn: 'root'
})
export class ArticleService extends DefaultBaseService {

  res: iResultHttp = new iResultHttp();

  protected _resultArticlesRates = new BehaviorSubject<any>(null);
  getResultArticlesRates() {return this._resultArticlesRates}
  clearResultArticlesRates() {this._resultArticlesRates.next(null)}
  protected _resultArticlesRatesError = new BehaviorSubject<any>(null);
  getResultArticlesRatesError() {return this._resultArticlesRatesError}
  clearResultArticlesRatesError() {this._resultArticlesRatesError.next(null)}
  protected _resultArticlesRatesCompanyId = new BehaviorSubject<any>(null);
  getResultArticlesRatesCompanyId() {return this._resultArticlesRatesCompanyId}
  clearResultArticlesRatesCompanyId() {this._resultArticlesRatesCompanyId.next(null)}
  protected _resultArticlesStock = new BehaviorSubject<any>(null);
  getRresultArticlesStock() {return this._resultArticlesStock}
  clearRresultArticlesStock() {this._resultArticlesStock.next(null)}
  protected _resultSingleStock = new BehaviorSubject<any>(null);
  getRresultSingleStock() {return this._resultSingleStock}
  clearRresultSingleStock() {this._resultSingleStock.next(null)}

  constructor(private http: HttpClient) {
    super();
  }

  getAll(queryParams: iArticleFilter){
    this.http.get(this.urlApi + '/api/article' + this.optionsGet(queryParams), this.getHeader()).subscribe({
      next: (data:any) => {
        let normalizedArticle = iArticle.convertFromCollection( iStaticUtilities.normalizeNamesCollection(data.data) );
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data:{data: normalizedArticle, total:data.total}});
        this.sendNextResult('result', this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultError', this.res, { method: this.getAll, args: [queryParams] });
        this.checkStatusError(error);
      },
    });
  }
  getAllStock(queryParams: iArticleFilter){
    this.http.get(this.urlApi + '/api/article/tabla' + this.optionsGet(queryParams), this.getHeader()).subscribe({
      next: (data:any) => {
        let normalizedArticle =  iStaticUtilities.normalizeNamesCollection(data.data) ;
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data:{data: normalizedArticle, total:data.total}});
        this.sendNextObservable(this._resultArticlesStock, this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextObservable(this._resultArticlesStock, this.res, true, { method: this.getAllStock, args: [queryParams] });
        this.checkStatusError(error);
      },
    });
  }

  getSingle(id: number){
    this.http.get(this.urlApi + '/api/article/'+id, this.getHeader()).subscribe({
      next: (data:any) => {
        let normalizedArticle = iArticle.convertFromObject( iStaticUtilities.normalizeNames(data) );
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: normalizedArticle});
        this.sendNextResult('resultIndividual', this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultIndividualError', this.res, { method: this.getSingle, args: [id] });
        this.checkStatusError(error);
      },
    });
  }

  getArticleByIdAndClientId(articleId: number, companyId:number){
    this.http.get(this.urlApi + '/api/article/buy'+this.optionsGet({articleId,companyId}), this.getHeader()).subscribe({
      next: (data:any) => {
        let normalizedArticle =  iStaticUtilities.normalizeNames(data) ;
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: normalizedArticle});
        this.sendNextResult('resultIndividual', this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultIndividualError', this.res, { method: this.getArticleByIdAndClientId, args: [articleId,companyId] });
        this.checkStatusError(error);
      },
    });
  }

  create(article:iArticle){
    this.http.post(this.urlApi+"/api/article", article, this.getHeader()).subscribe({
      next: (data:any) => {
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: data["data"]});
        this.sendNextResult('resultUpdate', this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultUpdateError', this.res, { method: this.create, args: [article] });
        this.checkStatusError(error);
      },
    });
  }

  update(article:iArticle){
    this.http.put(this.urlApi+"/api/article/"+article.id, article, this.getHeader()).subscribe({
      next: (data:any) => {
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: data["data"]});
        this.sendNextResult('resultUpdate', this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultUpdateError', this.res, { method: this.update, args: [article] });
        this.checkStatusError(error);
      },
    });
  }

  delete(ids: Array<number|string>){
    let idsObjs = this.convertArrayObject(ids, "ids");
    this.http.delete(this.urlApi+"/api/article", this.sendBodyOptions(idsObjs) ).subscribe({
      next: (data:any) => {
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: data["data"]});
        this.sendNextResult('resultDelete', this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultDeleteError', this.res, { method: this.delete, args: [ids] });
        this.checkStatusError(error);
      },
    });
  }

  getArticlesRatesClientLiquid(queryParams: any){
    this.http.get(this.urlApi + '/api/article/rates/custom/liquid' + this.optionsGet(queryParams), this.getHeader()).subscribe({
      next: (data:any) => {
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: {data: data.data, total: data.total}});
        this.sendNextObservable(this._resultArticlesRates, this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextObservable(this._resultArticlesRatesError, this.res, true, { method: this.getArticlesRatesClientLiquid, args: [queryParams] });
        this.checkStatusError(error);
      },
    });
  }

  getArticlesRatesClientDust(queryParams: any){
    this.http.get(this.urlApi + '/api/article/rates/custom/dust' + this.optionsGet(queryParams), this.getHeader()).subscribe({
      next: (data:any) => {
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: {data: data.data, total: data.total}});
        this.sendNextObservable(this._resultArticlesRates, this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextObservable(this._resultArticlesRatesError, this.res, true, { method: this.getArticlesRatesClientDust, args: [queryParams] });
        this.checkStatusError(error);
      },
    });
  }

  getArticlesRatesGenericLiquid(queryParams: any){
    this.http.get(this.urlApi + '/api/article/rates/generic/liquid' + this.optionsGet(queryParams), this.getHeader()).subscribe({
      next: (data:any) => {
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: {data: data.data, total: data.total}});
        this.sendNextObservable(this._resultArticlesRates, this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextObservable(this._resultArticlesRatesError, this.res, true, { method: this.getArticlesRatesGenericLiquid, args: [queryParams] });
        this.checkStatusError(error);
      },
    });
  }

  getArticlesRatesGenericDust(queryParams: any){
    this.http.get(this.urlApi + '/api/article/rates/generic/dust' + this.optionsGet(queryParams), this.getHeader()).subscribe({
      next: (data:any) => {
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: {data: data.data, total: data.total}});
        this.sendNextObservable(this._resultArticlesRates, this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextObservable(this._resultArticlesRatesError, this.res, true, { method: this.getArticlesRatesGenericDust, args: [queryParams] });
        this.checkStatusError(error);
      },
    });
  }
  getArticlesRatesCompanyId(queryParams: any, companyId){
    this.http.get(this.urlApi + '/api/article/cliente/'+companyId + this.optionsGet(queryParams), this.getHeader()).subscribe({
      next: (data:any) => {
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: {data: data.data, total: data.total}});
        this.sendNextObservable(this._resultArticlesRatesCompanyId, this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextObservable(this._resultArticlesRatesCompanyId, this.res, true, { method: this.getArticlesRatesCompanyId, args: [queryParams,companyId] });
        this.checkStatusError(error);
      },
    });
  }
  getSingleStock(queryParams: iArticleFilter, articleId){
    this.http.get(this.urlApi + '/api/stock/article/'+articleId + this.optionsGet(queryParams), this.getHeader()).subscribe({
      next: (data:any) => {
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: data[0]});
        this.sendNextObservable(this._resultSingleStock, this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextObservable(this._resultSingleStock, this.res, true, { method: this.getSingleStock, args: [queryParams,articleId] });
        this.checkStatusError(error);
      },
    });
  }


}
