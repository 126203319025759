<div class="popupGeneralContainer">
  <div class="header">
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
    <p *ngIf="modalData.accion=='editar'">Editar líquido</p>
    <p *ngIf="modalData.accion=='crear'">Crear líquido</p>
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
  </div>
  <div class="body">
    <div class="tripleColumn">
      <div class="customContainer" *ngIf="modalData.accion !== 'editar'">
        <label for="Provincia">Cliente</label>
        <selector [options]="opcionesClientes" [(ngModel)]="clientes" (change)="checkIsCompleted()"></selector>
      </div>
      <div class="customContainer" *ngIf="modalData.accion !== 'editar'">
        <label for="pais">Producto</label>
        <selector [options]="opcionesProducto" [(ngModel)]="productos" (change)="checkIsCompleted()"></selector>
      </div>
      <CustomInput [readonly]="modalData.accion=='ver'" classList="fullWidth tall special" [type]="'number'"  placeholder="Venta" label="Venta" [(ngModel)]="venta" (change)="modalData.accion ==='editar'? editSecction(venta, 'price') :checkIsCompleted()"></CustomInput>
    </div>

  </div>
  <div class="footer" *ngIf="modalData.accion!='ver'">
    <btn classList="secundario" (click)="forceClosePopup()">
      <ng-container>
       Cancelar
      </ng-container>
    </btn>
    <btn classList="secundaryButton" (click)="create()" [class.disabled]="!isCompleted" *ngIf="modalData.accion=='crear'">
      <ng-container >
        Crear
      </ng-container>
    </btn>
    <btn classList="secundaryButton" (click)="closePopup()" [class.disabled]="!isCompleted" *ngIf="modalData.accion=='editar'">
      <ng-container >
        Editar
      </ng-container>
    </btn>
  </div>
</div>
