import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { iOptionsSelector } from '@quasar_dynamics/basic-designsystem';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'app-NuevoDocumento-Popup',
  templateUrl: './NuevoDocumento-Popup.component.html',
  styleUrls: ['./NuevoDocumento-Popup.component.scss']
})
export class NuevoDocumentoPopupComponent implements OnInit {
  estados: Array<any> = [{id: 1, estado: "Pendiente"}, {id: 2, estado: "Realizada"}, {id: 3, estado: "Descartada"}]

  isCompleted: boolean = false;
  document: any = null;
  documentLoaded: any = null;
  data: any = {
    nombre: "",
    tipoDocumento: null,
    fechaDoc: new Date(),
    fechaCaducidad: new Date(),
    estado: null,
    adjuntos: [],
  }
  opcionesTipoDocumento: iOptionsSelector = {
    items: ["Factura"],
    search: true,
    placeholder: "Tipo de documento",
    append: true,
  }
  constructor(public dialogRef: MatDialogRef<NuevoDocumentoPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any,) { }


  ngOnInit() {

  }
  /**
  * FUNCTIONALITY
  */
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }

  /**
    * VALIDATIONS
    */
  checkIsCompleted() {
    this.isCompleted = StaticUtilitiesService.isCompleteObject(this.data);
  }

  /**
     *
     * Add documentos
     */

  addDocument(event: any) {
    if ((event[0].file.size < 5242880 && event[0].file.type == "application/pdf") || (event[0].file.size < 1048576 && (event[0].file.type != "image/png" || event[0].file.type != "image/jpeg"))) {
      if (event[0].file.type != "image/jpeg" && event[0].file.type != "image/png" && event[0].file.type != "application/pdf") {
        StaticUtilitiesService.showWarning("Formato de archivo no admitido")
        this.document = null;
      } else {
        event.forEach((element: any) => {
          let obj = {
            url: element.base64
          }
          if (this.data.adjuntos.length == 0) {
            this.data.adjuntos.push(obj)
          } else {
            this.data.adjuntos.push(obj)
          }
        });
        this.documentLoaded = event[0].file.name;
        this.checkIsCompleted();
      }

    } else {
      StaticUtilitiesService.showWarning("El tamaño del archivo o el formato no es correcto")
    }
  }
}
