<FullContainer>
  <div class="tarifasGeneralContainer">
    <div class="header">
      <h1 *ngIf="selected == 0">PROVEEDORES</h1>
      <h1 *ngIf="selected == 1">MATERIAS PRIMAS</h1>
      <h1 *ngIf="selected == 2">PEDIDOS</h1>

      <CustomToggle [selected]="selected" [selectores]="selectores" (selectedOption)="selectedOption($event)">
      </CustomToggle>
    </div>
    <erpProveedores *ngIf="selected == 0"></erpProveedores>
    <erpProveedoresMateriasPrimas *ngIf="selected == 1"></erpProveedoresMateriasPrimas>
    <erpProveedoresPedidos *ngIf="selected == 2"></erpProveedoresPedidos>
  </div>
</FullContainer>