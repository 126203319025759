import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { iUnsubscribeDestroy } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { LoginService } from 'src/app/Services/Api/Login.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'app-Profile-Popup',
  templateUrl: './Profile-Popup.component.html',
  styleUrls: ['./Profile-Popup.component.scss']
})
export class ProfilePopupComponent extends iUnsubscribeDestroy implements OnInit {
  
  createUserFlag:boolean = false;
  nombre: string = "Paco Peña";
  email: string = "paco@peña.com";
  password: string = '********';
  editPassword: boolean = false;
  checkedPasswords: boolean = false;
  checkPassword: string = "";
  passwordToSend: any = {
    oldPassword: '',
    newPassword: ''
  }
  newUser: any = {
    email: '',
    password: '',
  }
  isCompletedUser: boolean = false;
  constructor(public dialogRef: MatDialogRef<ProfilePopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any, private loginSE:LoginService) {
    super();
  }

  ngOnInit() { 
    this.getFromLocalStorage()
  }

  /**
  * FUNCTIONALITY
  */
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }
  checkPasswords() {
    if(this.passwordToSend.newPassword === this.passwordToSend.oldPassword){
      StaticUtilitiesService.showWarning('La contraseña nueva no puede ser igual a la antigua')
      return
    }
    if ( this.passwordToSend.newPassword && this.passwordToSend.oldPassword && this.checkPassword && (this.passwordToSend.newPassword === this.checkPassword)) {
      this.checkedPasswords = true;
    }else{
      this.checkedPasswords = false;
    }
  }
  getFromLocalStorage(){
    this.nombre = localStorage.getItem('userName')!;
    this.email = localStorage.getItem('email')!;
  }
  checkNewUser(){
    this.isCompletedUser = StaticUtilitiesService.isCompleteObject(this.newUser)
  }
  create(){}
  /**
   * CALLS TO API
   */

  logOut(){
    this.forceClosePopup();
    this.loginSE.logOut();
    this.loginSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(res => {
      if(!res){return}
      this._unsubInd.next('')
    })
    this.loginSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(res => {
      if(!res){return}
      StaticUtilitiesService.showError('Error al cerrar sesión')
      this._unsub.next('')
    })
  }

}
