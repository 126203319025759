<div class="popupGeneralContainer">
  <div class="infoSteps">


    <div class="line">
      <span class="number left" [ngClass]="{active: selectorMap === 0}">1</span>
      <span class="number medium" [ngClass]="{active: selectorMap === 1}">2</span>
      <span class="number right" [ngClass]="{active: selectorMap === 2}">3</span>
    </div>
    <mat-icon (click)="forceClosePopup()">close</mat-icon>

  </div>
  <div class="header">
    <p *ngIf="selectorMap == 0">Crear Albarán</p>
    <p *ngIf="selectorMap == 1"> Albarán {{numAlbaran}}</p>
    <p *ngIf="selectorMap == 2"> Resumen albarán {{numAlbaran}}</p>
  </div>
  <div class="body" *ngIf='selectorMap == 0'>
    <CustomInput [readonly]="true" classList="fullWidth tall special" [type]="'number'" placeholder="Nº albarán"
      label="Nº albarán" [(ngModel)]="numAlbaran"
      (change)="modalData.accion ==='editar'? editSecction(numAlbaran, 'numAlbaran') :checkIsCompleted()"></CustomInput>
    <CustomInput [readonly]="modalData.accion==='ver'" classList="fullWidth tall special"
      placeholder="Agencia Transporte" label="Agencia Transporte" [(ngModel)]="data.transportAgency"
      (change)="modalData.accion ==='editar'? editSecction(data.transportAgency, 'transportAgency') :checkIsCompleted()">
    </CustomInput>
    <div class="customContainer">
      <label for="Pais">Fecha</label>
      <datepicker (dataFromPicker)="data.date = $event; checkIsCompleted()" [placeholder]="data.date"></datepicker>
    </div>
    <CustomInput [readonly]="modalData.accion==='ver'" classList="fullWidth tall special" placeholder="S/ Referencia"
      label="S/ Referencia" [(ngModel)]="data.sReference"
      (change)="modalData.accion ==='editar'? editSecction(data.sReference, 'sReference') :checkIsCompleted()">
    </CustomInput>
    <div class="customContainer">
      <label for="Pais">Cliente</label>
      <selector [options]="opcionesCliente" [(ngModel)]="cliente" (change)="getClienteForAddress($event)"></selector>
    </div>
    <CustomInput [readonly]="modalData.accion==='ver'" classList="fullWidth tall special" placeholder="Bultos"
      label="Bultos" [(ngModel)]="data.packages"
      (change)="modalData.accion ==='editar'? editSecction(data.packages, 'packages') :checkIsCompleted()">
    </CustomInput>
    <div class="customContainer" *ngIf="companyReady">
      <label for="Pais">Dirección de envío</label>
      <selector [options]="opcionesAddress" [(ngModel)]="data.address" (change)="checkIsCompleted()"></selector>
    </div>
  </div>
  <div class="seccion2" *ngIf="selectorMap === 1">
    <NuevoAlbaranPopupSeccion2 (dataSeccion2)="getDataSeccion2($event)" [cliente]="cliente" [valueIva]="valueIva" [dataSeccion2FromFather]="dataSeccion2"></NuevoAlbaranPopupSeccion2>
  </div>
  <div class="seccion3" *ngIf="selectorMap === 2">
    <NuevoAlbaranPopupSeccion3 [dataSeccion3]="dataSeccion3" ></NuevoAlbaranPopupSeccion3>
  </div>





  <!-- FOOTER -->
  <div class="footer" *ngIf="modalData.accion!='ver'">
    <btn classList="secundario" (click)="forceClosePopup()" *ngIf="selectorMap === 0">
      <ng-container>
        Cancelar
      </ng-container>
    </btn>
    <btn classList="secundario" (click)="previousSection()" *ngIf="selectorMap !== 0">
      <ng-container>
        Previo
      </ng-container>
    </btn>
    <btn classList="secundaryButton" [ngClass]="{disabled: !isCompleted}" (click)="nextSection()"
      *ngIf=" selectorMap !== 2">
      <ng-container *ngIf="modalData.accion=='crear' || modalData.accion=='editar'">
        Siguiente
      </ng-container>
    </btn>
    <btn classList="secundaryButton" (click)="create()" [class.disabled]="selectorMap !== 2" *ngIf="selectorMap == 2">
      <ng-container *ngIf="modalData.accion=='crear' || modalData.accion=='editar'">
        Enviar email
      </ng-container>
    </btn>
  </div>
</div>