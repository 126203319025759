<div class="popupGeneralContainer">
  <div class="header">
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
    <p *ngIf="modalData.accion=='editar'">Editar Parte de produccion</p>
    <p *ngIf="modalData.accion=='crear'">Crear Parte de produccion</p>
    <p *ngIf="modalData.accion=='ver'">Ver Parte de produccion</p>
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
  </div>

  <div class="content">
    <NuevoPartePopup-Ficha (_isCompleted)="isCompletedCheck($event)" [data]="dataFicha" (_dataFicha)="dataFichaGet($event)" *ngIf="modalData.accion=='crear' || modalData.accion === 'editar'"></NuevoPartePopup-Ficha>
    <NuevoPartePopup-PlanificacionEnvasado (dataPlanificacion)="dataPlanificacionGet($event)" [dataFicha]="dataFicha" [data]="dataFichaEnvasado" *ngIf="modalData.accion=='crear' || modalData.accion === 'editar'" (create)="createReady($event)"></NuevoPartePopup-PlanificacionEnvasado>
  </div>

  <div class="footer">
    <btn classList="secundario" (click)="forceClosePopup()" >
      <ng-container>
        Cancelar
      </ng-container>
    </btn>
    <btn classList="secundaryButton" (click)="createParte()" [class.disabled]="!isCreateReady" *ngIf="this.modalData.accion === 'crear'" >
      <ng-container *ngIf="modalData.accion=='crear'">
        Crear
      </ng-container>
    </btn>
    <btn classList="secundaryButton" (click)="closePopup()" [class.disabled]="!isCreateReady" *ngIf="this.modalData.accion === 'editar'" >
      <ng-container *ngIf="modalData.accion=='editar'">
        Editar
      </ng-container>
    </btn>

  </div>
</div>