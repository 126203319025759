import { Component, OnInit } from '@angular/core';
import {
  iOptionsSelector,
  iUnsubscribeDestroy,
  PopupService,
} from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { NuevoAsientoPopupComponent } from 'src/app/Popups/NuevoAsiento-Popup/NuevoAsiento-Popup.component';
import { NuevoStaockArticulosPopupComponent } from 'src/app/Popups/NuevoStaockArticulos-popup/NuevoStaockArticulos-popup.component';
import { NuevoStockMateriasPrimasPopupComponent } from 'src/app/Popups/NuevoStockMateriasPrimas-Popup/NuevoStockMateriasPrimas-Popup.component';

@Component({
  selector: 'ERP-Stock',
  templateUrl: './ERP-Stock.component.html',
  styleUrls: ['./ERP-Stock.component.scss'],
})
export class ERPStockComponent extends iUnsubscribeDestroy implements OnInit {
  selectedList: boolean = true;
  refreshRaw: boolean = false;
  refreshArticle: boolean = false;
  filterRaw: any = {};
  filterArticle: any = {};

  selected = 0;

  opcionesUnderMin: iOptionsSelector = {
    items: [{ label: 'Si', value: true }],
    clearable: true,
    search: true,
    placeholder: 'Stock bajo minimo',
    bindValue: 'value',
  };
  opcionesVSNegative: iOptionsSelector = {
    items: [{ label: 'Si', value: true }],
    clearable: true,
    search: true,
    placeholder: 'Stock Virtual Negativo',
    bindValue: 'value',
  };

  selectores = [
    {
      label: 'Materias primas',
      icon: 'none',
      image: 'none',
      selected: 0,
      before: 'yes',
      color: 'rojo-oscuro',
    },
    // { label: 'Artículos', icon: 'none', image: 'none', selected: 1, before: 'yes', color: 'rojo-oscuro' },
  ];

  constructor(private popupSE: PopupService) {
    super();
  }

  ngOnInit() {}

  /**
   *
   * GETTERS && SETTERS
   */

  selectedOption(event) {
    this.selected = event;
  }

  getFilter(event, key) {
    if (this.selected === 0) {
      this.filterRaw[key] = event;
      if (!this.filterRaw.under_min) {
        delete this.filterRaw.under_min;
      }
      if (!this.filterRaw.vs_negative) {
        delete this.filterRaw.vs_negative;
      }
      this.filterRaw = { ...this.filterRaw };
    }
    if (this.selected === 1) {
      this.filterArticle[key] = event;
      if (!this.filterArticle.under_min) {
        delete this.filterArticle.under_min;
      }
      if (!this.filterArticle.vs_negative) {
        delete this.filterArticle.vs_negative;
      }

      this.filterArticle = { ...this.filterArticle };
    }
  }

  create() {
    if (this.selected == 0) {
      this.popupSE.openPopup(NuevoStockMateriasPrimasPopupComponent, {
        data: null,
        accion: 'crear',
      }),
        this.popupSE
          .returnData()
          .pipe(takeUntil(this._unsubInd))
          .subscribe((data) => {
            if (!data) {
              return;
            }
            this.refreshRaw = true;
            this._unsubInd.next('');
          });
    }
    if (this.selected == 1) {
      this.popupSE.openPopup(NuevoStaockArticulosPopupComponent, {
        data: null,
        accion: 'crear',
      }),
        this.popupSE
          .returnData()
          .pipe(takeUntil(this._unsubInd))
          .subscribe((data) => {
            if (!data) {
              return;
            }
            this.refreshArticle = true;
            this._unsubInd.next('');
          });
    }
  }
}
