import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { iOptionsSelector, iUnsubscribeDestroy } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { CharacteristicService } from 'src/app/Services/Api/Characteristic.service';

@Component({
  selector: 'nuevaFormulaStep4',
  templateUrl: './NuevaFormula-Step4.component.html',
  styleUrls: ['./NuevaFormula-Step4.component.scss']
})
export class NuevaFormulaStep4Component extends iUnsubscribeDestroy implements OnInit {

  @Input() step4Data: any = null;
  @Input() caracteristicaFlag: boolean = false;
  @Input() toEditData: any = null;
  @Output() isCompleted: EventEmitter<any> = new EventEmitter<any>();

  data: any = {
    density: "",
    humidity: "",
    color: "",
    hasTemperature: null
  }
  opcionesCaracteristicas: iOptionsSelector = {
    items: [],
    clearable: false,
    search: true,
    placeholder: "Característica",
    append: true,
    bindLabel: "description",
    bindValue: "id"
  }
  specs: Array<any> = [
    {id: null, value:null}
  ]
  constructor(private characteristicSE:CharacteristicService) {
    super();
  }


  ngOnInit() {
    this.step4Data != null ? this.specs = this.step4Data : null;
    this.getCharacteristics()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["caracteristicaFlag"] != undefined) {
      if (changes["caracteristicaFlag"].currentValue != null && changes["caracteristicaFlag"].previousValue != undefined ) {
        this.generateRow()
      }
    }
    if (changes["toEditData"]){
      if(changes['toEditData'].currentValue){
        this.specs = changes['toEditData'].currentValue.characteristicManies.map((item:any)=>{return {id: item.characteristic.id, value: item.value}})
        this.checkIsCompleted()
      }
    }
  }

  /**
   * FUNCTIONALITIES
   */
  checkIsCompleted() {
    this.isCompleted.emit(this.specs)
  }
  generateRow(){
    this.specs.push({ id: null,value: ''})
  }
  removeThisCharacteristicLine(index){
    this.specs.splice(index,1)
    this.checkIsCompleted()
  }

  /**
   * API CALLS
   */

  getCharacteristics(){
    this.characteristicSE.getAll({num_devoluciones: 1000,num_pagina: 1});
    this.characteristicSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe((res) => {
      if(!res){return};
      let {data, total } = res.data;
       
      this.opcionesCaracteristicas.items = data;
      this._unsubInd.next('')
    })
  }


}
