import { iBaseObject } from "@quasar_dynamics/basic-designsystem";

export interface iMeasurementUnit {
    id?: number,
    unit?: string,
}

export class iMeasurementUnit extends iBaseObject {
    static convertFromCollection(ob: any): Array<iMeasurementUnit> {
        let iMeasurementUnitCollection: Array<iMeasurementUnit> = [];
        ob.forEach((element: any) => {
            iMeasurementUnitCollection.push(this.convertFromObject(element));
        });
        return iMeasurementUnitCollection;
    }

    static convertFromObject(ob: any): iMeasurementUnit {
        let iMeasurementUnitObj = new iMeasurementUnit();
        iMeasurementUnitObj.id = ob.id;
        iMeasurementUnitObj.unit = ob.unit;
        return iMeasurementUnitObj;
    }

    static createEmptyObject(): iMeasurementUnit {
        let iMeasurementUnitObj = new iMeasurementUnit();
        iMeasurementUnitObj.unit = '';
        return iMeasurementUnitObj;
    }
}

export interface iMeasurementUnitFilter {
    num_devoluciones: number;
    num_pagina: number;
    genericFilter?: string;
    unit?: string,
}