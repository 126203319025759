<FullContainer>
  <div class="fullView">
    <div class="headerAndToggle">
      <div class="header">
        <p>FORMULACIÓN/PRODUCCIÓN</p>
      </div>
      <div class="toggle">
        <CustomToggle [selected]="selected" [selectores]="selectores" (selectedOption)="selectedOption($event)"
          (addNew)="create()">
        </CustomToggle>
      </div>
    </div>
    <div class="filters">



      <div class="buttonsFipelineList" [ngClass]="{listSelected: selectedList, pipeSelected: !selectedList}"
        *ngIf="selected == 1">
        <button class="iconLeft" (click)="selectedListToggle(false)">
          <ng-container>
            <mat-icon class="mat-icon-left">segment</mat-icon>
          </ng-container>
        </button>

        <button class="iconRight" (click)="selectedListToggle(true)">
          <ng-container>
            <mat-icon>reorder</mat-icon>
          </ng-container>
        </button>
      </div>



      <filterActions (addNew)="create()" [disableFilter]="selectedList === false"
        [disableSearch]="selectedList === false"
        (searchSend)="getFilters($event, 'genericFilter');getFiltersProductionReport($event, 'genericFilter') ">
        <ng-container filterContent>
          <div class="containerSelectores" *ngIf="selected === 0">
            <div>
              <selector [options]="opcionesFamilia" (change)="getFilters($event, 'familia')"></selector>
            </div>
            <div *ngIf="selected === 0">
              <selector [options]="opcionesSubfamilia" (change)="getFilters($event, 'subfamilia')"></selector>
            </div>
          </div>

          <div class="containerSelectores" *ngIf="selected === 1">
            <div>
              <selector [options]="opcionesProductionArea"
                (change)="getFiltersProductionReport($event, 'productionArea')"></selector>
            </div>
            <div class="width10vw">
              <datepicker [clearable]="true" [textoPlaceholder]="'Fecha inicio'"
                (dataFromPicker)="getFiltersProductionReport($event, 'fechaIni')"></datepicker>
            </div>
            <div class="width10vw">
              <datepicker [clearable]="true" [textoPlaceholder]="'Fecha fin'"
                (dataFromPicker)="getFiltersProductionReport($event, 'fechaFin')"></datepicker>
            </div>
            <div class="width10vw">
              <datepicker [clearable]="true" [textoPlaceholder]="'Fecha entrega inicio'"
                (dataFromPicker)="getFiltersProductionReport($event, 'fechaEntregaIni')"></datepicker>
            </div>
            <div class="width10vw">
              <datepicker [clearable]="true" [textoPlaceholder]="'Fecha entrega fin'"
                (dataFromPicker)="getFiltersProductionReport($event, 'fechaEntregaFin')"></datepicker>
            </div>
          </div>
        </ng-container>
      </filterActions>

      <div class="download" *ngIf="selectedList === false">
        <btn [classList]="'iconBtn'" class="fitContent" (click)="downloadFlag()">
          <ng-container>
            <mat-icon class="mat-icon-left">file_download</mat-icon>
          </ng-container>
          <span>Descargar</span>
        </btn>
      </div>
    </div>

    <div class="content">


      <ng-container *ngIf="selected === 0">
        <ERP-PP-Formulas [filtroFamilia]="filtroFamilia" [filtroSubfamilia]="filtroSubfamilia"
          [filtroBusqueda]="filtroBusqueda " [flagRefresh]="flagRefresh"></ERP-PP-Formulas>
      </ng-container>
      <ng-container *ngIf="selected === 1">
        <ERP-PP-PartesProduccion [filterProductionReport]="filterProductionReport" [flagRefreshParte]="flagRefreshParte"
          [downloadFlagEvent]="downloadFlagEvent" [selectedList]="selectedList"></ERP-PP-PartesProduccion>
      </ng-container>




    </div>

  </div>

</FullContainer>