import { iBaseObject } from "@quasar_dynamics/basic-designsystem";
import { iCompany } from "./iCompany";
import { iArticle } from "./iArticle";

export interface iRatesCustomer {
    id: number,
    price?: number,
    surcharge?: number,
    factor?: number,
    company?: iCompany,
    article?: iArticle,
}

export class iRatesCustomer extends iBaseObject {
    static convertFromCollection(ob: any): Array<iRatesCustomer> {
        let iRatesCustomerCollection: Array<iRatesCustomer> = [];
        ob.forEach((element: any) => {
            iRatesCustomerCollection.push(this.convertFromObject(element));
        });
        return iRatesCustomerCollection;
    }

    static convertFromObject(ob: any): iRatesCustomer {
        let iRatesCustomerObj = new iRatesCustomer();
        iRatesCustomerObj.id = ob.id;
        iRatesCustomerObj.price = ob.price;
        iRatesCustomerObj.surcharge = ob.surcharge;
        iRatesCustomerObj.factor = ob.factor;
        iRatesCustomerObj.company = ob.company ? iCompany.convertFromObject(ob.company) : iCompany.createEmptyObject();
        iRatesCustomerObj.article = ob.article ? iArticle.convertFromObject(ob.article) : iArticle.createEmptyObject();
        return iRatesCustomerObj;
    }

    static createEmptyObject(): iRatesCustomer {
        let iRatesCustomerObj = new iRatesCustomer();
        iRatesCustomerObj.price = -1;
        iRatesCustomerObj.surcharge = -1;
        iRatesCustomerObj.factor = -1;
        iRatesCustomerObj.company = new iCompany();
        iRatesCustomerObj.article = new iArticle();
        return iRatesCustomerObj;
    }
}

export interface iRatesCustomerFilter {
    num_devoluciones: number;
    num_pagina: number;
    genericFilter?: string;
    price?: number,
    surcharge?: number,
    factor?: number,
    company?: number,
    article?: number,
}