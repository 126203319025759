<FullContainer>
  <div class="fullView">
    <div class="headerAndArchivar">
      <div class="header">
        <button class="goBack" (click)="goBack()">
          <ng-container>
            <mat-icon>
              arrow_back
            </mat-icon>
          </ng-container>
        </button>
        <p>CLIENTES / <span>INDUSMETAL TORRES, S.L.</span></p>
      </div>
    </div>
    <div class="archivador">
      <accordion [title]="'FICHA'">
        <ng-container>
          <ERP-Cliente-Detail-Ficha (refresh)="refresh($event)" [data]="data" class="fullWidth"></ERP-Cliente-Detail-Ficha>
        </ng-container>
      </accordion>
    </div>

    <div class="accordionInformacionComercial">
      <accordion class="" [title]="'DIRECCIONES DE ENVÍO ALTERNATIVAS'">
        <ERP-CLIENTE-Detail-DireccionesAlternativas (refresh)="refresh($event)" [data]="data" class="fullWidth"></ERP-CLIENTE-Detail-DireccionesAlternativas>
      </accordion>
    </div>

    <div class="accordionInformacionComercial">
      <accordion class="borderTopBottom" [title]="'FICHA DE PRODUCTOS'">
        <ERP-Cliente-Detail-FichaDeProductos [data]="data" class="fullWidth"></ERP-Cliente-Detail-FichaDeProductos>
      </accordion>
    </div>

    <div class="accordionInformacionComercial">
      <accordion class="" [title]="'HISTÓRICO DE PEDIDOS'">
        <ERP-Cliente-Detail-HistoricoPedidos [data]="data" class="fullWidth"></ERP-Cliente-Detail-HistoricoPedidos>
      </accordion>
    </div>

    <div class="accordionInformacionComercial">
      <accordion class="" [title]="'CONTABILIDAD'">
        <ERP-Cliente-Detail-Contabilidad [clienteId]="clienteId" class="fullWidth"></ERP-Cliente-Detail-Contabilidad>
      </accordion>
    </div>

  </div>
</FullContainer>