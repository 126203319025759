<div class="fichaGeneralContainer" *ngIf="data.length !== 0">
  <pill>
    <ng-container>
      <div class="info">
        <span class="material-icons icon" (click)="edit('datos')">
          edit
        </span>
        <div class="row">
          <div class="column">
            <label for="">Código:</label>
            <span>{{data.code}}</span>
          </div>
          <div class="column">
            <label for="">Nombre:</label>
            <span>{{data.name}}</span>
          </div>
        </div>
        <div class="row">
          <div class="column">
            <label for="">N.I.F:</label>
            <span>{{data.nif}}</span>
          </div>
          <div class="column">
            <label for="">Dirección:</label>
            <span>{{data?.companyAddress[0]?.street}}</span>
          </div>
        </div>
        <div class="row">
          <div class="column">
            <label for="">Municipio:</label>
            <span>{{data?.companyAddress[0]?.municipality}}</span>
          </div>
          <div class="column">
            <label for="">Código postal:</label>
            <span>{{data?.companyAddress[0]?.postalCode}}</span>
          </div>
        </div>
        <div class="row">
          <div class="column">
            <label for="">Provincia:</label>
            <span>{{data?.companyAddress[0]?.province}}</span>
          </div>
          <div class="column">
            <label for="">País:</label>
            <span>{{data?.companyAddress[0]?.country}}</span>
          </div>
        </div>
        <div class="row">
          <div class="column special">
            <label for="">Teléfonos:</label>
            <div>
              <span class="pill">{{data.phones[0]}}</span>
              <span class="pill">{{data.phones[1]}}</span>
              <span class="pill">{{data.phones[2]}}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="column special">
            <label for="">E-mail:</label>
            <div>
              <span class="pill">{{data.emails[0]}}</span>
              <span class="pill">{{data.emails[1]}}</span>
              <span class="pill">{{data.emails[2]}}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="column special">
            <label for="">E-mail pedidos:</label>
            <div>
              <span class="pill">{{data.orderEmails}}</span>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </pill>
  <pill>
    <ng-container>
      <div class="info">
        <span class="material-icons icon" (click)="edit('pago')">
          edit
        </span>
        <div class="row">
          <div class="column special">
            <label for="">Forma de pago:</label>
            <span>{{data.paymentMethods[0]?.wayToPay?.description}}</span>
          </div>
        </div>
        <div class="row">
          <div class="column special">
            <label for="">IBAN:</label>
            <span>{{data.paymentMethods[0]?.iban}}</span>
          </div>
        </div>
        <div class="row">
          <div class="column special">
            <label for="">BIC:</label>
            <span>{{data.paymentMethods[0]?.bic}}</span>
          </div>
        </div>
        <div class="row">
          <div class="column special">
            <label for="">Tipo IVA:</label>
            <span>{{data.paymentMethods[0]?.typeIva?.description}}</span>
          </div>
        </div>
        <div class="row">
          <div class="column special">
            <label for="">Recargo de equivalencia:</label>
            <span>{{data.paymentMethods[0]?.surcharge ? 'Sí': 'No'}}</span>
          </div>
        </div>
        <div class="row">
          <div class="column special">
            <label for="">Tarifa asignada:</label>
            <span>{{data.paymentMethods[0]?.tarifa}}</span>
          </div>
        </div>
        <div class="row">
          <div class="column special">
            <label for="">Riesgo económico máx. aceptado:</label>
            <span>{{data.paymentMethods[0]?.maxRisk}}</span>
          </div>
        </div>
        <div class="row">
          <div class="column special">
            <label for="">Portes:</label>
            <span>{{data.porte?.name}}</span>
          </div>
        </div>
      </div>
    </ng-container>
  </pill>
  <pill>
    <ng-container>
      <div class="info">
        <span class="material-icons icon" (click)="edit('observaciones')">
          edit
        </span>
        <div class="row">
          <div class="column special">
            <label for="">Observaciones:</label>
            <span>{{data.observation}}</span>
          </div>
        </div>
      </div>
    </ng-container>
  </pill>
  <pill>
    <ng-container>
      <div class="info">
        <div class="icons">
          <span class="material-icons icon" (click)="edit('agente')">
            edit
          </span>
        </div>

        <div class="row">
          <div class="column special" *ngFor="let agent of agentes">
            <label for="">Agente:</label>
            <span>{{agent.name}}</span>
          </div>
        </div>
      </div>
    </ng-container>
  </pill>
</div>
