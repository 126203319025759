import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'InformacionComercial',
  templateUrl: './InformacionComercial.component.html',
  styleUrls: ['./InformacionComercial.component.scss']
})
export class InformacionComercialComponent implements OnInit {

  informacionComercial=[
    {label:'Qué hacen : ',value:'Pintura comercial'},
    {label:'Clientes : ',value:'Ejemplo cliente 1, ejemplo cliente 2'},
    {label:'Sector : ',value:'Ejemplo sector'},
    {label:'Responsable de compras : ',value:'Ejemplo datos del responsable de compras'},
  ]

  constructor() { }

  ngOnInit() {
  }

}
