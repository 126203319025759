<div class="popupGeneralContainer">
  <div class="header">
    <mat-icon (click)="closePopup('edit')">close</mat-icon>
    <p *ngIf="modalData.accion=='editar'">Editar agente</p>
    <p *ngIf="modalData.accion=='crear'">Crear agente</p>
    <p *ngIf="modalData.accion=='ver'">Ver agente</p>
    <mat-icon (click)="closePopup('edit')">close</mat-icon>
  </div>
  <div class="body">
    <div class="tripleColumn">
      <CustomInput [readonly]="modalData.accion=='ver'" classList="fullWidth tall special" placeholder="Código" label="Código" [(ngModel)]="codigo" (change)="modalData.accion ==='editar'? editSecction(codigo, 'code') :checkIsCompleted()"></CustomInput>
      <CustomInput [readonly]="modalData.accion=='ver'" classList="fullWidth tall special" placeholder="Nombre" label="Nombre" [(ngModel)]="nombre" (change)="modalData.accion ==='editar'? editSecction(nombre, 'name') :checkIsCompleted()"></CustomInput>
      <CustomInput [readonly]="modalData.accion=='ver'" classList="fullWidth tall special" placeholder="NIF" label="NIF" [(ngModel)]="nif" (change)="modalData.accion ==='editar'? editSecction(nif, 'nif') :checkIsCompleted()"></CustomInput>
    </div>
    <div class="tripleColumn">
      <CustomInput [readonly]="modalData.accion=='ver'" classList="fullWidth tall special" placeholder="Email" label="Email" [(ngModel)]="email" (change)="modalData.accion ==='editar'? editSecction(email, 'email') :checkIsCompleted()"></CustomInput>
      <CustomInput [readonly]="modalData.accion=='ver'" classList="fullWidth tall special" placeholder="Teléfono 1" label="Teléfono 1" [(ngModel)]="telefono1" (change)="modalData.accion ==='editar'? editSectionAddress(telefono1, 'telefono1') :checkIsCompleted()"></CustomInput>
      <CustomInput [readonly]="modalData.accion=='ver'" classList="fullWidth tall special" placeholder="Teléfono 2"  label="Teléfono 2" [(ngModel)]="telefono2" (change)="modalData.accion ==='editar'? editSectionAddress(telefono2, 'telefono2') :checkIsCompleted()"></CustomInput>
    </div>
    <div class="tripleColumn">
      <CustomInput [readonly]="modalData.accion=='ver'" classList="fullWidth tall special" placeholder="Dirección" label="Dirección" [(ngModel)]="direccion" (change)="modalData.accion ==='editar'? editSectionAddress(direccion, 'street') :checkIsCompleted()"></CustomInput>
      <CustomInput [readonly]="modalData.accion=='ver'" classList="fullWidth tall special" placeholder="Municipio" label="Municipio" [(ngModel)]="municipio" (change)="modalData.accion ==='editar'? editSectionAddress(municipio, 'municipality') :checkIsCompleted()"></CustomInput>
      <CustomInput [readonly]="modalData.accion=='ver'" classList="fullWidth tall special" placeholder="Cód. Postal" label="Cód. Postal" [(ngModel)]="codigoPostal" (change)="modalData.accion ==='editar'? editSectionAddress(codigoPostal, 'postalCode') :checkIsCompleted()"></CustomInput>
    </div>
    <div class="tripleColumn">
      <div class="customContainer" *ngIf="modalData.accion !== 'ver'">
        <label for="Provincia">Provincia</label>
        <selector [options]="opcionesProvincias" [(ngModel)]="provincia" (change)="modalData.accion ==='editar'? getSelectorAddress(provincia,'province') :checkIsCompleted()"></selector>
      </div>
      <CustomInput [readonly]="modalData.accion=='ver'" classList="fullWidth tall special" placeholder="Provincia" [label]="'Provincia'" [(ngModel)]="provincia" *ngIf="modalData.accion == 'ver'"></CustomInput>
      <div class="customContainer" *ngIf="modalData.accion !== 'ver'">
        <label for="pais">Paises</label>
        <selector [options]="opcionesPais" [(ngModel)]="pais" (change)="getSelectorAddress(pais,'country')" ></selector>
      </div>
      <CustomInput [readonly]="modalData.accion=='ver'" classList="fullWidth tall special" placeholder="País" [label]="'País'" [(ngModel)]="pais" *ngIf="modalData.accion == 'ver'"></CustomInput>
      <CustomInput [readonly]="modalData.accion=='ver'" [type]="'number'" classList="fullWidth tall special" placeholder="% Comisión" label="% Comisión" [(ngModel)]="comision" (change)="modalData.accion ==='editar'? editSecction(comision, 'comision') :checkIsCompleted()"></CustomInput>
    </div>
    <div class="customContainer" *ngIf="modalData.accion !== 'ver'">
      <label for="">Clientes asignados</label>
      <selector [options]="opcionesClienteAsignados" [(ngModel)]="clientes" (change)="modalData.accion ==='editar'? getSelectorAgent(clientes, 'company') :checkIsCompleted()"></selector>
    </div>
    <CustomInput [readonly]="modalData.accion=='ver'" classList="fullWidth tall special" placeholder="Clientes asignados" [label]="'Clientes asignados'" [(ngModel)]="clientes" *ngIf="modalData.accion == 'ver'"></CustomInput>
  </div>
  <div class="footer" *ngIf="modalData.accion!='ver'">
    <btn classList="secundario" (click)="forceClosePopup()">
      <ng-container>
       Cerrar
      </ng-container>
    </btn>
    <btn classList="secundaryButton" (click)="create()" [class.disabled]="!isCompleted" *ngIf="modalData.accion=='crear'">
      <ng-container>
        Crear
      </ng-container>
    </btn>
    <btn classList="secundaryButton" (click)="closePopup('edit')" [class.disabled]="!isCompleted" *ngIf="modalData.accion=='editar'">
      <ng-container >
        Editar
      </ng-container>
    </btn>
  </div>
</div>
