<Pagination [paginacionTotal]="totalPaginacion" (change)="changePagination($event)" *ngIf="selectedList"></Pagination>
<pill class="tablePil" classList="tablePill" *ngIf="selectedList">
  <dataTable [forceUpdateWidth]="forceUpdateWidth" [data]="dataForTable" [isLoading]="isLoading" [options]="optionsTabla" [displayedColumns]="displayedColumnsTabla" (edit)="edit($event)" (delete)="delete($event,'simple')" (deleteAll)="delete(null,'all')" (deleteSelected)="delete($event,'selected')" (clickRow)="see($event)" ></dataTable>
</pill>



<div class="pipelineMono" *ngIf="!selectedList">
  <div class="container">
    <h2 class="headers left">Ms100 <p>{{Ms100.length}}</p>  <div class="image"></div>  </h2>
    <div
      id="Ms100"
      cdkDropList
      [cdkDropListData]="Ms100"
      class="list"
      (cdkDropListDropped)="drop($event)"
      [cdkDropListEnterPredicate]="noReturnPredicate">
      <div
        class="box"
        *ngFor="let number of Ms100"
        [cdkDragData]="number"
        cdkDrag>
        <p>{{number.code}}</p>
        <div class="flex">
          <p>{{number.formula.code}}</p>
          <p>-</p>
          <p class="bold">{{number.formula.name}}</p>
        </div>
        <p>{{number.formula.finalObservation}}</p>
        <div class="between">
          <p>{{number.date | date:'dd/MM/yyyy'}}</p>
          <p class="bold">{{number.amount}}</p>
        </div>

      
      </div>
    </div>
  </div>
  <div class="container">
    <h2 class="headers right">Ms400 <p>{{Ms400.length}}</p> </h2>
    <div
      id="Ms400"
      cdkDropList
      [cdkDropListData]="Ms400"
      class="list"
      (cdkDropListDropped)="drop($event)"
      >
      <div
        class="box"
        *ngFor="let number of Ms400"
        [cdkDragData]="number"
        cdkDrag>
        <p>{{number.code}}</p>
        <div class="flex">
          <p>{{number.formula.code}}</p>
          <p>-</p>
          <p class="bold">{{number.formula.name}}</p>
        </div>
        <p>{{number.formula.finalObservation}}</p>
        <div class="between">
          <p>{{number.date | date:'dd/MM/yyyy'}}</p>
          <p class="bold">{{number.amount}}</p>
        </div>
      
      </div>
    </div>
  </div>
</div>