import { Component, OnInit } from '@angular/core';
import { iOptionsSelector } from '@quasar_dynamics/basic-designsystem';

@Component({
  selector: 'facturacion',
  templateUrl: './Facturacion.component.html',
  styleUrls: ['./Facturacion.component.scss']
})
export class FacturacionComponent implements OnInit {
  consumo: any = null;
  data: any = {
    cliente: null,
    producto: null,
    fechaIni: null,
    fechaFin: null,
    facturacion: "Anual",
  }
  opcionesConsumo: iOptionsSelector = {
    items: ["Facturación en KG/€", "Facturación en L/€"],
    clearable: true,
    search: false,
    placeholder: "Facturación",
    append: true,
  }
  opcionesClientes: iOptionsSelector = {
    items: [{key: 1, value: "Top 15"}, {key: 2, value: "Cliente test"}],
    clearable: true,
    search: true,
    placeholder: "Cliente",
    append: true,
    bindLabel: "value",
  }
  opcionesProductos: iOptionsSelector = {
    items: ["Producto 1"],
    clearable: true,
    search: true,
    placeholder: "Productos",
    append: true,
  }
  opcionesFacturacion: iOptionsSelector = {
    items: ["Anual", "Mensual"],
    search: true,
    placeholder: "Facturación",
    append: true,
  }

  constructor() { }

  ngOnInit() {
  }

}
