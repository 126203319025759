import { iBaseObject } from "@quasar_dynamics/basic-designsystem";
import { iCategoryNotice } from "./iCategoryNotice";

export interface iNotice {
    id: number,
    description?: string,
    isActive?: boolean,
    category?: iCategoryNotice,
}

export class iNotice extends iBaseObject {
    static convertFromCollection(ob: any): Array<iNotice> {
        let iNoticeCollection: Array<iNotice> = [];
        ob.forEach((element: any) => {
            iNoticeCollection.push(this.convertFromObject(element));
        });
        return iNoticeCollection;
    }

    static convertFromObject(ob: any): iNotice {
        let iNoticeObj = new iNotice();
        iNoticeObj.id = ob.id;
        iNoticeObj.description = ob.description;
        iNoticeObj.isActive = ob.isActive;
        iNoticeObj.category = ob.category ? iCategoryNotice.convertFromObject(ob.category) : iCategoryNotice.createEmptyObject();
        return iNoticeObj;
    }

    static createEmptyObject(): iNotice {
        let iNoticeObj = new iNotice();
        iNoticeObj.description = '';
        iNoticeObj.isActive = false;
        iNoticeObj.category = iCategoryNotice.createEmptyObject();
        return iNoticeObj;
    }
}

export interface iNoticeFilter {
    num_devoluciones: number;
    num_pagina: number;
    genericFilter?: string;
    description?: string,
    isActive?: boolean,
    category?: number,
}