<pill>
  <ng-container>
    <div class="fullPill">
      <div class="iconLine">
      </div>
      <div class="headerRow">
        <p class="bold">Código material</p>
        <p class="bold">Código secundario</p>
        <p class="bold">Descripción</p>
        <p class="bold">Stock Real</p>
        <p class="bold">Stock Reserva</p>
        <p class="bold">Stock Pte.Rec</p>
        <p class="bold">Stock Virtual</p>
        <p class="bold">Cantidad</p>
      </div>

      <div class="contentRow" *ngFor="let data of dataForTable">
        <p *ngIf="!data.indication" [ngClass]="{rojo: data.materiaPrima, naranja: data.pedido}">{{data.articleCode || data.rawCode}}</p>
        <p *ngIf="!data.indication" [ngClass]="{rojo: data.materiaPrima, naranja: data.pedido}">{{data.rawSecondaryCode}}</p>
        <p *ngIf="!data.indication" [ngClass]="{rojo: data.materiaPrima, naranja: data.pedido}">{{data.articleName || data.rawName}} </p>
        <p *ngIf="!data.indication" [ngClass]="{rojo: data.materiaPrima, naranja: data.pedido}">{{data.articleRealStock || data.rawRealStock }}</p>
        <p *ngIf="!data.indication" [ngClass]="{rojo: data.materiaPrima, naranja: data.pedido}">{{data.articleReservedStock || data.rawReservedStock}}</p>
        <p *ngIf="!data.indication" [ngClass]="{rojo: data.materiaPrima, naranja: data.pedido}">{{data.articlePendingStock || data.rawPendingStock }}</p>
        <p *ngIf="!data.indication" [ngClass]="{rojo: data.materiaPrima, naranja: data.pedido}">{{data.articleVirtualStock || data.rawVirtualStock}}</p>
        <p *ngIf="!data.indication" [ngClass]="{rojo: data.rawRealStock && data.batchAmount > data.rawVirtualStock && data.batchAmount > (data.rawPendingStock + data.rawPendingStock)  , naranja: data.rawRealStock && data.batchAmount > data.rawVirtualStock && data.rawPendingStock && data.batchAmount > (data.rawPendingStock + data.rawPendingStock)} " >{{data.batchAmount}}</p>
        <div class="description" *ngIf="data.indication">
          <p>{{data.indication}}</p>
        </div>
      </div>
      <div class="total">
        <p class="bold">TOTAL : </p>
        <p>{{total}}</p>
      </div>

      <div class="observacionesFinales">
        <p class="bold">Observaciones Finales:</p>
        <p>{{observacionesFinales}}</p>
      </div>
    </div>
  </ng-container>
</pill>