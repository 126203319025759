import { Component, EventEmitter, Inject, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { iDate, iOptionsSelector, iUnsubscribeDestroy } from '@quasar_dynamics/basic-designsystem';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';
import { NuevoParteDeProduccionPopupComponent } from '../../NuevoParteDeProduccion-Popup.component';
import { ProductionReportService } from 'src/app/Services/Api/ProductionReport.service';
import { takeUntil } from 'rxjs';
import { FormulaService } from 'src/app/Services/Api/Formula.service';

@Component({
  selector: 'NuevoPartePopup-Ficha',
  templateUrl: './NuevoPartePopup-Ficha.component.html',
  styleUrls: ['./NuevoPartePopup-Ficha.component.scss']
})
export class NuevoPartePopupFichaComponent extends iUnsubscribeDestroy implements OnInit {

  @Output() _isCompleted = new EventEmitter<boolean>()
  @Output() _dataFicha = new EventEmitter<any>()
  @Input() data: any

  isCompleted: boolean = false;
  areaProduccion: any = null;

  opcionesProductos: iOptionsSelector = {
    items: [],
    clearable: true,
    search: true,
    bindLabel: 'code',
    placeholder: "Productos",
    append: true
  }
  opcionesAreaProduccion: iOptionsSelector = {
    items: [
      { id: 1, label: 'MS 100' },
      { id: 2, label: 'MS 400' },
    ],
    clearable: true,
    search: true,
    placeholder: "Area de producción",
    append: true
  }

  constructor(public dialogRef: MatDialogRef<NuevoParteDeProduccionPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any, private productionReportSE: ProductionReportService, private formulaSE:FormulaService) {
    super();
  }

  ngOnInit() {
    this.getAllFormulas()
  }

  ngOnChanges(changes:SimpleChanges){
    if(changes['data']){
      if(changes['data'].currentValue){
        this.isCompleted = true
      }
    }
  }

  /**
* FUNCTIONALITY
*/
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }

  /**
   * CHECK COMPLETED
   */

  checkIsCompleted() {
    let check = this.data 
     
    delete this.data.areaProduccion
    this.isCompleted = StaticUtilitiesService.isCompleteObject(check)
    this._isCompleted.emit(this.isCompleted)
    if (this.isCompleted) {
      this.data.areaProduccion = (this.areaProduccion)?this.areaProduccion?.id:null
      this._dataFicha.emit(this.data);

    }
  }

  /**
   * DATEPICKER
   */

  dataFromPicker(event: any) {
    this.data.fechaEntrega = event;
    this.checkIsCompleted();
  }

  /**
   * API CALLS
   */


  getAllFormulas() {
    this.formulaSE.getFromulaTabla({ num_devoluciones: 522222, num_pagina: 1 });
    this.formulaSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe((res) => {
      if (!res) return;
      let { data } = res.data;
      this.opcionesProductos.items = data
      this._unsubInd.next('')
    })
  }

}
