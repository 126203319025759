import { Component, OnInit } from '@angular/core';
import { PopupService, slideNavigationTypes } from '@quasar_dynamics/basic-designsystem';
import { ConfirmPopupComponent } from 'src/app/Popups/ConfirmPopup/ConfirmPopup.component';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'app-CPotencialesDetailView',
  templateUrl: './CPotencialesDetailView.component.html',
  styleUrls: ['./CPotencialesDetailView.component.scss']
})
export class CPotencialesDetailViewComponent implements OnInit {

  constructor(private staticUtilitiesSE:StaticUtilitiesService, private popupSE:PopupService) { }

  ngOnInit() {
  }

  goBack(){
    this.staticUtilitiesSE.goTo('crm/clientes/', slideNavigationTypes.slideToBottom)
  }

  transformar(key:string){
    this.popupSE.openPopup(ConfirmPopupComponent, { accion: key, elemento: 'el cliente', crm: true});
  }
}
