<FullContainer>
  <div class="fullView">
    <div class="headerAndArchivar">
      <div class="header">
        <button class="goBack" (click)="goBack()">
          <ng-container>
            <mat-icon>
              arrow_back
            </mat-icon>
          </ng-container>
        </button>
        <p>CLIENTES / <span>INDUSMETAL TORRES, S.L.</span></p>
      </div>
      <div class="buttonArchivar">
        <btn classList="transformarEnCAntiguo" (click)="transformarEnCAntiguo()">Transformar en C.Antiguo</btn>
      </div>
    </div>
    <div class="archivador">
      <accordion [title]="'INFORMACION DEL CLIENTE'">
        <ng-container>
          <InformacionDeCliente class="fullWidth"></InformacionDeCliente>
        </ng-container>
      </accordion>
    </div>

    <div class="accordionInformacionComercial">
      <accordion class="" [title]="'INFORMACION COMERCIAL'">
        <InformacionComercial class="fullWidth"></InformacionComercial>
      </accordion>
    </div>

    <div class="accordionInformacionComercial">
      <accordion class="borderTopBottom" [title]="'ACCIONES PENDIENTES'">
      </accordion>
    </div>

    <div class="accordionInformacionComercial">
      <accordion class="" [title]="'DATOS DE CONSUMO'">
        <DatosDeConsumo class="fullWidth"></DatosDeConsumo>
      </accordion>
    </div>

    <div class="accordionInformacionComercial">
      <accordion class="" [title]="'INCIDENCIAS'">
        <Incidencias class="fullWidth"></Incidencias>
      </accordion>
    </div>

    <div class="accordionInformacionComercial">
      <accordion class="" [title]="'FICHA DE PRODUCTOS'">
        <FichaDeProducto class="fullWidth"></FichaDeProducto>
      </accordion>
    </div>

    <div class="accordionInformacionComercial">
      <accordion class="" [title]="'HISTÓRICO PEDIDOS'">
        <HistoricoPedidos class="fullWidth"></HistoricoPedidos>
      </accordion>
    </div>

  </div>
</FullContainer>