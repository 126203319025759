import { Component, OnInit } from '@angular/core';
import { iUnsubscribeDestroy, NotificationsService } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { LoginService } from 'src/app/Services/Api/Login.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'app-ForgotPassword',
  templateUrl: './ForgotPassword.component.html',
  styleUrls: ['./ForgotPassword.component.scss']
})
export class ForgotPasswordComponent extends iUnsubscribeDestroy implements OnInit {

  email: string = ''
  isComplete: boolean = false;

  constructor( private notificationSE: NotificationsService, private loginSE:LoginService) {
    super();
  }

  ngOnInit() {
  }

  isValidEmail(email) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }
  
  
  checkComplete(){
    if(!this.email){
      this.isComplete = false;
    }
    this.isComplete = true;
  }

  sendCredentials(){
    if(!this.isValidEmail(this.email)){
      StaticUtilitiesService.showError('El correo electrónico no es válido')
      return;
    }
    this.loginSE.recoverAccount(this.email);
    this.loginSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe((res:any)=>{
      if(!res){return;}
      this.notificationSE.showFeedBack('Se ha enviado un correo a tu cuenta de correo electrónico')
      this._unsubInd.next('');
    })
    this.loginSE.getResultError().pipe(takeUntil(this._unsub)).subscribe((res:any)=>{
      if(!res){return;}
      this.notificationSE.showError(res.message)
      this._unsub.next('');
    })
  }

}
