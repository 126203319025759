<div class="fullAccordion">

  <!-- <div class="pill">
    <div class="row">
      <p class="bold">Descripción</p>
      <p class="bold align-text-center">Valor</p>
    </div>
    <div class="row" *ngFor="let data of dataForTable">
      <p>{{data.characteristic.description}}</p>
      <p class="align-text-center" *ngIf="data.value != 0">{{data.value}}</p>
      <p class="align-text-center" *ngIf="data.value == 0">-</p>
    </div>
  </div> -->

  <pill class="tablePil" classList="tablePill">

  <dataTable [data]="dataForTable" [isLoading]="isLoading" [options]="optionsTabla" [displayedColumns]="displayedColumnsTabla" ></dataTable>

  </pill>



</div>