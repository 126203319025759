import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { iDataTableColumns, iDataTableOptions, iOptionsSelector, iUnsubscribeDestroy, PopupService } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { ConfirmPopupComponent } from 'src/app/Popups/ConfirmPopup/ConfirmPopup.component';
import { NuevaSubfamiliasPopupComponent } from 'src/app/Popups/NuevaSubfamilias-Popup/NuevaSubfamilias-Popup.component';
import { FamilyService } from 'src/app/Services/Api/Family.service';
import { SubfamilyService } from 'src/app/Services/Api/Subfamily.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';
import { iSubfamilyFilter } from 'src/app/Shared/Interfaces/iSubfamily';
import * as XLSX from 'xlsx';


@Component({
  selector: 'erpMantenimientosDetallesSubfamilias',
  templateUrl: './ERP-Mantenimientos-Detalles-Subfamilias.component.html',
  styleUrls: ['./ERP-Mantenimientos-Detalles-Subfamilias.component.scss']
})
export class ERPMantenimientosDetallesSubfamiliasComponent extends iUnsubscribeDestroy implements OnInit {

  opcionesCodigos: iOptionsSelector = {
    items: [],
    clearable: true,
    search: true,
    bindValue: "label",
    placeholder: "Codigo",
    append: true
  }
  codigo: string | null = null;
  filter: iSubfamilyFilter = {
    num_devoluciones: 20,
    num_pagina: 1,
    code: '',
    description: '',
    note: '',
    family: 0,
    genericFilter: '',
  }
  totalPaginacion: number = 0;

  opcionesFamilias: iOptionsSelector = {
    items: [],
    clearable: true,
    bindValue: "id",
    search: true,
    placeholder: "Familia",
    append: true
  }
  familia: string | null = null;

  isLoading: boolean = false;

  //Tabla
  displayedColumnsTabla: Array<iDataTableColumns> = [
    { label: 'Código', property: 'code' },
    { label: 'Descripción', property: 'description' },
    { label: 'Familia', property: 'familyCode' },
    { label: 'Notas', property: 'note' },
  ];

  dataForTable = []

  optionsTabla: iDataTableOptions = {
    isSelectable: true,
    isHovered: true,
    canSee: true,
    canEdit: true,
    canDelete: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc',
    moreIconsHead: [{ callback: (event) => { this.exportexcel() }, icon: "download" }],
  }
  dataForTableFiltered: Array<any> = this.dataForTable;


  constructor(private router: Router, private staticUtilitiesSE: StaticUtilitiesService, private popupSE: PopupService, private subFamilySE: SubfamilyService, private familySE: FamilyService) {
    super()
  }

  ngOnInit() {
    this.getAllSubfamilies()
  }
  /**
   * GETTERS & SETTERS
   */
  getSelector(event, key: string) {
    if (!event) { this.filter[key] = ''; this.getAllSubfamilies(); return }
    this.filter[key] = event
    this.getAllSubfamilies()
  }
  search(event) {
    if (!event) { this.filter.genericFilter = ''; this.getAllSubfamilies(); return }
    this.filter.genericFilter = event
    this.getAllSubfamilies()
  }
  /**
  * FUNCTIONALITIES
  */
  edit(event: any) {
    this.popupSE.openPopup(NuevaSubfamiliasPopupComponent, { data: event, accion: "editar" });
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe((res) => {
      if (!res) { return }
      this.getAllSubfamilies()
      this.getAllFamiliesForFilter()
      this._unsubInd2.next("")
    })
  }
  create() {
    this.popupSE.openPopup(NuevaSubfamiliasPopupComponent, { data: null, accion: "crear" });
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe((res) => {
      if (!res) { return }
      this.getAllSubfamilies()
      this.getAllFamiliesForFilter()
      this._unsubInd2.next("")
    })
  }
  see(event: any) {
    this.popupSE.openPopup(NuevaSubfamiliasPopupComponent, { data: event, accion: "ver" });

  }
  delete(event: any, type: String) {
    let ids: any = []
    switch (type) {
      case "single":
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'la familia' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe((data) => {
          if (!data) { return }
          ids = [event.id]
          this.deleteSubfamily(ids)
          this._unsubInd2.next("")
        })
        break;
      case "selected":
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'las subfamilias seleccionadas' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe((data) => {
          if (!data) { return }
          ids = event.map((item) => { return item.id })
          this.deleteSubfamily(ids)
          this._unsubInd2.next("")
        })
        break;
      case "all":
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'todas las subfamilias' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe((data) => {
          if (!data) { return }
          ids = this.dataForTableFiltered.map((item) => { return item.id })
          this.deleteSubfamily(ids)
          this._unsubInd2.next("")
        })
        break;
    }
  }
  changePagination(event: any) {
    if (event === 'next') {
      this.filter.num_pagina++;
      this.getAllSubfamilies();
    } else {
      this.filter.num_pagina--;
      this.getAllSubfamilies();
    }
  }
  /**
* DESCARGA DE PARTES EN EXCEL
*/

  exportexcel(): void {
    let table = document.createElement("table")
    table.append((document.querySelector("dataTable .tableHeader thead") as HTMLElement).cloneNode(true))
    table.querySelector("thead .iconsActions")?.remove();
    table.querySelector("thead .checkboxContainer")?.remove();
    let rows = Array.from(document.querySelectorAll("dataTable .tableBody tbody tr "));
    let filteredRows = rows.filter((row) => {
      return row.querySelector('.checkboxContainer mat-checkbox ')!.getAttribute('ng-reflect-checked') === "true"
    })
    if (filteredRows.length > 0) {
      filteredRows.forEach((row) => {
        let newRow = row.cloneNode(true) as HTMLElement;
        newRow.querySelector('td:last-of-type')?.remove();
        newRow.querySelector('td:first-of-type')?.remove();
        table.append(newRow)
      })
    } else {
      table.append((document.querySelector("dataTable .tableBody tbody") as HTMLElement).cloneNode(true))
      table.querySelectorAll("tbody tr td:last-of-type").forEach(element => {
        element.remove();
      })
      table.querySelectorAll("tbody tr td:first-of-type").forEach(element => {
        element.remove();
      })
    }
    /* pass here the table id */
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, 'subfamilia.xlsx');

  }

  /**
   * CALLS TO API
   */

  getAllSubfamilies() {
    this.isLoading = true;
    this.subFamilySE.getAll(this.filter);
    this.subFamilySE.getResult().pipe(takeUntil(this._unsubInd)).subscribe((res) => {
      if (!res) { return }
      let { data, total } = res.data;
      this.totalPaginacion = total;
      this.dataForTable = data.map((item) => {
        return { ...item, familyCode: item.family.code }
      });
      // if (this.opcionesCodigos.items.length === 0) {
        this.opcionesCodigos.items = data.map((item) => { return { id: item.code, label: item.code } })
      // }
      this.dataForTableFiltered = this.dataForTable;
      this.isLoading = false;
      this.getAllFamiliesForFilter()
      this._unsubInd.next("")
    })
  }
  deleteSubfamily(ids: number[]) {
    this.subFamilySE.delete(ids);
    this.subFamilySE.getResultDelete().pipe(takeUntil(this._unsubInd3)).subscribe((res) => {
      if (!res) { return }
      StaticUtilitiesService.showFeedback('Subfamilia eliminada correctamente')
      this.getAllSubfamilies()
      this._unsubInd3.next("")
    })
    this.subFamilySE.getResultDeleteError().pipe(takeUntil(this._unsub)).subscribe((res) => {
      if (!res) { return }
      StaticUtilitiesService.showError(res.message)
      this.getAllSubfamilies()
      this._unsub.next("")
    })
  }
  getAllFamiliesForFilter() {
    this.familySE.getAll({ num_devoluciones: 999, num_pagina: 1 });
    this.familySE.getResult().pipe(takeUntil(this._unsubInd4)).subscribe((res) => {
      if (!res) { return }
      let { data } = res.data;
      this.opcionesFamilias.items = data.map((item) => { return { id: item.id, label: item.code } })
      this._unsubInd4.next("")
    })
  }
}
