import { Component, OnInit } from '@angular/core';
import { iDataTableColumns, iDataTableOptions } from '@quasar_dynamics/basic-designsystem';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'CACondicionesEspeciales',
  templateUrl: './CACondicionesEspeciales.component.html',
  styleUrls: ['./CACondicionesEspeciales.component.scss']
})
export class CACondicionesEspecialesComponent implements OnInit {

  isLoading: boolean = false;

  //Tabla
  displayedColumnsTabla: Array<iDataTableColumns> = [
    { label: 'Artículo', property: 'articulo' },
    { label: 'Precio', property: 'precio' },
    { label: 'Fecha Oferta', property: 'fecha', align:'center' },
    { label: 'Unidad', property: 'unidad', align:'center',},
    { label: '%Datos', property: 'datos', align:'center'},
  ];

  dataForTable = [
    { id: 1, nPedido: '789', fecha:'08/05/2023', articulo:'Hucha', precio:'100', unidad: 'KG', datos: '', },
    { id: 2, nPedido: '789', fecha:'08/05/2023', articulo:'Hucha', precio:'100', unidad: 'KG', datos: '', },
    { id: 3, nPedido: '789', fecha:'08/05/2023', articulo:'Hucha', precio:'100', unidad: 'KG', datos: '', },
    { id: 4, nPedido: '789', fecha:'08/05/2023', articulo:'Hucha', precio:'100', unidad: 'KG', datos: '', },
    { id: 5, nPedido: '789', fecha:'08/05/2023', articulo:'Hucha', precio:'100', unidad: 'KG', datos: '', },
    { id: 6, nPedido: '789', fecha:'08/05/2023', articulo:'Hucha', precio:'100', unidad: 'KG', datos: '', },
    { id: 7, nPedido: '789', fecha:'08/05/2023', articulo:'Hucha', precio:'100', unidad: 'KG', datos: '', },
  ]

  optionsTabla: iDataTableOptions = {
    isSelectable: true,
    canEdit: true,
    canSee: true,
    moreIconsHead:[{icon:'download',callback:()=>{console.log('download')}}],
    canDelete: true,
    isHovered: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc'
  }


  constructor(private staticUtilitiesSE: StaticUtilitiesService) { }

  ngOnInit() {
    this.isLoading = true;
    setTimeout(() => {
      this.isLoading = false;
      
    }, 1000);
  }


}
