import { Injectable } from '@angular/core';
import { iDate, iUnsubscribeDestroy } from '@quasar_dynamics/basic-designsystem';
import * as pdfMake from "pdfmake/build/pdfmake";
import { BehaviorSubject, Observable } from 'rxjs';
import pdfFonts from "src/assets/Fonts/custom-fonts.js"; // The path of your custom fonts
pdfMake.vfs = pdfFonts.pdfMake.vfs;

(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;


@Injectable({
  providedIn: 'root'
})
export class GenerateAlbaranService extends iUnsubscribeDestroy {

  private _pdfGenerated: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  pdfAlbaran: any;

  constructor() {
    super();
  }

  public async generateAlbaran(data) {
    let filteredData: any = []
    let arrayRow: any = []
    data.arrayLineas.forEach((item, index) => {
      if (index === 0) {
        arrayRow = ['Nº Pedido', 'Referencia', 'Descripción', 'Cantidad',]
        filteredData.push(arrayRow)
        arrayRow = [item.orderId, item.code, item.description, item.quantity,]
        filteredData.push(arrayRow)

      }
      if (index > 0) {
        arrayRow = [item.orderId, item.code, item.description, item.quantity,]
        filteredData.push(arrayRow)
      }
    })
    data.filteredData = filteredData

     this.generatePDFAlbaran(data);
  }

  getPdf():Observable<any>{
    return this._pdfGenerated.asObservable();
  }



  getPdfAlbaran() {
     
    return this.pdfAlbaran;
  }

  private async generatePDFAlbaran(data) {

    const docDefinition = {
      header: {


      },
      footer: {
        columns: [
          {
            widths: ['25%', '75%'],
            image: await this.getBase64ImageFromURL('/assets/Icons/mopasaLogoRojo.png'),
            width: 100,
            alignment: 'left', // Alinea verticalmente el contenido de la columna al centro
            fit: [25, 25],
            margin: [40, 0, 0, 0],
          },
          {
            stack: [
              { text: 'Mopasa Barnices y Pintura', style: 'textoSmallBoldFooter', margin: [0, 0, 0, 0] },
              { text: 'P.I. El Plà, Parcela 38 · Tel.: 961 240 311 · Fax: 961 230 428', style: 'textoSmallFooter', margin: [0, 0, 0, 0] },
              { text: 'Apartado de Correos 15 · 46290 Alcàsser (Valencia) España', style: 'textoSmallFooter', margin: [0, 0, 0, 0] },
              { text: 'www.mopasa.com', style: 'textoSmallFooter', margin: [0, 0, 0, 0], color: 'red' },
            ], alignment: 'left',
            margin: [-20, 0, 0, 0]
          },
          {
            text: 'PRODUCTOS QUÍMICOS MOPASA S.L., Registro Mercantil de Valencia, folio 101 Libro de Sociedades, Sección 4ª Limitadas, hojas nº 2961. Inscripción 1ª - C.I.F. B46060448',
            alignment: 'left',
            style: 'textoFooterRight',
            margin: [0, 0, 50, 0],
            width: '60%',
          }
        ]
      },
      content: [
        {
          image: await this.getBase64ImageFromURL('/assets/Icons/mopasaLogoRojoCompleto.png'),
          fit: [150, 150],
          width: 100,
          alignment: 'right', // Alinea verticalmente el contenido de la columna al centro
        },
        {
          text: 'ALBARÁN DE ENTREGA',
          fontSize: 18,
          bold: true,
          margin: [0, -35, 0, 10],

        },
        {
          text:
            "Número " + data.numAlbaran,
          style: "subheader"
        },
        {
          text:
            iDate.javascriptConvert(new Date(data.date)).toStringDate('EU'),
          style: "subheader"
        },
        // {
        //   canvas: [
        //     {
        //       type: 'rect', // Agregamos un rectángulo
        //       x: 0,
        //       y: 10,
        //       w: 255, // El ancho del rectángulo
        //       h: 130, // El alto del rectángulo
        //       r: 10,
        //       color: '#f1f1f0'
        //     }
        //   ]
        // },
        // {
        //   canvas: [
        //     {
        //       type: 'rect', // Agregamos un rectángulo
        //       x: 0,
        //       y: 5,
        //       w: 255, // El ancho del rectángulo
        //       h: 100, // El alto del rectángulo
        //       r: 10,
        //       color: '#f1f1f0'
        //     }
        //   ]
        // },
        // {
        //   canvas: [
        //     {
        //       type: 'rect', // Agregamos un rectángulo
        //       x: 260,
        //       y: -235,
        //       w: 255, // El ancho del rectángulo
        //       h: 130, // El alto del rectángulo
        //       r: 10,
        //       color: '#f1f1f0'
        //     }
        //   ]
        // },
        // Cliente
        {
          text: 'CLIENTE',
          style: 'subheader',
          absolutePosition: { x: 50, y: 135 }
        },
        {
          text: data.name,
          style: 'subheader',
          absolutePosition: { x: 50, y: 150 }
        },
        {
          text: `${data.billingAddress.street}, ${data.billingAddress.number}, ${data.billingAddress.postalCode}`,
          style: 'textoNormal',
          absolutePosition: { x: 50, y: 175 }
        },
        {
          text: `${data.billingAddress.municipality}, ${data.billingAddress.province}, ${data.billingAddress.country}`,
          style: 'textoNormal',
          absolutePosition: { x: 50, y: 190 }
        },
        {
          text: 'Tél: ' + data.phones,
          style: 'textoNormal',
          absolutePosition: { x: 50, y: 205 }
        },
        {
          text: 'NIF: ' + data.nif,
          style: 'textoNormal',
          absolutePosition: { x: 50, y: 225 }
        },
        // Direccion de entrega
        {
          text: 'DIRECCIÓN DE ENTREGA',
          style: 'subheader',
          absolutePosition: { x: 310, y: 135 }
        },
        {
          text: data.name,
          style: 'subheader',
          absolutePosition: { x: 310, y: 150 }
        },
        {
          text: `${data.properAddress.street}, ${data.properAddress.number}, ${data.properAddress.postalCode}`,
          style: 'textoNormal',
          absolutePosition: { x: 310, y: 175 }
        },
        {
          text: `${data.properAddress.municipality}, ${data.properAddress.province}, ${data.properAddress.country}`,
          style: 'textoNormal',
          absolutePosition: { x: 310, y: 190 }
        },
        {
          text: 'Tél: ' + data.phones[0],
          style: 'textoNormal',
          absolutePosition: { x: 310, y: 205 }
        },
        // AGENTE
        {
          text: 'Envío:',
          style: 'subheader',
          absolutePosition: { x: 50, y: 265 }
        },
        {
          text: data.transportAgency,
          style: 'textoNormal',
          absolutePosition: { x: 80, y: 265 }
        },
        {
          text: 'S/ Referencia:',
          style: 'subheader',
          absolutePosition: { x: 50, y: 280 }
        },
        {
          text: data.sReference,
          style: 'textoNormal',
          absolutePosition: { x: 120, y: 280 }
        },
        {
          text: 'Tipo Portes:',
          style: 'subheader',
          absolutePosition: { x: 50, y: 295 }
        },
        {
          text: data.porte.name,
          style: 'textoNormal',
          absolutePosition: { x: 110, y: 295 }
        },
        {
          text: 'Peso Neto:',
          style: 'subheader',
          absolutePosition: { x: 50, y: 310 }
        },
        {
          text: data.peso + ' Kg',
          style: 'textoNormal',
          absolutePosition: { x: 100, y: 310 }
        },
        {
          text: 'Bultos:',
          style: 'subheader',
          absolutePosition: { x: 50, y: 325 }
        },
        {
          text: data.packages,
          style: 'textoNormal',
          absolutePosition: { x: 90, y: 325 }
        },
        {
          margin: [0, 140, 0, 0],
          layout: 'tableLayout',
          table: {
            headerRows: 1,
            fontSize: 8,
            widths: ['*', '*', 200, '*'],
            body:
              data.filteredData,
            styles: {
              fontSize: 8
            },
          }
        },
        {
          text: 'El responsable de la entrega del residuo de envase o envase usado para su correcta gestión ambiental, será el poseedor final (artículo 18.1, R.D. 782/98)',
          style: 'textoSmall',
          align: 'center',
          width: '100%',
          margin: [60, 10, 0, 0],
        },
        {
          text: data.note,
          style: 'textoNormal',
          align: 'center',
          width: '100%',
          margin: [0, 40, 0, 0],
        },
      ],
      styles: {
        header: {
          fontSize: 20,
          bold: true,
          margin: [0, 0, 0, 10]
        },
        subheader: {
          fontSize: 10,
          bold: true,
          margin: [0, 0, 0, 5]
        },
        textoNormal: {
          fontSize: 10,
          margin: [0, 0, 0, 5]
        },
        textoFooterRight: {
          fontSize: 7,
          margin: [0, 0, 0, 5]
        },
        textoSmall: {
          fontSize: 6,
          margin: [0, 0, 0, 5]
        },
        textoSmallFooter: {
          fontSize: 5,
          margin: [0, 0, 0, 5]
        },
        textoSmallBold: {
          bold: true,
          fontSize: 6,
          margin: [0, 0, 0, 5]
        },
        textoSmallBoldFooter: {
          bold: true,
          fontSize: 5,
          margin: [0, 0, 0, 5]
        },
        textoMedium: {
          fontSize: 8,
          margin: [0, 0, 0, 5]
        },
        table: {
          margin: [0, 5, 0, 15]
        },
      }
    };
    pdfMake.tableLayouts = {
      tableLayout: {
        fontSize: 10,
        fillColor: function (rowIndex, node, columnIndex) {
          return (rowIndex === 0) ? '#f1f1f0' : null;
        },
        vLineWidth: function (i, node) {

          return 1; // dibujar bordes verticales en todas las celdas
        },
        hLineWidth: function (i, node) {
          return (i === node.table.body.length || i === 0) ? 1 : 0; // dibujar bordes horizontales en todas las celdas
        },
        hLineColor: function (i, node) {
          return '#bfbfbf'; // color del borde horizontal
        },
        vLineColor: function (i, node) {
          return '#bfbfbf'; // color del borde vertical
        },
        paddingLeft: function (i, node) {
          return 10; // espacio interno de la celda izquierda
        },
        paddingRight: function (i, node) {
          return 10; // espacio interno de la celda derecha
        },
        paddingTop: function (i, node) {
          return 5; // espacio interno de la celda superior
        },
        paddingBottom: function (i, node) {
          return 5; // espacio interno de la celda inferior
        },
      }
    };
    pdfMake.createPdf(docDefinition).download("albarán.pdf");
    pdfMake.createPdf(docDefinition).getBase64((data) => {
      this.pdfAlbaran = ['data:application/pdf;base64,'+data];
       
      this._pdfGenerated.next(this.pdfAlbaran);
    });
  }

  private getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");

      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;

        var ctx = canvas.getContext("2d");
        ctx!.drawImage(img, 0, 0);

        var dataURL = canvas.toDataURL("image/png");

        resolve(dataURL);
      };
      img.onerror = error => {
        reject(error);
      };
      img.src = url;
    });
  }

}
