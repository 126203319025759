<div class="paginacion">
  <div class="numeroDeResultadosMostrados">{{numMin}}-{{numMax}} de {{paginacionTotal}}</div>
  <div class="arrows">
    <ng-container class="arrowLeft">
      <mat-icon (click)="previousPage()">
        chevron_left
      </mat-icon>
    </ng-container>
    <ng-container class="arrowRight">
      <mat-icon (click)="nextPage()">
        chevron_right
      </mat-icon>
    </ng-container>
  </div>
</div>