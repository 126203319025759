import { Component, OnInit } from '@angular/core';
import { iDataTableColumns, iDataTableOptions, slideNavigationTypes } from '@quasar_dynamics/basic-designsystem';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'ClientesMaquinas',
  templateUrl: './ClientesMaquinas.component.html',
  styleUrls: ['./ClientesMaquinas.component.scss']
})
export class ClientesMaquinasComponent implements OnInit {

  isLoading: boolean = false;

  //Tabla
  displayedColumnsTabla: Array<iDataTableColumns> = [
    { label: 'Nombre', property: 'nombre', align:'center', classList: 'bold' },
    { label: 'Descripcion', property: 'descripcion' },
    { label: 'Alertas', property:'alertas', },
  ];

  dataForTable = [
    { id: 1, nombre:'Máquina X', descripcion: 'Lorem ipsum', alertas: 'Revisión cercana: 19/05/2022', },
    { id: 2, nombre:'Máquina X', descripcion: 'Lorem ipsum', alertas: 'Revisión cercana: 19/05/2022', },
    { id: 3, nombre:'Máquina X', descripcion: 'Lorem ipsum', alertas: 'Revisión cercana: 19/05/2022', },
    { id: 4, nombre:'Máquina X', descripcion: 'Lorem ipsum', alertas: 'Revisión cercana: 19/05/2022', },
    { id: 5, nombre:'Máquina X', descripcion: 'Lorem ipsum', alertas: 'Revisión cercana: 19/05/2022', },
    { id: 6, nombre:'Máquina X', descripcion: 'Lorem ipsum', alertas: 'Revisión cercana: 19/05/2022', },
    { id: 7, nombre:'Máquina X', descripcion: 'Lorem ipsum', alertas: 'Revisión cercana: 19/05/2022', },
  ]

  optionsTabla: iDataTableOptions = {
    isSelectable: true,
    canEdit: true,
    canSee: true,
    canDelete: true,
    moreIconsHead:[{icon:'download',callback:()=>{console.log('download')}}],
    isHovered: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc'
  }


  constructor(private staticUtilitiesSE:StaticUtilitiesService) { }

  ngOnInit() {
    this.isLoading = true;
    setTimeout(() => {
      this.isLoading = false;
      
    }, 1000);
  }

  navigateToMaquinas(event){
    this.staticUtilitiesSE.goTo('crm/clientes/maquinas/'+event.id, slideNavigationTypes.slideToTop)
  }

}
