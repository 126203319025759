import { Component, OnInit } from '@angular/core';
import { iDataTableColumns, iDataTableOptions, iUnsubscribeDestroy, PopupService, slideNavigationTypes } from '@quasar_dynamics/basic-designsystem';
import { Subject, takeUntil } from 'rxjs';
import { ConfirmPopupComponent } from 'src/app/Popups/ConfirmPopup/ConfirmPopup.component';
import { NuevoDocumentoPopupComponent } from 'src/app/Popups/NuevoDocumento-Popup/NuevoDocumento-Popup.component';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'CRM-Documentos-DetallesCarpeta',
  templateUrl: './CRM-Documentos-DetallesCarpeta.component.html',
  styleUrls: ['./CRM-Documentos-DetallesCarpeta.component.scss']
})
export class CRMDocumentosDetallesCarpetaComponent extends iUnsubscribeDestroy implements OnInit {


  carpetaId!:number
  nombreCarpeta: string = localStorage.getItem("nombreCarpeta")!

  _reloadTableWidth = new Subject()
  isLoading: boolean = false;

  //Tabla
  displayedColumnsTabla: Array<iDataTableColumns> = [
    { label: 'Documento', property: 'documento' },
    { label: 'Tipo de doc.', property: 'tipoDocumento', align: "center" },
    { label: 'Fecha de doc.', property: 'fechaDocumento', align: "center" },
    { label: 'Fecha caducidad', property: 'fechaCaducidad', align: "center" },
    { label: 'Estado', property: 'estado', align: "center",classList:'tableEstado tableEstadoDocumentos'  },

  ];

  dataForTable = [
    { id: 1, documento: "Documento XX", tipoDocumento: "Presupuesto", fechaDocumento: "10/06/2022", fechaCaducidad: "10/06/2022", estado: "Revisado"},
    { id: 1, documento: "Documento XX", tipoDocumento: "Presupuesto", fechaDocumento: "10/06/2022", fechaCaducidad: "10/06/2022", estado: "Revisado"},
    { id: 1, documento: "Documento XX", tipoDocumento: "Presupuesto", fechaDocumento: "10/06/2022", fechaCaducidad: "10/06/2022", estado: "Revisado"},
    { id: 1, documento: "Documento XX", tipoDocumento: "Presupuesto", fechaDocumento: "10/06/2022", fechaCaducidad: "10/06/2022", estado: "Revisado"},
    { id: 1, documento: "Documento XX", tipoDocumento: "Presupuesto", fechaDocumento: "10/06/2022", fechaCaducidad: "10/06/2022", estado: "Revisado"},
    { id: 1, documento: "Documento XX", tipoDocumento: "Presupuesto", fechaDocumento: "10/06/2022", fechaCaducidad: "10/06/2022", estado: "Revisado"},
    { id: 1, documento: "Documento XX", tipoDocumento: "Presupuesto", fechaDocumento: "10/06/2022", fechaCaducidad: "10/06/2022", estado: "Revisado"},
    { id: 1, documento: "Documento XX", tipoDocumento: "Presupuesto", fechaDocumento: "10/06/2022", fechaCaducidad: "10/06/2022", estado: "Revisado"},
    { id: 1, documento: "Documento XX", tipoDocumento: "Presupuesto", fechaDocumento: "10/06/2022", fechaCaducidad: "10/06/2022", estado: "Revisado"},
  ]

  optionsTabla: iDataTableOptions = {
    isSelectable: true,
    isHovered: true,
    canEdit: true,
    canDelete: true,
    canSee: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc',
    moreIconsHead: [
      {
        icon: 'download',
        callback(element) {

        },
      }
    ]

  }

  constructor( private staticUtilities:StaticUtilitiesService, private popupSE:PopupService, ) {
    super();
  }

  ngOnInit() {
    this.getParams()
  }
  override ngOnDestroy(): void {
    localStorage.removeItem("nombreCarpeta")
  }
  getParams(){
    if(!this.staticUtilities.getParams().carpeta || this.staticUtilities.getParams().carpeta <= 0){
      StaticUtilitiesService.showError('No se ha encontrado la carpeta')
      this.staticUtilities.goTo('crm/documentos', slideNavigationTypes.slideToBottom)
      return;
    }
    this.carpetaId = this.staticUtilities.getParams().carpeta
    this.isLoading = true;
    setTimeout(() => {
      this.isLoading = false;
    }, 1500);
  }

  goBack(){
    this.staticUtilities.goTo('crm/documentos', slideNavigationTypes.slideToBottom)
  }

  /**
   * FUNCTIONALITIES
   */
  edit(event: any) {
    this.popupSE.openPopup(NuevoDocumentoPopupComponent, { data: event, accion: "editar" });
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd)).subscribe((data) => {
      StaticUtilitiesService.showFeedback("Se ha editado el documento correctamente")
      this._unsubInd.next("")
    })
  }
  create() {
    this.popupSE.openPopup(NuevoDocumentoPopupComponent, { data: null, accion: "crear" });
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd)).subscribe((data) => {
      StaticUtilitiesService.showFeedback("Se ha creado el documento correctamente")
      this._unsubInd.next("")
    })
  }
  see(event: any) {
    this.popupSE.openPopup(NuevoDocumentoPopupComponent, { data: null, accion: "ver" });

  }
  delete(event: any, type: String) {
    switch (type) {
      case "single":
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'el documento' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd)).subscribe((data) => {
          StaticUtilitiesService.showFeedback("Se ha eliminado el documento correctamente")
          this._unsubInd.next("")
        })
        break;
      case "selected":
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'los documentos seleccionadas' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd)).subscribe((data) => {
          StaticUtilitiesService.showFeedback("Se han eliminado los documentos correctamente")
          this._unsubInd.next("")
        })
        break;
      case "all":
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'todas los documentos' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd)).subscribe((data) => {
          StaticUtilitiesService.showFeedback("Se han eliminado los documentos correctamente")
          this._unsubInd.next("")
        })
        break;
    }
  }

}
