import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'CustomToggle',
  templateUrl: './CustomToggle.component.html',
  styleUrls: ['./CustomToggle.component.scss']
})
export class CustomToggleComponent implements OnInit {
  @Output('selectedOption') selectedOption = new EventEmitter<number>(); 


  @Input('selectores')selectores: any[] = [];
  @Input('selected')selected: number = 0;




  constructor() { }

  ngOnInit() {
  }

  selectedPill(event) {
    this.selected = event;
    this.selectedOption.emit(this.selected);

  }
}
