<div class="popupGeneralContainer">
  <div class="header">
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
    <p *ngIf="modalData.accion=='editar'">Editar carpeta</p>
    <p *ngIf="modalData.accion=='crear'">Crear carpeta</p>
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
  </div>
  <div class="body">
    <CustomInput [readonly]="modalData.accion=='ver'" classList="fullWidth tall special" placeholder="Nombre" label="Nombre" [(ngModel)]="data.nombre" (change)="checkIsCompleted()"></CustomInput>
    <CustomTextArea [readonly]="modalData.accion=='ver'"  placeHolder="Notas" label="Notas" [(ngModel)]="data.notas" name="notas" (change)="checkIsCompleted()"></CustomTextArea>
  </div>
  <div class="footer" *ngIf="modalData.accion!='ver'">
    <btn classList="secundario" (click)="forceClosePopup()">
      <ng-container>
       Cancelar
      </ng-container>
    </btn>
    <btn classList="secundaryButton" (click)="closePopup()" [class.disabled]="!isCompleted">
      <ng-container *ngIf="modalData.accion=='crear'">
        Crear
      </ng-container>
      <ng-container *ngIf="modalData.accion=='editar'">
        Editar
      </ng-container>
    </btn>
  </div>
</div>
