<pill style="width: 100%;">
  <div class="content">
    <CustomInput  classList="fullWidth tall special" [readonly]="true"  placeholder="Nº Albarán" label="Nº Albarán" [(ngModel)]="data.numAlbaran" ></CustomInput>
    <div class="customContainer">
      <label for="Fecha albarán">Fecha albarán</label>
      <datepicker [readonly] = true [placeholder]="data.albaranDate"></datepicker>
    </div>
      <CustomInput  classList="fullWidth tall special" [readonly]="true"  placeholder="Nº Factura" label="Nº Factura" [(ngModel)]="data.id" ></CustomInput>
    <div class="customContainer">
      <label for="Fecha albarán">Fecha factura</label>
      <datepicker [readonly] = true [placeholder]="data.date"></datepicker>
    </div>
    <div class="customContainer">
      <label for="Fecha albarán">Vencimiento</label>
      <datepicker [placeholder]="data.paymentDate" (dataFromPicker)="data.paymentDate = $event"></datepicker>
    </div>
    <br>
    <CustomTextArea class="dobleCol" placeHolder="Notas" label="Notas" [(ngModel)]="data.note" name="note" (keyup)="checkIsComplete()"></CustomTextArea>


  </div>
</pill>