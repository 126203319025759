<FullContainer>
  <div class="fullView">
    <div class="headerAndToggle">
      <div class="header">
        <p>CLIENTES</p>
      </div>
      <div class="toggle">
        <CustomToggle [selected]="selected" [selectores]="selectores" (selectedOption)="selectedOption($event)">
        </CustomToggle>
      </div>
    </div>
    <div class="filters">

      <filterActions (addNew)="create()" [disableFilter]="selected == 0" (searchSend)="selected === 0 ?search = $event: getFilter($event, 'genericFilter')">
        <ng-container filterContent>
          <div class="containerSelectores">
            <div>
              <selector [options]="opcionesClientes" (change)="getFilter($event, 'company')" ></selector>
            </div>
            <div>
              <datepicker [clearable]="true" [filter]="true" (dataFromPicker)="getFilter($event, 'fechaPedidoStart')"></datepicker>
            </div>
            <div>
              <datepicker [clearable]="true" [filter]="true" (dataFromPicker)="getFilter($event, 'fechaPedidoEnd')"></datepicker>
            </div>
          </div>
        </ng-container>
      </filterActions>

    </div>

    <div class="content">


      <ng-container *ngIf="selected === 0">
        <ERP-Clientes-CLientesLista [refresh]="refresh" [search]="search"></ERP-Clientes-CLientesLista>
      </ng-container>
      <ng-container *ngIf="selected === 1">
        <ERP-Clientes-Pedidos  [refreshPedidos]="refreshPedidos" (clientsForFilter)="clientsForFilter($event)" [filterOrders]="filterOrders" ></ERP-Clientes-Pedidos>
      </ng-container>
      <ng-container *ngIf="selected === 2">
        <ERP-Clientes-Albaran [refreshAlbaran]='refreshAlbaran' ></ERP-Clientes-Albaran>
      </ng-container>




    </div>

  </div>

</FullContainer>