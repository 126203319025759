<FullContainer>
  <div class="fullView">
    <div class="headerAndToggle">
      <div class="header">
        <p>STOCK</p>
      </div>
      <div class="toggle">
        <CustomToggle [selected]="selected" [selectores]="selectores" (selectedOption)="selectedOption($event)">
        </CustomToggle>
      </div>
    </div>
    <div class="filters">

      <filterActions (addNew)="create()" (searchSend)="getFilter($event,'genericFilter')">
        <ng-container filterContent>
          <div class="containerSelectores">
            <div>
              <selector [options]="opcionesUnderMin" (change)="getFilter($event,'under_min')"></selector>
            </div>
            <div>
              <selector [options]="opcionesVSNegative" (change)="getFilter($event,'vs_negative')"></selector>
            </div>
          </div>
        </ng-container>
      </filterActions>
    </div>

    <div class="content">


      <ng-container *ngIf="selected === 0">
        <ERP-Stock-MateriasPrimas [refreshRaw]="refreshRaw"  [filterRaw]="filterRaw" ></ERP-Stock-MateriasPrimas>
      </ng-container>
      <ng-container *ngIf="selected === 1">
        <ERP-Stock-Articulos [filterArticle]="filterArticle" [refreshArticle]="refreshArticle"></ERP-Stock-Articulos>
      </ng-container>