import { CdkDrag, CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { iDataTableColumns, iDataTableOptions, iDate, iUnsubscribeDestroy, PopupService, slideNavigationTypes } from '@quasar_dynamics/basic-designsystem';
import { Subject, takeUntil } from 'rxjs';
import { ConfirmPopupComponent } from 'src/app/Popups/ConfirmPopup/ConfirmPopup.component';
import { NuevoClientePopupComponent } from 'src/app/Popups/NuevoCliente-Popup/NuevoCliente-Popup.component';
import { ProductionReportService } from 'src/app/Services/Api/ProductionReport.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';
import { iProductionReportFilter } from 'src/app/Shared/Interfaces/iProductionReport';
import * as XLSX from 'xlsx';
import * as fs from 'file-saver';


@Component({
  selector: 'ERP-PP-PartesProduccion',
  templateUrl: './ERP-PP-PartesProduccion.component.html',
  styleUrls: ['./ERP-PP-PartesProduccion.component.scss']
})
export class ERPPPPartesProduccionComponent extends iUnsubscribeDestroy implements OnInit {
  @Input() selectedList: boolean = true
  @Input() downloadFlagEvent: boolean = false
  @Input() filterProductionReport: any = null
  totalPaginacion: number = 0;
  isLoading: boolean = false;
  forceUpdateWidth = new Subject();
  @Input() flagRefreshParte: boolean = false

  //Tabla
  displayedColumnsTabla: Array<iDataTableColumns> = [
    { label: 'Código de parte', property: 'code' },
    { label: 'Producto', property: 'formula', },
    { label: 'Cantidad', property: 'amount', align: 'center', format: 'kg' },
    { label: 'Fecha', property: 'fecha', align: 'center' },
    { label: 'Fecha entrega', property: 'fechaEntrega', align: 'center' },
    { label: 'Estado', property: 'state', align: 'center', classList: 'tableEstado tableEstadoProduccion' },
    { label: 'Cantidad real obtenida', property: 'realAmount', align: 'center' },
    { label: '%Merma', property: 'waste', align: 'center' },
    { label: 'Área prod.', property: 'productionArea', align: 'center' },
  ];

  dataForTable: any = []

  filter: any = {
    num_devoluciones: 20,
    num_pagina: 1,

  }

  optionsTabla: iDataTableOptions = {
    isSelectable: true,
    moreIconsHead: [{ callback: (event) => { this.exportexcel() }, icon: "download" }],
    canDelete: true,
    isHovered: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc'
  }


  constructor(private router: Router, private staticUtilitiesSE: StaticUtilitiesService, private popupSE: PopupService, private productionReportSE: ProductionReportService) {
    super();
  }

  ngOnInit() {
    this.getAllProductionReport()
    this.getAllColumns()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['flagRefreshParte']) {
      if (changes['flagRefreshParte'].currentValue) {
        this.getAllProductionReport()
      }
    }
    if (changes['filterProductionReport']){
      if (changes['filterProductionReport'].currentValue){
        if(!changes['filterProductionReport'].currentValue.productionArea){
          delete this.filter.productionArea
        }
        if(!changes['filterProductionReport'].currentValue.fechaIni){
          delete this.filter.fechaIni
        }
        if(!changes['filterProductionReport'].currentValue.fechaFin){
          delete this.filter.fechaFin
        }
        if(!changes['filterProductionReport'].currentValue.fechaEntregaIni){
          delete this.filter.fechaEntregaIni
        }
        if(!changes['filterProductionReport'].currentValue.fechaEntregaFin){
          delete this.filter.fechaEntregaFin
        }
        if(!changes['filterProductionReport'].currentValue.genericFilter){
          delete this.filter.genericFilter
        }
        this.filter = {...this.filter, ...changes['filterProductionReport'].currentValue}
        this.getAllProductionReport()
      }
      if (Object.keys(changes['filterProductionReport'].currentValue).length === 0){
        delete this.filter.productionArea 
        delete this.filter.fechaIni 
        delete this.filter.fechaFin 
        delete this.filter.fechaEntregaIni 
        delete this.filter.fechaEntregaFin 
        delete this.filter.genericFilter
        this.getAllProductionReport()
      }
    }
  }

  /**
 * Edit
 */

  edit(event) {
    this.popupSE.openPopup(NuevoClientePopupComponent, { data: event, accion: 'editar' })
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
      if (!res) { return };
      this._unsubInd2.next('');
    })
  }

  /**
   * Delete
   */

  delete(event, key) {
    let ids:number[] = []
    switch (key) {
      case 'simple':
        ids = [event.id]
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'la fórmula' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
          if (!res) { return };
          this.deleteParte(ids);
          this._unsubInd2.next('');
        })
        break;
      case 'all':
        ids = this.dataForTable.map(element => element.id)
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'todas las fórmulas' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
          if (!res) { return };
          this.deleteParte(ids);
          this._unsubInd2.next('');
        })

        break;
      case 'selected':
        ids = event.map(element => element.id)
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'las fórmulas seleccionadas' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
          if (!res) { return };
          this.deleteParte(ids);
          this._unsubInd2.next('');
        })
        break;
      default:
        break;
    }
  }

  checkcompra() {
    this.dataForTable.map((element, index) => {
      if (element.merma > 0) {
        (document.querySelectorAll(".tableBodyContainer .tableBody tbody tr td[columntable='merma']")![index] as HTMLElement).classList.add("venta");
      } else {
        (document.querySelectorAll(".tableBodyContainer .tableBody tbody tr td[columntable='merma']")![index] as HTMLElement).classList.add("negativo");
      }
    })
  }

  /**
   * See
   */

  see(event) {
    this.staticUtilitiesSE.goTo('erp/partesproduccion/partes/', slideNavigationTypes.slideToTop, { parte: event.id })
  }

  /**
   * PAGINATION
   */

  changePagination(event: any) {
    if (event === 'next') {
      this.filter.num_pagina++;
      this.getAllProductionReport();
    } else {
      this.filter.num_pagina--;
      this.getAllProductionReport();
    }
  }

  /**
* DESCARGA DE PARTES EN EXCEL
*/

exportexcel(): void {
  let table = document.createElement("table")
  table.append((document.querySelector("dataTable .tableHeader thead") as HTMLElement).cloneNode(true))
  table.querySelector("thead .iconsActions")?.remove();
  table.querySelector("thead .checkboxContainer")?.remove();
  let rows = Array.from(document.querySelectorAll("dataTable .tableBody tbody tr "));
  let filteredRows = rows.filter((row) => {
    return row.querySelector('.checkboxContainer mat-checkbox ')!.getAttribute('ng-reflect-checked') === "true"
  })
  if (filteredRows.length > 0) {
    filteredRows.forEach((row) => {
      let newRow = row.cloneNode(true) as HTMLElement;
      newRow.querySelector('td:last-of-type')?.remove();
      newRow.querySelector('td:first-of-type')?.remove();
      table.append(newRow)
    })
  } else {
    table.append((document.querySelector("dataTable .tableBody tbody") as HTMLElement).cloneNode(true))
    table.querySelectorAll("tbody tr td:last-of-type").forEach(element => {
      element.remove();
    })
    table.querySelectorAll("tbody tr td:first-of-type").forEach(element => {
      element.remove();
    })
  }
  /* pass here the table id */
  const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table);

  /* generate workbook and add the worksheet */
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  /* save to file */
  XLSX.writeFile(wb, 'PartesDeProduccion.xlsx');

}


  /**
   * API CALLS
   */

  getAllProductionReport() {
    this.isLoading = true;
    this.productionReportSE.getAllTable(this.filter);
    this.productionReportSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(res => {
      if (!res) { return }
      let { data, total } = res.data;
      this.totalPaginacion = total;
      this.dataForTable = data.map((element, index) => { return { ...element, fecha: iDate.javascriptConvert(new Date(element.date)).toStringDate('EU'), fechaEntrega: iDate.javascriptConvert(new Date(element.deliveryDate)).toStringDate('EU'), state: element.isClosed ? 'Cerrado' : 'Pendiente', } });
      setTimeout(() => {
        this.forceUpdateWidth.next(true)
        this.checkcompra()
      }, 10);

      this.isLoading = false;
      this._unsubInd.next('');
    });
  }

  deleteParte(ids:number[]){
    this.productionReportSE.delete(ids);
    this.productionReportSE.getResultDelete().pipe(takeUntil(this._unsubInd3)).subscribe(res => {
      if (!res) { return }
      StaticUtilitiesService.showFeedback('Parte eliminado correctamente')
      this.getAllProductionReport();
      this._unsubInd3.next('');
    })
    this.productionReportSE.getResultDeleteError().pipe(takeUntil(this._unsub)).subscribe(res => {
      if (!res) { return }
      StaticUtilitiesService.showError('Error al eliminar el parte')
      this._unsub.next('');
    })
  }




  // _________________________________________________________________________________


  Ms100: any = [
    { id: 1, codigo: '456453123 - 7', numero: '0001', producto: 'DISOLVENTE L-09/R E.E.', observacion: 'observacion de ejemplo que podría ser esta mismo', fecha: '15/11/2023', peso: '50 Kg', },
    { id: 1, codigo: '456453123 - 7', numero: '0001', producto: 'DISOLVENTE L-09/R E.E.', observacion: 'observacion de ejemplo que podría ser esta mismo', fecha: '15/11/2023', peso: '50 Kg', },
    { id: 1, codigo: '456453123 - 7', numero: '0001', producto: 'DISOLVENTE L-09/R E.E.', observacion: 'observacion de ejemplo que podría ser esta mismo', fecha: '15/11/2023', peso: '50 Kg', },
    { id: 1, codigo: '456453123 - 7', numero: '0001', producto: 'DISOLVENTE L-09/R E.E.', observacion: 'observacion de ejemplo que podría ser esta mismo', fecha: '15/11/2023', peso: '50 Kg', },
    { id: 1, codigo: '456453123 - 7', numero: '0001', producto: 'DISOLVENTE L-09/R E.E.', observacion: 'observacion de ejemplo que podría ser esta mismo', fecha: '15/11/2023', peso: '50 Kg', },
    { id: 1, codigo: '456453123 - 7', numero: '0001', producto: 'DISOLVENTE L-09/R E.E.', observacion: 'observacion de ejemplo que podría ser esta mismo', fecha: '15/11/2023', peso: '50 Kg', },
    { id: 1, codigo: '456453123 - 7', numero: '0001', producto: 'DISOLVENTE L-09/R E.E.', observacion: 'observacion de ejemplo que podría ser esta mismo', fecha: '15/11/2023', peso: '50 Kg', },
    { id: 1, codigo: '456453123 - 7', numero: '0001', producto: 'DISOLVENTE L-09/R E.E.', observacion: 'observacion de ejemplo que podría ser esta mismo', fecha: '15/11/2023', peso: '50 Kg', },
  ];
  Ms400: any = [
    { id: 1, codigo: '456453123 - 43543534', numero: '0001', producto: 'DISOLVENTE L-09/R E.E.', observacion: 'observacion de ejemplo que podría ser esta mismo', fecha: '15/11/2023', peso: '50 Kg', },
    { id: 1, codigo: '456453123 - 43543534', numero: '0001', producto: 'DISOLVENTE L-09/R E.E.', observacion: 'observacion de ejemplo que podría ser esta mismo', fecha: '15/11/2023', peso: '50 Kg', },
    { id: 1, codigo: '456453123 - 43543534', numero: '0001', producto: 'DISOLVENTE L-09/R E.E.', observacion: 'observacion de ejemplo que podría ser esta mismo', fecha: '15/11/2023', peso: '50 Kg', },
    { id: 1, codigo: '456453123 - 43543534', numero: '0001', producto: 'DISOLVENTE L-09/R E.E.', observacion: 'observacion de ejemplo que podría ser esta mismo', fecha: '15/11/2023', peso: '50 Kg', },
    { id: 1, codigo: '456453123 - 43543534', numero: '0001', producto: 'DISOLVENTE L-09/R E.E.', observacion: 'observacion de ejemplo que podría ser esta mismo', fecha: '15/11/2023', peso: '50 Kg', },
    { id: 1, codigo: '456453123 - 43543534', numero: '0001', producto: 'DISOLVENTE L-09/R E.E.', observacion: 'observacion de ejemplo que podría ser esta mismo', fecha: '15/11/2023', peso: '50 Kg', },
  ];

  drop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      this.editColumns(event);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
      this.editColumns(event);
    }
  }

  noReturnPredicate() {
    return false;
  }

  /**
   * API CALLS
   */

  getAllColumns() {
    this.productionReportSE.getAllColumns();
    this.productionReportSE.getResultColumns().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
      if (!res) { return }
      let { data } = res;
      this.Ms100 = data.ms100;
      this.Ms400 = data.ms400;
      this._unsubInd2.next('');
    })
  }

  editColumns(event) {
    let data = event.container.data.map(item => item.id)
    let elementoAmover = data[event.previousIndex]
    if (event.previousIndex === 0) {
      const insert = (arr, newItem) => [
        ...arr.slice(0, event.previousIndex),
        newItem,
        ...arr.slice(event.previousIndex)
      ]
      data = insert(data, elementoAmover)
    } else {
      elementoAmover = data.find((item, index) => {
        if (index === event.previousIndex) {
          return item;
        }
      });
      data.splice(event.previousIndex, 1);
      let finalIndex;
      if (event.previousIndex > event.currentIndex) {
        finalIndex = event.currentIndex;
      } else {
        finalIndex = event.currentIndex - 1;
      }
      const insert = (arr,  newItem) => [
        ...arr.slice(0, event.previousIndex),
        newItem,
        ...arr.slice(event.previousIndex)
      ]
      data = insert(data,  elementoAmover);
    }
    let updateData: any = [];
    if (event.container.id === 'Ms100') {
      updateData = {
        ms100: data
      }
    }
    if (event.container.id === 'Ms400') {
      updateData = {
        ms400: data
      }
    }
    this.productionReportSE.updateColumns(updateData)
    this.productionReportSE.getResultUpdate().pipe(takeUntil(this._unsubInd3)).subscribe(res => {
      if (!res) { return }
      StaticUtilitiesService.showFeedback('Lista de partes de producción actualizada correctamente')
      this.getAllColumns()
      this._unsubInd3.next('');
    });
    this.productionReportSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe(res => {
      if (!res) { return }
      StaticUtilitiesService.showError('La lista de partes no pudo ser actualizada')
      this.getAllColumns()
      this._unsub.next('');
    });
  }

}
