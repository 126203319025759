<pill class="tablePil" classList="tablePill">
  <div class="headerTable">
    <p class="headerTableP">Fungible</p>
    <p class="headerTableP">Descripción</p>
    <p class="headerTableP">Notas</p>
    <p class="headerTableP"></p>
  </div>
  <ng-container *ngFor="let linea of arrayLineasTabla; let index = index">
    <div class="bodyTable">
      <div class="contenedor">
        <selector [options]="opcionesFungibles" [(ngModel)]="arrayLineasTabla[index]"
          (change)="getFungiblesSelected(materialSelected, linea)" (change)="checkIsCompleted()"></selector>
      </div>
      <div class="contenedor">
        <ng-container *ngIf="linea.descripcion == ''">
          -
        </ng-container>
        <ng-container *ngIf="linea.descripcion != ''">
          {{linea.descripcion}}
        </ng-container>
      </div>
      <div class="contenedor" >
        <ng-container *ngIf="linea.notas == ''">
          -
        </ng-container>
        <ng-container *ngIf="linea.notas != ''">
          {{linea.notas}}
        </ng-container>
      </div>
      <div class="icons">
        <span class="closeIcon" *ngIf="arrayLineasTabla.length > 1" (click)="removeRow(linea)">
          <mat-icon>close</mat-icon>
        </span>
      </div>
    </div>
  </ng-container>

</pill>
