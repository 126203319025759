<div class="fullContainer">
  <div class="firstLine">
    <div class="databox">
      <div class="infoLeft">
        <div class="line">
          <p class="bold">Nº albarán</p>
          <p>{{dataSeccion3.numAlbaran}}</p>
        </div>
        <div class="line">
          <p class="bold">Fecha albarán</p>
          <p>{{dataSeccion3.date | date:'dd/MM/yyyy'}}</p>
        </div>
        <div class="line especial">
          <p class="bold">Cliente</p>
          <p>{{dataSeccion3.id}} - {{dataSeccion3.name}}</p>
        </div>
      </div>
      <div class="infoCliente">
        <p class="bold">Cliente</p>
        <br>
        <p class="bold">{{dataSeccion3.name}}</p>
        <p> {{dataSeccion3.billingAddress?.street}},{{dataSeccion3.billingAddress?.number}},{{dataSeccion3.billingAddress?.postalCode}}</p>
        <p> {{dataSeccion3.billingAddress?.municipality}},{{dataSeccion3.billingAddress?.province}},{{dataSeccion3.billingAddress?.country}} </p>
        <br>
        <p>Tél: {{dataSeccion3.phones[0]}}</p>
        <p>NIF: {{dataSeccion3.nif}}</p>
      </div>
      <div class="infoCliente">
        <p class="bold">Dirección de entrega</p>
        <br>
        <p class="bold">{{dataSeccion3.name}}</p>
        <p> {{dataSeccion3.properAddress?.street}},{{dataSeccion3.properAddress?.number}},{{dataSeccion3.properAddress?.postalCode}}</p>
        <p> {{dataSeccion3.properAddress?.municipality}},{{dataSeccion3.properAddress?.province}},{{dataSeccion3.properAddress?.country}} </p>
        <br>
        <p>Tél: {{dataSeccion3.properAddress?.phones}}</p>
      </div>
    </div>
    <div class="databox">
      <div class="infoLeft">
        <div class="line">
          <p class="bold">Agencia transporte :</p>
          <p>{{dataSeccion3.transportAgency}}</p>
        </div>
        <div class="line">
          <p class="bold">S/ Referencia : </p>
          <p>{{dataSeccion3.sReference}}</p>
        </div>
        <div class="line">
          <p class="bold">Tipo Portes :</p>
          <p>{{dataSeccion3.porte.name}}</p>
        </div>
        <div class="line">
          <p class="bold">Peso Neto :</p>
          <p>{{dataSeccion3.peso.toFixed(2)}}</p>
        </div>
        <div class="line">
          <p class="bold">Bultos :</p>
          <p>{{dataSeccion3.packages}}</p>
        </div>
      </div>

    </div>
  </div>
  <pill>
    <div class="bodyTab">
      <div class="headerTab">
        <p class="bold">Pedido</p>
        <p class="bold">Referencia</p>
        <p class="bold">Descripción</p>
        <p class="bold">Cantidad</p>
        <p class="bold">Precio</p>
        <p class="bold">Importe</p>
      </div>
      <div class="contentTab">
        <div class="line" *ngFor="let line of dataSeccion3.arrayLineas">
          <p class="bold">{{line.orderId}}</p>
          <p >{{line.code}}</p>
          <p >{{line.description}}</p>
          <div class="inline">
            <p >{{line.quantity}}</p>
            <p>{{line.unit}}</p>
          </div>

          <p >{{line.price}}</p>
          <p >{{line.subtotal}}</p>
        </div>
      </div>
    </div>
  </pill>
  <div class="accounting">
    <div class="line">
      <p class="bold">Base imponible :</p>
      <p>{{dataSeccion3.baseImponible | currency:'EUR'}}</p>
    </div>
    <div class="line">
      <p class="bold">IVA :</p>
      <p>{{dataSeccion3.iva | currency:'EUR'}}</p>
    </div>
    <div class="line">
      <p class="bold">Recargo de equivalencia *:</p>
        <p>{{dataSeccion3.recargoEquivalencia | currency:'EUR'}}</p>
    </div>
    <div class="line">
      <p class="bold">TOTAL : </p>
      <p>{{dataSeccion3.total | currency:'EUR'}}</p>
    </div>
  </div>
  <div class="pesoAndNotes">
    <div class="line">
      <p class="bold">Peso Neto :</p>
      <p>{{dataSeccion3.peso.toFixed(2)}} kg</p>
    </div>
    <div class="line">
      <p class="bold nowrap">Notas :</p>
      <p>{{dataSeccion3.note}} </p>
    </div>
  </div>

</div>
