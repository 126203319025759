<FullContainer>
  <div class="fullView">
    <div class="headerAndArchivar">
      <div class="header">
        <button class="goBack" (click)="goBack()">
          <ng-container>
            <mat-icon>
              arrow_back
            </mat-icon>
          </ng-container>
        </button>
        <p>FÓRMULA<span> / {{data.name}} {{data.code}}</span></p>
      </div>
    </div>
    <div class="archivador">
      <accordion [title]="'FICHA'">
        <ng-container>
          <ERP-PP-Formulas-DV-Ficha (reload)="reload($event)" [data]="data"
            class="fullWidth"></ERP-PP-Formulas-DV-Ficha>
        </ng-container>
      </accordion>
    </div>
    <div class="archivador">
      <accordion [title]="'FÓRMULA'">
        <ng-container>
          <ERP-PP-Formulas-DV-Formula [data]="data" (reload)="reload($event)"
            class="fullWidth"></ERP-PP-Formulas-DV-Formula>
        </ng-container>
      </accordion>
    </div>
    <div class="archivador">
      <accordion [title]="'ENVASES COMPATIBLES'">
        <ng-container>
          <ERP-PP-Formulas-DV-EnvasesCompatibles [data]="data" (reload)="reload($event)"
            class="fullWidth"></ERP-PP-Formulas-DV-EnvasesCompatibles>
        </ng-container>
      </accordion>
    </div>
    <div class="archivador">
      <accordion [title]="'ESPECIFICACIONES DE PRODUCTO'">
        <ng-container>
          <ERP-PP-EspecificacionesDeProducto [data]="data" (reload)="reload($event)"
            class="fullWidth"></ERP-PP-EspecificacionesDeProducto>
        </ng-container>
      </accordion>
    </div>




  </div>
</FullContainer>