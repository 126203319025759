import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { iDataTableColumns, iDataTableOptions, iOptionsSelector, iUnsubscribeDestroy, PopupService } from '@quasar_dynamics/basic-designsystem';
import { Subject, takeUntil } from 'rxjs';
import { ConfirmPopupComponent } from 'src/app/Popups/ConfirmPopup/ConfirmPopup.component';
import { NuevoAgentePopupComponent } from 'src/app/Popups/NuevoAgente-Popup/NuevoAgente-Popup.component';
import { NuevoEnvasePopupComponent } from 'src/app/Popups/NuevoEnvase-Popup/NuevoEnvase-Popup.component';
import { AgentService } from 'src/app/Services/Api/Agent.service';
import { CompanyService } from 'src/app/Services/Api/Company.service';
import { StaticDataHandlerService } from 'src/app/Services/Utils/StaticDataHandler.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';
import { iAgentFilter } from 'src/app/Shared/Interfaces/iAgent';
import { iCompanyFilter } from 'src/app/Shared/Interfaces/iCompany';
import * as XLSX from 'xlsx';

@Component({
  selector: 'erpMantenimientoDetallesAgentes',
  templateUrl: './ERP-Mantenimiento-Detalles-Agentes.component.html',
  styleUrls: ['./ERP-Mantenimiento-Detalles-Agentes.component.scss']
})
export class ERPMantenimientoDetallesAgentesComponent extends iUnsubscribeDestroy implements OnInit {

  _reloadTableWidth = new Subject()
  opcionesPais: iOptionsSelector = {
    items: StaticDataHandlerService.getPaises(),
    clearable: true,
    search: true,
    bindValue:'label',
    placeholder: "País",
    append: true
  }
  pais: string | null = null;
  filter: iAgentFilter = {
    num_devoluciones: 20,
    num_pagina: 1,
  }
  clienteFilter:iCompanyFilter ={
    num_pagina: 1,
    num_devoluciones: 20,
    isClient: true,
  }
  totalPaginacion: number = 0;
  opcionesProvincias: iOptionsSelector = {
    items: StaticDataHandlerService.getProvincias(),
    clearable: true,
    search: true,
    bindValue:'label',
    placeholder: "Provincias",
    append: true
  }
  provincia: string | null = null;

  opcionesClienteAsignados: iOptionsSelector = {
    items: [],
    clearable: true,
    search: true,
    bindValue:'id',
    placeholder: "Clientes",
    append: true,
    multiple: {
      isMultiple: true,
      checkbox: true,
      multipleLimitReplacer: 2,
      multipleTextReplacer: "Varios clientes seleccionados"
    },
  }
  clientes: string | null = null;

  isLoading: boolean = false;

  //Tabla
  displayedColumnsTabla: Array<iDataTableColumns> = [
    { label: 'Nombre', property: 'name' },
    { label: 'País', property: 'pais', align: "center" },
    { label: 'Provincia', property: 'provincia', align: "center" },
    { label: 'Clientes asignados', property: 'clientes', align: "center" },

  ];

  dataForTable = []

  optionsTabla: iDataTableOptions = {
    isSelectable: true,
    isHovered: true,
    canSee: true,
    canEdit: true,
    canDelete: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc',
    moreIconsHead: [{ callback: (event) => { this.exportexcel() }, icon: "download" }],
  }
  dataForTableFiltered: Array<any> = this.dataForTable;


  constructor(private router: Router, private staticUtilitiesSE: StaticUtilitiesService, private popupSE: PopupService, private agentSE: AgentService, private companySE: CompanyService) {
    super()
  }

  ngOnInit() {
    this.getAllAgents()
    this.getClientesForFilter()
  }
  /**
   * GETTERS & SETTERS
   */

  search(event){
    if(!event){delete this.filter.genericFilter; this.getAllAgents() ;return}
    this.filter.genericFilter = event
    this.getAllAgents()
  }
  getSelector(event,key){
    if(!event){delete this.filter[key]; this.getAllAgents() ;return}
    this.filter[key] = event
    this.getAllAgents()
  }

  /**
  * FUNCTIONALITIES
  */
  edit(event: any) {
    this.popupSE.openPopup(NuevoAgentePopupComponent, { data: event, accion: "editar" });
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe((res) => {
      if (!res) { return }
      this.ngOnInit()
      this._unsubInd2.next("")
    })
  }
  create() {
    this.popupSE.openPopup(NuevoAgentePopupComponent, { data: null, accion: "crear" });
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe((res) => {
      if (!res) { return }
      this.ngOnInit()
      this._unsubInd2.next("")
    })
  }
  see(event: any) {
    this.popupSE.openPopup(NuevoAgentePopupComponent, { data: event, accion: "ver" });

  }
  delete(event: any, type: String) {
    let ids:any = []
    switch (type) {
      case "single":
        ids = [event.id]
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'el agente' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe((res) => {
          if (!res) { return }
          this.deleteAgent(ids)
          this._unsubInd2.next("")
        })
        break;
      case "selected":
        ids = event.map((el: any) => el.id)
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'los agentes seleccionadas' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe((res) => {
          if (!res) { return }
          this.deleteAgent(ids)
          this._unsubInd2.next("")
        })
        break;
      case "all":
        ids = this.dataForTable.map((el: any) => el.id)
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'todas los agentes' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe((res) => {
          if (!res) { return }
          this.deleteAgent(ids)
          this._unsubInd2.next("")
        })
        break;
    }
  }
  changePagination(event: any) {
    if (event === 'next') {
      this.filter.num_pagina++;
      this.getAllAgents();
    } else {
      this.filter.num_pagina--;
      this.getAllAgents();
    }
  }

/**
* DESCARGA DE PARTES EN EXCEL
*/

exportexcel(): void {
  let table = document.createElement("table")
  table.append((document.querySelector("dataTable .tableHeader thead") as HTMLElement).cloneNode(true))
  table.querySelector("thead .iconsActions")?.remove();
  table.querySelector("thead .checkboxContainer")?.remove();
  let rows = Array.from(document.querySelectorAll("dataTable .tableBody tbody tr "));
  let filteredRows = rows.filter((row) => {
    return row.querySelector('.checkboxContainer mat-checkbox ')!.getAttribute('ng-reflect-checked') === "true"
  })
  if (filteredRows.length > 0) {
    filteredRows.forEach((row) => {
      let newRow = row.cloneNode(true) as HTMLElement;
      newRow.querySelector('td:last-of-type')?.remove();
      newRow.querySelector('td:first-of-type')?.remove();
      table.append(newRow)
    })
  } else {
    table.append((document.querySelector("dataTable .tableBody tbody") as HTMLElement).cloneNode(true))
    table.querySelectorAll("tbody tr td:last-of-type").forEach(element => {
      element.remove();
    })
    table.querySelectorAll("tbody tr td:first-of-type").forEach(element => {
      element.remove();
    })
  }
  /* pass here the table id */
  const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table);

  /* generate workbook and add the worksheet */
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  /* save to file */
  XLSX.writeFile(wb, 'agentes.xlsx');

}


  /**
   * API CALLS
   */
  getAllAgents() {
    this.isLoading = true;
    this.agentSE.getAll(this.filter);
    this.agentSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe((res) => {
      if (!res) { return; }
      let { data, total } = res.data;
      this.totalPaginacion = total;
      if(data.length > 0){
      this.dataForTable = data.map((item) => { return { ...item, codigo: item?.code, pais: item?.address?.country, provincia: item?.address?.province, clientes: item?.company?.length, phone1:item?.address?.phones[0], phone2:item?.address?.phones[1] } });
      }else{
        this.dataForTable = []
      }
      this.dataForTableFiltered = this.dataForTable;
      setTimeout(() => {
        this._reloadTableWidth.next(true)
      }, 1);
      this.isLoading = false;
      this._unsubInd.next("")
    })
  }
  deleteAgent(ids:number[]){
    this.agentSE.delete(ids);
    this.agentSE.getResultDelete().pipe(takeUntil(this._unsubInd3)).subscribe((res) => {
      if (!res) { return; }
      StaticUtilitiesService.showFeedback('Agente eliminado correctamente');
      this.getAllAgents();
      this._unsubInd3.next("")
    })
    this.agentSE.getResultDeleteError().pipe(takeUntil(this._unsub)).subscribe((res) => {
      if (!res) { return; }
      StaticUtilitiesService.showFeedback('Error al eliminar el agente');
      this._unsub.next("")
    })
  }
  getClientesForFilter(){
    this.companySE.getClientOrProveedor(this.clienteFilter);
    this.companySE.getResult().pipe(takeUntil(this._unsubInd2)).subscribe((res) => {
      if(!res){return}
      let {data} = res.data;
      this.opcionesClienteAsignados.items = data.map((item) => {return {id:item.id, label:item.name}})
      this._unsubInd2.next('');
    })
  }


}
