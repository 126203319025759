import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { iDataTableColumns, iDataTableOptions, iUnsubscribeDestroy, PopupService } from '@quasar_dynamics/basic-designsystem';
import { Subject, takeUntil } from 'rxjs';
import { OrderService } from 'src/app/Services/Api/Order.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';


@Component({
  selector: 'erpPedidosDetallesPedido',
  templateUrl: './ERP-Pedidos-Detalles-Pedido.component.html',
  styleUrls: ['./ERP-Pedidos-Detalles-Pedido.component.scss']
})
export class ERPPedidosDetallesPedidoComponent extends iUnsubscribeDestroy implements OnInit {

  @Input() orderId:number = 0
  
  isLoading: boolean = false;
  baseImponible: number | null = null;
  plusIva: number | null = null;
  total: number | null = null;
  data:any = []
  forceUpdateWidth = new Subject()
  ivaValue:number = 0


  //Tabla
  displayedColumnsTabla: Array<iDataTableColumns> = [
    { label: 'Artículo', property: 'articulo' },
    { label: 'Descripción', property: 'descripcion',  },
    { label: 'Cantidad', property: 'quantity', align:'center' },
    { label: 'Precio', property: 'price', align:'center', format:'currency'  },
    { label: 'Subtotal', property: 'subtotal', align:'center', format:'currency' },
  ];

  dataForTable:any = []

  optionsTabla: iDataTableOptions = {
    isHovered: true,
    minHeight: 250,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc'
  }


  constructor(private router:Router, private staticUtilitiesSE: StaticUtilitiesService, private popupSE: PopupService, private orderSE:OrderService) {
    super();
  }

  ngOnInit() {

  }

  ngOnChanges(change:SimpleChanges){
    if(change['orderId']){
      this.getOrderID()
    }
  }

  getSizes(){
    let documents = (document.querySelectorAll('.col.value'));
    documents.forEach((element: any) => {
      element.style.width = (document.querySelector(' div.tableBodyContainer > table > tbody > tr:nth-child(1) > td:nth-child(5) > div') as HTMLElement).clientWidth + 'px';
    });
  }

  getBaseImponible(){
    let baseImponible = 0;
    this.dataForTable.forEach(element => {
      baseImponible += element.subtotal;
    });
    this.baseImponible = baseImponible;
    this.plusIva = this.baseImponible * this.ivaValue;
    this.total = this.baseImponible + this.plusIva
  }

  /**
   * API CALLS
   */

  getOrderID(){
    this.isLoading = true;
    this.orderSE.getSingle(this.orderId);
    this.orderSE.getResultIndividual().pipe(takeUntil(this._unsubInd)).subscribe((res) => {
      if(!res){return};
      let {data} = res;
      this.data = data
      this.ivaValue = data.company.paymentMethods[0].typeIva.value;
      this.dataForTable = data.orderItems.map((element:any) => {return {...element, articulo: element.rawMaterial.name, descripcion: element?.rawMaterial?.description,}})
      setTimeout(() => {
        this.getBaseImponible()
        setTimeout(() => {
          
          this.getSizes();
        }, 1);
        this.forceUpdateWidth.next(true)
      }, 1000);
      this.isLoading = false;
      this._unsubInd.next('');
    })
  }

}
