<FullContainer>
  <div class="documentosGeneralContainer">
    <div class="header">
      <p>DOCUMENTOS</p>
    </div>
    <filterActions [disableFilter]="true" (addNew)="create()"></filterActions>
    <pill class="tablePil" classList="tablePill">
      <dataTable [data]="dataForTable" [forceUpdateWidth]="_reloadTableWidth" [isLoading]="isLoading" (clickRow)="see($event)" [options]="optionsTabla" [displayedColumns]="displayedColumnsTabla" (edit)="edit($event)"(delete)="delete($event,'single')" (deleteAll)="delete($event,'all')" (deleteSelected)="delete($event,'selected')"></dataTable>
    </pill>
  </div>
</FullContainer>
