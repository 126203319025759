import { iBaseObject } from "@quasar_dynamics/basic-designsystem";
import { iFamily } from "./iFamily";

export interface iSubfamily {
    id: number,
    code?: string,
    description?: string,
    note?: string,
    family?: iFamily,
}

export class iSubfamily extends iBaseObject {
    static convertFromCollection(ob: any): Array<iSubfamily> {
        let iSubfamilyCollection: Array<iSubfamily> = [];
        ob.forEach((element: any) => {
            iSubfamilyCollection.push(this.convertFromObject(element));
        });
        return iSubfamilyCollection;
    }

    static convertFromObject(ob: any): iSubfamily {
        let iSubfamilyObj = new iSubfamily();
        iSubfamilyObj.id = ob.id;
        iSubfamilyObj.code = ob.code;
        iSubfamilyObj.description = ob.description;
        iSubfamilyObj.note = ob.note;
        iSubfamilyObj.family = ob.family ? iFamily.convertFromObject(ob.family) : iFamily.createEmptyObject();
        return iSubfamilyObj;
    }

    static createEmptyObject(): iSubfamily {
        let iSubfamilyObj = new iSubfamily();
        iSubfamilyObj.code = '';
        iSubfamilyObj.description = '';
        iSubfamilyObj.note = '';
        iSubfamilyObj.family = iFamily.createEmptyObject();
        return iSubfamilyObj;
    }
}

export interface iSubfamilyFilter {
    num_devoluciones: number;
    num_pagina: number;
    genericFilter?: string;
    code?: string,
    description?: string,
    note?: string,
    family?: number,
}

export interface iSubfamilyCreate {
    code?: string,
    description?: string,
    note?: string,
    family?: iFamily,
}