import { iBaseObject } from "@quasar_dynamics/basic-designsystem";
import { iCompany } from "./iCompany";
import { iAddress } from "./iAddress";

export interface iAgent {
    id?: number,
    name?: string,
    nif?: string,
    email?: string,
    code?: string,
    comision?: number,
    company?: iCompany[],
    address?: iAddress | [],
}

export class iAgent extends iBaseObject {
    static convertFromCollection(ob: any): Array<iAgent> {
         
        let iAgentCollection: Array<iAgent> = [];
        ob.forEach((element: any) => {
            iAgentCollection.push(this.convertFromObject(element));
        });
        return iAgentCollection;
    }

    static convertFromObject(ob: any): iAgent {
        let iAgentObj = new iAgent();
        iAgentObj.id = ob.id;
        iAgentObj.name = ob.name;
        iAgentObj.nif = ob.nif;
        iAgentObj.email = ob.email;
        iAgentObj.code = ob.code;
        iAgentObj.comision = ob.comision;
        iAgentObj.company = ob.company ? iCompany.convertFromCollection(ob.company) : [];
        iAgentObj.address = ob.address ? iAddress.convertFromObject(ob.address) : new iAddress();
        return iAgentObj;
    }

    static createEmptyObject(): iAgent {
        let iAgentObj = new iAgent();
        iAgentObj.name = '';
        iAgentObj.nif = '';
        iAgentObj.email = '';
        iAgentObj.code = '';
        iAgentObj.comision = -1;
        iAgentObj.company = [];
        iAgentObj.address = new iAddress();
        return iAgentObj;
    }
}

export interface iAgentFilter {
    num_devoluciones: number;
    num_pagina: number;
    genericFilter?: string;
    name?: string,
    nif?: string,
    email?: string,
    code?: string,
    comision?: number,
    company?: number,
    address?: number,
}