import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultBaseService, iResultHttp, iStaticUtilities } from '@quasar_dynamics/basic-designsystem';
import { iContainer, iContainerFilter } from 'src/app/Shared/Interfaces/iContainer';

@Injectable({
  providedIn: 'root'
})
export class DeliveryNoteService extends DefaultBaseService {

  res: iResultHttp = new iResultHttp();

  constructor(private http: HttpClient) {
    super();
  }

  getLastAlbaranId() {
    this.http.get(this.urlApi + '/api/delivery/note/last', this.getHeader()).subscribe({
      next: (data: any) => {
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: data });
        this.sendNextResult('result', this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultError', this.res, { method: this.getLastAlbaranId, args: [] });
        this.checkStatusError(error);
      }
    })
  }
  getSingle(id:number) {
    this.http.get(this.urlApi + '/api/delivery/note/'+id, this.getHeader()).subscribe({
      next: (data: any) => {
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: data });
        this.sendNextResult('resultIndividual', this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultIndividualError', this.res, { method: this.getSingle, args: [id] });
        this.checkStatusError(error);
      }
    })
  }
  getAllAlbaran(queryParams:any) {
    this.http.get(this.urlApi + '/api/delivery/note/' + this.optionsGet(queryParams), this.getHeader()).subscribe({
      next: (data: any) => {
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: {data:data.data, total:data.total} });
        this.sendNextResult('result', this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultError', this.res, { method: this.getAllAlbaran, args: [queryParams] });
        this.checkStatusError(error);
      }
    })
  }
  create(albaran:any){
    this.http.post(this.urlApi+"/api/delivery/note", albaran, this.getHeader()).subscribe({
      next: (data:any) => {
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: data["data"]});
        this.sendNextResult('resultUpdate', this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultUpdateError', this.res, { method: this.create, args: [albaran] });
        this.checkStatusError(error);
      },
    });
  }
  update(albaran:any){
    this.http.put(this.urlApi+"/api/delivery/note/"+albaran.id, albaran, this.getHeader()).subscribe({
      next: (data:any) => {
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: data["data"]});
        this.sendNextResult('resultUpdate', this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultUpdateError', this.res, { method: this.update, args: [albaran] });
        this.checkStatusError(error);
      },
    });
  }

}
