<div class="popupGeneralContainer">
  <div class="header">
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
    <p *ngIf="modalData.accion=='editar'">Editar tipo incidencia</p>
    <p *ngIf="modalData.accion=='crear'">Crear tipo incidencia</p>
    <p *ngIf="modalData.accion=='ver'">Ver tipo incidencia</p>
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
  </div>
  <div class="body">
    <CustomInput [readonly]="modalData.accion=='ver'" classList="fullWidth tall special" placeHolder="Nombre"
      label="Nombre" [(ngModel)]="name" name="name"
      (change)="modalData.accion ==='editar'? editSecction(name, 'name') :checkIsCompleted()"></CustomInput>
    <CustomTextArea [readonly]="modalData.accion=='ver'" placeholder="Descripción" label="Descripción"
      [(ngModel)]="descripcion"
      (change)="modalData.accion ==='editar'? editSecction(descripcion, 'description') :checkIsCompleted()">
    </CustomTextArea>
  </div>
  <div class="footer" *ngIf="modalData.accion!='ver'">
    <btn classList="secundario" (click)="forceClosePopup()">
      <ng-container>
        Cancelar
      </ng-container>
    </btn>
    <btn classList="secundaryButton" (click)="create()" [class.disabled]="!isCompleted" *ngIf="modalData.accion=='crear'">
      <ng-container >
        Crear
      </ng-container>
    </btn>
    <btn classList="secundaryButton" (click)="closePopup()" [class.disabled]="!isCompleted" *ngIf="modalData.accion=='editar'">
      <ng-container >
        Editar
      </ng-container>
    </btn>
  </div>
</div>