import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultBaseService, iResultHttp, iStaticUtilities } from '@quasar_dynamics/basic-designsystem';
import { iFormula, iFormulaFilter } from 'src/app/Shared/Interfaces/iFormula';

@Injectable({
  providedIn: 'root'
})
export class FormulaService extends DefaultBaseService {

  res: iResultHttp = new iResultHttp();

  constructor(private http: HttpClient) {
    super();
  }

  getAll(queryParams: iFormulaFilter){
    this.http.get(this.urlApi + '/api/formula' + this.optionsGet(queryParams), this.getHeader()).subscribe({
      next: (data:any) => {
        let normalizedFormula = iFormula.convertFromCollection( iStaticUtilities.normalizeNamesCollection(data.data) );
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: {data:normalizedFormula, total:data.total}});
        this.sendNextResult('result', this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultError', this.res, { method: this.getAll, args: [queryParams] });
        this.checkStatusError(error);
      },
    });
  }
  getArticlesAndRawMaterials(queryParams: iFormulaFilter){
    this.http.get(this.urlApi + '/api/formula/list' + this.optionsGet(queryParams), this.getHeader()).subscribe({
      next: (data:any) => {
        let normalizedFormula =  iStaticUtilities.normalizeNamesCollection(data) ;
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: normalizedFormula});
        this.sendNextResult('result', this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultError', this.res, { method: this.getArticlesAndRawMaterials, args: [queryParams] });
        this.checkStatusError(error);
      },
    });
  }
  getFromulaTabla(queryParams: iFormulaFilter){
    this.http.get(this.urlApi + '/api/formula/tabla' + this.optionsGet(queryParams), this.getHeader()).subscribe({
      next: (data:any) => {
        let normalizedFormula =  iStaticUtilities.normalizeNamesCollection(data.data) ;
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: {data:normalizedFormula, total:data.total}});
        this.sendNextResult('result', this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultError', this.res, { method: this.getFromulaTabla, args: [queryParams] });
        this.checkStatusError(error);
      },
    });
  }

  getSingle(id: number){
    this.http.get(this.urlApi + '/api/formula/'+id, this.getHeader()).subscribe({
      next: (data:any) => {
        let normalizedFormula =  iStaticUtilities.normalizeNames(data) ;
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: normalizedFormula});
        this.sendNextResult('resultIndividual', this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultIndividualError', this.res, { method: this.getSingle, args: [id] });
        this.checkStatusError(error);
      },
    });
  }

  create(formula:iFormula){
    this.http.post(this.urlApi+"/api/formula", formula, this.getHeader()).subscribe({
      next: (data:any) => {
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: data["data"]});
        this.sendNextResult('resultUpdate', this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultUpdateError', this.res, { method: this.create, args: [formula] });
        this.checkStatusError(error);
      },
    });
  }

  update(formula:any){
    this.http.put(this.urlApi+"/api/formula/"+formula.id, formula, this.getHeader()).subscribe({
      next: (data:any) => {
         
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: iFormula.convertFromObject( iStaticUtilities.normalizeNames(data) )});
        this.sendNextResult('resultUpdate', this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultUpdateError', this.res, { method: this.update, args: [formula] });
        this.checkStatusError(error);
      },
    });
  }
  updateFormulaDetail(formulaDetail:iFormula){
    this.http.put(this.urlApi+"/api/formula/detail/"+formulaDetail.id, formulaDetail, this.getHeader()).subscribe({
      next: (data:any) => {
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: data["data"]});
        this.sendNextResult('resultUpdate', this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultUpdateError', this.res, { method: this.update, args: [formulaDetail] });
        this.checkStatusError(error);
      },
    });
  }

  delete(ids: Array<number|string>){
    let idsObjs = this.convertArrayObject(ids, "ids");
    this.http.delete(this.urlApi+"/api/formula", this.sendBodyOptions(idsObjs) ).subscribe({
      next: (data:any) => {
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: data["data"]});
        this.sendNextResult('resultDelete', this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultDeleteError', this.res, { method: this.delete, args: [ids] });
        this.checkStatusError(error);
      },
    });
  }

  deletePdf(id, index){
     
    this.http.delete(this.urlApi+"/api/formula/delete/pdf/"+id+'/'+index, this.getHeader() ).subscribe({
      next: (data:any) => {
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: data["data"]});
        this.sendNextResult('resultDelete', this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultDeleteError', this.res, { method: this.deletePdf, args: [id, index] });
        this.checkStatusError(error);
      },
    });
  }

}
