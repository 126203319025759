<div class="popupGeneralContainer">
  <div class="header">
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
    <p>PERFIL</p>
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
  </div>
  <div class="body">
    <div class="customContainer">
      <label for="Pais">Nombre</label>
      <p>{{nombre}}</p>
    </div>
    <div class="customContainer">
      <label for="Pais">Email</label>
      <p>{{email}}</p>
    </div>
    <div class="customContainer">
      <div class="iconLine">
        <label for="Pais">Contraseña</label>
        <ng-container>
          <mat-icon class="outline" (click)="editPassword = !editPassword">edit</mat-icon>
        </ng-container>
      </div>
      <hr *ngIf="editPassword">
      <p *ngIf="!editPassword">{{password}}</p>
      <CustomInput classList="fullWidth tall special" [type]="'password'" placeholder="Contraseña antigua" label="Contraseña antigua"
      [(ngModel)]="passwordToSend.oldPassword" (change)="checkPasswords()" *ngIf="editPassword"></CustomInput>
      <CustomInput classList="fullWidth tall special" [type]="'password'" placeholder="Contraseña nueva" label="Contraseña nueva"
      [(ngModel)]="passwordToSend.newPassword" (change)="checkPasswords()" *ngIf="editPassword"></CustomInput>
      <CustomInput classList="fullWidth tall special" [type]="'password'" placeholder="Repita la contraseña" label="Repita la contraseña"
      [(ngModel)]="checkPassword" (change)="checkPasswords()" *ngIf="editPassword"></CustomInput>
      <btn *ngIf="editPassword" classList="fino" [class.disabled]="!checkedPasswords" >Enviar nueva contraseña</btn>
      <hr *ngIf="editPassword">
    </div>

    <!-- <div class="createUser">
      <btn class="align-self-start" classList="fino crm" (click)="createUserFlag == true? createUserFlag = false:createUserFlag = true">
        <ng-container *ngIf="!createUserFlag">Crear usuario</ng-container>  
        <ng-container *ngIf="createUserFlag"> Cerrar creacion de usuario </ng-container>
      </btn>
      <div class="createUserBlock" *ngIf="createUserFlag">
        <hr>
        <CustomInput classList="fullWidth tall special" placeholder="Email" label="Email"
        [(ngModel)]="newUser.email" (change)="checkNewUser()" ></CustomInput>
        <CustomInput classList="fullWidth tall special" [type]="password" placeholder="Contraseña" label="Contraseña"
        [(ngModel)]="newUser.password" (change)="checkNewUser()" ></CustomInput>
        <btn class="align-self-start" classList="fino" (click)="create()" [class.disabled]="!isCompletedUser">crear usuario</btn>
        <hr>

      </div>
    </div> -->

    <btn class="align-self-start" classList="fino secundario" (click)="logOut()">Cerrar sesión</btn>
  </div>
  <div class="footer">

    <btn classList="secundaryButton" (click)="closePopup()">
      <ng-container>
        Cerrar
      </ng-container>
    </btn>
  </div>
</div>