<div class="fullAccordion" *ngIf="isActive">

  <pill class="tablePil" classList="tablePill">
    <dataTable [data]="dataForTable" [isLoading]="isLoading" [options]="optionsTabla"
      [displayedColumns]="displayedColumnsTabla"></dataTable>
    <div class="calculo">





      <div class="notas">
        <p>NOTAS :</p>
        <p>{{data.note}}</p>
      </div>





      <div class="tablaCalculo">
        <div class="row">
          <div class="col">
            <p class="header">Base imponible</p>
          </div>
          <div class="col value">
            <p>{{baseImponible | currency:'EUR'}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p class="header">IVA ({{data.company?.paymentMethods[0]?.typeIva.value | percent}})</p>
          </div>
          <div class="col value">
            <p>{{plusIva.toFixed(2) | currency:'EUR'}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p class="header">Recargo de equivalencia</p>
          </div>
          <div class="col value">
            <p>{{recargoEquivalencia.toFixed(2) | currency:'EUR'}}</p>
          </div>
        </div>
        <div class="row total">
          <div class="col">
            <p class="header">TOTAL</p>
          </div>
          <div class="col value">
            <p>{{total.toFixed(2) | currency:'EUR'}}</p>
          </div>
        </div>
      </div>

    </div>

  </pill>

  <pill>
    <div class="secondSection">

      <div class="riesgoTotalCLiente">
        <p class="riesgoTotalHeader">Riesgo total del cliente: </p>
        <p class="riesgoTotalValue" [ngClass]="{green: data.company?.paymentMethods[0]?.maxRisk > total, red: data.company?.paymentMethods[0]?.maxRisk < total}">{{data.company?.paymentMethods[0]?.maxRisk | currency:'EUR'}} </p>
      </div>
      <div class="leyenda">
        <p>T: tarifa</p>
        <p>F: ficha</p>
      </div>
    </div>
  </pill>
</div>