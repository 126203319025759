<div class="popupGeneralContainer">
  <div class="header">
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
    <p *ngIf="modalData.accion=='editar'">Editar Cliente</p>
    <p *ngIf="modalData.accion=='crear'">Crear Cliente</p>
    <p *ngIf="modalData.accion=='ver'">Ver Cliente</p>
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
  </div>
  <div class="body">
    <div class="datos">
      <p class="header">INFORMACIÓN GENERAL</p>
      <CustomInput [readonly]="modalData.accion=='ver'" classList="fullWidth tall special" placeholder="Código"
        label="Código" [(ngModel)]="codigo" (change)="modalData.accion=='editar' ? editCompany(codigo,'code') : checkIsCompleted()"></CustomInput>
      <CustomInput [readonly]="modalData.accion=='ver'" classList="fullWidth tall special" placeholder="Nombre"
        label="Nombre" [(ngModel)]="nombre" (change)="modalData.accion=='editar' ? editCompany(nombre,'name') : checkIsCompleted()"></CustomInput>
      <CustomInput [readonly]="modalData.accion=='ver'" classList="fullWidth tall special" placeholder="NIF" label="NIF"
        [(ngModel)]="nif" (change)="modalData.accion=='editar' ? editCompany(nif,'nif') : checkIsCompleted()"></CustomInput>
      <CustomInput [readonly]="modalData.accion=='ver'" classList="fullWidth tall special" placeholder="Direccion"
        label="Direccion" [(ngModel)]="direccion" (change)="modalData.accion=='editar' ? editAddress(direccion,'street') : checkIsCompleted()"></CustomInput>
      <CustomInput [readonly]="modalData.accion=='ver'" classList="fullWidth tall special" placeholder="Municipio"
        label="Municipio" [(ngModel)]="municipio" (change)="modalData.accion=='editar' ? editAddress(municipio,'municipality') : checkIsCompleted()"></CustomInput>
      <CustomInput [readonly]="modalData.accion=='ver'" classList="fullWidth tall special" placeholder="Código Postal"
        label="Código Postal" [(ngModel)]="cp" (change)="modalData.accion=='editar' ? editAddress(cp,'postalCode') : checkIsCompleted()"></CustomInput>
      <div class="customContainer">
        <label for="Provincia">Provincia</label>
        <selector (change)="modalData.accion=='editar' ? editAddress(provincia,'province') : checkIsCompleted()" [options]="opcionesProvincias" [(ngModel)]="provincia"></selector>
      </div>
      <div class="customContainer">
        <label for="Pais">País</label>
        <selector (change)="modalData.accion=='editar' ? editAddress(pais,'country') : checkIsCompleted()" [options]="opcionesPaises" [(ngModel)]="pais"></selector>
      </div>
      <div></div>
      <CustomInput [readonly]="modalData.accion=='ver'"
      classList="fullWidth tall special" placeholder="Teléfono" label="Teléfonos de contacto" [(ngModel)]="telefono"
      (change)="modalData.accion=='editar' ? editCompany(telefono,'telefono1') : checkIsCompleted()"></CustomInput>
      <CustomInput [readonly]="modalData.accion=='ver'"
      classList="fullWidth tall special" placeholder="Teléfono" [(ngModel)]="telefono2" (change)="modalData.accion=='editar' ? editCompany(telefono2,'telefono2') : checkIsCompleted()">
    </CustomInput>
      <CustomInput [readonly]="modalData.accion=='ver'"
        classList="fullWidth tall special" placeholder="Teléfono" label="" [(ngModel)]="telefono3" (change)="modalData.accion=='editar' ? editCompany(telefono3,'telefono3') : checkIsCompleted()">
      </CustomInput>
      <CustomInput [readonly]="modalData.accion=='ver'"
      classList="fullWidth tall special" placeholder="Email" label="Emails de contacto" [(ngModel)]="email"
      (change)="modalData.accion=='editar' ? editCompany(email,'email1') : checkIsCompleted()"></CustomInput>
      <CustomInput [readonly]="modalData.accion=='ver'"
      classList="fullWidth tall special" placeholder="Email " [(ngModel)]="email2" (change)="modalData.accion=='editar' ? editCompany(email2,'email2') : checkIsCompleted()">
    </CustomInput>
    <CustomInput [readonly]="modalData.accion=='ver'"
    classList="fullWidth tall special" placeholder="Email " [(ngModel)]="email3" (change)="modalData.accion=='editar' ? editCompany(email3,'email3') : checkIsCompleted()">
  </CustomInput>
    <CustomInput [readonly]="modalData.accion=='ver'"
    classList="fullWidth tall special" placeholder="Email de pedidos " label="Email de pedidos" [(ngModel)]="orderEmails" (change)="modalData.accion=='editar' ? editCompany(orderEmails,'orderEmails') : checkIsCompleted()">
  </CustomInput>
    </div>
    
    <div class="datos">
      <p class="header">FORMAS DE PAGO</p>
      <div class="customContainer">
        <label for="formaDePago">Forma de pago</label>
        <selector (change)="modalData.accion=='editar' ? editPayment(formaDePago,'wayToPay') : checkIsCompleted()" [options]="opcionesFormasPago" [(ngModel)]="formaDePago"></selector>
      </div>
      <CustomInput [readonly]="modalData.accion=='ver'" classList="fullWidth tall special" placeholder="IBAN"
        label="IBAN" [(ngModel)]="iban" (change)="modalData.accion=='editar' ? editPayment(iban,'iban') : checkIsCompleted()"></CustomInput>
        <CustomInput [readonly]="modalData.accion=='ver'" classList="fullWidth tall special" placeholder="BIC" label="BIC"
        [(ngModel)]="bic" (change)="modalData.accion=='editar' ? editPayment(bic,'bic') : checkIsCompleted()"></CustomInput>
        <div class="customContainer">
          <label for="formaDePago">Tipo IVA</label>
          <selector (change)="modalData.accion=='editar' ? editPayment(tipoIVA,'typeIva') : checkIsCompleted()" [options]="opcionesTipoIVA" [(ngModel)]="tipoIVA"></selector>
        </div>
        <div class="customContainer">
          <label for="formaDePago">Tarifa Asignada</label>
          <selector (change)="modalData.accion=='editar' ? editPayment(recargoTarifaAsignada,'tarifa') : checkIsCompleted()" [options]="opcionesTarifaAsignada" [(ngModel)]="recargoTarifaAsignada">
          </selector>
        </div>
        <div class="customContainer">
          <label for="formaDePago">Recargo de equivalencia</label>
          <selector (change)="modalData.accion=='editar' ? editPayment(recargoEquivalencia,'surcharge') : checkIsCompleted()" [options]="opcionesRecargoEquivalencia" [(ngModel)]="recargoEquivalencia">
          </selector>
        </div>
      <CustomInput [readonly]="modalData.accion=='ver'" classList="fullWidth tall special"
        placeholder="Riesgo Económico Max. Aceptado" label="Riesgo Económico Max. Aceptado" [(ngModel)]="riesgoMax"
        (change)="modalData.accion=='editar' ? editPayment(riesgoMax,'maxRisk') : checkIsCompleted()"></CustomInput>
      <div class="customContainer">
        <label for="formaDePago">Portes</label>
        <selector (change)="modalData.accion=='editar' ? editCompany(portes,'porte') : checkIsCompleted()" [options]="opcionesPortes" [(ngModel)]="portes"></selector>
      </div>
    </div>

    <div class="datos">
      <p class="header">INFORMACIÓN EXTRA</p>
      <div class="customContainer" class="agente">
        <label for="formaDePago">Agente</label>
        <selector (change)="modalData.accion=='editar' ? editCompany(agente,'agents') : checkIsCompleted()" [options]="opcionesAgente" [(ngModel)]="agente"></selector>
      </div>
      <CustomTextArea [readonly]="modalData.accion=='ver'" class="textArea observaciones" placeHolder="Observaciones"
        label="Observaciones" [(ngModel)]="observaciones" name="Observaciones" (change)="modalData.accion=='editar' ? editCompany(observaciones,'observation') : checkIsCompleted()"
       >
      </CustomTextArea>
    </div>


  </div>
  <div class="footer" *ngIf="modalData.accion!='ver'">
    <btn classList="secundario" (click)="forceClosePopup()">
      <ng-container>
        Cancelar
      </ng-container>
    </btn>
    <btn classList="secundaryButton" (click)="createCliente()" [class.disabled]="!isCompleted" *ngIf="modalData.accion=='crear'">
      <ng-container >
        Crear
      </ng-container>
    </btn>
    <btn classList="secundaryButton" (click)="closePopup('editar')" [class.disabled]="!isCompleted" *ngIf="modalData.accion=='editar'">
      <ng-container >
        Editar
      </ng-container>
    </btn>
  </div>
</div>