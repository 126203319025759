import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { iUnsubscribeDestroy } from '@quasar_dynamics/basic-designsystem';

@Component({
  selector: 'app-EditarCondicionalesTarifas-Popup',
  templateUrl: './EditarCondicionalesTarifas-Popup.component.html',
  styleUrls: ['./EditarCondicionalesTarifas-Popup.component.scss']
})
export class EditarCondicionalesTarifasPopupComponent extends iUnsubscribeDestroy implements OnInit {

  maxFactor: number | null = null;
  minAporte: number | null = null;


  constructor(public dialogRef: MatDialogRef<EditarCondicionalesTarifasPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any,) {
    super();
  }

  ngOnInit() {

  }

  /**
* FUNCTIONALITY
*/
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }




}
