import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { iDate, iUnsubscribeDestroy } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { BillService } from 'src/app/Services/Api/Bill.service';
import { DeliveryNoteService } from 'src/app/Services/Api/DeliveryNote.service';
import { PaymentMethodService } from 'src/app/Services/Api/PaymentMethod.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'NuevaFacturaSeccion1-popup',
  templateUrl: './NuevaFacturaSeccion1-popup.component.html',
  styleUrls: ['./NuevaFacturaSeccion1-popup.component.scss']
})
export class NuevaFacturaSeccion1PopupComponent extends iUnsubscribeDestroy implements OnInit {

  @Input() modalData: any = null;
  @Output() isComplete = new EventEmitter<any>();
  data: any = {
    numAlbaran: 0,
    paymentDate: 0,
    date: new Date(),
    id: 0,
    albaranDate: '',
    note: ''
  }

  constructor(private deliveryNoteSE: DeliveryNoteService, private billSE: BillService, private paymentMethodSE: PaymentMethodService) {
    super();
  }

  ngOnInit() {
    this.getDeliveryNoteId()
    this.getLastBillId()
  }

  /**
   * GETTERS && SETTERS
   */

  setter(data: any) {
    this.data.numAlbaran = data.id;
    this.data.albaranDate = data.date;
    this.checkIsComplete()
  }

  /**
   * FUNCTIONALITY
   */

  checkIsComplete() {
    this.isComplete.emit(this.data);
  }

  /**
   * API CALLS
   */

  getDeliveryNoteId() {
    this.deliveryNoteSE.getSingle(this.modalData.data.id);
    this.deliveryNoteSE.getResultIndividual().pipe(takeUntil(this._unsubInd)).subscribe((res: any) => {
      if (!res) return;
      this.getDaysPayment(res.data.ordered[0].company.id);
      this.setter(res.data);
      this._unsubInd.next('')
    })
  }
  getLastBillId() {
    this.billSE.getLastBillId();
    this.billSE.getResultIndividual().pipe(takeUntil(this._unsubInd2)).subscribe((res: any) => {
      if (!res) { return };
      this.data.id = Number(res.data) + 1;
      this.checkIsComplete()
      this._unsubInd2.next('')
    })
  }
  getDaysPayment(id) {
    this.paymentMethodSE.getPaymentDaysOfClient(id);
    this.paymentMethodSE.getResultIndividual().pipe(takeUntil(this._unsubInd3)).subscribe((res: any) => {
      if (!res) return;
      this.data.paymentDate = new Date(new Date().setDate(new Date().getDate() + res.data));
      this.checkIsComplete()
      this._unsubInd3.next('')
    })
  }


}
