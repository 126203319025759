import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { ClientesDetailViewComponent } from './Pages/Section-CRM/CRM-Clientes/ClientesDetailView/ClientesDetailView.component';
import { CRMClientesComponent } from './Pages/Section-CRM/CRM-Clientes/CRM-Clientes.component';
import { ClientesAntiguosDetailViewComponent } from './Pages/Section-CRM/CRM-Clientes/_holder/ClientesAntiguos/ClientesAntiguosDetailView/ClientesAntiguosDetailView.component';
import { ClientesCPotencialesComponent } from './Pages/Section-CRM/CRM-Clientes/_holder/ClientesCPotenciales/ClientesCPotenciales.component';
import { CPotencialesDetailViewComponent } from './Pages/Section-CRM/CRM-Clientes/_holder/ClientesCPotenciales/CPotencialesDetailView/CPotencialesDetailView.component';
import { MaquinasDetailViewComponent } from './Pages/Section-CRM/CRM-Clientes/_holder/ClientesMaquinas/MaquinasDetailView/MaquinasDetailView.component';
import { CRMDashboardComponent } from './Pages/Section-CRM/CRM-Dashboard/CRM-Dashboard.component';
import { ERPClientesComponent } from './Pages/Section-ERP/ERP-Clientes/ERP-Clientes.component';
import { ERPDashboardComponent } from './Pages/Section-ERP/ERP-Dashboard/ERP-Dashboard.component';
import { ERPTarifasComponent } from './Pages/Section-ERP/ERP-Tarifas/ERP-Tarifas.component';
import { ERPMantenimientosDetallesComponent } from './Pages/Section-ERP/ERP-Mantenimientos/ERP-Mantenimientos-Detalles/ERP-Mantenimientos-Detalles.component';
import { ERPMantenimientosComponent } from './Pages/Section-ERP/ERP-Mantenimientos/ERP-Mantenimientos.component';
import { ForgotPasswordComponent } from './Pages/Section-Login/ForgotPassword/ForgotPassword.component';
import { LoginPageComponent } from './Pages/Section-Login/LoginPage/LoginPage.component';
import { ClienteDetailViewComponent } from './Pages/Section-ERP/ERP-Clientes/_holder/ERP-Clientes-CLientesLista/ERP-ClienteDetailView/ClienteDetailView.component';
import { ERPProveedoresComponent } from './Pages/Section-ERP/ERP-Proveedores/ERP-Proveedores.component';
import { ERPProveedoresDetallesComponent } from './Pages/Section-ERP/ERP-Proveedores/_Holders/ERP-Proveedores-Proveedores/ERP-Proveedores-Detalles/ERP-Proveedores-Detalles.component';
import { ClientesPedidosDetailComponent } from './Pages/Section-ERP/ERP-Clientes/_holder/ERP-Clientes-Pedidos/Clientes-Pedidos-Detail/Clientes-Pedidos-Detail.component';
import { ERPMateriasPrimasDetallesComponent } from './Pages/Section-ERP/ERP-Proveedores/_Holders/ERP-Proveedores-MateriasPrimas/ERP-MateriasPrimas-Detalles/ERP-MateriasPrimas-Detalles.component';
import { ERPPartesProduccionComponent } from './Pages/Section-ERP/ERP-PartesProduccion/ERP-PartesProduccion.component';
import { ERPPedidosDetallesComponent } from './Pages/Section-ERP/ERP-Proveedores/_Holders/ERP-Proveedores-Pedidos/ERP-Pedidos-Detalles/ERP-Pedidos-Detalles.component';
import { ERPStockComponent } from './Pages/Section-ERP/ERP-Stock/ERP-Stock.component';
import { ERPStockArticulosDetailViewComponent } from './Pages/Section-ERP/ERP-Stock/_holder/ERP-Stock-Articulos/ERP-Stock-Articulos-DetailView/ERP-Stock-Articulos-DetailView.component';
import { ERPStockMateriasPrimasDetailViewComponent } from './Pages/Section-ERP/ERP-Stock/_holder/ERP-Stock-MateriasPrimas/ERP-Stock-MateriasPrimas-DetailView/ERP-Stock-MateriasPrimas-DetailView.component';
import { ERPContabilidadComponent } from './Pages/Section-ERP/ERP-Contabilidad/ERP-Contabilidad.component';
import { ERPPPPartesProduccionDetailViewComponent } from './Pages/Section-ERP/ERP-PartesProduccion/_holder/ERP-PP-PartesProduccion/ERP-PP-PartesProduccion-DetailView/ERP-PP-PartesProduccion-DetailView.component';
import { CRMEstadisticasComponent } from './Pages/Section-CRM/CRM-Estadisticas/CRM-Estadisticas.component';
import { ERPPPFormulasDVComponent } from './Pages/Section-ERP/ERP-PartesProduccion/_holder/ERP-PP-Formulas/ERP-PP-Formulas-DV/ERP-PP-Formulas-DV.component';
import { CRMAlertasComponent } from './Pages/Section-CRM/CRM-Alertas/CRM-Alertas.component';
import { CRMDocumentosComponent } from './Pages/Section-CRM/CRM-Documentos/CRM-Documentos.component';
import { CRMDocumentosDetallesCarpetaComponent } from './Pages/Section-CRM/CRM-Documentos/CRM-Documentos-DetallesCarpeta/CRM-Documentos-DetallesCarpeta.component';
import { CRMAccionesComercialesComponent } from './Pages/Section-CRM/CRM-AccionesComerciales/CRM-AccionesComerciales.component';
import { LoggedInGuard } from './Services/Utils/Guards/logged-in.guard';
import { ERPUsersComponent } from './Pages/Section-ERP/ERP-Users/ERP-Users.component';
import { RecoverPasswordComponent } from './Pages/Section-Login/RecoverPassword/RecoverPassword.component';

const routes: Routes = [
  { path: '', component: LoginPageComponent, pathMatch: 'full'},
  // Login
  { path: 'login', component: LoginPageComponent, data: { title: 'Login', } },
  { path: 'forgotPassword', component: ForgotPasswordComponent, data: { title: 'Login', } },
  { path: 'recoverPassword', component: RecoverPasswordComponent, data: { title: 'Login', } },
  // CRM
  // {
  //   path: 'crm', data: { title: 'CRM', }, canActivate:[LoggedInGuard], children: [
  //     { path: '', redirectTo: '/crm/dashboard', pathMatch: 'full' },
  //     { path: 'dashboard', component: CRMDashboardComponent, data: { title: 'Dashboard', } },
  //     { path: 'clientes', component: CRMClientesComponent, data: { title: 'Clientes', } },
  //     { path: 'clientes/:id', component: ClientesDetailViewComponent, data: { title: 'Clientes Detalle', } },
  //     { path: 'clientes/cpotencial/:id', component: CPotencialesDetailViewComponent, data: { title: 'Clientes Potenciales Detalle', } },
  //     { path: 'clientes/maquinas/:id', component: MaquinasDetailViewComponent, data: { title: 'Clientes Máquinas Detalle', } },
  //     { path: 'clientes/antiguos/:id', component: ClientesAntiguosDetailViewComponent, data: { title: 'Clientes Antiguos Detalle', } },
  //     { path: 'estadisticas', component: CRMEstadisticasComponent, data: { title: 'Estadisticas', } },
  //     { path: 'alertas', component: CRMAlertasComponent, data: { title: 'Alertas', } },
  //     { path: 'documentos', component: CRMDocumentosComponent, data: { title: 'Documentos', } },
  //     { path: 'documentos/carpeta', component: CRMDocumentosDetallesCarpetaComponent, data: { title: 'Carpeta', } },
  //     { path: 'acciones-comerciales', component: CRMAccionesComercialesComponent, data: { title: 'Acciones comerciales', } },
  //   ]
  // },
  // ERP
  {
    path: 'erp', data: { title: 'ERP', },canActivate:[LoggedInGuard], children: [
      { path: '', redirectTo: '/erp/dashboard', pathMatch: 'full' },
      // { path: 'users', component: ERPUsersComponent, data: { title: 'Usuarios', } },
      { path: 'dashboard', component: ERPDashboardComponent, data: { title: 'Dashboard', } },
      { path: 'mantenimientos', component: ERPMantenimientosComponent, data: { title: 'Mantenimientos', } },
      { path: 'mantenimientos/detalles-mantenimiento', component: ERPMantenimientosDetallesComponent,  data: { title: 'Detalles de mantenimiento', },},
      { path: 'tarifas', component: ERPTarifasComponent, data: { title: 'Finanzas', } },
      { path: 'proveedores', component: ERPProveedoresComponent, data: { title: 'Proveedores', } },
      { path: 'proveedores/detalles-proveedor', component: ERPProveedoresDetallesComponent, data: { title: 'Detalles del proveedor', } },
      { path: 'proveedores/detalles-materia-prima', component: ERPMateriasPrimasDetallesComponent, data: { title: 'Detalles de la materia prima', } },
      { path: 'proveedores/detalles-pedido', component: ERPPedidosDetallesComponent, data: { title: 'Detalles del pedido', } },
      { path: 'clientes', component: ERPClientesComponent,  data: { title: 'Clientes', },},
      { path: 'clientes/detalles-cliente', component: ClienteDetailViewComponent,  data: { title: 'Clientes Detalle', },},
      { path: 'clientes/detalles-pedido', component: ClientesPedidosDetailComponent,  data: { title: 'Clientes Pedidos Detalle', },},
      { path: 'partesproduccion', component: ERPPartesProduccionComponent,  data: { title: 'Partes de produccion', },},
      { path: 'partesproduccion/partes', component: ERPPPPartesProduccionDetailViewComponent,  data: { title: 'Detalles del parte de produccion', },},
      { path: 'partesproduccion/formula', component: ERPPPFormulasDVComponent,  data: { title: 'Detalles de la fórmula', },},
      { path: 'stock', component: ERPStockComponent,  data: { title: 'Stock', },},
      { path: 'stock/articulos', component: ERPStockArticulosDetailViewComponent,  data: { title: 'Stock artículos', },},
      { path: 'stock/materiasprimas', component: ERPStockMateriasPrimasDetailViewComponent,  data: { title: 'Stock materias primas', },},
      { path: 'contabilidad', component: ERPContabilidadComponent,  data: { title: 'Contabilidad', },},
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
