import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { iOptionsSelector, iUnsubscribeDestroy } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { CompanyService } from 'src/app/Services/Api/Company.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'NuevaPrevisionDePagos-Popup',
  templateUrl: './NuevaPrevisionDePagos-Popup.component.html',
  styleUrls: ['./NuevaPrevisionDePagos-Popup.component.scss']
})
export class NuevaPrevisionDePagosPopupComponent extends iUnsubscribeDestroy implements OnInit {

  descripcion: string = "";
  notas: string = ""
  isCompleted: boolean = false;
  document: any = null;
  documentLoaded: any = null;
  nPedido: string = "";
  data: any = {
    fechaFactura: new Date(),
    fechaPago: new Date(),
    proveedor: null,
    baseImponible: null,
    iva: null,
    irpf: null,
    state: null,
    adjuntos: [],
  }
  opcionesEstados: iOptionsSelector = {
    items: [
      { id: 0, label: "Pendiente recibir" },
      { id: 1, label: "Pendiente pago" },
      { id: 2, label: "Pagada" },
    ],
    search: true,
    placeholder: "Estado",
    append: true,
  }
  opcionesProveedor: iOptionsSelector = {
    items: [],
    search: true,
    placeholder: "Proveedor",
    bindLabel:'name',
    bindValue:'id',
    append: true,
  }

  constructor(public dialogRef: MatDialogRef<NuevaPrevisionDePagosPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any,private companySE:CompanyService) {
    super();
  }

  ngOnInit() {
    if (this.modalData.accion == 'editar') {
      this.setter();
    }
    this.GetProveedoresForFilter()
  }

  /**
  * FUNCTIONALITY
  */
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }

  /**
   * GETTERS && SETTERS
   */

  pedidoNum(event) {
    if(!event){delete this.data.nPedido}
    this.data.nPedido = event.target.value;
    this.checkIsCompleted();
  }

  setter() {
    this.data = this.modalData.data;
  }

  /**
   * VALIDATIONS
   */
  checkIsCompleted() {
    this.isCompleted = StaticUtilitiesService.isCompleteObject(this.data);
  }

  /**
   * Selector
   */

  getFromSelector(event, key) {
     
    if (!event) { this.data[key] = null }
    this.data[key] = event;
    this.checkIsCompleted();
    
  }

  /**
   *
   * Add documentos
   */

  addDocument(event: any) {
    if ((event[0].file.size < 5242880 && event[0].file.type == "application/pdf") || (event[0].file.size < 1048576 && (event[0].file.type != "image/png" || event[0].file.type != "image/jpeg"))) {
      if (event[0].file.type != "image/jpeg" && event[0].file.type != "image/png" && event[0].file.type != "application/pdf") {
        StaticUtilitiesService.showWarning("Formato de archivo no admitido")
        this.document = null;
      } else {
        event.forEach((element: any) => {
          let obj = {
            url: element.base64
          }
          if (this.data.adjuntos.length == 0) {
            this.data.adjuntos.push(obj)
          } else {
            this.data.adjuntos.push(obj)
          }
        });
      }
      this.documentLoaded = event[0].file.name;
      this.checkIsCompleted();
    } else {
      StaticUtilitiesService.showWarning("El tamaño del archivo o el formato no es correcto")
    }
  }



  /**
   * API CALLS
   */

  GetProveedoresForFilter() {
    this.companySE.getClientOrProveedor({num_devoluciones:8888888, num_pagina:1, isClient: false, isActive: true});
    this.companySE.getResult().pipe(takeUntil(this._unsubInd)).subscribe((res: any) => {
      if(!res){return};
      let {data} = res.data;
      this.opcionesProveedor.items = data
      this._unsubInd.next('')
    })
  }

}
