<pill class="minHeightPills">
  <ng-container>
    <div class="iconsAndContent">
      <div class="content">

        <div class="espacioParrafo" *ngFor="let direccion of direccionesAlternativas; let index = index">
         <p class="line"><span class="bold">Nombre: &nbsp; </span> {{direccion.nombre}} </p>
         <p class="line"><span class="bold">Direccion {{index}}: &nbsp; </span> {{direccion.direccion}} </p>
           
        </div>
      </div>
    </div>
  </ng-container>
</pill>