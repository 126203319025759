<div class="fullContainer">
  <div class="iconLine">
  </div>
  <div class="firstRow">
    <p class="bold">Código</p>
    <p class="bold">Descripción</p>
    <p class="bold">Cantidad Lt.</p>
    <p class="bold">Ocupación Kg</p>
    <p class="bold">Cantidad Producto</p>
    <p class="bold">Cantidad Envasado</p>
    <br>
  </div>
  <div class="rows" *ngFor="let row of data; let index = index">
    <p>{{row.code}}</p>
    <p>{{row.description}}</p>
    <p>{{row.occLiters || '-'}}</p>
    <p>{{row.occKg}}</p>
    <CustomInput classList="fullWidth tall special" [type]="'number'" [(ngModel)]="row.product" (keyup)="checkIsComplete()">{{row.product}}</CustomInput>
    <CustomInput classList="fullWidth tall special" [type]="'number'" [(ngModel)]="row.quantity" (keyup)="checkIsComplete()" *ngIf="index !== 0">{{row.quantity}}</CustomInput>
  </div>
</div>
