import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultBaseService, iResultHttp, iStaticUtilities } from '@quasar_dynamics/basic-designsystem';
import { iAgent, iAgentFilter } from 'src/app/Shared/Interfaces/iAgent';

@Injectable({
  providedIn: 'root'
})
export class AgentService extends DefaultBaseService {

  res: iResultHttp = new iResultHttp();

  constructor(private http: HttpClient) {
    super();
  }

  getAll(queryParams: iAgentFilter) {
    this.http.get(this.urlApi + '/api/agent' + this.optionsGet(queryParams), this.getHeader()).subscribe({
      next: (data: any) => {
        let normalizedAgent = iAgent.convertFromCollection(data.data);
         
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: { data: normalizedAgent, total: data.total } });
        this.sendNextResult('result', this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultError', this.res, { method: this.getAll, args: [queryParams] });
        this.checkStatusError(error);
      },
    });
  }

  getSingle(id: number) {
    this.http.get(this.urlApi + '/api/agent/' + id, this.getHeader()).subscribe({
      next: (data: any) => {
        let normalizedAgent = iAgent.convertFromObject(iStaticUtilities.normalizeNames(data));
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: normalizedAgent });
        this.sendNextResult('resultIndividual', this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultIndividualError', this.res, { method: this.getSingle, args: [id] });
        this.checkStatusError(error);
      },
    });
  }

  getLastAgent() {
    this.http.get(this.urlApi + '/api/agent/last', this.getHeader()).subscribe({
      next: (data: any) => {
        let normalizedAgent = data;
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: normalizedAgent });
        this.sendNextResult('resultIndividual', this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultIndividualError', this.res, { method: this.getLastAgent, args: [] });
        this.checkStatusError(error);
      },
    });
  }

  create(agent: iAgent) {
    this.http.post(this.urlApi + "/api/agent", agent, this.getHeader()).subscribe({
      next: (data: any) => {
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: data["data"] });
        this.sendNextResult('resultUpdate', this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultUpdateError', this.res, { method: this.create, args: [agent] });
        this.checkStatusError(error);
      },
    });
  }

  update(agent: iAgent) {
    this.http.put(this.urlApi + "/api/agent/" + agent.id, agent, this.getHeader()).subscribe({
      next: (data: any) => {
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: data["data"] });
        this.sendNextResult('resultUpdate', this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultUpdateError', this.res, { method: this.update, args: [agent] });
        this.checkStatusError(error);
      },
    });
  }

  delete(ids: Array<number | string>) {
    let idsObjs = this.convertArrayObject(ids, "ids");
    this.http.delete(this.urlApi + "/api/agent", this.sendBodyOptions(idsObjs)).subscribe({
      next: (data: any) => {
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: data["data"] });
        this.sendNextResult('resultDelete', this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultDeleteError', this.res, { method: this.delete, args: [ids] });
        this.checkStatusError(error);
      },
    });
  }

}
