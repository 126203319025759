import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'CustomTextArea',
  templateUrl: './CustomTextArea.component.html',
  styleUrls: ['./CustomTextArea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomTextAreaComponent),  // replace name as appropriate
      multi: true
    }
  ]
})
export class CustomTextAreaComponent implements OnInit {

  @Input() label: string = ""
  @Input() placeHolder: string = ""
  @Input() cantidadCaracteres: number | null = null
  @Input() cantidadMax: number | null = null
  @Input() disabled: boolean = false
  @Input() error: boolean = false;
  @Input() readonly: boolean = false;
  visible: boolean = false;

  constructor() { }

  //INTERNAL VALUES

  private _onChange = (_: any) => { };
  private _onTouched = () => { };
  private _oldData!: any;

  _disabled: boolean = false;
  model:any;

  writeValue(obj: any): void {
    this.model = obj;
  }

  setDisabledState?(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  onNgModelChange(event:any) {
    this._oldData = this.model;
    this.model = event;
  }

  onModelChange($event:any) {
    this.model = $event;
    this._onChange(this.model);
  }


  ngOnInit() { }


}
