import { Component, EventEmitter, Input, OnInit, Output, SimpleChange } from '@angular/core';

@Component({
  selector: 'Pagination',
  templateUrl: './Pagination.component.html',
  styleUrls: ['./Pagination.component.scss']
})
export class PaginationComponent implements OnInit {

  @Output() change = new EventEmitter<string>();

  // Paginacion

  @Input() paginacionTotal!: number;
  paginacionActual!: number;
  numMaxPag!: number;
  numMin: number = 1;
  numMax: number = 20;

  pagina: number = 1;
  maximo: number = 20;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes:SimpleChange) {
    if(changes['paginacionTotal']) {
      this.numMaxPag = Math.ceil(this.paginacionTotal / this.maximo)
      if (this.paginacionTotal < this.maximo) {this.numMax = this.paginacionTotal}
      else{this.numMax = 20}
    }
  }


  /**
 * NEXT PAGE
 */

  nextPage() {
    if (this.numMax === this.paginacionTotal) { return }
    this.pagina++
    this.numMin = this.numMin + this.maximo
    this.numMax = (this.numMax + this.maximo < this.paginacionTotal) ? this.numMax + this.maximo : this.paginacionTotal
    this.change.emit('next')
  }
  
  /**
   * PREVIOUS PAGE
  */
 
 previousPage() {
   if (this.numMin === 1) { return }
   this.numMin = this.numMin - this.maximo;
   this.numMax = (this.numMax - this.maximo < this.maximo) ? this.maximo : this.numMax - this.maximo
   this.pagina--
   this.change.emit('previous')
  }

}
