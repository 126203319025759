import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'NuevaFacturaSeccion2-popup',
  templateUrl: './NuevaFacturaSeccion2-popup.component.html',
  styleUrls: ['./NuevaFacturaSeccion2-popup.component.scss']
})
export class NuevaFacturaSeccion2PopupComponent implements OnInit {
  @Input() modalData: any = null;
  @Input() dataFromSection1: any = null;
  @Input() dataAlbaran: any = null;

  constructor() { }

  ngOnInit() {
  }

}
