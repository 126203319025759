import { Component, OnInit } from '@angular/core';
import { iUnsubscribeDestroy, PopupService } from '@quasar_dynamics/basic-designsystem';
import { EditarInformacionDelClientePopupComponent } from 'src/app/Popups/EditarInformacionDelCliente-Popup/EditarInformacionDelCliente-Popup.component';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'InformacionDeCliente',
  templateUrl: './InformacionDeCliente.component.html',
  styleUrls: ['./InformacionDeCliente.component.scss']
})
export class InformacionDeClienteComponent extends iUnsubscribeDestroy implements OnInit {

  constructor(private popupSE:PopupService) {
    super();
  }

  ngOnInit() {
  }

  edit(key:string){
    if(key === 'informacionCliente'){
      this.popupSE.openPopup(EditarInformacionDelClientePopupComponent, { accion: 'editar', elemento: 'Información del cliente', crm: true});
    }
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(res=>{
      if(!res) {return};
      this._unsubInd2.next('');
    })
  }

}
