import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { iDataTableColumns, iDataTableOptions, iUnsubscribeDestroy } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { ProductionReportService } from 'src/app/Services/Api/ProductionReport.service';

@Component({
  selector: 'CierreDeFabricacionParteCaracteristicas-Popup',
  templateUrl: './CierreDeFabricacionParteCaracteristicas-Popup.component.html',
  styleUrls: ['./CierreDeFabricacionParteCaracteristicas-Popup.component.scss']
})
export class CierreDeFabricacionParteCaracteristicasPopupComponent extends iUnsubscribeDestroy implements OnInit {

  @Input() parteId!: number;
  @Output() isComplete:EventEmitter<boolean> = new EventEmitter<boolean>() ;
  @Output() dataCharacteristics:EventEmitter<any> = new EventEmitter<any>() ;

  dataForTable: any = [];
  isLoading: boolean = false;
  optionsTabla: iDataTableOptions = {
    isHovered: true,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc',
    // moreIconsHead: [{ callback: (event) => { this.exportexcel() }, icon: "download" }],
  }
  displayedColumnsTabla: Array<iDataTableColumns> = [
    { label: 'Descripcion', property: 'descripcion' },
    { label: 'Valor', property: 'value', align: 'center' },
  ];


  constructor(private productionReportSE: ProductionReportService) {
    super();
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['parteId']) {
      if (changes['parteId'].currentValue) {
        this.getCharacteristicsBatchProductionReport()
        
      }
    }
  }

  /*
  * FUNCTIONALITY
   */

  checkIsComplete(){
    let check:any = this.dataForTable.filter((element:any) => {element.value === null || element.value === undefined || element.value === ''});
    if(check.length > 0){
      this.isComplete.emit(false)
    }
    if(check.length == 0){
      this.isComplete.emit(true)
      let characteristicsMany = this.dataForTable.map((element:any) => {return {characteristic: element.characteristic.id, value: element.value}}) 
      this.dataCharacteristics.emit(characteristicsMany)
    }
  }

  /**
   * API CALLS
   */

  getCharacteristicsBatchProductionReport() {
    this.isLoading = true;
    this.productionReportSE.getBatchCharacteristicsId(this.parteId);
    this.productionReportSE.getResultBatchCharacteristicsId().pipe(takeUntil(this._unsubInd)).subscribe(res=>{
      if(!res){return}
      let {data} = res;
      this.dataForTable = data.map((element:any) => {return {...element, descripcion: element.characteristic.description}});
      this.checkIsComplete()
      this.isLoading = false;
      this._unsubInd.next('')
    })
  }

}
