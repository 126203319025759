<FullContainer>
  <div class="estadisticasGeneralContainer">
    <div class="header">
      <p>ESTADÍSTICAS</p>
      <CustomToggle [selected]="selected" [selectores]="selectores" (selectedOption)="selected = $event"></CustomToggle>

    </div>
    <consumoProductos *ngIf="selected == 0"></consumoProductos>
    <consumoClientes *ngIf="selected == 1"></consumoClientes>
    <facturacion *ngIf="selected == 2"></facturacion>
    <conversion *ngIf="selected == 3"></conversion>
  </div>
</FullContainer>
