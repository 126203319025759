import { Component, EventEmitter, OnInit, Output } from '@angular/core';


@Component({
  selector: 'app-CRM-Clientes',
  templateUrl: './CRM-Clientes.component.html',
  styleUrls: ['./CRM-Clientes.component.scss']
})
export class CRMClientesComponent implements OnInit {

  selectedList:boolean = true;
  
  selected = 1;

  selectores = [
    { label: 'Mapa', icon: 'map', image:'none', selected: 0 ,before:'none'},
    { label: 'Clientes', icon: 'none', image:'none', selected: 1, before:'yes' },
    { label: 'C.Potenciales', icon: 'none', image:'none', selected: 2, before:'yes' },
    { label: 'C.Antiguos', icon: 'none', image:'none', selected: 3, before:'yes' },
    { label: 'Máquinas', icon: 'none', image:'none', selected: 4, before:'yes' },
  ]


  constructor() { }

  ngOnInit() {
  }

  selectedOption(event) {
    this.selected = event;
  }

  selectedListToggle(event) {
    this.selectedList = event;
  }

}
