<div class="fullAccordion">
  <pill class="minHeightPillsIconosDirecciones pillDirecciones" *ngFor="let direccion of data?.companyAddress">
    <ng-container>
      <div class="icon" >
        <ng-container>
          <mat-icon class="outline" (click)="edit(direccion)">
            edit
          </mat-icon>
        </ng-container>
      </div>
      <div class="pill">
        <p> <span class="bold">Direccion :</span> {{direccion?.street}} </p>
        <p> <span class="bold">Código Postal :</span> {{direccion?.postalCode}} </p>
        <p> <span class="bold">País :</span> {{direccion?.country}} </p>
        <p> <span class="bold">Número :</span> {{direccion?.number}} </p>
        <p> <span class="bold">Teléfonos :</span> {{direccion?.phones[0]}} </p>
        <p> <span class="bold">Municipio :</span> {{direccion?.municipality}} </p>
        <p> <span class="bold">Provincia :</span> {{direccion?.province}} </p>
      </div>
    </ng-container>
  </pill>
</div>