import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-CRM-Estadisticas',
  templateUrl: './CRM-Estadisticas.component.html',
  styleUrls: ['./CRM-Estadisticas.component.scss']
})
export class CRMEstadisticasComponent implements OnInit {
  selected = 0;
  selectores = [
    { label: 'Cons. Productos', image:'none', icon: 'none',selected: 0 ,before:'yes', color: "azul-verdoso"},
    { label: 'Cons. Clientes', icon: 'none', image:'none', selected: 1, before:'yes', color: "azul-verdoso" },
    { label: 'Facturación', icon: 'none', image:'none', selected: 2, before:'yes' , color: "azul-verdoso"},
    { label: 'Conversión', icon: 'none', image:'none', selected: 3, before:'yes' , color: "azul-verdoso"},
  ]
  constructor() { }

  ngOnInit() {
  }

}
