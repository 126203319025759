import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { iDataTableColumns, iDataTableOptions, slideNavigationTypes } from '@quasar_dynamics/basic-designsystem';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'ClientesCPotenciales',
  templateUrl: './ClientesCPotenciales.component.html',
  styleUrls: ['./ClientesCPotenciales.component.scss']
})
export class ClientesCPotencialesComponent implements OnInit {
  
  @Input('selectedList') selectedList: boolean = true;

  isLoading: boolean = false;

  //Tabla
  displayedColumnsTabla: Array<iDataTableColumns> = [
    { label: 'Código', property: 'codigo' },
    { label: 'Nombre', property: 'nombre' },
    { label: 'Comunidad Autónoma', property: 'comunidadAutonoma' },
    { label: 'Provincia', property: 'provincia', align: 'center' },
    { label: 'Estado', property: 'estado',},
  ];

  dataForTable = [
    { id: 1, codigo: '0001', nombre:'Marcos Lopez SL', estado: 'Reunión concretada', comunidadAutonoma: 'Comunidad Valenciana', provincia: 'Valencia' },
    { id: 2, codigo: '0002', nombre:'Marcos Lopez SL', estado: 'Contrato hecho', comunidadAutonoma: 'Comunidad Valenciana', provincia: 'Valencia' },
    { id: 3, codigo: '0003', nombre:'Marcos Lopez SL', estado: 'Reunión concertada', comunidadAutonoma: 'Comunidad Valenciana', provincia: 'Valencia' },
    { id: 4, codigo: '0004', nombre:'Marcos Lopez SL', estado: 'Envío muestras', comunidadAutonoma: 'Comunidad Valenciana', provincia: 'Valencia' },
    { id: 5, codigo: '0005', nombre:'Marcos Lopez SL', estado: 'Presupuesto enviado', comunidadAutonoma: 'Comunidad Valenciana', provincia: 'Valencia' },
    { id: 6, codigo: '0006', nombre:'Marcos Lopez SL', estado: 'Archivado', comunidadAutonoma: 'Comunidad Valenciana', provincia: 'Valencia' },
    { id: 7, codigo: '0007', nombre:'Marcos Lopez SL', estado: 'Reunión concretada', comunidadAutonoma: 'Comunidad Valenciana', provincia: 'Valencia' },
  ]

  optionsTabla: iDataTableOptions = {
    isSelectable: true,
    canEdit: true,
    canSee: true,
    moreIconsRow: [
      {icon:'archive', callback:(element)=> {console.log(element)}},
    ],
    isHovered: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc'
  }


  constructor(private staticUtilitiesSE:StaticUtilitiesService) { }

  ngOnInit() {
    this.isLoading = true;
    setTimeout(() => {
      this.isLoading = false;
      
    }, 1000);
  }

  seeClientePotencial(event){
    this.staticUtilitiesSE.goTo('crm/clientes/cpotencial/'+event.id, slideNavigationTypes.slideToTop)
  }



}
