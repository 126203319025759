<pill class="tablePil" classList="tablePill" *ngIf="dataForTable.length > 0">
  <div class="tablaPlanificacion">
    <div class="tablaHeader">
      <p class="bold">Código</p>
      <p class="bold">Descripción</p>
      <p class="bold">Occ. Lt.</p>
      <p class="bold">Occ. Kg</p>
      <p class="bold">Unidad de medida</p>
      <p class="bold">Cantidad Unidad</p>
      <p class="bold">Cantidad Envasado</p>
      <br>
    </div>
    <div class="tablaBody">
      <br>
      <div class="tablaRow" *ngFor="let row of dataForTable; let index = index">
        <p>{{row.code}}</p>
        <p>{{row.description}}</p>
        <p>{{row.cantidadL}}</p>
        <p>{{row.ocupacionKg}}</p>
        <p>{{row.measurementUnit?.unit}}</p>
        <CustomInput classList="fullWidth tall special thin" placeholder="Cantidad envasado"
       [readonly]="index === 0" [readonly]="true"   [(ngModel)]="row.cantidadUnidad"  >
      </CustomInput>
        <CustomInput classList="fullWidth tall special thin" placeholder="Cantidad envasado"
        [type]="'number'" [readonly]="index === 0"  (keyup)="changeEnvasado($event, row)" [(ngModel)]="row.cantidadEnvasado" *ngIf="index !== 0" >
      </CustomInput>
      <p class="buble" (click)="llenar(row)" *ngIf="index !== 0">llenar</p>
    
      </div>

    </div>
  </div>
</pill>