import { CdkDropListGroup } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit } from '@angular/core';
import {
  iUnsubscribeDestroy,
  PopupService,
} from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { ProfilePopupComponent } from 'src/app/Popups/Profile-Popup/Profile-Popup.component';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'header',
  templateUrl: './Header.component.html',
  styleUrls: ['./Header.component.scss'],
})
export class HeaderComponent extends iUnsubscribeDestroy implements OnInit {
  // @Input() personName = "Persona";

  personName: string | null = '';
  option: string = 'ERP';

  navegationERP = [
    { label: 'Inicio', icon: 'home', router: '/erp/dashboard' },
    { label: 'Mantenimientos', icon: 'none', router: '/erp/mantenimientos' },
    // { label: 'Tarifas', icon: 'none', router: '/erp/tarifas' },
    { label: 'Proveedores', icon: 'none', router: '/erp/proveedores' },
    // { label: 'Clientes', icon: 'none', router: '/erp/clientes' },
    {
      label: 'Partes de produccion',
      icon: 'none',
      router: '/erp/partesproduccion',
    },
    { label: 'Stock', icon: 'none', router: '/erp/stock' },
    { label: 'Contabilidad', icon: 'none', router: '/erp/contabilidad' },
    { label: '', icon: 'person', popup: true },
  ];
  navegationCRM = [
    { label: 'Inicio', icon: 'home', router: '/crm/dashboard' },
    { label: 'Clientes', icon: 'none', router: '/crm/clientes' },
    { label: 'Estadísticas', icon: 'none', router: '/crm/estadisticas' },
    { label: 'Alertas', icon: 'none', router: '/crm/alertas' },
    {
      label: 'Acciones comerciales',
      icon: 'none',
      router: '/crm/acciones-comerciales',
    },
    { label: 'Documentos', icon: 'none', router: '/crm/documentos' },
    { label: 'this.personName', icon: 'person', popup: true },
  ];

  navegationArray: any[] = this.navegationERP;

  constructor(
    private staticSE: StaticUtilitiesService,
    private popupSE: PopupService
  ) {
    super();
  }

  ngOnInit() {
    let userName = localStorage.getItem('userName')!;
    this.navegationERP[this.navegationERP.length - 1].label = userName;
    let route = this.staticSE.getActualRoute();
    if (route.split('/')[1] === 'crm') {
      this.option = 'CRM';
      this.navegationArray = this.navegationCRM;
    } else {
      this.option = 'ERP';
      this.navegationArray = this.navegationERP;
    }
  }

  setNavigation(key: string) {
    key === 'CRM'
      ? (this.navegationArray = this.navegationCRM)
      : (this.navegationArray = this.navegationERP);
    this.option = key;
  }

  openPopup() {
    this.popupSE.openPopup(
      ProfilePopupComponent,
      { data: 'data' },
      'rightPopupAnimation'
    );
    this.popupSE
      .returnData()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }

        this._unsubInd.next('');
      });
  }

  navigateTo(nav: any) {}
}
