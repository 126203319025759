import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { iUnsubscribeDestroy } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { ProductionReportService } from 'src/app/Services/Api/ProductionReport.service';

@Component({
  selector: 'ERP-PP-PP-DV-EspecificacionesDeProducto',
  templateUrl: './ERP-PP-PP-DV-EspecificacionesDeProducto.component.html',
  styleUrls: ['./ERP-PP-PP-DV-EspecificacionesDeProducto.component.scss']
})
export class ERPPPPPDVEspecificacionesDeProductoComponent extends iUnsubscribeDestroy implements OnInit {

  @Input() parteId!: number;



  dataForTable:any = []
  dataForTableFungible:any = []

  constructor(private productionReportSE:ProductionReportService) {
    super();
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['parteId']) {
      if (changes['parteId'].currentValue) {
        this.getFormulaCharacteristics()
        this.getFungibles()
      }
    }
  }

  /**
   * API CALLS
   */


  getFormulaCharacteristics(){
    this.productionReportSE.getCharacteristicsId(this.parteId);
    this.productionReportSE.getResultCharacteristicsId().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
      if (!res) { return }
      let {data} = res;
      this.dataForTable = data;
      this._unsubInd2.next('');
    })
  }

  getFungibles(){
    this.productionReportSE.getFungiblesId(this.parteId);
    this.productionReportSE.getResultFungiblesId().pipe(takeUntil(this._unsubInd3)).subscribe(res => {
      if (!res) { return }
      let {data} = res;
      this.dataForTableFungible = data;
      this._unsubInd3.next('');
    })
  }
}
