<FullContainer>
  <div class="tarifasGeneralContainer">
    <div class="header">
      <h1>TARIFAS</h1>
      <CustomToggle [selected]="selected" [selectores]="selectores" (selectedOption)="selectedOption($event)"></CustomToggle>
    </div>
    <erpTarifasPolvo [maxFactor]="maxFactor" [minAporte]="minAporte" *ngIf="selected == 0"></erpTarifasPolvo>
    <erpTarifasClientePolvo [maxFactor]="maxFactor" [minAporte]="minAporte" *ngIf="selected == 1"></erpTarifasClientePolvo>
    <erpTarifasLiquida [maxFactor]="maxFactor" [minAporte]="minAporte" *ngIf="selected == 2"></erpTarifasLiquida>
    <erpTarifasClienteLiquida [maxFactor]="maxFactor" [minAporte]="minAporte" *ngIf="selected == 3"></erpTarifasClienteLiquida>
  </div>
</FullContainer>
