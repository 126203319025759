import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PopupService, iOptionsSelector, iUnsubscribeDestroy } from '@quasar_dynamics/basic-designsystem';
import { ArticleService } from 'src/app/Services/Api/Article.service';
import { CompanyService } from 'src/app/Services/Api/Company.service';
import { OrderService } from 'src/app/Services/Api/Order.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';
import { take, takeUntil } from 'rxjs/operators';
import { DeliveryNoteService } from 'src/app/Services/Api/DeliveryNote.service';
import { NuevoPedidoAlbaranPopupComponent } from '../NuevoPedidoAlbaran-Popup/NuevoPedidoAlbaran-Popup.component';
import { GenerateAlbaranService } from 'src/app/Services/Api/GenerateAlbaran.service';
@Component({
  selector: 'app-NuevoAlbaran-Popup',
  templateUrl: './NuevoAlbaran-Popup.component.html',
  styleUrls: ['./NuevoAlbaran-Popup.component.scss']
})
export class NuevoAlbaranPopupComponent extends iUnsubscribeDestroy implements OnInit {

  selectorMap = 0;
  numAlbaran: any = null;
  cliente: any = null;
  client: any = null;
  isCompleted: boolean = false;
  valueIva: number = 0;
  companyReady = false;
  dataSeccion2: any = {}
  dataSeccion3: any = {}
  opcionesCliente: iOptionsSelector = {
    items: [],
    append: true,
    placeholder: 'Cliente',
    bindLabel: 'name',
    search: true,
  }
  opcionesAddress: iOptionsSelector = {
    items: [],
    append: true,
    placeholder: 'Dirección',
    bindLabel: 'street',
    bindValue: 'id',
    search: true,
  }
  data: any = {
    date: new Date(),
    packages: '',
    sReference: '',
    transportAgency: '',
    address: '',
  }

  constructor(public dialogRef: MatDialogRef<NuevoAlbaranPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any, private orderSE: OrderService, private companySE: CompanyService, private articleSE: ArticleService, private deliveryNoteSE: DeliveryNoteService, private popupSE: PopupService, private generateAlbaranSE: GenerateAlbaranService) {
    super();
  }

  ngOnInit() {
    this.getLastAlbaranId()
    this.getClientesForSelector()
  }

  /**
   * GETTERS && SETTERS
   */

  editSecction(event, key) {
  }
  getClienteForAddress(event) {
    if (!event) { this.opcionesAddress.items = []; this.companyReady = false; delete this.data.address; return }
    this.companyReady = false;
    delete this.data.address
    this.getClientById(event.id)
  }

  /**
* FUNCTIONALITY
*/
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }
  nextSection() {
    if (this.selectorMap < 2)
      this.selectorMap++;
  }
  previousSection() {
    if (this.selectorMap > 0)
      this.selectorMap--;
  }
  checkIsCompleted() {
     
    this.isCompleted = StaticUtilitiesService.isCompleteObject(this.data);
  }
  addOrder() {
    this.popupSE.openPopup(NuevoPedidoAlbaranPopupComponent, { data: this.cliente });
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe((res: any) => {
      if (!res) { return }
       
      this._unsubInd2.next('');
    })
  }
  getDataSeccion2(event) {
    this.dataSeccion2 = event;
     
    let properAddress: any = []
    properAddress = this.client.companyAddress.filter((item) => { return item.id === this.data.address })[0]
    this.dataSeccion3 = { ...this.dataSeccion2, ...this.client, valueIva: this.valueIva, ...this.data, numAlbaran: this.numAlbaran, properAddress }
  }


  /**
   * API CALLS
   */
  async create() {
    let data: any = { ...this.data };
    let details = this.dataSeccion2.arrayLineas.map((item) => { return { orderItem: item.id, amountSent: item.quantity, subtotal: item.subtotal } })
    data.details = details
     
     
    let clearedData: any = { ...this.dataSeccion2, netWeight: this.dataSeccion2.peso, base: this.dataSeccion2.baseImponible, surcharge: this.dataSeccion2.recargoEquivalencia };
    data = { ...data, ...clearedData }

    this.generateAlbaranSE.generateAlbaran(this.dataSeccion3)
    this.generateAlbaranSE.getPdf().pipe(takeUntil(this._unsubInd2)).subscribe(res =>{
      if(!res){return}
      data = { ...data, attacheds: res }
      this.createAlbaran(data);
      this._unsubInd2.next('');
    })
      
    
  }

  getLastAlbaranId() {
    this.deliveryNoteSE.getLastAlbaranId()
    this.deliveryNoteSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe((res: any) => {
      if (!res) { return }
      this.numAlbaran = Number(res.data) + 1;
      this.data.code = this.numAlbaran;
      this._unsubInd.next('');
    })
  }

  getClientesForSelector() {
    this.companySE.getClientOrProveedor({ num_devoluciones: 80000, num_pagina: 1, isClient: true, isActive: true });
    this.companySE.getResult().pipe(takeUntil(this._unsubInd2)).subscribe((res: any) => {
      if (!res) { return }
      let { data } = res.data
      this.opcionesCliente.items = data;
      this._unsubInd2.next('');
    })
  }
  // getClientesForSelector() {
  //   this.companySE.getClientOrProveedor({ num_devoluciones: 80000, num_pagina: 1, isClient: true, isActive: true });
  //   this.companySE.getResult().pipe(takeUntil(this._unsubInd2)).subscribe((res: any) => {
  //     if (!res) { return }
  //     let { data } = res.data
  //     this.opcionesCliente.items = data;
  //     this._unsubInd2.next('');
  //   })
  // }
  getClientById(id) {
    this.companySE.getSingle(id);
    this.companySE.getResultIndividual().pipe(takeUntil(this._unsubInd)).subscribe((res: any) => {
      if (!res) { return }
      let { data } = res
       
      this.opcionesAddress.items = data.companyAddress;
      this.valueIva = data.paymentMethods[0].typeIva.value;
      this.client = data
      this.companyReady = true;
      this._unsubInd.next('');
    })
  }

  createAlbaran(data) {
     
    this.deliveryNoteSE.create(data);
    this.deliveryNoteSE.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe((res: any) => {
      if (!res) { return }
      StaticUtilitiesService.showFeedback('Albarán creado correctamente')
      this.closePopup(res.data)
      this._unsubInd.next('');
    })
    this.deliveryNoteSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res: any) => {
      if (!res) { return }
      StaticUtilitiesService.showError('El albarán no ha podido ser creado')
      this.closePopup(res.data)
      this._unsub.next('');
    })
  }

}
