<pill>
  <ng-container>
    <div class="fullPill">
      <div class="iconLine">
        <span class="icon " (click)="edit()">
          <ng-container>
            <mat-icon class="outline">
              edit
            </mat-icon>
          </ng-container>
        </span>
      </div>
      <div class="headerRow">
        <p class="bold">Código material</p>
        <p class="bold">Código secundario</p>
        <p class="bold">Descripción</p>
        <p class="bold">Cantidad</p>
      </div>

      <div class="contentRow" *ngFor="let data of lines">
        <p *ngIf="!data.indication">{{data?.rawMaterial?.code || data?.article?.code}} 
        </p>
        <p *ngIf="!data.indication">{{data?.rawMaterial?.secondaryCode || data?.article?.code}} 
        </p>
        <p *ngIf="!data.indication">{{data?.article?.description}} 
        </p>
        <p *ngIf="!data.indication">{{data.quantity}} 
        </p>
        <div class="description" *ngIf="data.indication">
          <p>{{data?.indication ?? data?.indication}}</p>
        </div>
      </div>
      <div class="total">
        <div class="line">
          <p class="bold">TOTAL : </p><p>{{total}}</p>
        </div>
        <div class="line">
          <p class="bold">Coste total (Kg) : </p><p>{{totalKg.toFixed(2)}}</p>
        </div>
        <div class="line">
          <p class="bold">Coste total (L) : </p><p>{{totalLitros.toFixed(2)}}</p>
        </div>
      </div>

      <div class="observacionesFinales">
        <p class="bold">Observaciones Finales:</p>
        <p>{{data.observation}}</p>
      </div>
    </div>
  </ng-container>
</pill>