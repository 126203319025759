<pill>
  <ng-container>
    <div class="estadisticaGeneralContainer">
      <div class="header">
        <selector [options]="opcionesConsumo" [(ngModel)]="consumo" ></selector>
        <p>CONSUMO POR FAMILIAS</p>
      </div>
      <div class="body">
        <div class="grafica">
          <div>
            <canvas baseChart [data]="barChartData" [options]="barChartOptions" [plugins]="barChartPlugins"
              [legend]="barChartLegend" [type]="'bar'">
            </canvas>
          </div>
        </div>
        <div class="filtros">
          <div class="customContainer">
            <label for="familia">FAMILIA</label>
            <selector [options]="opcionesFamilia" [(ngModel)]="data.familia" ></selector>
          </div>
          <div class="customContainer">
            <label for="familia">SUBFAMILIA</label>
            <selector [options]="opcionesSubfamilia" [(ngModel)]="data.subFamilia" ></selector>
          </div>
          <div class="customContainer">
            <label for="familia">PRODUCTOS</label>
            <selector [options]="opcionesProductos" [(ngModel)]="data.producto" ></selector>
          </div>
          <div class="customContainer">
            <label for="fecha">FECHA INICIO</label>
            <datepicker (dataFromPicker)="data.fechaIni = $event"  [clearable]="true" ></datepicker>
          </div>
          <div class="customContainer">
            <label for="fecha">FECHA FIN</label>
            <datepicker (dataFromPicker)="data.fechaFin = $event" [clearable]="true" ></datepicker>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</pill>
