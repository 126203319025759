import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { iUnsubscribeDestroy } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { IncidentService } from 'src/app/Services/Api/Incident.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'app-NuevoTipoIncidencia-Popup',
  templateUrl: './NuevoTipoIncidencia-Popup.component.html',
  styleUrls: ['./NuevoTipoIncidencia-Popup.component.scss']
})
export class NuevoTipoIncidenciaPopupComponent extends iUnsubscribeDestroy implements OnInit {

  descripcion: string = "";
  name: string = ""
  isCompleted: boolean = false;
  updateObject: any = {
    id: "",
  }
  constructor(public dialogRef: MatDialogRef<NuevoTipoIncidenciaPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any, private incidentSE:IncidentService) {
    super();
  }

  ngOnInit() {
    if (this.modalData.accion === 'editar' || this.modalData.accion === 'ver') {
      this.setter()
    }
  }

  /**
   * GETTER && SETTER
   */

  setter() {
    this.descripcion = this.modalData.data.description;
    this.name = this.modalData.data.name;
    this.updateObject.id = this.modalData.data.id;
  }
  editSecction(event, key: string) {
    if (!event) { return }
    this.updateObject[key] = event;
    this.checkIsCompleted();
    this.edit()
  }

  /**
  * FUNCTIONALITY
  */
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }

  /**
   * VALIDATIONS
   */
  checkIsCompleted() {
    this.descripcion && this.name ? this.isCompleted = true : this.isCompleted = false
  }

  /**
   * API CALLS
   */

  edit() {
    this.incidentSE.update(this.updateObject);
    this.incidentSE.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe((res) => {
      if(!res){return}
      StaticUtilitiesService.showFeedback('Tipo de incidencia actualizado')
      this._unsubInd.next('')
    })
    this.incidentSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res) => {
      if(!res){return}
      StaticUtilitiesService.showError('El tipo de incidencia no se pudo actualizar')
      this._unsub.next('')
    })
  }
  create() {
    let data:any = {
      name: this.name,
      description: this.descripcion
    }
    this.incidentSE.create(data);
    this.incidentSE.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe((res) => {
      if(!res){return}
      StaticUtilitiesService.showFeedback('Tipo de incidencia creado')
      this.closePopup('create')
      this._unsubInd.next('')
    })
    this.incidentSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res) => {
      if(!res){return}
      StaticUtilitiesService.showError('La incidencia no pudo ser creada')
      this.closePopup('create')
      this._unsub.next('')
    })
  }
}
