<div class="fichaGeneralContainer" *ngIf="isActive">
  <pill>
    <ng-container>
      <div class="info">
        <span class="material-icons icon" (click)="edit('datos')">
          edit
        </span>
        <div class="row">
          <div class="column">
            <label for="">Número pedido:</label>
            <span>{{data.id}}</span>
          </div>
          <div class="column">
            <label for="">Fecha pedido:</label>
            <span>{{data.date | date:'dd/MM/yyyy'}}</span>
          </div>
        </div>
        <div class="row">
          <div class="column">
            <label for="">Cliente:</label>
            <span>{{data?.company?.name}}</span>
          </div>
          <div class="column">
            <label for="">Email pedidos:</label>
            <span>{{data?.company?.orderEmails[0]}}</span>
          </div>
        </div>
        <div class="row">
          <div class="column">
            <label for="">Teléfono:</label>
            <span>{{data?.company?.phones[0]}}</span>
          </div>
          <div class="column">
            <label for="">Forma de pago:</label>
            <span>{{data?.company?.paymentMethods[0]?.wayToPay?.description}}</span>
          </div>
        </div>
        <div class="row">
          <div class="column">
            <label for="">Fecha de cobro:</label>
            <span>{{data.payDate | date:'dd/MM/yyyy'}}</span>
          </div>
          <div class="column">
            <label for="">Estado</label>
            <span [ngClass]="{yellow: data.state === 'Pendiente', green:data.state === 'Completado'}">{{data.state}}</span>
          </div>
        </div>
      </div>
    </ng-container>
  </pill>
  <pill>
    <ng-container>
      <div class="info">
        <div class="row">
          <div class="column special">
            <label for="">Direccion de envío:</label>
            <span>{{data?.company?.name}} <br> {{data?.address?.street}}, {{data?.address?.number}},{{data?.address?.municipality}} ,{{data?.address?.postalCode}}, {{data?.address?.province}} ({{data?.address?.country}}) <br> {{data?.address?.contact}} - {{data?.address?.phones[0]}}</span>
          </div>
        </div>
        <div class="row">
          <div class="column special">
            <label for="">Agente:</label>
            <span *ngFor="let agent of data?.company?.agents">{{agent.name}}</span>
          </div>
        </div>
        <div class="row">
          <div class="column special">
            <label for="">Portes:</label>
            <span>{{data?.company?.porte.name}}</span>
          </div>
        </div>
      </div>
    </ng-container>
  </pill>
  <pill>
    <ng-container>
      <div class="info">
        <div class="icons">
          <span class="material-icons icon" (click)="subirDocumento()">
            add
          </span>
          <span class="material-icons icon" (click)="verDocumentos(true)" [class.disabled]="documentos.length == 0">
            edit
          </span>
        </div>

        <div class="row">
          <div class="column special">
            <label for="">Adjuntos:</label>
            <div class="documentos">
              <div class="documento" *ngFor="let documento of data.attacheds | slice:0:2; let i = index;">
                <label for="">Documento {{i+1}}:</label>
                <a [href]="documento" target="_blank">
                  <img src="/assets/Icons/pdf.svg" alt="">
                </a>
              </div>
            </div>
            <p class="noData" *ngIf="documentos.length == 0">No hay adjuntos</p>
            <span class="verTodo" (click)="verDocumentos()" *ngIf="documentos.length > 0">Ver todos</span>
          </div>
        </div>
      </div>
    </ng-container>
  </pill>

</div>
