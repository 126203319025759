import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { iUnsubscribeDestroy } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { AccountingService } from 'src/app/Services/Api/Accounting.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'app-CrearAsientoDetalleCompany-Popup',
  templateUrl: './CrearAsientoDetalleCompany-Popup.component.html',
  styleUrls: ['./CrearAsientoDetalleCompany-Popup.component.scss']
})
export class CrearAsientoDetalleCompanyPopupComponent extends iUnsubscribeDestroy implements OnInit {

  amount: any = null;
  company: number = 0;
  seat: boolean = false;
  isCompleted: boolean = false;

  updateObject: any = {
    id: '',
    seat:true
  }

  constructor(public dialogRef: MatDialogRef<CrearAsientoDetalleCompanyPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any, private accountingSE: AccountingService) {
    super();
  }

  ngOnInit() {
    this.setter()
  }

  /**
   * GETTER && SETTER
   */
  editInput(event, key) { }
  setter() {
    this.updateObject.id = this.modalData.companyId;
    this.company = Number(this.modalData.companyId);
  } 

  /**
  * FUNCTIONALITY
  */
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }
  checkIsCompleted() {
    if (this.amount != 0 && this.company != 0) {
      this.isCompleted = true;
    }
    this.amount = Number(this.amount)
  }

  /**
   * API CALLS
   */


  create() {
    let data: any = {
      amount: this.amount,
      company: this.company,
      seat: true
    }
    this.accountingSE.create(data);
    this.accountingSE.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe((res: any) => {
      if (!res) return
      StaticUtilitiesService.showFeedback('Asiento creado correctamente')
      this.closePopup('create')
      this._unsubInd.next('')
    })
    this.accountingSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res: any) => {
      if (!res) return
      StaticUtilitiesService.showError('El asiento no pudo ser creado')
      this._unsub.next('')
    })
  }
}
