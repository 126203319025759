import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { iButton, iUnsubscribeDestroy, NotificationsService, PopupService } from '@quasar_dynamics/basic-designsystem';
import { Subject, takeUntil } from 'rxjs';
import { LoginLoadingComponent } from 'src/app/Popups/LoginLoading/LoginLoading.component';
import { LoginService } from 'src/app/Services/Api/Login.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';


@Component({
  selector: 'app-LoginPage',
  templateUrl: './LoginPage.component.html',
  styleUrls: ['./LoginPage.component.scss']
})
export class LoginPageComponent extends iUnsubscribeDestroy implements OnInit {

  data: any = {
    username: "",
    password: "",
  }
  disable: boolean = true;
  isLogin = false;
  emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  animSend = new Subject<boolean>();
  animActive = false;
  constructor(public notificaciones: NotificationsService, public popup: PopupService, public route: Router, private loginSE: LoginService) {
    super();
  }
  ngOnInit() {
  }
  checkComplete() {
    if (this.data.username.trim() && this.data.password) {
      if (this.emailRegex.test(this.data.username)) {
        this.disable = false;
      } else {
        StaticUtilitiesService.showWarning("El formato del email no es correcto");
        this.disable = true;
      }
    } else {
      this.disable = true;
    }
  }
  login(event) {
    event.preventDefault();
    this.loginSE.login(this.data);
    this.loginSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(res => {
      if (!res) { return }
      this.animActive = true;
      this.animSend.next(true);
      this.getMe();
      this.isLogin = true;
      this._unsubInd.next('')
    })
    this.loginSE.getResultError().pipe(takeUntil(this._unsub)).subscribe(res => {
      if (!res) { return }
      this.notificaciones.showError(res.message);
      this._unsub.next('')
    })
  }
  finishAnim() {
    if (this.isLogin && this.animActive) {
      this.popup.openPopup(LoginLoadingComponent, null, ["fullSizePopup", "loginLoading"]);
      this.animActive = false;
      this.animSend.next(false);
      setTimeout(() => {
        this.route.navigate(['/erp/dashboard']);
      }, 150);
    } else {
      this.animSend.next(false);
    }
  }
  getMe() {
    this.loginSE.me()
    this.loginSE.getResultIndividual().pipe(takeUntil(this._unsubInd3)).subscribe(res => {
      if (!res) { return }
      let { data } = res;
      localStorage.setItem('userName', data.name)
      localStorage.setItem('email', data.email)
      this._unsubInd3.next('')
    })
    
  }


}
