import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { iDataTableColumns, iDataTableOptions, iDate, iUnsubscribeDestroy, PopupService, slideNavigationTypes } from '@quasar_dynamics/basic-designsystem';
import { ConfirmPopupComponent } from 'src/app/Popups/ConfirmPopup/ConfirmPopup.component';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';
import { Subject, takeUntil } from 'rxjs';
import { OrderService } from 'src/app/Services/Api/Order.service';
import { iCompanyFilter } from 'src/app/Shared/Interfaces/iCompany';
import { NuevoClientePopupComponent } from 'src/app/Popups/NuevoCliente-Popup/NuevoCliente-Popup.component';
import { NuevoClientePedidoComponentPopup } from 'src/app/Popups/NuevoClientePedido-Popup/NuevoClientePedido-Popup.component';
import { EditarPedidoClientePopupComponent } from 'src/app/Popups/EditarPedidoClientePopup/EditarPedidoClientePopup.component';
import * as XLSX from 'xlsx';
import { CompanyService } from 'src/app/Services/Api/Company.service';

@Component({
  selector: 'ERP-Clientes-Pedidos',
  templateUrl: './ERP-Clientes-Pedidos.component.html',
  styleUrls: ['./ERP-Clientes-Pedidos.component.scss']
})
export class ERPClientesPedidosComponent extends iUnsubscribeDestroy implements OnInit {

  @Input() filterOrders:any = {
    num_devoluciones: 20,
    num_pagina: 1,
    isClient: true,
    isActive: true,
  }
  @Output() clientsForFilter = new EventEmitter<any>()
  @Input() refreshPedidos:boolean = false

  isLoading: boolean = false;
  forceUpdateWidth = new Subject()

  //Tabla
  displayedColumnsTabla: Array<iDataTableColumns> = [
    { label: 'Nº Pedido', property: 'code' },
    { label: 'Fecha Pedido', property: 'fechaPedido', align: 'center' },
    { label: 'Cliente', property: 'supplier' },
    { label: 'Estado', property: 'state', align: 'center', classList: 'tableEstado tableEstadoPedidos' },
  ];

  dataForTable:any = []
  totalPaginacion: number = 0

  optionsTabla: iDataTableOptions = {
    isSelectable: true,
    canEdit: true,
    canSee: true,
    canDelete: true,
    moreIconsHead: [{ callback: (event) => { this.exportexcel() }, icon: "download" }],
    isHovered: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc'
  }



  constructor(private router: Router, private staticUtilitiesSE: StaticUtilitiesService, private popupSE: PopupService, private orderSE: OrderService, private companySE:CompanyService) {
    super();
  }

  ngOnInit() {
    this.getAllOrders();
    this.getCompaniesForFilter()
  }

  ngOnChanges(changes:SimpleChanges){
    if(changes['refreshPedidos'] ){
      this.getAllOrders();
    }
    if(changes['filterOrders']){
      if (Object.keys(changes['filterOrders'].currentValue).length > 0) {
        if(changes['filterOrders'].currentValue['company']){
          this.filterOrders.company = changes['filterOrders'].currentValue['company']
        }
        if(! changes['filterOrders'].currentValue['company']){
          delete this.filterOrders.company 
        }
        if(changes['filterOrders'].currentValue['fechaPedidoStart']){
          this.filterOrders.fechaPedidoStart = changes['filterOrders'].currentValue['fechaPedidoStart']
        }
        if(! changes['filterOrders'].currentValue['fechaPedidoStart']){
          delete this.filterOrders.fechaPedidoStart 
        }
        if(changes['filterOrders'].currentValue['fechaPedidoEnd']){
          this.filterOrders.fechaPedidoEnd = changes['filterOrders'].currentValue['fechaPedidoEnd']
        }
        if(! changes['filterOrders'].currentValue['fechaPedidoEnd']){
          delete this.filterOrders.fechaPedidoEnd 
        }
        if(changes['filterOrders'].currentValue['genericFilter']){
          this.filterOrders.genericFilter = changes['filterOrders'].currentValue['genericFilter']
        }
        if(! changes['filterOrders'].currentValue['genericFilter']){
          delete this.filterOrders.genericFilter 
        }
        this.getAllOrders();
      }
    }
  }

  /**
 * Delete
 */

  delete(event, key) {
    let ids:number[] = [];
    switch (key) {
      case 'simple':
        ids = [event.numPedido];
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'el pedido' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
          if (!res) { return };
          this.deleteOrder(ids);
    this.getAllOrders();

          this._unsubInd2.next('');
        })
        break;
        case 'all':
          ids = this.dataForTable.map(order => order.numPedido);
          this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'todos los pedidos' });
          this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
            if (!res) { return };
            this.deleteOrder(ids);
    this.getAllOrders();

            this._unsubInd2.next('');
          })
          
          break;
          case 'selected':
            ids = event.map(order => order.numPedido);
            this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'los pedidos seleccionados' });
            this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
              if (!res) { return };
              this.deleteOrder(ids);
    this.getAllOrders();

          this._unsubInd2.next('');
        })
        break;
      default:
        break;
    }
  }


  /**
 * See pedido
 */

  see(event) {
    this.staticUtilitiesSE.goTo('/erp/clientes/detalles-pedido', slideNavigationTypes.slideToTop, { pedido: event.id })
  }

  /**
   * EDIT
   */

  edit(event) {
    this.popupSE.openPopup(EditarPedidoClientePopupComponent, { accion: 'editar', data: event, type:'detalle' });
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
      if (!res) { return }
      this.getAllOrders()
      this._unsubInd2.next('')
    })
  }

      /**
* DESCARGA DE PARTES EN EXCEL
*/

exportexcel(): void {
  let table = document.createElement("table")
  table.append((document.querySelector("dataTable .tableHeader thead") as HTMLElement).cloneNode(true))
  table.querySelector("thead .iconsActions")?.remove();
  table.querySelector("thead .checkboxContainer")?.remove();
  let rows = Array.from(document.querySelectorAll("dataTable .tableBody tbody tr "));
  let filteredRows = rows.filter((row) => {
    return row.querySelector('.checkboxContainer mat-checkbox ')!.getAttribute('ng-reflect-checked') === "true"
  })
  if (filteredRows.length > 0) {
    filteredRows.forEach((row) => {
      let newRow = row.cloneNode(true) as HTMLElement;
      newRow.querySelector('td:last-of-type')?.remove();
      newRow.querySelector('td:first-of-type')?.remove();
      table.append(newRow)
    })
  } else {
    table.append((document.querySelector("dataTable .tableBody tbody") as HTMLElement).cloneNode(true))
    table.querySelectorAll("tbody tr td:last-of-type").forEach(element => {
      element.remove();
    })
    table.querySelectorAll("tbody tr td:first-of-type").forEach(element => {
      element.remove();
    })
  }
  /* pass here the table id */
  const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table);

  /* generate workbook and add the worksheet */
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  /* save to file */
  XLSX.writeFile(wb, 'pedidosDeClientes.xlsx');

}


  /**
   * PAGINATION
   */

  changePagination(event: any) {
    if (event === 'next') {
      this.filterOrders.num_pagina++;
      this.getAllOrders();
    } else {
      this.filterOrders.num_pagina--;
      this.getAllOrders();
    }
  }

  /**
   * API CALLS
   */

  getAllOrders() {
    this.isLoading = true;
    this.orderSE.getAllOrdersCompanyProveedor(this.filterOrders);
    this.orderSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(res => {
      if (!res) { return };
      let { data, total } = res.data;
      this.totalPaginacion = total;
      this.dataForTable = data.map(order => { return { ...order, fechaPedido: iDate.javascriptConvert(new Date(order.orderDate)).toStringDate('EU'), } });
      setTimeout(() => { this.forceUpdateWidth.next(true) }, 100)
      this.isLoading = false;
      this._unsubInd.next('');
    })
  }

  deleteOrder(ids: number[]) {
    this.orderSE.delete(ids);
    this.orderSE.getResultDelete().pipe(takeUntil(this._unsubInd3)).subscribe(res => {
      if (!res) { return };
      StaticUtilitiesService.showFeedback('Pedido eliminado correctamente')
      this.getAllOrders();
      this._unsubInd3.next('');
    })
    this.orderSE.getResultDeleteError().pipe(takeUntil(this._unsub)).subscribe(res => {
      if (!res) { return };
      StaticUtilitiesService.showError('El pedido no pudo ser eliminado')
      this.getAllOrders();
      this._unsub.next('');
    })
  }

  getCompaniesForFilter(){
    this.companySE.getClientOrProveedor({num_devoluciones:500, num_pagina:1, isClient:true, isActive:true});
    this.companySE.getResult().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
      if(!res){return}
      this.clientsForFilter.emit(res.data.data);
      this._unsubInd2.next('')
    })
  }

}
