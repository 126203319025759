import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultBaseService, iResultHttp, iStaticUtilities } from '@quasar_dynamics/basic-designsystem';
import { iSubfamily, iSubfamilyCreate, iSubfamilyFilter } from 'src/app/Shared/Interfaces/iSubfamily';

@Injectable({
  providedIn: 'root'
})
export class SubfamilyService extends DefaultBaseService {

  res: iResultHttp = new iResultHttp();

  constructor(private http: HttpClient) {
    super();
  }

  getAll(queryParams: iSubfamilyFilter){
    this.http.get(this.urlApi + '/api/subfamily' + this.optionsGet(queryParams), this.getHeader()).subscribe({
      next: (data:any) => {
        let normalizedSubfamily =  iStaticUtilities.normalizeNamesCollection(data.data) ;
         
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: {data:normalizedSubfamily, total:data.total}});
        this.sendNextResult('result', this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultError', this.res, { method: this.getAll, args: [queryParams] });
        this.checkStatusError(error);
      },
    });
  }

  getSingle(id: number){
    this.http.get(this.urlApi + '/api/subfamily/'+id, this.getHeader()).subscribe({
      next: (data:any) => {
        let normalizedSubfamily = iSubfamily.convertFromObject( iStaticUtilities.normalizeNames(data) );
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: normalizedSubfamily});
        this.sendNextResult('resultIndividual', this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultIndividualError', this.res, { method: this.getSingle, args: [id] });
        this.checkStatusError(error);
      },
    });
  }

  create(subfamily:iSubfamilyCreate){
    this.http.post(this.urlApi+"/api/subfamily", subfamily, this.getHeader()).subscribe({
      next: (data:any) => {
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: data["data"]});
        this.sendNextResult('resultUpdate', this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultUpdateError', this.res, { method: this.create, args: [subfamily] });
        this.checkStatusError(error);
      },
    });
  }

  update(subfamily:iSubfamily){
    this.http.put(this.urlApi+"/api/subfamily/"+subfamily.id, subfamily, this.getHeader()).subscribe({
      next: (data:any) => {
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: data["data"]});
        this.sendNextResult('resultUpdate', this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultUpdateError', this.res, { method: this.update, args: [subfamily] });
        this.checkStatusError(error);
      },
    });
  }

  delete(ids: Array<number|string>){
    let idsObjs = this.convertArrayObject(ids, "ids");
    this.http.delete(this.urlApi+"/api/subfamily", this.sendBodyOptions(idsObjs) ).subscribe({
      next: (data:any) => {
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: data["data"]});
        this.sendNextResult('resultDelete', this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultDeleteError', this.res, { method: this.delete, args: [ids] });
        this.checkStatusError(error);
      },
    });
  }

}
