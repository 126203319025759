<div class="fullAccordion">
  <div class="yearTop">
    <div class="year">
      <p>2022</p>
    </div>
    <div class="bottomPills">
      <pill class="halfWidth" *ngFor="let actual of actualYear">
        <ng-container>
          <div class="fecha">
            <p class="label bold">{{actual.label}} </p>
            <p class="valor">{{actual.value}}</p>
          </div>
        </ng-container>
      </pill>
    </div>
  </div>
  <div class="yearsBottom">
    <div class="previousYear" *ngFor="let previous of previousYears">
      <div class="year">
        <p>{{previous.year}}</p>
      </div>
      <div class="bottomPills">
        <pill class="minHeightPills15em halfWidth">
          <ng-container>
            <div class="fecha">
              <p class="label bold">{{previous.anual}} </p>
              <p class="valor">{{previous.value}}</p>
            </div>
          </ng-container>
        </pill>
        <pill class="minHeightPills15em halfWidth">
          <ng-container>
            <div class="fecha">
              <p class="label bold">{{previous.mensual}} </p>
              <p class="valor">{{previous.valueMensual}}</p>
            </div>
          </ng-container>
        </pill>
      </div>
    </div>
  </div>

  <div class="secondSection">
    <pill class="fullWidth minHeightPillsIconos">
      <ng-container>
        <div class="fullPill">
          <div class="iconos">
            <ng-container>
              <mat-icon class="outline">
                edit
              </mat-icon>
            </ng-container>
          </div>
          <div class="content">
            <div class="lineOfContent">
              <p class="bold">Frecuencia de consumo :</p>
              <selector></selector>
            </div>
            <div class="lineOfContent">
              <p class="bold">Fecha último pedido :</p>
              <p>05/11/2022</p>
              <p class="warning"> <span>
                <ng-container>
                  <mat-icon class="warning">
                    warning
                  </mat-icon>
                </ng-container>

              </span> Ha bajado la frecuencia de consumo</p>
            </div>
          </div>
        </div>
      </ng-container>
    </pill>
  </div>
</div>