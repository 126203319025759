import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { iOptionsSelector } from '@quasar_dynamics/basic-designsystem';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'app-NuevaPrevisionDeCobros-Popup',
  templateUrl: './NuevaPrevisionDeCobros-Popup.component.html',
  styleUrls: ['./NuevaPrevisionDeCobros-Popup.component.scss']
})
export class NuevaPrevisionDeCobrosPopupComponent implements OnInit {

  descripcion: string = "";
  notas: string = ""
  isCompleted: boolean = false;
  document: any = null;
  documentLoaded: any = null;
  data: any = {
    nPedido: 1235,
    fechaFactura: new Date(),
    fechaPago: new Date(),
    cliente: null,
    baseImponible: null,
    iva: null,
    recargo: null,
    estado: null,
    adjuntos: [],
  }
  opcionesEstados: iOptionsSelector = {
    items: [
      { id: 0, label: "Pendiente" },
      { id: 1, label: "Remesada" },
    ],
    search: true,
    placeholder: "Estado",
    append: true,
  }
  opcionesCliente: iOptionsSelector = {
    items: [
      { id: 0, label: "Antonio Orozco" },
      { id: 1, label: "Amancio Ortega" },
      { id: 2, label: "Úrsula Corberó" },
    ],
    search: true,
    placeholder: "Cliente",
    append: true,
  }

  constructor(public dialogRef: MatDialogRef<NuevaPrevisionDeCobrosPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any,) { }

  ngOnInit() {
    if(this.modalData.accion == 'editar'){
      this.setter();
    }
   }

  /**
  * FUNCTIONALITY
  */
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }

  /**
   * VALIDATIONS
   */
  checkIsCompleted() {
    this.isCompleted = StaticUtilitiesService.isCompleteObject(this.data);
  }

  /**
   * Setter
   */

  setter() {
    this.data = this.modalData.data;
  }

  /**
   * Selector
   */

  getFromSelector(event, key){
    if(!event){this.data[key] = null; return;}
    this.data[key] = event.label;
    this.checkIsCompleted();
  }

  /**
   * 
   * Add documentos
   */

  addDocument(event: any) {
    if ((event[0].file.size < 5242880 && event[0].file.type == "application/pdf") || (event[0].file.size < 1048576 && (event[0].file.type != "image/png" || event[0].file.type != "image/jpeg"))) {
      if (event[0].file.type != "image/jpeg" && event[0].file.type != "image/png" && event[0].file.type != "application/pdf") {
        StaticUtilitiesService.showWarning("Formato de archivo no admitido")
        this.document = null;
      } else {
        event.forEach((element: any) => {
          let obj = {
            url: element.base64
          }
          if (this.data.adjuntos.length == 0) {
            this.data.adjuntos.push(obj)
          } else {
            this.data.adjuntos.push(obj)
          }
        });
      }
      this.documentLoaded = event[0].file.name;
      this.checkIsCompleted();
    }else{
      StaticUtilitiesService.showWarning("El tamaño del archivo o el formato no es correcto")
    }
  }

  getCounting(event) {
    if (!event) { this.data.baseImponible = null; this.data.iva = null; this.data.irpf = null; return; }
    this.data.iva = (event * 0.21).toFixed(2);
    this.data.recargo = (event * 0.052).toFixed(2);
  }
}
