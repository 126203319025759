import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultBaseService, iResultHttp, iStaticUtilities } from '@quasar_dynamics/basic-designsystem';
import { iFamily, iFamilyCreate, iFamilyFilter } from 'src/app/Shared/Interfaces/iFamily';

@Injectable({
  providedIn: 'root'
})
export class FamilyService extends DefaultBaseService {

  res: iResultHttp = new iResultHttp();

  constructor(private http: HttpClient) {
    super();
  }

  getAll(queryParams: iFamilyFilter) {
    this.http.get(this.urlApi + '/api/family' + this.optionsGet(queryParams), this.getHeader()).subscribe({
      next: (data: any) => {
        let normalizedFamily = iFamily.convertFromCollection(iStaticUtilities.normalizeNamesCollection(data.data));
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: { data: normalizedFamily, total: data.total } });
        this.sendNextResult('result', this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultError', this.res, { method: this.getAll, args: [queryParams] });
        this.checkStatusError(error);
      },
    });
  }

  getSingle(id: number) {
    this.http.get(this.urlApi + '/api/family/' + id, this.getHeader()).subscribe({
      next: (data: any) => {
        let normalizedFamily = iFamily.convertFromObject(iStaticUtilities.normalizeNames(data));
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: normalizedFamily });
        this.sendNextResult('resultIndividual', this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultIndividualError', this.res, { method: this.getSingle, args: [id] });
        this.checkStatusError(error);
      },
    });
  }

  create(family: iFamilyCreate) {
    this.http.post(this.urlApi + "/api/family", family, this.getHeader()).subscribe({
      next: (data: any) => {
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: data["data"] });
        this.sendNextResult('resultUpdate', this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultUpdateError', this.res, { method: this.create, args: [family] });
        this.checkStatusError(error);
      },
    });
  }

  update(family: iFamily) {
    this.http.put(this.urlApi + "/api/family/" + family.id, family, this.getHeader()).subscribe({
      next: (data: any) => {
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: data["data"] });
        this.sendNextResult('resultUpdate', this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultUpdateError', this.res, { method: this.update, args: [family] });
        this.checkStatusError(error);
      },
    });
  }

  delete(ids: Array<number | string>) {
    let idsObjs = this.convertArrayObject(ids, "ids");
    this.http.delete(this.urlApi + "/api/family", this.sendBodyOptions(idsObjs)).subscribe({
      next: (data: any) => {
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: data["data"] });
        this.sendNextResult('resultDelete', this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultDeleteError', this.res, { method: this.delete, args: [ids] });
        this.checkStatusError(error);
      },
    });
  }

}
