import { Component, Input, OnInit, SimpleChanges, } from '@angular/core';
import { Router } from '@angular/router';
import { iDataTableColumns, iDataTableOptions, iDate, iUnsubscribeDestroy, PopupService } from '@quasar_dynamics/basic-designsystem';
import { ConfirmPopupComponent } from 'src/app/Popups/ConfirmPopup/ConfirmPopup.component';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';
import { takeUntil, } from 'rxjs/operators';
import { NuevaPrevisionDeCobrosPopupComponent } from 'src/app/Popups/NuevaPrevisionDeCobros-Popup/NuevaPrevisionDeCobros-Popup.component';
import { BillService } from 'src/app/Services/Api/Bill.service';
import { AccountingService } from 'src/app/Services/Api/Accounting.service';
import { Subject } from 'rxjs';
import { CambiarEstadoPedidoClientePopupComponent } from 'src/app/Popups/CambiarEstadoPedidoCliente-Popup/CambiarEstadoPedidoCliente-Popup.component';
import { CambiarEstadoBillPopupComponent } from 'src/app/Popups/CambiarEstadoBill-Popup/CambiarEstadoBill-Popup.component';
import * as XLSX from 'xlsx';
import { fileURLToPath } from 'url';

@Component({
  selector: 'ERP-Contabilidad-Clientes',
  templateUrl: './ERP-Contabilidad-Clientes.component.html',
  styleUrls: ['./ERP-Contabilidad-Clientes.component.scss']
})
export class ERPContabilidadClientesComponent extends iUnsubscribeDestroy implements OnInit {

  @Input() filtersClients: any = {};

  forceUpdateWidth = new Subject();
  isLoading: boolean = false;
  totalPaginacion: number = 0;
  total: any = {
    baseImponible: 0,
    iva: 0,
    recargo: 0,
  }
  filter: any = {
    num_devoluciones: 20,
    num_pagina: 1,
    cliente: true,
  }


  //Tabla
  displayedColumnsTabla: Array<iDataTableColumns> = [
    { label: 'Nº Factura', property: 'billNumber' },
    { label: 'Fecha factura / Fecha cobro', property: 'fechaFacturaPago', format: 'lineBreak', align: 'center' },
    { label: 'Cliente', property: 'proveedor', },
    { label: 'Base Imponible', property: 'baseImponible', align: 'center' },
    { label: 'IVA', property: 'iva', align: 'center' },
    { label: 'Recargo equivalencia', property: 'surcharge', align: 'center' },
    { label: 'Estado', property: 'state', align: 'center', classList: 'tableEstado tableEstadoPagos' },
  ];

  dataForTable: any = []

  optionsTabla: iDataTableOptions = {
    isSelectable: true,
    canEdit: true,
    moreIconsHead: [{ callback: (event) => { this.exportexcel() }, icon: "download" }],
    isHovered: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc'
  }


  constructor(private router: Router, private staticUtilitiesSE: StaticUtilitiesService, private popupSE: PopupService, private billSE: BillService, private accountingSE: AccountingService) {
    super();
  }

  ngOnInit() {
    this.getAllContabilidadCliente()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['filtersClients']) {
      if (changes['filtersClients']?.currentValue?.genericFilter) {
        this.filter['genericFilter'] = changes['filtersClients']?.currentValue?.genericFilter
        this.getAllContabilidadCliente()
      }
      if (!changes['filtersClients'].currentValue?.genericFilter) {
        delete this.filter['genericFilter']
        this.getAllContabilidadCliente()
      }
    }
  }

  getCuentas() {
    this.dataForTable.forEach(element => {
      this.total.baseImponible += Number(element.baseImponible);
      this.total.iva += Number(element.iva);
      this.total.recargo += Number(element.surcharge);
    })
  }

  removeCheckbox() {

    this.dataForTable.forEach((element, index) => {
      if (element.state !== 'Pendiente') {
        (document.querySelector(`div.tableBodyContainer > table > tbody > tr:nth-child(${index + 1}) > td.checkboxContainer.ng-star-inserted`) as HTMLElement).style.pointerEvents = 'none';
        (document.querySelector(`div.tableBodyContainer > table > tbody > tr:nth-child(${index + 1}) > td.checkboxContainer.ng-star-inserted > mat-checkbox`) as HTMLElement).style.display = 'none';
        (document.querySelector(`div.tableBodyContainer > table > tbody > tr:nth-child(${index + 1}) > td.iconsActions.ng-star-inserted > div`) as HTMLElement).style.display = 'none';

      }
    })
  }
  /**
* PAGINATION
*/

  changePagination(event: any) {
    if (event === 'next') {
      this.filter.num_pagina++;
      this.getAllContabilidadCliente();
    } else {
      this.filter.num_pagina--;
      this.getAllContabilidadCliente();
    }
  }

  /**
* DESCARGA DE PARTES EN EXCEL
*/

  exportexcel(): void {
    let table = document.createElement("table")
    table.append((document.querySelector("dataTable .tableHeader thead") as HTMLElement).cloneNode(true))
    table.querySelector("thead .iconsActions")?.remove();
    table.querySelector("thead .checkboxContainer")?.remove();
    let rows = Array.from(document.querySelectorAll("dataTable .tableBody tbody tr "));
    let filteredRows = rows.filter((row) => {
      return row.querySelector('.checkboxContainer mat-checkbox ')!.getAttribute('ng-reflect-checked') === "true"
    })
    if (filteredRows.length > 0) {
      filteredRows.forEach((row) => {
        let newRow = row.cloneNode(true) as HTMLElement;
        newRow.querySelector('td:last-of-type')?.remove();
        newRow.querySelector('td:first-of-type')?.remove();
        table.append(newRow)
      })
    } else {
      table.append((document.querySelector("dataTable .tableBody tbody") as HTMLElement).cloneNode(true))
      table.querySelectorAll("tbody tr td:last-of-type").forEach(element => {
        element.remove();
      })
      table.querySelectorAll("tbody tr td:first-of-type").forEach(element => {
        element.remove();
      })
    }
    /* pass here the table id */
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, 'ContabilidadClientes.xlsx');

  }


  /**
   * RETABLES
   */

  retablesButton(event) {
    let rows = Array.from(document.querySelectorAll("dataTable .tableBody tbody tr "));
    let filteredRows: any = []
    rows.forEach((row) => {
      if (row.querySelector('.checkboxContainer mat-checkbox ')!.getAttribute('ng-reflect-checked') === "true") { filteredRows.push((row.querySelector('td:nth-child(2)') as HTMLElement).innerText) }
    })
    if (filteredRows.length === 0) { StaticUtilitiesService.showWarning('No se puede generar remesas si no hay ninguna factura seleccionada'); return }
    let objToPass: any = {
      bills: filteredRows,
    }

    this.createRemesas(objToPass)
  }

  /**
 * Edit
 */


  edit(event) {
    this.popupSE.openPopup(CambiarEstadoBillPopupComponent, { accion: 'editar', data: event });
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
      if (!res) { return };
      this.getAllContabilidadCliente();
      this._unsubInd2.next('');
    })
  }

  /**
   * Delete
   */

  delete(event, key) {
    switch (key) {
      case 'simple':
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'la materia prima' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
          if (!res) { return };
          this._unsubInd2.next('');
        })
        break;
      case 'all':
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'todas las materias primas' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
          if (!res) { return };
          this._unsubInd2.next('');
        })

        break;
      case 'selected':
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'las materias primas seleccionadas' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
          if (!res) { return };
          this._unsubInd2.next('');
        })
        break;
      default:
        break;
    }
  }

  /**
   * See cliente
   */

  see(event) {
    this.getBillId(event.billNumber)
  }
  downloadPdf = async (url: string, fileName: string) => {
    const response = await fetch(url);
    const blob = await response.blob();
    const urlCreated = URL.createObjectURL(blob);
  
    const link = document.createElement("a");
    link.href = urlCreated;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  /**
 * API CALLS
 */

  getAllContabilidadCliente() {
    this.isLoading = true;
    this.accountingSE.getAllAccountingClient(this.filter);
    this.accountingSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(res => {
      if (!res) { return };
      let { data, total } = res.data;
      this.totalPaginacion = total;
      setTimeout(() => {
        this.getCuentas()
      }, 1);
      this.dataForTable = data.map(element => { return { ...element, fechaFacturaPago: `${iDate.javascriptConvert(new Date(element.creationBill)).toStringDate('EU')} \n  ${iDate.javascriptConvert(new Date(element?.paymentDate)).toStringDate('EU')}` } });
      setTimeout(() => {
        this.forceUpdateWidth.next(true)
        this.removeCheckbox()
      }, 1);
      this.isLoading = false;
      this._unsubInd.next('');
    })
  }

  createRemesas(data) {
    this.billSE.generateRemesas(data);
    this.billSE.getResultUpdate().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
      if (!res) return;
      StaticUtilitiesService.showFeedback('Remesas generadas correctamente');
      this.getAllContabilidadCliente();
      this._unsubInd2.next('');
    })
    this.billSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe(res => {
      if (!res) return;
      StaticUtilitiesService.showError('Las remesas no se han podido generar');
      this.getAllContabilidadCliente();
      this._unsub.next('');
    })
  }

  getBillId(id) {
    this.billSE.getSingle(id);
    this.billSE.getResultIndividual().pipe(takeUntil(this._unsubInd)).subscribe(res => {
      if (!res) { return };
      this.downloadPdf(res.data.attacheds[0], 'Factura.pdf')
      this._unsubInd.next('');
    })
  }

}
