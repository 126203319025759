<div class="popupGeneralContainer">
  <div class="header">
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
    <p *ngIf="modalData.accion=='editar' || modalData.accion==null ">Editar proveedor</p>
    <p *ngIf="modalData.accion=='crear' ">Crear proveedor</p>
    <p *ngIf="modalData.accion=='ver' ">Ver proveedor</p>
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
  </div>
  <div class="body">
    <div class="datos"
      *ngIf=" ( modalData.accion == 'crear' || modalData.section == 'datos' || modalData.accion == 'ver' ||  modalData.accion == 'editar')">
      <p class="header">INFORMACIÓN GENERAL</p>
      <div class="tripleColumn">
        <CustomInput [readonly]="modalData.accion=='ver'" classList="fullWidth tall special" placeholder="Código"
          label="Código" [(ngModel)]="codigo" [required]="true"
          (change)="modalData.accion ==='editar'? editSectionCompany(codigo, 'code') :checkIsCompleted() ">
        </CustomInput>
        <CustomInput [readonly]="modalData.accion=='ver'" classList="fullWidth tall special" placeholder="Nombre"
          label="Nombre" [(ngModel)]="nombre" [required]="true"
          (change)="modalData.accion ==='editar'? editSectionCompany(nombre, 'name') :checkIsCompleted() ">
        </CustomInput>
        <CustomInput [readonly]="modalData.accion=='ver'" classList="fullWidth tall special" placeholder="NIF"
          label="NIF" [(ngModel)]="nif" [required]="true"
          (change)="modalData.accion ==='editar'? editSectionCompany(nif, 'nif') :checkIsCompleted() "></CustomInput>
      </div>
      <div class="tripleColumn">
        <CustomInput [readonly]="modalData.accion=='ver'" classList="fullWidth tall special" placeholder="Dirección"
          label="Dirección" [(ngModel)]="direccion"
          (change)="modalData.accion ==='editar'? editSectionCompanyAddress(direccion, 'street') :checkIsCompleted() ">
        </CustomInput>
        <CustomInput [readonly]="modalData.accion=='ver'" classList="fullWidth tall special" placeholder="Municipio"
          label="Municipio" [(ngModel)]="municipio"
          (change)="modalData.accion ==='editar'? editSectionCompanyAddress(municipio, 'municipality') :checkIsCompleted() ">
        </CustomInput>
        <CustomInput [readonly]="modalData.accion=='ver'" classList="fullWidth tall special" placeholder="Código postal"
          label="Código postal" [(ngModel)]="cp"
          (change)="modalData.accion ==='editar'? editSectionCompanyAddress(cp, 'postalCode') :checkIsCompleted() ">
        </CustomInput>

      </div>
      <div class="tripleColumn">
        <div class="customContainer">
          <label for="Provincia">Provincia</label>
          <selector [options]="opcionesProvincias" [(ngModel)]="provincia"
            (change)="modalData.accion ==='editar'? editSectionCompanyAddress(provincia, 'province') :checkIsCompleted() ">
          </selector>
        </div>
        <div class="customContainer">
          <label for="Pais">País</label>
          <selector [options]="opcionesPaises" [(ngModel)]="pais"
            (change)="modalData.accion ==='editar'? editSectionCompanyAddress(pais, 'country') :checkIsCompleted() ">
          </selector>
        </div>
        <div class="customContainer"></div>

      </div>
      <div class="tripleColumn">
        <CustomInput [readonly]="modalData.accion=='ver'" classList="fullWidth tall special"
          placeholder="Email de pedidos" label="Email de pedidos" [(ngModel)]="emailPedidos"
          (change)="modalData.accion ==='editar'? editSectionCompany(emailPedidos, 'orderEmail') :checkIsCompleted() ">
        </CustomInput>
        <CustomInput [readonly]="modalData.accion=='ver'" classList="fullWidth tall special"
          placeholder="Email de contacto" label="Email de contacto" [(ngModel)]="email1"
          (change)="modalData.accion ==='editar'? editSectionCompany(email1, 'email1') :checkIsCompleted() ">
        </CustomInput>
        <CustomInput [readonly]="modalData.accion=='ver'" classList="fullWidth tall special"
          placeholder="Email de contacto" label="" [(ngModel)]="email2"
          (change)="modalData.accion ==='editar'? editSectionCompany(email2, 'email2') :checkIsCompleted() ">
        </CustomInput>
      </div>
      <div class="tripleColumn">
        <CustomInput [type]="'text'" [readonly]="modalData.accion=='ver'" classList="fullWidth tall special"
          placeholder="Teléfono de contacto" label="Teléfonos de contacto" [(ngModel)]="telefonoContacto1"
          (change)="modalData.accion ==='editar'? editSectionCompany(telefonoContacto1, 'telefonoContacto1') :checkIsCompleted() ">
        </CustomInput>
        <CustomInput [type]="'text'" [readonly]="modalData.accion=='ver'" classList="fullWidth tall special"
          class="margin-top" placeholder="Teléfono de contacto" label="" [(ngModel)]="telefonoContacto2"
          (change)="modalData.accion ==='editar'? editSectionCompany(telefonoContacto2, 'telefonoContacto2') :checkIsCompleted() ">
        </CustomInput>
        <CustomInput [type]="'text'" [readonly]="modalData.accion=='ver'" classList="fullWidth tall special"
          class="margin-top" placeholder="Teléfono de contacto" label="" [(ngModel)]="telefonoContacto3"
          (change)="modalData.accion ==='editar'? editSectionCompany(telefonoContacto3, 'telefonoContacto3') :checkIsCompleted() ">
        </CustomInput>
      </div>
    </div>
    <div class="datos"
      *ngIf=" ( modalData.accion == 'crear' || modalData.section == 'pago' || modalData.accion == 'ver' || modalData.accion == 'editar')">
      <p class="header">FORMAS DE PAGO</p>
      <div class="tripleColumn">
        <div class="customContainer">
          <label for="Forma de pago">Forma de pago</label>
          <selector [options]="opcionesFormaPago" [(ngModel)]="formaPago"
            (change)="modalData.accion ==='editar'? editSectionPaymentMethod(formaPago, 'wayToPay') :checkIsCompleted() ">
          </selector>
        </div>
        <CustomInput [readonly]="modalData.accion=='ver'" classList="fullWidth tall special" placeholder="IBAN"
          label="IBAN" [(ngModel)]="iban"
          (change)="modalData.accion ==='editar'? editSectionPaymentMethod(iban, 'iban') :checkIsCompleted() ">
        </CustomInput>
        <CustomInput [readonly]="modalData.accion=='ver'" classList="fullWidth tall special" placeholder="BIC"
          label="BIC" [(ngModel)]="bic"
          (change)="modalData.accion ==='editar'? editSectionPaymentMethod(bic, 'bic') :checkIsCompleted() ">
        </CustomInput>
      </div>
      <div class="tripleColumn">

        <div class="customContainer">
          <label for="Forma de pago">Retención IRPF: </label>
          <selector [options]="opcionesIRPF" [(ngModel)]="retnecionIRPF"
            (change)="modalData.accion ==='editar'? editSectionPaymentMethod(retnecionIRPF, 'irpf') :checkIsCompleted() ">
          </selector>
        </div>
        <div class="customContainer">
          <label for="Forma de pago">Tipo IVA: </label>
          <selector [options]="opcionesTipoIva" [(ngModel)]="tipoIva"
            (change)="modalData.accion ==='editar'? editSectionPaymentMethod(tipoIva, 'typeIva') :checkIsCompleted() ">
          </selector>
        </div>
        <div class="customContainer">
          <label for="Días fijos">Tipo portes:</label>
          <selector [options]="opcionesTipoPorte" [(ngModel)]="tipoPortes"
            (change)="modalData.accion ==='editar'? editSectionCompany(tipoPortes, 'porte') :checkIsCompleted() ">
          </selector>
        </div>
      </div>
    </div>
    <div class="datos"
      *ngIf=" ( modalData.accion == 'crear' || modalData.section == 'observaciones' || modalData.accion == 'ver' || modalData.accion == 'editar')">
      <p class="header">INFORMACIÓN EXTRA</p>
      <CustomTextArea [readonly]="modalData.accion=='ver'" placeHolder="Observaciones" label="Observaciones"
        [(ngModel)]="observaciones" name="observaciones"
        (change)="modalData.accion ==='editar'? editSectionCompany(observaciones, 'observation') :checkIsCompleted() ">
      </CustomTextArea>

    </div>
    <div class="footer" *ngIf="modalData.accion!='ver'">
      <btn classList="secundario" (click)="forceClosePopup()">
        <ng-container>
          Cancelar
        </ng-container>
      </btn>
      <btn classList="secundaryButton" (click)="create()" [class.disabled]="!isCompleted"
        *ngIf="modalData.accion=='crear' ">
        <ng-container>
          Crear
        </ng-container>
      </btn>
      <btn classList="secundaryButton" (click)="closePopup()" [class.disabled]="!isCompleted"
        *ngIf="modalData.accion=='editar' || modalData.accion==null ">
        <ng-container>
          Editar
        </ng-container>
      </btn>
    </div>
  </div>