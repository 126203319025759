import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { iOptionsSelector } from '@quasar_dynamics/basic-designsystem';

@Component({
  selector: 'app-NuevoPedidoAlbaran-Popup',
  templateUrl: './NuevoPedidoAlbaran-Popup.component.html',
  styleUrls: ['./NuevoPedidoAlbaran-Popup.component.scss']
})
export class NuevoPedidoAlbaranPopupComponent implements OnInit {

  isCompleted: boolean = false;
  pedido: any = null;
  opcionesPedido:iOptionsSelector = {
    items: [],
    search: true,
    placeholder: 'Pedido',
    append: true,

  }

  constructor(public dialogRef: MatDialogRef<NuevoPedidoAlbaranPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any) { }

  ngOnInit() {
     
  }

  getPedido(event) {
    if (!event) { this.isCompleted = false; return }
    this.isCompleted = true;
  }
  /**
* FUNCTIONALITY
*/
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }
}
