<div class="fullAccordeon">
  <filterActions (addNew)="create()" (searchSend)="getSelector($event,'genericFilter')">
    <ng-container filterContent>
      <div class="containerSelectores">
        <div>
          <selector [options]="opcionesTipo" (change)="getSelector($event,'type')" ></selector>
        </div>
    </div>
  </ng-container>
</filterActions>
<Pagination [paginacionTotal]="totalPaginacion" (change)="changePagination($event)"></Pagination>

<pill class="tablePil" classList="tablePill">
  <dataTable [forceUpdateWidth]="forceUpdateWidth" [data]="dataForTable" [isLoading]="isLoading" [options]="optionsTabla" [displayedColumns]="displayedColumnsTabla" (delete)="delete($event,'simple')" (deleteAll)="delete(null,'all')" (deleteSelected)="delete($event,'selected')" (clickRow)="see($event)" (edit)="edit($event)" (see)="see($event)"></dataTable>
</pill>
</div>