<div class="fichaGeneralContainer">
  <pill>
    <ng-container>
      <div class="info">
        <span class="material-icons icon" (click)="editFormulaPopup()">
          edit
        </span>
        <div class="row">
          <div class="column">
            <label for="">Código:</label>
            <span>{{data.code}}</span>
          </div>
          <div class="column">
            <label for="">Objeto:</label>
            <span>{{data.object}}</span>
          </div>
        </div>
        <div class="row">
          <div class="column">
            <label for="">Descripción:</label>
            <span>{{data.name}}</span>
          </div>
          <div class="column">
            <label for="">Unidad de medida:</label>
            <span>{{data?.measurementUnit?.unit}}</span>
          </div>
        </div>
        <div class="row">
          <div class="column">
            <label for="">Densidad:</label>
            <span>{{data.density}}</span>
          </div>
        </div>
        <div class="row">
          <div class="column">
            <label for="">Familia:</label>
            <span>{{data?.family?.code}} - {{data?.family?.description}}</span>
          </div>
        </div>
        <div class="row">
          <div class="column">
            <label for="">Subfamilia:</label>
            <span>{{data?.subfamily?.code}} - {{data?.subfamily?.description}}</span>
          </div>
        </div>
      </div>
    </ng-container>
  </pill>
  <div class="smallPills">
    <div class="firstPill">
      <pill>
        <ng-container>
          <div class="info">
            <span class="material-icons icon" (click)="editFormulaPopup()">
              edit
            </span>
            <div class="row">
              <div class="column">
                <label for="">Observaciones:</label>
                <span>{{data.observation}}</span>
              </div>
            </div>
          </div>
        </ng-container>
      </pill>
    </div>
    <div class="secondPill">
      <pill>
        <ng-container>
          <div class="info">
            <div class="icons">
              <span class="material-icons icon" (click)="subirDocumento()">
                add
              </span>
              <span class="material-icons icon" (click)="verDocumentos(true)" [class.disabled]="documentos.length == 0">
                edit
              </span>
            </div>

            <div class="row">
              <div class="column special">
                <label for="">Adjuntos:</label>
                <div class="documentos" *ngFor="let document of data.attacheds; let index = index">
                  <div class="documento">
                    <label for="">Documento {{index}}:</label>
                    <a href="https://www.orimi.com/pdf-test.pdf" target="_blank">
                      <img src="/assets/Icons/pdf.svg" alt="">
                    </a>
                  </div>
                </div>
                <p class="noData" *ngIf="documentos.length == 0">No hay adjuntos</p>
              </div>
            </div>
          </div>
        </ng-container>
      </pill>
      </div>
  </div>
</div>