import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { iDataTableColumns, iDataTableOptions, iUnsubscribeDestroy, PopupService } from '@quasar_dynamics/basic-designsystem';
import { Subject, takeUntil } from 'rxjs';
import { ConfirmPopupComponent } from 'src/app/Popups/ConfirmPopup/ConfirmPopup.component';
import { NuevoTipoIvaPopupComponent } from 'src/app/Popups/NuevoTipoIva-Popup/NuevoTipoIva-Popup.component';
import { TypesIvaService } from 'src/app/Services/Api/TypesIva.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';
import { iTypesIvaFilter } from 'src/app/Shared/Interfaces/iTypesIva';
import * as XLSX from 'xlsx';

@Component({
  selector: 'erpMantenimientoDetallesTipoIva',
  templateUrl: './ERP-Mantenimiento-Detalles-TipoIva.component.html',
  styleUrls: ['./ERP-Mantenimiento-Detalles-TipoIva.component.scss']
})
export class ERPMantenimientoDetallesTipoIvaComponent extends iUnsubscribeDestroy implements OnInit {

  isLoading: boolean = false;
  _reloadTableWidth = new Subject()

  //Tabla
  displayedColumnsTabla: Array<iDataTableColumns> = [
    { label: 'Descripción', property: 'description' },
    { label: 'Valor(%)', property: 'value', align: "center", format: "percentaje" },
  ];

  filter: iTypesIvaFilter = {
    num_devoluciones: 500,
    num_pagina: 1,
    description: '',
    value: 0,
  }
  totalPaginacion: number = 0;

  dataForTable = []

  optionsTabla: iDataTableOptions = {
    isSelectable: true,
    isHovered: true,
    canSee: true,
    canEdit: true,
    canDelete: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc',
    moreIconsHead: [{ callback: (event) => { this.exportexcel() }, icon: "download" }],
  }
  dataForTableFiltered: Array<any> = this.dataForTable;

  constructor(private router: Router, private staticUtilitiesSE: StaticUtilitiesService, private popupSE: PopupService, private tipoIvaSE: TypesIvaService) {
    super()
  }

  ngOnInit() {
    this.getAllTipoIva()
  }

  /**
   * GETTERS && SETTERS
   */

  search(event){
    if(!event){this.filter.genericFilter = ""; this.getAllTipoIva(); return}
    this.filter.genericFilter = event;
    this.getAllTipoIva()
  }

  /**
   * FUNCTIONALITIES
   */
  edit(event: any) {
    this.popupSE.openPopup(NuevoTipoIvaPopupComponent, { data: event, accion: "editar" });
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe((data) => {
      if (!data) { return }
      this.ngOnInit()
      this._unsubInd2.next("")
    })
  }
  create() {
    this.popupSE.openPopup(NuevoTipoIvaPopupComponent, { data: null, accion: "crear" });
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe((data) => {
      if (!data) { return }
      this.ngOnInit()
      this._unsubInd2.next("")
    })
  }
  see(event: any) {
    this.popupSE.openPopup(NuevoTipoIvaPopupComponent, { data: event, accion: "ver" });

  }
  delete(event: any, type: String) {
    let ids: number[] = []
    switch (type) {
      case "single":
        ids = [event.id]
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'el tipo de iva ' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe((data) => {
          this.deleteIva(ids)
          this._unsubInd2.next("")
        })
        break;
      case "selected":
        ids = event.map((e) => e.id)
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'los tipos de iva seleccionadas' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe((data) => {
          this.deleteIva(ids)
          this._unsubInd2.next("")
        })
        break;
      case "all":
        ids = this.dataForTable.map((e: any) => { return e.id })
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'todas los tipos de iva' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe((data) => {
          this.deleteIva(ids)
          this._unsubInd2.next("")
        })
        break;
    }
  }
  changePagination(event: any) {
    if (event === 'next') {
      this.filter.num_pagina++;
      this.getAllTipoIva();
    } else {
      this.filter.num_pagina--;
      this.getAllTipoIva();
    }
  }

  /**
* DESCARGA DE PARTES EN EXCEL
*/

  exportexcel(): void {
    let table = document.createElement("table")
    table.append((document.querySelector("dataTable .tableHeader thead") as HTMLElement).cloneNode(true))
    table.querySelector("thead .iconsActions")?.remove();
    table.querySelector("thead .checkboxContainer")?.remove();
    let rows = Array.from(document.querySelectorAll("dataTable .tableBody tbody tr "));
    let filteredRows = rows.filter((row) => {
      return row.querySelector('.checkboxContainer mat-checkbox ')!.getAttribute('ng-reflect-checked') === "true"
    })
    if (filteredRows.length > 0) {
      filteredRows.forEach((row) => {
        let newRow = row.cloneNode(true) as HTMLElement;
        newRow.querySelector('td:last-of-type')?.remove();
        newRow.querySelector('td:first-of-type')?.remove();
        table.append(newRow)
      })
    } else {
      table.append((document.querySelector("dataTable .tableBody tbody") as HTMLElement).cloneNode(true))
      table.querySelectorAll("tbody tr td:last-of-type").forEach(element => {
        element.remove();
      })
      table.querySelectorAll("tbody tr td:first-of-type").forEach(element => {
        element.remove();
      })
    }
    /* pass here the table id */
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, 'tiposDeIVA.xlsx');

  }


  /**
   * CALLS TO API
   */

  getAllTipoIva() {
    this.isLoading = true;
    this.tipoIvaSE.getAll(this.filter);
    this.tipoIvaSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe((res) => {
      if (!res) { return }
      let { data, total } = res.data;
      this.totalPaginacion = total;
      this.dataForTable = data;
      this.dataForTableFiltered = this.dataForTable;
      setTimeout(() => {
        this._reloadTableWidth.next(true)
      }, 1);
      this.isLoading = false;
      this._unsubInd.next('')
    })
  }
  deleteIva(ids: number[]) {
    this.tipoIvaSE.delete(ids);
    this.tipoIvaSE.getResultDelete().pipe(takeUntil(this._unsubInd3)).subscribe((res) => {
      if (!res) { return }
      StaticUtilitiesService.showFeedback("Tipo de iva eliminado correctamente")
      this.getAllTipoIva()
      this._unsubInd3.next('')
    })
    this.tipoIvaSE.getResultDeleteError().pipe(takeUntil(this._unsub)).subscribe((res) => {
      if (!res) { return }
      StaticUtilitiesService.showError(res.message)
      this.getAllTipoIva()
      this._unsub.next('')
    })
  }

}
