<div class="popupGeneralContainer">
  <div class="header">
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
    <p *ngIf="modalData.accion=='editar'">Editar asiento</p>
    <p *ngIf="modalData.accion=='crear'">Crear asiento</p>
    <p *ngIf="modalData.accion=='ver'">Ver asiento</p>
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
  </div>
  <div class="body">
    <CustomInput [readonly]="modalData.accion=='ver'" classList="fullWidth tall special" placeholder="Cantidad"
      label="Cantidad" [(ngModel)]="data.quantity"
      (change)="modalData.accion === 'editar' ? editSection(data.quantity, 'quantity') :checkIsCompleted()"></CustomInput>

    <CustomTextArea [readonly]="modalData.accion=='ver'" placeHolder="Notas" label="Notas" [(ngModel)]="data.note"
      name="notas" (change)="modalData.accion === 'editar' ? editSection(data.note, 'note') :checkIsCompleted()">
    </CustomTextArea>
  </div>
  <div class="footer" *ngIf="modalData.accion!='ver'">
    <btn classList="secundario" (click)="forceClosePopup()">
      <ng-container>
        Cancelar
      </ng-container>
    </btn>
    <btn classList="secundaryButton" (click)="closePopup()" [class.disabled]="!isCompleted"
      *ngIf="modalData.accion=='editar'">
      <ng-container>
        Editar
      </ng-container>
    </btn>
    <btn classList="secundaryButton" (click)="create()" [class.disabled]="!isCompleted"
      *ngIf="modalData.accion=='crear'">
      <ng-container>
        Crear
      </ng-container>
    </btn>
  </div>
</div>