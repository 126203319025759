// Modules
import { NgSelectModule } from '@ng-select/ng-select';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule, CurrencyPipe, registerLocaleData } from '@angular/common';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { QuasarLibraryModule } from '@quasar_dynamics/basic-designsystem';
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgChartsModule } from 'ng2-charts';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';





// Components
import { CustomInputComponent } from './Shared/Components/CustomInput/CustomInput.component';
import { FullContainerComponent } from './Shared/Layout/FullContainer/FullContainer.component';
import { HeaderComponent } from './Shared/Layout/Header/Header.component';
import { CustomToggleComponent } from './Shared/Components/CustomToggle/CustomToggle.component';
import { FilterActionsComponent } from './Shared/Components/FilterActions/FilterActions.component';
import { SearcherComponent } from './Shared/Components/Searcher/Searcher.component';

// Pages

// CRM
import { CRMDashboardComponent } from './Pages/Section-CRM/CRM-Dashboard/CRM-Dashboard.component';
import { CRMClientesComponent } from './Pages/Section-CRM/CRM-Clientes/CRM-Clientes.component';
import { ClientesClientesComponent } from './Pages/Section-CRM/CRM-Clientes/_holder/ClientesClientes/ClientesClientes.component';
import { ClientesCPotencialesComponent } from './Pages/Section-CRM/CRM-Clientes/_holder/ClientesCPotenciales/ClientesCPotenciales.component';
import { ClientesPotencialesPipelineComponent } from './Pages/Section-CRM/CRM-Clientes/_holder/ClientesCPotenciales/ClientesPotencialesPipeline/ClientesPotencialesPipeline.component';
import { ClientesMaquinasComponent } from './Pages/Section-CRM/CRM-Clientes/_holder/ClientesMaquinas/ClientesMaquinas.component';
import { ClientesMapaComponent } from './Pages/Section-CRM/CRM-Clientes/_holder/ClientesMapa/ClientesMapa.component';
import { ClientesDetailViewComponent } from './Pages/Section-CRM/CRM-Clientes/ClientesDetailView/ClientesDetailView.component';
import { InformacionDeClienteComponent } from './Pages/Section-CRM/CRM-Clientes/ClientesDetailView/_holder/InformacionDeCliente/InformacionDeCliente.component';
import { InformacionComercialComponent } from './Pages/Section-CRM/CRM-Clientes/ClientesDetailView/_holder/InformacionComercial/InformacionComercial.component';
import { DatosDeConsumoComponent } from './Pages/Section-CRM/CRM-Clientes/ClientesDetailView/_holder/DatosDeConsumo/DatosDeConsumo.component';
import { IncidenciasComponent } from './Pages/Section-CRM/CRM-Clientes/ClientesDetailView/_holder/Incidencias/Incidencias.component';
import { FichaDeProductoComponent } from './Pages/Section-CRM/CRM-Clientes/ClientesDetailView/_holder/FichaDeProducto/FichaDeProducto.component';
import { ClientesAntiguosComponent } from './Pages/Section-CRM/CRM-Clientes/_holder/ClientesAntiguos/ClientesAntiguos.component';
import { CPotencialesDetailViewComponent } from './Pages/Section-CRM/CRM-Clientes/_holder/ClientesCPotenciales/CPotencialesDetailView/CPotencialesDetailView.component';
import { MaquinasDetailViewComponent } from './Pages/Section-CRM/CRM-Clientes/_holder/ClientesMaquinas/MaquinasDetailView/MaquinasDetailView.component';
import { CAInformacionDelClienteComponent } from './Pages/Section-CRM/CRM-Clientes/_holder/ClientesAntiguos/ClientesAntiguosDetailView/_holder/CAInformacionDelCliente/CAInformacionDelCliente.component';
import { ClientesAntiguosDetailViewComponent } from './Pages/Section-CRM/CRM-Clientes/_holder/ClientesAntiguos/ClientesAntiguosDetailView/ClientesAntiguosDetailView.component';
import { CAHistoricoPedidosComponent } from './Pages/Section-CRM/CRM-Clientes/_holder/ClientesAntiguos/ClientesAntiguosDetailView/_holder/CAHistoricoPedidos/CAHistoricoPedidos.component';
import { CACondicionesEspecialesComponent } from './Pages/Section-CRM/CRM-Clientes/_holder/ClientesAntiguos/ClientesAntiguosDetailView/_holder/CACondicionesEspeciales/CACondicionesEspeciales.component';

// Login
import { ContainerLoginComponent } from './Pages/Section-Login/_holder/ContainerLogin/ContainerLogin.component';
import { LoginPageComponent } from './Pages/Section-Login/LoginPage/LoginPage.component';
import { ForgotPasswordComponent } from './Pages/Section-Login/ForgotPassword/ForgotPassword.component';
import { CADireccionesAlternativasComponent } from './Pages/Section-CRM/CRM-Clientes/_holder/ClientesAntiguos/ClientesAntiguosDetailView/_holder/CADireccionesAlternativas/CADireccionesAlternativas.component';

// ERP
import { ERPDashboardComponent } from './Pages/Section-ERP/ERP-Dashboard/ERP-Dashboard.component';
import { ERPMantenimientosComponent } from './Pages/Section-ERP/ERP-Mantenimientos/ERP-Mantenimientos.component';
import { ERPMantenimientosDetallesComponent } from './Pages/Section-ERP/ERP-Mantenimientos/ERP-Mantenimientos-Detalles/ERP-Mantenimientos-Detalles.component';
import { ERPMantenimientosDetallesCaracteristicasComponent } from './Pages/Section-ERP/ERP-Mantenimientos/ERP-Mantenimientos-Detalles/ERP-Mantenimientos-Detalles-Caracteristicas/ERP-Mantenimientos-Detalles-Caracteristicas.component';
import { ERPClientesComponent } from './Pages/Section-ERP/ERP-Clientes/ERP-Clientes.component';


// Popups
import { LoginLoadingComponent } from './Popups/LoginLoading/LoginLoading.component';
import { HistoricoPedidosComponent } from './Pages/Section-CRM/CRM-Clientes/ClientesDetailView/_holder/HistoricoPedidos/HistoricoPedidos.component';
import { EditarInformacionDelClientePopupComponent } from './Popups/EditarInformacionDelCliente-Popup/EditarInformacionDelCliente-Popup.component';

import { ConfirmPopupComponent } from './Popups/ConfirmPopup/ConfirmPopup.component';
import { NuevaCaracteristicaPopupComponent } from './Popups/NuevaCaracteristica-Popup/NuevaCaracteristica-Popup.component';
import { CustomTextAreaComponent } from './Shared/Components/CustomTextArea/CustomTextArea.component';
import { ERPMantenimientosDetallesFamiliasComponent } from './Pages/Section-ERP/ERP-Mantenimientos/ERP-Mantenimientos-Detalles/ERP-Mantenimientos-Detalles-Familias/ERP-Mantenimientos-Detalles-Familias.component';
import { NuevaFamiliaPopupComponent } from './Popups/NuevaFamilia-Popup/NuevaFamilia-Popup.component';
import { ERPMantenimientosDetallesSubfamiliasComponent } from './Pages/Section-ERP/ERP-Mantenimientos/ERP-Mantenimientos-Detalles/ERP-Mantenimientos-Detalles-Subfamilias/ERP-Mantenimientos-Detalles-Subfamilias.component';
import { NuevaSubfamiliasPopupComponent } from './Popups/NuevaSubfamilias-Popup/NuevaSubfamilias-Popup.component';
import { ERPMantenimientoDetallesEnvasesComponent } from './Pages/Section-ERP/ERP-Mantenimientos/ERP-Mantenimientos-Detalles/ERP-Mantenimiento-Detalles-Envases/ERP-Mantenimiento-Detalles-Envases.component';
import { NuevoEnvasePopupComponent } from './Popups/NuevoEnvase-Popup/NuevoEnvase-Popup.component';
import { ERPMantenimientoDetallesAgentesComponent } from './Pages/Section-ERP/ERP-Mantenimientos/ERP-Mantenimientos-Detalles/ERP-Mantenimiento-Detalles-Agentes/ERP-Mantenimiento-Detalles-Agentes.component';
import { NuevoAgentePopupComponent } from './Popups/NuevoAgente-Popup/NuevoAgente-Popup.component';
import { ERPMantenimientoDetallesTipoIvaComponent } from './Pages/Section-ERP/ERP-Mantenimientos/ERP-Mantenimientos-Detalles/ERP-Mantenimiento-Detalles-TipoIva/ERP-Mantenimiento-Detalles-TipoIva.component';
import { ERPMantenimientosDetallesFormasPagoComponent } from './Pages/Section-ERP/ERP-Mantenimientos/ERP-Mantenimientos-Detalles/ERP-Mantenimientos-Detalles-FormasPago/ERP-Mantenimientos-Detalles-FormasPago.component';
import { NuevaFormaPagoPopupComponent } from './Popups/NuevaFormaPago-Popup/NuevaFormaPago-Popup.component';
import { ERPMantenimientoDetallesPortesPagoComponent } from './Pages/Section-ERP/ERP-Mantenimientos/ERP-Mantenimientos-Detalles/ERP-Mantenimiento-Detalles-PortesPago/ERP-Mantenimiento-Detalles-PortesPago.component';
import { NuevoPortesPopupComponent } from './Popups/NuevoPortes-Popup/NuevoPortes-Popup.component';
import { NuevoFungiblePopupComponent } from './Popups/NuevoFungible-Popup/NuevoFungible-Popup.component';
import { ERPMantenimientoDetallesFungiblesComponent } from './Pages/Section-ERP/ERP-Mantenimientos/ERP-Mantenimientos-Detalles/ERP-Mantenimiento-Detalles-Fungibles/ERP-Mantenimiento-Detalles-Fungibles.component';
import { ERPMantenimientosDetallesTipoIncidenciaComponent } from './Pages/Section-ERP/ERP-Mantenimientos/ERP-Mantenimientos-Detalles/ERP-Mantenimientos-Detalles-TipoIncidencia/ERP-Mantenimientos-Detalles-TipoIncidencia.component';
import { NuevoTipoIncidenciaPopupComponent } from './Popups/NuevoTipoIncidencia-Popup/NuevoTipoIncidencia-Popup.component';
import { NuevoTipoIvaPopupComponent } from './Popups/NuevoTipoIva-Popup/NuevoTipoIva-Popup.component';
import { ERPClientesCLientesListaComponent } from './Pages/Section-ERP/ERP-Clientes/_holder/ERP-Clientes-CLientesLista/ERP-Clientes-CLientesLista.component';
import { ERPClientesPedidosComponent } from './Pages/Section-ERP/ERP-Clientes/_holder/ERP-Clientes-Pedidos/ERP-Clientes-Pedidos.component';
import { NuevoClientePopupComponent } from './Popups/NuevoCliente-Popup/NuevoCliente-Popup.component';
import { ERPTarifasComponent } from './Pages/Section-ERP/ERP-Tarifas/ERP-Tarifas.component';
import { ERPTarifasPolvoComponent } from './Pages/Section-ERP/ERP-Tarifas/_Holders/ERP-Tarifas-Polvo/ERP-Tarifas-Polvo.component';
import localEs from '@angular/common/locales/es';
import { EditarTarifasPopupComponent } from './Popups/EditarTarifas-Popup/EditarTarifas-Popup.component';
import { ERPTarifasClientePolvoComponent } from './Pages/Section-ERP/ERP-Tarifas/_Holders/ERP-Tarifas-ClientePolvo/ERP-Tarifas-ClientePolvo.component';
import { NuevoClientePolvoPopupComponent } from './Popups/NuevoClientePolvo-Popup/NuevoClientePolvo-Popup.component';
import { ERPTarifasLiquidaComponent } from './Pages/Section-ERP/ERP-Tarifas/_Holders/ERP-Tarifas-Liquida/ERP-Tarifas-Liquida.component';
import { ERPTarifasClienteLiquidaComponent } from './Pages/Section-ERP/ERP-Tarifas/_Holders/ERP-Tarifas-ClienteLiquida/ERP-Tarifas-ClienteLiquida.component';
import { EditarClienteLiquidoPopupComponent } from './Popups/EditarClienteLiquido-Popup/EditarClienteLiquido-Popup.component';
import { ClienteDetailViewComponent } from './Pages/Section-ERP/ERP-Clientes/_holder/ERP-Clientes-CLientesLista/ERP-ClienteDetailView/ClienteDetailView.component';
import { ERPClienteDetailFichaComponent } from './Pages/Section-ERP/ERP-Clientes/_holder/ERP-Clientes-CLientesLista/ERP-ClienteDetailView/_holder/ERP-Cliente-Detail-Ficha/ERP-Cliente-Detail-Ficha.component';
import { ERPCLIENTEDetailDireccionesAlternativasComponent } from './Pages/Section-ERP/ERP-Clientes/_holder/ERP-Clientes-CLientesLista/ERP-ClienteDetailView/_holder/ERP-CLIENTE-Detail-DireccionesAlternativas/ERP-CLIENTE-Detail-DireccionesAlternativas.component';
import { ERPProveedoresComponent } from './Pages/Section-ERP/ERP-Proveedores/ERP-Proveedores.component';
import { ERPProveedoresProveedoresComponent } from './Pages/Section-ERP/ERP-Proveedores/_Holders/ERP-Proveedores-Proveedores/ERP-Proveedores-Proveedores.component';
import { ERPProveedoresMateriasPrimasComponent } from './Pages/Section-ERP/ERP-Proveedores/_Holders/ERP-Proveedores-MateriasPrimas/ERP-Proveedores-MateriasPrimas.component';
import { ERPProveedoresPedidosComponent } from './Pages/Section-ERP/ERP-Proveedores/_Holders/ERP-Proveedores-Pedidos/ERP-Proveedores-Pedidos.component';
import { ERPProveedoresDetallesComponent } from './Pages/Section-ERP/ERP-Proveedores/_Holders/ERP-Proveedores-Proveedores/ERP-Proveedores-Detalles/ERP-Proveedores-Detalles.component';
import { ERPProveedoresDetallesFichaComponent } from './Pages/Section-ERP/ERP-Proveedores/_Holders/ERP-Proveedores-Proveedores/ERP-Proveedores-Detalles/_Holders/ERP-Proveedores-Detalles-Ficha/ERP-Proveedores-Detalles-Ficha.component';
import { ERPClienteDetailHistoricoPedidosComponent } from './Pages/Section-ERP/ERP-Clientes/_holder/ERP-Clientes-CLientesLista/ERP-ClienteDetailView/_holder/ERP-Cliente-Detail-HistoricoPedidos/ERP-Cliente-Detail-HistoricoPedidos.component';
import { ERPClienteDetailContabilidadComponent } from './Pages/Section-ERP/ERP-Clientes/_holder/ERP-Clientes-CLientesLista/ERP-ClienteDetailView/_holder/ERP-Cliente-Detail-Contabilidad/ERP-Cliente-Detail-Contabilidad.component';
import { ERPClienteDetailFichaDeProductosComponent } from './Pages/Section-ERP/ERP-Clientes/_holder/ERP-Clientes-CLientesLista/ERP-ClienteDetailView/_holder/ERP-Cliente-Detail-FichaDeProductos/ERP-Cliente-Detail-FichaDeProductos.component';
import { NuevaDireccionDeEnvioAlternativaComponent } from './Popups/NuevaDireccionDeEnvioAlternativa/NuevaDireccionDeEnvioAlternativa.component';
import { ClientesPedidosDetailComponent } from './Pages/Section-ERP/ERP-Clientes/_holder/ERP-Clientes-Pedidos/Clientes-Pedidos-Detail/Clientes-Pedidos-Detail.component';
import { CPDFichaPedidoComponent } from './Pages/Section-ERP/ERP-Clientes/_holder/ERP-Clientes-Pedidos/Clientes-Pedidos-Detail/_holder/CPD-FichaPedido/CPD-FichaPedido.component';
import { CPDPedidoNumeroComponent } from './Pages/Section-ERP/ERP-Clientes/_holder/ERP-Clientes-Pedidos/Clientes-Pedidos-Detail/_holder/CPD-PedidoNumero/CPD-PedidoNumero.component';
import { ERPProveedoresDetallesHistoricoPedidosComponent } from './Pages/Section-ERP/ERP-Proveedores/_Holders/ERP-Proveedores-Proveedores/ERP-Proveedores-Detalles/_Holders/ERP-Proveedores-Detalles-HistoricoPedidos/ERP-Proveedores-Detalles-HistoricoPedidos.component';
import { ERPProveedoresDetallesContabilidadComponent } from './Pages/Section-ERP/ERP-Proveedores/_Holders/ERP-Proveedores-Proveedores/ERP-Proveedores-Detalles/_Holders/ERP-Proveedores-Detalles-Contabilidad/ERP-Proveedores-Detalles-Contabilidad.component';
import { ERPProveedoresDetallesFichaProductosComponent } from './Pages/Section-ERP/ERP-Proveedores/_Holders/ERP-Proveedores-Proveedores/ERP-Proveedores-Detalles/_Holders/ERP-Proveedores-Detalles-FichaProductos/ERP-Proveedores-Detalles-FichaProductos.component';
import { DocumentosPopupComponent } from './Popups/Documentos-Popup/Documentos-Popup.component';
import { CrearProveedorPopupComponent } from './Popups/CrearProveedor-Popup/CrearProveedor-Popup.component';
import { AsignarProductoProveedorPopupComponent } from './Popups/AsignarProductoProveedor-Popup/AsignarProductoProveedor-Popup.component';

import { NuevoClientePedidoComponentPopup } from './Popups/NuevoClientePedido-Popup/NuevoClientePedido-Popup.component';
import { DatePickerComponent } from './Shared/Components/DatePicker/DatePicker.component';
import { ERPMateriasPrimasDetallesComponent } from './Pages/Section-ERP/ERP-Proveedores/_Holders/ERP-Proveedores-MateriasPrimas/ERP-MateriasPrimas-Detalles/ERP-MateriasPrimas-Detalles.component';
import { ERPMateriasPrimasDetallesFichaComponent } from './Pages/Section-ERP/ERP-Proveedores/_Holders/ERP-Proveedores-MateriasPrimas/ERP-MateriasPrimas-Detalles/_Holders/ERP-MateriasPrimas-Detalles-Ficha/ERP-MateriasPrimas-Detalles-Ficha.component';
import { ERPMateriasPrimasDetallesCaracteristicasComponent } from './Pages/Section-ERP/ERP-Proveedores/_Holders/ERP-Proveedores-MateriasPrimas/ERP-MateriasPrimas-Detalles/_Holders/ERP-MateriasPrimas-Detalles-Caracteristicas/ERP-MateriasPrimas-Detalles-Caracteristicas.component';
import { ERPMateriasPrimasDetallesHistoricoComponent } from './Pages/Section-ERP/ERP-Proveedores/_Holders/ERP-Proveedores-MateriasPrimas/ERP-MateriasPrimas-Detalles/_Holders/ERP-MateriasPrimas-Detalles-Historico/ERP-MateriasPrimas-Detalles-Historico.component';
import { NuevaMateriaPrimaPopupComponent } from './Popups/NuevaMateriaPrima-Popup/NuevaMateriaPrima-Popup.component';
import { ERPPartesProduccionComponent } from './Pages/Section-ERP/ERP-PartesProduccion/ERP-PartesProduccion.component';
import { ERPPPFormulasComponent } from './Pages/Section-ERP/ERP-PartesProduccion/_holder/ERP-PP-Formulas/ERP-PP-Formulas.component';
import { ERPPedidosDetallesComponent } from './Pages/Section-ERP/ERP-Proveedores/_Holders/ERP-Proveedores-Pedidos/ERP-Pedidos-Detalles/ERP-Pedidos-Detalles.component';
import { ERPPedidosDetallesFichaComponent } from './Pages/Section-ERP/ERP-Proveedores/_Holders/ERP-Proveedores-Pedidos/ERP-Pedidos-Detalles/_Holders/ERP-Pedidos-Detalles-Ficha/ERP-Pedidos-Detalles-Ficha.component';
import { ERPPedidosDetallesPedidoComponent } from './Pages/Section-ERP/ERP-Proveedores/_Holders/ERP-Proveedores-Pedidos/ERP-Pedidos-Detalles/_Holders/ERP-Pedidos-Detalles-Pedido/ERP-Pedidos-Detalles-Pedido.component';
import { NuevoProveedorPedidoPopupComponent } from './Popups/NuevoProveedorPedido-Popup/NuevoProveedorPedido-Popup.component';
import { ERPPPPartesProduccionComponent } from './Pages/Section-ERP/ERP-PartesProduccion/_holder/ERP-PP-PartesProduccion/ERP-PP-PartesProduccion.component';
import { NuevaFormulaPopupComponent } from './Popups/NuevaFormula-Popup/NuevaFormula-Popup.component';
import { NuevaFormularStep1Component } from './Popups/NuevaFormula-Popup/_Holder/NuevaFormular-Step1/NuevaFormular-Step1.component';
import { NuevoParteDeProduccionPopupComponent } from './Popups/NuevoParteDeProduccion-Popup/NuevoParteDeProduccion-Popup.component';
import { NuevoPartePopupFichaComponent } from './Popups/NuevoParteDeProduccion-Popup/_holder/NuevoPartePopup-Ficha/NuevoPartePopup-Ficha.component';
import { NuevoPartePopupPlanificacionEnvasadoComponent } from './Popups/NuevoParteDeProduccion-Popup/_holder/NuevoPartePopup-PlanificacionEnvasado/NuevoPartePopup-PlanificacionEnvasado.component';
import { ERPStockComponent } from './Pages/Section-ERP/ERP-Stock/ERP-Stock.component';
import { ERPStockMateriasPrimasComponent } from './Pages/Section-ERP/ERP-Stock/_holder/ERP-Stock-MateriasPrimas/ERP-Stock-MateriasPrimas.component';
import { ERPStockArticulosComponent } from './Pages/Section-ERP/ERP-Stock/_holder/ERP-Stock-Articulos/ERP-Stock-Articulos.component';
import { ERPStockArticulosDetailViewComponent } from './Pages/Section-ERP/ERP-Stock/_holder/ERP-Stock-Articulos/ERP-Stock-Articulos-DetailView/ERP-Stock-Articulos-DetailView.component';
import { ERPStockArticulosHistoricoComponent } from './Pages/Section-ERP/ERP-Stock/_holder/ERP-Stock-Articulos/ERP-Stock-Articulos-DetailView/_holder/ERP-Stock-Articulos-Historico/ERP-Stock-Articulos-Historico.component';
import { NuevoAsientoPopupComponent } from './Popups/NuevoAsiento-Popup/NuevoAsiento-Popup.component';
import { ERPStockMateriasPrimasDetailViewComponent } from './Pages/Section-ERP/ERP-Stock/_holder/ERP-Stock-MateriasPrimas/ERP-Stock-MateriasPrimas-DetailView/ERP-Stock-MateriasPrimas-DetailView.component';
import { ERPStockMateriasHistoricoComponent } from './Pages/Section-ERP/ERP-Stock/_holder/ERP-Stock-MateriasPrimas/ERP-Stock-MateriasPrimas-DetailView/_holder/ERP-Stock-Materias-Historico/ERP-Stock-Materias-Historico.component';
import { ERPContabilidadComponent } from './Pages/Section-ERP/ERP-Contabilidad/ERP-Contabilidad.component';
import { ERPContabilidadProveedoresComponent } from './Pages/Section-ERP/ERP-Contabilidad/_holder/ERP-Contabilidad-Proveedores/ERP-Contabilidad-Proveedores.component';
import { NuevaFormulaStep2Component } from './Popups/NuevaFormula-Popup/_Holder/NuevaFormula-Step2/NuevaFormula-Step2.component';
import { NuevaPrevisionDePagosPopupComponent } from './Popups/NuevaPrevisionDePagos-Popup/NuevaPrevisionDePagos-Popup.component';
import { ERPContabilidadClientesComponent } from './Pages/Section-ERP/ERP-Contabilidad/_holder/ERP-Contabilidad-Clientes/ERP-Contabilidad-Clientes.component';
import { NuevaPrevisionDeCobrosPopupComponent } from './Popups/NuevaPrevisionDeCobros-Popup/NuevaPrevisionDeCobros-Popup.component';
import { NuevaFormulaStep3Component } from './Popups/NuevaFormula-Popup/_Holder/NuevaFormula-Step3/NuevaFormula-Step3.component';
import { NuevaFormulaStep4Component } from './Popups/NuevaFormula-Popup/_Holder/NuevaFormula-Step4/NuevaFormula-Step4.component';
import { NuevaFormulaStep5Component } from './Popups/NuevaFormula-Popup/_Holder/NuevaFormula-Step5/NuevaFormula-Step5.component';
import { ERPPPPartesProduccionDetailViewComponent } from './Pages/Section-ERP/ERP-PartesProduccion/_holder/ERP-PP-PartesProduccion/ERP-PP-PartesProduccion-DetailView/ERP-PP-PartesProduccion-DetailView.component';
import { ERPPPPPDVFichaComponent } from './Pages/Section-ERP/ERP-PartesProduccion/_holder/ERP-PP-PartesProduccion/ERP-PP-PartesProduccion-DetailView/_holder/ERP-PP-PP-DV-Ficha/ERP-PP-PP-DV-Ficha.component';
import { ERPPPPPDVFormulaComponent } from './Pages/Section-ERP/ERP-PartesProduccion/_holder/ERP-PP-PartesProduccion/ERP-PP-PartesProduccion-DetailView/_holder/ERP-PP-PP-DV-Formula/ERP-PP-PP-DV-Formula.component';
import { ERPPPPPDVPlanificacionEnvasadoComponent } from './Pages/Section-ERP/ERP-PartesProduccion/_holder/ERP-PP-PartesProduccion/ERP-PP-PartesProduccion-DetailView/_holder/ERP-PP-PP-DV-PlanificacionEnvasado/ERP-PP-PP-DV-PlanificacionEnvasado.component';
import { CRMEstadisticasComponent } from './Pages/Section-CRM/CRM-Estadisticas/CRM-Estadisticas.component';
import { ConsumoProductosComponent } from './Pages/Section-CRM/CRM-Estadisticas/_Holders/ConsumoProductos/ConsumoProductos.component';
import { ConsumoClientesComponent } from './Pages/Section-CRM/CRM-Estadisticas/_Holders/ConsumoClientes/ConsumoClientes.component';
import { ERPPPPPDVEspecificacionesDeProductoComponent } from './Pages/Section-ERP/ERP-PartesProduccion/_holder/ERP-PP-PartesProduccion/ERP-PP-PartesProduccion-DetailView/_holder/ERP-PP-PP-DV-EspecificacionesDeProducto/ERP-PP-PP-DV-EspecificacionesDeProducto.component';
import { ERPPPPPDVEspecificacionesDeLoteComponent } from './Pages/Section-ERP/ERP-PartesProduccion/_holder/ERP-PP-PartesProduccion/ERP-PP-PartesProduccion-DetailView/_holder/ERP-PP-PP-DV-EspecificacionesDeLote/ERP-PP-PP-DV-EspecificacionesDeLote.component';
import { ERPPPFormulasDVComponent } from './Pages/Section-ERP/ERP-PartesProduccion/_holder/ERP-PP-Formulas/ERP-PP-Formulas-DV/ERP-PP-Formulas-DV.component';
import { ERPPPFormulasDVFichaComponent } from './Pages/Section-ERP/ERP-PartesProduccion/_holder/ERP-PP-Formulas/ERP-PP-Formulas-DV/_holder/ERP-PP-Formulas-DV-Ficha/ERP-PP-Formulas-DV-Ficha.component';
import { FacturacionComponent } from './Pages/Section-CRM/CRM-Estadisticas/_Holders/Facturacion/Facturacion.component';
import { LineChartComponent } from './Pages/Section-CRM/CRM-Estadisticas/_Holders/Facturacion/LineChart/LineChart.component';
import { BarChartComponent } from './Pages/Section-CRM/CRM-Estadisticas/_Holders/Facturacion/BarChart/BarChart.component';
import { SingleLineChartComponent } from './Pages/Section-CRM/CRM-Estadisticas/_Holders/Facturacion/SingleLineChart/SingleLineChart.component';
import { ConversionComponent } from './Pages/Section-CRM/CRM-Estadisticas/_Holders/Conversion/Conversion.component';
import { ERPPPFormulasDVFormulaComponent } from './Pages/Section-ERP/ERP-PartesProduccion/_holder/ERP-PP-Formulas/ERP-PP-Formulas-DV/_holder/ERP-PP-Formulas-DV-Formula/ERP-PP-Formulas-DV-Formula.component';
import { CRMAlertasComponent } from './Pages/Section-CRM/CRM-Alertas/CRM-Alertas.component';
import { CRMDocumentosComponent } from './Pages/Section-CRM/CRM-Documentos/CRM-Documentos.component';
import { NuevaCarpetaPopupComponent } from './Popups/NuevaCarpeta-Popup/NuevaCarpeta-Popup.component';
import { CRMDocumentosDetallesCarpetaComponent } from './Pages/Section-CRM/CRM-Documentos/CRM-Documentos-DetallesCarpeta/CRM-Documentos-DetallesCarpeta.component';
import { CRMAccionesComercialesComponent } from './Pages/Section-CRM/CRM-AccionesComerciales/CRM-AccionesComerciales.component';
import { CRMAccionesComecialesListaComponent } from './Pages/Section-CRM/CRM-AccionesComerciales/_Holders/CRM-AccionesComeciales-Lista/CRM-AccionesComeciales-Lista.component';
import { NuevaAccionComercialPopupComponent } from './Popups/NuevaAccionComercial-Popup/NuevaAccionComercial-Popup.component';
import { NuevoDocumentoPopupComponent } from './Popups/NuevoDocumento-Popup/NuevoDocumento-Popup.component';
import { ERPPPFormulasDVEnvasesCompatiblesComponent } from './Pages/Section-ERP/ERP-PartesProduccion/_holder/ERP-PP-Formulas/ERP-PP-Formulas-DV/_holder/ERP-PP-Formulas-DV-EnvasesCompatibles/ERP-PP-Formulas-DV-EnvasesCompatibles.component';
import { ERPPPEspecificacionesDeProductoComponent } from './Pages/Section-ERP/ERP-PartesProduccion/_holder/ERP-PP-Formulas/ERP-PP-Formulas-DV/_holder/ERP-PP-EspecificacionesDeProducto/ERP-PP-EspecificacionesDeProducto.component';
import { ProfilePopupComponent } from './Popups/Profile-Popup/Profile-Popup.component';
import { SubirDocumentoPopupComponent } from './Popups/SubirDocumento-Popup/SubirDocumento-Popup.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { PaginationComponent } from './Shared/Components/Pagination/Pagination.component';
import { CambiarEstadoPedidoProveedorPopupComponent } from './Popups/CambiarEstadoPedidoProveedor-Popup/CambiarEstadoPedidoProveedor-Popup.component';
import { EditarCondicionalesTarifasPopupComponent } from './Popups/EditarCondicionalesTarifas-Popup/EditarCondicionalesTarifas-Popup.component';
import { NuevoAsientoRawMAterialPopupComponent } from './Popups/NuevoAsientoRawMAterial-Popup/NuevoAsientoRawMAterial-Popup.component';
import { CambiarEstadoPedidoClientePopupComponent } from './Popups/CambiarEstadoPedidoCliente-Popup/CambiarEstadoPedidoCliente-Popup.component';
import { NuevoRateProductoClientePopupComponent } from './Popups/NuevoRateProductoCliente-Popup/NuevoRateProductoCliente-Popup.component';
import { NuevoStockMateriasPrimasPopupComponent } from './Popups/NuevoStockMateriasPrimas-Popup/NuevoStockMateriasPrimas-Popup.component';
import { EditarPedidoClientePopupComponent } from './Popups/EditarPedidoClientePopup/EditarPedidoClientePopup.component';
import { NuevoStaockArticulosPopupComponent } from './Popups/NuevoStaockArticulos-popup/NuevoStaockArticulos-popup.component';
import { ERPClientesAlbaranComponent } from './Pages/Section-ERP/ERP-Clientes/_holder/ERP-Clientes-Albaran/ERP-Clientes-Albaran.component';
import { NuevoAlbaranPopupComponent } from './Popups/NuevoAlbaran-Popup/NuevoAlbaran-Popup.component';
import { NuevoAlbaranPopupSeccion2Component } from './Popups/NuevoAlbaran-Popup/_holder/NuevoAlbaranPopupSeccion2/NuevoAlbaranPopupSeccion2.component';
import { NuevoPedidoAlbaranPopupComponent } from './Popups/NuevoPedidoAlbaran-Popup/NuevoPedidoAlbaran-Popup.component';
import { NuevoAlbaranPopupSeccion3Component } from './Popups/NuevoAlbaran-Popup/_holder/NuevoAlbaranPopupSeccion3/NuevoAlbaranPopupSeccion3.component';
import { EditAlbaranPopupComponent } from './Popups/EditAlbaranPopup/EditAlbaranPopup.component';
import { NuevaFacturaSeccion1PopupComponent } from './Popups/EditAlbaranPopup/_holder/NuevaFacturaSeccion1-popup/NuevaFacturaSeccion1-popup.component';
import { NuevaFacturaSeccion2PopupComponent } from './Popups/EditAlbaranPopup/_holder/NuevaFacturaSeccion2-popup/NuevaFacturaSeccion2-popup.component';
import { CambiarEstadoBillPopupComponent } from './Popups/CambiarEstadoBill-Popup/CambiarEstadoBill-Popup.component';
import { CrearAsientoDetalleCompanyPopupComponent } from './Popups/CrearAsientoDetalleCompany-Popup/CrearAsientoDetalleCompany-Popup.component';
import { CierreDeFabricacionPopupComponent } from './Popups/CierreDeFabricacion-Popup/CierreDeFabricacion-Popup.component';
import { CierreDeFabricacionParteFormulaPopupComponent } from './Popups/CierreDeFabricacion-Popup/_holder/CierreDeFabricacionParteFormula-Popup/CierreDeFabricacionParteFormula-Popup.component';
import { CierreDeFabricacionParteEnvasesPopupComponent } from './Popups/CierreDeFabricacion-Popup/_holder/CierreDeFabricacionParteEnvases-Popup/CierreDeFabricacionParteEnvases-Popup.component';
import { CierreDeFabricacionParteCaracteristicasPopupComponent } from './Popups/CierreDeFabricacion-Popup/_holder/CierreDeFabricacionParteCaracteristicas-Popup/CierreDeFabricacionParteCaracteristicas-Popup.component';
import { ERPUsersComponent } from './Pages/Section-ERP/ERP-Users/ERP-Users.component';
import { RecoverPasswordComponent } from './Pages/Section-Login/RecoverPassword/RecoverPassword.component';

registerLocaleData(localEs, 'es');

@NgModule({
  declarations: [
    AppComponent,
    // Components
    CustomInputComponent,
    FullContainerComponent,
    HeaderComponent,
    CustomToggleComponent,
    FilterActionsComponent,
    SearcherComponent,
    CustomTextAreaComponent,
    DatePickerComponent,
    PaginationComponent,
    // Pages
    // CRM
    CRMDashboardComponent,
    CRMClientesComponent,
    ClientesClientesComponent,
    ClientesCPotencialesComponent,
    ClientesPotencialesPipelineComponent,
    ClientesMaquinasComponent,
    ClientesMapaComponent,
    ClientesDetailViewComponent,
    InformacionDeClienteComponent,
    InformacionComercialComponent,
    DatosDeConsumoComponent,
    IncidenciasComponent,
    FichaDeProductoComponent,
    HistoricoPedidosComponent,
    ClientesAntiguosComponent,
    CPotencialesDetailViewComponent,
    MaquinasDetailViewComponent,
    ClientesAntiguosDetailViewComponent,
    CAInformacionDelClienteComponent,
    CADireccionesAlternativasComponent,
    CAHistoricoPedidosComponent,
    CACondicionesEspecialesComponent,
    ClienteDetailViewComponent,
    NuevaFormularStep1Component,
    NuevaFormulaStep2Component,
    CRMEstadisticasComponent,
    ConsumoProductosComponent,
    CRMAlertasComponent,
    CRMDocumentosComponent,
    CRMDocumentosDetallesCarpetaComponent,
    CRMAccionesComercialesComponent,
    CRMAccionesComecialesListaComponent,
    NuevoDocumentoPopupComponent,
    // Login
    LoginPageComponent,
    ContainerLoginComponent,
    ForgotPasswordComponent,
    RecoverPasswordComponent,
    // ERP
    ERPDashboardComponent,
    ERPMantenimientosComponent,
    ERPMantenimientosDetallesComponent,
    ERPMantenimientosDetallesCaracteristicasComponent,
    ERPMantenimientosDetallesFamiliasComponent,
    ERPMantenimientosDetallesSubfamiliasComponent,
    ERPMantenimientoDetallesEnvasesComponent,
    ERPMantenimientoDetallesAgentesComponent,
    ERPMantenimientoDetallesTipoIvaComponent,
    ERPMantenimientosDetallesFormasPagoComponent,
    ERPMantenimientoDetallesPortesPagoComponent,
    ERPMantenimientoDetallesFungiblesComponent,
    ERPMantenimientosDetallesTipoIncidenciaComponent,
    ERPClientesCLientesListaComponent,
    ERPClientesPedidosComponent,
    ERPClientesComponent,
    ERPTarifasComponent,
    ERPClientesComponent,
    ERPTarifasPolvoComponent,
    ERPTarifasClientePolvoComponent,
    ERPTarifasLiquidaComponent,
    ERPTarifasClienteLiquidaComponent,
    ERPClienteDetailFichaComponent,
    ERPCLIENTEDetailDireccionesAlternativasComponent,
    ERPClienteDetailFichaDeProductosComponent,
    ERPClienteDetailHistoricoPedidosComponent,
    ERPClienteDetailContabilidadComponent,

    ERPProveedoresComponent,
    ERPProveedoresProveedoresComponent,
    ERPProveedoresMateriasPrimasComponent,
    ERPProveedoresPedidosComponent,
    ERPProveedoresDetallesComponent,
    ERPProveedoresDetallesFichaComponent,
    ClientesPedidosDetailComponent,
    CPDFichaPedidoComponent,
    CPDPedidoNumeroComponent,
    ERPProveedoresDetallesHistoricoPedidosComponent,
    ERPProveedoresDetallesContabilidadComponent,
    ERPProveedoresDetallesFichaProductosComponent,
    ERPMateriasPrimasDetallesComponent,
    ERPMateriasPrimasDetallesFichaComponent,
    ERPMateriasPrimasDetallesCaracteristicasComponent,
    ERPMateriasPrimasDetallesHistoricoComponent,
    ERPPartesProduccionComponent,
    ERPPPFormulasComponent,
    ERPPedidosDetallesComponent,
    ERPPedidosDetallesFichaComponent,
    ERPPedidosDetallesPedidoComponent,
    ERPPPPartesProduccionComponent,
    ERPStockComponent,
    ERPStockMateriasPrimasComponent,
    ERPStockArticulosComponent,
    ERPStockArticulosDetailViewComponent,
    ERPStockArticulosHistoricoComponent,
    ERPStockMateriasPrimasDetailViewComponent,
    ERPStockMateriasHistoricoComponent,
    ERPContabilidadComponent,
    ERPContabilidadProveedoresComponent,
    ERPContabilidadClientesComponent,
    ERPPPPartesProduccionDetailViewComponent,
    ERPPPPPDVFichaComponent,
    ERPPPPPDVFormulaComponent,


    NuevaFormulaStep3Component,
    NuevaFormulaStep4Component,
    NuevaFormulaStep5Component,
    ERPPPPPDVPlanificacionEnvasadoComponent,
    ERPPPPPDVEspecificacionesDeProductoComponent,
    ERPPPPPDVEspecificacionesDeLoteComponent,
    ERPPPFormulasDVComponent,
    ERPPPFormulasDVFichaComponent,
    ERPPPFormulasDVFormulaComponent,
    ERPPPFormulasDVEnvasesCompatiblesComponent,
    ERPPPEspecificacionesDeProductoComponent,
    ERPClientesAlbaranComponent,
    ERPUsersComponent,
    // Popups
    LoginLoadingComponent,
    ConfirmPopupComponent,
    NuevaCaracteristicaPopupComponent,
    NuevaFamiliaPopupComponent,
    NuevaSubfamiliasPopupComponent,
    NuevoEnvasePopupComponent,
    NuevoAgentePopupComponent,
    EditarInformacionDelClientePopupComponent,
    NuevaFormaPagoPopupComponent,
    NuevoFungiblePopupComponent,
    NuevoPortesPopupComponent,
    NuevoTipoIncidenciaPopupComponent,
    NuevoTipoIvaPopupComponent,
    EditarTarifasPopupComponent,
    NuevoClientePolvoPopupComponent,
    EditarClienteLiquidoPopupComponent,
    NuevoClientePopupComponent,
    NuevaDireccionDeEnvioAlternativaComponent,
    DocumentosPopupComponent,
    CrearProveedorPopupComponent,
    AsignarProductoProveedorPopupComponent,
    NuevoClientePedidoComponentPopup,
    NuevaMateriaPrimaPopupComponent,
    NuevoProveedorPedidoPopupComponent,
    NuevaFormulaPopupComponent,
    NuevoParteDeProduccionPopupComponent,
    NuevoPartePopupFichaComponent,
    NuevoPartePopupPlanificacionEnvasadoComponent,
    NuevoAsientoPopupComponent,
    NuevaPrevisionDePagosPopupComponent,
    NuevaPrevisionDeCobrosPopupComponent,
    ConsumoClientesComponent,
    FacturacionComponent,
    LineChartComponent,
    BarChartComponent,
    SingleLineChartComponent,
    ConversionComponent,
    NuevaCarpetaPopupComponent,
    NuevaAccionComercialPopupComponent,
    ProfilePopupComponent,
    SubirDocumentoPopupComponent,
    CambiarEstadoPedidoProveedorPopupComponent,
    EditarCondicionalesTarifasPopupComponent,
    NuevoAsientoRawMAterialPopupComponent,
    CambiarEstadoPedidoClientePopupComponent,
    NuevoRateProductoClientePopupComponent,
    NuevoStockMateriasPrimasPopupComponent,
    EditarPedidoClientePopupComponent,
    NuevoStaockArticulosPopupComponent,
    NuevoAlbaranPopupComponent,
    NuevoAlbaranPopupSeccion2Component,
    NuevoPedidoAlbaranPopupComponent,
    NuevoAlbaranPopupSeccion3Component,
    EditAlbaranPopupComponent,
    NuevaFacturaSeccion1PopupComponent,
    NuevaFacturaSeccion2PopupComponent,
    CambiarEstadoBillPopupComponent,
    CrearAsientoDetalleCompanyPopupComponent,
    CierreDeFabricacionPopupComponent,
    CierreDeFabricacionParteFormulaPopupComponent,
    CierreDeFabricacionParteEnvasesPopupComponent,
    CierreDeFabricacionParteCaracteristicasPopupComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    NgSelectModule,
    QuasarLibraryModule,
    MatIconModule,
    DragDropModule,
    CommonModule,
    MatNativeDateModule,
    MatIconModule,
    GoogleMapsModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    NgChartsModule,
    MatCheckboxModule,
    MatRadioModule,
    HttpClientModule,

  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'es' },
    CurrencyPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
