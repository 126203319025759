<div class="body">
  <CustomInput [readonly]="true" classList="fullWidth tall special" placeholder="Código de parte"
    label="Código de parte" [(ngModel)]="data.codigoParte" (change)="checkIsCompleted()"></CustomInput>
  <div class="customContainer">
    <label for="Producto">Producto</label>
    <selector (change)="checkIsCompleted()" [options]="opcionesProductos" [(ngModel)]="data.producto"></selector>
  </div>
  <div class="customContainer">
    <label for="Fecha de entrega">Fecha de entrega</label>
    <datepicker (dataFromPicker)="dataFromPicker($event)" [placeholder]="data.fechaEntrega"></datepicker>
  </div>
  <CustomInput [readonly]="modalData.accion=='ver'" classList="fullWidth tall special" placeholder="Cantidad a fabricar"
    label="Cantidad a fabricar" [type]="'number'" [(ngModel)]="data.cantidad" (change)="checkIsCompleted()">
  </CustomInput>
  <div class="customContainer">
    <label for="Producto">Área de produccion</label>
    <selector (change)="checkIsCompleted()" [options]="opcionesAreaProduccion" [(ngModel)]="areaProduccion">
    </selector>
  </div>
</div>