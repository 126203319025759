<div class="fullAccordeon">
  <div class="header">
    <filterActions [activeBtn]="false" [disableFilter]="true" (searchSend)="search($event)">
      <ng-container filterContent>
        <div class="containerSelectores">
          <div>
            <selector></selector>
          </div>
        </div>
      </ng-container>
    </filterActions>
    <div class="buttons">
      <btn classList="fino" (click)="asignarProducto()">
        <ng-container >
          Asignar un producto
        </ng-container>
      </btn>
    </div>
  </div>
  <pill class="tablePil" classList="tablePill">
    <dataTable [data]="dataForTable" [isLoading]="isLoading" [options]="optionsTabla"
      [displayedColumns]="displayedColumnsTabla"  (clickRow)="see($event)" [forceUpdateWidth]="_reloadTableWidth" (delete)="delete($event,'single')"
      (deleteAll)="delete(null,'all')" (deleteSelected)="delete($event,'selected')" ></dataTable>
  </pill>
</div>
