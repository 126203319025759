import { iBaseObject } from "@quasar_dynamics/basic-designsystem";
import { iFamily } from "./iFamily";
import { iSubfamily } from "./iSubfamily";
import { iMeasurementUnit } from "./iMeasurementUnit";
import { iProductionReport } from "./iProductionReport";
import { iSpecification } from "./iSpecification";

export interface iRawMaterial {
    id?: number,
    code?: string,
    secondaryCode?: string,
    name?: string,
    minStock?: number,
    lastPrice?: number,
        attacheds?: [],
    description?: string,
    realStock?: number,
    reservedStock?: number,
    pendingStock?: number,
    virtualStock?: number,
    family?: iFamily,
    subfamily?: iSubfamily,
    measurementUnit?: iMeasurementUnit,
    production?: iProductionReport[],
    specification?: iSpecification,
}

export class iRawMaterial extends iBaseObject {
    static convertFromCollection(ob: any): Array<iRawMaterial> {
        let iRawMaterialCollection: Array<iRawMaterial> = [];
        ob.forEach((element: any) => {
            iRawMaterialCollection.push(this.convertFromObject(element));
        });
        return iRawMaterialCollection;
    }

    static convertFromObject(ob: any): iRawMaterial {
        let iRawMaterialObj = new iRawMaterial();
        iRawMaterialObj.id = ob.id;
        iRawMaterialObj.code = ob.code;
        iRawMaterialObj.secondaryCode = ob.secondaryCode;
        iRawMaterialObj.name = ob.name;
        iRawMaterialObj.minStock = ob.minStock;
        iRawMaterialObj.lastPrice = ob.lastPrice;
        iRawMaterialObj.attacheds = ob.attacheds;
        iRawMaterialObj.description = ob.description;
        iRawMaterialObj.realStock = ob.realStock;
        iRawMaterialObj.reservedStock = ob.reservedStock;
        iRawMaterialObj.pendingStock = ob.pendingStock;
        iRawMaterialObj.virtualStock = ob.virtualStock;
        iRawMaterialObj.family = ob.family ? iFamily.convertFromObject(ob.family) : iFamily.createEmptyObject();
        iRawMaterialObj.subfamily = ob.subfamily ? iSubfamily.convertFromObject(ob.subfamily) : iSubfamily.createEmptyObject();
        iRawMaterialObj.measurementUnit = ob.measurementUnit ? iMeasurementUnit.convertFromObject(ob.measurementUnit) : new iMeasurementUnit();
        iRawMaterialObj.production = ob.production ? iProductionReport.convertFromCollection(ob.production) : [];
        iRawMaterialObj.specification = ob.specification ? iSpecification.convertFromObject(ob.specification) : new iSpecification();
        return iRawMaterialObj;
    }

    static createEmptyObject(): iRawMaterial {
        let iRawMaterialObj = new iRawMaterial();
        iRawMaterialObj.code = '';
        iRawMaterialObj.secondaryCode = '';
        iRawMaterialObj.name = '';
        iRawMaterialObj.minStock = -1;
        iRawMaterialObj.lastPrice = -1;
        iRawMaterialObj.attacheds = [];
        iRawMaterialObj.description = '';
        iRawMaterialObj.realStock = -1;
        iRawMaterialObj.reservedStock = -1;
        iRawMaterialObj.pendingStock = -1;
        iRawMaterialObj.virtualStock = -1;
        iRawMaterialObj.family = iFamily.createEmptyObject();
        iRawMaterialObj.subfamily = iSubfamily.createEmptyObject();
        iRawMaterialObj.measurementUnit = iMeasurementUnit.createEmptyObject();
        iRawMaterialObj.production = [];
        iRawMaterialObj.specification = new iSpecification();
        return iRawMaterialObj;
    }
}

export interface iRawMaterialFilter {
    num_devoluciones: number;
    num_pagina: number;
    genericFilter?: string;
    code?: string,
    secondaryCode?: string,
    name?: string,
    minStock?: number,
    lastPrice?: number,
    attacheds?: number,
    description?: string,
    realStock?: number,
    reservedStock?: number,
    pendingStock?: number,
    virtualStock?: number,
    family?: number,
    subfamily?: number,
    measurementUnit?: number,
    production?: number,
    specification?: number,
}