import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { iOptionsSelector, iUnsubscribeDestroy } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { FamilyService } from 'src/app/Services/Api/Family.service';
import { SubfamilyService } from 'src/app/Services/Api/Subfamily.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'app-NuevaSubfamilias-Popup',
  templateUrl: './NuevaSubfamilias-Popup.component.html',
  styleUrls: ['./NuevaSubfamilias-Popup.component.scss']
})
export class NuevaSubfamiliasPopupComponent extends iUnsubscribeDestroy implements OnInit {

  descripcion: string = "";
  notas: string = ""
  codigo: string = ""
  isCompleted: boolean = false;

  opcionesFamilias: iOptionsSelector = {
    items: [],
    bindValue: 'id',
    search: true,
    placeholder: "Todas",
    append: true
  }
  familia: any = null;
  updateObject: any = {
    id:'',
  };

  constructor(public dialogRef: MatDialogRef<NuevaSubfamiliasPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any, private subFamilySE:SubfamilyService, private familySE:FamilyService) {
    super();
  }

  ngOnInit() {
     
    if (this.modalData.accion === 'editar' || this.modalData.accion === 'ver') {
      this.setter();
    }
    this.getFamiliasSelector();
  }

  /**
  * FUNCTIONALITY
  */
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }
  editSection(event, key:string){
    if(!event){this.checkIsCompleted();return}
    this.updateObject[key] = event;
    this.checkIsCompleted();
    this.update();
  }

  /**
   * GETTERS && SETTERS
   */

  setter(){
    this.descripcion = this.modalData.data.description;
    this.notas = this.modalData.data.note;
    this.codigo = this.modalData.data.code;
    this.familia = this.modalData.data.family.id;
    this.updateObject.id = this.modalData.data.id;
    this.checkIsCompleted();
  }

  /**
   * VALIDATIONS
   */
  checkIsCompleted() {
    this.codigo = this.codigo.toUpperCase();
    this.descripcion && this.notas && this.codigo && this.familia ? this.isCompleted = true : this.isCompleted = false
  }

  /**
   * CALLS TO API
   */

  update(){
    this.subFamilySE.update(this.updateObject);
    this.subFamilySE.getResultUpdate().pipe(takeUntil(this._unsubInd2)).subscribe((res:any)=>{
      if(!res){return}
      StaticUtilitiesService.showFeedback('Subfamilia actualizada correctamente')
      this._unsubInd2.next('')
    })
    this.subFamilySE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res:any)=>{
      if(!res){return}
      StaticUtilitiesService.showError(res.message)
      this._unsub.next('')
    })
  }
  getFamiliasSelector(){
    this.familySE.getAll({num_devoluciones:1000, num_pagina:1});
    this.familySE.getResult().pipe(takeUntil(this._unsubInd2)).subscribe((res:any)=>{
      if(!res){return}
      let {data} = res.data;
      this.opcionesFamilias.items = data.map((item:any)=>{return {label:item.code, id:item.id}})
      this._unsubInd2.next('')
    })
  }
  create(){
    this.subFamilySE.create({code:this.codigo, description:this.descripcion, note:this.notas, family:this.familia});
    this.subFamilySE.getResultUpdate().pipe(takeUntil(this._unsubInd2)).subscribe((res:any)=>{
      if(!res){return}
      StaticUtilitiesService.showFeedback('Subfamilia creada correctamente')
      this.closePopup('create')
      this._unsubInd2.next('')
    })
    this.subFamilySE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res:any)=>{
      if(!res){return}
      StaticUtilitiesService.showError(res.message)
      this._unsub.next('')
    })
  }

}
