<FullContainer>
  <div class="fullView">
    <div class="headerAndArchivar">
      <div class="header">
        <button class="goBack" (click)="goBack()">
          <ng-container>
            <mat-icon>
              arrow_back
            </mat-icon>
          </ng-container>
        </button>
        <p>PROVEEDORES / <span>{{orderName}} / {{orderId}}</span></p>
      </div>
      <div class="buttons">
        <ng-container *ngIf="false">
          <span class="button">
            <ng-container>
              <mat-icon>
                mail
              </mat-icon>
            </ng-container>
            <p>ENVIAR</p>
          </span>
        </ng-container>
        <span class="button" (click)="printScreen()">
          <ng-container>
            <mat-icon>
              print
            </mat-icon>
          </ng-container>
          <p>IMPRIMIR</p>
        </span>
      </div>
    </div>
    <div class="archivador">
      <accordion [open]="openAccordeon" [title]="'FICHA PEDIDO'">
        <ng-container>
          <erpPedidosDetallesFicha class="fullWidth" (orderName)="getOrderName($event)"></erpPedidosDetallesFicha>
        </ng-container>
      </accordion>
    </div>

    <div class="accordionInformacionComercial">
      <accordion [open]="openAccordeon" class="" [title]="'RESUMEN DEL PEDIDO' ">
        <erpPedidosDetallesPedido class="fullWidth" [orderId]="orderId"></erpPedidosDetallesPedido>
      </accordion>
    </div>

  </div>
</FullContainer>