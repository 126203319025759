<div class="fullAccordeon">
  <div class="header">
    <filterActions (searchSend)="search($event)" (addNew)="newAsiento()">
      <ng-container filterContent>
        <div class="containerSelectores">
          <div>
            <selector [options]="opcionesTipos" (change)="getSelector($event, 'type')"></selector>
          </div>
        </div>
      </ng-container>
    </filterActions>
  </div>

  <pill class="tablePil" classList="tablePill">
    <dataTable [data]="dataForTable" [isLoading]="isLoading" [options]="optionsTabla" (edit)="edit($event)"
      [displayedColumns]="displayedColumnsTabla" [forceUpdateWidth]="_reloadTableWidth"></dataTable>
  </pill>
</div>
