import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ERP-PP-Formulas-DV-EnvasesCompatibles',
  templateUrl: './ERP-PP-Formulas-DV-EnvasesCompatibles.component.html',
  styleUrls: ['./ERP-PP-Formulas-DV-EnvasesCompatibles.component.scss']
})
export class ERPPPFormulasDVEnvasesCompatiblesComponent implements OnInit {

  @Input() data:any = []


  dataForTable:any = [  ]

  constructor() { }

  ngOnInit() {
  }

}
