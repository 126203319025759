import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'app-NuevaCarpeta-Popup',
  templateUrl: './NuevaCarpeta-Popup.component.html',
  styleUrls: ['./NuevaCarpeta-Popup.component.scss']
})
export class NuevaCarpetaPopupComponent implements OnInit {
   data: any = {
    nombre: "",
    notas: ""
  }
  isCompleted: boolean = false;
  constructor(public dialogRef: MatDialogRef<NuevaCarpetaPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any) { }

  ngOnInit() {}

  /**
  * FUNCTIONALITY
  */
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }

/**
 * VALIDATIONS
 */
checkIsCompleted(){
  StaticUtilitiesService.isCompleteObject(this.data) ? this.isCompleted = true : this.isCompleted = false
}

}
