import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PopupService, iOptionsSelector, iUnsubscribeDestroy } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { BillService } from 'src/app/Services/Api/Bill.service';
import { DeliveryNoteService } from 'src/app/Services/Api/DeliveryNote.service';
import { GenerateBillService } from 'src/app/Services/Api/GenerateBill.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'app-EditAlbaranPopup',
  templateUrl: './EditAlbaranPopup.component.html',
  styleUrls: ['./EditAlbaranPopup.component.scss']
})
export class EditAlbaranPopupComponent extends iUnsubscribeDestroy implements OnInit {

  isFactura: boolean = false;
  page: number = 0;
  isSection1Completed: boolean = false;
  dataAlbaran: any = null;
  opcionesEstado: iOptionsSelector = {
    items: [
      { label: 'Pdt. De Salir', id: 'pdt. de salir' },
      { label: 'Salido', id: 'salido' },
    ],
    bindValue: 'label',
    bindLabel: 'label',
    append: true,
    placeholder: 'Estado',
    search: true,
    clearable: false,
  }
  state: string = 'pdt. de salir';
  attacheds: any = null;
  dataFromSection1: any = null;
  updateObject: any = {
    id: null,
  };

  constructor(public dialogRef: MatDialogRef<EditAlbaranPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any, private popupSE: PopupService, private deliveryNoteSE:DeliveryNoteService, private generateBillSE:GenerateBillService, private billSE:BillService) {
    super();
  }

  ngOnInit() {
    this.state = this.modalData.data.state;
    this.updateObject.id = this.modalData.data.id;
    this.getAlbaranId()
  }
  /**
* FUNCTIONALITY
*/
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }
  isComplete(event) {
    if (this.page === 0) {
      this.isSection1Completed = StaticUtilitiesService.isCompleteObject(event)
      this.dataFromSection1 = event;
    }
  }
  nextPage(){
    if(this.page === 1){return}
    this.page++;
  }
  lastPage(){
    if(this.page === 0){this.isFactura = false ;return}
    this.page--;
  }

  updateState(state) {
    this.updateObject['state'] = state;
    this.deliveryNoteSE.update(this.updateObject);
    this.deliveryNoteSE.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe((res) => {
      if(!res){return}
      StaticUtilitiesService.showFeedback('Albarán actualizado correctamente')
      this._unsubInd.next('')
    })
    this.deliveryNoteSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res) => {
      if(!res){return}
      StaticUtilitiesService.showError('El albarán no pudo ser actualizado')
      this._unsub.next('')
    })
   }

  generateFactura() {
    this.generateBillSE.generatePDFFactura({...this.dataAlbaran, ...this.dataFromSection1})
    this.generateBillSE.getPdf().pipe(takeUntil(this._unsubInd)).subscribe((res) => {
      if(!res){return}
      this.createBill(res)
      this._unsubInd.next('')
    })
  }

  /**
   * API CALLS
   */

  getAlbaranId(){
    this.deliveryNoteSE.getSingle(this.modalData.data.id);
    this.deliveryNoteSE.getResultIndividual().pipe(takeUntil(this._unsubInd)).subscribe((res) => {
      if(!res){return}
      this.dataAlbaran = res.data;
      this.attacheds = res.data.attacheds[0];
      this._unsubInd.next('')
    })
  }

  createBill(base64){
    let data = {
      attacheds: base64,
      paymentDate: this.dataFromSection1.paymentDate,
      deliveryNote: this.dataAlbaran.id,
      company: this.dataAlbaran.ordered[0].company.id,
    }
    this.billSE.create(data);
    this.billSE.getResultUpdate().pipe(takeUntil(this._unsubInd3)).subscribe((res) => {
      if(!res){return}
      StaticUtilitiesService.showFeedback('Factura creada correctamente')
      this._unsubInd3.next('')
    })
    this.billSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res) => {
      if(!res){return}
      StaticUtilitiesService.showError('La factura no pudo ser creada')
      this._unsub.next('')
    })
  }

}
