<pill>
  <ng-container>
    <div class="estadisticaGeneralContainer">
      <div class="header">
        <selector [options]="opcionesConsumo" [(ngModel)]="consumo"></selector>
        <p *ngIf="data.cliente == null">FACTURACIÓN ANUAL</p>
        <p *ngIf="data.cliente != null && data.cliente.key == 1" [ngStyle]="{'text-transform': 'Uppercase'}">TOP 15 CLIENTES POR FACTURACIÓN {{data.facturacion}}</p>
        <p *ngIf="data.cliente != null && data.cliente.key > 1" [ngStyle]="{'text-transform': 'Uppercase'}">{{data.cliente.value}}</p>
      </div>
      <div class="body">
        <ng-container *ngIf="data.cliente == null">
          <div class="grafica">
            <div>
              <lineChart></lineChart>
            </div>
          </div>
          <div class="filtros">
            <div class="leyend one">
              <div class="line"></div>
              <span>Facturación MOPASA</span>
            </div>
            <div class="leyend two">
              <div class="line"></div>
              <span>Gastos en Materias Primas</span>
            </div>
            <div class="customContainer">
              <label for="familia">CLIENTES</label>
              <selector [options]="opcionesClientes" [(ngModel)]="data.cliente" (change)="data.cliente = $event;">
              </selector>
            </div>
            <div class="customContainer">
              <label for="familia">PRODUCTOS</label>
              <selector [options]="opcionesProductos" [(ngModel)]="data.producto"></selector>
            </div>

            <div class="customContainer">
              <label for="fecha">FECHA INICIO</label>
              <datepicker (dataFromPicker)="data.fechaIni = $event" [clearable]="true"></datepicker>
            </div>
            <div class="customContainer">
              <label for="fecha">FECHA FIN</label>
              <datepicker (dataFromPicker)="data.fechaFin = $event" [clearable]="true"></datepicker>
            </div>
          </div>
        </ng-container>
        <ng-container  *ngIf="data.cliente != null && data.cliente.key == 1">
          <div class="grafica">
            <div>
              <barChart></barChart>
            </div>
          </div>
          <div class="filtros">
            <div class="customContainer">
              <label for="familia">CLIENTES</label>
              <selector [options]="opcionesClientes" [(ngModel)]="data.cliente" (change)="data.cliente = $event;">
              </selector>
            </div>
            <div class="customContainer">
              <label for="familia">FACTURACIÓN</label>
              <selector [options]="opcionesFacturacion" [(ngModel)]="data.facturacion"></selector>
            </div>

            <div class="customContainer">
              <label for="fecha">FECHA INICIO</label>
              <datepicker (dataFromPicker)="data.fechaIni = $event" [clearable]="true"></datepicker>
            </div>
            <div class="customContainer">
              <label for="fecha">FECHA FIN</label>
              <datepicker (dataFromPicker)="data.fechaFin = $event" [clearable]="true"></datepicker>
            </div>
          </div>
        </ng-container>
        <ng-container  *ngIf="data.cliente != null && data.cliente.key > 1">
          <div class="grafica">
            <div>
              <singleLineChart></singleLineChart>
            </div>
          </div>
          <div class="filtros">
            <div class="customContainer">
              <label for="familia">CLIENTES</label>
              <selector [options]="opcionesClientes" [(ngModel)]="data.cliente" (change)="data.cliente = $event;">
              </selector>
            </div>
            <div class="customContainer">
              <label for="familia">FACTURACIÓN</label>
              <selector [options]="opcionesFacturacion" [(ngModel)]="data.facturacion"></selector>
            </div>

            <div class="customContainer">
              <label for="fecha">FECHA INICIO</label>
              <datepicker (dataFromPicker)="data.fechaIni = $event" [clearable]="true"></datepicker>
            </div>
            <div class="customContainer">
              <label for="fecha">FECHA FIN</label>
              <datepicker (dataFromPicker)="data.fechaFin = $event" [clearable]="true"></datepicker>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</pill>
