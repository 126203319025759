import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  iDataTableColumns,
  iDataTableOptions,
  iOptionsSelector,
  iUnsubscribeDestroy,
} from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { ArticleService } from 'src/app/Services/Api/Article.service';
import { FormulaService } from 'src/app/Services/Api/Formula.service';
import { FormulaDetailService } from 'src/app/Services/Api/FormulaDetail.service';
import { RawMaterialService } from 'src/app/Services/Api/RawMaterial.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'nuevaFormulaStep2',
  templateUrl: './NuevaFormula-Step2.component.html',
  styleUrls: ['./NuevaFormula-Step2.component.scss'],
})
export class NuevaFormulaStep2Component
  extends iUnsubscribeDestroy
  implements OnInit, OnChanges
{
  @Input() nuevoMaterialFlag: boolean = false;
  @Input() nuevaExplicacionFlag: boolean = false;
  @Input() cuadrarFlag: boolean = false;
  @Input() step2Data: any = null;
  @Input() toEditData: any = null;
  @Input() modalData: any = null;

  @Output() isCompleted: EventEmitter<any> = new EventEmitter<any>();
  @Output() costData: EventEmitter<any> = new EventEmitter<any>();
  @Output() recall: EventEmitter<true> = new EventEmitter<true>();

  isEditing: boolean = false;
  observaciones: string = '';
  materialSelected: any = null;
  total: number = 0;
  totalKg: number = 0;
  totalL: number = 0;
  arrayLineasTabla: Array<any> = [
    {
      id: null,
      label: '',
      codigoMaterial: '',
      codigoSecundario: '',
      descripcion: '',
      cantidad: null,
      coste: 0,
      especial: false,
    },
  ];
  articles: any = [];
  opcionesArticulos: iOptionsSelector = {
    items: [],
    clearable: false,
    search: true,
    bindLabel: 'display',
    placeholder: 'Artículos',
    append: true,
  };
  updateObject: any = {
    id: 0,
  };

  constructor(
    private articleSE: ArticleService,
    private fromulaSE: FormulaService,
    private rawMaterialSE: RawMaterialService,
    private formulaDetailSE: FormulaDetailService
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['nuevoMaterialFlag'] != undefined) {
      if (
        changes['nuevoMaterialFlag'].currentValue != null &&
        changes['nuevoMaterialFlag'].previousValue != undefined
      ) {
        if (this.isEditing) {
          this.generateRow();
        } else {
          if (this.arrayLineasTabla.length > 0) {
            this.arrayLineasTabla.push({
              id: null,
              quantity: 0,
              sequence: this.arrayLineasTabla.length + 1,
              article: null,
              rawMaterial: null,
              formulaDetailId: null,
              label: null,
              codigoMaterial: null,
              codigoSecundario: null,
              coste: null,
              cantidad: 0,
              especial: false,
              name: null,
              readonly: true,
              densidad: null,
            });
          } else {
            this.arrayLineasTabla = [
              {
                id: null,
                quantity: 0,
                sequence: this.arrayLineasTabla.length + 1,
                article: null,
                rawMaterial: null,
                formulaDetailId: null,
                label: null,
                codigoMaterial: null,
                codigoSecundario: null,
                name: null,
                coste: null,
                cantidad: 0,
                especial: false,
                readonly: true,
                densidad: null,
              },
            ];
          }
        }
      }
    }
    if (changes['nuevaExplicacionFlag'] != undefined) {
      if (
        changes['nuevaExplicacionFlag'].currentValue != null &&
        changes['nuevaExplicacionFlag'].previousValue != undefined
      ) {
        if (this.isEditing) {
          this.generateRowExplicacion();
        } else {
          if (this.arrayLineasTabla.length > 0) {
            this.arrayLineasTabla.push({
              id: null,
              quantity: 0,
              sequence: this.arrayLineasTabla.length + 1,
              article: null,
              rawMaterial: null,
              formulaDetailId: null,
              name: null,
              label: null,
              codigoMaterial: null,
              codigoSecundario: null,
              coste: null,
              cantidad: 0,
              especial: true,
              readonly: true,
              densidad: null,
            });
          } else {
            this.arrayLineasTabla = [
              {
                id: null,
                quantity: 0,
                sequence: this.arrayLineasTabla.length + 1,
                name: null,
                article: null,
                rawMaterial: null,
                formulaDetailId: null,
                label: null,
                codigoMaterial: null,
                codigoSecundario: null,
                coste: null,
                cantidad: 0,
                especial: true,
                readonly: true,
                densidad: null,
              },
            ];
          }
        }
      }
    }
    if (changes['cuadrarFlag'] != undefined) {
      if (
        changes['cuadrarFlag'].currentValue != null &&
        changes['cuadrarFlag'].previousValue != undefined
      ) {
        this.cuadrarMuestra();
      }
    }
    if (changes['toEditData']) {
      if (changes['toEditData'].currentValue) {
        this.isEditing = true;
        this.setter(changes['toEditData'].currentValue);
      }
    }
  }

  ngOnInit(): void {
    if (this.step2Data != null) this.arrayLineasTabla = this.step2Data;
    this.updateTotal();
    this.getArticulosForSelector();
  }

  override ngOnDestroy(): void {
    if (this.toEditData) {
      this.editFormula();
    }
  }

  /**
   * GETTERS & SETTERS
   */

  getArticuloSelected(event, index) {
    if (event.type == 'article') {
      this.getArticleById(event.id, index);

      if (this.isEditing) {
        let formulaDetail = this.arrayLineasTabla[index];
        formulaDetail.id = event.id;
        delete formulaDetail.rawMaterial;
        formulaDetail.article = {};
        formulaDetail.article.id = event.id;
        this.editSectionFormulaDetail(formulaDetail);
      }

      return;
    }
    if (event.type === 'raw') {
      this.getRawMaterialById(event.id, index);

      if (this.isEditing) {
        let formulaDetail = this.arrayLineasTabla[index];
        formulaDetail.id = event.id;
        formulaDetail.rawMaterial = {};
        delete formulaDetail.article;
        formulaDetail.rawMaterial.id = event.id;
        this.editSectionFormulaDetail(formulaDetail);
      }

      return;
    }
  }

  setter(data) {
    // Se genera el array correcto y se ordena por sequence
    data.formulaDetails.sort((a, b) => a.sequence - b.sequence);
    this.arrayLineasTabla = data.formulaDetails
      .map((element, index) => {
        let especial: boolean = false;
        element.indication ? (especial = true) : (especial = false);
        if (especial) {
          return {
            explicacion: element.indication,
            especial: especial,
            formulaDetailId: element.id,
            sequence: element.sequence,
          };
        } else {
          if (element.article) {
            this.articles[index] = {
              id: element.article.id,
              code: element.article.code,
              type: 'article',
              display: `${element.article.code} - ${
                element.article.name ?? ''
              }`,
            };
            return {
              ...element,
              formulaDetailId: element.id,
              id: element.article.id,
              label: element.article.code,
              codigoMaterial: element.article.code,
              codigoSecundario: 'article',
              descripcion: element.article.description,
              coste: element.article.productionCost,
              cantidad: element.quantity,
              especial: especial,
              readonly: true,
              densidad: element.article.formula?.density,
            };
          } else if (element.rawMaterial) {
            this.articles[index] = {
              id: element?.rawMaterial?.id,
              code: element.rawMaterial.code,
              type: 'raw',
              display: `${element.rawMaterial.code} - ${
                element.rawMaterial.name ?? ''
              }`,
            };
            return {
              ...element,
              formulaDetailId: element.id,
              id: element?.rawMaterial?.id,
              label: element.rawMaterial.code,
              codigoMaterial: element.rawMaterial.code,
              codigoSecundario: element.rawMaterial.secondaryCode,
              descripcion: element?.rawMaterial?.description,
              coste: element.rawMaterial.lastPrice,
              cantidad: element.quantity,
              especial: especial,
              readonly: true,
              densidad: element.rawMaterial.density,
            };
          } else {
            this.articles[index] = {
              id: null,
              code: null,
              type: null,
              display: null,
            };
            return {
              ...element,
              formulaDetailId: element.id,
              id: null,
              label: null,
              codigoMaterial: null,
              codigoSecundario: null,
              descripcion: null,
              coste: null,
              cantidad: element.quantity,
              especial: especial,
              readonly: true,
              densidad: null,
            };
          }
        }
      })
      .sort((a, b) => a.sequence - b.sequence);
    this.updateObject.id = data.id;
    this.observaciones = data.finalObservation;
    this.updateTotal();
    this.checkIsCompleted();
  }

  /**
   * FUNCTIONALITIES
   */

  checkIsCompleted() {
    if (
      !this.arrayLineasTabla ||
      this.arrayLineasTabla.length == 0 ||
      !this.arrayLineasTabla[0]
    ) {
      return;
    }
    this.isCompleted.emit(this.arrayLineasTabla);
    this.costData.emit({
      totalQuantity: this.total,
      totalKg: this.totalKg,
      totalL: this.totalL,
      observation: this.observaciones,
    });
  }

  generateRow() {
    let objectToPass: any = {
      formula: this.toEditData?.id ?? null,
      sequence: this.arrayLineasTabla.length + 1,
      quantity: 0,
    };
    this.createFromulaDetail(objectToPass);
  }

  generateRowExplicacion() {
    let objectToPass: any = {
      formula: this.toEditData?.id ?? null,
      sequence: this.arrayLineasTabla.length + 1,
      quantity: null,
      indication: 'Indique aquí su explicación',
    };
    this.createFromulaDetail(objectToPass);
  }

  updateTotal() {
    this.total = 0;
    this.totalKg = 0;
    this.totalL = 0;

    this.arrayLineasTabla.map((element) => {
      if (!element?.special && element?.id != null) {
        this.total += Number(element.cantidad);
        this.totalKg += Number((element.cantidad * element.coste) / 1000);
        this.totalL += Number(
          (element.cantidad * element.coste) / Number(element.densidad) / 1000
        );
      }
    });
  }

  removeRow(linea) {
    this.arrayLineasTabla.splice(this.arrayLineasTabla.indexOf(linea), 1);
    if (this.isEditing) {
      this.deleteFormulaDetail([linea.formulaDetailId]);
    } else {
      this.regenerateArrayLineasTabla();
    }
    this.checkIsCompleted();
  }

  moveUp(linea) {
    if (this.arrayLineasTabla.indexOf(linea) > 0) {
      const currentIndex = this.arrayLineasTabla.indexOf(linea);
      this.moveElement(currentIndex, currentIndex - 1);
      if (this.isEditing) {
        const articleToMove = this.articles[currentIndex];
        this.articles.splice(currentIndex, 1);
        this.articles.splice(currentIndex - 1, 0, articleToMove);
        this.sendAllIndexToEdit();
      } else {
        this.regenerateArrayLineasTabla();
      }
      this.checkIsCompleted();
    }
  }

  moveDown(linea) {
    if (
      this.arrayLineasTabla.indexOf(linea) <
      this.arrayLineasTabla.length - 1
    ) {
      const currentIndex = this.arrayLineasTabla.indexOf(linea);
      this.moveElement(currentIndex, currentIndex + 1);
      if (this.isEditing) {
        const articleToMove = this.articles[currentIndex];
        this.articles.splice(currentIndex, 1);
        this.articles.splice(currentIndex + 1, 0, articleToMove);
        this.sendAllIndexToEdit();
      } else {
        this.regenerateArrayLineasTabla();
      }
    }
    this.checkIsCompleted();
  }

  moveElement(from, to) {
    this.arrayLineasTabla.splice(
      to,
      0,
      this.arrayLineasTabla.splice(from, 1)[0]
    );
  }

  regenerateArrayLineasTabla() {
    this.articles = [];
    this.arrayLineasTabla.map((element, index) => {
      if (element.article) {
        this.articles[index] = {
          id: element.article.id,
          code: element.label,
          type: 'article',
          display: `${element.label} - ${element.name ?? ''}`,
        };
      } else if (!element.article && !element.especial) {
        this.articles[index] = {
          id: element?.id,
          code: element.label,
          type: 'raw',
          display: `${element.label} - ${element.name ?? ''}`,
        };
      } else if (element.especial) {
        this.articles[index] = {
          id: null,
          code: null,
          type: null,
          display: null,
        };
      }
    });
  }

  cuadrarMuestra() {
    this.arrayLineasTabla.map((element) => {
      if (!element?.especial) {
        element.cantidad = (
          (Number(element.cantidad) * 1000) /
          this.total
        ).toFixed(3);
      }
    });
    this.updateTotal();
    this.checkIsCompleted();
    if (this.isEditing) this.updatEachLineQuantity();
  }

  updatEachLineQuantity() {
    this.arrayLineasTabla.map((element) => {
      if (!element?.especial) {
        this.editSectionFormulaDetail(element);
      }
    });
  }

  sendAllIndexToEdit() {
    this.arrayLineasTabla.map((element, index) => {
      if (element.formulaDetailId) {
        element.sequence = index + 1;
        this.editSectionFormulaDetail(element);
      }
    });
  }

  editSection(event, key) {
    this.updateObject[key] = event;
    this.editFormula();
  }

  editSectionFormulaDetail(event) {
    let updateObject: any = {
      id: event.formulaDetailId,
      quantity: event.cantidad,
      materialCode: event.codigoMaterial,
      secondaryCode: event.codigoSecundario,
      description: event.descripcion,
      indication: event.indication,
      sequence: event.sequence,
    };
    if (event.rawMaterial) {
      updateObject.rawMaterial = event.rawMaterial.id;
    }
    if (event.article) {
      updateObject.article = event.article.id;
    }
    if (event.explicacion) {
      updateObject.indication = event.explicacion;
    }

    this.editFormulaDetail(updateObject);
    this.checkIsCompleted();
  }

  // editSectionFormulaDetail(value, key,eventKey) {
  //   const objectToPass:any = {}
  //   objectToPass.id = value.formulaDetailId;
  //   objectToPass[key] = value[eventKey];
  //   this.editFormulaDetail(objectToPass);
  // }

  /**
   * API CALLS
   */

  getArticulosForSelector() {
    this.fromulaSE.getArticlesAndRawMaterials({
      num_devoluciones: 50000,
      num_pagina: 1,
    });
    this.fromulaSE
      .getResult()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        let { data } = res;
        data.map((article) => {
          let display: string = `${article.code} - ${article.name ?? ''}`;
          article.display = display;
        });
        this.opcionesArticulos.items = data;
        this._unsubInd.next('');
      });
  }

  getRawMaterialById(id, index) {
    this.rawMaterialSE.getSingleSupplier(id);
    this.rawMaterialSE
      .getResultSingleRawMaterial()
      .pipe(takeUntil(this._unsubInd9))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        let { data } = res;

        (this.arrayLineasTabla[index] = {
          ...this.arrayLineasTabla[index],
          id: data.id,
          label: data.code,
          codigoMaterial: data.code,
          codigoSecundario: data.secondaryCode,
          descripcion: data.description,
          cantidad: 0,
          densidad: data.density,
          name: data.name,
          coste: data.lastPrice,
          especial: false,
        }),
          this._unsubInd9.next('');
      });
  }
  getArticleById(id, index) {
    this.articleSE.getSingle(id);
    this.articleSE
      .getResultIndividual()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        let { data } = res;
        (this.arrayLineasTabla[index] = {
          ...this.arrayLineasTabla[index],
          article: {
            id: data.id,
          },
          id: data.id,
          label: data.code,
          codigoMaterial: data.code,
          codigoSecundario: 'articulo',
          descripcion: data.description,
          cantidad: 0,
          densidad: data.formula.density,
          coste: data.productionCost,
          name: data.name,
          especial: false,
        }),
          this._unsubInd.next('');
      });
  }

  /**
   * API CALLS
   */

  editFormula() {
    this.fromulaSE.update(this.updateObject);
    this.fromulaSE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        StaticUtilitiesService.showFeedback(
          'La fórmula se ha editado correctamente'
        );
        let { data } = res;
        this.setter(data);
        this._unsubInd2.next('');
      });
    this.fromulaSE
      .getResultUpdateError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        StaticUtilitiesService.showError(
          'Ha ocurrido un error al editar la fórmula'
        );
        this._unsubInd2.next('');
      });
  }

  editFormulaDetail(updateObject) {
    this.fromulaSE.updateFormulaDetail(updateObject);
    this.fromulaSE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd3))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        StaticUtilitiesService.showFeedback(
          'La fórmula se ha editado correctamente'
        );
        this._unsubInd3.next('');
      });
    this.fromulaSE
      .getResultUpdateError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        StaticUtilitiesService.showError(
          'Ha ocurrido un error al editar la fórmula'
        );
        this._unsub.next('');
      });
  }

  createFromulaDetail(objectToPass) {
    this.formulaDetailSE.create(objectToPass);
    this.formulaDetailSE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res) => {
        if (!res) return;
        StaticUtilitiesService.showFeedback(
          'Líne de fórmula creada correctamente'
        );
        this.recall.emit(true);
        this._unsubInd2.next('');
      });
    this.formulaDetailSE
      .getResultUpdateError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res) => {
        if (!res) return;
        StaticUtilitiesService.showError(
          'La línea de fórmula no pudo ser creada'
        );
        this._unsub.next('');
      });
  }

  deleteFormulaDetail(id: number[]) {
    this.formulaDetailSE.delete(id);
    this.formulaDetailSE
      .getResultDelete()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res) => {
        if (!res) return;
        StaticUtilitiesService.showFeedback(
          'Líne de fórmula eliminada correctamente'
        );
        this.recall.emit(true);
        this._unsubInd2.next('');
      });
    this.formulaDetailSE
      .getResultDeleteError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res) => {
        if (!res) return;
        StaticUtilitiesService.showError(
          'La línea de fórmula no pudo ser eliminada'
        );
        this._unsub.next('');
      });
  }
}
