import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { iUnsubscribeDestroy, PopupService } from '@quasar_dynamics/basic-designsystem';
import { NuevaDireccionDeEnvioAlternativaComponent } from 'src/app/Popups/NuevaDireccionDeEnvioAlternativa/NuevaDireccionDeEnvioAlternativa.component';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ERP-CLIENTE-Detail-DireccionesAlternativas',
  templateUrl: './ERP-CLIENTE-Detail-DireccionesAlternativas.component.html',
  styleUrls: ['./ERP-CLIENTE-Detail-DireccionesAlternativas.component.scss']
})
export class ERPCLIENTEDetailDireccionesAlternativasComponent extends iUnsubscribeDestroy implements OnInit {

  @Input() data:any = null 
  @Output() refresh = new EventEmitter<boolean>();


  direcciones:any = [
  ]

  constructor(private popup:PopupService) {
    super();
  }

  ngOnInit() {
  }


  ngOnChanges(changes:SimpleChanges){
    if(changes['data']){
      if(changes['data'].currentValue){
        setTimeout(() => {
      
          this.addButtonLastPill()
        }, 1);
      }
    }
  }

  edit(direccion){
    this.popup.openPopup(NuevaDireccionDeEnvioAlternativaComponent, { data: direccion, accion: 'editar' });
    this.popup.returnData().pipe(takeUntil(this._unsubInd)).subscribe(res => {
      if (!res) { return };
      this.refresh.emit(true);
      this._unsubInd.next('');
    })
  }
  
  create(){
    this.popup.openPopup(NuevaDireccionDeEnvioAlternativaComponent, { data:  this.data.id, accion: 'crear' });  
    this.popup.returnData().pipe(takeUntil(this._unsubInd)).subscribe(res => {
      if (!res) { return };
      this.refresh.emit(true);
      this._unsubInd.next('');
    })
  }

  addButtonLastPill(){
    let iconRow = document.querySelector('.fullAccordion :last-child .pillContainer .icon')! as HTMLElement;
    let addIcon = document.createElement('mat-icon');
    addIcon.innerHTML = 'add';
    addIcon.className = 'mat-icon notranslate outline material-icons mat-icon-no-color'
    addIcon.addEventListener('click', () => {
      this.create()
    })
    iconRow.appendChild(addIcon);
  }

}
