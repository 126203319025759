<FullContainer>
  <div class="proveedorDetallesGeneralContainer">
    <div class="header">
      <div (click)="returnBack()">
        <span class="material-icons-outlined">
          arrow_back
        </span>
      </div>
      <h1>PROVEEDORES / <span>{{proveedor}}</span></h1>
    </div>
    <accordion [title]="'FICHA'">
      <ng-container>
        <erpProveedoresDetallesFicha (refreshData)="refreshData($event)" [data]="dataProveedor" class="fullWidth">
        </erpProveedoresDetallesFicha>
      </ng-container>
    </accordion>
    <accordion [title]="'FICHA DE PRODUCTOS'">
      <ng-container>
        <erpProveedoresDetallesFichaProductos (refreshData)="refreshData($event)" class="fullWidth"
          [data]="dataProveedor"></erpProveedoresDetallesFichaProductos>
      </ng-container>
    </accordion>
    <ng-container *ngIf="false">
      <accordion [title]="'HISTÓRICO PEDIDOS'">
        <ng-container>
          <erpProveedoresDetallesHistoricoPedidos class="fullWidth" [data]="dataProveedor">
          </erpProveedoresDetallesHistoricoPedidos>
        </ng-container>
      </accordion>
      <accordion [title]="'CONTABILIDAD'">
        <ng-container>
          <erpProveedoresDetallesContabilidad class="fullWidth" [data]="dataProveedor">
          </erpProveedoresDetallesContabilidad>
        </ng-container>
      </accordion>
    </ng-container>

  </div>
</FullContainer>