import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import {
  iDataTableColumns,
  iDataTableOptions,
  iDate,
  iUnsubscribeDestroy,
  PopupService,
  slideNavigationTypes,
} from '@quasar_dynamics/basic-designsystem';
import { Subject, takeUntil } from 'rxjs';
import { ConfirmPopupComponent } from 'src/app/Popups/ConfirmPopup/ConfirmPopup.component';
import { RawMaterialService } from 'src/app/Services/Api/RawMaterial.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';
import { iRawMaterialFilter } from 'src/app/Shared/Interfaces/iRawMaterial';
import * as XLSX from 'xlsx';

@Component({
  selector: 'ERP-Stock-MateriasPrimas',
  templateUrl: './ERP-Stock-MateriasPrimas.component.html',
  styleUrls: ['./ERP-Stock-MateriasPrimas.component.scss'],
})
export class ERPStockMateriasPrimasComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  @Input() refreshRaw: boolean = false;
  @Input() filterRaw: any = {};
  isLoading: boolean = false;
  filter: any = {
    num_devoluciones: 20,
    num_pagina: 1,
  };
  totalPaginacion: number = 0;
  forceUpdateWidth = new Subject();

  //Tabla
  displayedColumnsTabla: Array<iDataTableColumns> = [
    { label: 'Código', property: 'code' },
    {
      label: 'Descripción/Proveedor',
      property: 'descProv',
      format: 'lineBreak',
    },
    { label: 'Stock Real', property: 'realStock', align: 'center' },
    { label: 'Stock Reservado', property: 'reservedStock', align: 'center' },
    { label: 'Stock Pte.Recibir', property: 'pendingStock', align: 'center' },
    { label: 'Stock Virtual', property: 'virtualStock', align: 'center' },
    { label: 'Stock Mínimo', property: 'minStock', align: 'center' },
    {
      label: 'Cantidad/fecha ult. compra',
      property: 'cantidadFecha',
      align: 'center',
      format: 'lineBreak',
    },
  ];

  dataForTable = [];

  optionsTabla: iDataTableOptions = {
    isSelectable: true,
    moreIconsHead: [
      {
        callback: (event) => {
          this.exportexcel();
        },
        icon: 'download',
      },
    ],
    canDelete: false,
    isHovered: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc',
  };

  constructor(
    private router: Router,
    private staticUtilitiesSE: StaticUtilitiesService,
    private popupSE: PopupService,
    private rawMaterialSE: RawMaterialService
  ) {
    super();
  }

  ngOnInit() {
    this.getAllRawMaterialsStock();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['refreshRaw']) {
      this.getAllRawMaterialsStock();
    }
    if (changes['filterRaw']) {
      if (changes['filterRaw'].currentValue) {
        delete this.filter.vs_negative;
        delete this.filter.under_min;
        delete this.filter.genericFilter;
        this.filter = {
          ...this?.filter,
          ...changes['filterRaw']?.currentValue,
        };
        this.getAllRawMaterialsStock();
      }
    }
  }

  /**
   * DESCARGA DE PARTES EN EXCEL
   */

  exportexcel(): void {
    let table = document.createElement('table');
    table.append(
      (
        document.querySelector('dataTable .tableHeader thead') as HTMLElement
      ).cloneNode(true)
    );
    table.querySelector('thead .iconsActions')?.remove();
    table.querySelector('thead .checkboxContainer')?.remove();
    let rows = Array.from(
      document.querySelectorAll('dataTable .tableBody tbody tr ')
    );
    let filteredRows = rows.filter((row) => {
      return (
        row
          .querySelector('.checkboxContainer mat-checkbox ')!
          .getAttribute('ng-reflect-checked') === 'true'
      );
    });
    if (filteredRows.length > 0) {
      filteredRows.forEach((row) => {
        let newRow = row.cloneNode(true) as HTMLElement;
        newRow.querySelector('td:last-of-type')?.remove();
        newRow.querySelector('td:first-of-type')?.remove();
        table.append(newRow);
      });
    } else {
      table.append(
        (
          document.querySelector('dataTable .tableBody tbody') as HTMLElement
        ).cloneNode(true)
      );
      table.querySelectorAll('tbody tr td:last-of-type').forEach((element) => {
        element.remove();
      });
      table.querySelectorAll('tbody tr td:first-of-type').forEach((element) => {
        element.remove();
      });
    }
    /* pass here the table id */
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, 'stockMateriasPrimas.xlsx');
  }

  /**
   * Delete
   */

  delete(event, key) {
    switch (key) {
      case 'simple':
        this.popupSE.openPopup(ConfirmPopupComponent, {
          accion: 'Eliminar',
          elemento: 'la materia prima',
        });
        this.popupSE
          .returnData()
          .pipe(takeUntil(this._unsubInd2))
          .subscribe((res) => {
            if (!res) {
              return;
            }
            this._unsubInd2.next('');
          });
        break;
      case 'all':
        this.popupSE.openPopup(ConfirmPopupComponent, {
          accion: 'Eliminar',
          elemento: 'todas las materias primas',
        });
        this.popupSE
          .returnData()
          .pipe(takeUntil(this._unsubInd2))
          .subscribe((res) => {
            if (!res) {
              return;
            }
            this._unsubInd2.next('');
          });

        break;
      case 'selected':
        this.popupSE.openPopup(ConfirmPopupComponent, {
          accion: 'Eliminar',
          elemento: 'las materias primas seleccionadas',
        });
        this.popupSE
          .returnData()
          .pipe(takeUntil(this._unsubInd2))
          .subscribe((res) => {
            if (!res) {
              return;
            }
            this._unsubInd2.next('');
          });
        break;
      default:
        break;
    }
  }

  /**
   * See cliente
   */

  see(event) {
    this.staticUtilitiesSE.goTo(
      '/erp/stock/materiasprimas',
      slideNavigationTypes.slideToTop,
      { materiaPrima: event.id }
    );
  }

  /**
   * PAGINATION
   */

  changePagination(event: any) {
    if (event === 'next') {
      this.filter.num_pagina++;
      this.getAllRawMaterialsStock();
    } else {
      this.filter.num_pagina--;
      this.getAllRawMaterialsStock();
    }
  }

  /**
   * API CALLS
   */

  getAllRawMaterialsStock() {
    this.isLoading = true;
    this.rawMaterialSE.getAllStock(this.filter);
    this.rawMaterialSE
      .getResult()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        let { data, total } = res.data;
        this.totalPaginacion = total;
        this.dataForTable = data.map((e, i) => {
          return {
            ...e,
            descProv: `${e.name} \n ${e.lastSupplier ?? '-'}`,
            cantidadFecha:
              e.dateLastPurchase && e.quantityProduct
                ? `${e.quantityProduct} \n ${iDate
                    .javascriptConvert(new Date(e.dateLastPurchase))
                    .toStringDate('EU')}`
                : '-',
          };
        });
        setTimeout(() => {
          this.forceUpdateWidth.next(true);
        }, 1);
        this.isLoading = false;
        this._unsubInd.next('');
      });
  }
}
