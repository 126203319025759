<div class="popupGeneralContainer">
  <div class="header">
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
    <p *ngIf="modalData.accion=='editar' || modalData.accion==null ">Editar materia prima</p>
    <p *ngIf="modalData.accion=='crear' ">Crear materia prima</p>
    <p *ngIf="modalData.accion=='ver' ">Ver materia prima</p>
    <mat-icon (click)="forceClosePopup() ">close</mat-icon>
  </div>
  <div class="body">
    <div class="datos"
      *ngIf=" ( modalData.accion == 'crear' || modalData.section == 'datos' || modalData.accion == 'ver' || modalData.accion == 'editar')">
      <p class="header">INFORMACIÓN GENERAL</p>
      <div class="doubleColumn">
        <CustomInput [readonly]="modalData.accion=='ver'" classList="fullWidth tall special" placeholder="Código"
          label="Código" [(ngModel)]="data.code"
          (change)="modalData.accion ==='editar'? editSecctionRawMaterial(data.code, 'code') :checkIsCompleted() ">
        </CustomInput>
        <CustomInput [readonly]="modalData.accion=='ver'" classList="fullWidth tall special"
          placeholder="Código secundario" label="Código secundario" [(ngModel)]="data.secondaryCode"
          (change)="modalData.accion ==='editar'? editSecctionRawMaterial(data.secondaryCode, 'secondaryCode') :checkIsCompleted() ">
        </CustomInput>
      </div>
      <CustomInput [readonly]="modalData.accion=='ver'" classList="fullWidth tall special" placeholder="Nombre"
        label="Nombre" [(ngModel)]="data.name"
        (change)="modalData.accion ==='editar'? editSecctionRawMaterial(data.name, 'name') :checkIsCompleted() ">
      </CustomInput>

      <div class="doubleColumn">
        <CustomInput [type]="'number'" [readonly]="modalData.accion=='ver'" classList="fullWidth tall special"
          placeholder="Densidad" label="Densidad" [(ngModel)]="data.density"
          (change)="modalData.accion ==='editar'? editSecctionRawMaterial(data.density, 'density') :checkIsCompleted() ">
        </CustomInput>
        <div class="customContainer">
          <label for="Provincia">Familia</label>
          <selector [options]="opcionesFamilia" [(ngModel)]="data.family"
            (change)="modalData.accion ==='editar'? editSecctionRawMaterial(data.family, 'family') :checkIsCompleted() ">
          </selector>
        </div>
      </div>
      <div class="doubleColumn">
        <div class="customContainer">
          <label for="Pais">Subfamilia</label>
          <selector [options]="opcionesSubfamilia" [(ngModel)]="data.subfamily"
            (change)="modalData.accion ==='editar'? editSecctionRawMaterial(data.subfamily, 'subfamily') :checkIsCompleted() ">
          </selector>
        </div>
        <CustomInput [type]="'number'" [readonly]="modalData.accion=='ver'" classList="fullWidth tall special"
          placeholder="Stock mínimo" label="Stock mínimo" [(ngModel)]="data.minStock"
          (change)="modalData.accion ==='editar'? editSecctionRawMaterial(data.minStock, 'minStock') :checkIsCompleted() ">
        </CustomInput>
      </div>
      <div class="doubleColumn">
        <CustomInput [type]="'number'" [readonly]="modalData.accion=='ver'" classList="fullWidth tall special"
          placeholder="Precio" label="Precio" [(ngModel)]="data.lastPrice"
          (change)="modalData.accion ==='editar'? editSecctionRawMaterial(data.lastPrice, 'lastPrice') :checkIsCompleted() ">
        </CustomInput>
        <div class="customContainer">
          <label for="Provincia">Unidad de medida</label>
          <selector [options]="opcionesUnidades" [(ngModel)]="data.measurementUnit"
            (change)="modalData.accion ==='editar'? editSecctionRawMaterial(data.measurementUnit, 'measurementUnit') :checkIsCompleted() ">
          </selector>
        </div>
      </div>
    </div>
    <div class="datos"
      *ngIf=" ( modalData.accion == 'crear' || modalData.section == 'caracteristicas' || modalData.accion == 'ver' || modalData.accion == 'editar')">
      <div class="header">
        <p>CARACTERÍSTICAS</p>
        <div (click)="addRow()">
          <ng-container>
            <mat-icon>
              add_circle
            </mat-icon>
          </ng-container>
        </div>
      </div>
      <div class="doubleColumn" *ngFor="let spec of specs; let index = index">
        <div class="customContainer">
          <label for="Provincia">Característica</label>
          <selector [options]="opcionesCaracteristicas" [(ngModel)]="spec.characteristic"
            (change)="modalData.accion ==='editar'? editSecctionRawMaterial(specs, 'characteristics') :checkIsCompleted() ">
          </selector>
        </div>
        <CustomInput [readonly]="modalData.accion=='ver'" classList="fullWidth tall special" placeholder="Valor"
          label="Valor" [(ngModel)]="spec.value"
          (change)="modalData.accion ==='editar'? editSecctionRawMaterial(specs, 'characteristics') :checkIsCompleted() ">
        </CustomInput>
        <div class="deleteButton" (click)="deleteRow(index)" *ngIf="specs.length >=2">
          <ng-container>
            <mat-icon>
              close
            </mat-icon>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="datos"
      *ngIf=" ( modalData.accion == 'crear' || modalData.section == 'observaciones' || modalData.accion == 'ver' || modalData.accion == 'editar')">
      <p class="header">INFORMACIÓN EXTRA</p>
      <CustomTextArea [readonly]="modalData.accion=='ver'" placeHolder="Observaciones" label="Observaciones "
        [(ngModel)]="data.description" name="observaciones"
        (change)="modalData.accion ==='editar'? editSecctionRawMaterial(data.description, 'description') :checkIsCompleted() ">
      </CustomTextArea>

    </div>
    <div class="footer" *ngIf="modalData.accion!='ver'">
      <btn classList="secundario" (click)="forceClosePopup()" *ngIf="modalData.accion =='crear' ">
        <ng-container>
          Cancelar
        </ng-container>
      </btn>
      <btn classList="secundaryButton" (click)="create()" [class.disabled]="!isCompleted"
        *ngIf="modalData.accion=='crear' ">
        <ng-container>
          Crear
        </ng-container>
      </btn>
      <btn classList="secundaryButton" (click)="closePopup()" [class.disabled]="!isCompleted"
        *ngIf="modalData.accion=='editar' || modalData.accion==null ">
        <ng-container>
          Editar
        </ng-container>
      </btn>
      <btn classList="secundaryButton" (click)="closePopup()"
        *ngIf="modalData.accion=='editar' || modalData.accion==null ">
        <ng-container>
          Cerrar
        </ng-container>
      </btn>
    </div>
  </div>
</div>
