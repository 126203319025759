<div class="step1">
  <div class="doubleColumn">
    <CustomInput classList="fullWidth tall special" placeholder="Código" label="Código" [(ngModel)]="data.codigo"
      (change)="modalData.accion === 'editar' ? setEdit(data.codigo, 'code') :checkIsCompleted()"></CustomInput>
    <CustomInput classList="fullWidth tall special" placeholder="Nombre" label="Nombre" [(ngModel)]="data.nombre"
      (change)="modalData.accion === 'editar' ? setEdit(data.nombre, 'name') :checkIsCompleted()"></CustomInput>
  </div>
  <div class="doubleColumn">
    <CustomInput classList="fullWidth tall special" [type]="'number'" placeholder="Densidad" label="Densidad"
      [(ngModel)]="data.densidad" (change)="modalData.accion === 'editar' ? setEdit(data.densidad, 'density') :checkIsCompleted()"></CustomInput>
    <div class="customContainer">
      <label for="familia">Familia:</label>
      <selector [options]="opcionesFamilia" [(ngModel)]="data.familia"
        (change)="modalData.accion === 'editar' ? setEdit(data.familia, 'family') :checkIsCompleted()"></selector>
    </div>
  </div>
  <div class="doubleColumn">
    <div class="customContainer">
      <label for="familia">Subfamilia: </label>
      <selector [options]="opcionesSubfamilia" [(ngModel)]="data.subFamilia"
        (change)="modalData.accion === 'editar' ? setEdit(data.subFamilia, 'subfamily') :checkIsCompleted()"></selector>
    </div>
    <div class="customContainer">
      <label for="familia">Tipo de producto: </label>

      <section class="checkbox">
        <mat-radio-group [(ngModel)]="data.tipo" (change)="modalData.accion === 'editar' ? setEdit(data.tipo, 'type') :checkIsCompleted()">
          <mat-radio-button value="polvo">Polvo</mat-radio-button>
          <mat-radio-button value="liquido">Líquido</mat-radio-button>
        </mat-radio-group>
      </section>
    </div>
  </div>


  <div class="doubleColumn">
    <div class="customContainer">
      <label for="familia">Unidad de medida:</label>
      <selector [options]="opcionesUnidades" [(ngModel)]="data.unidadMedida"
        (change)="modalData.accion === 'editar' ? setEdit(data.unidadMedida, 'measurementUnit') :checkIsCompleted()"></selector>
    </div>
    <div class="customContainer">
      <label for="familia">Fecha de fórmula (actualizable): </label>
      <datepicker [placeholder]="data.fechaFormula" (dataFromPicker)="modalData.accion === 'editar' ? setEdit(data.fechaFormula, 'dateFormula') :checkIsCompleted()">
      </datepicker>
    </div>
  </div>
  <div class="doubleColumn">
    <CustomInput classList="fullWidth tall special" placeholder="Objeto" label="Objeto" [(ngModel)]="data.objeto"
      (change)="modalData.accion === 'editar' ? setEdit(data.objeto, 'object') :checkIsCompleted()"></CustomInput>
  </div>
</div>