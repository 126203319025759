<div class="popupGeneralContainer">
  <div class="header">
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
    <p *ngIf="modalData.accion=='editar'">Editar portes</p>
    <p *ngIf="modalData.accion=='crear'">Crear portes</p>
    <p *ngIf="modalData.accion=='ver'">Ver portes</p>
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
  </div>
  <div class="body">
    <div class="doubleColumn">
      <CustomInput [readonly]="modalData.accion=='ver'" classList="fullWidth tall special" placeholder="Nombre"
        label="Nombre" [(ngModel)]="name" name="name"
        (change)="modalData.accion === 'editar' ? editSection(name, 'name') :checkIsCompleted()"></CustomInput>
      <CustomInput [readonly]="modalData.accion=='ver'" classList="fullWidth tall special" placeholder="Descripción"
        label="Descripción" [(ngModel)]="descripcion"
        (change)="modalData.accion === 'editar' ? editSection(descripcion, 'description') :checkIsCompleted()">
      </CustomInput>
    </div>

  </div>
  <div class="footer" *ngIf="modalData.accion!='ver'">
    <btn classList="secundario" (click)="forceClosePopup()">
      <ng-container>
        Cancelar
      </ng-container>
    </btn>
    <btn classList="secundaryButton" (click)="create()" [class.disabled]="!isCompleted"
      *ngIf="modalData.accion=='crear'">
      <ng-container>
        Crear
      </ng-container>
    </btn>
    <btn classList="secundaryButton" (click)="closePopup()" [class.disabled]="!isCompleted"
      *ngIf="modalData.accion=='editar'">
      <ng-container>
        Editar
      </ng-container>
    </btn>
  </div>
</div>