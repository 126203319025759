import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultBaseService, iResultHttp, iStaticUtilities } from '@quasar_dynamics/basic-designsystem';
import { iCharacteristic, iCharacteristicFilter } from 'src/app/Shared/Interfaces/iCharacteristic';

@Injectable({
  providedIn: 'root'
})
export class CharacteristicService extends DefaultBaseService {

  res: iResultHttp = new iResultHttp();

  constructor(private http: HttpClient) {
    super();
  }

  getAll(queryParams: iCharacteristicFilter){
    this.http.get(this.urlApi + '/api/characteristic' + this.optionsGet(queryParams), this.getHeader()).subscribe({
      next: (data:any) => {
        let normalizedCharacteristic = iCharacteristic.convertFromCollection( iStaticUtilities.normalizeNamesCollection(data.data) );
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: {data:normalizedCharacteristic, total:data.total}});
        this.sendNextResult('result', this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultError', this.res, { method: this.getAll, args: [queryParams] });
        this.checkStatusError(error);
      },
    });
  }

  getSingle(id: number){
    this.http.get(this.urlApi + '/api/characteristic/'+id, this.getHeader()).subscribe({
      next: (data:any) => {
        let normalizedCharacteristic = iCharacteristic.convertFromObject( iStaticUtilities.normalizeNames(data) );
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: normalizedCharacteristic});
        this.sendNextResult('resultIndividual', this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultIndividualError', this.res, { method: this.getSingle, args: [id] });
        this.checkStatusError(error);
      },
    });
  }

  create(characteristic:any){
    this.http.post(this.urlApi+"/api/characteristic", characteristic, this.getHeader()).subscribe({
      next: (data:any) => {
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: data["data"]});
        this.sendNextResult('resultUpdate', this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultUpdateError', this.res, { method: this.create, args: [characteristic] });
        this.checkStatusError(error);
      },
    });
  }

  update(characteristic:iCharacteristic){
    this.http.put(this.urlApi+"/api/characteristic/"+characteristic.id, characteristic, this.getHeader()).subscribe({
      next: (data:any) => {
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: data["data"]});
        this.sendNextResult('resultUpdate', this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultUpdateError', this.res, { method: this.update, args: [characteristic] });
        this.checkStatusError(error);
      },
    });
  }

  delete(ids: Array<number|string>){
    let idsObjs = this.convertArrayObject(ids, "ids");
    this.http.delete(this.urlApi+"/api/characteristic", this.sendBodyOptions(idsObjs) ).subscribe({
      next: (data:any) => {
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: data["data"]});
        this.sendNextResult('resultDelete', this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultDeleteError', this.res, { method: this.delete, args: [ids] });
        this.checkStatusError(error);
      },
    });
  }

}
