import { Component, OnInit } from '@angular/core';
import {
  PopupService,
  iUnsubscribeDestroy,
  slideNavigationTypes,
} from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { NuevaMateriaPrimaPopupComponent } from 'src/app/Popups/NuevaMateriaPrima-Popup/NuevaMateriaPrima-Popup.component';
import { NuevoStockMateriasPrimasPopupComponent } from 'src/app/Popups/NuevoStockMateriasPrimas-Popup/NuevoStockMateriasPrimas-Popup.component';
import { ArticleService } from 'src/app/Services/Api/Article.service';
import { RawMaterialService } from 'src/app/Services/Api/RawMaterial.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';
import { iArticleFilter } from 'src/app/Shared/Interfaces/iArticle';

@Component({
  selector: 'ERP-Stock-MateriasPrimas-DetailView',
  templateUrl: './ERP-Stock-MateriasPrimas-DetailView.component.html',
  styleUrls: ['./ERP-Stock-MateriasPrimas-DetailView.component.scss'],
})
export class ERPStockMateriasPrimasDetailViewComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  rawMaterialId: number = 0;
  dataClean: any = {
    code: 'Cargando...',
    dateLastPurchase: 'Cargando...',
    id: 1,
    lastSupplier: 'Cargando...',
    minStock: 'Cargando...',
    name: 'Cargando...',
    orderCount: 'Cargando...',
    pendingStock: 'Cargando...',
    quantityProduct: 'Cargando...',
    realStock: 'Cargando...',
    regularSupplier: 'Cargando...',
    reservedStock: 'Cargando...',
    virtualStock: 'Cargando...',
  };

  constructor(
    private staticUtilitiesSE: StaticUtilitiesService,
    private rawMaterialSE: RawMaterialService,
    private popupSE:PopupService
  ) {
    super();
  }

  ngOnInit() {
    this.rawMaterialId = this.staticUtilitiesSE.getParams().materiaPrima;
    this.getarticleStockId();
  }
  
  editRawMaterial(){
    this.popupSE.openPopup(NuevaMateriaPrimaPopupComponent, { accion: 'editar', data:this.dataClean });
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd3)).subscribe((data) => {
      this.getarticleStockId();
      this._unsubInd3.next("")
    })
  }

  goBack() {
    this.staticUtilitiesSE.goTo(
      'erp/stock/',
      slideNavigationTypes.slideToBottom
    );
  }

  getarticleStockId() {
    this.rawMaterialSE.getSingleStock(this.rawMaterialId);
    this.rawMaterialSE
      .getResultIndividual()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        let { data, total } = res.data;
        this.dataClean = data[0];
        this._unsubInd.next('');
      });
  }
}
