<div class="filterActionsContainer">
  <div class="buttons">
  <div class="add">
    <btn class="fitContent" *ngIf="checkBoolean(activeBtn)" classList="iconBtn plusBtn fitContent"
      (click)="sendAddNew()">
      <i class="material-icons">add</i>
      <span class="spanInt">añadir nueva</span>
    </btn>
  </div>
  <div class="add"  *ngIf="!isFilterDisabled">
    <btn [classList]="classFilter" class="fitContent" (click)="toggleFilter()">
      <img class="img-icon" src="/assets/Icons/FilterIcon.svg">
      <span>filtrar</span>
    </btn>
  </div>
  <div class="add buscador"  *ngIf="!isSeachDisabled">
    <btn [classList]="classSearch" class="fitContent" (click)="toggleSearch()">
      <i class="material-icons">search</i>
      <span>buscar</span>
    </btn>
    <div class="searchContainer" *ngIf="isSearchActive&&!isSeachDisabled">
      <input [placeholder]="inputOptions.placeholder ?? '' " (keyup)="updateInputVal()" [(ngModel)]="inputOptions.model"
        (keyup.enter)="sendInputVal()">
      <btn classList="onlyIcon removeBorderLeft" class="fitContent" (click)="sendInputVal()"><i
          class="material-icons">search</i></btn>
    </div>
  </div>
</div>
  <div class="filterContainer " *ngIf="isFilterActive&&!isFilterDisabled">
    <ng-content select="[filterContent]"></ng-content>
  </div>

</div>
