<FullContainer>
  <div class="mantenimientosGeneralContainer">
    <div class="header">
      <div (click)="returnBack()">
        <span class="material-icons-outlined">
          arrow_back
          </span>
      </div>
      <h1>MANTENIMIENTOS  / <span>{{nombreMantenimiento}}</span></h1>
    </div>
    <erpMantenimientosDetallesCaracteristicas *ngIf="idMantenimiento == 1"></erpMantenimientosDetallesCaracteristicas>
    <erpMantenimientosDetallesFamilias *ngIf="idMantenimiento == 2"></erpMantenimientosDetallesFamilias>
    <erpMantenimientosDetallesSubfamilias *ngIf="idMantenimiento == 3"></erpMantenimientosDetallesSubfamilias>
    <erpMantenimientoDetallesEnvases *ngIf="idMantenimiento == 4"></erpMantenimientoDetallesEnvases>
    <erpMantenimientoDetallesAgentes *ngIf="idMantenimiento == 5"></erpMantenimientoDetallesAgentes>
    <erpMantenimientoDetallesTipoIva *ngIf="idMantenimiento == 6"></erpMantenimientoDetallesTipoIva>
    <erpMantenimientosDetallesFormasPago *ngIf="idMantenimiento == 7"></erpMantenimientosDetallesFormasPago>
    <erpMantenimientoDetallesPortesPago *ngIf="idMantenimiento == 8"></erpMantenimientoDetallesPortesPago>
    <erpMantenimientoDetallesFungibles *ngIf="idMantenimiento == 9"></erpMantenimientoDetallesFungibles>
    <erpMantenimientosDetallesTipoIncidencia *ngIf="idMantenimiento == 10"></erpMantenimientosDetallesTipoIncidencia>
  </div>
</FullContainer>
