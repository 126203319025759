import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { iOptionsSelector, iUnsubscribeDestroy } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { OrderService } from 'src/app/Services/Api/Order.service';
import { PopupAlbaranService } from 'src/app/Services/Api/PopupAlbaran.service';

@Component({
  selector: 'NuevoAlbaranPopupSeccion2',
  templateUrl: './NuevoAlbaranPopupSeccion2.component.html',
  styleUrls: ['./NuevoAlbaranPopupSeccion2.component.scss']
})
export class NuevoAlbaranPopupSeccion2Component extends iUnsubscribeDestroy implements OnInit {
  @Input() cliente: any = null;
  @Input() dataSeccion2FromFather: any = null;
  @Input() valueIva: number = 0;
  @Input() displaySelector: boolean = true;
  @Output() dataSeccion2 = new EventEmitter<any>()
  data: any = {};
  baseImponible: number = 0;
  iva: number = 0;
  recargoEquivalencia: number = 0;
  total: number = 0;
  pedido: any = null;
  note: string = '';
  peso: number = 0;
  opcionesPedido: iOptionsSelector = {
    items: [],
    search: true,
    placeholder: 'Pedido',
    bindLabel: 'numPedido',
    append: true,
  }
  arrayLineas: any = []

  constructor(private orderSE: OrderService, private popupAlbaranSE: PopupAlbaranService) {
    super();
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['cliente']) {
      this.getPedidosCompany(changes['cliente'].currentValue.id);
    }
    if (changes['dataSeccion2FromFather']) {
      if (Object.keys(changes['dataSeccion2FromFather'].currentValue).length > 0) {
        this.arrayLineas = changes['dataSeccion2FromFather'].currentValue.arrayLineas
        this.baseImponible = changes['dataSeccion2FromFather'].currentValue.baseImponible
        this.iva = changes['dataSeccion2FromFather'].currentValue.iva
        this.recargoEquivalencia = changes['dataSeccion2FromFather'].currentValue.recargoEquivalencia
        this.total = changes['dataSeccion2FromFather'].currentValue.total
        this.note = changes['dataSeccion2FromFather'].currentValue.note
      }
    }
  }

  /**
   * 
   * 
   * GETTERS && SETTERS 
   */

  setDataSeccion2() {
    this.data = {
      baseImponible: this.baseImponible,
      iva: this.iva,
      recargoEquivalencia: this.recargoEquivalencia,
      total: this.total,
      pedido: this.pedido,
      note: this.note,
      peso: this.peso,
      arrayLineas: this.arrayLineas,
    }
    this.dataSeccion2.emit(this.data);
  }

  /**
   * 
   * FUNCTIONALITY
   *  
   */

  formatUnit(item): string {
    let unit = '';
     
    switch (item.article.measurementUnit.unit) {
      case 'Kilogramos':
        unit = 'kg';
        break;
      case 'Litros':
        unit = 'L';
        break;
      default:
        break;
    }
    return unit
  }

  getAccounting() {
    if (this.recargoEquivalencia < 0) this.recargoEquivalencia = 0;
    this.baseImponible = 0;
    this.arrayLineas.map((item: any) => {
      this.baseImponible += Number(item.subtotal);
    })
    this.iva = Number(this.baseImponible) * Number(this.valueIva);
    this.total = Number(this.baseImponible) + Number(this.iva) + Number(this.recargoEquivalencia);
    this.setDataSeccion2()
  }

  getPedido(event) {
     
    this.getPedidosById(event.numPedido);
  }

  deleteRow(index) {
    this.arrayLineas.splice(index, 1);
    this.getAccounting()
    this.getPeso()
    this.setDataSeccion2()
  }
  getImporte(event, index) {
     
    this.arrayLineas[index].subtotal = Number(event.quantity) * Number(event.price);
    this.getAccounting()
    this.getPeso()
    this.setDataSeccion2()
  }
  getPeso() {
    this.peso = 0;
    this.arrayLineas.map((item: any) => {
      if (item.unit === 'kg') {
        this.peso += Number(item.quantity);
      }
      if (item.unit === 'L') {
        this.peso += Number(item.quantity) * Number(item.density);
      }
    })
    this.setDataSeccion2()
    return this.peso;
  }
  /**
   * API CALLS
   */

  getPedidosCompany(id) {
    this.orderSE.getAllPedidosForAlbaranList(id);
    this.orderSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe((res: any) => {
      if (!res) { return };
      let { data } = res;
       
      this.opcionesPedido.items = data;
      this._unsubInd.next('');
    })
  }
  getPedidosById(id) {
    this.orderSE.getSingle(id);
    this.orderSE.getResultIndividual().pipe(takeUntil(this._unsubInd)).subscribe((res: any) => {
      if (!res) { return };
       
      let orderItems = res.data.orderItems.map((item: any, index) => {
         
        if (index === 0) {
          return { id: item.id, orderId: res.data.id, code: item.article.code, description: item.article.description, pending: item.pending, quantity: item.pending, price: item.price, subtotal: item.subtotal, unit: this.formatUnit(item), density: item.article.formula.density}
        } else { return { id: item.id, orderId: '', code: item.article.code, description: item.article.description, pending: item.pending, quantity: item.pending, price: item.price, subtotal: item.subtotal, unit: this.formatUnit(item), density: item.article.formula.density} }
      })
      this.arrayLineas = [...this.arrayLineas, ...orderItems];
      this.recargoEquivalencia = res.data.surcharge; 
      this.getAccounting()
      this.setDataSeccion2()
      this.getPeso()
      this._unsubInd.next('');
    })
  }
}
