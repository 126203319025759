import { Component, HostListener, Input, OnInit } from '@angular/core';
import { iDataTableColumns, iDataTableOptions, iOptionsSelector, iUnsubscribeDestroy, PopupService } from '@quasar_dynamics/basic-designsystem';
import { Subject, takeUntil } from 'rxjs';
import { EditarCondicionalesTarifasPopupComponent } from 'src/app/Popups/EditarCondicionalesTarifas-Popup/EditarCondicionalesTarifas-Popup.component';
import { EditarTarifasPopupComponent } from 'src/app/Popups/EditarTarifas-Popup/EditarTarifas-Popup.component';
import { ArticleService } from 'src/app/Services/Api/Article.service';
import { iArticleFilter } from 'src/app/Shared/Interfaces/iArticle';
import * as XLSX from 'xlsx';


@Component({
  selector: 'erpTarifasPolvo',
  templateUrl: './ERP-Tarifas-Polvo.component.html',
  styleUrls: ['./ERP-Tarifas-Polvo.component.scss']
})
export class ERPTarifasPolvoComponent extends iUnsubscribeDestroy implements OnInit {
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.resize();
  }
  @Input() maxFactor:number = 0 
  @Input() minAporte:number = 0;
  
  _reloadTableWidth = new Subject();
  isLoading: boolean = false;

  //Tabla
  displayedColumnsTabla: Array<iDataTableColumns> = [
    { label: 'Código', property: 'code' },
    { label: 'Producto', property: 'name' },
    { label: 'Coste', property: 'productionCost', align: "center", format: 'currency' },
    { label: 'Tarifa 1', property: 'rateOne', align: "center", format: 'currency' },
    { label: 'Tarifa 2', property: 'rateTwo', align: "center", format: 'currency' },
    { label: 'Aporte T2', property: 'aporte', align: "center", format: 'currency' },
    { label: 'Factor', property: 'factor', align: "center", format: 'percentaje' },
    { label: '24 meses', property: 'month', align: "center" },

  ];

  dataForTable: any = [
    { code: "0001", name: "Polvo de talco", productionCost: 0.5, rateOne: 0.5, rateTwo: 0.5, aporte: 0.5, factor: 0.5, meses: 0.5 },
  ]
  optionsTabla: iDataTableOptions = {
    isSelectable: true,
    isHovered: true,
    canEdit: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc',
    moreIconsHead: [{ callback: (event) => { this.exportexcel() }, icon: "download" }],
  }
  dataForTableFiltered: Array<any> = this.dataForTable;

  opcionesCliente: iOptionsSelector = {
    items: [],
    clearable: true,
    search: true,
    placeholder: "Todos",
    append: true,
    multiple: {
      isMultiple: true,
      checkbox: true,
      multipleLimitReplacer: 2,
      multipleTextReplacer: "Varios clientes seleccionados"
    },
  }
  clientes: string | null = null;

  opcionesProducto: iOptionsSelector = {
    items: [],
    clearable: true,
    search: true,
    placeholder: "Todos",
    append: true,
    multiple: {
      isMultiple: true,
      checkbox: true,
      multipleLimitReplacer: 2,
      multipleTextReplacer: "Varios productos seleccionados"
    },
  }
  producto: string | null = null;

  filter: iArticleFilter = {
    num_devoluciones: 20,
    num_pagina: 1,
    genericFilter: "",
  }

  promedioCoste: number = 0;
  promedioTarifa1: number = 0;
  promedioTarifa2: number = 0;
  aporte: number = 0;
  factor: number = 0;
  totalPaginacion: number = 0;
  constructor(private popupSE: PopupService, private articleSE: ArticleService) {
    super();
  }

  ngOnInit() {
    this.getRatesDust()
  }

  /**
  * GETTERS & SETTERS
  */



  /**
   * FUNCTIONALITIES
   */
  resize() {
    if (this.dataForTable.length != 0) {
      (document.querySelectorAll(".promedios > span")[0] as HTMLElement).style.width = ((document.querySelectorAll(".tableBodyContainer .tableBody tbody tr:first-of-type td")[0] as HTMLElement).clientWidth + (document.querySelectorAll(".tableBodyContainer .tableBody tbody tr:first-of-type td")[1] as HTMLElement).clientWidth + (document.querySelectorAll(".tableBodyContainer .tableBody tbody tr:first-of-type td")[2] as HTMLElement).clientWidth) + "px";
      (document.querySelectorAll(".promedios > span")[1] as HTMLElement).style.width = ((document.querySelectorAll(".tableBodyContainer .tableBody tbody tr:first-of-type td")[3] as HTMLElement).clientWidth) + "px";
      (document.querySelectorAll(".promedios > span")[2] as HTMLElement).style.width = ((document.querySelectorAll(".tableBodyContainer .tableBody tbody tr:first-of-type td")[4] as HTMLElement).clientWidth) + "px";
      (document.querySelectorAll(".promedios > span")[3] as HTMLElement).style.width = ((document.querySelectorAll(".tableBodyContainer .tableBody tbody tr:first-of-type td")[5] as HTMLElement).clientWidth) + "px";
      (document.querySelectorAll(".promedios > span")[4] as HTMLElement).style.width = ((document.querySelectorAll(".tableBodyContainer .tableBody tbody tr:first-of-type td")[6] as HTMLElement).clientWidth) + "px";
      (document.querySelectorAll(".promedios > span")[5] as HTMLElement).style.width = ((document.querySelectorAll(".tableBodyContainer .tableBody tbody tr:first-of-type td")[7] as HTMLElement).clientWidth) + "px";
    }
  }
  calculateData() {
    setTimeout(() => {
      this.promedioCoste = 0;
      this.promedioTarifa1 = 0;
      this.promedioTarifa2 = 0;
      this.aporte = 0
      this.factor = 0
      this.dataForTable.map((element, index) => {
        if (element.aporte < this.minAporte) {
          (document.querySelectorAll(".tableBodyContainer .tableBody tbody tr td[columntable='aporte']")[index] as HTMLElement).style.color = "#ED1A23";
        }
        if (element.factor > this.maxFactor) {
          (document.querySelectorAll(".tableBodyContainer .tableBody tbody tr td[columntable='factor']")[index] as HTMLElement).style.color = "#ED1A23";
        }
        this.promedioCoste += Number(element.productionCost);
        this.promedioTarifa1 += Number(element.rateOne);
        this.promedioTarifa2 += Number(element.rateTwo);
        this.aporte += Number(element.aporte);
        this.factor += Number(element.factor);
      })
      this.promedioCoste = Number(this.promedioCoste) / Number(this.dataForTable.length);
      this.promedioTarifa1 = Number(this.promedioTarifa1) / Number(this.dataForTable.length);
      this.promedioTarifa2 = Number(this.promedioTarifa2) / Number(this.dataForTable.length);
      this.aporte = Number(this.aporte) / Number(this.dataForTable.length);
      this.factor = Number(this.factor) / Number(this.dataForTable.length);

      this.resize();
    }, 1);
  }
  edit(event: any) {
    this.popupSE.openPopup(EditarTarifasPopupComponent, { data: event, accion: "editar" });
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe((data) => {
      if (!data) return;
      this.getRatesDust()
      this._unsubInd2.next("")
    })
  }

  condicionales(){
    this.popupSE.openPopup(EditarCondicionalesTarifasPopupComponent, );
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe((res)=>{
      if(!res) return;
      if(res['returnValue'].maxFactor){
        this.maxFactor = res['returnValue'].maxFactor;
      }
      if(res['returnValue'].minAporte){
        this.minAporte = res['returnValue'].minAporte;
      }
      this._unsubInd2.next("")
    })
  }

  changePagination(event: any) {
    if (event === 'next') {
      this.filter.num_pagina++;
      this.getRatesDust();
    } else {
      this.filter.num_pagina--;
      this.getRatesDust();
    }
  }
  search(event: any) {
    if (!event.target.value) { delete this.filter.genericFilter; this.getRatesDust(); return }
    this.filter.genericFilter = event.target.value;
    this.getRatesDust();
  }

  /**
* DESCARGA DE PARTES EN EXCEL
*/

exportexcel(): void {
  let table = document.createElement("table")
  table.append((document.querySelector("dataTable .tableHeader thead") as HTMLElement).cloneNode(true))
  table.querySelector("thead .iconsActions")?.remove();
  table.querySelector("thead .checkboxContainer")?.remove();
  let rows = Array.from(document.querySelectorAll("dataTable .tableBody tbody tr "));
  let filteredRows = rows.filter((row) => {
    return row.querySelector('.checkboxContainer mat-checkbox ')!.getAttribute('ng-reflect-checked') === "true"
  })
  if (filteredRows.length > 0) {
    filteredRows.forEach((row) => {
      let newRow = row.cloneNode(true) as HTMLElement;
      newRow.querySelector('td:last-of-type')?.remove();
      newRow.querySelector('td:first-of-type')?.remove();
      table.append(newRow)
    })
  } else {
    table.append((document.querySelector("dataTable .tableBody tbody") as HTMLElement).cloneNode(true))
    table.querySelectorAll("tbody tr td:last-of-type").forEach(element => {
      element.remove();
    })
    table.querySelectorAll("tbody tr td:first-of-type").forEach(element => {
      element.remove();
    })
  }
  /* pass here the table id */
  const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table);

  /* generate workbook and add the worksheet */
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  /* save to file */
  XLSX.writeFile(wb, 'tarifaPolvos.xlsx');

}



  /**
   * API CALL
   */

  getRatesDust() {
    this.isLoading = true;
    this.articleSE.getArticlesRatesGenericDust(this.filter);
    this.articleSE.getResultArticlesRates().pipe(takeUntil(this._unsubInd)).subscribe((res) => {
      if (!res) { return }
      let { data, total } = res.data;
      this.totalPaginacion = total
      this.dataForTable = data.map((element: any) => {return { ...element, factor : element.factor/100}});
      this.dataForTableFiltered = this.dataForTable;
      this.calculateData()
      setTimeout(() => {
        this._reloadTableWidth.next(true)
      }, 1);
      this.isLoading = false;
      this._unsubInd.next("")
    })
  }



}
