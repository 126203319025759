import { Component, OnInit } from '@angular/core';
import {
  iDataTableColumns,
  iDataTableOptions,
  iOptionsSelector,
  iUnsubscribeDestroy,
  PopupService,
  slideNavigationTypes,
} from '@quasar_dynamics/basic-designsystem';
import { Subject, takeUntil } from 'rxjs';
import { ConfirmPopupComponent } from 'src/app/Popups/ConfirmPopup/ConfirmPopup.component';
import { NuevaMateriaPrimaPopupComponent } from 'src/app/Popups/NuevaMateriaPrima-Popup/NuevaMateriaPrima-Popup.component';
import { FamilyService } from 'src/app/Services/Api/Family.service';
import { RawMaterialService } from 'src/app/Services/Api/RawMaterial.service';
import { SubfamilyService } from 'src/app/Services/Api/Subfamily.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';
import {
  iRawMaterial,
  iRawMaterialFilter,
} from 'src/app/Shared/Interfaces/iRawMaterial';
import * as XLSX from 'xlsx';

@Component({
  selector: 'erpProveedoresMateriasPrimas',
  templateUrl: './ERP-Proveedores-MateriasPrimas.component.html',
  styleUrls: ['./ERP-Proveedores-MateriasPrimas.component.scss'],
})
export class ERPProveedoresMateriasPrimasComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  _reloadTableWidth = new Subject();
  isLoading: boolean = false;
  opcionesFamilia: iOptionsSelector = {
    items: [],
    clearable: true,
    search: true,
    bindLabel: 'description',
    bindValue: 'id',
    placeholder: 'Familia',
    append: true,
    multiple: {
      isMultiple: true,
      checkbox: true,
      multipleLimitReplacer: 2,
      multipleTextReplacer: 'Varias familias seleccionadas',
    },
  };
  familias: string | null = null;
  totalPaginacion: number = 10;
  opcionesSubFamilia: iOptionsSelector = {
    items: [],
    clearable: true,
    search: true,
    bindLabel: 'description',
    placeholder: 'Subfamilia',
    bindValue: 'id',
    append: true,
    multiple: {
      isMultiple: true,
      checkbox: true,
      multipleLimitReplacer: 2,
      multipleTextReplacer: 'Varias subfamilias seleccionadas',
    },
  };
  subFamilias: string | null = null;

  //Tabla
  displayedColumnsTabla: Array<iDataTableColumns> = [
    { label: 'Código', property: 'code' },
    { label: 'Nombre', property: 'name' },
    { label: 'Familia', property: 'familia' },
    { label: 'Subfamilia', property: 'subfamilia' },
  ];

  dataForTable: any = [
    {
      code: '4234',
      name: '4B (SUST SJ 5122 DT)',
      familia: 'Metales',
      subfamilia: 'Pesados',
    },
    {
      code: '5435',
      name: 'LOMON R-996 ANTES DE COMPRAR',
      familia: 'Pintura',
      subfamilia: 'Líquida',
    },
    { code: '2643', name: 'SJ6300 ', familia: 'Pintura', subfamilia: 'Sólida' },
    {
      code: '7543',
      name: 'BARITA R2 - BARITA 5',
      familia: 'Metales',
      subfamilia: 'Pesados',
    },
    {
      code: '2413',
      name: 'PRIMIDXL552/MEGAMIDXL/T105',
      familia: 'Pintura',
      subfamilia: 'Líquida',
    },
    {
      code: '6543',
      name: 'BLANCO FIJO MIBER EXTRA',
      familia: 'Pintura',
      subfamilia: 'Líquida',
    },
    {
      code: '7643',
      name: 'MBKF 615-PUDRAFLOW M1355',
      familia: 'Aditivos',
      subfamilia: 'Endurecedor',
    },
    {
      code: '1436',
      name: 'EPOKUKDO KD292U/ CHS EPOXI',
      familia: 'Pintura',
      subfamilia: 'Sólida',
    },
    {
      code: '7635',
      name: 'POLIKYD AS-245X60 / AC1215',
      familia: 'Aditivos',
      subfamilia: 'Catalizador',
    },
    {
      code: '3265',
      name: 'NEGRO LLAMA101POLVO',
      familia: 'Pintura',
      subfamilia: 'Sólida',
    },
  ];

  optionsTabla: iDataTableOptions = {
    isSelectable: true,
    isHovered: true,
    canEdit: true,
    canDelete: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc',
    moreIconsHead: [
      {
        callback: (event) => {
          this.exportexcel();
        },
        icon: 'download',
      },
    ],
  };
  dataForTableFiltered: Array<any> = this.dataForTable;
  filter: iRawMaterialFilter = {
    num_devoluciones: 20,
    num_pagina: 1,
  };

  constructor(
    private popupSE: PopupService,
    private staticUtilities: StaticUtilitiesService,
    private rawMaterialSE: RawMaterialService,
    private familySE: FamilyService,
    private subFamilySE: SubfamilyService
  ) {
    super();
  }

  ngOnInit() {
    this.getAllRawMaterials();
    this.getFamiliesForFilter();
    this.getSubfamiliesForFilter();
  }

  /**
   * GETTERS & SETTERS
   */
  getSelector(event, key: string) {
    if (!event) {
      delete this.filter[key];
      this.getAllRawMaterials();
      return;
    }
    this.filter[key] = event;
    this.getAllRawMaterials();
  }
  search(event: any) {
    if (!event) {
      delete this.filter['genericFilter'];
      this.getAllRawMaterials();
      return;
    }
    this.filter['genericFilter'] = event;
    this.getAllRawMaterials();
  }

  /**
   * FUNCTIONALITIES
   */
  clickRow(event) {
    this.staticUtilities.goTo(
      '/erp/proveedores/detalles-materia-prima',
      slideNavigationTypes.slideToTop,
      { materiaPrima: event.id }
    );
  }
  create() {
    this.popupSE.openPopup(NuevaMateriaPrimaPopupComponent, {
      accion: 'crear',
    });
    this.popupSE
      .returnData()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((data) => {
        if (!data) return;
        this.getAllRawMaterials();
        this._unsubInd2.next('');
      });
  }
  edit(event: any) {
    this.popupSE.openPopup(NuevaMateriaPrimaPopupComponent, {
      accion: 'editar',
      data: event,
    });
    this.popupSE
      .returnData()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((data) => {
        if (!data) return;
        this.getAllRawMaterials();
        this._unsubInd2.next('');
      });
  }

  delete(event: any, type: String) {
    let ids: any[] = [];
    switch (type) {
      case 'single':
        ids = [event.id];
        this.popupSE.openPopup(ConfirmPopupComponent, {
          accion: 'Eliminar',
          elemento: 'la materia prima',
        });
        this.popupSE
          .returnData()
          .pipe(takeUntil(this._unsubInd2))
          .subscribe((data) => {
            if (!data) return;
            this.deleteRawMaterial(ids);
            this._unsubInd2.next('');
          });
        break;
      case 'selected':
        ids = event.map((item: any) => item.id);
        this.popupSE.openPopup(ConfirmPopupComponent, {
          accion: 'Eliminar',
          elemento: 'las materias primas seleccionadas',
        });
        this.popupSE
          .returnData()
          .pipe(takeUntil(this._unsubInd2))
          .subscribe((data) => {
            if (!data) return;
            this.deleteRawMaterial(ids);
            this._unsubInd2.next('');
          });
        break;
      case 'all':
        ids = this.dataForTable.map((item: any) => item.id);
        this.popupSE.openPopup(ConfirmPopupComponent, {
          accion: 'Eliminar',
          elemento: 'todas las materias primas',
        });
        this.popupSE
          .returnData()
          .pipe(takeUntil(this._unsubInd2))
          .subscribe((data) => {
            if (!data) return;
            this.deleteRawMaterial(ids);
            this._unsubInd2.next('');
          });
        break;
    }
  }
  changePagination(event: any) {
    if (event === 'next') {
      this.filter.num_pagina++;
      this.getAllRawMaterials();
    } else {
      this.filter.num_pagina--;
      this.getAllRawMaterials();
    }
  }
  /**
   * DESCARGA DE PARTES EN EXCEL
   */

  exportexcel(): void {
    let table = document.createElement('table');
    table.append(
      (
        document.querySelector('dataTable .tableHeader thead') as HTMLElement
      ).cloneNode(true)
    );
    table.querySelector('thead .iconsActions')?.remove();
    table.querySelector('thead .checkboxContainer')?.remove();
    let rows = Array.from(
      document.querySelectorAll('dataTable .tableBody tbody tr ')
    );
    let filteredRows = rows.filter((row) => {
      return (
        row
          .querySelector('.checkboxContainer mat-checkbox ')!
          .getAttribute('ng-reflect-checked') === 'true'
      );
    });
    if (filteredRows.length > 0) {
      filteredRows.forEach((row) => {
        let newRow = row.cloneNode(true) as HTMLElement;
        newRow.querySelector('td:last-of-type')?.remove();
        newRow.querySelector('td:first-of-type')?.remove();
        table.append(newRow);
      });
    } else {
      table.append(
        (
          document.querySelector('dataTable .tableBody tbody') as HTMLElement
        ).cloneNode(true)
      );
      table.querySelectorAll('tbody tr td:last-of-type').forEach((element) => {
        element.remove();
      });
      table.querySelectorAll('tbody tr td:first-of-type').forEach((element) => {
        element.remove();
      });
    }
    /* pass here the table id */
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, 'MateriasPrimas.xlsx');
  }

  /**
   * API CALLS
   */

  getAllRawMaterials() {
    this.isLoading = true;
    this.rawMaterialSE.getAll(this.filter);
    this.rawMaterialSE
      .getResult()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (!res) return;
        let { data, total } = res.data;
        this.totalPaginacion = total;
        this.dataForTable = data.map((item) => {
          return {
            ...item,
            familia: item?.family?.description,
            subfamilia: item?.subfamily?.description,
          };
        });
        this.dataForTableFiltered = this.dataForTable;
        setTimeout(() => {
          this._reloadTableWidth.next(true);
        }, 1);
        this.isLoading = false;
        this._unsubInd.next('');
      });
  }

  deleteRawMaterial(ids: number[]) {
    this.rawMaterialSE.delete(ids);
    this.rawMaterialSE
      .getResultDelete()
      .pipe(takeUntil(this._unsubInd3))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        StaticUtilitiesService.showFeedback(
          'Materia prima eliminada correctamente'
        );
        this.getAllRawMaterials();
        this._unsubInd3.next('');
      });
    this.rawMaterialSE
      .getResultDeleteError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        StaticUtilitiesService.showError(res.message);
        this.getAllRawMaterials();
        this._unsub.next('');
      });
  }

  getFamiliesForFilter() {
    this.familySE.getAll({ num_devoluciones: 1000, num_pagina: 1 });
    this.familySE
      .getResult()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        let { data, total } = res.data;
        this.opcionesFamilia.items = data;
        this._unsubInd2.next('');
      });
  }
  getSubfamiliesForFilter() {
    this.subFamilySE.getAll({ num_devoluciones: 1000, num_pagina: 1 });
    this.subFamilySE
      .getResult()
      .pipe(takeUntil(this._unsubInd3))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        let { data, total } = res.data;
        this.opcionesSubFamilia.items = data;
        this._unsubInd3.next('');
      });
  }
}
