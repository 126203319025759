import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ERP-PP-EspecificacionesDeProducto',
  templateUrl: './ERP-PP-EspecificacionesDeProducto.component.html',
  styleUrls: ['./ERP-PP-EspecificacionesDeProducto.component.scss']
})
export class ERPPPEspecificacionesDeProductoComponent implements OnInit {

  @Input() data:any = []


  dataForTable = [
    {id:1, descripcion: 'Densidad (ISO 2811)', valor: '0,9'},
    {id:1, descripcion: 'Humedad Trisecbutira', valor: 'Limpio'},
    {id:1, descripcion: 'Color / Aspecto', valor: 'Trans.S / Color'},
    {id:1, descripcion: 'Temperatura Suministrada', valor: 'Sí'},
  ]
  dataForTableFungible = [
    {id:1, descripcion: 'Filtro 5mm', valor: '0,9'},
    {id:1, descripcion: 'Filtro 1mm', valor: '0,9'},
  ]

  constructor() { }

  ngOnInit() {
    this.addLineHeaderAccordion();
  }

  addLineHeaderAccordion(){
    let header = (document.querySelector("body > app-root > main > erp-pp-formulas-dv > fullcontainer > div > div > div > div:nth-child(5) > accordion > div > div.accorHeader") as HTMLElement)
    header.style.position = "relative"
    let spanToAdd = document.createElement("span")
    spanToAdd.innerHTML = "FUNGIBLES"
    spanToAdd.style.position = "absolute"
    spanToAdd.style.left = "50%"
    header.appendChild(spanToAdd)
  }
}
