import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  iUnsubscribeDestroy,
  PopupService,
} from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { CrearProveedorPopupComponent } from 'src/app/Popups/CrearProveedor-Popup/CrearProveedor-Popup.component';
import { DocumentosPopupComponent } from 'src/app/Popups/Documentos-Popup/Documentos-Popup.component';
import { SubirDocumentoPopupComponent } from 'src/app/Popups/SubirDocumento-Popup/SubirDocumento-Popup.component';
import { CompanyService } from 'src/app/Services/Api/Company.service';
import { StaticDataHandlerService } from 'src/app/Services/Utils/StaticDataHandler.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'erpProveedoresDetallesFicha',
  templateUrl: './ERP-Proveedores-Detalles-Ficha.component.html',
  styleUrls: ['./ERP-Proveedores-Detalles-Ficha.component.scss'],
})
export class ERPProveedoresDetallesFichaComponent
  extends iUnsubscribeDestroy
  implements OnInit, AfterViewInit
{
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.resize();
  }

  @Input() data: any = [];
  @Output() refreshData = new EventEmitter<string>();

  code: string = 'cargando';
  name: string = 'cargando';
  nif: string = 'cargando';
  street: string = 'cargando';
  municipality: string = 'cargando';
  postalCode: string = 'cargando';
  province: string = 'cargando';
  country: string = 'cargando';
  telephone1: string = 'cargando';
  telephone2: string = 'cargando';
  telephone3: string = 'cargando';
  email1: string = 'cargando';
  email2: string = 'cargando';
  orderEmail: string = 'cargando';
  wayToPay: string = 'cargando';
  fixedDays: string = 'cargando';
  iban: string = 'cargando';
  bic: string = 'cargando';
  irpf: string = 'cargando';
  typeIva: string = 'cargando';
  porte: string = 'cargando';
  observation: string = 'cargando';
  documentos: Array<any> = [];
  documentosToPass: Array<any> = [];
  uppdateData: any = {
    id: '',
  };

  constructor(
    private popupSE: PopupService,
    private staticUtilitiesSE: StaticUtilitiesService,
    private companySE: CompanyService
  ) {
    super();
  }

  ngAfterViewInit(): void {
    // this.resize();
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data']) {
      if (Object.keys(changes['data'].currentValue).length > 0) {
        this.data = changes['data'].currentValue;
        this.setter();
      }
    }
  }

  /**
   * GETTERS && SETTERS
   */

  setter() {
    this.code = this.data.code;
    this.name = this.data.name;
    this.nif = this.data.nif;
    this.street = this.data.companyAddress[0].street
      ? this.data.companyAddress[0].street
      : '-';
    this.municipality =
      this.data.companyAddress[0].municipality != ''
        ? this.data.companyAddress[0].municipality
        : '-';
    this.postalCode = this.data.companyAddress[0].postalCode ?? '-';
    this.province = this.data.companyAddress[0].province ?? '-';
    this.country = this.data.companyAddress[0].country ?? '-';
    this.telephone1 = this.data?.phones[0];
    this.telephone2 = this.data?.phones[1];
    this.telephone3 = this.data?.phones[2];
    this.email1 = this.data?.emails[0];
    this.email2 = this.data?.emails[1];
    this.orderEmail = this.data.orderEmails[0];
    this.wayToPay = this.data.paymentMethods[0]?.wayToPay?.description ?? '-';
    this.fixedDays =
      this.data.fixedDays.length > 0 ? this.data.fixedDays.toString() : '-';
    this.iban =
      this.data.paymentMethods[0].iban != ''
        ? this.data.paymentMethods[0].iban
        : '-';
    this.bic =
      this.data.paymentMethods[0].bic != ''
        ? this.data.paymentMethods[0].bic
        : '-';
    this.irpf =
      this.data.paymentMethods[0].irpf == null
        ? '-'
        : this.data.paymentMethods[0].irpf
        ? 'Sí'
        : 'No';
    this.typeIva = this.data.paymentMethods[0]?.typeIva?.description ?? '-';
    this.porte = this.data.porte?.name ?? '-';
    this.observation = this.data.observation;
    this.uppdateData.id = this.data.id;
    this.documentos = this.data.attacheds.map((doc, i) => {
      return { doc, name: `Documento${i + 1}` };
    });
  }

  /**
   * FUNCTIONALITIES
   */

  resize() {
    setTimeout(() => {
      (
        document.querySelector('pill:nth-of-type(2)') as HTMLElement
      ).style.height =
        (document.querySelector('pill:first-of-type') as HTMLElement)
          .clientHeight +
        (document.querySelector('pill:first-of-type') as HTMLElement).style
          .padding +
        'px';
    }, 100);
  }

  verDocumentos(editar: boolean = false) {
    this.popupSE.openPopup(DocumentosPopupComponent, {
      editar: editar,
      documentos: this.documentos,
      proveedorId: this.data.id,
    });
    this.popupSE
      .returnData()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((data) => {
        this.refreshData.emit('refresh');
        this._unsubInd2.next('');
      });
  }

  edit(event, seccion) {
    this.popupSE.openPopup(CrearProveedorPopupComponent, {
      accion: 'editar',
      section: seccion,
      data: event,
    });
    this.popupSE
      .returnData()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((data) => {
        this.refreshData.emit('refresh');
        this._unsubInd2.next('');
      });
  }
  subirDocumento() {
    this.popupSE.openPopup(SubirDocumentoPopupComponent, {
      formatos: ['image/png', 'image/jpeg', 'application/pdf'],
      size: 10485760,
    });
    this.popupSE
      .returnData()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((data) => {
        if (!data) return;
        this.uppdateData.attacheds = data['returnValue'].map(
          (pdf) => pdf.base64
        );
        this.editDocumento();
        this._unsubInd2.next('');
      });
  }

  editDocumento() {
    this.companySE.update(this.uppdateData);
    this.companySE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd3))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        StaticUtilitiesService.showFeedback('Documento subido correctamente');
        this.refreshData.emit('refresh');
        this._unsubInd3.next('');
      });
    this.companySE
      .getResultUpdateError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        StaticUtilitiesService.showError(res.message);
        this.refreshData.emit('refresh');
        this._unsub.next('');
      });
  }
}
