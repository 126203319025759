<div class="popupGeneralContainer">
  <div class="header">
    <mat-icon (click)="forceClosePopup()">close</mat-icon>

    <p >Editar adjuntos</p>
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
  </div>
  <div class="body">

    <CustomInput  classList="fullWidth tall special" [type]="'number'"  placeholder="Máximo factor" label="Máximo factor" [(ngModel)]="maxFactor" ></CustomInput>
    <CustomInput  classList="fullWidth tall special" [type]="'number'"  placeholder="Mínimo aporte" label="Mínimo aporte" [(ngModel)]="minAporte" ></CustomInput>


  </div>
  <div class="footer" >
    <btn classList="secundaryButton" (click)="closePopup()">
      <ng-container>
        cancelar
      </ng-container>
    </btn>
    <btn classList="secundaryButton" (click)="closePopup({'maxFactor':maxFactor, 'minAporte':minAporte })">
      <ng-container>
        Finalizar
      </ng-container>
    </btn>
  </div>
</div>