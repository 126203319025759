import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { iUnsubscribeDestroy } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { WayToPayService } from 'src/app/Services/Api/WayToPay.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'app-NuevaFormaPago-Popup',
  templateUrl: './NuevaFormaPago-Popup.component.html',
  styleUrls: ['./NuevaFormaPago-Popup.component.scss']
})
export class NuevaFormaPagoPopupComponent extends iUnsubscribeDestroy implements OnInit {


  descripcion: string = "";
  code: string = "";
  dias: number | null = null;
  isCompleted: boolean = false;
  updateObject: any = {
    id:''
  }
  constructor(public dialogRef: MatDialogRef<NuevaFormaPagoPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any, private wayToPaySE:WayToPayService) {
    super();
  }

  ngOnInit() {
    if(this.modalData.accion !== 'crear'){
      this.setter()
    }
   }

   /**
    * GETTERS && SETTERS
    */

   setter(){
      this.descripcion = this.modalData.data.description
      this.code = this.modalData.data.code
      this.dias = this.modalData.data.days
      this.updateObject.id = this.modalData.data.id
   }
   editSecction(event, key){
    if(!event){return};
    this.updateObject[key] = event
    this.checkIsCompleted()
    this.edit()
   }

  /**
  * FUNCTIONALITY
  */
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }

  /**
   * VALIDATIONS
   */
  checkIsCompleted() {
    this.descripcion && this.code && this.dias ? this.isCompleted = true : this.isCompleted = false
  }

  /**
   * API CALLS
   */

  edit(){
    this.wayToPaySE.update(this.updateObject);
    this.wayToPaySE.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe((res)=>{
      if(!res){return}
      StaticUtilitiesService.showFeedback('Forma de pago actualizada')
      this._unsubInd.next('')
    })
    this.wayToPaySE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res)=>{
      if(!res){return}
      StaticUtilitiesService.showError('Error al actualizar la forma de pago')
      this._unsub.next('')
    })
  }
  create(){
    let data:any = {
      description: this.descripcion,
      code: this.code,
      days: this.dias
    }
    this.wayToPaySE.create(data);
    this.wayToPaySE.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe((res)=>{
      if(!res){return}
      StaticUtilitiesService.showFeedback('Forma de pago creada')
      this.closePopup('create')
      this._unsubInd.next('')
    })
    this.wayToPaySE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res)=>{
      if(!res){return}
      StaticUtilitiesService.showError(res.message)
      this.closePopup('create')
      this._unsub.next('')
    })
  }
}
