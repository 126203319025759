<div class="fullContainer">
  <pill>
    <div class="informatinBox">
      <div class="infoLeft">
        <div class="line">
          <p class="bold">Nº factura</p>
          <p>{{dataFromSection1.id}}</p>
        </div>
        <div class="line">
          <p class="bold">Fecha factura</p>
          <p>{{dataFromSection1.date | date:'dd/MM/yyyy'}}</p>
        </div>
        <div class="line">
          <p class="bold">Vencimiento</p>
          <p>{{dataFromSection1.paymentDate | date:'dd/MM/yyyy'}}</p>
        </div>
        <div class="line">
          <p class="bold">Nº albarán</p>
          <p>{{dataFromSection1.numAlbaran}}</p>
        </div>
      </div>
      <div class="infoCliente">
        <p class="bold">Cliente</p>
        <br>
        <p class="bold">{{dataAlbaran.ordered[0]?.company?.name}}</p>
        <p> {{dataAlbaran?.address?.street}},{{dataAlbaran?.address?.number}}, {{dataAlbaran?.address?.postalCode}}</p>
        <p> {{dataAlbaran?.address?.municipality}},{{dataAlbaran?.address?.province}}, {{dataAlbaran?.address?.country}} </p>
        <br>
        <p>Tél: {{dataAlbaran?.address?.phones[0]}}</p>
        <p>NIF: {{dataAlbaran?.ordered[0]?.company?.nif}}</p>
      </div>
      <div class="infoCliente">
        <p class="bold">Condiciones de pago</p>
        <br>
        <p>{{dataAlbaran.ordered[0]?.company?.payment_methods[0]?.way_to_pay?.description}} - {{dataAlbaran.ordered[0]?.company?.payment_methods[0]?.way_to_pay?.days}}</p>
        <p class="bold"> Domiciliación Bancaria </p>
        <p> {{dataAlbaran.ordered[0]?.company?.payment_methods[0]?.banco}}</p>
        <p> {{dataAlbaran.ordered[0]?.company?.payment_methods[0]?.iban}}</p>
      </div>
    </div>
  </pill>
  <pill>
    <div class="bodyTab">
      <div class="headerTab">
        <p class="bold">Pedido</p>
        <p class="bold">Referencia</p>
        <p class="bold">Descripción</p>
        <p class="bold">Cantidad</p>
        <p class="bold">Precio</p>
        <p class="bold">Importe</p>
      </div>
      <div class="contentTab" >
        <div class="line" *ngFor="let line of dataAlbaran?.delivery_note_details">
          <p class="bold">{{line.order_item.ordered.id}}</p>
          <p>{{line.order_item.article.code}}</p>
          <p>{{line.order_item.article.description}}</p>
          <p>{{line.amount_sent}}</p>
          <div class="inline">
            <p>{{line.order_item.price}}</p>
            <p>€</p>
          </div>

          <p>{{line.subtotal}}</p>
        </div>
      </div>
    </div>
  </pill>
  <div class="accounting">
    <div class="line">
      <p class="bold">Base imponible :</p>
      <p>{{dataAlbaran.base}}</p>
    </div>
    <div class="line">
      <p class="bold">IVA :</p>
      <p>{{dataAlbaran.iva}}</p>
    </div>
    <div class="line">
      <p class="bold">Recargo de equivalencia *:</p>
      <p>{{dataAlbaran.surcharge}}</p>
    </div>
    <div class="line">
      <p class="bold">TOTAL : </p>
      <p>{{dataAlbaran.total}}</p>
    </div>
  </div>
  <div class="pesoAndNotes">
    <div class="line">
      <p class="bold">Peso Neto :</p>
      <p>{{dataAlbaran.net_weight}} kg</p>
    </div>
    <div class="line">
      <p class="bold nowrap">Notas :</p>
      <p>{{dataFromSection1.note}} </p>
    </div>
  </div>
</div>