import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { iUnsubscribeDestroy } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { FormulaService } from 'src/app/Services/Api/Formula.service';

@Component({
  selector: 'NuevoPartePopup-PlanificacionEnvasado',
  templateUrl: './NuevoPartePopup-PlanificacionEnvasado.component.html',
  styleUrls: ['./NuevoPartePopup-PlanificacionEnvasado.component.scss'],
})
export class NuevoPartePopupPlanificacionEnvasadoComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  @Input('data') data: any = null;
  @Input('dataFicha') dataFicha: any = null;
  @Output() create = new EventEmitter<boolean>();
  @Output() dataPlanificacion = new EventEmitter();

  dataForTable: any = [];
  restoProducto: number = 0;
  formulaID: number = 0;
  densidadProd: number = 0;

  option1: any = [];

  constructor(private formulaSE: FormulaService) {
    super();
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data']) {
      if (changes['data'].currentValue.planificacionData) {
        this.restoProducto = Number(
          changes['data'].currentValue.planificacionData[0].product
        );
        this.densidadProd = Number(changes['data'].currentValue.densidad);
        this.dataForTable = changes['data'].currentValue.planificacionData.map(
          (event: any) => {
            return {
              ...event,
              cantidadL: Number(event.occLiters),
              ocupacionKg: Number(event.occKg),
              cantidadEnvasado: Number(event.quantity),
              cantidadUnidad: Number(event.product),
            };
          }
        );
        this.emitDataPlanificacion();
      }
      if (!changes['data'].currentValue.producto) {
        return;
      }
      if (changes['data'].currentValue.producto.id) {
        this.getFormulaId(changes['data'].currentValue.producto.id);
      }
    }
    if (changes['dataFicha']) {
      if (changes['dataFicha'].currentValue) {
        this.restoProducto = Number(changes['dataFicha'].currentValue.cantidad);
      }
    }
  }

  changeEnvasado(event, row) {
    const eventValue = Number(event.target.value);
    const isOccupationKg = Boolean(Number(row.ocupacionKg));
    const isOccLiters = Boolean(Number(row.occLiters));

    if (isOccupationKg) {
      this.updateRowValuesForKg(row, eventValue);
    }

    if (isOccLiters) {
      this.updateRowValuesForLiters(row, eventValue);
    }

    this.checkLinesEnvasado();
  }

  updateRowValuesForKg(row, eventValue) {
    const newCantidadUnidad = eventValue * Number(row.occKg);
    row.cantidadEnvasado = eventValue;
    row.cantidadUnidad = Math.trunc(newCantidadUnidad);
  }

  updateRowValuesForLiters(row, eventValue) {
    const newCantidadUnidad = eventValue * Number(row.occLiters);
    row.cantidadEnvasado = eventValue;
    row.cantidadUnidad = Math.trunc(newCantidadUnidad);
  }

  checkLinesEnvasado() {
    this.dataForTable[0].cantidadUnidad = Number(this.data.cantidad);

    this.dataForTable.forEach((element, index) => {
      if (index === 0) {
        return;
      }

      this.updateCantidadUnidadAndEnvasado(element, index);
    });

    this.emitDataPlanificacion();
  }

  updateCantidadUnidadAndEnvasado(element, index) {
    if (element.typeProduct.id === 1) {
      this.updateForTypeProduct1(element);
    }

    if (element.typeProduct.id === 2) {
      this.updateForTypeProduct2(element);
    }
  }

  updateForTypeProduct1(element) {
    this.dataForTable[0].cantidadUnidad = Math.trunc(
      Number(this.dataForTable[0].cantidadUnidad) -
        Number(element.cantidadEnvasado) * Number(element.occKg)
    );
    this.dataForTable[0].cantidadEnvasado =
      this.dataForTable[0].cantidadUnidad / Number(this.dataForTable[0].occKg);
  }

  updateForTypeProduct2(element) {
    this.dataForTable[0].cantidadUnidad = Math.trunc(
      Number(this.dataForTable[0].cantidadUnidad) -
        Number(element.cantidadEnvasado) *
          Number(this.densidadProd) *
          Number(element.occLiters)
    );
    this.dataForTable[0].cantidadEnvasado =
      this.dataForTable[0].cantidadUnidad / Number(this.dataForTable[0].occKg);
  }

  emitDataPlanificacion() {
    if (this.dataForTable[0].cantidadUnidad >= 0) {
      this.create.emit(true);
      let objectEmmiter = {
        containers: this.dataForTable,
        formula: this.formulaID,
      };
      this.dataPlanificacion.emit(objectEmmiter);
    } else {
      this.create.emit(false);
      this.dataPlanificacion.emit(null);
    }
  }

  llenar(event) {
    this.resetDataForTable();

    if (event.typeProduct.id === 1) {
      this.handleTypeProduct1(event);
    }

    if (event.typeProduct.id === 2) {
      this.handleTypeProduct2(event);
    }
  }

  resetDataForTable() {
    this.dataForTable.forEach((element, index) => {
      if (index === 0) {
        element.cantidadEnvasado = this.restoProducto / 0.01;
        element.cantidadUnidad = this.restoProducto;
        return;
      }
      element.cantidadEnvasado = 0;
      element.cantidadUnidad = 0;
    });
  }

  handleTypeProduct1(event) {
    event.cantidadEnvasado =
      this.dataForTable[0].cantidadUnidad / event.ocupacionKg;

    if (event.cantidadEnvasado !== Math.floor(event.cantidadEnvasado)) {
      this.dataForTable[0].cantidadUnidad =
        event.cantidadEnvasado - Math.floor(event.cantidadEnvasado);
      event.cantidadEnvasado = Math.floor(event.cantidadEnvasado);
      event.cantidadUnidad = event.cantidadEnvasado * event.ocupacionKg;
      this.checkLinesEnvasado();
      return;
    }

    this.dataForTable[0].cantidadUnidad =
      this.dataForTable[0].cantidadUnidad % event.ocupacionKg;
    event.cantidadUnidad = event.cantidadEnvasado * event.ocupacionKg;
    this.checkLinesEnvasado();
  }

  handleTypeProduct2(event) {
    event.cantidadEnvasado =
      this.dataForTable[0].cantidadUnidad / event.occLiters / this.densidadProd;

    if (event.cantidadEnvasado !== Math.floor(event.cantidadEnvasado)) {
      this.dataForTable[0].cantidadUnidad =
        Number(event.cantidadEnvasado) -
        Math.floor(Number(event.cantidadEnvasado));
      event.cantidadEnvasado = Math.floor(event.cantidadEnvasado);
      event.cantidadUnidad =
        event.cantidadEnvasado * event.occLiters * this.densidadProd;
      this.checkLinesEnvasado();
      return;
    }

    this.dataForTable[0].cantidadUnidad =
      this.dataForTable[0].cantidadUnidad %
      (event.occLiters / this.densidadProd);
    event.cantidadUnidad =
      event.cantidadEnvasado * event.occLiters * this.densidadProd;
    this.checkLinesEnvasado();
  }

  /**
   * API CALLS
   */

  getFormulaId(id) {
    this.formulaSE.getSingle(id);
    this.formulaSE
      .getResultIndividual()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        let { data } = res;
        this.dataForTable = data.container.map((element, index) => {
          if (index === 0) {
            return {
              ...element,
              cantidadL: element?.occLiters,
              ocupacionKg: element?.occKg,
              cantidadEnvasado: this.restoProducto / 0.01,
              cantidadUnidad: this.restoProducto,
            };
          }
          return {
            ...element,
            cantidadL: element?.occLiters,
            ocupacionKg: element?.occKg,
            cantidadEnvasado: 0,
            cantidadUnidad: 0,
          };
        });
        this.formulaID = data.id;

        this.densidadProd = data.density;
        this.emitDataPlanificacion();
        this._unsubInd2.next('');
      });
  }
}
