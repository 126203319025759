<FullContainer>
  <div class="proveedorDetallesGeneralContainer">
    <div class="header">
      <div (click)="returnBack()">
        <span class="material-icons-outlined">
          arrow_back
        </span>
      </div>
      <h1>MATERIAS PRIMAS / <span>{{rawMaterialName}}</span></h1>
    </div>
    <accordion [title]="'FICHA'">
      <ng-container>
        <erpMateriasPrimasDetallesFicha class="fullWidth" (rawMaterialName)="getRawMaterialName($event)">
        </erpMateriasPrimasDetallesFicha>
      </ng-container>
    </accordion>
    <accordion [title]="'CARACTERÍSTICAS'">
      <ng-container>
        <erpMateriasPrimasDetallesCaracteristicas></erpMateriasPrimasDetallesCaracteristicas>
      </ng-container>
    </accordion>
    <accordion [title]="'HISTÓRICO'" *ngIf="false">
      <ng-container>
        <erpMateriasPrimasDetallesHistorico class="fullWidth"> </erpMateriasPrimasDetallesHistorico>
      </ng-container>
    </accordion>
  </div>
</FullContainer>