import { Component, OnInit } from '@angular/core';
import { iDataTableColumns, iDataTableOptions, iUnsubscribeDestroy, PopupService } from '@quasar_dynamics/basic-designsystem';
import { Subject, takeUntil } from 'rxjs';
import { NuevaMateriaPrimaPopupComponent } from 'src/app/Popups/NuevaMateriaPrima-Popup/NuevaMateriaPrima-Popup.component';
import { RawMaterialService } from 'src/app/Services/Api/RawMaterial.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'erpMateriasPrimasDetallesCaracteristicas',
  templateUrl: './ERP-MateriasPrimas-Detalles-Caracteristicas.component.html',
  styleUrls: ['./ERP-MateriasPrimas-Detalles-Caracteristicas.component.scss']
})
export class ERPMateriasPrimasDetallesCaracteristicasComponent extends iUnsubscribeDestroy implements OnInit {
  isLoading: boolean = false;
  _reloadTableWidth = new Subject()
  //Tabla
  displayedColumnsTabla: Array<iDataTableColumns> = [
    { label: 'Descripción', property: 'descripcion' },
    { label: 'Valor', property: 'value' },
  ];

  rawMaterialId:number = 0;
  data:any = []

  dataForTable:any = [
  ]

  optionsTabla: iDataTableOptions = {
    isHovered: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc',
    moreIconsHead: [{
     icon: "edit",
     callback: () => { this.edit() }
    }]
  }
  constructor(private popupSE: PopupService, private rawMaterialSE: RawMaterialService, private staticUtitlitiesSE: StaticUtilitiesService ) {
    super();
  }

  ngOnInit() {
    this.rawMaterialId = this.staticUtitlitiesSE.getParams().materiaPrima
    this.getRawMaterialId();

  }
  edit(){
    this.popupSE.openPopup(NuevaMateriaPrimaPopupComponent, { accion: 'editar', data: {id: this.rawMaterialId} });
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd)).subscribe((data) => {
      this.getRawMaterialId();
      this._unsubInd.next("")
    })
  }


    /**
   * API CALLS
   */

    getRawMaterialId(){
      this.rawMaterialSE.getSingleSupplier(this.rawMaterialId);
      this.rawMaterialSE.getResultSingleRawMaterial().pipe(takeUntil(this._unsubInd)).subscribe((res) => {
        if(!res){return};
        let {data} = res;
        this.dataForTable = data.characteristicManies.map((item) => {return{...item, descripcion:item.characteristic.description}});
        this._unsubInd.next('')
      })
    }
}
