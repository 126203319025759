<div class="popupGeneralContainer">
  <div class="infoSteps">

    <p class="tituloStep2" *ngIf="selectorMap == 1"> PEDIDO: {{data.nPedido}}</p>

    <div class="line">
      <span class="number left" [ngClass]="{active: selectorMap === 0}">1</span>
      <span class="number medium" [ngClass]="{active: selectorMap === 1}">2</span>
      <span class="number right" [ngClass]="{active: selectorMap === 2}">3</span>
    </div>

    <mat-icon (click)="forceClosePopup()">close</mat-icon>
  </div>
  <div class="header">
    <p *ngIf="selectorMap == 0">Crear Pedido</p>
  </div>

  <!-- SECTION 1 -->

  <div class="body" *ngIf="selectorMap === 0">
    <CustomInput [readonly]="true" classList="fullWidth tall special" placeholder="Número de pedido"
      label="Número de pedido" [(ngModel)]="data.nPedido" (change)="checkFirstSection()"></CustomInput>
    <div class="customContainer">
      <label for="Pais">Fecha pedido</label>
      <datepicker (dataFromPicker)="dataFromPicker($event)"></datepicker>
    </div>
    <div class="customContainer">
      <label for="Pais">Proveedor</label>
      <selector [options]="opcionesProveedor" [(ngModel)]="data.proveedor" (change)="proveedorSelector($event); checkFirstSection()"></selector>
    </div>
    <br>
    <CustomInput [readonly]="true" classList="fullWidth tall special" placeholder="Email pedidos" label="Email pedidos"
      [(ngModel)]="proveedorSelected.emailPedidos" (change)="checkFirstSection()" *ngIf="isClienteSelected"></CustomInput>
    <CustomInput [readonly]="true" classList="fullWidth tall special" placeholder="Forma de pago" label="Forma de pago"
      [(ngModel)]="proveedorSelected.formaDePago" (change)="checkFirstSection()" *ngIf="isClienteSelected"></CustomInput>
    <CustomInput [readonly]="true" classList="fullWidth tall special" placeholder="Fecha de pago" label="Fecha de pago"
      [(ngModel)]="proveedorSelected.fechaDePago" (change)="checkFirstSection()" *ngIf="isClienteSelected"></CustomInput>
    <CustomInput [readonly]="true" classList="fullWidth tall special" placeholder="Tipo portes" label="Tipo portes"
      [(ngModel)]="proveedorSelected.tipoPortes" (change)="checkFirstSection()" *ngIf="isClienteSelected"></CustomInput>
    <CustomInput [readonly]="true" classList="fullWidth tall special" placeholder="Tipo de IVA" label="Tipo de IVA"
      [(ngModel)]="proveedorSelected.tipoIva" (change)="checkFirstSection()" *ngIf="isClienteSelected"></CustomInput>
  </div>

  <div class="tablaPedido" *ngIf="selectorMap == 1">

    <filterActions [disableFilter]="true" [disableSearch]="true" (addNew)="newLine()"></filterActions>

    <!-- SECTION 2 -->

    <pill class="tablePil" classList="tablePill">
      <div class="headerTable">
        <p class="headerTableP">Artículo</p>
        <!-- <p class="headerTableP">Descripción</p> -->
        <p class="headerTableP">Cantidad</p>
        <p class="headerTableP">Precio</p>
        <p class="headerTableP">Subtotal</p>
      </div>
      <div class="bodyTable" *ngFor="let linea of arrayLineasTabla; let index = index">
        <div class="contenedor">
          <selector [options]="opcionesArticulos" [(ngModel)]="linea.articleName" (change)="getArticulo($event,index)"></selector>
        </div>
        <!-- <div class="contenedor">
          <CustomInput classList="fullWidth tall special" placeholder="Descripcion"
            [(ngModel)]="linea.description" (change)="checkSecondSection()"></CustomInput>
        </div> -->
        <div class="contenedor">
          <CustomInput [type]="'number'" classList="fullWidth tall special" placeholder="Cantidad"
          [(ngModel)]="linea.quantity" (change)="sumSubtotal(index)" ></CustomInput>
        </div>
        <p>{{linea.price}} €</p>
        <div class="contenedor">
          <div class="customMasEur" *ngIf="!linea.articleName">
            <CustomInput [type]="'number'" [readonly]="linea.articleName" classList="fullWidth tall special" placeholder="Cantidad"
            [(ngModel)]="linea.subtotal" (change)="getBaseImponible()" ></CustomInput>
            <p>€</p>
          </div>
          <p *ngIf="linea.articleName">{{linea.subtotal.toFixed(2)  | currency: 'EUR'}}</p>
        </div>
        <span class="closeIcon" *ngIf="arrayLineasTabla.length > 1">
          <mat-icon (click)="deleteLine(index)">close</mat-icon>
        </span>
      </div>
      <div class="calculo">
        <div class="notas">
          <CustomTextArea placeHolder="Notas" label="Notas" [(ngModel)]="notas" name="notas" class="height20vh" (change)="noteChange($event)">
          </CustomTextArea>
        </div>
        <div class="tablaCalculo">
          <div class="row">
            <div class="col">
              <p class="header">Base imponible</p>
            </div>
            <div class="col value">
              <p>{{baseImponible.toFixed(2)  | currency: 'EUR'}} </p>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <p class="header">IVA ({{proveedorSelected.tipoIva}}%)</p>
            </div>
            <div class="col value">
              <p>{{plusIva.toFixed(2) | currency: 'EUR'}} </p>
            </div>
          </div>
          <div class="row" *ngIf="irpfCompany">
            <div class="col">
              <p class="header" >IRPF</p>
            </div>
            <div class="col value">
              <CustomInput [type]="'number'" classList="fullWidth tall special"
              [(ngModel)]="irpf" (change)="getBaseImponible()" ></CustomInput>    
              <!-- <p>{{irpf.toFixed(2) | currency: 'EUR'}}</p> -->
              </div>
          </div>
          <div class="row total">
            <div class="col">
              <p class="header">TOTAL</p>
            </div>
            <div class="col value">
              <p>{{total.toFixed(2) | currency: 'EUR'}} </p>
            </div>
          </div>
        </div>
      </div>
    </pill>
  </div>


  <!-- SECTION 3 -->

  <div class="tablaPedido" *ngIf="selectorMap == 2">

    <div class="headerSection">
      <div class="firstPill">
        <pill class="minHeightPills">
          <ng-container>
            <div class="grid">

              <p><span class="bold">Número pedido : </span> {{data.nPedido}}</p>
              <p><span class="bold">Fecha pedido : </span> {{data.fechaPedido}}</p>
              <p><span class="bold">Proveedor : </span> {{data.proveedor}}</p>
              <p><span class="bold">Email pedidos : </span> {{proveedorSelected.emailPedidos}}</p>
              <p><span class="bold">Forma de pago : </span> {{proveedorSelected.formaDePago}}</p>
              <p><span class="bold">Fecha de pago : </span> {{proveedorSelected.fechaDePago}}</p>
              <p><span class="bold">Tipo portes : </span> {{proveedorSelected.tipoPortes}}</p>

            </div>
          </ng-container>
        </pill>
      </div>
    </div>
    <pill class="tablePil" classList="tablePill">
      <dataTable [data]="arrayLineasTabla" [isLoading]="isLoading" [options]="optionsTabla" [displayedColumns]="displayedColumnsTabla" ></dataTable>
      <div class="calculo">
        <div class="notas">
          <label>Notas</label>
          <p>{{notas}}</p>
        </div>
        <div class="tablaCalculo">
          <div class="row">
            <div class="col">
              <p class="header">Base imponible</p>
            </div>
            <div class="col value">
              <p>{{baseImponible.toFixed(2)  | currency: 'EUR'}} </p>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <p class="header">IVA ({{proveedorSelected.tipoIva}}%)</p>
            </div>
            <div class="col value">
              <p>{{plusIva.toFixed(2) | currency: 'EUR'}} </p>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <p class="header">IRPF: </p>
            </div>
            <div class="col value">
              <p>{{irpf}} €</p>
              </div>
          </div>
          <div class="row total">
            <div class="col">
              <p class="header">TOTAL</p>
            </div>
            <div class="col value">
              <p class="bold">{{total.toFixed(2) | currency: 'EUR'}} </p>
            </div>
          </div>
        </div>
      </div>
    </pill>
  </div>

  <!-- FOOTER -->

  <div class="footer" *ngIf="modalData.accion!='ver'">
    <btn classList="secundario" (click)="forceClosePopup()" *ngIf="selectorMap === 0">
      <ng-container>
        Cancelar
      </ng-container>
    </btn>
    <btn classList="secundario" (click)="previousSection()" *ngIf="selectorMap !== 0">
      <ng-container>
        Previo
      </ng-container>
    </btn>
    <btn classList="secundaryButton" (click)="nextSection()"
      [class.disabled]="(selectorMap ===  0 && !isFirstSectionCompleted) || (selectorMap === 1 && !isSecondSectionCompleted)"
      *ngIf="selectorMap !== 2">
      <ng-container *ngIf="modalData.accion=='crear'">
        Siguiente
      </ng-container>
    </btn>
    <btn classList="secundaryButton" (click)="create()" [class.disabled]="!isCompleted && selectorMap !== 2" *ngIf="selectorMap == 2">
      <ng-container *ngIf="modalData.accion=='crear'">
        Enviar email
      </ng-container>
    </btn>
  </div>
</div>
