import { iBaseObject, iDate } from "@quasar_dynamics/basic-designsystem";
import { iRawMaterial } from "./iRawMaterial";
import { iCompany } from "./iCompany";

export interface iRawMaterialCompany {
    id: number,
    price?: number,
    offerDate?: iDate,
    rawMaterial?: iRawMaterial,
    company?: iCompany,
}

export class iRawMaterialCompany extends iBaseObject {
    static convertFromCollection(ob: any): Array<iRawMaterialCompany> {
        let iRawMaterialCompanyCollection: Array<iRawMaterialCompany> = [];
        ob.forEach((element: any) => {
            iRawMaterialCompanyCollection.push(this.convertFromObject(element));
        });
        return iRawMaterialCompanyCollection;
    }

    static convertFromObject(ob: any): iRawMaterialCompany {
        let iRawMaterialCompanyObj = new iRawMaterialCompany();
        iRawMaterialCompanyObj.id = ob.id;
        iRawMaterialCompanyObj.price = ob.price;
        iRawMaterialCompanyObj.offerDate = iDate.phpConvert(ob.offerDate);
        iRawMaterialCompanyObj.rawMaterial = ob.rawMaterial ? iRawMaterial.convertFromObject(ob.rawMaterial) : iRawMaterial.createEmptyObject();
        iRawMaterialCompanyObj.company = ob.company ? iCompany.convertFromObject(ob.company) : iCompany.createEmptyObject();
        return iRawMaterialCompanyObj;
    }

    static createEmptyObject(): iRawMaterialCompany {
        let iRawMaterialCompanyObj = new iRawMaterialCompany();
        iRawMaterialCompanyObj.price = -1;
        iRawMaterialCompanyObj.offerDate = iDate.javascriptConvert(new Date());
        iRawMaterialCompanyObj.rawMaterial = iRawMaterial.createEmptyObject();
        iRawMaterialCompanyObj.company = iCompany.createEmptyObject();
        return iRawMaterialCompanyObj;
    }
}

export interface iRawMaterialCompanyFilter {
    num_devoluciones: number;
    num_pagina: number;
    genericFilter?: string;
    price?: number,
    offerDate?: string,
    rawMaterial?: number,
    company?: number,
}