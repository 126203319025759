import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { iOptionsSelector, iUnsubscribeDestroy } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { RawMaterialService } from 'src/app/Services/Api/RawMaterial.service';
import { RawMaterialCompanyService } from 'src/app/Services/Api/RawMaterialCompany.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'app-AsignarProductoProveedor-Popup',
  templateUrl: './AsignarProductoProveedor-Popup.component.html',
  styleUrls: ['./AsignarProductoProveedor-Popup.component.scss']
})
export class AsignarProductoProveedorPopupComponent extends iUnsubscribeDestroy implements OnInit {

  isCompleted: boolean = false;
  opcionesProducto: iOptionsSelector = {
    items: [],
    clearable: true,
    search: true,
    placeholder: "Producto",
    bindLabel: "name",
    bindValue: "id",
    append: true,
  }

  data: any = {
    rawMaterial: null,
    price: null
  }

  constructor(public dialogRef: MatDialogRef<AsignarProductoProveedorPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any, private rawMaterialCompany: RawMaterialCompanyService, private rawMaterial: RawMaterialService) {
    super();
  }

  ngOnInit() {
    this.data.company = this.modalData.proveedorId;
    this.getAllRawMaterials()
  }

  /**
  * FUNCTIONALITY
  */
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }
  checkIsCompleted(){
    this.isCompleted = StaticUtilitiesService.isCompleteObject(this.data)
  }
  getFromDatepicker(event,key){
    this.data[key] = event;
    this.checkIsCompleted()
  }
  /**
   * API CALLS
   */

  create(){
    this.rawMaterialCompany.create(this.data);
    this.rawMaterialCompany.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe((res) => {
      if(!res){return}
      StaticUtilitiesService.showFeedback('Producto asignado correctamente');
      this.closePopup('create');
      this._unsubInd.next('')
    })
    this.rawMaterialCompany.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res) => {
      if(!res){return}
      StaticUtilitiesService.showError('El producto no pudo ser asignado');
      this._unsub.next('')
    })
  }

  getAllRawMaterials(){
    this.rawMaterial.getAll({num_devoluciones: 1000, num_pagina: 1});
    this.rawMaterial.getResult().pipe(takeUntil(this._unsubInd)).subscribe((res) => {
      if(!res){return}
      let {data, total} = res.data;
      this.opcionesProducto.items = data;
      this._unsubInd.next('')
    })

  }
}
