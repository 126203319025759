<div class="fullAccordion">

  <div class="pill">
    <div class="iconLine">
      <span class="icon">
        <ng-container>
          <mat-icon>
            edit
          </mat-icon>
        </ng-container>
      </span>
    </div>
    <div class="row">
      <p class="bold">Código</p>
      <p class="bold">Descripción</p>
      <p class="bold align-text-center">Cantidad L</p>
      <p class="bold align-text-center">Ocupacion Kg</p>
    </div>
    <div class="row" *ngFor="let data of data?.container">
      <p>{{data.code}}</p>
      <p>{{data.description}}</p>
      <p class="align-text-center">{{data.occLiters}}</p>
      <p class="align-text-center">{{data.occKg}}</p>
    </div>
  </div>
</div>