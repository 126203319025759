import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { iDataTableColumns, iDataTableOptions, iUnsubscribeDestroy } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { ProductionReportService } from 'src/app/Services/Api/ProductionReport.service';
import * as XLSX from 'xlsx';


@Component({
  selector: 'ERP-PP-PP-DV-EspecificacionesDeLote',
  templateUrl: './ERP-PP-PP-DV-EspecificacionesDeLote.component.html',
  styleUrls: ['./ERP-PP-PP-DV-EspecificacionesDeLote.component.scss']
})
export class ERPPPPPDVEspecificacionesDeLoteComponent extends iUnsubscribeDestroy implements OnInit {

  @Input() parteId!: number;


  dataForTable: any = []
  isLoading: boolean = false;
  optionsTabla: iDataTableOptions = {
    isHovered: true,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc',
    moreIconsHead: [{ callback: (event) => { this.exportexcel() }, icon: "download" }],
  }
  displayedColumnsTabla: Array<iDataTableColumns> = [
    { label: 'Descripcion', property: 'descripcion' },
    { label: 'Valor', property: 'value', align: 'center' },
  ];


  constructor(private productionReportSE: ProductionReportService) {
    super();
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['parteId']) {
      if (changes['parteId'].currentValue) {
        this.getBatchProductionReport()
      }
    }
  }

  /**
* DESCARGA DE PARTES EN EXCEL
*/

  exportexcel(): void {
    let table = document.createElement("table")
    table.append((document.querySelector("dataTable .tableHeader thead") as HTMLElement).cloneNode(true))
    table.querySelector("thead .iconsActions")?.remove();
    table.querySelector("thead .checkboxContainer")?.remove();

    table.append((document.querySelector("dataTable .tableBody tbody") as HTMLElement).cloneNode(true))
    table.querySelectorAll("tbody tr td:last-of-type").forEach(element => {
      element.remove();
    })
    table.querySelectorAll("tbody tr td:first-of-type").forEach(element => {
      element.remove();
    })

    /* pass here the table id */
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, 'Especificaciones de lote.xlsx');

  }



  /**
   * API CALLS
   */

  getBatchProductionReport() {
    this.productionReportSE.getBatchCharacteristicsId(this.parteId);
    this.productionReportSE.getResultBatchCharacteristicsId().pipe(takeUntil(this._unsubInd4)).subscribe(res => {
      if (!res) { return }
      let { data } = res;
      this.dataForTable = data.map((item: any) => { return { ...item, descripcion: item.characteristic.description } });
      this._unsubInd4.next('');
    })
  }

}
