import { iBaseObject, iDate } from "@quasar_dynamics/basic-designsystem";
import { iArticle } from "./iArticle";
import { iFormula } from "./iFormula";
import { iProductionArea } from "./iProductionArea";
import { iRawMaterial } from "./iRawMaterial";
import { iSpecification } from "./iSpecification";

export interface iProductionReport {
    id: number,
    code?: string,
    date?: iDate,
    amount?: number,
    deliveryDate?: iDate,
    isClosed?: boolean,
    realAmount?: number,
    waste?: number,
    article?: iArticle,
    formula?: iFormula,
    productionArea?: iProductionArea,
    rawMaterials?: iRawMaterial[],
    specification?: iSpecification,
}

export class iProductionReport extends iBaseObject {
    static convertFromCollection(ob: any): Array<iProductionReport> {
        let iProductionReportCollection: Array<iProductionReport> = [];
        ob.forEach((element: any) => {
            iProductionReportCollection.push(this.convertFromObject(element));
        });
        return iProductionReportCollection;
    }

    static convertFromObject(ob: any): iProductionReport {
        let iProductionReportObj = new iProductionReport();
        iProductionReportObj.id = ob.id;
        iProductionReportObj.code = ob.code;
        iProductionReportObj.date = iDate.phpConvert(ob.date);
        iProductionReportObj.amount = ob.amount;
        iProductionReportObj.deliveryDate = iDate.phpConvert(ob.deliveryDate);
        iProductionReportObj.isClosed = ob.isClosed;
        iProductionReportObj.realAmount = ob.realAmount;
        iProductionReportObj.waste = ob.waste;
        iProductionReportObj.article = ob.article ? iArticle.convertFromObject(ob.article) : iArticle.createEmptyObject();
        iProductionReportObj.formula = ob.formula ? iFormula.convertFromObject(ob.formula) : iFormula.createEmptyObject();
        iProductionReportObj.productionArea = ob.productionArea ? iProductionArea.convertFromObject(ob.productionArea) : iProductionArea.createEmptyObject();
        iProductionReportObj.rawMaterials = ob.rawMaterials ? iRawMaterial.convertFromCollection(ob.rawMaterials) : [];
        iProductionReportObj.specification = ob.specification ? iSpecification.convertFromObject(ob.specification) : iSpecification.createEmptyObject();
        return iProductionReportObj;
    }

    static createEmptyObject(): iProductionReport {
        let iProductionReportObj = new iProductionReport();
        iProductionReportObj.code = '';
        iProductionReportObj.date = iDate.javascriptConvert(new Date());
        iProductionReportObj.amount = -1;
        iProductionReportObj.deliveryDate = iDate.javascriptConvert(new Date());
        iProductionReportObj.isClosed = false;
        iProductionReportObj.realAmount = -1;
        iProductionReportObj.waste = -1;
        iProductionReportObj.article = iArticle.createEmptyObject();
        iProductionReportObj.formula = iFormula.createEmptyObject();
        iProductionReportObj.productionArea = iProductionArea.createEmptyObject();
        iProductionReportObj.rawMaterials = [];
        iProductionReportObj.specification = iSpecification.createEmptyObject();
        return iProductionReportObj;
    }
}

export interface iProductionReportFilter {
    num_devoluciones: number;
    num_pagina: number;
    genericFilter?: string;
    code?: string,
    date?: string,
    amount?: number,
    deliveryDate?: string,
    isClosed?: boolean,
    realAmount?: number,
    waste?: number,
    article?: number,
    formula?: number,
    productionArea?: number,
    rawMaterials?: number,
    specification?: number,
}