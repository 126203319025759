import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DefaultBaseService,
  iResultHttp,
  iStaticUtilities,
} from '@quasar_dynamics/basic-designsystem';
import { BehaviorSubject } from 'rxjs';
import { iOrder, iOrderFilter } from 'src/app/Shared/Interfaces/iOrder';

@Injectable({
  providedIn: 'root',
})
export class OrderService extends DefaultBaseService {
  res: iResultHttp = new iResultHttp();

  protected _resultLastOrder = new BehaviorSubject<any>(null);
  getResultLastOrder() {
    return this._resultLastOrder;
  }
  clearResultLastOrder() {
    this._resultLastOrder.next(null);
  }

  constructor(private http: HttpClient) {
    super();
  }

  getAll(queryParams: iOrderFilter) {
    this.http
      .get(
        this.urlApi + '/api/order' + this.optionsGet(queryParams),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          let normalizedOrder = iOrder.convertFromCollection(
            iStaticUtilities.normalizeNamesCollection(data)
          );
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: normalizedOrder,
          });
          this.sendNextResult('result', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultError', this.res, {
            method: this.getAll,
            args: [queryParams],
          });
          this.checkStatusError(error);
        },
      });
  }
  getAllPedidosForAlbaranList(id) {
    this.http
      .get(this.urlApi + '/api/order/company/list/' + id, this.getHeader())
      .subscribe({
        next: (data: any) => {
          let normalizedOrder = iStaticUtilities.normalizeNamesCollection(data);
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: normalizedOrder,
          });
          this.sendNextResult('result', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultError', this.res, {
            method: this.getAllPedidosForAlbaranList,
            args: [id],
          });
          this.checkStatusError(error);
        },
      });
  }

  getSingle(id: number) {
    this.http
      .get(this.urlApi + '/api/order/' + id, this.getHeader())
      .subscribe({
        next: (data: any) => {
          let normalizedOrder = iStaticUtilities.normalizeNames(data);
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: normalizedOrder,
          });
          this.sendNextResult('resultIndividual', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultIndividualError', this.res, {
            method: this.getSingle,
            args: [id],
          });
          this.checkStatusError(error);
        },
      });
  }

  create(order: iOrder) {
    this.http
      .post(this.urlApi + '/api/order', order, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data['data'],
          });
          this.sendNextResult('resultUpdate', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultUpdateError', this.res, {
            method: this.create,
            args: [order],
          });
          this.checkStatusError(error);
        },
      });
  }

  update(order: any) {
    this.http
      .put(this.urlApi + '/api/order/' + order.id, order, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data['data'],
          });
          this.sendNextResult('resultUpdate', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultUpdateError', this.res, {
            method: this.update,
            args: [order],
          });
          this.checkStatusError(error);
        },
      });
  }

  delete(ids: Array<number | string>) {
    let idsObjs = this.convertArrayObject(ids, 'ids');
    this.http
      .delete(this.urlApi + '/api/order', this.sendBodyOptions(idsObjs))
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data['data'],
          });
          this.sendNextResult('resultDelete', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultDeleteError', this.res, {
            method: this.delete,
            args: [ids],
          });
          this.checkStatusError(error);
        },
      });
  }

  getAllOrdersCompanyProveedor(queryParams: iOrderFilter) {
    this.http
      .get(
        this.urlApi + '/api/order/company' + this.optionsGet(queryParams),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          let normalizedOrder = iStaticUtilities.normalizeNamesCollection(
            data.data
          );
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: { data: normalizedOrder, total: data.total },
          });
          this.sendNextResult('result', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultError', this.res, {
            method: this.getAllOrdersCompanyProveedor,
            args: [queryParams],
          });
          this.checkStatusError(error);
        },
      });
  }
  getAllOrdersOneCompany(queryParams: iOrderFilter, companyId: number) {
    this.http
      .get(
        this.urlApi +
          '/api/order/company/' +
          companyId +
          this.optionsGet(queryParams),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          let normalizedOrder = iStaticUtilities.normalizeNamesCollection(
            data.data
          );
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: { data: normalizedOrder, total: data.total },
          });
          this.sendNextResult('result', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultError', this.res, {
            method: this.getAllOrdersOneCompany,
            args: [queryParams, companyId],
          });
          this.checkStatusError(error);
        },
      });
  }

  getLastOrder() {
    this.http.get(this.urlApi + '/api/order/last', this.getHeader()).subscribe({
      next: (data: any) => {
        this.res = new iResultHttp({
          status: 200,
          ok: true,
          message: '',
          data: data,
        });
        this.sendNextObservable(this._resultLastOrder, this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({
          status: error.status,
          ok: false,
          message: error.error.message,
        });
        this.sendNextObservable(this._resultLastOrder, this.res, true, {
          method: this.getLastOrder,
          args: [],
        });
        this.checkStatusError(error);
      },
    });
  }
  deleteOrderPdf(id, index) {
    this.http
      .delete(
        this.urlApi + '/api/order/delete/pdf/' + id + '/' + index,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data['data'],
          });
          this.sendNextResult('resultDelete', this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message,
          });
          this.sendNextResult('resultDeleteError', this.res, {
            method: this.deleteOrderPdf,
            args: [id, index],
          });
          this.checkStatusError(error);
        },
      });
  }
}
