<FullContainer>
  <div class="fullView">
    <div class="headerAndToggle">
      <div class="header">
        <p>CLIENTES</p>
      </div>
      <div class="toggle">
        <CustomToggle [selected]="selected" [selectores]="selectores" (selectedOption)="selectedOption($event)">
        </CustomToggle>
      </div>
    </div>
    <div class="filters">

      <div class="buttonsFipelineList" [ngClass]="{listSelected: selectedList, pipeSelected: !selectedList}"
        *ngIf="selected == 2">
        <button class="iconLeft" (click)="selectedListToggle(false)">
          <ng-container>
            <mat-icon class="mat-icon-left">segment</mat-icon>
          </ng-container>
        </button>

        <button class="iconRight" (click)="selectedListToggle(true)">
          <ng-container>
            <mat-icon>reorder</mat-icon>
          </ng-container>
        </button>
      </div>

      <filterActions>
        <ng-container filterContent>
          <div class="containerSelectores">
            <div>
              <selector></selector>
            </div>
            <div>
              <selector></selector>
            </div>
            <div>
              <selector></selector>
            </div>
            <div>
              <selector></selector>
            </div>
          </div>
        </ng-container>
      </filterActions>
    </div>

    <div class="content">

      <ng-container *ngIf="selected === 0">
        <ClientesMapa></ClientesMapa>
      </ng-container>
      <ng-container *ngIf="selected === 1">
        <ClientesClientes></ClientesClientes>
      </ng-container>
      <ng-container *ngIf="selected === 2">
        <ClientesCPotenciales [selectedList]="selectedList"></ClientesCPotenciales>
      </ng-container>
      <ng-container *ngIf="selected === 3">
        <ClientesAntiguos></ClientesAntiguos>
      </ng-container>
      <ng-container *ngIf="selected === 4">
        <ClientesMaquinas></ClientesMaquinas>
      </ng-container>



    </div>

  </div>

</FullContainer>