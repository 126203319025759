import { iBaseObject } from "@quasar_dynamics/basic-designsystem";

export interface iTypeProduct {
    id: number,
    description?: string,
}

export class iTypeProduct extends iBaseObject {
    static convertFromCollection(ob: any): Array<iTypeProduct> {
        let iTypeProductCollection: Array<iTypeProduct> = [];
        ob.forEach((element: any) => {
            iTypeProductCollection.push(this.convertFromObject(element));
        });
        return iTypeProductCollection;
    }

    static convertFromObject(ob: any): iTypeProduct {
        let iTypeProductObj = new iTypeProduct();
        iTypeProductObj.id = ob.id;
        iTypeProductObj.description = ob.description;
        return iTypeProductObj;
    }

    static createEmptyObject(): iTypeProduct {
        let iTypeProductObj = new iTypeProduct();
        iTypeProductObj.description = '';
        return iTypeProductObj;
    }
}

export interface iTypeProductFilter {
    num_devoluciones: number;
    num_pagina: number;
    genericFilter?: string;
    description?: string,
}