import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { iUnsubscribeDestroy } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { ProductionReportService } from 'src/app/Services/Api/ProductionReport.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'app-CierreDeFabricacion-Popup',
  templateUrl: './CierreDeFabricacion-Popup.component.html',
  styleUrls: ['./CierreDeFabricacion-Popup.component.scss']
})
export class CierreDeFabricacionPopupComponent extends iUnsubscribeDestroy implements OnInit {

  selectorMap = 0;
  isCompletedFormulaDetail: boolean = false;
  parteId:any = null;
  formulaData:any = null;
  characteristicManyData:any = null;
  isCompletedCaracteristicas: boolean = false;
  productionReportDetail: any = null;

  constructor(public dialogRef: MatDialogRef<CierreDeFabricacionPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any,private productionReportSE:ProductionReportService) {
    super();
  }

  ngOnInit() {
    this.parteId = this.modalData.parteId;
  }

  /**
* FUNCTIONALITY
*/
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }
  nextSection() {
    if (this.selectorMap < 2)
      this.selectorMap++;
  }
  previousSection() {
    if (this.selectorMap > 0)
      this.selectorMap--;
  }
  FormulaParteData(event){
    this.formulaData = event;
    this.isCompletedFormulaDetail = true;
  }
  isCompleteCaracteristicas(event){
    this.isCompletedCaracteristicas = event;
  }
  dataCharacteristics(event){
    this.characteristicManyData = event;
  }
  dataPRDetail(event){
    this.productionReportDetail = event;
  }
  create() {
    let createObject:any =  {...this.formulaData, productionReportDetail: this.productionReportDetail,characteristicsMany: this.characteristicManyData}
    this.closeProductionReport(createObject)
  }

   /**
    * API CALLS
    */

   closeProductionReport(createObject) {
    this.productionReportSE.closeProductionReport(createObject, this.parteId);
    this.productionReportSE.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe((res: any) => {
      if(!res)return;
      StaticUtilitiesService.showFeedback('Parte cerrado correctamente')
      this.closePopup('create')
      this._unsubInd.next('')
    });
    this.productionReportSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res: any) => {
      if(!res)return;
      StaticUtilitiesService.showError('El parte no pudo ser creado')
      this._unsub.next('')
    });
   }
}
