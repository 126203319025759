<div class="popupGeneralContainer">
  <div class="header">
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
  </div>
  <div class="timeline">
    <div class="line">
      <span [class.active]="timeLineStep == 1">1</span>
      <span [class.active]="timeLineStep == 2">2</span>
      <span [class.active]="timeLineStep == 3">3</span>
      <span [class.active]="timeLineStep == 4">4</span>
      <span [class.active]="timeLineStep == 5">5</span>
    </div>
  </div>
  <p class="title">
    <ng-container *ngIf="timeLineStep == 1">
      FICHA
    </ng-container>
    <ng-container *ngIf="timeLineStep == 2">
      FÓRMULA
    </ng-container>
    <ng-container *ngIf="timeLineStep == 3">
      ENVASES COMPATIBLES
    </ng-container>
    <ng-container *ngIf="timeLineStep == 4">
      ESPECIFICACIONES DE PRODUCTO
    </ng-container>
    <ng-container *ngIf="timeLineStep == 5">
     FUNGIBLES
    </ng-container>
  </p>

  <div class="buttons" *ngIf="timeLineStep == 2">
    <div>
      <div class="add">
        <btn class="fitContent" classList="iconBtn plusBtn fitContent" (click)="nuevoMaterial()">
          <i class="material-icons">add</i>
          <span class="spanInt">AÑADIR COMPONENTE</span>
        </btn>
      </div>
      <div class="add">
        <btn class="fitContent" classList="iconBtn plusBtn fitContent " (click)="nuevaExplicacion()">
          <i class="material-icons grey">add</i>
          <span class="spanInt">AÑADIR LÍNEA PROCESO</span>
        </btn>
      </div>
    </div>
    <btn classList="transformarEnCAntiguo" (click)="cuadrar()">
      Cuadrar la muestra
    </btn>
  </div>
  <div class="buttons" *ngIf="timeLineStep == 3">
    <div>
      <div class="add">
        <btn class="fitContent" classList="iconBtn plusBtn fitContent" (click)="nuevoEnvase()">
          <i class="material-icons">add</i>
          <span class="spanInt">AÑADIR ENVASE</span>
        </btn>
      </div>
    </div>
  </div>
  <div class="buttons" *ngIf="timeLineStep == 4">
    <div>
      <div class="add">
        <btn class="fitContent" classList="iconBtn plusBtn fitContent" (click)="nuevaCaracteristica()">
          <i class="material-icons">add</i>
          <span class="spanInt">AÑADIR CARACTERÍSTICA</span>
        </btn>
      </div>
    </div>
  </div>
  <div class="buttons" *ngIf="timeLineStep == 5">
    <div>
      <div class="add">
        <btn class="fitContent" classList="iconBtn plusBtn fitContent" (click)="nuevoFungible()">
          <i class="material-icons">add</i>
          <span class="spanInt">AÑADIR FUNGIBLE</span>
        </btn>
      </div>
    </div>
  </div>
  <div class="body">
    <nuevaFormularStep1 *ngIf="timeLineStep == 1" (isCompleted)="step1Data = $event;checkIsCompleted()"
      [step1Data]="step1Data" [toEditData]="toEditData" [modalData]="modalData"></nuevaFormularStep1>
    <nuevaFormulaStep2 *ngIf="timeLineStep == 2" (costData)="costDataFunction($event)" (recall)="recall($event)" (isCompleted)="setData($event, step2Data);checkIsCompleted()"
      [step2Data]="step2Data" [nuevoMaterialFlag]="nuevoMaterialFlag" [cuadrarFlag]="cuadrarFlag"
      [nuevaExplicacionFlag]="nuevaExplicacionFlag" [toEditData]="toEditData" [modalData]="modalData"></nuevaFormulaStep2>
    <nuevaFormulaStep3 *ngIf="timeLineStep == 3" (isCompleted)="setData($event, step3Data);checkIsCompleted()"
      [step3Data]="step3Data" [envaseFlag]="envaseFlag" [toEditData]="toEditData"></nuevaFormulaStep3>
    <nuevaFormulaStep4 [caracteristicaFlag]="caracteristicaFlag" *ngIf="timeLineStep == 4" (isCompleted)="setData($event, step4Data);checkIsCompleted()"
      [step4Data]="step4Data" [toEditData]="toEditData"></nuevaFormulaStep4>
    <nuevaFormulaStep5 *ngIf="timeLineStep == 5" [fungibleFlag]="fungibleFlag" (isCompleted)="setData($event, step5Data);checkIsCompleted()"
      [step5Data]="step5Data" [toEditData]="toEditData"></nuevaFormulaStep5>
  </div>
  <div class="footer" *ngIf="modalData.accion!='ver'">
    <btn classList="secundario" (click)="forceClosePopup()" *ngIf="timeLineStep == 1">
      <ng-container>
        Cancelar
      </ng-container>
    </btn>
    <btn classList="secundario" (click)="previousStep()" *ngIf="timeLineStep > 1">
      <ng-container>
        Atrás
      </ng-container>
    </btn>
    <btn classList="secundaryButton" (click)="create()" [class.disabled]="!isCompleted" *ngIf="modalData.accion=='crear' && timeLineStep == 5">
      <ng-container >
        Crear
      </ng-container>
    </btn>
    <btn classList="secundaryButton" (click)="closePopup()" [class.disabled]="!isCompleted" *ngIf="modalData.accion=='editar' && timeLineStep == 5">
      <ng-container >
        Editar
      </ng-container>
    </btn>
    <btn classList="secundaryButton" (click)="nextStep()" [class.disabled]="!isCompleted" *ngIf="timeLineStep != 5">
      <ng-container>
        Siguiente
      </ng-container>
    </btn>
  </div>
</div>
