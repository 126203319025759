import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { iUnsubscribeDestroy } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { GenericRateService } from 'src/app/Services/Api/GenericRate.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'app-EditarTarifas-Popup',
  templateUrl: './EditarTarifas-Popup.component.html',
  styleUrls: ['./EditarTarifas-Popup.component.scss']
})
export class EditarTarifasPopupComponent extends iUnsubscribeDestroy implements OnInit {


  tarifa1: number | null = null;
  tarifa2: number | null = null;
  isCompleted: boolean = false;
  updateObject: any = {
    id: '',
  }
  constructor(public dialogRef: MatDialogRef<EditarTarifasPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any, private genericRateSE: GenericRateService) {
    super();
  }

  ngOnInit() {
    if (this.modalData.accion !== 'crear') {
      this.setter()
    }
  }

  /**
   * GETTERS && SETTERS
   */

  setter() {
    this.tarifa1 = this.modalData.data.rateOne;
    this.tarifa2 = this.modalData.data.rateTwo;
    this.modalData.data.genericRateId ? this.updateObject.id = this.modalData.data.genericRateId : this.updateObject.id = this.modalData.data.RatesCustomerId;
  }

  /**
  * FUNCTIONALITY
  */
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }
  editSecction(event, key: string) {
    if (!event) { return };
    this.updateObject[key] = event;
    this.checkIsCompleted();
    this.edit();
  }

  /**
   * VALIDATIONS
   */
  checkIsCompleted() {
    this.tarifa1 && this.tarifa2 ? this.isCompleted = true : this.isCompleted = false
  }

  /**
   * API CALLS
   */

  edit() {
    if (this.modalData.data.genericRateId) {
      this.genericRateSE.update(this.updateObject);
      this.genericRateSE.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe(res => {
        if (!res) { return }
        StaticUtilitiesService.showFeedback('Tarifa actualizada correctamente')
        this._unsubInd.next('');
      })
      this.genericRateSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe(res => {
        if (!res) { return }
        StaticUtilitiesService.showError(res.message)
        this._unsub.next('');
      })
    }
  }
}
