import { Component, OnInit } from '@angular/core';
import { iUnsubscribeDestroy, PopupService, slideNavigationTypes } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { OrderService } from 'src/app/Services/Api/Order.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'app-Clientes-Pedidos-Detail',
  templateUrl: './Clientes-Pedidos-Detail.component.html',
  styleUrls: ['./Clientes-Pedidos-Detail.component.scss']
})
export class ClientesPedidosDetailComponent extends iUnsubscribeDestroy implements OnInit {

  pedidoId:number = 0
  openAccordeon:boolean = false
  data:any = {}

  constructor( private staticUtilities:StaticUtilitiesService, private popupSE:PopupService, private orderSE:OrderService ) {
    super();
  }

  ngOnInit() {
    this.getParams()
  }
  
  getParams(){
    if(!this.staticUtilities.getParams().pedido || this.staticUtilities.getParams().pedido <= 0){
      StaticUtilitiesService.showError('No se ha encontrado el pedido')
      this.staticUtilities.goTo('erp/clientes/', slideNavigationTypes.slideToBottom)
      return;
    }
    this.pedidoId = this.staticUtilities.getParams().pedido
    this.getPedidoId()
  }

  goBack(){
    this.staticUtilities.goTo('erp/clientes/', slideNavigationTypes.slideToBottom)
  }

  refresh(event){
    if(event){
      this.getPedidoId()
    }
  }

  printScreen(){
    this.openAccordions()
    setTimeout(() => {
      window.print();
    }, 100);
  }
  openAccordions(){
    this.openAccordeon = true
    setTimeout(() => {
      this.openAccordeon = false
    }, 200);
  }

  /**
   * API CALLS
   */

  getPedidoId(){
    this.orderSE.getSingle(this.pedidoId);
    this.orderSE.getResultIndividual().pipe(takeUntil(this._unsubInd)).subscribe((res) => {
      if(!res){return};
      let {data, total} = res;
      this.data = data;
      this._unsubInd.next('')
    })
  }


}
