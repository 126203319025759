<div class="popupGeneralContainer">
  <div class="header">
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
    <p>Asignar producto al proveedor</p>
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
  </div>
  <div class="body">
    <div class="tripleColumn">
      <div class="customContainer">
        <label for="Días fijos">Producto</label>
        <selector [options]="opcionesProducto" [(ngModel)]="data.rawMaterial" (change)="checkIsCompleted()"></selector>
      </div>
      <CustomInput [type]="'number'" classList="fullWidth tall special"  placeholder="Precio" label="Precio" [(ngModel)]="data.price" (change)="checkIsCompleted()"></CustomInput>
      <div class="customContainer">
        <label for="Días fijos">Fecha de oferta</label>
        <datepicker (dataFromPicker)="getFromDatepicker($event, 'offerDate')"></datepicker>
      </div>

    </div>
    <div class="footer">
      <btn classList="secundario" (click)="forceClosePopup()">
        <ng-container>
          Cancelar
        </ng-container>
      </btn>
      <btn classList="secundaryButton" (click)="create()" [class.disabled]="!isCompleted">
        <ng-container>
          Asignar
        </ng-container>
      </btn>
    </div>
  </div>
