import { iBaseObject } from "@quasar_dynamics/basic-designsystem";

export interface iAddress {
    id: number,
    street?: string,
    number?: string,
    postalCode?: string,
    country?: string,
    province?: string,
    municipality?: string,
    contact?: string,
    phones?: any,
}

export class iAddress extends iBaseObject {
    static convertFromCollection(ob: any): Array<iAddress> {
        let iAddressCollection: Array<iAddress> = [];
        ob.forEach((element: any) => {
            iAddressCollection.push(this.convertFromObject(element));
        });
        return iAddressCollection;
    }

    static convertFromObject(ob: any): iAddress {
        let iAddressObj = new iAddress();
        iAddressObj.id = ob.id;
        iAddressObj.street = ob.street;
        iAddressObj.number = ob.number;
        iAddressObj.postalCode = ob.postal_code;
        iAddressObj.country = ob.country;
        iAddressObj.province = ob.province;
        iAddressObj.municipality = ob.municipality;
        iAddressObj.contact = ob.contact;
        iAddressObj.phones = ob.phones;
        return iAddressObj;
    }

    static createEmptyObject(): iAddress {
        let iAddressObj = new iAddress();
        iAddressObj.street = '';
        iAddressObj.number = '';
        iAddressObj.postalCode = '';
        iAddressObj.country = '';
        iAddressObj.province = '';
        iAddressObj.municipality = '';
        iAddressObj.contact = '';
        iAddressObj.phones = null;
        return iAddressObj;
    }
}

export interface iAddressFilter {
    num_devoluciones: number;
    num_pagina: number;
    genericFilter?: string;
    street?: string,
    number?: string,
    postalCode?: string,
    country?: string,
    province?: string,
    municipality?: string,
    contact?: string,
    phones?: any,
}