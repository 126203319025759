import { Component, OnInit } from '@angular/core';
import { iOptionsSelector, iUnsubscribeDestroy, PopupService } from '@quasar_dynamics/basic-designsystem';
import { NuevoClientePopupComponent } from 'src/app/Popups/NuevoCliente-Popup/NuevoCliente-Popup.component';
import { takeUntil } from 'rxjs';
import { NuevoClientePedidoComponentPopup } from 'src/app/Popups/NuevoClientePedido-Popup/NuevoClientePedido-Popup.component';
import { NuevoAlbaranPopupComponent } from 'src/app/Popups/NuevoAlbaran-Popup/NuevoAlbaran-Popup.component';

@Component({
  selector: 'ERP-Clientes',
  templateUrl: './ERP-Clientes.component.html',
  styleUrls: ['./ERP-Clientes.component.scss']
})
export class ERPClientesComponent extends iUnsubscribeDestroy implements OnInit {

  selectedList: boolean = true;
  refresh: boolean = false;
  refreshAlbaran: boolean = false;
  refreshPedidos: boolean = false;
  selected = 0;
  search = '';
  filterOrders:any = {
    num_devoluciones: 20,
    num_pagina: 1,
    isClient: true,
    isActive: true,
  }
  opcionesClientes: iOptionsSelector = {
    items: [],
    clearable: true,
    search: true,
    placeholder: "Cliente",
    bindValue:'id',
    bindLabel:'name',
    append: true,
  }

  selectores = [
    { label: 'Clientes', icon: 'none', image: 'none', selected: 0, before: 'yes', color: 'azul' },
    { label: 'Pedidos', icon: 'none', image: 'none', selected: 1, before: 'yes', color: 'azul' },
    { label: 'Albarán', icon: 'none', image: 'none', selected: 2, before: 'yes', color: 'azul' },
  ]


  constructor(private popupSE: PopupService) {
    super();
  }

  ngOnInit() {
  }

  /**
   * 
   * GETTERS AND SETTERS
   */

  getFilter(event,key){
    this.filterOrders[key] = event
    this.filterOrders = {...this.filterOrders}
  }
  clientsForFilter(event){
    this.opcionesClientes.items = event;
  }


  selectedOption(event) {
    this.selected = event;
  }

  selectedListToggle(event) {
    this.selectedList = event;
  }

  /**
   * create
   */

  create() {
    if (this.selected == 0) {
      this.popupSE.openPopup(NuevoClientePopupComponent, { data: null, accion: 'crear' })
      this.popupSE.returnData().pipe(takeUntil(this._unsubInd)).subscribe(res => {
        if (!res) { return };
        this.refresh = !this.refresh;
        this._unsubInd.next('');
      })
    }
    if(this.selected == 1){
      if(screen.width > 1450){
        this.popupSE.openPopup(NuevoClientePedidoComponentPopup, { data: null, accion: 'crear' })
      }else{
        this.popupSE.openPopup(NuevoClientePedidoComponentPopup, { data: null, accion: 'crear' }, 'fullSizePopup')
      }
      this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
        if (!res) { return };
        this.refreshPedidos = !this.refreshPedidos;
        this._unsubInd2.next('');
      })
    }
    if (this.selected == 2) {
      this.popupSE.openPopup(NuevoAlbaranPopupComponent, { data: null, accion: 'crear' })
      this.popupSE.returnData().pipe(takeUntil(this._unsubInd)).subscribe(res => {
        if (!res) { return };
        this.refreshAlbaran = !this.refreshAlbaran;
        this._unsubInd.next('');
      })
    }
  }



}


