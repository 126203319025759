import { Component, OnInit } from '@angular/core';
import { iUnsubscribeDestroy, PopupService, slideNavigationTypes } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { NuevaMateriaPrimaPopupComponent } from 'src/app/Popups/NuevaMateriaPrima-Popup/NuevaMateriaPrima-Popup.component';
import { SubirDocumentoPopupComponent } from 'src/app/Popups/SubirDocumento-Popup/SubirDocumento-Popup.component';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'app-ERP-MateriasPrimas-Detalles',
  templateUrl: './ERP-MateriasPrimas-Detalles.component.html',
  styleUrls: ['./ERP-MateriasPrimas-Detalles.component.scss']
})
export class ERPMateriasPrimasDetallesComponent extends iUnsubscribeDestroy implements OnInit {

  rawMaterialName = "";
  idMateriaPrima: number | null = null;
  isLoading: boolean = false;
  constructor(private staticUtilities: StaticUtilitiesService, private popupSE: PopupService) {
    super();
  }
  ngOnInit() {
    if (this.staticUtilities.getParams().materiaPrima == undefined) {
      this.staticUtilities.goTo("/erp/proveedores", slideNavigationTypes.slideToBottom);
      StaticUtilitiesService.showError("No se ha encontrado la materia prima");
    } else {
      this.idMateriaPrima = Number(this.staticUtilities.getParams().materiaPrima);
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
      }, 1500);
    }
  }
  /**
   * FUNCTIONALITIES
   */
  returnBack() {
    localStorage.removeItem("mantenimiento")
    this.staticUtilities.goTo("/erp/proveedores", slideNavigationTypes.slideToBottom)
  }
  edit(event, seccion){
    this.popupSE.openPopup(NuevaMateriaPrimaPopupComponent, { accion: null, data: {id:this.idMateriaPrima} });
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd)).subscribe((data) => {
      StaticUtilitiesService.showFeedback("Se ha editado la materia prima correctamente")
      this._unsubInd.next("")
    })
  }
  getRawMaterialName(name){
    this.rawMaterialName = name;
  }

}
