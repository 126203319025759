import { Component, OnInit } from '@angular/core';
import { StaticDataHandlerService } from 'src/app/Services/Utils/StaticDataHandler.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';
import * as pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'src/assets/Fonts/custom-fonts.js'; // The path of your custom fonts
import { takeUntil } from 'rxjs';
import { LoginService } from 'src/app/Services/Api/Login.service';
import { iUnsubscribeDestroy } from '@quasar_dynamics/basic-designsystem';
import { NoticeService } from 'src/app/Services/Api/Notice.service';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-ERP-Dashboard',
  templateUrl: './ERP-Dashboard.component.html',
  styleUrls: ['./ERP-Dashboard.component.scss'],
})
export class ERPDashboardComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  navegationERP = [
    {
      label: 'Mantenimientos',
      color: '#FF5664',
      router: '/erp/mantenimientos',
    },
    { label: 'Proveedores', color: '#009E9E', router: '/erp/proveedores' },
    // { label: 'Clientes', color: '#0083F1', router: '/erp/clientes' },
    {
      label: 'Partes de producción',
      color: '#FF8000',
      router: '/erp/partesproduccion',
    },
    { label: 'Stock', color: '#C50505', router: '/erp/stock' },
    { label: 'Contabilidad', color: '#11AC2F', router: '/erp/contabilidad' },
  ];
  ultimosMovimientos: Array<any> = [];

  constructor(
    private staticUtilitiesSE: StaticUtilitiesService,
    private loginSE: LoginService,
    private noticeService: NoticeService
  ) {
    super();
  }

  ngOnInit() {
    this.getAllNotices();
  }

  /**
   * FUNCTIONALITIES
   */
  navigateTo(url: string) {
    this.staticUtilitiesSE.goTo(url);
  }

  // PDF GENERATOR ---------------------------------------------------------------------------------------------------------

  async generatePDFAlbaran() {
    const docDefinition = {
      header: {},
      footer: {
        columns: [
          {
            widths: ['25%', '75%'],
            image: await this.getBase64ImageFromURL(
              '/assets/Icons/mopasaLogoRojo.png'
            ),
            width: 100,
            alignment: 'left', // Alinea verticalmente el contenido de la columna al centro
            fit: [25, 25],
            margin: [40, 0, 0, 0],
          },
          {
            stack: [
              {
                text: 'Mopasa Barnices y Pintura',
                style: 'textoSmallBoldFooter',
                margin: [0, 0, 0, 0],
              },
              {
                text: 'P.I. El Plà, Parcela 38 · Tel.: 961 240 311 · Fax: 961 230 428',
                style: 'textoSmallFooter',
                margin: [0, 0, 0, 0],
              },
              {
                text: 'Apartado de Correos 15 · 46290 Alcàsser (Valencia) España',
                style: 'textoSmallFooter',
                margin: [0, 0, 0, 0],
              },
              {
                text: 'www.mopasa.com',
                style: 'textoSmallFooter',
                margin: [0, 0, 0, 0],
                color: 'red',
              },
            ],
            alignment: 'left',
            margin: [-20, 0, 0, 0],
          },
          {
            text: 'PRODUCTOS QUÍMICOS MOPASA S.L., Registro Mercantil de Valencia, folio 101 Libro de Sociedades, Sección 4ª Limitadas, hojas nº 2961. Inscripción 1ª - C.I.F. B46060448',
            alignment: 'left',
            style: 'textoFooterRight',
            margin: [0, 0, 50, 0],
            width: '60%',
          },
        ],
      },
      content: [
        {
          image: await this.getBase64ImageFromURL(
            '/assets/Icons/mopasaLogoRojoCompleto.png'
          ),
          fit: [150, 150],
          width: 100,
          alignment: 'right', // Alinea verticalmente el contenido de la columna al centro
        },
        {
          text: 'ALBARÁN DE ENTREGA',
          fontSize: 18,
          bold: true,
          margin: [0, -35, 0, 10],
        },
        {
          text: 'Número 23',
          style: 'subheader',
        },
        {
          text: '23/03/2023',
          style: 'subheader',
        },
        {
          canvas: [
            {
              type: 'rect', // Agregamos un rectángulo
              x: 0,
              y: 10,
              w: 255, // El ancho del rectángulo
              h: 130, // El alto del rectángulo
              r: 10,
              color: '#f1f1f0',
            },
          ],
        },
        {
          canvas: [
            {
              type: 'rect', // Agregamos un rectángulo
              x: 0,
              y: 5,
              w: 255, // El ancho del rectángulo
              h: 80, // El alto del rectángulo
              r: 10,
              color: '#f1f1f0',
            },
          ],
        },
        {
          canvas: [
            {
              type: 'rect', // Agregamos un rectángulo
              x: 260,
              y: -215,
              w: 255, // El ancho del rectángulo
              h: 130, // El alto del rectángulo
              r: 10,
              color: '#f1f1f0',
            },
          ],
        },
        {
          canvas: [
            {
              type: 'rect', // Agregamos un rectángulo
              x: 260,
              y: -80,
              w: 255, // El ancho del rectángulo
              h: 80, // El alto del rectángulo
              r: 10,
              color: '#f1f1f0',
            },
          ],
        },
        // Cliente
        {
          text: 'CLIENTE',
          style: 'subheader',
          absolutePosition: { x: 50, y: 135 },
        },
        {
          text: 'SUBERCAT SYSTEMS, S.L.U.',
          style: 'subheader',
          absolutePosition: { x: 50, y: 150 },
        },
        {
          text: 'CL Sant Jordi, 19-21, 8812',
          style: 'textoNormal',
          absolutePosition: { x: 50, y: 175 },
        },
        {
          text: 'Sant Pere de Ribes, Barcelona',
          style: 'textoNormal',
          absolutePosition: { x: 50, y: 190 },
        },
        {
          text: 'Tél: 692804779',
          style: 'textoNormal',
          absolutePosition: { x: 50, y: 205 },
        },
        {
          text: 'NIF: B66481060',
          style: 'textoNormal',
          absolutePosition: { x: 50, y: 225 },
        },
        // Direccion de entrega
        {
          text: 'DIRECCIÓN DE ENTREGA',
          style: 'subheader',
          absolutePosition: { x: 310, y: 135 },
        },
        {
          text: 'SUBERCAT SYSTEMS, S.L.U.',
          style: 'subheader',
          absolutePosition: { x: 310, y: 150 },
        },
        {
          text: 'CL Sant Jordi, 19-21, 8812',
          style: 'textoNormal',
          absolutePosition: { x: 310, y: 175 },
        },
        {
          text: 'Sant Pere de Ribes, Barcelona',
          style: 'textoNormal',
          absolutePosition: { x: 310, y: 190 },
        },
        {
          text: 'Tél: 692804779',
          style: 'textoNormal',
          absolutePosition: { x: 310, y: 205 },
        },
        // AGENTE
        {
          text: 'Agente:',
          style: 'subheader',
          absolutePosition: { x: 50, y: 265 },
        },
        {
          text: 'José Luis Llano Romero',
          style: 'textoNormal',
          absolutePosition: { x: 90, y: 265 },
        },
        {
          text: 'Envío:',
          style: 'subheader',
          absolutePosition: { x: 50, y: 280 },
        },
        {
          text: 'Schenker',
          style: 'textoNormal',
          absolutePosition: { x: 80, y: 280 },
        },
        {
          text: 'Almacén:',
          style: 'subheader',
          absolutePosition: { x: 50, y: 295 },
        },
        {
          text: 'Valencia',
          style: 'textoNormal',
          absolutePosition: { x: 100, y: 295 },
        },
        // Bottom-right-box
        {
          text: 'S/ Referencia:',
          style: 'subheader',
          absolutePosition: { x: 310, y: 265 },
        },
        {
          text: '32',
          style: 'textoNormal',
          absolutePosition: { x: 375, y: 265 },
        },
        {
          text: 'Tipo Portes:',
          style: 'subheader',
          absolutePosition: { x: 310, y: 280 },
        },
        {
          text: 'Pagados',
          style: 'textoNormal',
          absolutePosition: { x: 370, y: 280 },
        },
        {
          text: 'Peso Neto:',
          style: 'subheader',
          absolutePosition: { x: 310, y: 295 },
        },
        {
          text: 'Pagados',
          style: 'textoNormal',
          absolutePosition: { x: 370, y: 295 },
        },
        {
          text: 'Bultos:',
          style: 'subheader',
          absolutePosition: { x: 310, y: 310 },
        },
        {
          text: '1',
          style: 'textoNormal',
          absolutePosition: { x: 345, y: 310 },
        },
        {
          margin: [0, -100, 0, 0],
          layout: 'tableLayout',
          table: {
            headerRows: 1,
            fontSize: 8,
            widths: ['*', 200, '*', '*', '*'],
            body: [
              [
                { text: 'Referencia', fontSize: 10 },
                { text: 'Descripción', fontSize: 10 },
                { text: 'Cantidad', fontSize: 10 },
                { text: 'Precio', fontSize: 10 },
                { text: 'Importe', fontSize: 10 },
              ],
              [
                { text: '20176515', fontSize: 10 },
                { text: 'MOPADUR AZUL S/M-P CSA BRILLO', fontSize: 10 },
                { text: '100,00 kg', fontSize: 10 },
                { text: '20,00 €/kg', fontSize: 10 },
                { text: '2000 €', fontSize: 10 },
              ],
              [
                { text: '20176515', fontSize: 10 },
                { text: 'MOPADUR AZUL S/M-P CSA MATE', fontSize: 10 },
                { text: '100,00 kg', fontSize: 10 },
                { text: '20,00 €/kg', fontSize: 10 },
                { text: '2000 €', fontSize: 10 },
              ],
            ],
            styles: {
              fontSize: 8,
            },
          },
        },
        {
          text: 'El responsable de la entrega del residuo de envase o envase usado para su correcta gestión ambiental, será el poseedor final (artículo 18.1, R.D. 782/98)',
          style: 'textoSmall',
          align: 'center',
          width: '100%',
          margin: [60, 10, 0, 0],
        },
      ],
      styles: {
        header: {
          fontSize: 20,
          bold: true,
          margin: [0, 0, 0, 10],
        },
        subheader: {
          fontSize: 10,
          bold: true,
          margin: [0, 0, 0, 5],
        },
        textoNormal: {
          fontSize: 10,
          margin: [0, 0, 0, 5],
        },
        textoFooterRight: {
          fontSize: 7,
          margin: [0, 0, 0, 5],
        },
        textoSmall: {
          fontSize: 6,
          margin: [0, 0, 0, 5],
        },
        textoSmallFooter: {
          fontSize: 5,
          margin: [0, 0, 0, 5],
        },
        textoSmallBold: {
          bold: true,
          fontSize: 6,
          margin: [0, 0, 0, 5],
        },
        textoSmallBoldFooter: {
          bold: true,
          fontSize: 5,
          margin: [0, 0, 0, 5],
        },
        textoMedium: {
          fontSize: 8,
          margin: [0, 0, 0, 5],
        },
        table: {
          margin: [0, 5, 0, 15],
        },
      },
    };
    pdfMake.tableLayouts = {
      tableLayout: {
        fontSize: 10,
        fillColor: function (rowIndex, node, columnIndex) {
          return rowIndex === 0 ? '#f1f1f0' : null;
        },
        vLineWidth: function (i, node) {
          return 1; // dibujar bordes verticales en todas las celdas
        },
        hLineWidth: function (i, node) {
          return i === node.table.body.length || i === 0 ? 1 : 0; // dibujar bordes horizontales en todas las celdas
        },
        hLineColor: function (i, node) {
          return '#bfbfbf'; // color del borde horizontal
        },
        vLineColor: function (i, node) {
          return '#bfbfbf'; // color del borde vertical
        },
        paddingLeft: function (i, node) {
          return 10; // espacio interno de la celda izquierda
        },
        paddingRight: function (i, node) {
          return 10; // espacio interno de la celda derecha
        },
        paddingTop: function (i, node) {
          return 5; // espacio interno de la celda superior
        },
        paddingBottom: function (i, node) {
          return 5; // espacio interno de la celda inferior
        },
      },
    };
    pdfMake.createPdf(docDefinition).download('albarán.pdf');
  }

  // PDF GENERATOR FACTURA

  async generatePDFFactura() {
    const docDefinition = {
      footer: {
        columns: [
          {
            widths: ['25%', '75%'],
            image: await this.getBase64ImageFromURL(
              '/assets/Icons/mopasaLogoRojo.png'
            ),
            width: 100,
            alignment: 'left', // Alinea verticalmente el contenido de la columna al centro
            fit: [25, 25],
            margin: [40, 0, 0, 0],
          },
          {
            stack: [
              {
                text: 'Mopasa Barnices y Pintura',
                style: 'textoSmallBoldFooter',
                margin: [0, 0, 0, 0],
              },
              {
                text: 'P.I. El Plà, Parcela 38 · Tel.: 961 240 311 · Fax: 961 230 428',
                style: 'textoSmallFooter',
                margin: [0, 0, 0, 0],
              },
              {
                text: 'Apartado de Correos 15 · 46290 Alcàsser (Valencia) España',
                style: 'textoSmallFooter',
                margin: [0, 0, 0, 0],
              },
              {
                text: 'www.mopasa.com',
                style: 'textoSmallFooter',
                margin: [0, 0, 0, 0],
                color: 'red',
              },
            ],
            alignment: 'left',
            margin: [-20, 0, 0, 0],
          },
          {
            text: 'PRODUCTOS QUÍMICOS MOPASA S.L., Registro Mercantil de Valencia, folio 101 Libro de Sociedades, Sección 4ª Limitadas, hojas nº 2961. Inscripción 1ª - C.I.F. B46060448',
            alignment: 'left',
            style: 'textoFooterRight',
            margin: [0, 0, 50, 0],
            width: '60%',
          },
        ],
      },
      content: [
        {
          image: await this.getBase64ImageFromURL(
            '/assets/Icons/mopasaLogoRojoCompleto.png'
          ),
          fit: [150, 150],
          width: 100,
          alignment: 'right',
        },
        {
          text: 'Factura',
          fontSize: 18,
          bold: true,
          margin: [0, -35, 0, 10],
        },
        {
          text: 'Número 451',
          style: 'subheader',
        },
        {
          text: '23/03/2023',
          style: 'subheader',
        },
        {
          canvas: [
            {
              type: 'rect', // Agregamos un rectángulo
              x: 0,
              y: 10,
              w: 250, // El ancho del rectángulo
              h: 130, // El alto del rectángulo
              r: 10,
              color: '#f1f1f0',
            },
          ],
        },
        {
          canvas: [
            {
              type: 'rect', // Agregamos un rectángulo
              x: 0,
              y: 20,
              w: 250, // El ancho del rectángulo
              h: 50, // El alto del rectángulo
              r: 10,
              color: '#f1f1f0',
            },
          ],
        },
        {
          canvas: [
            {
              type: 'rect', // Agregamos un rectángulo
              x: 270,
              y: -200,
              w: 250, // El ancho del rectángulo
              h: 130, // El alto del rectángulo
              r: 10,
              color: '#f1f1f0',
            },
          ],
        },
        // Cliente
        {
          text: 'CLIENTE',
          style: 'subheader',
          absolutePosition: { x: 50, y: 135 },
        },
        {
          text: 'Metalicas Coysa, S.L.',
          style: 'subheader',
          absolutePosition: { x: 50, y: 150 },
        },
        {
          text: 'Pol. Ind. Santa Ana, C. Fomento, 2, 41590',
          style: 'textoNormal',
          absolutePosition: { x: 50, y: 175 },
        },
        {
          text: 'La Roda de Andalucía, Sevilla',
          style: 'textoNormal',
          absolutePosition: { x: 50, y: 190 },
        },
        {
          text: 'Tél: 654879458',
          style: 'textoNormal',
          absolutePosition: { x: 50, y: 205 },
        },
        {
          text: 'NIF: B91431619',
          style: 'textoNormal',
          absolutePosition: { x: 50, y: 225 },
        },
        // Direccion de entrega
        {
          text: 'CONDICIONES DE PAGO',
          style: 'subheader',
          absolutePosition: { x: 320, y: 135 },
        },
        {
          text: 'Giro 30 días fecha albarán',
          style: 'textoNormal',
          absolutePosition: { x: 320, y: 150 },
        },
        {
          text: 'Domiciliación Bancaria',
          style: 'subHeader',
          absolutePosition: { x: 320, y: 175 },
        },
        {
          text: 'Bankinter',
          style: 'textoNormal',
          absolutePosition: { x: 320, y: 190 },
        },
        {
          text: 'ES64 0081 1222 8100 0111 7416',
          style: 'textoNormal',
          absolutePosition: { x: 320, y: 205 },
        },
        {
          text: 'Vencimiento: 25/05/2023',
          style: 'textoNormal',
          absolutePosition: { x: 320, y: 220 },
        },
        // AGENTE
        {
          text: 'Agente:',
          style: 'subheader',
          absolutePosition: { x: 50, y: 285 },
        },
        {
          text: 'José Luis Llano Romero',
          style: 'textoNormal',
          absolutePosition: { x: 90, y: 285 },
        },
        {
          text: 'Albarán:',
          style: 'subheader',
          absolutePosition: { x: 50, y: 300 },
        },
        {
          text: '1235',
          style: 'textoNormal',
          absolutePosition: { x: 90, y: 300 },
        },
        {
          margin: [0, -50, 0, 0],
          layout: 'tableLayout',
          table: {
            headerRows: 1,
            fontSize: 8,
            widths: ['*', 200, '*', '*', '*'],
            body: [
              [
                { text: 'Referencia', fontSize: 10 },
                { text: 'Descripción', fontSize: 10 },
                { text: 'Cantidad', fontSize: 10 },
                { text: 'Precio', fontSize: 10 },
                { text: 'Importe', fontSize: 10 },
              ],
              [
                { text: '20176515', fontSize: 10 },
                { text: 'MOPADUR AZUL S/M-P CSA BRILLO', fontSize: 10 },
                { text: '100,00 kg', fontSize: 10 },
                { text: '20,00 €/kg', fontSize: 10 },
                { text: '2000 €', fontSize: 10 },
              ],
              [
                { text: '20176515', fontSize: 10 },
                { text: 'MOPADUR AZUL S/M-P CSA MATE', fontSize: 10 },
                { text: '100,00 kg', fontSize: 10 },
                { text: '20,00 €/kg', fontSize: 10 },
                { text: '2000 €', fontSize: 10 },
              ],
            ],
            styles: {
              fontSize: 8,
            },
          },
        },
        {
          columns: [
            {
              stack: [
                { text: 'Base imponible', style: 'textoNormal' },
                { text: 'IVA (21%)', style: 'textoNormal' },
                { text: 'Recargo de equivalencia', style: 'textoNormal' },
              ],
              alignment: 'right',
              width: '85%',
            },
            {
              stack: [
                { text: '4000', style: 'textoNormal' },
                { text: '840', style: 'textoNormal' },
                { text: '', style: 'textoNormal' },
              ],
              alignment: 'right',
              width: '15%',
            },
          ],
          margin: [0, 20, 0, 0],
        },
        {
          canvas: [
            {
              type: 'line',
              x1: 0,
              y1: 0,
              x2: 200,
              y2: 0,
              lineWidth: 1,
              lineColor: '#000000',
            },
          ],
          margin: [0, 0, 0, 10],
          alignment: 'right',
        },
        {
          columns: [
            {
              stack: [{ text: 'TOTAL FACTURA:', style: 'subheader' }],
              alignment: 'right',
              width: '85%',
            },
            {
              stack: [{ text: '4840', style: 'subheader' }],
              alignment: 'right',
              width: '15%',
            },
          ],
          margin: [0, 5, 0, 0],
        },
      ],
      styles: {
        header: {
          fontSize: 20,
          bold: true,
          margin: [0, 0, 0, 10],
        },
        subheader: {
          fontSize: 10,
          bold: true,
          margin: [0, 0, 0, 5],
        },
        textoNormal: {
          fontSize: 10,
          margin: [0, 0, 0, 5],
        },
        textoFooterRight: {
          fontSize: 7,
          margin: [0, 0, 0, 5],
        },
        textoGrande: {
          fontSize: 14,
          margin: [0, 0, 0, 5],
        },
        textoSmall: {
          fontSize: 6,
          margin: [0, 0, 0, 5],
        },
        textoSmallFooter: {
          fontSize: 5,
          margin: [0, 0, 0, 5],
        },
        textoSmallBold: {
          bold: true,
          fontSize: 6,
          margin: [0, 0, 0, 5],
        },
        textoSmallBoldFooter: {
          bold: true,
          fontSize: 5,
          margin: [0, 0, 0, 5],
        },
        textoMedium: {
          fontSize: 8,
          margin: [0, 0, 0, 5],
        },
        table: {
          margin: [0, 5, 0, 15],
        },
      },
    };
    pdfMake.tableLayouts = {
      tableLayout: {
        margin: [0, -60, 0, 0],
        fontSize: 10,
        fillColor: function (rowIndex, node, columnIndex) {
          return rowIndex === 0 ? '#f1f1f0' : null;
        },
        vLineWidth: function (i, node) {
          return 1; // dibujar bordes verticales en todas las celdas
        },
        hLineWidth: function (i, node) {
          return i === node.table.body.length || i === 0 ? 1 : 0; // dibujar bordes horizontales en todas las celdas
        },
        hLineColor: function (i, node) {
          return '#bfbfbf'; // color del borde horizontal
        },
        vLineColor: function (i, node) {
          return '#bfbfbf'; // color del borde vertical
        },
        paddingLeft: function (i, node) {
          return 10; // espacio interno de la celda izquierda
        },
        paddingRight: function (i, node) {
          return 10; // espacio interno de la celda derecha
        },
        paddingTop: function (i, node) {
          return 5; // espacio interno de la celda superior
        },
        paddingBottom: function (i, node) {
          return 5; // espacio interno de la celda inferior
        },
      },
    };
    pdfMake.createPdf(docDefinition).download('factura.pdf');
  }
  getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute('crossOrigin', 'anonymous');

      img.onload = () => {
        var canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;

        var ctx = canvas.getContext('2d');
        ctx!.drawImage(img, 0, 0);

        var dataURL = canvas.toDataURL('image/png');

        resolve(dataURL);
      };
      img.onerror = (error) => {
        reject(error);
      };
      img.src = url;
    });
  }

  /**
   * API CALLS
   */

  getAllNotices() {
    this.noticeService.getAll({
      num_devoluciones: 8,
      num_pagina: 1,
      isActive: true,
    });
    this.noticeService
      .getResult()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        let { data, total } = res.data;
        this.ultimosMovimientos = data.map((item: any) => {
          switch (item.category.id) {
            case 1:
              return { ...item, color: '#FF5664' };
              break;
            case 2:
              return { ...item, color: '#009E9E' };
              break;
            case 3:
              return { ...item, color: '#0083F1' };
              break;
            case 4:
              return { ...item, color: '#FF8000' };
              break;
            case 5:
              return { ...item, color: '#C50505' };
              break;
            case 6:
              return { ...item, color: '#11AC2F' };
              break;
          }
        });

        this._unsubInd.next('');
      });
  }
}
