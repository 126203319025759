import { Component, OnInit,Inject, } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-LoginLoading',
  templateUrl: './LoginLoading.component.html',
  styleUrls: ['./LoginLoading.component.scss']
})
export class LoginLoadingComponent implements OnInit {
  width=0;
  interval:NodeJS.Timeout | undefined;

  constructor(public dialogRef: MatDialogRef<LoginLoadingComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any) { }

  ngOnInit() {
    setTimeout(()=>{
      this.openDashboard();
    },1500);
  }
  openDashboard(){
    this.dialogRef.close();
  }

}
