import { iBaseObject } from "@quasar_dynamics/basic-designsystem";
import { iMeasurementUnit } from "./iMeasurementUnit";
import { iTypeProduct } from "./iTypeProduct";

export interface iContainer {
    id: number,
    code?: string,
    description?: string,
    occKg?: number,
    occLiters?: number,
    cost?: number,
    note?: string,
    measurementUnit?: any,
    typeProduct?: any,
}

export class iContainer extends iBaseObject {
    static convertFromCollection(ob: any): Array<iContainer> {
        let iContainerCollection: Array<iContainer> = [];
        ob.forEach((element: any) => {
            iContainerCollection.push(this.convertFromObject(element));
        });
        return iContainerCollection;
    }

    static convertFromObject(ob: any): iContainer {
        let iContainerObj = new iContainer();
        iContainerObj.id = ob.id;
        iContainerObj.code = ob.code;
        iContainerObj.description = ob.description;
        iContainerObj.occKg = ob.occKg;
        iContainerObj.occLiters = ob.occLiters;
        iContainerObj.cost = ob.cost;
        iContainerObj.note = ob.note;
        iContainerObj.measurementUnit = ob.measurementUnit ? ob.measurementUnit : {};
        iContainerObj.typeProduct = ob.typeProduct ? ob.typeProduct : {};
        return iContainerObj;
    }

    static createEmptyObject(): iContainer {
        let iContainerObj = new iContainer();
        iContainerObj.code = '';
        iContainerObj.description = '';
        iContainerObj.occKg = -1;
        iContainerObj.occLiters = -1;
        iContainerObj.cost = -1;
        iContainerObj.note = '';
        iContainerObj.measurementUnit = {};
        iContainerObj.typeProduct = {};
        return iContainerObj;
    }
}

export interface iContainerFilter {
    num_devoluciones: number;
    num_pagina: number;
    genericFilter?: string;
    code?: string,
    description?: string,
    occKg?: number,
    occLiters?: number,
    cost?: number,
    note?: string,
    measurementUnit?: number,
    typeProduct?: number,
}