<div class="section">
  <pill class="minHeightPillsIconos">
    <ng-container>
      <div class="iconsAndContent">
        <div class="icons">
          <ng-container>
            <mat-icon class="outline">
              edit
            </mat-icon>
          </ng-container>
        </div>
        <div class="content">

          <div class="espacioParrafo" *ngFor="let info of informacionComercial">
            <span class="bold">{{info.label}} </span>
            <p> {{info.value}}</p>
          </div>
        </div>
      </div>
    </ng-container>
  </pill>
</div>