<div class="fullPill">
  <div class="iconLine">
  </div>
  <div class="headerRow">
    <p class="bold">Código material</p>
    <p class="bold">Código secundario</p>
    <p class="bold">Descripción</p>
    <p class="bold">Cantidad</p>
  </div>

  <div class="contentRow" *ngFor="let data of dataForTable">
    <p *ngIf="!data.indication">{{data.articleCode || data.rawCode}}</p>
    <p *ngIf="!data.indication">{{data.rawSecondaryCode}}</p>
    <p *ngIf="!data.indication">{{data.articleName || data.rawName}} </p>
    <CustomInput [type]="'number'" classList="fullWidth tall special" *ngIf="!data.indication" [(ngModel)]="data.batchAmount" (keyup)="checkIsComplete()" ></CustomInput>
    <br *ngIf="data.indication">
  </div>
  <div class="total">
    <p class="bold">TOTAL : </p>
    <CustomInput [type]="'number'" classList="fullWidth tall special"[(ngModel)]="total" (keyup)="checkIsComplete()" ></CustomInput>
  </div>

  <div class="observacionesFinales">
    <p class="bold">Observaciones Finales:</p>
    <p>{{observacionesFinales}}</p>
  </div>
</div>
