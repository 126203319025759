<FullContainer>
  <div class="fullView">
    <div class="headerAndToggle">
      <div class="header">
        <p>ACCIONES COMERCIALES</p>
      </div>
      <div class="toggle">
        <CustomToggle [selected]="selected" [selectores]="selectores" (selectedOption)="selected = $event" (addNew)="create()">
        </CustomToggle>
      </div>
    </div>
    <filterActions (addNew)="create()">
      <ng-container filterContent>
        <div class="containerSelectores">
          <div>
            <selector></selector>
          </div>
          <div>
            <datepicker [clearable]="true"></datepicker>
          </div>
          <div>
            <datepicker [clearable]="true"></datepicker>
          </div>
          <div>
            <selector></selector>
          </div>
          <div>
            <selector></selector>
          </div>
          <div>
            <selector></selector>
          </div>
          <div>
            <selector></selector>
          </div>
        </div>
      </ng-container>
    </filterActions>
    <crmAccionesComecialesLista *ngIf="selected == 0"></crmAccionesComecialesLista>
  </div>

</FullContainer>
