import { iBaseObject } from "@quasar_dynamics/basic-designsystem";

export interface iMaintenance {
    id: number,
    category?: string,
    entity?: string,
}

export class iMaintenance extends iBaseObject {
    static convertFromCollection(ob: any): Array<iMaintenance> {
        let iMaintenanceCollection: Array<iMaintenance> = [];
        ob.forEach((element: any) => {
            iMaintenanceCollection.push(this.convertFromObject(element));
        });
        return iMaintenanceCollection;
    }

    static convertFromObject(ob: any): iMaintenance {
        let iMaintenanceObj = new iMaintenance();
        iMaintenanceObj.id = ob.id;
        iMaintenanceObj.category = ob.category;
        iMaintenanceObj.entity = ob.entity;
        return iMaintenanceObj;
    }

    static createEmptyObject(): iMaintenance {
        let iMaintenanceObj = new iMaintenance();
        iMaintenanceObj.category = '';
        iMaintenanceObj.entity = '';
        return iMaintenanceObj;
    }
}

export interface iMaintenanceFilter {
    num_devoluciones: number;
    num_pagina: number;
    genericFilter?: string;
    category?: string,
    entity?: string,
}