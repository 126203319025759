import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { iOptionsSelector, iUnsubscribeDestroy } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { FungibleService } from 'src/app/Services/Api/Fungible.service';

@Component({
  selector: 'nuevaFormulaStep5',
  templateUrl: './NuevaFormula-Step5.component.html',
  styleUrls: ['./NuevaFormula-Step5.component.scss']
})
export class NuevaFormulaStep5Component extends iUnsubscribeDestroy implements OnInit {

  @Input() fungibleFlag: boolean = false;
  @Input() step5Data: any = null;
  @Input() toEditData: any = null;
  @Output() isCompleted: EventEmitter<any> = new EventEmitter<any>();

  observaciones: string = ""
  materialSelected: any = null;
  arrayLineasTabla: Array<any> = [
    {  id: null,descripcion: '', notas: ''},
  ];
  opcionesFungibles: iOptionsSelector = {
    items: [
      { id: 1,descripcion: 'Viscosidad', notas: 'Esto es un ejemplo'},
      { id: 2, descripcion: 'Densidad', notas: "Esto es un ejemplo"},
      { id: 3, descripcion: 'Materia fija', notas: "Esto es un ejemplo"},

    ],
    clearable: false,
    search: true,
    placeholder: "Fungibles",
    append: true,
    bindLabel: "descripcion"
  }
  constructor(private fungibleSE:FungibleService) {
    super();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes["fungibleFlag"] != undefined) {
      if (changes["fungibleFlag"].currentValue != null && changes["fungibleFlag"].previousValue != undefined ) {
        this.generateRow()
      }
    }
    if (changes["toEditData"]){
      if(changes['toEditData'].currentValue){
        this.arrayLineasTabla = changes['toEditData'].currentValue.fungible.map((item:any)=>{return {id: item.id, descripcion: item.description, notas: item.note}})
        this.checkIsCompleted()
      }
    }

  }

  ngOnInit() {
    this.step5Data != null ? this.arrayLineasTabla = this.step5Data : null;
    this.getFingibles()
  }

  /**
   * GETTERS & SETTERS
   */
  getFungiblesSelected(event, linea){
    linea = event;
  }

  /**
   * FUNCTIONALITIES
   */
  checkIsCompleted() {
    this.isCompleted.emit(this.arrayLineasTabla)
  }
  generateRow(){
    this.arrayLineasTabla.push(    {  id: null,descripcion: '', notas: ''},
    )
  }

  removeRow(linea){
    this.arrayLineasTabla.splice(this.arrayLineasTabla.indexOf(linea), 1);
    this.checkIsCompleted();

  }

  /**
   * API CALLS
   */

  getFingibles(){
    this.fungibleSE.getAll({num_devoluciones: 1000,num_pagina: 1});
    this.fungibleSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(res => {
      if(!res){return};
      let {data, total} = res.data
      this.opcionesFungibles.items = data.map(item => {return {id:item.id, descripcion: item.description, notas: item.note}});
      this._unsubInd.next('')
    })
  }

}
