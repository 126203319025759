import { Component, OnInit } from '@angular/core';
import { ChartConfiguration, ChartType } from 'chart.js';

@Component({
  selector: 'singleLineChart',
  templateUrl: './SingleLineChart.component.html',
  styleUrls: ['./SingleLineChart.component.scss']
})
export class SingleLineChartComponent implements OnInit {
  public lineChartData: ChartConfiguration['data'] = {
    datasets: [
      {
        data: [ 65, 59, 80, 81, 56, 55, 40, 65, 59, 80, 81, 56 ],
        label: 'Series A',
        backgroundColor: 'transparent',
        borderColor: '#FF5664',
        pointBackgroundColor: '#FF5664',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(148,159,177,0.8)',
        fill: 'origin',
      }
    ],
    labels: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiemnbre", "Octubre", "Noviembre", "Diciembre"]
  };

  public lineChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    elements: {
      line: {
        tension: 0.5
      }
    },
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      y:
        {
          position: 'left',
        },

    },

    plugins: {
      legend: { display: false },
    }
  };

  public lineChartType: ChartType = 'line';
  constructor() { }

  ngOnInit() {
  }

}
