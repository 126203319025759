import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { PopupService, iUnsubscribeDestroy } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { DocumentosPopupComponent } from 'src/app/Popups/Documentos-Popup/Documentos-Popup.component';
import { SubirDocumentoPopupComponent } from 'src/app/Popups/SubirDocumento-Popup/SubirDocumento-Popup.component';
import { FormulaService } from 'src/app/Services/Api/Formula.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'ERP-PP-Formulas-DV-Ficha',
  templateUrl: './ERP-PP-Formulas-DV-Ficha.component.html',
  styleUrls: ['./ERP-PP-Formulas-DV-Ficha.component.scss']
})
export class ERPPPFormulasDVFichaComponent extends iUnsubscribeDestroy implements OnInit {

  @Input() data: any = []
  @Output() reload = new EventEmitter();

  constructor(private popupSE: PopupService, private formulaSE:FormulaService,) {
    super();
  }

  updateData:any = {
    id: 0,
  }

  documentos: any = []

  ngOnInit() {
  }

  ngOnChanges(changes:SimpleChanges){
    if(changes['data']){
      if(Object.keys(changes['data'].currentValue).length > 0){
        this.updateData.id = changes['data'].currentValue.id
        this.documentos = changes['data'].currentValue.attacheds
      }
    }
  }

  /**
 * DOCUMENTOS
 */

  subirDocumento() {
    this.popupSE.openPopup(SubirDocumentoPopupComponent, { formatos: ['image/png', 'image/jpeg', 'application/pdf'], size: 10485760 });
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe((data) => {
      if (!data) return;
      this.updateData.attacheds = data['returnValue'].map((item) => item.base64);
      this.editFormula()
      this._unsubInd2.next("")
    })
  }

  verDocumentos(editar: boolean = false) {
    this.popupSE.openPopup(DocumentosPopupComponent, { editar: editar, documentos: this.data.attacheds, formulaId:this.data.id ,formula:true })
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe((data) => {
      if (!data) return;
      if(data['returnValue'] == 'delete'){
        this.reload.emit(true);
      }
      this.updateData.attacheds = data['returnValue']?.map((item) => item.base64);
      this.editFormula()
      this._unsubInd2.next("")
    })
  }


  /**
   * EDIT
   */

  editFormula() {
    this.formulaSE.update(this.updateData);
    this.formulaSE.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe((res) => {
      if (!res) return;
      StaticUtilitiesService.showFeedback('Formula actualizada correctamente')
      this.reload.emit(true);
      this._unsubInd.next("")
    })
    this.formulaSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res) => {
      if (!res) return;
      StaticUtilitiesService.showError('Error al actualizar la formula')
      this._unsub.next("")
    })
   }

   editFormulaPopup(){}

}
