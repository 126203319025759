<div class="proveedores">
  <filterActions (addNew)="create()" (searchSend)="search($event)">
    <ng-container filterContent>
      <div class="containerSelectores">
        <div>
          <selector [options]="opcionesFamilia" [(ngModel)]="familias" (change)="getSelector($event, 'family')"></selector>
        </div>
        <div>
          <selector [options]="opcionesSubFamilia" [(ngModel)]="subFamilias" (change)="getSelector($event, 'subfamily')"></selector>
        </div>
      </div>
    </ng-container>
  </filterActions>
  <Pagination [paginacionTotal]="totalPaginacion" (change)="changePagination($event)"></Pagination>
  <pill class="tablePil" classList="tablePill">
    <dataTable [data]="dataForTableFiltered" [forceUpdateWidth]="_reloadTableWidth" [isLoading]="isLoading" (clickRow)="clickRow($event)" [options]="optionsTabla" [displayedColumns]="displayedColumnsTabla" (edit)="edit($event)" (delete)="delete($event,'single')" (deleteAll)="delete($event,'all')" (deleteSelected)="delete($event,'selected')"></dataTable>
  </pill>
</div>
