<div class="popupGeneralContainer">
  <div class="header">
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
    <p>Editar tarifas</p>
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
  </div>
  <div class="body">
    <div class="doubleColumn">
      <CustomInput [readonly]="modalData.accion=='ver'" classList="fullWidth tall special"[type]="'number'"  placeholder="Tarifa 1" label="Tarifa 1" [(ngModel)]="tarifa1" (change)="modalData.accion ==='editar'? editSecction(tarifa1, 'rateOne') :checkIsCompleted()"></CustomInput>
      <CustomInput [readonly]="modalData.accion=='ver'" classList="fullWidth tall special" [type]="'number'"  placeholder="Tarifa 2" label="Tarifa 2" [(ngModel)]="tarifa2" name="tarifa2" (change)="modalData.accion ==='editar'? editSecction(tarifa2, 'rateTwo') :checkIsCompleted()"></CustomInput>
    </div>

  </div>
  <div class="footer" *ngIf="modalData.accion!='ver'">
    <btn classList="secundario" (click)="forceClosePopup()">
      <ng-container>
       Cancelar
      </ng-container>
    </btn>
    <btn classList="secundaryButton" (click)="closePopup()" [class.disabled]="!isCompleted">
      <ng-container>
        Editar
      </ng-container>
    </btn>
  </div>
</div>
