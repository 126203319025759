import { Component, OnInit } from '@angular/core';
import { iUnsubscribeDestroy, PopupService, slideNavigationTypes } from '@quasar_dynamics/basic-designsystem';
import { ConfirmPopupComponent } from 'src/app/Popups/ConfirmPopup/ConfirmPopup.component';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';
import { takeUntil } from 'rxjs';
import { ProductionAreaService } from 'src/app/Services/Api/ProductionArea.service';
import { ProductionReportService } from 'src/app/Services/Api/ProductionReport.service';
import { CierreDeFabricacionPopupComponent } from 'src/app/Popups/CierreDeFabricacion-Popup/CierreDeFabricacion-Popup.component';

@Component({
  selector: 'app-ERP-PP-PartesProduccion-DetailView',
  templateUrl: './ERP-PP-PartesProduccion-DetailView.component.html',
  styleUrls: ['./ERP-PP-PartesProduccion-DetailView.component.scss']
})
export class ERPPPPartesProduccionDetailViewComponent extends iUnsubscribeDestroy implements OnInit {

  parteId!:number
  openAccordeon:boolean = false
  codeParte:string = 'Cargando...'

  constructor( private staticUtilities:StaticUtilitiesService, private popupSE:PopupService, private productionReportSE:ProductionReportService ) {
    super();
  }

  ngOnInit() {
    this.getParams()
    this.addLineHeaderAccordion()
  }
  
  getParams(){
    if(!this.staticUtilities.getParams().parte || this.staticUtilities.getParams().parte <= 0){
      StaticUtilitiesService.showError('No se ha encontrado el parte de produccion')
      this.staticUtilities.goTo('erp/partesproduccion/', slideNavigationTypes.slideToBottom)
      return;
    }
    this.parteId = this.staticUtilities.getParams().parte
  }
  
  goBack(){
    this.staticUtilities.goTo('erp/partesproduccion/', slideNavigationTypes.slideToBottom)
  }
  
  addLineHeaderAccordion(){
    let header = (document.querySelector("body > app-root > main > app-erp-pp-partesproduccion-detailview > fullcontainer > div > div > div > div:nth-child(5) > accordion > div > div.accorHeader") as HTMLElement)
    header.style.position = "relative"
    let spanToAdd = document.createElement("span")
    spanToAdd.innerHTML = "FUNGIBLES"
    spanToAdd.style.position = "absolute"
    spanToAdd.style.left = "50%"
    header.appendChild(spanToAdd)
  }

  cierreDeFabricacion(){
    this.popupSE.openPopup(CierreDeFabricacionPopupComponent, { parteId:this.parteId,});
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(res=>{
      if(!res) {return};
      this._unsubInd.next('');
    })
  }
  
  printScreen(){
    this.openAccordions()
    setTimeout(() => {
      window.print();
    }, 100);
  }
  openAccordions(){
    this.openAccordeon = true
    setTimeout(() => {
      this.openAccordeon = false
    }, 200);
  }
  codeProductionReport(event){
    this.codeParte = event
  }



}
