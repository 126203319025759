<FullContainer>
  <div class="dashboardGeneralContainer">
    <div class="cardsGeneralContaier">
      <!-- <button (click)="generatePDFFactura()">hreoigre</button> -->
      <pill *ngFor="let navegacion of navegationERP" classList="erpDashboardPill"
        (click)="navigateTo(navegacion.router)">
        <ng-container>
          <div class="container">
            <div [ngStyle]="{'background': navegacion.color}"></div>
            <span>{{navegacion.label}}</span>
          </div>
        </ng-container>
      </pill>
    </div>
    <div class="ultimosMovimientosGeneralContainer">
      <pill>
        <ng-container>
          <div class="ultimosMovimientos">
            <p>ÚLTIMOS MOVIMIENTOS</p>
            <div class="movimientos">
              <div class="movimiento" *ngFor="let ultimoMovimiento of ultimosMovimientos">
                <div [ngStyle]="{'background': ultimoMovimiento.color}"></div>
                <span>{{ultimoMovimiento.description}}</span>
              </div>
            </div>
          </div>
        </ng-container>
      </pill>
    </div>
  </div>
</FullContainer>