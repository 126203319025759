import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { iDate, iOptionsSelector, iUnsubscribeDestroy } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { FormulaService } from 'src/app/Services/Api/Formula.service';
import { ProductionReportService } from 'src/app/Services/Api/ProductionReport.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';
import { iFormulaFilter } from 'src/app/Shared/Interfaces/iFormula';

@Component({
  selector: 'NuevoParteDeProduccion-Popup',
  templateUrl: './NuevoParteDeProduccion-Popup.component.html',
  styleUrls: ['./NuevoParteDeProduccion-Popup.component.scss']
})
export class NuevoParteDeProduccionPopupComponent extends iUnsubscribeDestroy implements OnInit {

  selected:number = 0;
  isCompleted: boolean = false;
  dataFicha:any = {
    codigoParte: iDate.javascriptConvert(new Date()).toStringDate('JAP').split('-').join(''),
    producto: null,
    fechaEntrega: new Date(),
    cantidad: "",
    areaProduccion: null
  } 
  dataPlanificacion:any = null
  dataFichaEnvasado:any = []
  isCreateReady:boolean = false
  encabezadoData:any = null
  planificacionData:any = null

  constructor(public dialogRef: MatDialogRef<NuevoParteDeProduccionPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any, private formulaSE:FormulaService, private productionReportSE:ProductionReportService) {
    super();
  }

  ngOnInit() {
    if(this.modalData.accion === 'crear'){
      this.getProductionReportNumber()
    }
    if(this.modalData.accion === 'editar'){
      this.getParteEncabezadoDatos()
      this.getPartePlanificacionDatos()
    }
  }

  /**
   * GETTERS && SETTERS 
   */

  setter(){
    if(!this.encabezadoData || !this.planificacionData ){return}
     
     
    this.dataFicha.codigoParte = this.encabezadoData.code;
    this.dataFicha.producto = this.encabezadoData.formula.id;
    this.dataFicha.fechaEntrega = this.encabezadoData.deliveryDate;
    this.dataFicha.cantidad = this.encabezadoData.amount;
    this.dataFicha.areaProduccion = this.encabezadoData.productionArea;
    this.dataFichaEnvasado = {planificacionData:this.planificacionData, edit:true, densidad:this.encabezadoData.formula.density, cantidad:this.encabezadoData.amount};
  }

  /**
* FUNCTIONALITY
*/
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }

  isCompletedCheck(event){
    this.isCompleted = event;
  }

  changeSelected(event){
    this.selected = event;
  }
  dataFichaGet(event){
    this.dataFicha = {...event};
    this.dataFichaEnvasado = {...event};
  }
  createReady(event){
    this.isCreateReady = event
  }
  dataPlanificacionGet(event){
     
    this.dataPlanificacion = {...event}
  }

  /**
   * API CALLS
   */

  createParte(){
    let data:any = {
      code: this.dataFicha.codigoParte,
      deliveryDate: iDate.javascriptConvert(new Date(this.dataFicha.fechaEntrega)).toStringDate('JAP'),
      productionArea: this.dataFicha.areaProduccion,
      formula:this.dataPlanificacion.formula,
      amount: this.dataFicha.cantidad,
      articles:this.dataPlanificacion.containers.map(item=>{return {container:item.id, quantity:item.cantidadEnvasado, product: item.cantidadUnidad}}),
      isClosed:false,
    }
     
    this.createProductionReport(data)
  }

  getProductionReportNumber(){
    this.productionReportSE.getNumberReportToday();
    this.productionReportSE.getResultLastParteProduccion().pipe(takeUntil(this._unsubInd)).subscribe((res)=>{
      if(!res){return}
      this.dataFicha.codigoParte = iDate.javascriptConvert(new Date()).toStringDate('JAP').split('-').join('') + '-' + res.data;
      this._unsubInd.next('')
    })
  }

  createProductionReport(data){
    this.productionReportSE.create(data)
    this.productionReportSE.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe(res=>{
      if(!res){return};
      StaticUtilitiesService.showFeedback('Parte de producción creado correctamente')
      this.closePopup('create')
      this._unsubInd.next('')
    })
    this.productionReportSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe(res=>{
      if(!res){return};
      StaticUtilitiesService.showFeedback('El parte de producción no pudo ser creado')
      this._unsub.next('')
    })
  }
  

  /**
   * API CALLS
   */

  getParteEncabezadoDatos(){
    this.productionReportSE.getEncabezadoId(this.modalData.parteId);
    this.productionReportSE.getResultEncabezadoId().pipe(takeUntil(this._unsubInd)).subscribe(res => {
      if(!res){return}
      let {data} = res;
      this.encabezadoData = data;
      this.setter()
      this._unsubInd.next('')
    })
  }
  getPartePlanificacionDatos(){
    this.productionReportSE.getPackagingId(this.modalData.parteId);
    this.productionReportSE.getResultPackagingId().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
      if(!res){return}
      let {data} = res;
      this.planificacionData = data;
      this.setter()
      this._unsubInd2.next('')
    })
  }


}
