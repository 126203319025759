<div class="popupGeneralContainer">
  <div class="header">
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
    <p>{{modalData.accion}} {{modalData.elemento}}</p>
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
  </div>
  <div class="body">
    <p>¿Estás seguro que quieres {{modalData.accion.toLowerCase()}} {{modalData.elemento}}?</p>
    <p>Este paso no tiene vuelta atrás</p>
  </div>
  <div class="footer" *ngIf="!crm">
    <btn classList="secundario" (click)="forceClosePopup()">
      <ng-container>
       Cancelar
      </ng-container>
    </btn>
    <btn classList="secundaryButton" (click)="closePopup('delete')">
      <ng-container>
        {{modalData.accion}}
      </ng-container>
    </btn>
  </div>
  <div class="footer" *ngIf="crm">
    <btn classList="secundarioCrm"  (click)="forceClosePopup()">
      <ng-container>
       Cancelar
      </ng-container>
    </btn>
    <btn  classList="crm" (click)="closePopup()">
      <ng-container>
        {{modalData.accion}}
      </ng-container>
    </btn>
  </div>

</div>
