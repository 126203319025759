<div class="popupGeneralContainer">
  <div class="infoSteps">

    <p class="tituloStep2" *ngIf="selectorMap == 1"> PEDIDO: {{data.nPedido}}</p>

    <div class="line">
      <span class="number left" [ngClass]="{active: selectorMap === 0}">1</span>
      <span class="number medium" [ngClass]="{active: selectorMap === 1}">2</span>
      <span class="number right" [ngClass]="{active: selectorMap === 2}">3</span>
    </div>

    <mat-icon (click)="forceClosePopup()">close</mat-icon>
  </div>
  <div class="header">
    <p *ngIf="selectorMap == 0">Crear Pedido</p>
  </div>

  <!-- SECTION 1 -->

  <div class="body" *ngIf="selectorMap === 0">
    <CustomInput [readonly]="true" classList="fullWidth tall special" placeholder="Número de pedido"
      label="Número de pedido" [(ngModel)]="data.nPedido" (change)="checkFirstSection()"></CustomInput>
    <div class="customContainer">
      <label for="Pais">Fecha pedido</label>
      <datepicker (dataFromPicker)="dataFromPicker($event)"></datepicker>
    </div>
    <div class="customContainer">
      <label for="Pais">Cliente</label>
      <selector [options]="opcionesClientes" [(ngModel)]="data.cliente" (change)="clienteSelector($event)"></selector>
    </div>
    <br>
    <CustomInput [readonly]="true" classList="fullWidth tall special" placeholder="Email pedidos" label="Email pedidos"
      [(ngModel)]="clienteSelected.emailPedidos" (change)="checkFirstSection()" *ngIf="isClienteSelected"></CustomInput>
    <CustomInput [readonly]="true" classList="fullWidth tall special" placeholder="Forma de pago" label="Forma de pago"
      [(ngModel)]="clienteSelected.formaDePago" (change)="checkFirstSection()" *ngIf="isClienteSelected"></CustomInput>
    <CustomInput [readonly]="true" classList="fullWidth tall special" placeholder="Tipo portes" label="Tipo portes"
      [(ngModel)]="clienteSelected.tipoPortes" (change)="checkFirstSection()" *ngIf="isClienteSelected"></CustomInput>
    <CustomInput [readonly]="true" classList="fullWidth tall special" placeholder="Tipo de IVA" label="Tipo de IVA"
      [(ngModel)]="clienteSelected.tipoIva" (change)="checkFirstSection()" *ngIf="isClienteSelected"></CustomInput>
    <div class="customContainer" *ngIf="isClienteSelected">
      <label for="Pais">Direcciones</label>
      <selector [options]="opcionesDirecciones" [(ngModel)]="data.direccion" (change)="checkFirstSection()"></selector>
    </div>
  </div>

  <div class="tablaPedido" *ngIf="selectorMap == 1">

    <filterActions [disableFilter]="true" [disableSearch]="true" (addNew)="newLine()"></filterActions>

    <!-- SECTION 2 -->

    <pill class="tablePil" classList="tablePill">
      <div class="headerTable">
        <p class="headerTableP">Artículo</p>
        <p class="headerTableP">Descripción</p>
        <p class="headerTableP">Cantidad</p>
        <p class="headerTableP">Precio</p>
        <p class="headerTableP">Subtotal</p>
      </div>
      <div class="bodyTable" *ngFor="let linea of arrayLineasTabla; let index = index">
        <div class="contenedor">
          <selector [options]="opcionesArticulos" [(ngModel)]="linea.articulo" (change)="getArticulo($event,index)">
          </selector>
        </div>
        <div class="contenedor">
          <CustomInput classList="fullWidth tall special" placeholder="Descripcion" [(ngModel)]="linea.descripcion"
            (change)="checkSecondSection()"></CustomInput>
        </div>
        <div class="contenedor">
          <CustomInput [type]="'number'" classList="fullWidth tall special" placeholder="Cantidad"
            [(ngModel)]="linea.cantidad" (change)="sumSubtotal(index)"></CustomInput>
        </div>
        <p>{{linea.precio}} €</p>
        <div class="contenedor">
          <div class="customMasEur" *ngIf="!linea.articulo">
            <CustomInput [type]="'number'" [readonly]="linea.articulo" classList="fullWidth tall special"
              placeholder="Cantidad" [(ngModel)]="linea.subtotal" (change)="getBaseImponible()"></CustomInput>
            <p>€</p>
          </div>
          <p *ngIf="linea.articulo">{{linea.subtotal.toFixed(2) | currency: 'EUR'}}</p>
        </div>
        <span class="closeIcon" *ngIf="arrayLineasTabla.length > 1">
          <mat-icon (click)="deleteLine(index)">close</mat-icon>
        </span>
      </div>

      <div class="calculo">
        <div class="notas">
          <CustomTextArea placeHolder="Notas" label="Notas" [(ngModel)]="notas" name="notas" class="height20vh">
          </CustomTextArea>
          <p class="bold">Riesgo máximo {{maxRisk}}</p>
        </div>
        <div class="tablaCalculo">
          <div class="row">
            <div class="col">
              <p class="header">Base imponible</p>
            </div>
            <div class="col value">
              <p>{{baseImponible.toFixed(2) | currency: 'EUR'}} </p>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <p class="header">IVA ({{clienteSelected.valorIva | percent}})</p>
            </div>
            <div class="col value">
              <p>{{plusIva.toFixed(2) | currency: 'EUR'}} </p>
            </div>
          </div>
          <div class="row" *ngIf="clienteSelected.recargoDeEquivalencia">
            <div class="col">
              <p class="header">Recargo de equivalencia</p>
            </div>
            <div class="col value">
              <CustomInput [type]="'number'" classList="fullWidth tall special" placeholder="Recargo de equivalencia"
                [(ngModel)]="recargoEquivalencia" (change)="getBaseImponible()"></CustomInput>
            </div>
          </div>
          <div class="row total">
            <div class="col">
              <p class="header">TOTAL</p>
            </div>
            <div class="col value">
              <p>{{total.toFixed(2) | currency: 'EUR'}} </p>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="riesgoTotal">
        <div class="riesgoTotalCLiente">
          <p class="riesgoTotalHeader">Riesgo total del cliente: </p>
          <p class="riesgoTotalValue">8.425,00 € (Fuera de riesgo)</p>
        </div>
        <div class="leyenda">
          <p>T: tarifa</p>
          <p>F: ficha</p>
        </div>
  
      </div> -->
    </pill>
  </div>


  <!-- SECTION 3 -->

  <div class="tablaPedido" *ngIf="selectorMap == 2">

    <div class="headerSection">
      <div class="firstPill">
        <pill class="minHeightPills">
          <ng-container>
            <div class="grid">
              <p><span class="bold">Número pedido : </span> {{data.nPedido}}</p>
              <p><span class="bold">Fecha pedido : </span> {{data.fechaPedido}}</p>
              <p><span class="bold">Cliente : </span> {{clienteSelected.nombre}}</p>
              <p><span class="bold">Email pedidos : </span> {{clienteSelected.emailPedidos}}</p>
              <p><span class="bold">Forma de pago : </span> {{clienteSelected.formaDePago}}</p>
              <p><span class="bold">Tipo portes : </span> {{clienteSelected.tipoPortes}}</p>
            </div>
          </ng-container>
        </pill>
      </div>
      <div class="secondPill">
        <pill class="minHeightPills">
          <ng-container>
            <p><span class="bold">Dirección de envío : </span> {{data.address.label}}</p>
          </ng-container>
        </pill>
      </div>

    </div>

    <pill class="tablePil" classList="tablePill">
      <dataTable [data]="arrayLineasTabla" [isLoading]="isLoading" [options]="optionsTabla"
        [displayedColumns]="displayedColumnsTabla"></dataTable>
      <div class="calculo">
        <div class="notas">
          <label>Notas</label>
          <p>{{notas}}</p>
        </div>
        <div class="tablaCalculo">
          <div class="row">
            <div class="col">
              <p class="header">Base imponible</p>
            </div>
            <div class="col value">
              <p>{{baseImponible.toFixed(2) | currency: 'EUR'}} </p>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <p class="header">IVA ({{clienteSelected.valorIva | percent}})</p>
            </div>
            <div class="col value">
              <p>{{plusIva.toFixed(2) | currency: 'EUR'}} </p>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <p class="header">Recargo de equivalencia</p>
            </div>
            <div class="col value">
              <p>{{recargoEquivalencia.toFixed(2) | currency: 'EUR'}}</p>
            </div>
          </div>
          <div class="row total">
            <div class="col">
              <p class="header">TOTAL</p>
            </div>
            <div class="col value">
              <p class="bold">{{total.toFixed(2) | currency: 'EUR'}} </p>
            </div>
          </div>
        </div>
      </div>

    </pill>
  </div>

  <!-- FOOTER -->

  <div class="footer" *ngIf="modalData.accion!='ver'">
    <btn classList="secundario" (click)="forceClosePopup()" *ngIf="selectorMap === 0">
      <ng-container>
        Cancelar
      </ng-container>
    </btn>
    <btn classList="secundario" (click)="previousSection()" *ngIf="selectorMap !== 0">
      <ng-container>
        Previo
      </ng-container>
    </btn>
    <btn classList="secundaryButton" (click)="nextSection()"
      [class.disabled]="(selectorMap ===  0 && !isFirstSectionCompleted) || (selectorMap === 1 && !isSecondSectionCompleted)"
      *ngIf="selectorMap !== 2">
      <ng-container *ngIf="modalData.accion=='crear' || modalData.accion=='editar'">
        Siguiente
      </ng-container>
    </btn>
    <btn classList="secundaryButton" (click)="create()" [class.disabled]="!isCompleted && selectorMap !== 2"
      *ngIf="selectorMap == 2">
      <ng-container *ngIf="modalData.accion=='crear' || modalData.accion=='editar'">
        Enviar email
      </ng-container>
    </btn>
  </div>
</div>