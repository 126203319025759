<div class="fullAccordion">

  <pill class="tablePil" classList="tablePill">
    <dataTable [data]="dataForTable" [isLoading]="isLoading" [options]="optionsTabla"
      [displayedColumns]="displayedColumnsTabla" [forceUpdateWidth]="forceUpdateWidth" ></dataTable>
    <div class="calculo">





      <div class="notas">
        <p>NOTAS :</p>
        <p>{{data.note}}</p>
      </div>





      <div class="tablaCalculo">
        <div class="row">
          <div class="col">
            <p class="header">Base imponible</p>
          </div>
          <div class="col value">
            <p>{{baseImponible | currency: 'EUR'}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p class="header">IVA ({{data?.company?.paymentMethods[0]?.typeIva?.value | percent }})</p>
          </div>
          <div class="col value">
            <p>{{plusIva | currency: 'EUR'}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p class="header">IRPF</p>
          </div>
          <div class="col value">
            <p></p>
          </div>
        </div>
        <div class="row total">
          <div class="col">
            <p class="header">TOTAL</p>
          </div>
          <div class="col value">
            <p>{{total | currency: 'EUR'}}</p>
          </div>
        </div>
      </div>

    </div>

  </pill>

</div>
