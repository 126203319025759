<div class="fichaGeneralContainer">
  <pill>
    <ng-container>
      <div class="info">
        <span class="material-icons icon" (click)="edit(data, 'datos')">
          edit
        </span>
        <div class="row">
          <div class="column">
            <label for="">Código:</label>
            <span>{{code}}</span>
          </div>
          <div class="column">
            <label for="">Nombre:</label>
            <span>{{name}}</span>
          </div>
        </div>
        <div class="row">
          <div class="column">
            <label for="">N.I.F:</label>
            <span>{{nif}}</span>
          </div>
          <div class="column">
            <label for="">Dirección:</label>
            <span>{{street}}</span>
          </div>
        </div>
        <div class="row">
          <div class="column">
            <label for="">Municipio:</label>
            <span>{{municipality}}</span>
          </div>
          <div class="column">
            <label for="">Código postal:</label>
            <span>{{postalCode}}</span>
          </div>
        </div>
        <div class="row">
          <div class="column">
            <label for="">Provincia:</label>
            <span>{{province}}</span>
          </div>
          <div class="column">
            <label for="">País:</label>
            <span>{{country}}</span>
          </div>
        </div>
        <div class="row">
          <div class="column special">
            <label for="">Teléfonos:</label>
            <div>
              <span *ngIf="!telephone1 && !telephone2 && !telephone3">-</span>
              <span class="pill" *ngIf="telephone1">{{telephone1}}</span>
              <span class="pill" *ngIf="telephone2">{{telephone2}}</span>
              <span class="pill" *ngIf="telephone3">{{telephone3}}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="column special">
            <label for="">E-mail:</label>
            <div>
              <span *ngIf="!email1 && !email2">-</span>
              <span class="pill" *ngIf="email1">{{email1}}</span>
              <span class="pill" *ngIf="email2">{{email2}}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="column special">
            <label for="">E-mail pedidos:</label>
            <div>
              <span *ngIf="!orderEmail">-</span>
              <span class="pill" *ngIf="orderEmail">{{orderEmail}}</span>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </pill>
  <pill>
    <ng-container>
      <div class="info">
        <span class="material-icons icon" (click)="edit(data, 'pago')">
          edit
        </span>
        <div class="row">
          <div class="column special">
            <label for="">Forma de pago:</label>
            <span>{{wayToPay}}</span>
          </div>
        </div>
        <div class="row">
          <div class="column special">
            <label for="">Días fijos:</label>
            <span>{{fixedDays}}</span>
          </div>
        </div>
        <div class="row">
          <div class="column special">
            <label for="">IBAN:</label>
            <span>{{iban}}</span>
          </div>
        </div>
        <div class="row">
          <div class="column special">
            <label for="">BIC:</label>
            <span>{{bic}}</span>
          </div>
        </div>
        <div class="row">
          <div class="column special">
            <label for="">Retención IRPF:</label>
            <span>{{irpf}}</span>
          </div>
        </div>
        <div class="row">
          <div class="column special">
            <label for="">Tipo IVA:</label>
            <span>{{typeIva}}</span>
          </div>
        </div>
        <div class="row">
          <div class="column special">
            <label for="">Tipo portes:</label>
            <span>{{porte}}</span>
          </div>
        </div>
      </div>
    </ng-container>
  </pill>
  <pill>
    <ng-container>
      <div class="info" (click)="edit(data, 'observaciones')">
        <span class="material-icons icon">
          edit
        </span>
        <div class="row">
          <div class="column special">
            <label for="">Observaciones:</label>
            <span *ngIf="observation != ''">{{observation}}</span>
            <p class="noData" *ngIf="observation == ''">No hay observaciones</p>
          </div>

        </div>
      </div>
    </ng-container>
  </pill>
  <pill>
    <ng-container>
      <div class="info">
        <div class="icons">
          <span class="material-icons icon" (click)="subirDocumento()">
            add
          </span>
          <span class="material-icons icon" (click)="verDocumentos(true)" [class.disabled]="documentos.length == 0">
            edit
          </span>
        </div>

        <div class="row">
          <div class="column special">
            <label for="">Adjuntos:</label>
            <div class="documentos" *ngIf="documentos.length > 0">
              <div class="documento" *ngFor="let documento of documentos | slice:0:2; let i = index;">
                <label for="">Documento {{i+1}}:</label>
                <a [href]="documento.doc" target="_blank">
                  <img src="/assets/Icons/pdf.svg" alt="">
                  <span>
                    {{documento.name}}
                  </span>
                </a>
              </div>
            </div>
            <p class="noData" *ngIf="documentos.length == 0">No hay adjuntos</p>
            <span class="verTodo" (click)="verDocumentos()" *ngIf="documentos.length > 0">Ver todos</span>
          </div>
        </div>
      </div>
    </ng-container>
  </pill>
</div>