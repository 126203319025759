import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  iDataTableColumns,
  iDataTableOptions,
  iDate,
  iOptionsSelector,
  iResultHttp,
  iUnsubscribeDestroy,
} from '@quasar_dynamics/basic-designsystem';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { CompanyService } from 'src/app/Services/Api/Company.service';
import { takeUntil } from 'rxjs';
import { OrderService } from 'src/app/Services/Api/Order.service';
import { RawMaterialService } from 'src/app/Services/Api/RawMaterial.service';

(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-NuevoProveedorPedido-Popup',
  templateUrl: './NuevoProveedorPedido-Popup.component.html',
  styleUrls: ['./NuevoProveedorPedido-Popup.component.scss'],
})
export class NuevoProveedorPedidoPopupComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  isCompleted: boolean = false;
  isFirstSectionCompleted: boolean = false;
  isSecondSectionCompleted: boolean = false;
  selectorMap = 0;
  irpfCompany: boolean = false;
  isClienteSelected: boolean = false;
  notas: string | null = null;
  data: any = {
    nPedido: null,
    fechaPedido: new Date().toLocaleDateString(),
    proveedor: null,
    direccion: null,
  };
  proveedorSelected: any = {
    emailPedidos: null,
    formaDePago: null,
    fechaDePago: null,
    tipoPortes: null,
    tipoIva: 21,
    retencionIRPF: true,
  };
  opcionesProveedor: iOptionsSelector = {
    items: [],
    clearable: true,
    search: true,
    placeholder: 'Proveedor',
    bindLabel: 'name',
    append: true,
  };

  /**
   *
   * Tabla
   *
   */
  proveedorId: number = 0;
  isLoading: boolean = false;
  baseImponible: any = 0;
  plusIva: any = 0;
  irpf: number = 0;
  total: number = 0;
  displayedColumnsTabla: Array<iDataTableColumns> = [
    { label: 'Artículo', property: 'articleName' },
    { label: 'Descripción', property: 'description' },
    { label: 'Cantidad', property: 'quantity', align: 'center' },
    {
      label: 'Precio',
      property: 'price',
      align: 'center',
      format: 'currency',
    },
    {
      label: 'Subtotal',
      property: 'subtotal',
      align: 'center',
      format: 'currency',
    },
  ];
  optionsTabla: iDataTableOptions = {
    isHovered: true,
    minHeight: 250,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc',
  };
  arrayLineasTabla: any = [
    {
      rawMaterial: null,
      description: '',
      quantity: '',
      price: null,
      subtotal: 0,
      company: 0,
    },
  ];
  opcionesArticulos: iOptionsSelector = {
    items: [],
    clearable: true,
    search: true,
    placeholder: 'Artículos',
    bindLabel: 'display',
    append: true,
  };
  dataCreate: any = {
    state: 'Pte. de recibir',
    company: 0,
    porte: 0,
    note: this.notas,
    orderItem: this.arrayLineasTabla,
    base: 0,
    iva: 0,
    irpf: 0,
    total: 0,
    code: 0,
  };

  constructor(
    public dialogRef: MatDialogRef<NuevoProveedorPedidoPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private companySE: CompanyService,
    private orderSE: OrderService,
    private rawMaterialSE: RawMaterialService
  ) {
    super();
  }

  ngOnInit() {
    this.getProveedores();
    this.getLastOrderNumber();
    this.getArticulos();
  }
  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }
  nextSection() {
    if (this.selectorMap < 2) this.selectorMap++;
    if (this.selectorMap == 1) {
      this.generateTable();
    }
    if (this.selectorMap == 2) {
      setInterval(() => {
        this.getHeight();
      }, 100);
    }
  }
  previousSection() {
    if (this.selectorMap > 0) this.selectorMap--;
    if (this.selectorMap == 1) {
      this.generateTable();
    }
  }
  sumarDias(fecha, dias) {
    fecha.setDate(fecha.getDate() + dias);
    return iDate.javascriptConvert(fecha).toStringDate('EU');
  }
  noteChange(event) {
    this.dataCreate.note = event.target.value;
  }

  /**
   * DATE PICKER
   */

  dataFromPicker(event) {
    this.data.fechaPedido = event;
    this.checkFirstSection();
  }

  /**
   * VALIDATIONS
   */
  checkIsCompleted() {
    this.isCompleted = StaticUtilitiesService.isCompleteObject(this.data);
  }
  checkFirstSection() {
    let firstSectionValidatorData: any = {
      nPedido: this.data.nPedido,
      fechaPedido: new Date().toLocaleDateString(),
      proveedor: this.data.proveedor,
    };

    this.isFirstSectionCompleted = StaticUtilitiesService.isCompleteObject(
      firstSectionValidatorData
    );
  }
  /**
   * PROVEEDOR SELECTOR
   */

  proveedorSelector(event) {
    if (!event) return;
    let id = event.id;
    this.getProveedorId(id);
    this.dataCreate.company = event.id;
    this.arrayLineasTabla.company = event.id;
    this.data.proveedor = event.name;
    this.proveedorId = event.id;
    event ? (this.isClienteSelected = true) : (this.isClienteSelected = false);
  }
  /**
   * CREATE
   */

  create() {
    this.dataCreate.base = this.baseImponible;
    this.dataCreate.iva = this.plusIva;
    this.dataCreate.irpf = this.irpf;
    this.dataCreate.total = this.total;
    this.orderSE.create(this.dataCreate);
    this.orderSE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        StaticUtilitiesService.showFeedback('Pedido creado correctamente');
        this.closePopup('create');
        this._unsubInd.next('');
      });
    this.orderSE
      .getResultUpdateError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        StaticUtilitiesService.showError('EL pedido no pudo ser creado');
        this._unsub.next('');
      });
  }
  /**
   * TABLE
   */
  getBaseImponible() {
    this.baseImponible = 0;
    this.arrayLineasTabla.forEach((element) => {
      this.baseImponible += Number(element.subtotal);
      if (this.baseImponible < 0) this.baseImponible = 0;
    });
    this.plusIva =
      this.baseImponible * Number(this.proveedorSelected.tipoIva / 100);
    if (this.proveedorSelected.retencionIRPF) {
      this.total =
        Number(this.baseImponible) + Number(this.plusIva) - Number(this.irpf);
      return;
    }
    this.total = Number(this.baseImponible) + Number(this.plusIva);
    this.irpf = Number(this.irpf);
  }
  generateTable() {
    this.isLoading = true;
    setTimeout(() => {
      this.getBaseImponible();
      this.isLoading = false;
    }, 1000);
  }
  newLine() {
    this.arrayLineasTabla.push({
      rawMaterial: null,
      description: '',
      quantity: 0,
      price: 0,
      subtotal: 0,
      company: this.arrayLineasTabla.company,
    });
    this.checkSecondSection();
  }
  deleteLine(index) {
    this.arrayLineasTabla.splice(index, 1);
    this.getBaseImponible();
    this.checkSecondSection();
  }
  sumSubtotal(index) {
    if (
      this.arrayLineasTabla[index].quantity &&
      this.arrayLineasTabla[index].price
    ) {
      this.arrayLineasTabla[index].subtotal =
        this.arrayLineasTabla[index].quantity *
        this.arrayLineasTabla[index].price;

      this.getBaseImponible();
    }
  }
  getArticulo(event, index) {
    this.obtenerUltimoLastPricePorCompany(this.proveedorId, event.id);
    if (!event) {
      this.arrayLineasTabla[index].rawMaterial = '';
      this.arrayLineasTabla[index].articleName = '';
      this.arrayLineasTabla[index].description = '';
      this.arrayLineasTabla[index].quantity = '';
      this.arrayLineasTabla[index].price = 0;
      this.arrayLineasTabla[index].subtotal = 0;
      this.arrayLineasTabla[index].company = this.proveedorId;
      this.getBaseImponible();
      this.checkSecondSection();
      return;
    }
    this.arrayLineasTabla[index].rawMaterial = event.id;
    this.arrayLineasTabla[index].articleName = event.display;
    this.arrayLineasTabla[index].description = event.description;
    this.arrayLineasTabla[index].quantity = event.quantity;
    this.arrayLineasTabla[index].price = event.precio;
    this.arrayLineasTabla[index].company = this.proveedorId;

    this.sumSubtotal(index);
    this.checkSecondSection();
  }
  checkSecondSection() {
    let validator = this.arrayLineasTabla.filter((element) => {
      if (!element.rawMaterial && !element.description) {
        return element;
      }
    });
    if (validator.length == 0) {
      this.isSecondSectionCompleted = true;
    } else {
      this.isSecondSectionCompleted = false;
    }
  }

  getHeight() {
    if (this.selectorMap == 1) {
      (
        document.querySelector(
          '#mat-dialog-0 > nuevoclientepedidopopup > div > div.tablaPedido.ng-star-inserted > div > div.secondPill > pill > div'
        ) as HTMLElement
      ).style.height =
        (
          document.querySelector(
            '#mat-dialog-0 > nuevoclientepedidopopup > div > div.tablaPedido.ng-star-inserted > div > div.firstPill > pill > div'
          ) as HTMLElement
        ).clientHeight + 'px';
    }
  }

  /**
   * GETTER && SETTER
   */

  setter(data) {
    this.proveedorSelected.emailPedidos = data.orderEmails[0];
    this.proveedorSelected.formaDePago =
      data.paymentMethods[0].wayToPay.description;
    this.proveedorSelected.fechaDePago = this.sumarDias(
      new Date(),
      data.paymentMethods[0].wayToPay.days
    );
    this.irpfCompany = data.paymentMethods[0].irpf;
    this.proveedorSelected.tipoPortes = data.porte.name;
    this.dataCreate.porte = data.porte.id;
    this.proveedorSelected.tipoIva = data.paymentMethods[0].typeIva.value * 100;
    this.proveedorSelected.retencionIRPF = data.paymentMethods[0].irpf;
  }

  /**
   * API CALLS
   */

  getProveedores() {
    this.companySE.getClientOrProveedor({
      num_devoluciones: 5000,
      num_pagina: 1,
      isClient: false,
    });
    this.companySE
      .getResult()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res: any) => {
        if (!res) return;
        let { data, total } = res.data;

        this.opcionesProveedor.items = data;
        this._unsubInd.next('');
      });
  }
  getProveedorId(id: number) {
    this.companySE.getSingle(id);
    this.companySE
      .getResultIndividual()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res) => {
        if (!res) return;
        let { data } = res;
        this.setter(data);
        this._unsubInd2.next('');
      });
  }
  getLastOrderNumber() {
    this.orderSE.getLastOrder();
    this.orderSE
      .getResultLastOrder()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res: any) => {
        if (!res) return;
        let { data } = res;
        this.data.nPedido = data.code + 1;
        this.dataCreate.code = data.code + 1;
        this._unsubInd2.next('');
      });
  }
  getArticulos() {
    this.rawMaterialSE.getAll({ num_devoluciones: 5000, num_pagina: 1 });
    this.rawMaterialSE
      .getResult()
      .pipe(takeUntil(this._unsubInd3))
      .subscribe((res: any) => {
        if (!res) return;
        let { data, total } = res.data;
        data.map((article) => {
          article.display = article.code + ' - ' + article.name;
        });

        this.opcionesArticulos.items = data;
        this._unsubInd3.next('');
      });
  }
  getArticuloId(id: number, index) {
    this.rawMaterialSE.getSingleSupplier(id);
    this.rawMaterialSE
      .getResultSingleRawMaterial()
      .pipe(takeUntil(this._unsubInd9))
      .subscribe((res: any) => {
        if (!res) return;
        let { data } = res;
        this.arrayLineasTabla[index].price = data.lastPrice;
        this.arrayLineasTabla[index].description = data.description;

        this._unsubInd9.next('');
      });
  }

  obtenerUltimoLastPricePorCompany(companyId: number, rmId: number) {
    this.companySE.obtenerUltimoLastPricePerCompany(companyId, rmId);
    this.companySE
      .getResultIndividual()
      .pipe(takeUntil(this._unsubInd9))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        let { data } = res;
        this.arrayLineasTabla[0].price = data.price;
        this._unsubInd9.next('');
      });
    this.companySE
      .getResultIndividualError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: iResultHttp) => {
        if (!res) {
          return;
        }
        StaticUtilitiesService.showError(String(res.message));
        this._unsub.next('');
      });
  }
}
