import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  iOptionsSelector,
  iStaticUtilities,
  iUnsubscribeDestroy,
} from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { AddressService } from 'src/app/Services/Api/Address.service';
import { CompanyService } from 'src/app/Services/Api/Company.service';
import { PaymentMethodService } from 'src/app/Services/Api/PaymentMethod.service';
import { PorteService } from 'src/app/Services/Api/Porte.service';
import { TypesIvaService } from 'src/app/Services/Api/TypesIva.service';
import { WayToPayService } from 'src/app/Services/Api/WayToPay.service';
import { StaticDataHandlerService } from 'src/app/Services/Utils/StaticDataHandler.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'app-CrearProveedor-Popup',
  templateUrl: './CrearProveedor-Popup.component.html',
  styleUrls: ['./CrearProveedor-Popup.component.scss'],
})
export class CrearProveedorPopupComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  isCompleted: boolean = false;
  opcionesProvincias: iOptionsSelector = {
    items: StaticDataHandlerService.getProvincias(),
    clearable: true,
    search: true,
    placeholder: 'Provincia',
    append: true,
  };
  opcionesPaises: iOptionsSelector = {
    items: StaticDataHandlerService.getPaises(),
    clearable: true,
    search: true,
    placeholder: 'País',
    append: true,
  };
  opcionesFormaPago: iOptionsSelector = {
    items: [],
    clearable: true,
    search: true,
    bindLabel: 'description',
    placeholder: 'Forma de pago',
    bindValue: 'id',
    append: true,
  };
  opcionesTipoIva: iOptionsSelector = {
    items: [],
    clearable: true,
    search: true,
    bindLabel: 'description',
    placeholder: 'Tipos de IVA',
    bindValue: 'id',
    append: true,
  };
  opcionesTipoPorte: iOptionsSelector = {
    items: [],
    clearable: true,
    search: true,
    bindLabel: 'name',
    placeholder: 'Tipo portes',
    bindValue: 'id',
    append: true,
  };
  opcionesIRPF: iOptionsSelector = {
    items: [
      { id: 1, label: 'No', boolean: false },
      { id: 2, label: 'Si', boolean: true },
    ],
    clearable: true,
    search: true,
    placeholder: 'Rentención IRPF',
    bindValue: 'boolean',
    append: true,
  };
  opcionesDias: iOptionsSelector = {
    items: [
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '10',
      '11',
      '12',
      '13',
      '14',
      '15',
      '16',
      '17',
      '18',
      '19',
      '20',
      '21',
      '22',
      '23',
      '24',
      '25',
      '26',
      '27',
      '28',
      '29',
      '30',
      '31',
    ],
    clearable: true,
    search: true,
    placeholder: 'Forma de pago',
    append: true,
    multiple: {
      isMultiple: true,
      checkbox: true,
      multipleLimitReplacer: 2,
      multipleTextReplacer: 'Varios días seleccionados',
    },
  };

  //FORM VARIABLES
  codigo: string = '';
  nombre: string = '';
  nif: string = '';
  direccion: string = '';
  municipio: string = '';
  number: any = null;
  cp: number | null = null;
  provincia: string | null = null;
  pais: string | null = null;
  telefonoContacto1: number | null = null;
  telefonoContacto2: number | null = null;
  telefonoContacto3: number | null = null;
  email1: string = '';
  email2: string = '';
  email3: string = '';
  emailPedidos: string = '';

  formaPago: any = null;
  dias: any = null;
  iban: string = '';
  bic: string = '';
  tipoPortes: any = null;
  tipoIva: any = null;
  retnecionIRPF: any = null;
  observaciones: string = '';
  updateObjectCompany: any = {
    id: '',
  };
  updateObjectAddress: any = {
    id: '',
  };
  updateObjectPaymentMethod: any = {
    id: '',
  };
  updateCompanyAddress: any = {
    id: '',
  };

  constructor(
    public dialogRef: MatDialogRef<CrearProveedorPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private companySE: CompanyService,
    private wayToPaySE: WayToPayService,
    private typesIvaSE: TypesIvaService,
    private porteSE: PorteService,
    private paymentMethodSE: PaymentMethodService,
    private addressSE: AddressService
  ) {
    super();
  }

  ngOnInit() {
    if (this.modalData.accion !== 'crear') {
      this.getCompanyId(this.modalData.data.id);
    }
    this.getWayToPayForFilter();
    this.getTypesIvaForFilter();
    this.getPortesForFilter();
  }

  /**
   * GETTERS AND SETTERS
   */

  setter(data) {
    this.updateObjectCompany.id = data.id;
    this.nombre = data.name;
    this.codigo = data.code;
    this.nif = data.nif;
    this.telefonoContacto1 = data.phones[0];
    this.telefonoContacto2 = data.phones[1];
    this.telefonoContacto3 = data.phones[2];
    this.email1 = data.emails[0];
    this.email2 = data.emails[1];
    this.emailPedidos = data.orderEmails[0];
    this.observaciones = data.observation;
    //
    this.iban = data.paymentMethods[0].iban;
    this.bic = data.paymentMethods[0].bic;
    this.formaPago = data.paymentMethods[0].wayToPay?.id ?? null;
    this.tipoIva = data.paymentMethods[0].typeIva?.id ?? null;
    this.retnecionIRPF = data.paymentMethods[0].irpf;
    this.tipoPortes = data.porte?.id ?? null;
    this.dias = data.fixedDays;
    this.updateObjectPaymentMethod.id = data.paymentMethods[0].id;
    //
    this.updateCompanyAddress.id = data.companyAddress[0].id;
    this.direccion = data.companyAddress[0].street;
    this.number = data.companyAddress[0].number;
    this.cp = data.companyAddress[0].postalCode;
    this.municipio = data.companyAddress[0].municipality;
    this.pais = data.companyAddress[0].country;
    this.provincia = data.companyAddress[0].province;
    this.isCompleted = true;
  }
  editSectionCompany(event, key) {
    switch (key) {
      case 'telefonoContacto1':
        this.updateObjectCompany.phones = [
          event,
          this.telefonoContacto2,
          this.telefonoContacto3,
        ];
        this.checkIsCompleted();
        this.editCompany();
        break;
      case 'telefonoContacto2':
        this.updateObjectCompany.phones = [
          this.telefonoContacto1,
          event,
          this.telefonoContacto3,
        ];
        this.checkIsCompleted();
        this.editCompany();
        break;
      case 'telefonoContacto3':
        this.updateObjectCompany.phones = [
          this.telefonoContacto1,
          this.telefonoContacto2,
          event,
        ];
        this.checkIsCompleted();
        this.editCompany();
        break;
      case 'email1':
        this.updateObjectCompany.emails = [event, this.email2, this.email3];
        this.checkIsCompleted();
        this.editCompany();
        break;
      case 'email2':
        this.updateObjectCompany.emails = [this.email1, event, this.email3];
        this.checkIsCompleted();
        this.editCompany();
        break;
    }
    this.updateObjectCompany[key] = event;
    this.checkIsCompleted();
    this.editCompany();
  }
  editSectionPaymentMethod(event, key) {
    if (key == 'irpf') {
      this.updateObjectPaymentMethod.irpf = event;
      this.checkIsCompleted();
      this.editPaymentMethod();
      return;
    }
    this.updateObjectPaymentMethod[key] = event;
    this.checkIsCompleted();
    this.editPaymentMethod();
  }
  editSectionCompanyAddress(event, key) {
    this.updateCompanyAddress[key] = event;
    this.checkIsCompleted();
    this.editCompanyAddress();
  }

  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }

  checkIsCompleted() {
    if (this.modalData.section == undefined) {
      this.codigo && this.nombre && this.nif
        ? (this.isCompleted = true)
        : (this.isCompleted = false);
    } else {
      switch (this.modalData.section) {
        case 'datos':
          this.codigo && this.nombre && this.nif
            ? (this.isCompleted = true)
            : (this.isCompleted = false);
          break;
      }
    }
  }

  /**
   * API CALLS
   */

  create() {
    let data: any = {
      name: this.nombre,
      code: this.codigo,
      nif: this.nif,
      phones: [
        this.telefonoContacto1,
        this.telefonoContacto2,
        this.telefonoContacto3,
      ],
      emails: [this.email1, this.email2, this.email3],
      orderEmails: [this.emailPedidos],
      observation: this.observaciones,
      isClient: false,
      fixedDays: this.dias,
      paymentMethod: {
        iban: this.iban,
        bic: this.bic,
        wayToPay: this.formaPago,
        typeIva: this.tipoIva,
        irpf: this.retnecionIRPF,
      },
      porte: this.tipoPortes,
      companyAddress: {
        description: 'proveedor',
        street: this.direccion,
        number: this.number,
        postalCode: this.cp,
        country: this.pais,
        province: this.provincia,
        municipality: this.municipio,
        phones: [
          this.telefonoContacto1,
          this.telefonoContacto2,
          this.telefonoContacto3,
        ],
      },
      billingAddress: {
        street: this.direccion,
        number: this.number,
        postalCode: this.cp,
        country: this.pais,
        province: this.provincia,
        municipality: this.municipio,
        phones: [
          this.telefonoContacto1,
          this.telefonoContacto2,
          this.telefonoContacto3,
        ],
      },
    };
    this.companySE.create(data);
    this.companySE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        StaticUtilitiesService.showFeedback('Proveedor creado correctamente');
        this.closePopup('create');
        this._unsubInd.next('');
      });
    this.companySE
      .getResultUpdateError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        StaticUtilitiesService.showError(res.message);
        this._unsub.next('');
      });
  }
  editCompany() {
    this.companySE.update(this.updateObjectCompany);
    this.companySE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd9))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        StaticUtilitiesService.showFeedback('Proveedor editado correctamente');
        this._unsubInd9.next('');
      });
    this.companySE
      .getResultUpdateError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        StaticUtilitiesService.showError(res.message);
        this._unsub.next('');
      });
  }
  editPaymentMethod() {
    this.paymentMethodSE.update(this.updateObjectPaymentMethod);
    this.paymentMethodSE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        StaticUtilitiesService.showFeedback(
          'Forma de pago editada correctamente'
        );
        this._unsubInd.next('');
      });
    this.paymentMethodSE
      .getResultUpdateError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        StaticUtilitiesService.showError('La forma de pago no se pudo editar');
        this._unsub.next('');
      });
  }
  editCompanyAddress() {
    this.addressSE.update(this.updateCompanyAddress);
    this.addressSE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        StaticUtilitiesService.showFeedback('Dirección editada correctamente');
        this._unsubInd.next('');
      });
    this.addressSE
      .getResultUpdateError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        StaticUtilitiesService.showError('La dirección no se pudo editar');
        this._unsub.next('');
      });
  }

  // MANTENIMIENTOS

  getWayToPayForFilter() {
    this.wayToPaySE.getAll({ num_devoluciones: 1000, num_pagina: 1 });
    this.wayToPaySE
      .getResult()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        let { data, total } = res.data;
        this.opcionesFormaPago.items = data;
        this._unsubInd2.next('');
      });
  }
  getTypesIvaForFilter() {
    this.typesIvaSE.getAll({ num_devoluciones: 1000, num_pagina: 1 });
    this.typesIvaSE
      .getResult()
      .pipe(takeUntil(this._unsubInd3))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        let { data, total } = res.data;
        this.opcionesTipoIva.items = data;
        this._unsubInd3.next('');
      });
  }

  getPortesForFilter() {
    this.porteSE.getAll({ num_devoluciones: 1000, num_pagina: 1 });
    this.porteSE
      .getResult()
      .pipe(takeUntil(this._unsubInd4))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        let { data, total } = res.data;
        this.opcionesTipoPorte.items = data;
        this._unsubInd4.next('');
      });
  }

  getCompanyId(id) {
    this.companySE.getSingle(id);
    this.companySE
      .getResultIndividual()
      .pipe(takeUntil(this._unsubInd5))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        let { data } = res;
        this.setter(data);
        this._unsubInd5.next('');
      });
  }
}
