<div class="popupGeneralContainer">
  <div class="header">
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
    <p *ngIf="modalData.accion=='editar'">Editar forma de pago</p>
    <p *ngIf="modalData.accion=='crear'">Crear forma de pago</p>
    <p *ngIf="modalData.accion=='ver'">Ver forma de pago</p>
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
  </div>
  <div class="body">
    <div class="doubleColumn">
      <CustomInput [readonly]="modalData.accion=='ver'" classList="fullWidth tall special" placeholder="Código" label="Código" [(ngModel)]="code" name="code" (change)="modalData.accion ==='editar'? editSecction(code, 'code') :checkIsCompleted()"></CustomInput>
      <CustomInput [readonly]="modalData.accion=='ver'" classList="fullWidth tall special" [type]="'number'"  placeholder="Días" label="Días" [(ngModel)]="dias" name="dias" (change)="modalData.accion ==='editar'? editSecction(dias, 'days') :checkIsCompleted()"></CustomInput>
    </div>
    <CustomInput [readonly]="modalData.accion=='ver'" classList="fullWidth tall special" placeholder="Descripción" label="Descripción" [(ngModel)]="descripcion" (change)="modalData.accion ==='editar'? editSecction(descripcion, 'description') :checkIsCompleted()"></CustomInput>

  </div>
  <div class="footer" *ngIf="modalData.accion!='ver'">
    <btn classList="secundario" (click)="forceClosePopup()">
      <ng-container>
       Cancelar
      </ng-container>
    </btn>
    <btn classList="secundaryButton" (click)="create()" [class.disabled]="!isCompleted" *ngIf="modalData.accion=='crear'">
      <ng-container >
        Crear
      </ng-container>
    </btn>
    <btn classList="secundaryButton" (click)="closePopup()" [class.disabled]="!isCompleted" *ngIf="modalData.accion=='editar'">
      <ng-container >
        Editar
      </ng-container>
    </btn>
  </div>
</div>
