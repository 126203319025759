<div class="popupGeneralContainer">
  <div class="header">
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
    <p *ngIf="modalData.accion=='editar'">Editar característica</p>
    <p *ngIf="modalData.accion=='crear'">Crear característica</p>
    <p *ngIf="modalData.accion=='ver'">Ver característica</p>
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
  </div>
  <div class="body">
    <CustomInput [readonly]="modalData.accion=='ver'" classList="fullWidth tall special" placeholder="Código" label="Código" [(ngModel)]="codigo" (change)="checkIsCompleted()"></CustomInput>
    <CustomInput [readonly]="modalData.accion=='ver'" classList="fullWidth tall special" placeholder="NIF" label="NIF" [(ngModel)]="nif" (change)="checkIsCompleted()"></CustomInput>
    <CustomInput [readonly]="modalData.accion=='ver'" classList="fullWidth tall special" placeholder="Direccion" label="Direccion" [(ngModel)]="direccion" (change)="checkIsCompleted()"></CustomInput>
    <CustomInput [readonly]="modalData.accion=='ver'" classList="fullWidth tall special" placeholder="Municipio" label="Municipio" [(ngModel)]="municipio" (change)="checkIsCompleted()"></CustomInput>
    <CustomInput [readonly]="modalData.accion=='ver'" classList="fullWidth tall special" placeholder="Código Postal" label="Código Postal" [(ngModel)]="cp" (change)="checkIsCompleted()"></CustomInput>
    <div class="customContainer">
      <label for="Provincia">Provincia</label>
      <selector [options]="opcionesProvincias" [(ngModel)]="provincia"></selector>
    </div>
    <div class="customContainer">
      <label for="Pais">País</label>
      <selector [options]="opcionesPaises" [(ngModel)]="pais"></selector>
    </div>
    <CustomInput *ngFor="let telefono of telefonos; let index = index" [readonly]="modalData.accion=='ver'" classList="fullWidth tall special" placeholder="Teléfono {{index + 1}}" label="Teléfono {{index + 1}}" [(ngModel)]="telefono.telefono" (change)="checkIsCompleted()"></CustomInput>
    <CustomInput *ngFor="let email of emails; let index = index" [readonly]="modalData.accion=='ver'" classList="fullWidth tall special" placeholder="E-mail {{index + 1}}" label="E-mail {{index + 1}}" [(ngModel)]="email.email" (change)="checkIsCompleted()"></CustomInput>
    <CustomInput *ngFor="let emailPedido of emailsPedidos; let index = index" [readonly]="modalData.accion=='ver'" classList="fullWidth tall special" placeholder="E-mail Pedido {{index + 1}}" label="E-mail Pedido {{index + 1}}" [(ngModel)]="emailPedido.email" (change)="checkIsCompleted()"></CustomInput>
    <CustomInput [readonly]="modalData.accion=='ver'" classList="fullWidth tall special" placeholder="URL Google Maps" label="URL Google Maps" [(ngModel)]="url" (change)="checkIsCompleted()"></CustomInput>

  </div>
  <div class="footer" *ngIf="modalData.accion!='ver'">
    <btn classList="secundario" (click)="forceClosePopup()">
      <ng-container>
       Cancelar
      </ng-container>
    </btn>
    <btn classList="secundaryButton" (click)="closePopup()" [class.disabled]="!isCompleted">
      <ng-container *ngIf="modalData.accion=='crear'">
        Crear
      </ng-container>
      <ng-container *ngIf="modalData.accion=='editar'">
        Editar
      </ng-container>
    </btn>
  </div>
</div>
