<pill class="tablePil" classList="tablePill">
  <div class="headerTable">
    <p class="headerTableP">Material</p>
    <!-- <p class="headerTableP">Código material</p> -->
    <p class="headerTableP">Código secundario</p>
    <!-- <p class="headerTableP">Descripción</p> -->
    <p class="headerTableP">Cantidad</p>
    <p class="headerTableP">Coste</p>
  </div>
  <ng-container *ngFor="let linea of arrayLineasTabla; let index = index">
    <div class="bodyTable" *ngIf="linea?.especial == false">
      <div class="contenedor">
        <selector
          [options]="opcionesArticulos"
          [(ngModel)]="articles[index]"
          (change)="getArticuloSelected($event, index); checkIsCompleted()"
        ></selector>
      </div>
      <div class="contenedor">
        {{ linea.codigoSecundario }}
      </div>
      <div class="contenedor">
        <CustomInput
          [class.disabled]="arrayLineasTabla[index].id == null"
          classList="fullWidth tall special"
          (change)="updateTotal()"
          placeholder="Cantidad"
          (change)="
            isEditing ? editSectionFormulaDetail(linea) : checkIsCompleted()
          "
          [(ngModel)]="linea.cantidad"
        ></CustomInput>
      </div>

      <p>{{ linea.coste | currency : "EUR" }}/kg</p>
      <div class="icons">
        <span class="closeIcon" (click)="moveUp(linea)">
          <mat-icon>arrow_upward</mat-icon>
        </span>
        <span class="closeIcon" (click)="moveDown(linea)">
          <mat-icon>arrow_downward</mat-icon>
        </span>
        <span
          class="closeIcon"
          *ngIf="arrayLineasTabla.length > 1"
          (click)="removeRow(linea)"
        >
          <mat-icon>close</mat-icon>
        </span>
      </div>
    </div>
    <div class="bodyTable especial" *ngIf="linea?.especial == true">
      <div class="contenedor">
        <CustomInput
          classList="fullWidth tall special"
          placeholder="Línea de proceso"
          [(ngModel)]="linea.explicacion"
          (change)="
            isEditing ? editSectionFormulaDetail(linea) : checkIsCompleted()
          "
        >
        </CustomInput>
      </div>

      <div class="icons">
        <span class="closeIcon" (click)="moveUp(linea)">
          <mat-icon>arrow_upward</mat-icon>
        </span>
        <span class="closeIcon" (click)="moveDown(linea)">
          <mat-icon>arrow_downward</mat-icon>
        </span>
        <span
          class="closeIcon"
          *ngIf="arrayLineasTabla.length > 1"
          (click)="removeRow(linea)"
        >
          <mat-icon>close</mat-icon>
        </span>
      </div>
    </div>
  </ng-container>
  <div class="calculo">
    <div class="notas">
      <CustomTextArea
        placeHolder="Observaciones finales"
        label="Observaciones finales"
        [(ngModel)]="observaciones"
        name="observaciones"
        class="height20vh"
        (change)="
          isEditing ? editSection(observaciones, 'finalObservation') : null
        "
      >
      </CustomTextArea>
    </div>
    <div class="tablaCalculo">
      <div class="row total">
        <div class="col">
          <p class="header">TOTAL MUESTRA (KG):</p>
        </div>
        <div class="col value">
          <p>{{ total.toFixed(2) | number }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <p class="header">Coste total (Kg):</p>
        </div>
        <div class="col value">
          <p>
            {{ totalKg | currency : "EUR" }}
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <p class="header">Coste total (L):</p>
        </div>
        <div class="col value">
          <p>
            {{ totalL.toFixed(2) | currency : "EUR" }}
          </p>
        </div>
      </div>
    </div>
  </div>
</pill>
