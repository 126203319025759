import { Component, OnInit } from '@angular/core';
import { slideNavigationTypes } from '@quasar_dynamics/basic-designsystem';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'app-MaquinasDetailView',
  templateUrl: './MaquinasDetailView.component.html',
  styleUrls: ['./MaquinasDetailView.component.scss']
})
export class MaquinasDetailViewComponent implements OnInit {

  constructor(private staticUtilitiesSE:StaticUtilitiesService) { }

  ngOnInit() {
  }

  goBack(){
    this.staticUtilitiesSE.goTo('crm/clientes/', slideNavigationTypes.slideToBottom)
  }

}
