import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { PopupService, iDataTableColumns, iDataTableOptions, iDate, iOptionsSelector, iUnsubscribeDestroy } from '@quasar_dynamics/basic-designsystem';
import { ConfirmPopupComponent } from 'src/app/Popups/ConfirmPopup/ConfirmPopup.component';
import * as XLSX from 'xlsx';
import { takeUntil } from 'rxjs/operators';
import { CompanyService } from 'src/app/Services/Api/Company.service';
import { DeliveryNoteService } from 'src/app/Services/Api/DeliveryNote.service';
import { EditAlbaranPopupComponent } from 'src/app/Popups/EditAlbaranPopup/EditAlbaranPopup.component';

@Component({
  selector: 'ERP-Clientes-Albaran',
  templateUrl: './ERP-Clientes-Albaran.component.html',
  styleUrls: ['./ERP-Clientes-Albaran.component.scss']
})
export class ERPClientesAlbaranComponent extends iUnsubscribeDestroy implements OnInit {

  @Input() refreshAlbaran: boolean = false;

  totalPaginacion: number = 0;
  filter: any = {
    num_devoluciones: 20,
    num_pagina: 1,
  }
  dataForTable: any = [  ]
  isLoading: boolean = false;
  optionsTabla: iDataTableOptions = {
    isSelectable: true,
    isHovered: true,
    canSee: true,
    canDelete: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc',
    moreIconsHead: [{ callback: (event) => { this.exportexcel() }, icon: "download" }],
  }
  opcionesClientes: iOptionsSelector = {
    items: [],
    clearable: true,
    search: true,
    placeholder: "Cliente",
    append: true,
  }
  displayedColumnsTabla: Array<iDataTableColumns> = [
    { label: 'Nº Albarán', property: 'code' },
    { label: 'Fecha albarán', property: 'albaranDate', align: "center" },
    { label: 'Cliente', property: 'client', align: "center" },
    { label: 'Estado', property: 'state', align: "center", classList:'tableEstado tableEstadoPedidos'   },

  ];



  constructor(private popupSE:PopupService,private companySE:CompanyService, private deliveryNoteSE:DeliveryNoteService) {
    super();
  }

  ngOnInit() {
    this.getAllAlbaranes()
  }

  ngOnChange(changes:SimpleChanges){
    if(changes['refreshAlbaran']){
      this.getAllAlbaranes()
    }
  }
  /**
   * GETTERS AND SETTERS 
   */

  /**
   * 
   * FUNCTIONALITY
   */
  see(event) {
    this.popupSE.openPopup(EditAlbaranPopupComponent, { data:event, accion:'editar'});
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
      if (!res) { return };
      this.getAllAlbaranes()
      this._unsubInd2.next('');
    })
  }

  /**
 * DESCARGA DE PARTES EN EXCEL
 */

  exportexcel(): void {
    let table = document.createElement("table")
    table.append((document.querySelector("dataTable .tableHeader thead") as HTMLElement).cloneNode(true))
    table.querySelector("thead .iconsActions")?.remove();
    table.querySelector("thead .checkboxContainer")?.remove();
    let rows = Array.from(document.querySelectorAll("dataTable .tableBody tbody tr "));
    let filteredRows = rows.filter((row) => {
      return row.querySelector('.checkboxContainer mat-checkbox ')!.getAttribute('ng-reflect-checked') === "true"
    })
    if (filteredRows.length > 0) {
      filteredRows.forEach((row) => {
        let newRow = row.cloneNode(true) as HTMLElement;
        newRow.querySelector('td:last-of-type')?.remove();
        newRow.querySelector('td:first-of-type')?.remove();
        table.append(newRow)
      })
    } else {
      table.append((document.querySelector("dataTable .tableBody tbody") as HTMLElement).cloneNode(true))
      table.querySelectorAll("tbody tr td:last-of-type").forEach(element => {
        element.remove();
      })
      table.querySelectorAll("tbody tr td:first-of-type").forEach(element => {
        element.remove();
      })
    }
    /* pass here the table id */
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, 'Albaranes.xlsx');

  }

  
  delete(event, key) {
    let ids:any[] = []
    switch (key) {
      case 'simple':
        ids = [event.id]
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'el cliente' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
          if (!res) { return };

          this._unsubInd2.next('');
        })
        break;
      case 'all':
        ids = this.dataForTable.map((item) => { return item.id })
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'todos los clientes' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
          if (!res) { return };

          this._unsubInd2.next('');
        })

        break;
      case 'selected':
        ids = event.map((item) => { return item.id})
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'los clientes seleccionados' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
          if (!res) { return };

          this._unsubInd2.next('');
        })
        break;
      default:
        break;
    }
  }


  /**
  * PAGINATION
  */

  changePagination(event: any) {
    if (event === 'next') {
      this.filter.num_pagina++;
      this.getAllAlbaranes();
    } else {
      this.filter.num_pagina--;
      this.getAllAlbaranes();
    }
  }


  /**
   * API CALLS
   */

  getAllAlbaranes() {
    this.isLoading = true;
    this.deliveryNoteSE.getAllAlbaran(this.filter);
    this.deliveryNoteSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(res => {
      if (!res) { return };
      let {data, total} = res.data;
      this.totalPaginacion = total;
      this.dataForTable = data.map((item) => {return {...item, albaranDate: iDate.javascriptConvert(new Date(item.date)).toStringDate('EU')}});
      this.isLoading = false;
      this._unsubInd.next('');
    })
  }
}
