import { iBaseObject } from "@quasar_dynamics/basic-designsystem";
import { iCompany } from "./iCompany";

export interface iAccounting {
    id?: number,
    balance?: number,
    company?: iCompany,
}

export class iAccounting extends iBaseObject {
    static convertFromCollection(ob: any): Array<iAccounting> {
        let iAccountingCollection: Array<iAccounting> = [];
        ob.forEach((element: any) => {
            iAccountingCollection.push(this.convertFromObject(element));
        });
        return iAccountingCollection;
    }

    static convertFromObject(ob: any): iAccounting {
        let iAccountingObj = new iAccounting();
        iAccountingObj.id = ob.id;
        iAccountingObj.balance = ob.balance;
        iAccountingObj.company = ob.company ? iCompany.convertFromObject(ob.company) : iCompany.createEmptyObject();
        return iAccountingObj;
    }

    static createEmptyObject(): iAccounting {
        let iAccountingObj = new iAccounting();
        iAccountingObj.balance = -1;
        iAccountingObj.company = iCompany.createEmptyObject();
        return iAccountingObj;
    }
}

export interface iAccountingFilter {
    num_devoluciones: number;
    num_pagina: number;
    genericFilter?: string;
    balance?: number,
    company?: number,
}