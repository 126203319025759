<div class="fichaGeneralContainer">
  <pill>
    <ng-container>
      <div class="info">
        <div class="row">
          <div class="column">
            <label for="">Código de parte:</label>
            <span>{{data.code}}</span>
          </div>
          <div class="column">
            <label for="">Fecha de entrega:</label>
            <span>{{deliveryDate | date:'dd/MM/yyyy'}}</span>
          </div>
        </div>
        <div class="row">
          <div class="column">
            <label for="">Producto:</label>
            <span>{{data?.formula?.name}}</span>
          </div>
          <div class="column">
            <label for="">Cantidad a fabricar:</label>
            <span>{{data.amount}}</span>
          </div>
        </div>
        <div class="row">
          <div class="column">
            <label for="">Tipo:</label>
            <span>{{data?.formula?.family?.code}}</span>
          </div>
          <div class="column">
            <label for="">Lote fabricación:</label>
            <span>{{data.code}}</span>
          </div>
        </div>
        <div class="row">
          <div class="column">
            <label for="">Densidad:</label>
            <span>{{data?.formula?.density}}</span>
          </div>
          <div class="column">
            <label for="">Área de producción:</label>
            <span>{{data?.productionArea?.name}}</span>
          </div>
        </div>
      </div>
    </ng-container>
  </pill>
</div>
