<FullContainer>
  <div class="fullView">
    <div class="headerAndArchivar">
      <div class="header">
        <button class="goBack" (click)="goBack()">
          <ng-container>
            <mat-icon>
              arrow_back
            </mat-icon>
          </ng-container>
        </button>
        <p>CLIENTES / <span>PEDIDOS / {{data.id}}</span></p>
      </div>
      <div class="buttons">
        <span class="button">
          <ng-container>
            <mat-icon>
              mail
            </mat-icon>
          </ng-container>
          <p>ENVIAR</p>
        </span>
        <span class="button" (click)="printScreen()">
          <ng-container>
            <mat-icon>
              print
            </mat-icon>
          </ng-container>
          <p>IMPRIMIR</p>
        </span>
      </div>
    </div>
    <div class="archivador">
      <accordion [open]="openAccordeon" [title]="'FICHA PEDIDO'">
        <ng-container>
          <CPD-FichaPedido class="fullWidth" (refresh)="refresh($event)" [data]="data" ></CPD-FichaPedido>
        </ng-container>
      </accordion>
    </div>

    <div class="accordionInformacionComercial">
      <accordion [open]="openAccordeon" class="" [title]="'PEDIDO'">
        <CPD-PedidoNumero class="fullWidth" [data]="data"></CPD-PedidoNumero>
      </accordion>
    </div>

  </div>
</FullContainer>