import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  iDataTableColumns,
  iDataTableOptions,
  iOptionsSelector,
  iUnsubscribeDestroy,
  PopupService,
} from '@quasar_dynamics/basic-designsystem';
import { Subject, takeUntil } from 'rxjs';
import { ConfirmPopupComponent } from 'src/app/Popups/ConfirmPopup/ConfirmPopup.component';
import { NuevoAsientoPopupComponent } from 'src/app/Popups/NuevoAsiento-Popup/NuevoAsiento-Popup.component';
import { NuevoAsientoRawMAterialPopupComponent } from 'src/app/Popups/NuevoAsientoRawMAterial-Popup/NuevoAsientoRawMAterial-Popup.component';
import { HistoricalService } from 'src/app/Services/Api/Historical.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';
import { iHistoricalFilter } from 'src/app/Shared/Interfaces/iHistorical';
import * as XLSX from 'xlsx';

@Component({
  selector: 'ERP-Stock-Materias-Historico',
  templateUrl: './ERP-Stock-Materias-Historico.component.html',
  styleUrls: ['./ERP-Stock-Materias-Historico.component.scss'],
})
export class ERPStockMateriasHistoricoComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  @Output() update: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() rawMaterialId: number = 0;
  isLoading: boolean = false;
  forceUpdateWidth = new Subject();
  totalPaginacion: number = 0;
  filter: iHistoricalFilter = {
    num_pagina: 1,
    num_devoluciones: 20,
  };

  //Tabla
  displayedColumnsTabla: Array<iDataTableColumns> = [
    { label: 'Fecha', property: 'fecha', align: 'center' },
    { label: 'Tipo', property: 'type', align: 'center' },
    { label: 'Movimiento', property: 'movement' },
    { label: 'Notas', property: 'note', align: 'center' },
    { label: 'Cantidad', property: 'quantity', align: 'center', format: 'kg' },
  ];

  opcionesTipo: iOptionsSelector = {
    items: [
      { label: 'Asiento', id: 1 },
      { label: 'Compra', id: 2 },
      { label: 'Venta', id: 3 },
    ],
    append: true,
    clearable: true,
    placeholder: 'Tipo',
    search: true,
    bindValue: 'label',
  };

  dataForTable: any = [];

  optionsTabla: iDataTableOptions = {
    isSelectable: true,
    canEdit: true,
    moreIconsHead: [
      {
        callback: (event) => {
          this.exportexcel();
        },
        icon: 'download',
      },
    ],
    canDelete: true,
    isHovered: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc',
  };

  constructor(
    private router: Router,
    private staticUtilitiesSE: StaticUtilitiesService,
    private popupSE: PopupService,
    private historicalSE: HistoricalService
  ) {
    super();
  }

  ngOnInit() {
    this.isLoading = true;
    setTimeout(() => {
      this.isLoading = false;
    }, 1000);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['rawMaterialId']) {
      this.getAllHistoricalRawMaterialId();
    }
  }

  /**
   * GETTERS && SETTERS
   */

  getSelector(event, key) {
    if (!event) {
      delete this.filter[key];
      this.getAllHistoricalRawMaterialId();
      return;
    }
    this.filter[key] = event;
    this.getAllHistoricalRawMaterialId();
  }

  checkcompra() {
    this.dataForTable.map((element, index) => {
      if (
        (element.type === 'Asiento' && element.quantity < 0) ||
        element.type === 'uso'
      ) {
        (
          document.querySelectorAll(
            ".tableBodyContainer .tableBody tbody tr td[columntable='quantity']"
          )![index] as HTMLElement
        ).classList.add('negativo');
      }
      if (
        (element.type === 'Asiento' && element.quantity > 0) ||
        element.type === 'proveedor'
      ) {
        (
          document.querySelectorAll(
            ".tableBodyContainer .tableBody tbody tr td[columntable='quantity']"
          )![index] as HTMLElement
        ).classList.add('venta');
      }
      if (element.type === 'venta') {
        (
          document.querySelectorAll(
            ".tableBodyContainer .tableBody tbody tr td[columntable='quantity']"
          )![index] as HTMLElement
        ).classList.add('compra');
      }
      if (element.type === 'compra') {
        (
          document.querySelectorAll(
            ".tableBodyContainer .tableBody tbody tr td[columntable='quantity']"
          )![index] as HTMLElement
        ).classList.add('venta');
      }
    });
  }

  checkIcons() {
    this.dataForTable.map((element, index) => {
      if (element.type !== 'Asiento') {
        (
          document.querySelectorAll(
            '.tableBodyContainer .tableBody tbody tr .iconsActions  .iconsContainer '
          )![index] as HTMLElement
        ).classList.add('displayNone');
      }
    });
  }

  /**
   * PAGINATION
   */

  changePagination(event: any) {
    if (event === 'next') {
      this.filter.num_pagina++;
      this.getAllHistoricalRawMaterialId();
    } else {
      this.filter.num_pagina--;
      this.getAllHistoricalRawMaterialId();
    }
  }

  /**
   * Edit
   */

  edit(event) {
    this.popupSE.openPopup(NuevoAsientoRawMAterialPopupComponent, {
      accion: 'editar',
      data: event,
    });
    this.popupSE
      .returnData()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        this.getAllHistoricalRawMaterialId();
        this._unsubInd2.next('');
      });
  }

  /**
   * Delete
   */

  delete(event, key) {
    let ids: any[] = [];
    switch (key) {
      case 'simple':
        ids = [event.id];
        this.popupSE.openPopup(ConfirmPopupComponent, {
          accion: 'Eliminar',
          elemento: 'el asiento',
        });
        this.popupSE
          .returnData()
          .pipe(takeUntil(this._unsubInd2))
          .subscribe((res) => {
            if (!res) {
              return;
            }
            this.deleteHistorical(ids);
            this._unsubInd2.next('');
          });
        break;
      case 'selected':
        ids = event.map((element) => element.id);
        this.popupSE.openPopup(ConfirmPopupComponent, {
          accion: 'Eliminar',
          elemento: 'los asientos seleccionados',
        });
        this.popupSE
          .returnData()
          .pipe(takeUntil(this._unsubInd2))
          .subscribe((res) => {
            if (!res) {
              return;
            }
            this.deleteHistorical(ids);
            this._unsubInd2.next('');
          });
        break;
      default:
        break;
    }
  }

  /**
   * DESCARGA DE PARTES EN EXCEL
   */

  exportexcel(): void {
    let table = document.createElement('table');
    table.append(
      (
        document.querySelector('dataTable .tableHeader thead') as HTMLElement
      ).cloneNode(true)
    );
    table.querySelector('thead .iconsActions')?.remove();
    table.querySelector('thead .checkboxContainer')?.remove();
    let rows = Array.from(
      document.querySelectorAll('dataTable .tableBody tbody tr ')
    );
    let filteredRows = rows.filter((row) => {
      return (
        row
          .querySelector('.checkboxContainer mat-checkbox ')!
          .getAttribute('ng-reflect-checked') === 'true'
      );
    });
    if (filteredRows.length > 0) {
      filteredRows.forEach((row) => {
        let newRow = row.cloneNode(true) as HTMLElement;
        newRow.querySelector('td:last-of-type')?.remove();
        newRow.querySelector('td:first-of-type')?.remove();
        table.append(newRow);
      });
    } else {
      table.append(
        (
          document.querySelector('dataTable .tableBody tbody') as HTMLElement
        ).cloneNode(true)
      );
      table.querySelectorAll('tbody tr td:last-of-type').forEach((element) => {
        element.remove();
      });
      table.querySelectorAll('tbody tr td:first-of-type').forEach((element) => {
        element.remove();
      });
    }
    /* pass here the table id */
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, 'Histórico.xlsx');
  }

  /**
   * add
   */

  create() {
    this.popupSE.openPopup(NuevoAsientoRawMAterialPopupComponent, {
      accion: 'crear',
      data: { id: this.rawMaterialId },
    });
    this.popupSE
      .returnData()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        this.getAllHistoricalRawMaterialId();
        this.update.emit(true);
        this._unsubInd2.next('');
      });
  }

  /**
   * API CALLS
   */

  getAllHistoricalRawMaterialId() {
    this.isLoading = true;
    this.historicalSE.getAllProduct(this.filter, this.rawMaterialId);
    this.historicalSE
      .getResult()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        let { data, total } = res.data;
        this.dataForTable = data.map((item) => {
          return { ...item, fecha: item.date.toStringDate('EU') };
        });
        this.totalPaginacion = total;
        setTimeout(() => {
          this.forceUpdateWidth.next(true);
          this.checkcompra();
          this.checkIcons();
        }, 1);
        this._unsubInd.next('');
        this.isLoading = false;
      });
  }
  deleteHistorical(ids: number[]) {
    this.historicalSE.delete(ids);
    this.historicalSE
      .getResultDelete()
      .pipe(takeUntil(this._unsubInd3))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        StaticUtilitiesService.showFeedback('Asiento eliminado correctamente');
        this.getAllHistoricalRawMaterialId();
        this.update.emit(true);
        this._unsubInd3.next('');
      });
    this.historicalSE
      .getResultDeleteError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        StaticUtilitiesService.showError('El asiento no pudo ser eliminado');
        this.getAllHistoricalRawMaterialId();
        this._unsub.next('');
      });
  }
}
