<sectionLogin-containerLogin>
  <div class="centerContainer">
    <div>
      <h2>Recupera tu contraseña</h2>
      <br>
      <CustomInput classList="fullWidth tall" [type]="'password'" placeholder="Nueva contraseña" label="Nueva contraseña" (change)="checkPasswords()" [(ngModel)]="objectToPass.new_password"></CustomInput>
      <br>
      <CustomInput classList="fullWidth tall" [type]="'password'" placeholder="Confirme la contraseña" label="Confirme la contraseña" (change)="checkPasswords()" [(ngModel)]="newPassword"></CustomInput>
      <div class="separatorInput"></div>
      <btn classList="fullWidth tall" [ngClass]="{'forwardDisabled': disableButton}" (click)="sendCredentials()">Enviar mis credenciales</btn>
      <div class="separatorInput"></div>
    </div>
  </div>
  
  <ng-container downContent>
    <div class="termsContainer">
    </div>
  </ng-container>
</sectionLogin-containerLogin>