import { iBaseObject } from "@quasar_dynamics/basic-designsystem";

export interface iFamily {
    id: number,
    code?: string,
    description?: string,
    note?: string,
}

export class iFamily extends iBaseObject {
    static convertFromCollection(ob: any): Array<iFamily> {
        let iFamilyCollection: Array<iFamily> = [];
        ob.forEach((element: any) => {
            iFamilyCollection.push(this.convertFromObject(element));
        });
        return iFamilyCollection;
    }

    static convertFromObject(ob: any): iFamily {
        let iFamilyObj = new iFamily();
        iFamilyObj.id = ob.id;
        iFamilyObj.code = ob.code;
        iFamilyObj.description = ob.description;
        iFamilyObj.note = ob.note;
        return iFamilyObj;
    }

    static createEmptyObject(): iFamily {
        let iFamilyObj = new iFamily();
        iFamilyObj.code = '';
        iFamilyObj.description = '';
        iFamilyObj.note = '';
        return iFamilyObj;
    }
}

export interface iFamilyFilter {
    num_devoluciones: number;
    num_pagina: number;
    genericFilter?: string;
    code?: string,
    description?: string,
    note?: string,
}

export interface iFamilyCreate {
    code?: string,
    description?: string,
    note?: string,
}