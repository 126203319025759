import { iBaseObject } from "@quasar_dynamics/basic-designsystem";

export interface iPorte {
    id: number,
    name?: string,
    description?: string,
}

export class iPorte extends iBaseObject {
    static convertFromCollection(ob: any): Array<iPorte> {
        let iPorteCollection: Array<iPorte> = [];
        ob.forEach((element: any) => {
            iPorteCollection.push(this.convertFromObject(element));
        });
        return iPorteCollection;
    }

    static convertFromObject(ob: any): iPorte {
        let iPorteObj = new iPorte();
        iPorteObj.id = ob.id;
        iPorteObj.name = ob.name;
        iPorteObj.description = ob.description;
        return iPorteObj;
    }

    static createEmptyObject(): iPorte {
        let iPorteObj = new iPorte();
        iPorteObj.name = '';
        iPorteObj.description = '';
        return iPorteObj;
    }
}

export interface iPorteFilter {
    num_devoluciones: number;
    num_pagina: number;
    genericFilter?: string;
    name?: string,
    description?: string,
}