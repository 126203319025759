import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { iUnsubscribeDestroy } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { HistoricalService } from 'src/app/Services/Api/Historical.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'app-NuevoAsiento-Popup',
  templateUrl: './NuevoAsiento-Popup.component.html',
  styleUrls: ['./NuevoAsiento-Popup.component.scss']
})
export class NuevoAsientoPopupComponent extends iUnsubscribeDestroy implements OnInit {
  updateObject: any = {
    id: '',
  };

  isCompleted: boolean = false;

  data:any ={
    quantity:'',
    note:'',

  } 
  constructor(public dialogRef: MatDialogRef<NuevoAsientoPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any, private historicalSE:HistoricalService) {
    super();
  }

  ngOnInit() {
     
    if (this.modalData.accion === 'editar' || this.modalData.accion === 'ver') {
      this.setter();
    }else{
      this.setterCreate()
    }
  }

  /**
   * GETTERS && SETTERS
   */

  setter() {
    this.data.quantity = this.modalData.data.quantity;
    this.data.note = this.modalData.data.note;
    this.updateObject.id = this.modalData.data.id;
   }

  setterCreate() {
    this.data.article = this.modalData.data.id;

  }

  /**
* FUNCTIONALITY
*/
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }
  editSection(event, key: string) {
    if (!event) { this.checkIsCompleted(); return }
    this.updateObject[key] = event;
    this.checkIsCompleted();
    this.update();
  }
  checkIsCompleted() {
     
    this.isCompleted = StaticUtilitiesService.isCompleteObject(this.data)
  }

  /**
 * CALLS TO API
 */

  update() {
    this.historicalSE.update(this.updateObject);
    this.historicalSE.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe((res: any) => {
      if (!res) { return }
      StaticUtilitiesService.showFeedback('Asiento actualizado correctamente')
      this._unsubInd2.next('')
    })
    this.historicalSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res: any) => {
      if (!res) { return }
      StaticUtilitiesService.showError('Error al actualizar el asiento')
      this._unsub.next('')
    })
  }

  create(){
    this.historicalSE.createAsiento(this.data, this.modalData.data.id, {isArticle:this.modalData.isArticle});
    this.historicalSE.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe((res: any) => {
      if (!res) { return }
      StaticUtilitiesService.showFeedback('Asiento creado correctamente')
      this.closePopup('create')
      this._unsubInd.next('')
    })
    this.historicalSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res: any) => {
      if (!res) { return }
      StaticUtilitiesService.showError('Error al crear el asiento')
      this._unsub.next('')
    })
  }

}
