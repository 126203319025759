import { Component, Input, OnInit, Output, SimpleChanges, } from '@angular/core';
import { Router } from '@angular/router';
import { iDataTableColumns, iDataTableOptions, iDate, iUnsubscribeDestroy, PopupService } from '@quasar_dynamics/basic-designsystem';
import { ConfirmPopupComponent } from 'src/app/Popups/ConfirmPopup/ConfirmPopup.component';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';
import { Subject, takeUntil } from 'rxjs';
import { BillService } from 'src/app/Services/Api/Bill.service';
import { iBillFilter } from 'src/app/Shared/Interfaces/iBill';
import * as XLSX from 'xlsx';
import { AccountingService } from 'src/app/Services/Api/Accounting.service';
import { CrearAsientoDetalleCompanyPopupComponent } from 'src/app/Popups/CrearAsientoDetalleCompany-Popup/CrearAsientoDetalleCompany-Popup.component';
import { EventEmitter } from '@angular/core';


@Component({
  selector: 'ERP-Cliente-Detail-Contabilidad',
  templateUrl: './ERP-Cliente-Detail-Contabilidad.component.html',
  styleUrls: ['./ERP-Cliente-Detail-Contabilidad.component.scss']
})
export class ERPClienteDetailContabilidadComponent extends iUnsubscribeDestroy implements OnInit {

  @Input() clienteId: number = 0;
  @Output() refresh = new EventEmitter()
  isLoading: boolean = false;
  forceUpdateWidth = new Subject();

  //Tabla
  displayedColumnsTabla: Array<iDataTableColumns> = [
    { label: 'Nº Factura', property: 'id' },
    { label: 'Fecha', property: 'fecha', align: 'center' },
    { label: 'Fecha cobro prevista', property: 'fechaCobro', align: 'center', },
    { label: 'Banco', property: 'banco', align: 'center', },
    { label: 'Importe', property: 'amount', align: 'center', format: 'currency' },
    { label: 'Saldo', property: 'balance', align: 'center', format: 'currency' },
    { label: 'Adjunto', property: 'adjunto', align: 'center', },
  ];

  totalPaginacion: number = 0;

  filter: iBillFilter = {
    num_devoluciones: 20,
    num_pagina: 1,
  }

  dataForTable:any = []

  optionsTabla: iDataTableOptions = {
    isSelectable: true,
    canSee: true,
    moreIconsHead: [{ callback: (event) => { this.exportexcel() }, icon: "download" }],

    isHovered: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc'
  }


  constructor(private router: Router, private staticUtilitiesSE: StaticUtilitiesService, private popupSE: PopupService, private billSE: BillService, private accountingSE: AccountingService) {
    super();
  }

  ngOnInit() {


  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['clienteId']) {
      this.getContabilidad()
    }
  }

  /**
   * FUNCTIONALITY
   */

  search(event) {
    if (!event) { delete this.filter.genericFilter; this.getContabilidad(); return; }
    this.filter.genericFilter = event;
    this.getContabilidad();
  }

  // checkcompra() {
  //   this.dataForTable.map((element, index) => {
  //     if (element.compra) {
  //       (document.querySelectorAll(".tableBodyContainer .tableBody tbody tr td[columntable='amount']")![index] as HTMLElement).classList.add("venta");
  //     } else {
  //       (document.querySelectorAll(".tableBodyContainer .tableBody tbody tr td[columntable='amount']")![index] as HTMLElement).classList.add("compra");
  //     }
  //   })
  // }

  checkSaldo() {
    this.dataForTable.map((element, index) => {
      if (element.balance < 0) {
        (document.querySelectorAll(".tableBodyContainer .tableBody tbody tr td[columntable='balance']")![index] as HTMLElement).classList.add("negativo");
      } else {
        (document.querySelectorAll(".tableBodyContainer .tableBody tbody tr td[columntable='balance']")![index] as HTMLElement).classList.add("venta");
      }
    })
  }

    /**
* DESCARGA DE PARTES EN EXCEL
*/

exportexcel(): void {
  let table = document.createElement("table")
  table.append((document.querySelector("dataTable .tableHeader thead") as HTMLElement).cloneNode(true))
  table.querySelector("thead .iconsActions")?.remove();
  table.querySelector("thead .checkboxContainer")?.remove();
  let rows = Array.from(document.querySelectorAll("dataTable .tableBody tbody tr "));
  let filteredRows = rows.filter((row) => {
    return row.querySelector('.checkboxContainer mat-checkbox ')!.getAttribute('ng-reflect-checked') === "true"
  })
  if (filteredRows.length > 0) {
    filteredRows.forEach((row) => {
      let newRow = row.cloneNode(true) as HTMLElement;
      newRow.querySelector('td:last-of-type')?.remove();
      newRow.querySelector('td:first-of-type')?.remove();
      table.append(newRow)
    })
  } else {
    table.append((document.querySelector("dataTable .tableBody tbody") as HTMLElement).cloneNode(true))
    table.querySelectorAll("tbody tr td:last-of-type").forEach(element => {
      element.remove();
    })
    table.querySelectorAll("tbody tr td:first-of-type").forEach(element => {
      element.remove();
    })
  }
  /* pass here the table id */
  const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table);

  /* generate workbook and add the worksheet */
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  /* save to file */
  XLSX.writeFile(wb, 'Contabilidad.xlsx');

}

  /**
   * PAGINATION
   */

  changePagination(event: any) {
    if (event === 'next') {
      this.filter.num_pagina++;
      this.getContabilidad();
    } else {
      this.filter.num_pagina--;
      this.getContabilidad();
    }
  }

  /**
 * Edit
 */

  edit(event) {

  }

  /**
   * Delete
   */

  delete(event, key) {
    switch (key) {
      case 'simple':
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'el esta fila de contabilidad' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
          if (!res) { return };
          this._unsubInd2.next('');
        })
        break;
      case 'all':
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'todas las filas de contabilidad' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
          if (!res) { return };
          this._unsubInd2.next('');
        })
        break;
      case 'selected':
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'las filas de contabilidad seleccionadas' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
          if (!res) { return };
          this._unsubInd2.next('');
        })
        break;
      default:
        break;
    }
  }

  /**
   * add
   */

  create() {
    this.popupSE.openPopup(CrearAsientoDetalleCompanyPopupComponent, { data:null, accion:'crear', companyId: this.clienteId, isArticle:true});
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
      if (!res) { return };
      this.getContabilidad();
      this._unsubInd2.next('');
    })
   }

  /**
   * API CALLS
   */

  getContabilidad() {
    this.isLoading = true;
    this.accountingSE.getAccountingTableClientOrSupplier(this.filter, this.clienteId);
    this.accountingSE.getResultAccountingTableClientOrSupplier().pipe(takeUntil(this._unsubInd)).subscribe(res => {
      if (!res) { return }
      let { data, total } = res.data;
      this.totalPaginacion = total;
      this.dataForTable = data?.map(element => { return { ...element, adjunto: element.attacheds?.length, fecha: iDate.javascriptConvert(new Date(element?.op_date)).toStringDate('EU'), fechaCobro: element?.bill?.payment_date ? iDate.javascriptConvert(new Date(element?.bill?.payment_date)).toStringDate('EU') : '' } })
      setTimeout(() => {
        this.forceUpdateWidth.next(true);
        this.checkSaldo()
        
      }, 10);
      this.isLoading = false;
      this._unsubInd.next('');
    })
  }

}


