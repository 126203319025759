import { Component, Input, OnInit } from '@angular/core';
import { iDataTableColumns, iDataTableOptions, iUnsubscribeDestroy, PopupService } from '@quasar_dynamics/basic-designsystem';
import { Subject, takeUntil } from 'rxjs';
import { EditarTarifasPopupComponent } from 'src/app/Popups/EditarTarifas-Popup/EditarTarifas-Popup.component';
import { ArticleService } from 'src/app/Services/Api/Article.service';
import { iArticleFilter } from 'src/app/Shared/Interfaces/iArticle';
import * as XLSX from 'xlsx';


@Component({
  selector: 'erpTarifasLiquida',
  templateUrl: './ERP-Tarifas-Liquida.component.html',
  styleUrls: ['./ERP-Tarifas-Liquida.component.scss']
})
export class ERPTarifasLiquidaComponent extends iUnsubscribeDestroy implements OnInit {
  @Input() maxFactor:number = 0 
  @Input() minAporte:number = 0;
  
  _reloadTableWidth = new Subject();
  isLoading: boolean = false;

  //Tabla
  displayedColumnsTabla: Array<iDataTableColumns> = [
    { label: 'Código', property: 'code' },
    { label: 'Producto', property: 'name', },
    { label: 'Cantidad', property: 'cantidad', align: "center", format: 'liters' },
    { label: 'Coste / L', property: 'costeL', align: "center", format:'currencyLiters' },
    { label: 'Coste / UD', property: 'costeUd', align: "center", format:'currencyUnit' },
    { label: 'Tarifa 1', property: 'rateOne', align: "center", format:'currency' },
    { label: 'Tarifa 2', property: 'rateTwo', align: "center", format:'currency' },
    { label: 'Aporte T2', property: 'aporte', align: "center", format:'currency' },
    { label: 'Factor', property: 'factor', align: "center", format:'percentaje' },
    { label: '24 meses', property: 'month', align: "center" },

  ];

  dataForTable: any = []

  optionsTabla: iDataTableOptions = {
    isSelectable: true,
    isHovered: true,
    canEdit: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc',
    moreIconsHead: [{ callback: (event) => { this.exportexcel() }, icon: "download" }],
  }
  totalPaginacion: number = 0;
  dataForTableFiltered: Array<any> = this.dataForTable;
  filter: iArticleFilter = {
    num_devoluciones: 20,
    num_pagina: 1,
    genericFilter: "",
  }
  constructor(private popupSE: PopupService, private articleSE:ArticleService) {
    super();
  }

  ngOnInit() {
    this.getRatesLiquid();
  }

  /**
  * GETTERS & SETTERS
  */
  getClientes() { }
  getProducto() { }

  getFilter(event?) {
    event != undefined ? this.filter = event : this.filter = this.filter;
  }

  /**
   * FUNCTIONALITIES
   */
  calculateData() {
    setTimeout(() => {
      this.dataForTable.map((element, index) => {
        if (element.aporte < this.minAporte) {
          (document.querySelectorAll(".tableBodyContainer .tableBody tbody tr td[columntable='aporte']")[index] as HTMLElement).style.color = "#ED1A23";
        }
        if (element.factor > this.maxFactor) {
          (document.querySelectorAll(".tableBodyContainer .tableBody tbody tr td[columntable='factor']")[index] as HTMLElement).style.color = "#ED1A23";
        }

      })
    }, 1);
  }
  edit(event: any) {
    this.popupSE.openPopup(EditarTarifasPopupComponent, { data: event, accion: "editar" });
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe((data) => {
      if(!data){return}
      this.getRatesLiquid()
      this._unsubInd2.next("")
    })
  }
  changePagination(event: any) {
    if (event === 'next') {
      this.filter.num_pagina++;
      this.getRatesLiquid();
    } else {
      this.filter.num_pagina--;
      this.getRatesLiquid();
    }
  }
  search(event: any) {
    if (!event.target.value) { delete this.filter.genericFilter; this.getRatesLiquid(); return }
    this.filter.genericFilter = event.target.value;
    this.getRatesLiquid();
  }
  /**
* DESCARGA DE PARTES EN EXCEL
*/

exportexcel(): void {
  let table = document.createElement("table")
  table.append((document.querySelector("dataTable .tableHeader thead") as HTMLElement).cloneNode(true))
  table.querySelector("thead .iconsActions")?.remove();
  table.querySelector("thead .checkboxContainer")?.remove();
  let rows = Array.from(document.querySelectorAll("dataTable .tableBody tbody tr "));
  let filteredRows = rows.filter((row) => {
    return row.querySelector('.checkboxContainer mat-checkbox ')!.getAttribute('ng-reflect-checked') === "true"
  })
  if (filteredRows.length > 0) {
    filteredRows.forEach((row) => {
      let newRow = row.cloneNode(true) as HTMLElement;
      newRow.querySelector('td:last-of-type')?.remove();
      newRow.querySelector('td:first-of-type')?.remove();
      table.append(newRow)
    })
  } else {
    table.append((document.querySelector("dataTable .tableBody tbody") as HTMLElement).cloneNode(true))
    table.querySelectorAll("tbody tr td:last-of-type").forEach(element => {
      element.remove();
    })
    table.querySelectorAll("tbody tr td:first-of-type").forEach(element => {
      element.remove();
    })
  }
  /* pass here the table id */
  const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table);

  /* generate workbook and add the worksheet */
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  /* save to file */
  XLSX.writeFile(wb, 'tarifaLiquidos.xlsx');

}

  
  /**
   * API CALL
   */

  getRatesLiquid() {
    this.isLoading = true;
    this.articleSE.getArticlesRatesGenericLiquid(this.filter);
    this.articleSE.getResultArticlesRates().pipe(takeUntil(this._unsubInd)).subscribe((res) => {
      if (!res) { return }
      let { data, total } = res.data;
      this.totalPaginacion = total
      this.dataForTable = data.map((element: any) => {return { ...element, factor : element.factor/100}});
      this.dataForTableFiltered = this.dataForTable;
      this.calculateData()
      setTimeout(() => {
        this._reloadTableWidth.next(true)
      }, 1);
      this.isLoading = false;
      this._unsubInd.next("")
    })
  }

}
