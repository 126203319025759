import { iBaseObject, iDate } from "@quasar_dynamics/basic-designsystem";
import { iMeasurementUnit } from "./iMeasurementUnit";
import { iGenericRate } from "./iGenericRate";
import { iContainer } from "./iContainer";
import { iFormula } from "./iFormula";

export interface iArticle {
    id?: number,
    code?: string,
    description?: string,
    realStock?: number,
    pendingStock?: number,
    virtualStock?: number,
    minStock?: number,
    name?: string,
    productionCost?: number,
    productionDate?: iDate,
    reservedStock?: number,
    isLiquid?: boolean,
    measurementUnit?: iMeasurementUnit,
    genericRate?: iGenericRate,
    container?: iContainer,
    secondaryCode?: string,
    formula?:iFormula,
}

export class iArticle extends iBaseObject {
    static convertFromCollection(ob: any): Array<iArticle> {
        let iArticleCollection: Array<iArticle> = [];
        ob.forEach((element: any) => {
            iArticleCollection.push(this.convertFromObject(element));
        });
        return iArticleCollection;
    }

    static convertFromObject(ob: any): iArticle {
        let iArticleObj = new iArticle();
        iArticleObj.id = ob.id;
        iArticleObj.code = ob.code;
        iArticleObj.secondaryCode = ob.secondaryCode;
        iArticleObj.description = ob.description;
        iArticleObj.realStock = ob.realStock;
        iArticleObj.pendingStock = ob.pendingStock;
        iArticleObj.virtualStock = ob.virtualStock;
        iArticleObj.minStock = ob.minStock;
        iArticleObj.name = ob.name;
        iArticleObj.productionCost = ob.productionCost;
        iArticleObj.productionDate = iDate.phpConvert(ob.productionDate);
        iArticleObj.reservedStock = ob.reservedStock;
        iArticleObj.isLiquid = ob.isLiquid;
        iArticleObj.measurementUnit = ob.measurementUnit ? iMeasurementUnit.convertFromObject(ob.measurementUnit) : iMeasurementUnit.createEmptyObject();
        iArticleObj.genericRate = ob.genericRate ? iGenericRate.convertFromObject(ob.genericRate) : iGenericRate.createEmptyObject();
        iArticleObj.container = ob.container ? iContainer.convertFromObject(ob.container) : iContainer.createEmptyObject();
        iArticleObj.formula = ob.formula ? iFormula.convertFromObject(ob.formula) : iFormula.createEmptyObject();
        return iArticleObj;
    }

    static createEmptyObject(): iArticle {
        let iArticleObj = new iArticle();
        iArticleObj.code = '';
        iArticleObj.description = '';
        iArticleObj.realStock = -1;
        iArticleObj.secondaryCode = '';
        iArticleObj.pendingStock = -1;
        iArticleObj.virtualStock = -1;
        iArticleObj.minStock = -1;
        iArticleObj.name = '';
        iArticleObj.productionCost = -1;
        iArticleObj.productionDate = iDate.javascriptConvert(new Date());
        iArticleObj.reservedStock = -1;
        iArticleObj.isLiquid = false;
        iArticleObj.measurementUnit = iMeasurementUnit.createEmptyObject();
        iArticleObj.genericRate = iGenericRate.createEmptyObject();
        iArticleObj.container = iContainer.createEmptyObject();
        iArticleObj.formula = iFormula.createEmptyObject();
        return iArticleObj;
    }
}

export interface iArticleFilter {
    num_devoluciones: number;
    num_pagina: number;
    genericFilter?: string;
    code?: string,
    description?: string,
    realStock?: number,
    pendingStock?: number,
    virtualStock?: number,
    minStock?: number,
    name?: string,
    productionCost?: number,
    productionDate?: string,
    reservedStock?: number,
    isLiquid?: boolean,
    measurementUnit?: number,
    genericRate?: number,
    container?: number,
}

