import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { iUnsubscribeDestroy } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { FamilyService } from 'src/app/Services/Api/Family.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'app-NuevaFamilia-Popup',
  templateUrl: './NuevaFamilia-Popup.component.html',
  styleUrls: ['./NuevaFamilia-Popup.component.scss']
})
export class NuevaFamiliaPopupComponent extends iUnsubscribeDestroy implements OnInit {

  descripcion: string = "";
  notas: string = ""
  codigo: string = ""
  isCompleted: boolean = false;
  dataEdit:any = {
    id:null,
  }
  constructor(public dialogRef: MatDialogRef<NuevaFamiliaPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any, public familySE:FamilyService) {
    super();
  }

  ngOnInit() {
    if (this.modalData.accion === "editar" || this.modalData.accion === "ver") {
      this.setter();
    }
  }

  /**
   * GETTES & SETTERS
   */

  setter() {
    this.descripcion = this.modalData.data.description;
    this.notas = this.modalData.data.note;
    this.codigo = this.modalData.data.code;
    this.dataEdit.id = this.modalData.data.id;
  }

  /**
  * FUNCTIONALITY
  */
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }
  editSecction(event,key:string){

    this.dataEdit[key] = event;
    this.checkIsCompleted();
    this.edit()
  }

  /**
   * VALIDATIONS
   */
  checkIsCompleted() {
    this.codigo = this.codigo.toUpperCase();
    this.descripcion && this.notas && this.codigo ? this.isCompleted = true : this.isCompleted = false
  }

  /**
   * CALLS TO API
   */

  edit(){
    this.familySE.update(this.dataEdit);
    this.familySE.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe((res)=>{
      if(!res){return}
      StaticUtilitiesService.showFeedback( 'Familia editada correctamente');
      this._unsubInd.next('');
    })
    this.familySE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res)=>{
      if(!res){return}
      StaticUtilitiesService.showError(res.message);
      this._unsub.next('');
    })
  }
  create(){
    let data = {
      description:this.descripcion,
      note:this.notas,
      code:this.codigo
    }
    this.familySE.create(data);
    this.familySE.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe((res)=>{
      if(!res){return}
      StaticUtilitiesService.showFeedback( 'Familia creada correctamente');
      this.closePopup('create')
      this._unsubInd.next('');
    })
    this.familySE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res)=>{
      if(!res){return}
      StaticUtilitiesService.showError(res.message);
      this._unsub.next('');
    })
  }
}
