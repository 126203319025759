import { Component, Input, OnChanges, OnInit, Output, SimpleChanges, EventEmitter } from '@angular/core';
import { iUnsubscribeDestroy } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { ProductionReportService } from 'src/app/Services/Api/ProductionReport.service';

@Component({
  selector: 'CierreDeFabricacionParteEnvases-Popup',
  templateUrl: './CierreDeFabricacionParteEnvases-Popup.component.html',
  styleUrls: ['./CierreDeFabricacionParteEnvases-Popup.component.scss']
})
export class CierreDeFabricacionParteEnvasesPopupComponent extends iUnsubscribeDestroy implements OnInit, OnChanges {

  @Input() parteId!: number;
  @Output() dataEnvases:EventEmitter<any> = new EventEmitter<any>();
  data:any = [];

  constructor(private productionReportSE:ProductionReportService) {
    super();
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['parteId']) {
      if (changes['parteId'].currentValue) {
        this.getEnvasesProductionReport()
      }
    }
  }

  /**
   * FUNCTIONALITY
   */

  checkIsComplete(){
    let productionReportDetail = this.data.map((item:any, index) => {
      if(index === 0){
        return {
          article: item.id,
          quantity: (Number(item.product) / 0.01),
          product:item.product,
        }
      }
      return {
        article: item.id,
        quantity: item.quantity,
        product:item.product,
      }
    })
    this.dataEnvases.emit(productionReportDetail);
  }

  /**
   * API CALLS
   */

  getEnvasesProductionReport() {
    this.productionReportSE.getPackagingId(this.parteId);
    this.productionReportSE.getResultPackagingId().pipe(takeUntil(this._unsubInd)).subscribe((res: any) => {
      if(!res) return;
      let {data} = res;
      this.data = data;
      this.checkIsComplete();
      this._unsubInd.next('');
    })
  }

}
