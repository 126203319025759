import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PopupService, iOptionsSelector, iUnsubscribeDestroy } from '@quasar_dynamics/basic-designsystem';
import { OrderService } from 'src/app/Services/Api/Order.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-EditarPedidoClientePopup',
  templateUrl: './EditarPedidoClientePopup.component.html',
  styleUrls: ['./EditarPedidoClientePopup.component.scss']
})
export class EditarPedidoClientePopupComponent extends iUnsubscribeDestroy implements OnInit {

  isCompleted:boolean = false;
  opcionesEstados: iOptionsSelector = {
    items: [
      { id: 1, label: "Pendiente" },
      { id: 2, label: "Completado" },
    ],
    placeholder: "Estado",
    append: true,
    bindValue:'label',
  }

  updateObject:any = {
    id: '',
    state:''
  }

  constructor(public dialogRef: MatDialogRef<EditarPedidoClientePopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any, private popupSE: PopupService, private orderSE:OrderService) {
    super();
  }

  ngOnInit() {
    if(this.modalData.accion !== 'crear'){
        this.setter();
    }
  }

  /**
   * GETTER && SETTER
   */

  setter(){
    this.updateObject.id = this.modalData.type === 'detalle' ? this.modalData.data.id :this.modalData.data.numPedido;
    this.updateObject.state = this.modalData.data.state;
  }

  /**
* FUNCTIONALITY
*/
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }
  checkIsCompleted(){
    if(this.updateObject.state !== ''){
      this.isCompleted = true;
    }else{
      this.isCompleted = false;
    }
  }

  /**
   * API CALLS
   */

  edit(){
    this.orderSE.update(this.updateObject);
    this.orderSE.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe((res:any)=>{
      if(!res){return}
      StaticUtilitiesService.showFeedback('Pedido actualizado correctamente')
      this._unsubInd.next('')
    })
    this.orderSE.getResultUpdateError().pipe(takeUntil(this._unsubInd)).subscribe((res:any)=>{
      if(!res){return}
      this.closePopup(res);
      StaticUtilitiesService.showError('No se pudo actualizar el pedido')
      this._unsubInd.next('')
    })
  }
}
