import { Component, OnInit } from '@angular/core';
import { iDataTableColumns, iDataTableOptions } from '@quasar_dynamics/basic-designsystem';

@Component({
  selector: 'app-ERP-Users',
  templateUrl: './ERP-Users.component.html',
  styleUrls: ['./ERP-Users.component.scss']
})
export class ERPUsersComponent implements OnInit {

  dataForTableFiltered: any = [
    {nombre:'Vicente Pastor', user:'vicentepastor@mopasa.com', rol:'Admin', estado:'Activo'},
    {nombre:'Vicente Pastor JR', user:'vpastorjr@mopasa.com', rol:'Admin', estado:'Activo'},
    {nombre:'Pedidos', user:'pedidos@mopasa.com', rol:'User', estado:'Activo'},
    {nombre:'Noelia Estrada', user:'noelia@mopasa.com', rol:'User', estado:'Activo'},
    {nombre:'Marketing', user:'marketing@mopasa.com', rol:'User', estado:'Activo'},
    {nombre:'Jiliano', user:'jiliando@mopasa.com', rol:'User', estado:'Activo'},
    {nombre:'Info', user:'info@mopasa.com', rol:'User', estado:'Activo'},
    {nombre:'Gerencia', user:'gerencia@mopasa.com', rol:'User', estado:'Activo'},
    {nombre:'Facturación', user:'factuiracion@mopasa.com', rol:'User', estado:'Activo'},
    {nombre:'Compras', user:'compras@mopasa.com', rol:'User', estado:'Activo'},
  ];
  optionsTabla: iDataTableOptions = {
    isSelectable: true,
    canEdit: true,
    canDelete: true,
    isHovered: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc'
  }
  displayedColumnsTabla: Array<iDataTableColumns> = [
    { label: 'Nombre', property: 'nombre' },
    { label: 'Usuario', property: 'user', },
    { label: 'Rol', property: 'rol', align: "center" },
    { label: 'Estado', property: 'estado', align: "center", classList:'tableEstado tableEstadoUser'   },

  ];



  constructor() { }

  ngOnInit() {
  }

}
