import { iBaseObject } from "@quasar_dynamics/basic-designsystem";
import { iFormula } from "./iFormula";
import { iArticle } from "./iArticle";
import { iRawMaterial } from "./iRawMaterial";

export interface iFormulaDetail {
    id: number,
    quantity?: number,
    indication?: string,
    sequence?: number,
    formula?: iFormula,
    article?: iArticle,
    rawMaterial?: iRawMaterial,
}

export class iFormulaDetail extends iBaseObject {
    static convertFromCollection(ob: any): Array<iFormulaDetail> {
        let iFormulaDetailCollection: Array<iFormulaDetail> = [];
        ob.forEach((element: any) => {
            iFormulaDetailCollection.push(this.convertFromObject(element));
        });
        return iFormulaDetailCollection;
    }

    static convertFromObject(ob: any): iFormulaDetail {
        let iFormulaDetailObj = new iFormulaDetail();
        iFormulaDetailObj.id = ob.id;
        iFormulaDetailObj.quantity = ob.quantity;
        iFormulaDetailObj.indication = ob.indication;
        iFormulaDetailObj.sequence = ob.sequence;
        iFormulaDetailObj.formula = ob.formula ? iFormula.convertFromObject(ob.formula) : iFormula.createEmptyObject();
        iFormulaDetailObj.article = ob.article ? iArticle.convertFromObject(ob.article) : iArticle.createEmptyObject();
        iFormulaDetailObj.rawMaterial = ob.rawMaterial ? iRawMaterial.convertFromObject(ob.rawMaterial) : iRawMaterial.createEmptyObject();
        return iFormulaDetailObj;
    }

    static createEmptyObject(): iFormulaDetail {
        let iFormulaDetailObj = new iFormulaDetail();
        iFormulaDetailObj.quantity = -1;
        iFormulaDetailObj.indication = '';
        iFormulaDetailObj.sequence = -1;
        iFormulaDetailObj.formula = iFormula.createEmptyObject();
        iFormulaDetailObj.article = iArticle.createEmptyObject();
        iFormulaDetailObj.rawMaterial = iRawMaterial.createEmptyObject();
        return iFormulaDetailObj;
    }
}

export interface iFormulaDetailFilter {
    num_devoluciones: number;
    num_pagina: number;
    genericFilter?: string;
    quantity?: number,
    indication?: string,
    sequence?: number,
    formula?: number,
    article?: number,
    rawMaterial?: number,
}