import { Component, OnInit } from '@angular/core';
import { ChartConfiguration } from 'chart.js';

@Component({
  selector: 'barChart',
  templateUrl: './BarChart.component.html',
  styleUrls: ['./BarChart.component.scss']
})
export class BarChartComponent implements OnInit {
  public barChartLegend = false;
  public barChartPlugins = [];

  public barChartData: ChartConfiguration<'bar'>['data'] = {
    labels: [ 1546, 1546,1546,1546,1546,1546,1546,1546,1546,1546,1546,1546,1546,1546,1546],
    datasets: [
      {
        data: [ 65, 59, 80, 81, 56, 55, 40, 14, 9, 18, 65, 59, 80, 81, 56 ],
        barThickness: 40,
        backgroundColor: '#FF979F',
        hoverBackgroundColor: '#FF979F',
        borderWidth: 2,
        borderColor: '#FF5664',
        hoverBorderColor: '#FF5664',
        borderSkipped: false,
        borderRadius: 32
      },

    ]
  };

  public barChartOptions: ChartConfiguration<'bar'>['options'] = {
    responsive: true,

    scales: {
      x: {
        grid: {
          lineWidth: 0,
        }, ticks: {
          color: "#000000",

        },
      },
      y: {
        min: 0,
        grid: {
          color: '#dfe0f5',
          drawBorder: false,
        },
      }
    },
    plugins: {
      legend: {
        display: false,
      }
    }

  };
  constructor() { }

  ngOnInit() {
  }

}
