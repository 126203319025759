<div class="fullWidthScreen">
  <div class="loginContainer" [ngClass]="{activedAni: isAnim}">
    <div class="teldomo">
      <img src="/assets/Icons/savzf21728661_1656464081070713_7844506453742645810_o.png" class="logoTeldomo">
    </div>
    <div class="containerContent">
      <div class="contentFlex">
        <div class="content">
          <ng-content></ng-content>
        </div>
        <div class="downContent">
          <ng-content select="[downContent]"></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>