import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { iOptionsSelector, iUnsubscribeDestroy } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { ArticleService } from 'src/app/Services/Api/Article.service';
import { CompanyService } from 'src/app/Services/Api/Company.service';
import { RatesCustomerService } from 'src/app/Services/Api/RatesCustomer.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'app-NuevoClientePolvo-Popup',
  templateUrl: './NuevoClientePolvo-Popup.component.html',
  styleUrls: ['./NuevoClientePolvo-Popup.component.scss']
})
export class NuevoClientePolvoPopupComponent extends iUnsubscribeDestroy implements OnInit {
  opcionesClientes: iOptionsSelector = {
    items: [],
    clearable: true,
    search: true,
    placeholder: "Cliente",
    append: true
  }
  
  opcionesProducto: iOptionsSelector = {
    items: [],
    clearable: true,
    search: true,
    placeholder: "Producto",
    append: true
  }
  clientes: any = null;
  productos: any = null;
  venta: number | null = null;
  isCompleted: boolean = false;
  updateObject: any = {
    id: '',
  };
  constructor(public dialogRef: MatDialogRef<NuevoClientePolvoPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any, private ratesCustomerSE: RatesCustomerService, private companySE: CompanyService, private articleSE: ArticleService) {
    super();
  }

  ngOnInit() {
     
    if (this.modalData.accion !== 'crear') {
      this.setter();
    }
    this.getClientesForFilter();
    this.getProductsForFilter()
  }

  /**
   * GETTERS && SETTERS
   */

  setter() {
    this.clientes = this.modalData.data.cliente;
    this.productos = this.modalData.data.producto;
    this.venta = this.modalData.data.venta;
    this.updateObject.id = this.modalData.data.RatesCustomerId;
  }
  editSelector(event, key: string) {
    if (!event) { return };
    this.updateObject[key] = event.id;
    this.checkIsCompleted();
    this.edit();
  }
  editSecction(event, key: string) {
    if (!event) { return };
    this.updateObject[key] = event;
    this.checkIsCompleted();
    this.edit();
  }
  /**
  * FUNCTIONALITY
  */
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }

  /**
   * VALIDATIONS
   */
  checkIsCompleted() {
    if (this.modalData.accion === 'crear') {
      this.productos && this.clientes && this.venta  ? this.isCompleted = true : this.isCompleted = false
    } else {
      this.venta? this.isCompleted = true : this.isCompleted = false
    }
  }

  /**
   * API CALLS
   */

  edit() {
    this.ratesCustomerSE.update(this.updateObject);
    this.ratesCustomerSE.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe((res) => {
      if (!res) { return }
      StaticUtilitiesService.showFeedback('Tarifa actualizada correctamente')
      this._unsubInd.next('')
    })
    this.ratesCustomerSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res) => {
      if (!res) { return }
      StaticUtilitiesService.showError('Error al actualizar la tarifa')
      this._unsub.next('')
    })
  }

  getClientesForFilter() {
    this.companySE.getClientOrProveedor({ num_devoluciones: 500, num_pagina: 1, isClient: true });
    this.companySE.getResult().pipe(takeUntil(this._unsubInd2)).subscribe((res) => {
      if (!res) { return }
      let { data } = res.data;
       
      this.opcionesClientes.items = data.map((item) => {
        return { id: item.id, label: `${item.id} - ${item.name}` }
      })
      this._unsubInd2.next('')
    })
  }
  getProductsForFilter() {
    this.articleSE.getAll({ num_devoluciones: 500, num_pagina: 1, isLiquid: false });
    this.articleSE.getResult().pipe(takeUntil(this._unsubInd3)).subscribe((res) => {
      if (!res) { return }
      let { data } = res.data;
       
      this.opcionesProducto.items = data.map((item) => {
        return { id: item.id, label: `${item.code}` }
      })
      this._unsubInd3.next('')
    })
  }
  create() {
    let data: any = {
      price: this.venta,
      article: this.productos?.id,
      company: this.clientes?.id,
    }
    this.ratesCustomerSE.create(data);
    this.ratesCustomerSE.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe((res) => {
      if(!res){return}
      StaticUtilitiesService.showFeedback('Tarifa creada correctamente')
      this.closePopup('create')
      this._unsubInd.next('')
    })
    this.ratesCustomerSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res) => {
      if(!res){return}
      StaticUtilitiesService.showError('Error al crear la tarifa')
      this._unsub.next('')
    })
  }

}
