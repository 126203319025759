import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { iUnsubscribeDestroy } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { TypesIvaService } from 'src/app/Services/Api/TypesIva.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'app-NuevoTipoIva-Popup',
  templateUrl: './NuevoTipoIva-Popup.component.html',
  styleUrls: ['./NuevoTipoIva-Popup.component.scss']
})
export class NuevoTipoIvaPopupComponent extends iUnsubscribeDestroy implements OnInit {

  descripcion: string = "";
  valor: number | null = null;
  isCompleted: boolean = false;
  updateObject: any = {
    id: 0,
  }
  constructor(public dialogRef: MatDialogRef<NuevoTipoIvaPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any, private typesIvaSE:TypesIvaService) {
    super();
  }

  ngOnInit() {
     
    if (this.modalData.accion === 'editar' || this.modalData.accion === 'ver') {
      this.setter()
    }
  }

  /**
   * GETTER && SETTER
   */

  setter() {
    this.descripcion = this.modalData.data.description
    this.valor = this.modalData.data.value
    this.updateObject.id = this.modalData.data.id
  }
  editSecction(event, key:string){
    if(!event){return};
    this.updateObject[key] = event;
    this.checkIsCompleted();
    this.update();
  }
  /**
  * FUNCTIONALITY
  */
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }

  /**
   * VALIDATIONS
   */
  checkIsCompleted() {
    this.descripcion && this.valor ? this.isCompleted = true : this.isCompleted = false
  }

  /**
   * CALLS TO API
   */

  update(){
    this.typesIvaSE.update(this.updateObject);
    this.typesIvaSE.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe((res:any)=>{
      if(!res){return};
      StaticUtilitiesService.showFeedback('Tipo de IVA actualizado correctamente')
      this._unsubInd.next('');
    })
    this.typesIvaSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res:any)=>{
      if(!res){return};
      StaticUtilitiesService.showError(res.message)
      this._unsub.next('');
    })
  }
  create(){
    let data:any = {
      description: this.descripcion,
      value: this.valor
    }
    this.typesIvaSE.create(data);
    this.typesIvaSE.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe((res:any)=>{
      if(!res){return};
      StaticUtilitiesService.showFeedback('Tipo de IVA creado correctamente')
      this.closePopup()
      this._unsubInd.next('');
    })
    this.typesIvaSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res:any)=>{
      if(!res){return};
      StaticUtilitiesService.showError(res.message)
      this.closePopup()
      this._unsub.next('');
    })
  }
}
