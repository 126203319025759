import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { iDataTableColumns, iDataTableOptions, iDate, iUnsubscribeDestroy, PopupService } from '@quasar_dynamics/basic-designsystem';
import { ConfirmPopupComponent } from 'src/app/Popups/ConfirmPopup/ConfirmPopup.component';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';
import { takeUntil } from 'rxjs';
import { BillService } from 'src/app/Services/Api/Bill.service';
import { iBillFilter } from 'src/app/Shared/Interfaces/iBill';
import { AccountingService } from 'src/app/Services/Api/Accounting.service';
import { CrearAsientoDetalleCompanyPopupComponent } from 'src/app/Popups/CrearAsientoDetalleCompany-Popup/CrearAsientoDetalleCompany-Popup.component';

@Component({
  selector: 'erpProveedoresDetallesContabilidad',
  templateUrl: './ERP-Proveedores-Detalles-Contabilidad.component.html',
  styleUrls: ['./ERP-Proveedores-Detalles-Contabilidad.component.scss']
})
export class ERPProveedoresDetallesContabilidadComponent extends iUnsubscribeDestroy implements OnInit {

  isLoading: boolean = false;

  @Input() data:any = [];


  //Tabla
  displayedColumnsTabla: Array<iDataTableColumns> = [
    { label: 'Nº Pedido', property: 'id' },
    { label: 'Fecha', property: 'fecha', align: 'center' },
    // { label: 'Fecha cobro prevista', property: 'fechaCobro', align: 'center', },
    // { label: 'Banco', property: 'banco', align: 'center',  },
    { label: 'Importe', property: 'amount', align: 'center', format: 'currency' },
    { label: 'Saldo', property: 'balance', align: 'center', format: 'currency' },
  ];

  dataForTable = []

  optionsTabla: iDataTableOptions = {
    isSelectable: true,
    canEdit: true,
    canSee: true,
    moreIconsHead: [
      { icon: 'download', callback: (event) => { console.log(event) } },
    ],
    canDelete: true,
    isHovered: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc'
  }
  filter:iBillFilter = {
    num_devoluciones:20,
    num_pagina:1,
  }
  totalPaginacion:number = 0;


  constructor(private router: Router, private staticUtilitiesSE: StaticUtilitiesService, private popupSE: PopupService, private billSE:BillService, private accountingSE:AccountingService) {
    super();
  }

  ngOnInit() {
  }
  
  ngOnChanges(changes:SimpleChanges){
    if(changes['data']){
      if (Object.keys(changes['data'].currentValue).length > 0) {
        this.data = changes['data'].currentValue;
        this.getAllContabilidad();
      }
    }
  }


  checkcompra(){
    this.dataForTable.map((element:any, index) => {
      if(element.compra){
        (document.querySelectorAll(".tableBodyContainer .tableBody tbody tr td[columntable='importe']")![index] as HTMLElement).classList.add("venta");
      }else{
        (document.querySelectorAll(".tableBodyContainer .tableBody tbody tr td[columntable='importe']")![index] as HTMLElement).classList.add("compra");
      }
    })
  }

  checkSaldo(){
    this.dataForTable.map((element:any, index) => {
      if(element.saldo < 0){
        (document.querySelectorAll(".tableBodyContainer .tableBody tbody tr td[columntable='saldo']")![index] as HTMLElement).classList.add("negativo");
      }else{
        (document.querySelectorAll(".tableBodyContainer .tableBody tbody tr td[columntable='saldo']")![index] as HTMLElement).classList.add("venta");
      }
    })
  }


/**
 * pagination
 */

changePagination(event: any) {
  if (event === 'next') {
    this.filter.num_pagina++;
    this.getAllContabilidad();
  } else {
    this.filter.num_pagina--;
    this.getAllContabilidad();
  }
}

  /**
 * Edit
 */

  edit(event) {

  }

  /**
   * Delete
   */

  delete(event, key) {
    switch (key) {
      case 'simple':
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'el esta fila de contabilidad' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
          if (!res) { return };
          this._unsubInd2.next('');
        })
        break;
      case 'all':
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'todas las filas de contabilidad' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
          if (!res) { return };
          this._unsubInd2.next('');
        })

        break;
      case 'selected':
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'las filas de contabilidad seleccionadas' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
          if (!res) { return };
          this._unsubInd2.next('');
        })
        break;
      default:
        break;
    }
  }

  search(event, key) {
    if(!event){delete this.filter[key]; this.getAllContabilidad() ;return}
    this.filter[key] = event;
    this.getAllContabilidad();
  }

  /**
   * See cliente
   */

  see(event) {
    // this.staticUtilitiesSE.goTo('/erp/clientes/detalles-cliente', slideNavigationTypes.slideToTop, {cliente: event.id})

  }

  /**
   * add
   */

  create() { 
    this.popupSE.openPopup(CrearAsientoDetalleCompanyPopupComponent, { data:null, accion:'crear', companyId: this.data.id, isArticle:false});
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
      if (!res) { return };
      this.getAllContabilidad();
      this._unsubInd2.next('');
    })

  }


  /**
   * API CALLS
   */

  getAllContabilidad(){
    this.isLoading = true;
    this.accountingSE.getAccountingTableClientOrSupplier(this.filter, this.data.id);
    this.accountingSE.getResultAccountingTableClientOrSupplier().pipe(takeUntil(this._unsubInd)).subscribe(res => {
      if(!res){return}
      let {data, total} = res.data;
      this.dataForTable = data?.map((element:any) => {return {...element, fecha: iDate.javascriptConvert(new Date(element.op_date)).toStringDate('EU'), fechaCobro: iDate.javascriptConvert(new Date(element.op_date)).toStringDate('EU')}});
      this.totalPaginacion = total;
      setTimeout(() => {
        this.checkcompra()
        this.checkSaldo()
        }, 1);
      this.isLoading = false;
      this._unsubInd.next('');
    })
  }
}
