<div class="fichaGeneralContainer">
  <pill>
    <ng-container>
      <div class="info">

        <div class="row">
          <div class="column">
            <label for="">Número pedido:</label>
            <span>{{data.id}}</span>
          </div>
          <div class="column">
            <label for="">Fecha pedido:</label>
            <span>{{data.date | date: 'dd/MM/yyyy' }}</span>
          </div>
        </div>
        <div class="row">
          <div class="column">
            <label for="">Proveedor:</label>
            <span>{{data.company?.code}} - {{data.company?.name}}</span>
          </div>
          <div class="column">
            <label for="">Email pedidos:</label>
            <span>{{data.company?.orderEmails}}</span>
          </div>
        </div>
        <div class="row">
          <div class="column">
            <label for="">Forma de pago:</label>
            <span>{{data.company?.paymentMethods[0]?.wayToPay?.description}}</span>
          </div>
          <div class="column">
            <label for="">Fecha de pago: </label>
            <span>{{data.payDate | date: 'dd/MM/yyyy' }}</span>
          </div>
        </div>
        <div class="row">
          <div class="column">
            <label for="">Tipo portes:</label>
            <span>Recoger en fábrica</span>
          </div>
          <div class="column">
            <label for=""></label>
            <span></span>
          </div>
        </div>
      </div>
    </ng-container>
  </pill>
  <pill>
    <ng-container>
      <div class="info">
        <div class="icons">
          <span class="material-icons icon" (click)="subirDocumento()">
            add
          </span>
          <span class="material-icons icon" (click)="verDocumentos(true)" [class.disabled]="documentos.length == 0">
            edit
          </span>
        </div>

        <div class="row">
          <div class="column special">
            <label for="">Adjuntos:</label>
            <div class="documentos">
              <div class="documento" *ngFor="let documento of documentos | slice:0:2; let i = index;">
                <label for="">Documento {{i+1}}:</label>
                <a [href]="documento.url" target="_blank">
                  <img src="/assets/Icons/pdf.svg" alt="">
                  <span>
                    Documento {{i+1}}
                  </span>
                </a>
              </div>
            </div>
            <p class="noData" *ngIf="documentos.length == 0">No hay adjuntos</p>
            <span class="verTodo" (click)="verDocumentos()" *ngIf="documentos.length > 0">Ver todos</span>
          </div>
        </div>
      </div>
    </ng-container>
  </pill>

</div>
