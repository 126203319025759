<div class="fullAccordeon">
  <filterActions [activeBtn]="false" [disableFilter]="true" (searchSend)="search($event)">
    <ng-container filterContent>
      <div class="containerSelectores">
        <div>
        </div>
        <div>
        </div>
      </div>
    </ng-container>
  </filterActions>
  <Pagination [paginacionTotal]="totalPaginacion" (change)="changePagination($event)"></Pagination>

<pill class="tablePil" classList="tablePill">
 
  <dataTable [forceUpdateWidth]="_reloadTableWidth" [data]="dataForTable" [isLoading]="isLoading" [options]="optionsTabla" [displayedColumns]="displayedColumnsTabla"  (clickRow)="see($event)" (see)="see($event)" (clickRow)="clickRow($event)" (see)="clickRow($event)"></dataTable>
</pill>
</div>
