import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { iUnsubscribeDestroy } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { PorteService } from 'src/app/Services/Api/Porte.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'app-NuevoPortes-Popup',
  templateUrl: './NuevoPortes-Popup.component.html',
  styleUrls: ['./NuevoPortes-Popup.component.scss']
})
export class NuevoPortesPopupComponent extends iUnsubscribeDestroy implements OnInit {

  descripcion: string = "";
  name: number | null = null;
  isCompleted: boolean = false;
  updateObject: any = {
    id: '',
  };
  constructor(public dialogRef: MatDialogRef<NuevoPortesPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any, private porteSE:PorteService) {
    super();
  }

  ngOnInit() {
     
    if (this.modalData.accion !== "create") {
      this.setter()
    }
  }

  /**
   * GETTERS && SETTERS
   */

  setter() {
    this.descripcion = this.modalData.data.description;
    this.name = this.modalData.data.name;
    this.updateObject.id = this.modalData.data.id;
  }
  editSection(event,key){
    if(!event){return}
    this.updateObject[key] = event;
    this.checkIsCompleted()
    this.edit()
  }

  /**
  * FUNCTIONALITY
  */
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }

  /**
   * VALIDATIONS
   */
  checkIsCompleted() {
    this.descripcion && this.name ? this.isCompleted = true : this.isCompleted = false
  }

  /**
   * API CALLS
   */

  edit(){
    this.porteSE.update(this.updateObject);
    this.porteSE.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe((res) => {
      if(!res){return;}
      StaticUtilitiesService.showFeedback('Parte actualizado correctamente')
      this._unsubInd.next('')
    })
    this.porteSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res) => {
      if(!res){return;}
      StaticUtilitiesService.showError('El parte no se pudo acctualizar')
      this._unsub.next('')
    })
  }
  create(){
    let data:any = {
      description: this.descripcion,
      name: this.name
    }
    this.porteSE.create(data);
    this.porteSE.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe((res) => {
      if(!res){return;}
      StaticUtilitiesService.showFeedback('Parte creado correctamente')
      this.closePopup('create')
      this._unsubInd.next('')
    })
    this.porteSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res) => {
      if(!res){return;}
      StaticUtilitiesService.showError('El parte no se pudo crear')
      this._unsub.next('')
    })
  }


}
