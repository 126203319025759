import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PopupAlbaranService {

  _test: string = ""

  constructor() { }

  setTest(str: string) {
    this._test = str
  }
  getTest() {
    return this._test
  }

}
