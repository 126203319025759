<sectionLogin-containerLogin [toggleAnim]="animSend" (finishAnim)="finishAnim()">
  <div class="centerContainer">
    <div class="mainBlock">
      <div class="header">
        <h2>¡Bienvenido!</h2>
      </div>
      <form action="submit">

        <div class="contentBlock">
          <CustomInput classList="fullWidth tall" name="email" placeholder="Introduce tu correo electrónico" label="Email" [(ngModel)]="data.username" (change)="checkComplete()"></CustomInput>
          <CustomInput classList="fullWidth tall" name="password" placeholder="Contraseña" type="password" label="Contraseña" [(ngModel)]="data.password" (keyup)="checkComplete()">
            <ng-container extraLabelContent>
              <a class="lostPassword" routerAnimationLeft [routerLink]="['/forgotPassword']" >¿Has olvidado tu contraseña?</a>
            </ng-container>
          </CustomInput>
          <btn classList="fullWidth" [class.disabled]="disable" id="submitButton" (keyup.enter)="login($event)" (click)="login($event)" >iniciar sesión</btn>
        </div>
      </form>
      <ng-container downContent>
        <div class="termsContainer">
        </div>
      </ng-container>
    </div>
  </div>
  
</sectionLogin-containerLogin>