import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  iStaticUtilities,
  iUnsubscribeDestroy,
} from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { FamilyService } from 'src/app/Services/Api/Family.service';
import { FormulaService } from 'src/app/Services/Api/Formula.service';
import { SubfamilyService } from 'src/app/Services/Api/Subfamily.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'app-NuevaFormula-Popup',
  templateUrl: './NuevaFormula-Popup.component.html',
  styleUrls: ['./NuevaFormula-Popup.component.scss'],
})
export class NuevaFormulaPopupComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  isCompleted: boolean = false;
  timeLineStep: number = 1;
  nuevoMaterialFlag: boolean = true;
  nuevaExplicacionFlag: boolean = true;
  cuadrarFlag: boolean = true;
  envaseFlag: boolean = true;
  fungibleFlag: boolean = true;
  caracteristicaFlag: boolean = true;
  step1Data: any = null;
  step2Data: any = null;
  step3Data: any = null;
  step4Data: any = null;
  step5Data: any = null;
  costData: any = null;
  toEditData: any = null;
  flagStep2: boolean = false;
  updateObject: any = {
    id: 0,
  };
  constructor(
    public dialogRef: MatDialogRef<NuevaFormulaPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private formulaSE: FormulaService
  ) {
    super();
  }

  ngOnInit() {
    if (this.modalData.accion === 'editar') {
      this.getFormulaById(this.modalData.data.id);
      this.updateObject.id = this.modalData.data.id;
    }
  }

  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }
  nextStep() {
    this.timeLineStep++;
    this.checkIsCompleted();
  }
  previousStep() {
    if (this.timeLineStep > 1) {
      this.timeLineStep--;
      this.checkIsCompleted();
    }
  }
  nuevoMaterial() {
    this.nuevoMaterialFlag
      ? (this.nuevoMaterialFlag = false)
      : (this.nuevoMaterialFlag = true);
  }
  nuevaExplicacion() {
    this.nuevaExplicacionFlag
      ? (this.nuevaExplicacionFlag = false)
      : (this.nuevaExplicacionFlag = true);
  }
  cuadrar() {
    this.cuadrarFlag ? (this.cuadrarFlag = false) : (this.cuadrarFlag = true);
  }
  nuevoEnvase() {
    this.envaseFlag ? (this.envaseFlag = false) : (this.envaseFlag = true);
  }
  nuevaCaracteristica() {
    this.caracteristicaFlag
      ? (this.caracteristicaFlag = false)
      : (this.caracteristicaFlag = true);
  }
  nuevoFungible() {
    this.fungibleFlag
      ? (this.fungibleFlag = false)
      : (this.fungibleFlag = true);
  }
  costDataFunction(event) {
    this.costData = event;
  }
  recall(event) {
    if (event) {
      this.getFormulaById(this.modalData.data.id);
    }
  }
  /**
   * VALIDATIONS
   */
  checkIsCompleted() {
    switch (this.timeLineStep) {
      case 1:
        let copyStep1Data = { ...this.step1Data };
        delete copyStep1Data.objeto;

        if (StaticUtilitiesService.isNullObject(copyStep1Data)) {
          this.isCompleted = false;
          return;
        }
        StaticUtilitiesService.isCompleteObject(copyStep1Data)
          ? (this.isCompleted = true)
          : (this.isCompleted = false);
        break;
      case 2:
        if (
          this.step2Data?.length == 0 ||
          !this.step2Data ||
          !this.step2Data[0]
        ) {
          this.isCompleted = false;
          return;
        }
        let copyStep2Data!: any[];
        if (Array.isArray(this.step2Data)) {
          copyStep2Data = this.step2Data;
        } else {
          copyStep2Data = this.step2Data;
        }
        let mappedStep2Data = copyStep2Data?.map((object) => {
          if (!object?.descripcion) delete object?.descripcion;
          if (!object?.article && object?.rawMaterial) delete object?.article;
          if (!object?.rawMaterial && object?.article)
            delete object?.rawMaterial;
          if (!object?.indication) delete object?.indication;
          return object;
        });
        if (StaticUtilitiesService.isNullObject(mappedStep2Data)) {
          this.isCompleted = false;
          return;
        }
        StaticUtilitiesService.isEmptyArray(this.step2Data)
          ? (this.isCompleted = false)
          : (this.isCompleted = true);

        break;
      case 3:
        if (StaticUtilitiesService.isNullObject(this.step3Data)) {
          this.isCompleted = false;
          return;
        }
        if (
          this.step3Data.filter((element) => {
            return element.id == null;
          }).length == 0
        ) {
          this.isCompleted = true;
        } else {
          this.isCompleted = false;
        }
        break;
        if (StaticUtilitiesService.isNullObject(this.step5Data)) {
          this.isCompleted = false;
          return;
        }
        if (
          this.step5Data.filter((element) => {
            return element.id == null;
          }).length == 0
        ) {
          this.isCompleted = true;
        } else {
          this.isCompleted = false;
        }
        break;
    }
  }
  setData(event, step) {
    switch (step) {
      case this.step2Data:
        this.step2Data = event;
        break;

      case this.step3Data:
        this.step3Data = event;

        if (this.modalData.accion === 'editar') {
          let idMap = this.toEditData.container.map((element) => {
            return element.id;
          });

          let ids = this.step3Data.filter((element, index) => {
            return idMap.includes(element.id) == false;
          });
          if (ids.length <= 0) return;

          this.updateObject.formulaContainer = ids.map((element, index) => {
            return element.id;
          });
          this.updateObject.article = ids.map((element, index) => {
            return {
              code: this.step1Data.codigo + element.codigo,
              description: `${element.descripcion}`,
              realStock: 0,
              pendingStock: 0,
              reservedStock: 0,
              virtualStock: 0,
              minStock: 0,
              name: `${this.step1Data.nombre} ${element.descripcion}`,
              productionCost:
                this.step1Data.tipo == 'polvo'
                  ? this.costData.totalKg
                  : this.costData.totalL,
              measurementUnit:
                this.step1Data.unidadMedida == 'Kilogramos' ? 1 : 2,
              isLiquid: this.step1Data.tipo == 'polvo' ? false : true,
              container: element.id,
              genericRate: {
                price: 0,
                rateOne: 0,
                rateTwo: 0,
                contributionRateTwo: 0,
                factor: 0,
              },
            };
          });
          delete this.updateObject.formulaDetail;
          this.editFormula();
        }
        break;

      case this.step4Data:
        this.step4Data = event;
        if (this.modalData.accion === 'editar') {
          this.updateObject.characteristics = event.map((element, index) => {
            return { characteristic: element.id, value: element.value };
          });
          delete this.updateObject.formulaDetail;
          delete this.updateObject.article;
          this.editFormula();
        }
        break;

      case this.step5Data:
        this.step5Data = event;
        if (this.modalData.accion === 'editar') {
          let idMap = this.toEditData.fungible.map((element) => {
            return element.id;
          });
          let ids = event.filter((element, index) => {
            return idMap.includes(element.id) == false;
          });
          if (ids.length <= 0) return;
          this.updateObject.fungible = ids.map((element, index) => {
            return element.id;
          });
          delete this.updateObject.formulaDetail;
          delete this.updateObject.article;
          delete this.updateObject.characteristics;
          this.editFormula();
        }
        break;
      default:
        break;
    }
  }

  mapDataToEditStep2(formulaDetailsArray) {
    return formulaDetailsArray.map((element, index) => {
      if (element.article) {
        return {
          article: element.article.id,
          cantidad: element.cantidad,
          codigoMaterial: element.codigoMaterial,
          codigoSecundario: element.codigoSecundario,
          coste: element.coste,
          densidad: element.densidad,
          descripcion: element.descripcion,
          especial: element.especial,
          formulaDetailId: element.formulaDetailId,
          id: element.id,
          label: element.label,
          quantity: element.quantity,
          readonly: element.readonly,
          sequence: element.sequence,
        };
      } else {
        return {
          rawMaterial: element.rawMaterial.id,
          cantidad: element.cantidad,
          codigoMaterial: element.codigoMaterial,
          codigoSecundario: element.codigoSecundario,
          coste: element.coste,
          densidad: element.densidad,
          descripcion: element.descripcion,
          especial: element.especial,
          formulaDetailId: element.formulaDetailId,
          id: element.id,
          label: element.label,
          quantity: element.quantity,
          readonly: element.readonly,
          sequence: element.sequence,
        };
      }
    });
  }

  /**
   * API CALLS
   */

  create() {
    let data: any = {
      code: this.step1Data.codigo,
      name: this.step1Data.nombre,
      density: this.step1Data.densidad,
      object: this.step1Data.objeto,
      observation: this.step1Data.nombre,
      finalObservation: this.costData.observation,
      family: this.step1Data.familia,
      subfamily: this.step1Data.subFamilia,
      type: this.step1Data.tipo,
      measurementUnit: this.step1Data.unidadMedida == 'Kilogramos' ? 1 : 2,
      formulaDetail: this.step2Data.map((element, index) => {
        if (element.especial) {
          return {
            sequence: index + 1,
            quantity: null,
            indication: element.explicacion,
          };
        }
        if (!element.especial) {
          if (element.codigoSecundario === 'articulo') {
            return {
              article: element.id,
              sequence: index + 1,
              quantity: element.cantidad,
              indication: null,
            };
          } else {
            return {
              rawMaterial: element.id,
              sequence: index + 1,
              quantity: element.cantidad,
              indication: null,
            };
          }
        }
        return;
      }),
      article: this.step3Data.map((element, index) => {
        return {
          code: this.step1Data.codigo + element.codigo,
          description: `${element.descripcion}`,
          realStock: 0,
          pendingStock: 0,
          reservedStock: 0,
          virtualStock: 0,
          minStock: 0,
          name: `${this.step1Data.nombre} ${element.descripcion}`,
          productionCost:
            this.step1Data.tipo == 'polvo'
              ? this.costData.totalKg
              : this.costData.totalL,
          measurementUnit: this.step1Data.unidadMedida == 'Kilogramos' ? 1 : 2,
          isLiquid: this.step1Data.tipo == 'polvo' ? false : true,
          container: element.id,
          genericRate: {
            price: 0,
            rateOne: 0,
            rateTwo: 0,
            contributionRateTwo: 0,
            factor: 0,
          },
        };
      }),
      characteristics: this.step4Data?.map((element, index) => {
        return { characteristic: element.id, value: element.value };
      }),
      formulaContainer: this.step3Data.map((element, index) => element.id),
      fungible: this.step5Data?.map((element, index) => element.id),
      specification: {
        density: 0,
        color: '',
        humidity: '',
        hasTemperature: false,
      },
    };

    this.formulaSE.create(data);
    this.formulaSE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (!res) return;
        this.closePopup('create');
        StaticUtilitiesService.showFeedback(
          'Formula y articulos creados correctamente'
        );
        this._unsubInd.next('');
      });
    this.formulaSE
      .getResultUpdateError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res) => {
        if (!res) return;
        StaticUtilitiesService.showError('La formula no pudo ser creada');
        this._unsub.next('');
      });
  }

  getFormulaById(id) {
    this.formulaSE.getSingle(id);
    this.formulaSE
      .getResultIndividual()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        let { data } = res;
        this.toEditData = data;

        this._unsubInd.next('');
      });
  }

  editFormula() {
    this.formulaSE.update(this.updateObject);
    this.formulaSE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (!res) return;
        StaticUtilitiesService.showFeedback(
          'Formula y articulos creados correctamente'
        );
        this._unsubInd.next('');
      });
    this.formulaSE
      .getResultUpdateError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res) => {
        if (!res) return;
        StaticUtilitiesService.showError('La fórmula no pudo ser actualizada');
        this._unsub.next('');
      });
  }

  editFormulaFormulaDetails(formulaDetail) {
    let objectToPass = {
      id: this.toEditData.id,
      formulaDetail: this.mapDataToEditStep2(formulaDetail),
    };
    this.formulaSE.update(objectToPass);
    this.formulaSE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (!res) return;
        StaticUtilitiesService.showFeedback(
          'Formula y articulos creados correctamente'
        );
        this._unsubInd.next('');
      });
    this.formulaSE
      .getResultUpdateError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res) => {
        if (!res) return;
        StaticUtilitiesService.showError('La fórmula no pudo ser actualizada');
        this._unsub.next('');
      });
  }
}
