import { Component, OnInit } from '@angular/core';
import { iUnsubscribeDestroy, PopupService, slideNavigationTypes } from '@quasar_dynamics/basic-designsystem';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'app-ERP-Pedidos-Detalles',
  templateUrl: './ERP-Pedidos-Detalles.component.html',
  styleUrls: ['./ERP-Pedidos-Detalles.component.scss']
})
export class ERPPedidosDetallesComponent extends iUnsubscribeDestroy implements OnInit {

  orderId:number = 0
  openAccordeon:boolean = false
  orderName:string = ''

  constructor( private staticUtilities:StaticUtilitiesService, private popupSE:PopupService, ) {
    super();
  }

  ngOnInit() {
    this.getParams()
  }

  getParams(){
    if(!this.staticUtilities.getParams().pedido || this.staticUtilities.getParams().pedido <= 0){
      StaticUtilitiesService.showError('No se ha encontrado el pedido')
      this.staticUtilities.goTo('erp/proveedores', slideNavigationTypes.slideToBottom)
      return;
    }
    this.orderId = this.staticUtilities.getParams().pedido
  }

  goBack(){
    this.staticUtilities.goTo('erp/proveedores', slideNavigationTypes.slideToBottom)
  }

  getOrderName(name:string){
    this.orderName = name
  }

  printScreen(){
    this.openAccordions()
    setTimeout(() => {
      window.print();
    }, 100);
  }
  openAccordions(){
    this.openAccordeon = true
    setTimeout(() => {
      this.openAccordeon = false
    }, 200);
  }


}
