<pill>
  <ng-container>
    <div class="fullContainer">
      <div class="iconLine"></div>
      <div class="firstRow">
        <p class="bold">Código</p>
        <p class="bold">Descripción</p>
        <p class="bold">Cantidad Lt.</p>
        <p class="bold">Ocupación Kg</p>
        <p class="bold">Cantidad Producto</p>
        <p class="bold">Cantidad Envasado</p>
      </div>
      <div class="rows" *ngFor="let row of data">
        <p>{{ row.code }}</p>
        <p>{{ row.description }}</p>
        <p>{{ row.occLiters || "-" }}</p>
        <p>{{ row.occKg }}</p>
        <p>{{ row.product }}</p>
        <p>{{ row.quantity }}</p>
      </div>
    </div>
  </ng-container>
</pill>
