import { iBaseObject } from "@quasar_dynamics/basic-designsystem";

export interface iCharacteristic {
    id: number,
    description?: string,
    note?: string,
}

export class iCharacteristic extends iBaseObject {
    static convertFromCollection(ob: any): Array<iCharacteristic> {
        let iCharacteristicCollection: Array<iCharacteristic> = [];
        ob.forEach((element: any) => {
            iCharacteristicCollection.push(this.convertFromObject(element));
        });
        return iCharacteristicCollection;
    }

    static convertFromObject(ob: any): iCharacteristic {
        let iCharacteristicObj = new iCharacteristic();
        iCharacteristicObj.id = ob.id;
        iCharacteristicObj.description = ob.description;
        iCharacteristicObj.note = ob.note;
        return iCharacteristicObj;
    }

    static createEmptyObject(): iCharacteristic {
        let iCharacteristicObj = new iCharacteristic();
        iCharacteristicObj.description = '';
        iCharacteristicObj.note = '';
        return iCharacteristicObj;
    }
}

export interface iCharacteristicFilter {
    num_devoluciones: number;
    num_pagina: number;
    genericFilter?: string;
    description?: string,
    note?: string,
}