<FullContainer>
  <div class="fullView">
    <div class="headerAndToggle">
      <div class="header">
        <p>CONTABILIDAD</p>
      </div>
      <div class="toggle">
        <CustomToggle [selected]="selected" [selectores]="selectores" (selectedOption)="selectedOption($event)">
        </CustomToggle>
      </div>
    </div>

    <div class="midPills">
      <div class="midPillsSection">
        <pill>
          <ng-container>
            <div class="cuenta">
              <p>PAGOS PENDIENTES :</p>
              <p *ngIf="headerData?.toPay === 'Cargando...'">Cargando...</p>
              <p *ngIf="headerData?.toPay !== 'Cargando...'"
                [ngClass]="{pagosPendientes: headerData.toPay > 0, positivo:headerData.toPay < 0 || !headerData?.toPay}">
                {{headerData?.toPay?.toFixed(2 ) ?? (0).toFixed(2)}}</p>
            </div>
          </ng-container>
        </pill>
      </div>
      <div class="midPillsSection">
        <pill>
          <ng-container>
            <div class="cuenta">
              <p>COBROS PENDIENTES :</p>
              <p *ngIf="headerData?.toCollect === 'Cargando...'">Cargando...</p>
              <p *ngIf="headerData?.toCollect !== 'Cargando...'"
                [ngClass]="{negativo: headerData.toCollect < 0, positivo:headerData.toCollect >= 0 }">
                {{headerData?.toCollect?.toFixed(2) ?? (0).toFixed(2)}}</p>
            </div>
          </ng-container>
        </pill>
      </div>
      <div class="midPillsSection">
        <pill>
          <ng-container>
            <div class="cuenta">
              <p>BALANCE ACTUAL :</p>
              <p *ngIf="headerData?.balance === 'Cargando...'">Cargando...</p>
              <p *ngIf="headerData?.balance !== 'Cargando...'"
                [ngClass]="{negativo: headerData.balance < 0, positivo:headerData.balance >= 0 }">
                {{headerData?.balance?.toFixed(2)}}</p>
            </div>
          </ng-container>
        </pill>
      </div>
    </div>

    <div class="subHeader">
      <p *ngIf="selected ==0">PREVISIÓN DE PAGOS</p>
      <p *ngIf="selected ==1">PREVISIÓN DE COBROS</p>
    </div>

    <div class="filters">

      <filterActions (addNew)="create()" [activeBtn]="false" (searchSend)="search($event)">
        <ng-container filterContent>
          <div class="containerSelectores">
            <div class="customContainer">
              <label for="fecha">Fecha</label>
              <selector [options]="opcionesFecha" [(ngModel)]="opcionFecha" *ngIf="selected === 0"></selector>
              <selector [options]="opcionesFecha" [(ngModel)]="opcionFecha" *ngIf="selected === 1"></selector>
            </div>
            <!-- Factura -->
            <div class="customContainer" *ngIf="opcionFecha?.label === 'Factura'">
              <label for="fecha">Desde</label>
              <datepicker (dataFromPicker)="getDataFromPicker($event,'bill_ini',filtersClients)" [clearable]="true">
              </datepicker>
            </div>
            <div class="customContainer" *ngIf="opcionFecha?.bill_fin === 'Factura'">
              <label for="fecha">Hasta</label>
              <datepicker (dataFromPicker)="getDataFromPicker($event,'bill_fin',filtersClients)" [clearable]="true">
              </datepicker>
            </div>
            <!-- Pedido -->
            <div class="customContainer" *ngIf="opcionFecha?.label === 'Pedido'">
              <label for="fecha">Desde</label>
              <datepicker (dataFromPicker)="getDataFromPicker($event,'odate_ini',filtersProveedores)"
                [clearable]="true"></datepicker>
            </div>
            <div class="customContainer" *ngIf="opcionFecha?.label === 'Pedido'">
              <label for="fecha">Hasta</label>
              <datepicker (dataFromPicker)="getDataFromPicker($event,'odate_fin',filtersProveedores)"
                [clearable]="true"></datepicker>
            </div>
            <!-- Pago Cliente-->
            <div class="customContainer" *ngIf="opcionFecha?.label === 'Pago' && selected === 1">
              <label for="fecha">Desde</label>
              <datepicker (dataFromPicker)="getDataFromPicker($event,'paydate_ini',filtersClients)" [clearable]="true">
              </datepicker>
            </div>
            <div class="customContainer" *ngIf="opcionFecha?.paydate_ini === 'Pago' && selected === 1">
              <label for="fecha">Hasta</label>
              <datepicker (dataFromPicker)="getDataFromPicker($event,'paydate_fin',filtersClients)" [clearable]="true">
              </datepicker>
            </div>
            <!-- Pago Proveedor-->
            <div class="customContainer" *ngIf="opcionFecha?.label === 'Pago' && selected === 0">
              <label for="fecha">Desde</label>
              <datepicker (dataFromPicker)="getDataFromPicker($event,'paydate_ini',filtersProveedores)"
                [clearable]="true"></datepicker>
            </div>
            <div class="customContainer" *ngIf="opcionFecha?.label === 'Pago' && selected === 0">
              <label for="fecha">Hasta</label>
              <datepicker (dataFromPicker)="getDataFromPicker($event,'paydate_fin',filtersProveedores)"
                [clearable]="true"></datepicker>
            </div>

          </div>
        </ng-container>
      </filterActions>
    </div>


    <div class="content">


      <ng-container *ngIf="selected === 0">
        <ERP-Contabilidad-Proveedores [filtersProveedores]="filtersProveedores"></ERP-Contabilidad-Proveedores>
      </ng-container>
      <ng-container *ngIf="selected === 1">
        <ERP-Contabilidad-Clientes [filtersClients]="filtersClients"></ERP-Contabilidad-Clientes>
      </ng-container>
    </div>
  </div>
</FullContainer>