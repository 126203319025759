import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { iDate, iOptionsSelector, iStaticUtilities, iUnsubscribeDestroy } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { FamilyService } from 'src/app/Services/Api/Family.service';
import { FormulaService } from 'src/app/Services/Api/Formula.service';
import { SubfamilyService } from 'src/app/Services/Api/Subfamily.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'nuevaFormularStep1',
  templateUrl: './NuevaFormular-Step1.component.html',
  styleUrls: ['./NuevaFormular-Step1.component.scss']
})
export class NuevaFormularStep1Component extends iUnsubscribeDestroy implements OnInit {
  @Input() step1Data: any = null;
  @Input() toEditData: any = null;
  @Input() modalData: any = null;
  @Output() isCompleted: EventEmitter<any> = new EventEmitter<any>();

  updateObject: any = {
    id:0
  }

  data: any = {
    codigo:  "",
    nombre:  "",
    densidad: null,
    familia: null,
    subFamilia: null,
    objeto: "",
    unidadMedida: null,
    fechaFormula: new Date(),
    tipo: "",
  }
  opcionesFamilia: iOptionsSelector = {
    items: [],
    clearable: true,
    search: true,
    bindLabel: 'description',
    placeholder: "Familia",
    bindValue: 'id',
    append: true,
  }
  opcionesSubfamilia: iOptionsSelector = {
    items: [],
    clearable: true,
    search: true,
    bindLabel: 'description',
    placeholder: "Subfamilia",
    bindValue: 'id',
    append: true,
  }
  opcionesUnidades: iOptionsSelector = {
    items: [{label:"Kilogramos", id:1}, {label:"Litros",id:2}],
    clearable: true,
    search: true,
    placeholder: "Unidad de medida",
    bindLabel: 'label',
    bindValue: 'id',
    append: true,
  }


  constructor( private familiaSE:FamilyService, private subFamilySE:SubfamilyService, private formulaSE:FormulaService) {
    super();
  }

  ngOnInit() {
    if ( !StaticUtilitiesService.isNullObject(this.step1Data)) {
      this.data = this.step1Data;
      this.data.fechaFormula =  new Date(this.step1Data.fechaFormula)
    }
    this.getSubfamiliasForFilter()
    this.getFamiliasForFilter()
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['toEditData']){
      if(changes['toEditData'].currentValue){
        this.setter(changes['toEditData'].currentValue);
      }
    }
  }
  /**
  * VALIDATIONS
  */
  checkIsCompleted() {
    this.isCompleted.emit(this.data)
  }

  /**
   * GETTERS && SETTERS
   */

  setter(data){
    this.data.codigo = data.code;
    this.data.nombre = data.name;
    this.data.densidad = data.density;
    this.data.familia = data.family.id;
    this.data.subFamilia = data.subfamily.id;
    this.data.objeto = data.object;
    this.data.unidadMedida = data.measurementUnit.id;
    this.data.tipo = data.type;
    this.updateObject.id = data.id;
    this.checkIsCompleted()
  }
  setEdit(event,key){
    if(!event)return
    this.updateObject[key] = event;
    this.checkIsCompleted()
    this.updateFormula()
  }

  /**
   * API CALLS
   */

  getFamiliasForFilter(){
    this.familiaSE.getAll({num_devoluciones:5000, num_pagina:1});
    this.familiaSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe((res:any)=>{
      if(!res){return};
      let {data} = res.data
      this.opcionesFamilia.items = data
      this._unsubInd.next('')
    })
  }
  getSubfamiliasForFilter(){
    this.subFamilySE.getAll({num_devoluciones:5000, num_pagina:1});
    this.subFamilySE.getResult().pipe(takeUntil(this._unsubInd2)).subscribe((res:any)=>{
      if(!res){return};
      let {data} = res.data
      this.opcionesSubfamilia.items = data
      this._unsubInd2.next('')
    })
  }
  updateFormula(){
    this.formulaSE.update(this.updateObject);
    this.formulaSE.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe((res:any)=>{
      if(!res){return};
      StaticUtilitiesService.showFeedback('Se ha actualizado la formula correctamente')
      this._unsubInd.next('')
    })
    this.formulaSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res:any)=>{
      if(!res){return};
      StaticUtilitiesService.showError('No se ha podido actualizar la formula')
      this._unsub.next('')
    })
  }
}
