import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { iDataTableColumns, iDataTableOptions, iDate, iOptionsSelector, iUnsubscribeDestroy, PopupService, slideNavigationTypes } from '@quasar_dynamics/basic-designsystem';
import { Subject, takeUntil } from 'rxjs';
import { CambiarEstadoPedidoClientePopupComponent } from 'src/app/Popups/CambiarEstadoPedidoCliente-Popup/CambiarEstadoPedidoCliente-Popup.component';
import { ConfirmPopupComponent } from 'src/app/Popups/ConfirmPopup/ConfirmPopup.component';
import { OrderService } from 'src/app/Services/Api/Order.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';
import { iOrderFilter } from 'src/app/Shared/Interfaces/iOrder';
import * as XLSX from 'xlsx';


@Component({
  selector: 'ERP-Cliente-Detail-HistoricoPedidos',
  templateUrl: './ERP-Cliente-Detail-HistoricoPedidos.component.html',
  styleUrls: ['./ERP-Cliente-Detail-HistoricoPedidos.component.scss']
})
export class ERPClienteDetailHistoricoPedidosComponent extends iUnsubscribeDestroy implements OnInit {

  isLoading: boolean = false;
  @Input() data: any = [];
  filter: iOrderFilter = {
    num_devoluciones: 20,
    num_pagina: 1,
  }
  totalPaginacion: number = 0;
  _reloadTableWidth = new Subject()

  opcionesState:iOptionsSelector = {
    items:[
      {id:1, label:'Pte. de salir'},
      {id:2, label:'Salido'},
      {id:3, label:'Facturado'},
    ],
    search:true,
    placeholder:'Estado',
    clearable:true,
    bindValue:'label'
  }

  //Tabla
  displayedColumnsTabla: Array<iDataTableColumns> = [
    { label: 'Nº Pedido', property: 'numPedido' },
    { label: 'Fecha', property: 'fecha', align: 'center' },
    { label: 'Estado', property: 'state', align: 'center', classList: 'tableEstado tableEstadoPedidos' },

  ];

  dataForTable = [
    { id: 1, fecha: '15/02/2023', nPedido: '022', adjuntos: 7, estado: 'pte. de salir', },
    { id: 2, fecha: '15/02/2023', nPedido: '022', adjuntos: 7, estado: 'salido', },
    { id: 3, fecha: '15/02/2023', nPedido: '022', adjuntos: 1, estado: 'facturado', },
    { id: 4, fecha: '15/02/2023', nPedido: '022', adjuntos: 7, estado: 'salido', },
    { id: 5, fecha: '15/02/2023', nPedido: '022', adjuntos: 7, estado: 'salido', },
    { id: 6, fecha: '15/02/2023', nPedido: '022', adjuntos: 7, estado: 'salido', },
    { id: 7, fecha: '15/02/2023', nPedido: '022', adjuntos: 7, estado: 'salido', },
  ]

  optionsTabla: iDataTableOptions = {
    isSelectable: true,
    canEdit: true,
    canSee: true,
    moreIconsHead: [{ callback: (event) => { this.exportexcel() }, icon: "download" }],
    isHovered: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc'
  }


  constructor(private router: Router, private staticUtilitiesSE: StaticUtilitiesService, private popupSE: PopupService, private orderSE: OrderService) {
    super();
  }

  ngOnInit() {
    this.isLoading = true;
    setTimeout(() => {
      this.isLoading = false;
      setTimeout(() => {
        this._reloadTableWidth.next(true)
      }, 1);

    }, 1000);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data']) {
      if(!changes['data'].currentValue) { return };
      this.getHistorico();
    }
  }

    /**
* DESCARGA DE PARTES EN EXCEL
*/

exportexcel(): void {
  let table = document.createElement("table")
  table.append((document.querySelector("dataTable .tableHeader thead") as HTMLElement).cloneNode(true))
  table.querySelector("thead .iconsActions")?.remove();
  table.querySelector("thead .checkboxContainer")?.remove();
  let rows = Array.from(document.querySelectorAll("dataTable .tableBody tbody tr "));
  let filteredRows = rows.filter((row) => {
    return row.querySelector('.checkboxContainer mat-checkbox ')!.getAttribute('ng-reflect-checked') === "true"
  })
  if (filteredRows.length > 0) {
    filteredRows.forEach((row) => {
      let newRow = row.cloneNode(true) as HTMLElement;
      newRow.querySelector('td:last-of-type')?.remove();
      newRow.querySelector('td:first-of-type')?.remove();
      table.append(newRow)
    })
  } else {
    table.append((document.querySelector("dataTable .tableBody tbody") as HTMLElement).cloneNode(true))
    table.querySelectorAll("tbody tr td:last-of-type").forEach(element => {
      element.remove();
    })
    table.querySelectorAll("tbody tr td:first-of-type").forEach(element => {
      element.remove();
    })
  }
  /* pass here the table id */
  const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table);

  /* generate workbook and add the worksheet */
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  /* save to file */
  XLSX.writeFile(wb, 'HistoricoDePedidos.xlsx');

}


  /**
   * GETTERS AND SETTERS
   */

  getSelector(event, key){
    if(!event){delete this.filter[key]; this.getHistorico(); return};
    this.filter[key] = event;
    this.getHistorico();
  }

  /**
 * Edit
 */

  edit(event) {
    this.popupSE.openPopup(CambiarEstadoPedidoClientePopupComponent, {data:event, accion:'editar'})
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
      if (!res) { return };
      this.getHistorico();
      this._unsubInd2.next('');
    })
  }

  /**
   * Delete
   */

  delete(event, key) {
    switch (key) {
      case 'simple':
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'el histórico de pedido' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
          if (!res) { return };
          this._unsubInd2.next('');
        })
        break;
      case 'all':
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'todos los históricos de pedido' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
          if (!res) { return };
          this._unsubInd2.next('');
        })

        break;
      case 'selected':
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'los históricos de pedido seleccionados' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
          if (!res) { return };
          this._unsubInd2.next('');
        })
        break;
      default:
        break;
    }
  }


    /**
   * See pedido
   */

    see(event){
      this.staticUtilitiesSE.goTo('/erp/clientes/detalles-pedido', slideNavigationTypes.slideToTop, {pedido: event.numPedido})
    }

  /**
   * PAGINATION
   */

  changePagination(event: any) {
    if (event === 'next') {
      this.filter.num_pagina++;
      this.getHistorico();
    } else {
      this.filter.num_pagina--;
      this.getHistorico();
    }
  }

  /**
   * add
   */

  create() { }

  /**
   * API CALLS
   */

  getHistorico() {
    this.isLoading = true;
    this.orderSE.getAllOrdersOneCompany(this.filter, this.data.id);
    this.orderSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe((res) => {
      if (!res) { return };
      let { data, total } = res.data;
      this.totalPaginacion = total;
      this.dataForTable = data?.map(item => {return {...item, fecha: iDate.javascriptConvert(new Date(item.fechaPedido)).toStringDate('EU'), adjuntos: item.adjunto.length}});
      this.isLoading = false;
      this._unsubInd.next('');
    })
  }

}
