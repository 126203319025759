<FullContainer>
  <div class="fullView">
    <div class="headerAndToggle">
      <div class="header">
        <p>Usuarios</p>
      </div>
    </div>
    <div class="filters">

      <filterActions [disableFilter]="true">
        <ng-container filterContent>
          <div class="containerSelectores">
            <div>
              <!-- <selector [options]="opcionesUnderMin" (change)="getFilter($event,'under_min')"></selector> -->
            </div>
            <div>
              <!-- <selector [options]="opcionesVSNegative" (change)="getFilter($event,'vs_negative')"></selector> -->
            </div>
          </div>
        </ng-container>
      </filterActions>
    </div>

    <div class="content">
      <pill class="tablePil" classList="tablePill">

        <dataTable [data]="dataForTableFiltered" 
          [options]="optionsTabla" [displayedColumns]="displayedColumnsTabla" ></dataTable>
      </pill>

    </div>
  </div>
</FullContainer>