import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { iDataTableColumns, iDataTableOptions, iUnsubscribeDestroy, PopupService } from '@quasar_dynamics/basic-designsystem';
import { Subject, takeUntil } from 'rxjs';
import { ConfirmPopupComponent } from 'src/app/Popups/ConfirmPopup/ConfirmPopup.component';
import { NuevaCaracteristicaPopupComponent } from 'src/app/Popups/NuevaCaracteristica-Popup/NuevaCaracteristica-Popup.component';
import { NuevoFungiblePopupComponent } from 'src/app/Popups/NuevoFungible-Popup/NuevoFungible-Popup.component';
import { FungibleService } from 'src/app/Services/Api/Fungible.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';
import { iFungibleFilter } from 'src/app/Shared/Interfaces/iFungible';
import * as XLSX from 'xlsx';

@Component({
  selector: 'erpMantenimientoDetallesFungibles',
  templateUrl: './ERP-Mantenimiento-Detalles-Fungibles.component.html',
  styleUrls: ['./ERP-Mantenimiento-Detalles-Fungibles.component.scss']
})
export class ERPMantenimientoDetallesFungiblesComponent extends iUnsubscribeDestroy implements OnInit {

  isLoading: boolean = false;
  forceUpdateWidth = new Subject()
  totalPaginacion: number = 0;

  //Tabla
  displayedColumnsTabla: Array<iDataTableColumns> = [
    { label: 'Código', property: 'code' },
    { label: 'Descripción', property: 'description' },
    { label: 'Notas', property: 'note' },
  ];

  dataForTable = []



  optionsTabla: iDataTableOptions = {
    isSelectable: true,
    isHovered: true,
    canSee: true,
    canEdit: true,
    canDelete: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc',
    moreIconsHead: [{ callback: (event) => { this.exportexcel() }, icon: "download" }],
  }
  dataForTableFiltered: Array<any> = this.dataForTable;

  filter: iFungibleFilter = {
    num_devoluciones: 20,
    num_pagina: 1,
    genericFilter: '',
    code: '',
    description: '',
    note: '',
  }
  constructor(private router: Router, private staticUtilitiesSE: StaticUtilitiesService, private popupSE: PopupService, private fungibleSE: FungibleService) {
    super()
  }

  ngOnInit() {
    this.getAllFungibles();
  }

  /**
   * GETTERS && SETTERS
   */

  search(event){
    if(!event){this.filter.genericFilter = ""; this.getAllFungibles(); return}
    this.filter.genericFilter = event;
    this.getAllFungibles();
    return
  }

  /**
   * FUNCTIONALITIES
   */
  edit(event: any) {
    this.popupSE.openPopup(NuevoFungiblePopupComponent, { data: event, accion: "editar" });
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe((data) => {
      this.ngOnInit()
      this._unsubInd2.next("")
    })
  }
  create() {
    this.popupSE.openPopup(NuevoFungiblePopupComponent, { data: null, accion: "crear" });
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe((data) => {
      this.ngOnInit()
      this._unsubInd2.next("")
    })
  }
  see(event: any) {
    this.popupSE.openPopup(NuevoFungiblePopupComponent, { data: event, accion: "ver" });

  }
  delete(event: any, type: String) {
    let ids: any[] = []
    switch (type) {
      case "single":
        ids = [event.id]
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'el fungible' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe((data) => {
          this.deleteFungible(ids)
          this._unsubInd2.next("")
        })
        break;
      case "selected":
        ids = event.map((e: any) => e.id)
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'los fungibles seleccionadas' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe((data) => {
          this.deleteFungible(ids)
          this._unsubInd2.next("")
        })
        break;
      case "all":
        ids = this.dataForTable.map((e: any) => e.id)
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'todas los fungibles' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe((data) => {
          this.deleteFungible(ids)
          this._unsubInd2.next("")
        })
        break;
    }
  }

  changePagination(event: any) {
    if (event === 'next') {
      this.filter.num_pagina++;
      this.getAllFungibles();
    } else {
      this.filter.num_pagina--;
      this.getAllFungibles();
    }
  }

    /**
* DESCARGA DE PARTES EN EXCEL
*/

exportexcel(): void {
  let table = document.createElement("table")
  table.append((document.querySelector("dataTable .tableHeader thead") as HTMLElement).cloneNode(true))
  table.querySelector("thead .iconsActions")?.remove();
  table.querySelector("thead .checkboxContainer")?.remove();
  let rows = Array.from(document.querySelectorAll("dataTable .tableBody tbody tr "));
  let filteredRows = rows.filter((row) => {
    return row.querySelector('.checkboxContainer mat-checkbox ')!.getAttribute('ng-reflect-checked') === "true"
  })
  if (filteredRows.length > 0) {
    filteredRows.forEach((row) => {
      let newRow = row.cloneNode(true) as HTMLElement;
      newRow.querySelector('td:last-of-type')?.remove();
      newRow.querySelector('td:first-of-type')?.remove();
      table.append(newRow)
    })
  } else {
    table.append((document.querySelector("dataTable .tableBody tbody") as HTMLElement).cloneNode(true))
    table.querySelectorAll("tbody tr td:last-of-type").forEach(element => {
      element.remove();
    })
    table.querySelectorAll("tbody tr td:first-of-type").forEach(element => {
      element.remove();
    })
  }
  /* pass here the table id */
  const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table);

  /* generate workbook and add the worksheet */
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  /* save to file */
  XLSX.writeFile(wb, 'Fungible.xlsx');

}

  /**
   * CALLS TO API
   */

  getAllFungibles() {
    this.isLoading = true;
    this.fungibleSE.getAll(this.filter);
    this.fungibleSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe((res) => {
      if (!res) { return };
      let { data, total } = res.data;
      this.totalPaginacion = total;
      this.dataForTable = data;
      this.dataForTableFiltered = this.dataForTable;
      this.isLoading = false;
      this.forceUpdateWidth.next(true)
      this._unsubInd.next("")
    })
  }
  deleteFungible(ids: number[]) {
    this.fungibleSE.delete(ids);
    this.fungibleSE.getResultDelete().pipe(takeUntil(this._unsubInd3)).subscribe((res) => {
      if (!res) { return }
      StaticUtilitiesService.showFeedback("Se ha eliminado el fungible correctamente")
      this.ngOnInit()
      this._unsubInd3.next('')
    })
    this.fungibleSE.getResultDeleteError().pipe(takeUntil(this._unsub)).subscribe((res) => {
      if (!res) { return }
      StaticUtilitiesService.showError("No se ha podido eliminar el fungible")
      this._unsub.next('')
    })
  }
}

