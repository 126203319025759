<div class="headerContainer" [ngClass]="{'ERP': option === 'ERP', 'CRM':option === 'CRM' }">
  <div class="lettermarkContainer">
    <img class="lettermark" src="/assets/Icons/savzf21728661_1656464081070713_7844506453742645810_o.png">
  </div>
  <!-- <div class="buttonContainer" >
    <button [ngClass]="{headerButtonERP: option === 'ERP',headerButtonCRM: option === 'CRM'}"><a
        [routerLink]="['/crm/dashboard']" routerLinkActive="activated" routerAnimationLeft (click)="setNavigation('CRM')">CRM</a></button>
    <button [ngClass]="{headerButtonERP: option === 'ERP',headerButtonCRM: option === 'CRM'}"><a
        [routerLink]="['/erp/dashboard']" routerLinkActive="activated" routerAnimationRight (click)="setNavigation('ERP')">ERP</a></button>
  </div> -->
  <div class="navegationContainer">
    <a class="navElem" *ngFor="let nav of navegationArray" (keyup.enter)="nav.popup? openPopup() : navigateTo(nav)" (click)=" nav.popup? openPopup() : navigateTo(nav)  "
      [routerLink]="!nav.popup ? [nav.router] : []"  routerLinkActive="activated">
      <img class="image-icons" *ngIf="nav.icon == null && nav.icon !== 'none' " [src]="nav.image">
      <i class="material-icons" *ngIf="nav.icon != null && nav.icon !== 'none'">{{nav.icon}}</i>
      <span>{{nav.label}}</span>
    </a>
  </div>
</div>
