<div class="popupGeneralContainer">
  <div class="header">
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
    <p *ngIf="modalData.accion=='editar'">Editar Direccion</p>
    <p *ngIf="modalData.accion=='crear'">Crear Direccion</p>
    <p *ngIf="modalData.accion=='ver'">Ver Direccion</p>
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
  </div>
  <div class="body">
    <CustomInput [readonly]="modalData.accion=='ver'" classList="fullWidth tall special" placeholder="Dirección"
      label="Dirección" [(ngModel)]="direccion" (change)="modalData.accion=='editar' ? editInput(direccion,'street') : checkIsCompleted()"></CustomInput>
    <CustomInput [readonly]="modalData.accion=='ver'" classList="fullWidth tall special" placeholder="Código Postal"
      label="Código Postal" [(ngModel)]="cp" (change)="modalData.accion=='editar' ? editInput(cp,'postalCode') : checkIsCompleted()"></CustomInput>
    <CustomInput [readonly]="modalData.accion=='ver'" classList="fullWidth tall special" placeholder="Número"
      label="Número" [type]="'number'" [(ngModel)]="number" (change)="modalData.accion=='editar' ? editInput(number,'number') : checkIsCompleted()"></CustomInput>
    <CustomInput [readonly]="modalData.accion=='ver'" classList="fullWidth tall special" placeholder="Municipio"
      label="Municipio" [(ngModel)]="municipio" (change)="modalData.accion=='editar' ? editInput(municipio,'municipality') : checkIsCompleted()"></CustomInput>
    <div class="customContainer">
      <label for="Pais">Provincia</label>
      <selector [options]="opcionesProvincias" (change)="modalData.accion=='editar' ? editInput(provincia,'province') : checkIsCompleted()" [(ngModel)]="provincia"></selector>
    </div>
    <div class="customContainer">
      <label for="Pais">País</label>
      <selector [options]="opcionesPaises" (change)="modalData.accion=='editar' ? editInput(pais,'country') : checkIsCompleted()" [(ngModel)]="pais"></selector>
    </div>
    <CustomInput [readonly]="modalData.accion=='ver'" classList="fullWidth tall special" placeholder="Teléfono"
      label="Teléfono" [(ngModel)]="telefono" (change)="modalData.accion=='editar' ? editInput(telefono,'phones') : checkIsCompleted()"></CustomInput>
    <!-- <CustomInput [readonly]="modalData.accion=='ver'" classList="fullWidth tall special" placeholder="Contacto"
      label="Contacto" [(ngModel)]="contacto" (change)="checkIsCompleted()"></CustomInput> -->


  </div>
  <div class="footer" *ngIf="modalData.accion!='ver'">
    <btn classList="secundario" (click)="forceClosePopup()">
      <ng-container>
        Cancelar
      </ng-container>
    </btn>
    <btn classList="secundaryButton" (click)="create()" [class.disabled]="!isCompleted" *ngIf="modalData.accion=='crear'">
      <ng-container >
        Crear
      </ng-container>
    </btn>
    <btn classList="secundaryButton" (click)="closePopup('edit')" [class.disabled]="!isCompleted" *ngIf="modalData.accion=='editar'">
      <ng-container >
        Editar
      </ng-container>
    </btn>
  </div>

</div>