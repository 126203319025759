import { AfterViewInit, Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { iUnsubscribeDestroy, PopupService } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { CrearProveedorPopupComponent } from 'src/app/Popups/CrearProveedor-Popup/CrearProveedor-Popup.component';
import { DocumentosPopupComponent } from 'src/app/Popups/Documentos-Popup/Documentos-Popup.component';
import { NuevaMateriaPrimaPopupComponent } from 'src/app/Popups/NuevaMateriaPrima-Popup/NuevaMateriaPrima-Popup.component';
import { SubirDocumentoPopupComponent } from 'src/app/Popups/SubirDocumento-Popup/SubirDocumento-Popup.component';
import { CompanyService } from 'src/app/Services/Api/Company.service';
import { RawMaterialService } from 'src/app/Services/Api/RawMaterial.service';
import { StaticDataHandlerService } from 'src/app/Services/Utils/StaticDataHandler.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';
@Component({
  selector: 'erpMateriasPrimasDetallesFicha',
  templateUrl: './ERP-MateriasPrimas-Detalles-Ficha.component.html',
  styleUrls: ['./ERP-MateriasPrimas-Detalles-Ficha.component.scss']
})
export class ERPMateriasPrimasDetallesFichaComponent extends iUnsubscribeDestroy implements OnInit, AfterViewInit{

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.resize();
  }
  @Output() rawMaterialName = new EventEmitter<string>();
  documentos: Array<any> = [];
  rawMaterialId = 0;
  data:any = []
  updateData: any = {
    id: 0
  }

  constructor(private popupSE: PopupService, private staticUtilitiesSE: StaticUtilitiesService, private rawMaterialSE:RawMaterialService) {
    super();
  }

  ngAfterViewInit(): void {
    this.resize();
  }

  ngOnInit() {
    this.rawMaterialId= this.staticUtilitiesSE.getParams().materiaPrima;
    this.getRawMaterialId()
  }

  /**
   * FUNCTIONALITIES
   */

  resize(){
    setTimeout(() => {
    (document.querySelector("pill:nth-of-type(2)") as HTMLElement).style.height = ((document.querySelector("pill:first-of-type") as HTMLElement).clientHeight + (document.querySelector("pill:first-of-type") as HTMLElement).style.padding) + "px"
    }, 1);
  }

  verDocumentos(editar: boolean = false){
    this.popupSE.openPopup(DocumentosPopupComponent, {editar: editar, documentos: this.documentos})
  }

  edit(event, seccion){
    this.popupSE.openPopup(NuevaMateriaPrimaPopupComponent, { accion: 'editar', data:this.data });
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd)).subscribe((data) => {
      StaticUtilitiesService.showFeedback("Se ha editado la materia prima correctamente")
      this._unsubInd.next("")
    })
  }
  subirDocumento() {
    this.popupSE.openPopup(SubirDocumentoPopupComponent, { formatos: ['image/png', 'image/jpeg', 'application/pdf'], size: 10485760 });
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe((data) => {
      if(!data) return;
      this.updateData.attacheds = data['returnValue'].map(pdf=>pdf.base64);
      this.editDocumento()
      this._unsubInd2.next("")
    })
  }

  /**
   * API CALLS
   */

  getRawMaterialId(){
    this.rawMaterialSE.getSingleSupplier(this.rawMaterialId);
    this.rawMaterialSE.getResultSingleRawMaterial().pipe(takeUntil(this._unsubInd)).subscribe((res) => {
      if(!res){return};
      let {data} = res;
      this.data = data;
      this.rawMaterialName.emit(data.name);
      this.documentos = data.attacheds;
      this._unsubInd.next('')
    })
  }
  editDocumento(){
    this.updateData.id = this.rawMaterialId;
    this.rawMaterialSE.update(this.updateData);
    this.rawMaterialSE.getResultUpdate().pipe(takeUntil(this._unsubInd3)).subscribe((res) => {
      if(!res){return};
      StaticUtilitiesService.showFeedback('Documento subido correctamente');
      this.getRawMaterialId();
      this._unsubInd3.next("")
    })
    this.rawMaterialSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res) => {
      if(!res){return};
      StaticUtilitiesService.showError(res.message);
      this.getRawMaterialId();
      this._unsub.next("")
    })
  }

}
