import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  iOptionsSelector,
  iUnsubscribeDestroy,
} from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { CharacteristicService } from 'src/app/Services/Api/Characteristic.service';
import { CompanyService } from 'src/app/Services/Api/Company.service';
import { FamilyService } from 'src/app/Services/Api/Family.service';
import { RawMaterialService } from 'src/app/Services/Api/RawMaterial.service';
import { SpecificationService } from 'src/app/Services/Api/Specification.service';
import { SubfamilyService } from 'src/app/Services/Api/Subfamily.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'app-NuevaMateriaPrima-Popup',
  templateUrl: './NuevaMateriaPrima-Popup.component.html',
  styleUrls: ['./NuevaMateriaPrima-Popup.component.scss'],
})
export class NuevaMateriaPrimaPopupComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  isCompleted: boolean = false;
  opcionesFamilia: iOptionsSelector = {
    items: [],
    clearable: true,
    search: true,
    placeholder: 'Familia',
    bindLabel: 'description',
    bindValue: 'id',
    append: true,
  };
  opcionesSubfamilia: iOptionsSelector = {
    items: [],
    clearable: true,
    search: true,
    placeholder: 'Subfamilia',
    bindValue: 'id',
    append: true,
    bindLabel: 'code',
  };
  opcionesCaracteristicas: iOptionsSelector = {
    items: [],
    clearable: true,
    search: true,
    placeholder: 'Característica',
    bindValue: 'id',
    append: true,
    bindLabel: 'description',
  };
  opcionesUnidades: iOptionsSelector = {
    items: [
      { label: 'Kilogramos', id: 2 },
      { label: 'Litros', id: 1 },
    ],
    clearable: true,
    search: true,
    placeholder: 'Unidades de medida',
    bindValue: 'id',
    append: true,
  };
  opcionesTemperatura: iOptionsSelector = {
    items: [
      { key: 'Sí', value: true },
      { key: 'No', value: false },
    ],
    clearable: true,
    search: true,
    placeholder: 'Temperatura suministrada',
    append: true,
    bindLabel: 'key',
    bindValue: 'value',
  };
  opcionesProveedores: iOptionsSelector = {
    items: [],
    clearable: true,
    search: true,
    placeholder: 'Proveedores',
    bindLabel: 'name',
    bindValue: 'id',
    append: true,
    multiple: {
      isMultiple: true,
      checkbox: true,
      multipleLimitReplacer: 2,
      multipleTextReplacer: 'Varios proveedores seleccionadas',
    },
  };
  updateObjectRawMaterial: any = {
    id: null,
  };
  updateObjectSpecification: any = {
    id: null,
  };
  specs: any[] = [{ characteristic: null, value: '' }];
  //FORM VARIABLES
  data: any = {
    code: '',
    secondaryCode: '',
    name: '',
    density: null,
    family: null,
    subfamily: null,
    lastPrice: null,
    minStock: null,
    realStock: 0,
    reservedStock: 0,
    pendingStock: 0,
    virtualStock: 0,
    description: null,
    measurementUnit: null,
  };

  observaciones: string = '';
  constructor(
    public dialogRef: MatDialogRef<NuevaMateriaPrimaPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private rawMaterialSE: RawMaterialService,
    private companySE: CompanyService,
    private familySE: FamilyService,
    private subFamilySE: SubfamilyService,
    private specificationSE: SpecificationService,
    private characteristicSE: CharacteristicService
  ) {
    super();
  }

  ngOnInit() {
    if (this.modalData.accion !== 'crear') {
      this.getRawMaterialId(this.modalData.data.id);
    }
    this.getProveedoresForFilter();
    this.gerFamiliaForFilter();
    this.gerSubFamiliaForFilter();
    this.getCharacteristicsForFilter();
  }
  /**
   * GETTERS && SETTERS
   */

  editSecctionRawMaterial(event, key) {
    if (!event) {
      return;
    }
    this.updateObjectRawMaterial[key] = event;
    this.checkIsCompleted();
    this.editRawMaterial();
  }
  editSecctionSpecification(event, key) {
    if (!event) {
      return;
    }
    this.updateObjectSpecification[key] = event;
    this.checkIsCompleted();
    this.editSpecification();
  }
  setter(data) {
    this.data.code = data?.code;
    this.data.secondaryCode = data?.secondaryCode;
    this.data.name = data?.name;
    this.data.density = data?.density;
    this.data.family = data?.family?.id;
    this.data.subfamily = data?.subfamily?.id;
    this.data.lastPrice = data?.lastPrice;
    this.data.minStock = data?.minStock;
    this.data.description = data?.description;
    this.data.measurementUnit = data?.measurementUnit?.id;
    this.updateObjectRawMaterial.id = data?.id;
    this.specs = data?.characteristicManies?.map((item) => {
      return { ...item, characteristic: item.characteristic.id };
    });
  }

  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }

  checkIsCompleted() {
    if (this.data.characteristics) {
      delete this.data.characteristics;
    }
    this.data.characteristics = this.specs;
    if (this.data.density) {
      this.data.density = parseFloat(this.data.density);
    }
    this.isCompleted = StaticUtilitiesService.isCompleteObject(this.data);
  }

  addRow() {
    this.specs.push({ characteristic: null, value: '' });
  }
  deleteRow(index) {
    this.specs.splice(index, 1);
  }
  addCharacteristic(index) {}

  /**
   * API CALLS
   */

  create() {
    this.rawMaterialSE.create(this.data);
    this.rawMaterialSE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        StaticUtilitiesService.showFeedback(
          'Materia prima creada correctamente'
        );
        this.closePopup(res);
        this._unsubInd.next('');
      });
    this.rawMaterialSE
      .getResultUpdateError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        StaticUtilitiesService.showError('La materia prima no se pudo crear');
        this._unsub.next('');
      });
  }
  getRawMaterialId(id: number) {
    this.rawMaterialSE.getSingleSupplier(id);
    this.rawMaterialSE
      .getResultSingleRawMaterial()
      .pipe(takeUntil(this._unsubInd9))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        let { data } = res;

        this.setter(data);
        this._unsubInd9.next('');
      });
  }
  getProveedoresForFilter() {
    this.companySE.getClientOrProveedor({
      num_devoluciones: 5000,
      num_pagina: 1,
      isClient: false,
    });
    this.companySE
      .getResult()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        let { data, total } = res.data;

        this.opcionesProveedores.items = data;
        this._unsubInd.next('');
      });
  }
  gerFamiliaForFilter() {
    this.familySE.getAll({ num_devoluciones: 5000, num_pagina: 1 });
    this.familySE
      .getResult()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        let { data, total } = res.data;

        this.opcionesFamilia.items = data;
        this._unsubInd2.next('');
      });
  }
  gerSubFamiliaForFilter() {
    this.subFamilySE.getAll({ num_devoluciones: 5000, num_pagina: 1 });
    this.subFamilySE
      .getResult()
      .pipe(takeUntil(this._unsubInd3))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        let { data, total } = res.data;

        this.opcionesSubfamilia.items = data;
        this._unsubInd3.next('');
      });
  }

  editRawMaterial() {
    this.rawMaterialSE.update(this.updateObjectRawMaterial);
    this.rawMaterialSE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        StaticUtilitiesService.showFeedback(
          'Materia prima editada correctamente'
        );
        this.setter(res);
        this._unsubInd.next('');
      });
    this.rawMaterialSE
      .getResultUpdateError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        StaticUtilitiesService.showError('La materia prima no se pudo editar');
        this._unsub.next('');
      });
  }
  editSpecification() {
    this.specificationSE.update(this.updateObjectSpecification);
    this.specificationSE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        StaticUtilitiesService.showFeedback(
          'Materia prima editada correctamente'
        );
        this._unsubInd2.next('');
      });
    this.specificationSE
      .getResultUpdateError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        StaticUtilitiesService.showError('La materia prima no se pudo editar');
        this._unsub.next('');
      });
  }
  getCharacteristicsForFilter() {
    this.characteristicSE.getAll({ num_devoluciones: 5000, num_pagina: 1 });
    this.characteristicSE
      .getResult()
      .pipe(takeUntil(this._unsubInd4))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        let { data, total } = res.data;

        this.opcionesCaracteristicas.items = data;
        this._unsubInd4.next('');
      });
  }
}
