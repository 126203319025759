
import { Component, OnInit } from '@angular/core';
import { iDataTableColumns, iDataTableOptions, iDate, iOptionsSelector, iUnsubscribeDestroy, PopupService, slideNavigationTypes } from '@quasar_dynamics/basic-designsystem';
import { Subject, takeUntil } from 'rxjs';
import { CambiarEstadoPedidoProveedorPopupComponent } from 'src/app/Popups/CambiarEstadoPedidoProveedor-Popup/CambiarEstadoPedidoProveedor-Popup.component';
import { ConfirmPopupComponent } from 'src/app/Popups/ConfirmPopup/ConfirmPopup.component';
import { NuevoProveedorPedidoPopupComponent } from 'src/app/Popups/NuevoProveedorPedido-Popup/NuevoProveedorPedido-Popup.component';
import { CompanyService } from 'src/app/Services/Api/Company.service';
import { OrderService } from 'src/app/Services/Api/Order.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';
import { iOrderFilter } from 'src/app/Shared/Interfaces/iOrder';
import * as XLSX from 'xlsx';


@Component({
  selector: 'erpProveedoresPedidos',
  templateUrl: './ERP-Proveedores-Pedidos.component.html',
  styleUrls: ['./ERP-Proveedores-Pedidos.component.scss']
})
export class ERPProveedoresPedidosComponent extends iUnsubscribeDestroy implements OnInit {

  _reloadTableWidth = new Subject();
  isLoading: boolean = false;
  opcionesProveedores: iOptionsSelector = {
    items: [],
    clearable: true,
    search: true,
    placeholder: "Proveedor",
    append: true,
    bindLabel: 'name',
    bindValue: 'id',
    multiple: {
      isMultiple: true,
      checkbox: true,
      multipleLimitReplacer: 2,
      multipleTextReplacer: "Varios proveedores seleccionados"
    },
  }
  proveedores: string | null = null;

  opcionesEstados: iOptionsSelector = {
    items: [
      {
        id: "1",
        label: 'Pte. de recibir'
      },
      {
        id: "2",
        label: 'Recibido'
      },
      {
        id: "3",
        label: 'Facturado'
      },
    ],
    clearable: true,
    search: true,
    placeholder: "Estado",
    bindValue: 'label',
    append: true,
    multiple: {
      isMultiple: true,
      checkbox: true,
      multipleLimitReplacer: 2,
      multipleTextReplacer: "Varios estados seleccionados"
    },
  }
  estados: string | null = null;

  //Tabla
  displayedColumnsTabla: Array<iDataTableColumns> = [
    { label: 'Nº Pedido', property: 'code' },
    { label: 'Fecha pedido', property: 'fechaPedido' },
    { label: 'Proveedor', property: 'supplier' },
    { label: 'Fecha pago', property: 'fechaPago', align: "center" },
    { label: 'Estado', property: 'state', align: "center", classList: "tableEstado tableEstadoPedidos" },
  ];

  dataForTable: any = []

  optionsTabla: iDataTableOptions = {
    isSelectable: true,
    isHovered: true,
    canEdit: true,
    canDelete: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc',
    moreIconsHead: [{ callback: (event) => { this.exportexcel() }, icon: "download" }],
  }
  dataForTableFiltered: Array<any> = this.dataForTable;
  filter:iOrderFilter = {
    num_devoluciones:20,
    num_pagina:1,
    isClient:false,

  }
  totalPaginacion:number = 0;

  constructor(private popupSE: PopupService, private staticUtilities: StaticUtilitiesService, private orderSE:OrderService, private companySE:CompanyService) {
    super();
  }


  ngOnInit() {
    this.getAllOrders()
    this.getProveedorForFilter()
  }

  /**
   * GETTERS & SETTERS
   */
  getSelector(event , key:string){
    if(!event){delete this.filter[key]; this.getAllOrders() ;return;}
    this.filter[key] = event;
    this.getAllOrders()
  }
  search(event){
    if(!event){delete this.filter.genericFilter; this.getAllOrders() ;return;}
    this.filter.genericFilter = event;
    this.getAllOrders()
  }

  /**
   * FUNCTIONALITIES
   */
  clickRow(event) {
    this.staticUtilities.goTo("/erp/proveedores/detalles-pedido", slideNavigationTypes.slideToTop, { pedido: event.id })

  }
  edit(event: any) {
    this.popupSE.openPopup(CambiarEstadoPedidoProveedorPopupComponent, { data: event, accion: "editar" });
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe((data) => {
      if(!data) return;
      this.getAllOrders()
      this._unsubInd2.next("")
    })
  }

  delete(event: any, type: String) {
    let ids:any[] = [] 
    switch (type) {
      case "single":
        ids = [event.id]
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'el pedido' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe((data) => {
          if(!data) return;
          this.deleteOrder(ids)
          this._unsubInd2.next("")
            })
        break;
      case "selected":
        ids = event.map((e:any) => e.id)
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'los pedidos seleccionadas' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe((data) => {
          if(!data) return;
          this.deleteOrder(ids)
          this._unsubInd2.next("")
            })
        break;
      case "all":
        ids = this.dataForTableFiltered.map((e:any) => e.id)
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'todas los pedidos' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe((data) => {
          if(!data) return;
          this.deleteOrder(ids)
          this._unsubInd2.next("")
            })
        break;
    }
  }
  create() {

    if (screen.width > 1450) {
      this.popupSE.openPopup(NuevoProveedorPedidoPopupComponent, { data: null, accion: 'crear' })
    } else {
      this.popupSE.openPopup(NuevoProveedorPedidoPopupComponent, { data: null, accion: 'crear' }, 'fullSizePopup')
    }
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
      if(!res) return;
      this.getAllOrders()
      this._unsubInd2.next("")
    })
  }
  changePagination(event: any) {
    if (event === 'next') {
      this.filter.num_pagina++;
      this.getAllOrders();
    } else {
      this.filter.num_pagina--;
      this.getAllOrders();
    }
  }

    /**
* DESCARGA DE PARTES EN EXCEL
*/

exportexcel(): void {
  let table = document.createElement("table")
  table.append((document.querySelector("dataTable .tableHeader thead") as HTMLElement).cloneNode(true))
  table.querySelector("thead .iconsActions")?.remove();
  table.querySelector("thead .checkboxContainer")?.remove();
  let rows = Array.from(document.querySelectorAll("dataTable .tableBody tbody tr "));
  let filteredRows = rows.filter((row) => {
    return row.querySelector('.checkboxContainer mat-checkbox ')!.getAttribute('ng-reflect-checked') === "true"
  })
  if (filteredRows.length > 0) {
    filteredRows.forEach((row) => {
      let newRow = row.cloneNode(true) as HTMLElement;
      newRow.querySelector('td:last-of-type')?.remove();
      newRow.querySelector('td:first-of-type')?.remove();
      table.append(newRow)
    })
  } else {
    table.append((document.querySelector("dataTable .tableBody tbody") as HTMLElement).cloneNode(true))
    table.querySelectorAll("tbody tr td:last-of-type").forEach(element => {
      element.remove();
    })
    table.querySelectorAll("tbody tr td:first-of-type").forEach(element => {
      element.remove();
    })
  }
  /* pass here the table id */
  const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table);

  /* generate workbook and add the worksheet */
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  /* save to file */
  XLSX.writeFile(wb, 'pedidosProveedores.xlsx');

}


  /**
   * API CALLS
   */

  getAllOrders(){
    this.isLoading = true
    this.orderSE.getAllOrdersCompanyProveedor(this.filter);
    this.orderSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe((res) => {
      if(!res){return};
      let {data, total} = res.data
      this.totalPaginacion = total
      this.dataForTable = data.map((item) => {return {...item,fechaPago: iDate.javascriptConvert(new Date(item.payDate)).toStringDate('EU'), fechaPedido: iDate.javascriptConvert(new Date(item.orderDate)).toStringDate('EU'),  }})
      this.dataForTableFiltered = this.dataForTable
      setTimeout(() => {
        this._reloadTableWidth.next(true)
      }, 1);
      this.isLoading = false
      this._unsubInd.next('')
    })
  }

  getProveedorForFilter(){
    this.companySE.getClientOrProveedor({num_devoluciones:5000, num_pagina:1, isClient: false,});
    this.companySE.getResult().pipe(takeUntil(this._unsubInd2)).subscribe((res) => {
      if(!res){return}
      this.opcionesProveedores.items = res.data.data.map((item) => {return {id: item.id, name: item.name}})
      this._unsubInd2.next("")
    })
  }

  deleteOrder(ids:number[]){
    this.orderSE.delete(ids);
    this.orderSE.getResultDelete().pipe(takeUntil(this._unsubInd3)).subscribe((res) => {
      if(!res){return}
      this.getAllOrders()
      StaticUtilitiesService.showFeedback('Pedido eliminado correctamente')
      this._unsubInd3.next("")
    })
    this.orderSE.getResultDeleteError().pipe(takeUntil(this._unsubInd3)).subscribe((res) => {
      if(!res){return}
      this.getAllOrders()
      StaticUtilitiesService.showError('El pedido no pudo ser eliminado')
      this._unsubInd3.next("")
    })
  }

}
