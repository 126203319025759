<div class="popupGeneralContainer">
  <div class="header">
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
    <p *ngIf="modalData.accion=='editar'">Editar subfamilia</p>
    <p *ngIf="modalData.accion=='crear'">Crear subfamilia</p>
    <p *ngIf="modalData.accion=='ver'">Ver subfamilia</p>
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
  </div>
  <div class="body">
    <div class="doubleColumn">
      <CustomInput [readonly]="modalData.accion=='ver'" classList="fullWidth tall special" placeholder="Código"
        label="Código" [(ngModel)]="codigo"
        (change)="modalData.accion === 'editar' ? editSection(codigo, 'code') :checkIsCompleted()"></CustomInput>
      <CustomInput [readonly]="modalData.accion=='ver'" classList="fullWidth tall special" placeholder="Descripción"
        label="Descripción" [(ngModel)]="descripcion"
        (change)="modalData.accion === 'editar' ? editSection(descripcion, 'description') :checkIsCompleted()">
      </CustomInput>
    </div>
    <div class="customContainer">
      <label for="Familia">Familia</label>
      <selector [options]="opcionesFamilias" [(ngModel)]="familia"
        (change)="modalData.accion === 'editar' ? editSection(familia, 'family') :checkIsCompleted()"></selector>
    </div>
    <CustomInput [readonly]="modalData.accion=='ver'" classList="fullWidth tall special" placeholder="Familia"
      [label]="'Familia'" [(ngModel)]="familia" *ngIf="modalData.accion == 'ver'"></CustomInput>

    <CustomTextArea [readonly]="modalData.accion=='ver'" placeHolder="Notas" label="Notas" [(ngModel)]="notas"
      name="notas" (change)="modalData.accion === 'editar' ? editSection(notas, 'note') :checkIsCompleted()">
    </CustomTextArea>
  </div>
  <div class="footer" *ngIf="modalData.accion!='ver'">
    <btn classList="secundario" (click)="forceClosePopup()">
      <ng-container>
        Cancelar
      </ng-container>
    </btn>
    <btn classList="secundaryButton" (click)="closePopup()" [class.disabled]="!isCompleted"
      *ngIf="modalData.accion=='editar'">
      <ng-container>
        Editar
      </ng-container>
    </btn>
    <btn classList="secundaryButton" (click)="create()" [class.disabled]="!isCompleted"
      *ngIf="modalData.accion=='crear'">
      <ng-container>
        Crear
      </ng-container>
    </btn>
  </div>
</div>