<pill class="tablePil" classList="tablePill">
  <div class="headerTable">
    <p class="headerTableP">Envase</p>
    <p class="headerTableP" [ngStyle]="{'text-align': 'center'}">Código</p>
    <p class="headerTableP">Descripción</p>
    <p class="headerTableP" [ngStyle]="{'text-align': 'center'}">Cantidad Lt.</p>
    <p class="headerTableP" [ngStyle]="{'text-align': 'center'}">Ocupación Kg</p>
  </div>
  <ng-container *ngFor="let linea of arrayLineasTabla; let index = index">
    <div class="bodyTable">
      <div class="contenedor">
        <selector [options]="opcionesEnvases" [(ngModel)]="arrayLineasTabla[index]"
          (change)="getEnvaseSelected(materialSelected, linea)" (change)="checkIsCompleted()">
        </selector>
        <p [(ngModel)]=" arrayLineasTabla[index]" *ngIf="index === 0">
        </p>
      </div>
      <div class="contenedor" [ngStyle]="{'text-align': 'center'}">
        <ng-container *ngIf="linea.codigo == ''">
          -
        </ng-container>
        <ng-container *ngIf="linea.codigo != ''">
          {{linea.codigo}}
        </ng-container>
      </div>
      <div class="contenedor">
        <ng-container *ngIf="linea.descripcion == ''">
          -
        </ng-container>
        <ng-container *ngIf="linea.descripcion != ''">
          {{linea.descripcion}}
        </ng-container>
      </div>
      <div class="contenedor" [ngStyle]="{'text-align': 'center'}">
        <ng-container *ngIf="linea.cantidad == null">
          -
        </ng-container>
        <ng-container *ngIf="linea.cantidad != null">
          {{linea.cantidad.toFixed(2) | number}}
        </ng-container>
      </div>
      <div class="contenedor" [ngStyle]="{'text-align': 'center'}">
        <ng-container *ngIf="linea.ocupacion == null">
          -
        </ng-container>
        <ng-container *ngIf="linea.ocupacion != null">
          {{linea.ocupacion | number}} Kg
        </ng-container>
      </div>
      <!-- <div class="contenedor" [ngStyle]="{'text-align': 'center'}">
        <mat-icon>delete</mat-icon>
      </div> -->
      <div class="icons">
        <span class="closeIcon" *ngIf="arrayLineasTabla.length > 1 && index != 0 && !isEditing"
          (click)="removeRow(linea)">
          <mat-icon>close</mat-icon>
        </span>
      </div>
    </div>
  </ng-container>

</pill>