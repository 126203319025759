import { Component, HostListener, Input, OnInit } from '@angular/core';
import { iDataTableColumns, iDataTableOptions, iOptionsSelector, iUnsubscribeDestroy, PopupService } from '@quasar_dynamics/basic-designsystem';
import { Subject, takeUntil } from 'rxjs';
import { ConfirmPopupComponent } from 'src/app/Popups/ConfirmPopup/ConfirmPopup.component';
import { NuevoClientePolvoPopupComponent } from 'src/app/Popups/NuevoClientePolvo-Popup/NuevoClientePolvo-Popup.component';
import { ArticleService } from 'src/app/Services/Api/Article.service';
import { CompanyService } from 'src/app/Services/Api/Company.service';
import { RatesCustomerService } from 'src/app/Services/Api/RatesCustomer.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';
import * as XLSX from 'xlsx';


@Component({
  selector: 'erpTarifasClientePolvo',
  templateUrl: './ERP-Tarifas-ClientePolvo.component.html',
  styleUrls: ['./ERP-Tarifas-ClientePolvo.component.scss']
})
export class ERPTarifasClientePolvoComponent extends iUnsubscribeDestroy implements OnInit {
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.resize();
  }
  @Input() maxFactor:number = 0 
  @Input() minAporte:number = 0;
  
  reloadTableWidth = new Subject()
  opcionesClientes: iOptionsSelector = {
    items: [],
    clearable: true,
    search: true,
    bindValue: "id",
    placeholder: "Cliente",
    append: true
  }
  clientes: string | null = null;
  
  opcionesProducto: iOptionsSelector = {
    items: [],
    clearable: true,
    search: true,
    bindValue: "id",
    placeholder: "Producto",
    append: true
  }
  productos: string | null = null;

  //Tabla
  _reloadTableWidth = new Subject();
  isLoading: boolean = false;
  displayedColumnsTabla: Array<iDataTableColumns> = [
    { label: 'Cliente', property: 'cliente' },
    { label: 'Producto', property: 'producto' },
    { label: 'Coste', property: 'coste', align: "center", format: 'currency' },
    { label: 'Venta', property: 'venta', align: "center", format: 'currency' },
    { label: 'Aporte', property: 'aporte', align: "center", format: 'currency' },
    { label: 'Factor', property: 'factor', align: "center", format: 'percentaje' },
    { label: '24 meses', property: 'month', align: "center", },

  ];

  dataForTable: any = []

  optionsTabla: iDataTableOptions = {
    isSelectable: true,
    isHovered: true,
    canEdit: true,
    canDelete: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc',
    moreIconsHead: [{ callback: (event) => { this.exportexcel() }, icon: "download" }],
  }
  totalPaginacion: number = 0;
  dataForTableFiltered: Array<any> = this.dataForTable;
  promedioCoste: number = 0;
  promedioVenta: number = 0;
  promedioAporte: number = 0;
  promedioFactor: number = 0;
  filter: any = {
    num_devoluciones: 20,
    num_pagina: 1,
    genericFilter: "",
  }
  constructor(private popupSE: PopupService, private articleSE: ArticleService, private ratesCustomerSE: RatesCustomerService, private companySE:CompanyService) {
    super();
  }

  ngOnInit() {
    this.getRatesClientDust()
    this.getClientesForFilter();
    this.getProductsForFilter();
  }

  /**
   * GETTERS & SETTERS
   */
  getSelector(event, key){
    if(!event){delete this.filter[key]; this.getRatesClientDust(); ;return;}
    this.filter[key] = event;
    this.getRatesClientDust();
  }
  /**
   * FUNCTIONALITIES
   */
  resize() {
    if (this.dataForTable.length != 0) {
      (document.querySelectorAll(".promedios > span")[0] as HTMLElement).style.width = ((document.querySelectorAll(".tableBodyContainer .tableBody tbody tr:first-of-type td")[0] as HTMLElement).clientWidth + (document.querySelectorAll(".tableBodyContainer .tableBody tbody tr:first-of-type td")[1] as HTMLElement).clientWidth + (document.querySelectorAll(".tableBodyContainer .tableBody tbody tr:first-of-type td")[2] as HTMLElement).clientWidth) + "px";
      (document.querySelectorAll(".promedios > span")[1] as HTMLElement).style.width = ((document.querySelectorAll(".tableBodyContainer .tableBody tbody tr:first-of-type td")[3] as HTMLElement).clientWidth) + "px";
      (document.querySelectorAll(".promedios > span")[2] as HTMLElement).style.width = ((document.querySelectorAll(".tableBodyContainer .tableBody tbody tr:first-of-type td")[4] as HTMLElement).clientWidth) + "px";
      (document.querySelectorAll(".promedios > span")[3] as HTMLElement).style.width = ((document.querySelectorAll(".tableBodyContainer .tableBody tbody tr:first-of-type td")[5] as HTMLElement).clientWidth) + "px";
      (document.querySelectorAll(".promedios > span")[4] as HTMLElement).style.width = ((document.querySelectorAll(".tableBodyContainer .tableBody tbody tr:first-of-type td")[6] as HTMLElement).clientWidth) + "px";
    }
  }
  calculateData() {
    setTimeout(() => {
      this.promedioCoste = 0
      this.promedioVenta = 0
      this.promedioFactor = 0
      this.promedioAporte = 0
      this.dataForTable.map((element, index) => {
        if (element.aporte < this.minAporte) {
          (document.querySelectorAll(".tableBodyContainer .tableBody tbody tr td[columntable='aporte']")[index] as HTMLElement).style.color = "#ED1A23";
        }
        if (element.factor > this.maxFactor) {
          (document.querySelectorAll(".tableBodyContainer .tableBody tbody tr td[columntable='factor']")[index] as HTMLElement).style.color = "#ED1A23";
        }
        this.promedioCoste += Number(element.coste);
        this.promedioVenta += Number(element.venta);
        this.promedioFactor += Number(element.factor);
        this.promedioAporte += Number(element.aporte);
      })
      this.promedioCoste = Number(this.promedioCoste) / Number(this.dataForTable.length);
      this.promedioVenta = Number(this.promedioVenta) / Number(this.dataForTable.length);
      this.promedioFactor = Number(this.promedioFactor) / Number(this.dataForTable.length);
      this.promedioAporte = Number(this.promedioAporte) / Number(this.dataForTable.length);
      this.resize();
    }, 1);
  }
  edit(event: any) {
    this.popupSE.openPopup(NuevoClientePolvoPopupComponent, { data: event, accion: "editar" });
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe((data) => {
      if (!data) { return }
      this.ngOnInit();
      this._unsubInd2.next("")
    })
  }
  create() {
    this.popupSE.openPopup(NuevoClientePolvoPopupComponent, { data: event, accion: "crear" });
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe((data) => {
      if (!data) { return }
      this.ngOnInit();
      this._unsubInd2.next("")
    })
  }
  delete(event: any, type: String) {
    let ids:any[] = []
    switch (type) {
      case "single":
        ids = [event.RatesCustomerId]
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'el polvo ' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe((data) => {
          if(!data){return}
          this.deleteRatesClientDust(ids)
          this._unsubInd2.next("")
        })
        break;
      case "selected":
        ids = event.map((element) => { return element.RatesCustomerId })
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'los polvos ' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe((data) => {
          if(!data){return}
          this.deleteRatesClientDust(ids)
          this._unsubInd2.next("")
        })
        break;
      case "all":
        ids = this.dataForTable.map((element) => { return element.RatesCustomerId })
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'todas los polvos' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe((data) => {
          if(!data){return}
          this.deleteRatesClientDust(ids)
          this._unsubInd2.next("")
        })
        break;
    }
  }
  changePagination(event: any) {
    if (event === 'next') {
      this.filter.num_pagina++;
      this.getRatesClientDust();
    } else {
      this.filter.num_pagina--;
      this.getRatesClientDust();
    }
  }
  search(event: any) {
    if (!event) { delete this.filter.genericFilter; this.getRatesClientDust(); return }
    this.filter.genericFilter = event;
    this.getRatesClientDust();
  }
  /**
* DESCARGA DE PARTES EN EXCEL
*/

exportexcel(): void {
  let table = document.createElement("table")
  table.append((document.querySelector("dataTable .tableHeader thead") as HTMLElement).cloneNode(true))
  table.querySelector("thead .iconsActions")?.remove();
  table.querySelector("thead .checkboxContainer")?.remove();
  let rows = Array.from(document.querySelectorAll("dataTable .tableBody tbody tr "));
  let filteredRows = rows.filter((row) => {
    return row.querySelector('.checkboxContainer mat-checkbox ')!.getAttribute('ng-reflect-checked') === "true"
  })
  if (filteredRows.length > 0) {
    filteredRows.forEach((row) => {
      let newRow = row.cloneNode(true) as HTMLElement;
      newRow.querySelector('td:last-of-type')?.remove();
      newRow.querySelector('td:first-of-type')?.remove();
      table.append(newRow)
    })
  } else {
    table.append((document.querySelector("dataTable .tableBody tbody") as HTMLElement).cloneNode(true))
    table.querySelectorAll("tbody tr td:last-of-type").forEach(element => {
      element.remove();
    })
    table.querySelectorAll("tbody tr td:first-of-type").forEach(element => {
      element.remove();
    })
  }
  /* pass here the table id */
  const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table);

  /* generate workbook and add the worksheet */
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  /* save to file */
  XLSX.writeFile(wb, 'clientePolvo.xlsx');

}


  /**
   * API CALL
   */

  getRatesClientDust() {
    this.isLoading = true;
    this.articleSE.getArticlesRatesClientDust(this.filter);
    this.articleSE.getResultArticlesRates().pipe(takeUntil(this._unsubInd)).subscribe((res) => {
      if (!res) { return }
      let { data, total } = res.data;
      this.totalPaginacion = total
      this.dataForTable = data.map((element: any) => {return { ...element, factor : element.factor/100}});
      this.dataForTableFiltered = this.dataForTable;
      this.calculateData()
      setTimeout(() => {
        this._reloadTableWidth.next(true)
      }, 1);
      this.isLoading = false;
      this._unsubInd.next("")
    })
  }
  deleteRatesClientDust(ids:number[]) {
    this.ratesCustomerSE.delete(ids);
    this.ratesCustomerSE.getResultDelete().pipe(takeUntil(this._unsubInd)).subscribe((res) => {
      if(!res){return}
      StaticUtilitiesService.showFeedback("Se ha eliminado la tarifa correctamente")
      this.getRatesClientDust()
      this._unsubInd.next('')
    })
    this.ratesCustomerSE.getResultDelete().pipe(takeUntil(this._unsub)).subscribe((res) => {
      if (!res) { return }
      StaticUtilitiesService.showError("No se ha podido eliminar la tarifa")
      this.getRatesClientDust()
      this._unsub.next("")
    })
  }
  getClientesForFilter() {
    this.companySE.getClientOrProveedor({ num_devoluciones: 500, num_pagina: 1, isClient: true });
    this.companySE.getResult().pipe(takeUntil(this._unsubInd2)).subscribe((res) => {
      if (!res) { return }
      let { data } = res.data;
      this.opcionesClientes.items = data.map((item) => {
        return { id: item.id, label: `${item.id} - ${item.name}` }
      })
      this._unsubInd2.next('')
    })
  }
  getProductsForFilter() {
    this.articleSE.getAll({ num_devoluciones: 500, num_pagina: 1, isLiquid: false });
    this.articleSE.getResult().pipe(takeUntil(this._unsubInd3)).subscribe((res) => {
      if (!res) { return }
      let { data } = res.data;
      this.opcionesProducto.items = data.map((item) => {
        return { id: item.id, label: `${item.code} - ${item.name}` }
      })
      this._unsubInd3.next('')
    })
  }
}
