import { Component, EventEmitter, HostListener, Input, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { iUnsubscribeDestroy, PopupService } from '@quasar_dynamics/basic-designsystem';
import { NuevoClientePopupComponent } from 'src/app/Popups/NuevoCliente-Popup/NuevoCliente-Popup.component';
import { StaticDataHandlerService } from 'src/app/Services/Utils/StaticDataHandler.service';
import { takeUntil } from 'rxjs';
import { NuevoParteDeProduccionPopupComponent } from 'src/app/Popups/NuevoParteDeProduccion-Popup/NuevoParteDeProduccion-Popup.component';
import { ProductionReportService } from 'src/app/Services/Api/ProductionReport.service';

@Component({
  selector: 'ERP-PP-PP-DV-Ficha',
  templateUrl: './ERP-PP-PP-DV-Ficha.component.html',
  styleUrls: ['./ERP-PP-PP-DV-Ficha.component.scss']
})
export class ERPPPPPDVFichaComponent extends iUnsubscribeDestroy implements OnInit {

  @Input() parteId!: number;
  @Output() codeProductionReport = new EventEmitter();

  data: any = {};
  date: Date | string = new Date();
  deliveryDate: Date | string = new Date();
  documentos: Array<any> = StaticDataHandlerService.getDocumentos();
  constructor(private popup: PopupService, private productionReportSE: ProductionReportService) {
    super();
  }

  ngAfterViewInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['parteId']) {
      if (changes['parteId'].currentValue) {
        this.getHeaderId()
      }
    }
  }

  ngOnInit() {
  }

  /**
   * edit
   */

  edit(section) {
    this.popup.openPopup(NuevoParteDeProduccionPopupComponent, { data: 'data', accion: 'editar', section: section },'fullSizePopup');
    this.popup.returnData().pipe(takeUntil(this._unsubInd)).subscribe(res => {
      if (!res) { return };
      this._unsubInd.next('');
    })
  }

  /**
   * API CALLS
   */

  getHeaderId() {
    this.productionReportSE.getEncabezadoId(this.parteId);
    this.productionReportSE.getResultEncabezadoId().pipe(takeUntil(this._unsubInd)).subscribe(res => {
      if (!res) { return }
      let { data } = res;
      this.codeProductionReport.emit(data.code)
      this.data = data;
      this._unsubInd.next('')
    })
  }

}
