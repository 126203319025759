import { Component, OnInit } from '@angular/core';
import { iUnsubscribeDestroy, PopupService, slideNavigationTypes } from '@quasar_dynamics/basic-designsystem';
import { ConfirmPopupComponent } from 'src/app/Popups/ConfirmPopup/ConfirmPopup.component';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';
import { takeUntil } from 'rxjs';
import { CompanyService } from 'src/app/Services/Api/Company.service';

@Component({
  selector: 'app-ClienteDetailView',
  templateUrl: './ClienteDetailView.component.html',
  styleUrls: ['./ClienteDetailView.component.scss']
})
export class ClienteDetailViewComponent extends iUnsubscribeDestroy implements OnInit {

  clienteId!:number
  data:any = null

  constructor( private staticUtilities:StaticUtilitiesService, private popupSE:PopupService, private companySE:CompanyService ) {
    super();
  }

  ngOnInit() {
    this.getParams()
    this.getClienteId()
  }

  getParams(){
    if(!this.staticUtilities.getParams().cliente || this.staticUtilities.getParams().cliente <= 0){
      StaticUtilitiesService.showError('No se ha encontrado el cliente')
      this.staticUtilities.goTo('erp/clientes/', slideNavigationTypes.slideToBottom)
      return;
    }
    this.clienteId = this.staticUtilities.getParams().cliente
  }

  goBack(){
    this.staticUtilities.goTo('erp/clientes/', slideNavigationTypes.slideToBottom)
  }
  refresh(event){
    if(event){
      this.getClienteId()
    }
  }




  /**
   * API CALLS
   */

  getClienteId(){
    this.companySE.getSingle(this.clienteId);
    this.companySE.getResultIndividual().pipe(takeUntil(this._unsubInd)).subscribe((res)=>{
      if(!res){return}
      let {data} = res;
      this.data = data
      this._unsubInd.next('')
    })
  }

}
