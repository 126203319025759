import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { iDataTableColumns, iDataTableOptions, iDate, iUnsubscribeDestroy, PopupService, slideNavigationTypes } from '@quasar_dynamics/basic-designsystem';
import { ConfirmPopupComponent } from 'src/app/Popups/ConfirmPopup/ConfirmPopup.component';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';
import { takeUntil } from 'rxjs/operators';
import { ArticleService } from 'src/app/Services/Api/Article.service';
import { iArticleFilter } from 'src/app/Shared/Interfaces/iArticle';
import * as XLSX from 'xlsx';


@Component({
  selector: 'ERP-Stock-Articulos',
  templateUrl: './ERP-Stock-Articulos.component.html',
  styleUrls: ['./ERP-Stock-Articulos.component.scss']
})
export class ERPStockArticulosComponent extends iUnsubscribeDestroy implements OnInit {

  @Input() filterArticle:any= {}
  @Input() refreshArticle:boolean= true

  isLoading: boolean = false;
  filter: any = {
    num_devoluciones: 20,
    num_pagina: 1,
  }

  //Tabla
  displayedColumnsTabla: Array<iDataTableColumns> = [
    { label: 'Código', property: 'code' },
    { label: 'Stock Real', property: 'realStock', align: 'center' },
    { label: 'Stock Reservado', property: 'reservedStock', align: 'center' },
    { label: 'Stock Pte.Recibir', property: 'pendingStock', align: 'center' },
    { label: 'Stock Virtual', property: 'virtualStock', align: 'center' },
    { label: 'Stock Mínimo', property: 'minStock', align: 'center' },
    { label: 'Cantidad/fecha ult. compra', property: 'cantidadFecha', align: 'center', format: 'lineBreak' },
  ];

  dataForTable:any = [  ]
  totalPaginacion: number = 0;
  optionsTabla: iDataTableOptions = {
    isSelectable: true,
    moreIconsHead: [{ callback: (event) => { this.exportexcel() }, icon: "download" }],
    canDelete: true,
    isHovered: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc'
  }


  constructor(private router: Router, private staticUtilitiesSE: StaticUtilitiesService, private popupSE: PopupService, private articleSE: ArticleService) {
    super();
  }

  ngOnInit() {
    this.getAllArticlesStock()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['refreshArticle']) {
      this.getAllArticlesStock()
    }
    if(changes['filterArticle']){
        if(changes['filterArticle'].currentValue){
          delete this.filter.vs_negative
          delete this.filter.under_min
          delete this.filter.genericFilter
          this.filter = {...this?.filter,...changes['filterRaw']?.currentValue}
          this.getAllArticlesStock()
        }
      
    }

  }

  /**
 * Edit
 */

  /**
   * Delete
   */

  delete(event, key) {
    let ids:any[] = [];
    switch (key) {
      case 'simple':
        ids = [event.id]
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'la materia prima' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
          if (!res) { return };
          this.deleteArticle(ids);
          this._unsubInd2.next('');
        })
        break;
      case 'all':
        ids = this.dataForTable.map(e => e.id);
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'todas las materias primas' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
          if (!res) { return };
          this.deleteArticle(ids);
          this._unsubInd2.next('');
        })

        break;
      case 'selected':
        ids = event.map(e => e.id);
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'las materias primas seleccionadas' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
          if (!res) { return };
          this.deleteArticle(ids);
          this._unsubInd2.next('');
        })
        break;
      default:
        break;
    }
  }

  
/**
* DESCARGA DE PARTES EN EXCEL
*/

exportexcel(): void {
  let table = document.createElement("table")
  table.append((document.querySelector("dataTable .tableHeader thead") as HTMLElement).cloneNode(true))
  table.querySelector("thead .iconsActions")?.remove();
  table.querySelector("thead .checkboxContainer")?.remove();
  let rows = Array.from(document.querySelectorAll("dataTable .tableBody tbody tr "));
  let filteredRows = rows.filter((row) => {
    return row.querySelector('.checkboxContainer mat-checkbox ')!.getAttribute('ng-reflect-checked') === "true"
  })
  if (filteredRows.length > 0) {
    filteredRows.forEach((row) => {
      let newRow = row.cloneNode(true) as HTMLElement;
      newRow.querySelector('td:last-of-type')?.remove();
      newRow.querySelector('td:first-of-type')?.remove();
      table.append(newRow)
    })
  } else {
    table.append((document.querySelector("dataTable .tableBody tbody") as HTMLElement).cloneNode(true))
    table.querySelectorAll("tbody tr td:last-of-type").forEach(element => {
      element.remove();
    })
    table.querySelectorAll("tbody tr td:first-of-type").forEach(element => {
      element.remove();
    })
  }
  /* pass here the table id */
  const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table);

  /* generate workbook and add the worksheet */
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  /* save to file */
  XLSX.writeFile(wb, 'StockArticulos.xlsx');

}
  /**
 * PAGINATION
 */

  changePagination(event: any) {
    if (event === 'next') {
      this.filter.num_pagina++;
      this.getAllArticlesStock();
    } else {
      this.filter.num_pagina--;
      this.getAllArticlesStock();
    }
  }


  /**
   * See cliente
   */

  see(event) {
    this.staticUtilitiesSE.goTo('erp/stock/articulos', slideNavigationTypes.slideToTop, { articulos: event.id })
  }

  /**
   * API CALLS
   */

  getAllArticlesStock() {
    this.isLoading = true;
    this.articleSE.getAllStock({...this.filter, ...this.filterArticle})
    this.articleSE.getRresultArticlesStock().pipe(takeUntil(this._unsubInd)).subscribe(res => {
      if (!res) { return };
      let { data, total } = res.data;
      this.totalPaginacion = total;
      this.dataForTable = data.map((e, i) => { return { ...e, cantidadFecha: `${e.productionAmount} \n ${iDate.javascriptConvert(new Date(e.productionDate)).toStringDate('EU')}` } });
      this.isLoading = false;
      this._unsubInd.next('');
    })
  }

  deleteArticle(id) {
    this.articleSE.delete(id);
    this.articleSE.getResultDelete().pipe(takeUntil(this._unsubInd3)).subscribe(res => {
      if (!res) { return };
      this.getAllArticlesStock();
      this._unsubInd3.next('');
    })
    this.articleSE.getResultDeleteError().pipe(takeUntil(this._unsub)).subscribe(res => {
      if (!res) { return };
      this.getAllArticlesStock();
      this._unsub.next('');
    })
  }

}
