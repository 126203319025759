import { iBaseObject } from "@quasar_dynamics/basic-designsystem";
import { iFormula } from "./iFormula";
import { iRawMaterial } from "./iRawMaterial";

export interface iSpecification {
    id?: number,
    density?: string,
    color?: string,
    humidity?: string,
    hasTemperature?: boolean,
    formula?: iFormula,
    rawMaterial?: iRawMaterial,
}

export class iSpecification extends iBaseObject {
    static convertFromCollection(ob: any): Array<iSpecification> {
        let iSpecificationCollection: Array<iSpecification> = [];
        ob.forEach((element: any) => {
            iSpecificationCollection.push(this.convertFromObject(element));
        });
        return iSpecificationCollection;
    }

    static convertFromObject(ob: any): iSpecification {
        let iSpecificationObj = new iSpecification();
        iSpecificationObj.id = ob.id;
        iSpecificationObj.density = ob.density;
        iSpecificationObj.color = ob.color;
        iSpecificationObj.humidity = ob.humidity;
        iSpecificationObj.hasTemperature = ob.hasTemperature;
        iSpecificationObj.formula = ob.formula ? iFormula.convertFromObject(ob.formula) : new iFormula();
        iSpecificationObj.rawMaterial = ob.rawMaterial ? iRawMaterial.convertFromObject(ob.rawMaterial) : new iRawMaterial();
        return iSpecificationObj;
    }

    static createEmptyObject(): iSpecification {
        let iSpecificationObj = new iSpecification();
        iSpecificationObj.density = '';
        iSpecificationObj.color = '';
        iSpecificationObj.humidity = '';
        iSpecificationObj.hasTemperature = false;
        iSpecificationObj.formula = new iFormula();
        iSpecificationObj.rawMaterial = iRawMaterial.createEmptyObject();
        return iSpecificationObj;
    }
}

export interface iSpecificationFilter {
    num_devoluciones: number;
    num_pagina: number;
    genericFilter?: string;
    density?: string,
    color?: string,
    humidity?: string,
    hasTemperature?: boolean,
    formula?: number,
    rawMaterial?: number,
}