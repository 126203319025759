import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ERP-Proveedores',
  templateUrl: './ERP-Proveedores.component.html',
  styleUrls: ['./ERP-Proveedores.component.scss'],
})
export class ERPProveedoresComponent implements OnInit {
  selected = 0;
  selectores = [
    {
      label: 'Proveedores',
      image: 'none',
      icon: 'none',
      selected: 0,
      before: 'yes',
      color: 'azul-verdoso',
    },
    {
      label: 'Materias primas',
      icon: 'none',
      image: 'none',
      selected: 1,
      before: 'yes',
      color: 'azul-verdoso',
    },
    {
      label: 'Pedidos',
      icon: 'none',
      image: 'none',
      selected: 2,
      before: 'yes',
      color: 'azul-verdoso',
    },
  ];

  constructor() {}

  ngOnInit() {}

  selectedOption(event) {
    this.selected = event;
  }
}
