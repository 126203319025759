<div class="popupGeneralContainer">
  <div class="header">
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
    <p *ngIf="!editar">Ver adjuntos</p>
    <p *ngIf="editar">Editar adjuntos</p>
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
  </div>
  <div class="body">
    <ng-container *ngIf="modalData.documentos.length > 0">
      <div class="documento" *ngFor="let documento of modalData.documentos; let index = index">
        <div>
          <img src="/assets/Icons/pdf.svg" alt="">
          <a [href]="documento.doc || documento" target="_blank">{{documento.name || index}}</a>
        </div>
        <div class="acciones" *ngIf="editar">
          <span class="material-icons" (click)="delete(index)">
            delete
            </span>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="footer" *ngIf="modalData.accion!='ver'">
    <btn classList="secundaryButton" (click)="closePopup()">
      <ng-container>
        Finalizar
      </ng-container>
    </btn>
  </div>
</div>
