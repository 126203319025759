<div class="mapSection" [ngStyle]="getStyleMapSection()">
  <div class="map">

    <google-map id="map" class="mapsborders fullWidth fullHeight" [options]="optionsMap" [zoom]="zoom"
      [center]="center">

      <map-info-window>
        <div class="infoWindow">
          <div class="ubicacionBlock">
            <div class="icon">
              <ng-container>
                <mat-icon>
                  location_on
                </mat-icon>
              </ng-container>
            </div>
            <div class="text">
              <p class="lineaText">{{STToShow.concepto}}</p>
            </div>
          </div>
          <p class="linea">{{STToShow.encargadoNombre}}</p>
          <p class="linea">{{STToShow.ubicacion}}</p>
          <btn classList="fullWidth buttonMapa" (click)="abrirSeguimientoTecnico(STToShow.id)">
            <p>Abrir</p>
          </btn>
        </div>
      </map-info-window>

      <map-marker #marker="mapMarker" *ngFor="let mapsMark of markers" [id]="mapsMark.title" [title]="mapsMark.title"
        [position]="mapsMark.position" [icon]="mapsMark.icon" [options]="mapsMark.options"
        (mapClick)="openInfo(marker)">
      </map-marker>
    </google-map>


  </div>
  <div class="mapInfo">
    <pill classList="fullHeight">
      <div class="filterMapContainer">
        <h4>Filtrar por estado: </h4>
        <div class="gridContainer">
          <div class="verTodos">
            <i class="material-icons">place</i>
            <p (click)="changeFilterStatus('')" class="cursor-pointer">Ver todos</p>
          </div>

          <div class="filterMap" [ngClass]="{isFilter: filterMapActive == '1'}" tabindex="0"
            (click)="changeFilterStatus('1')" (keyup.enter)="changeFilterStatus('1')">
            <i class="material-icons clientes">place</i>
            <span>Clientes</span>
          </div>
          <div class="filterMap" [ngClass]="{isFilter: filterMapActive == '1'}" tabindex="0"
            (click)="changeFilterStatus('1')" (keyup.enter)="changeFilterStatus('1')">
            <i class="material-icons cPotenciales">place</i>
            <span>C.Potenciales</span>
          </div>
          <div class="filterMap" [ngClass]="{isFilter: filterMapActive == '1'}" tabindex="0"
            (click)="changeFilterStatus('1')" (keyup.enter)="changeFilterStatus('1')">
            <i class="material-icons cAntiguos">place</i>
            <span>C.Antiguos</span>
          </div>

          <!-- <div *ngFor="let est of estados; let index=index" class="filterMap"
            [ngClass]="{isFilter: filterStatus == est}" tabindex="0" (click)="changeFilterStatus(est)"
            (keyup.enter)="changeFilterStatus(est.id)">
            <i class="material-icons" [ngClass]="est.toLowerCase()">place</i>
            <span>{{est}}</span>
          </div> -->
        </div>
      </div>


    </pill>
  </div>
</div>