import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import {
  iDataTableColumns,
  iDataTableOptions,
  iUnsubscribeDestroy,
  PopupService,
} from '@quasar_dynamics/basic-designsystem';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'CPD-PedidoNumero',
  templateUrl: './CPD-PedidoNumero.component.html',
  styleUrls: ['./CPD-PedidoNumero.component.scss'],
})
export class CPDPedidoNumeroComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  @Input() data: any = [];

  isActive: boolean = false;

  isLoading: boolean = false;
  baseImponible: number | null = null;
  plusIva: any = null;
  recargoEquivalencia: any = null;
  total: any = null;

  //Tabla
  displayedColumnsTabla: Array<iDataTableColumns> = [
    { label: 'Artículo', property: 'articulo' },
    { label: 'Descripción', property: 'description' },
    { label: 'Cantidad', property: 'quantity', align: 'center' },
    { label: 'Precio', property: 'price', align: 'center', format: 'currency' },
    {
      label: 'Subtotal',
      property: 'subtotal',
      align: 'center',
      format: 'currency',
    },
  ];

  dataForTable: any = [];

  optionsTabla: iDataTableOptions = {
    isHovered: true,
    minHeight: 250,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc',
  };

  constructor(
    private router: Router,
    private staticUtilitiesSE: StaticUtilitiesService,
    private popupSE: PopupService
  ) {
    super();
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (Object.entries(changes['data'].currentValue).length > 0) {
      this.isLoading = true;
      this.isActive = true;
      setTimeout(() => {
        this.getSizes();
      }, 100);

      this.dataForTable = changes['data'].currentValue.orderItems?.map(
        (element: any) => {
          return { ...element, articulo: element.article.code };
        }
      );
      this.getBaseImponible();
      this.isLoading = false;
    }
  }

  getSizes() {
    let documents = document.querySelectorAll('.col.value');
    documents.forEach((element: any) => {
      element.style.width =
        (
          document.querySelector(
            ' div.tableBodyContainer > table > tbody > tr:nth-child(1) > td:nth-child(5) > div'
          ) as HTMLElement
        ).clientWidth + 'px';
    });
  }

  getBaseImponible() {
    let baseImponible = 0;
    this.dataForTable.forEach((element) => {
      baseImponible += element.subtotal;
    });
    this.baseImponible = Number(baseImponible);
    this.plusIva =
      Number(this.baseImponible) *
      Number(this.data.company?.paymentMethods[0]?.typeIva.value);
    this.recargoEquivalencia = Number(this.data.surcharge);
    this.total =
      Number(this.baseImponible) +
      Number(this.plusIva) +
      Number(this.recargoEquivalencia);
  }
}
