<div class="popupGeneralContainer">
  <div class="header">
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
    <p *ngIf="modalData.accion=='editar'">Editar envase</p>
    <p *ngIf="modalData.accion=='crear'">Crear envase</p>
    <p *ngIf="modalData.accion=='ver'">Ver envase</p>
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
  </div>
  <div class="body">
    <div class="fourthColumn">
      <CustomInput [readonly]="modalData.accion=='ver'" classList="fullWidth tall special" placeholder="Código" label="Código" [(ngModel)]="codigo" (change)="modalData.accion === 'editar' ? editSection(codigo, 'code') :checkIsCompleted()"></CustomInput>
      <CustomInput [readonly]="modalData.accion=='ver'" classList="fullWidth tall special" placeholder="Descripción" label="Descripción" [(ngModel)]="descripcion" (change)="modalData.accion === 'editar' ? editSection(descripcion, 'description') :checkIsCompleted()"></CustomInput>
      <div class="customContainer" *ngIf="modalData.accion !== 'ver'">
        <label for="Familia">Tipo</label>
        <selector [options]="opcionesTipo" [(ngModel)]="tipo"></selector>
      </div>
      <CustomInput [readonly]="modalData.accion=='ver'" classList="fullWidth tall special" placeholder="Tipo" [label]="'Tipo'" [(ngModel)]="tipo" *ngIf="modalData.accion == 'ver'"></CustomInput>
      <div class="customContainer" *ngIf="modalData.accion !== 'ver'">
        <label for="Familia">Ud. Ventas</label>
        <selector [options]="opcionesUnidadesVenta" [(ngModel)]="unidadVenta" (change)="modalData.accion === 'editar' ? editSection(unidadVenta, 'measurement') :checkIsCompleted()"></selector>
      </div>
      <CustomInput [readonly]="modalData.accion=='ver'" classList="fullWidth tall special" placeholder="Ud. Venta" [label]="'Ud. Venta'" [(ngModel)]="unidadVenta" *ngIf="modalData.accion == 'ver'"></CustomInput>

    </div>
    <div class="tripleColumn">
      <CustomInput [type]="'number'" [readonly]="modalData.accion=='ver'" classList="fullWidth tall special" placeholder="Ocupación kg" label="Ocupación kg" [(ngModel)]="ocupacionKg" (change)="modalData.accion === 'editar' ? editSection(ocupacionKg, 'occKg') :checkIsCompleted()" *ngIf="isKg"></CustomInput>
      <CustomInput [type]="'number'" [readonly]="modalData.accion=='ver'" classList="fullWidth tall special" placeholder="Ocupación L" label="Ocupación L" [(ngModel)]="ocupacionL" (change)="modalData.accion === 'editar' ? editSection(ocupacionL, 'occLiters') :checkIsCompleted()"*ngIf="isL" ></CustomInput>
      <CustomInput [type]="'number'" [readonly]="modalData.accion=='ver'" classList="fullWidth tall special" placeholder="Repercusión coste envase" label="Repercusión coste envase" [(ngModel)]="costeEnvase" (change)="modalData.accion === 'editar' ? editSection(costeEnvase, 'cost') :checkIsCompleted()"></CustomInput>
    </div>
    <CustomTextArea [readonly]="modalData.accion=='ver'"  placeHolder="Notas" label="Notas" [(ngModel)]="notas" name="notas" (change)="modalData.accion === 'editar' ? editSection(notas, 'note') :checkIsCompleted()"></CustomTextArea>


  </div>
  <div class="footer" *ngIf="modalData.accion!='ver'">
    <btn classList="secundario" (click)="forceClosePopup()">
      <ng-container>
       Cancelar
      </ng-container>
    </btn>
    <btn classList="secundaryButton" (click)="create()" [class.disabled]="!isCompleted" *ngIf="modalData.accion=='crear'">
      <ng-container >
        Crear
      </ng-container>
    </btn>
    <btn classList="secundaryButton" (click)="closePopup()" [class.disabled]="!isCompleted" *ngIf="modalData.accion=='editar'">
      <ng-container >
        Editar
      </ng-container>
    </btn>
  </div>
</div>
