<div class="popupGeneralContainer">
  <div class="header">
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
    <p *ngIf="modalData.accion=='editar'">Editar acción comercial</p>
    <p *ngIf="modalData.accion=='crear'">Crear acción comercial</p>
    <p *ngIf="modalData.accion=='ver'">Ver acción comercial</p>
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
  </div>
  <div class="body">
    <div class="tripleColumn">
      <div class="customContainer">
        <label for="">Cliente</label>
        <selector [class.readonly]="modalData.accion=='ver'" [options]="opcionesCliente" [(ngModel)]="data.cliente" (change)="checkIsCompleted()"></selector>
      </div>
      <div class="customContainer">
        <label for="">Tipo cliente</label>
        <selector  [class.readonly]="modalData.accion=='ver'" [options]="opcionesTipoCliente" [(ngModel)]="data.tipoCliente" (change)="checkIsCompleted()">
        </selector>
      </div>
      <div class="customContainer">
        <label for="">Tipo de acción</label>
        <selector  [class.readonly]="modalData.accion=='ver'" [options]="opcionesTipoAccion" [(ngModel)]="data.tipoAccion" (change)="checkIsCompleted()">
        </selector>
      </div>
    </div>
    <div class="tripleColumn">
      <div class="customContainer">
        <label for="">Asignado a</label>
        <selector  [class.readonly]="modalData.accion=='ver'" [options]="opcionesAsignado" [(ngModel)]="data.asignado" (change)="checkIsCompleted()">
        </selector>
      </div>
      <div class="customContainer">
        <label for="">Fecha últ. acción</label>
        <datepicker  [class.readonly]="modalData.accion=='ver'" (dataFromPicker)="data.ultimaAccion = $event"></datepicker>
      </div>
      <div class="customContainer">
        <label for="">Fecha prevista</label>
        <datepicker  [class.readonly]="modalData.accion=='ver'" (dataFromPicker)="data.fechaPrevista = $event"></datepicker>
      </div>
    </div>
    <div class="customContainer">
      <label for="">Estado</label>
      <div class="estados">
        <span   [class.readonly]="modalData.accion=='ver'" (click)="data.estado = estado" *ngFor="let estado of estados" [class.pendiente] = "estado.id == 1" [class.realizada] = "estado.id == 2" [class.descartada] = "estado.id == 3" [class.active]="estado == data.estado">
          {{estado.estado}}
        </span>
      </div>
    </div>
    <CustomTextArea [readonly]="modalData.accion=='ver'" placeHolder="Notas" label="Notas" [(ngModel)]="data.notas"
      name="notas" (change)="checkIsCompleted()"></CustomTextArea>
  </div>
  <div class="footer" *ngIf="modalData.accion!='ver'">
    <btn classList="secundario" (click)="forceClosePopup()">
      <ng-container>
        Cancelar
      </ng-container>
    </btn>
    <btn classList="secundaryButton" (click)="closePopup()" [class.disabled]="!isCompleted">
      <ng-container *ngIf="modalData.accion=='crear'">
        Crear
      </ng-container>
      <ng-container *ngIf="modalData.accion=='editar'">
        Editar
      </ng-container>
    </btn>
  </div>
</div>
