<div class="popupGeneralContainer" *ngIf="!isFactura">
  <div class="header">
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
    <p>Albarán {{modalData?.data?.id}}</p>
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
  </div>
  <div class="body">
    <div class="customContainer" *ngIf="this.state === 'pdt. de salir' || this.state === 'Pdt. de salir' ">
      <label for="Estado">Estado </label>
      <selector [options]="opcionesEstado" [(ngModel)]="state" (change)="updateState(state)"></selector>
    </div>
    <div class="documento">

      <div class="cursor-pointer">
        <img src="/assets/Icons/pdf.svg" alt="">
        <a [href]="attacheds" target="_blank">Albarán {{modalData?.data?.id}}</a>
      </div>
    </div>


  </div>
  <div class="footer" *ngIf="modalData.accion!='ver'">
    <btn classList="secundario" (click)="closePopup('editar')">
      <ng-container>
        Editar
      </ng-container>
    </btn>
    <btn classList="secundaryButton" (click)="isFactura = true; page === 0" *ngIf="this.state == 'pdt. de salir' || this.state == 'Pdt. de salir' ">
      <ng-container>
        Facturar
      </ng-container>
    </btn>
    <btn classList="secundaryButton" (click)="closePopup('editar')" *ngIf="this.state !== 'pdt. de salir' && this.state !== 'Pdt. de salir' ">
      <ng-container>
        Cerrar
      </ng-container>
    </btn>
  </div>
</div>

<div class="popupGeneralContainerFactura" *ngIf="isFactura">
  <div class="header">
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
    <p>Nueva Factura</p>
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
  </div>
  <div class="bodyFactura" *ngIf="page === 0">
    <NuevaFacturaSeccion1-popup (isComplete)="isComplete($event)" [modalData]="modalData"></NuevaFacturaSeccion1-popup>
  </div>
  <div class="bodyFactura" *ngIf="page === 1">
    <NuevaFacturaSeccion2-popup (isComplete)="isComplete($event)" [dataAlbaran]="dataAlbaran" [dataFromSection1]="dataFromSection1" [modalData]="modalData"></NuevaFacturaSeccion2-popup>
  </div>
  <div class="footer">
    <btn classList="secundario" (click)="lastPage()">
      <ng-container>
        Atrás
      </ng-container>
    </btn>
    <btn classList="secundaryButton" (click)="nextPage()" [ngClass]="{disabled: !isSection1Completed}" *ngIf="page === 0">
      <ng-container>
        Siguiente
      </ng-container>
    </btn>
    <btn classList="secundaryButton" (click)="generateFactura()" [ngClass]="{disabled: !isSection1Completed}" *ngIf="page === 1">
      <ng-container>
        Generar Factura
      </ng-container>
    </btn>
  </div>

</div>