import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'CADireccionesAlternativas',
  templateUrl: './CADireccionesAlternativas.component.html',
  styleUrls: ['./CADireccionesAlternativas.component.scss']
})
export class CADireccionesAlternativasComponent implements OnInit {

    direccionesAlternativas=[
    {nombre:'Indusmetal Torres, S.L.',direccion:'C/ Las Pozas, 6, Rute 14960, Córdoba (España)'},
    {nombre:'Indusmetal Torres, S.L. - Barcelona',direccion:'C/ Las Pozas, 6, Rute 14960, Córdoba (España)'},
    {nombre:'Indusmetal Torres, S.L. - Sevilla',direccion:'C/ Las Pozas, 6, Rute 14960, Córdoba (España)'},
  ]

  constructor() { }

  ngOnInit() {
  }

}
