import { Component, OnInit } from '@angular/core';
import { iDataTableColumns, iDataTableOptions } from '@quasar_dynamics/basic-designsystem';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'CAHistoricoPedidos',
  templateUrl: './CAHistoricoPedidos.component.html',
  styleUrls: ['./CAHistoricoPedidos.component.scss']
})
export class CAHistoricoPedidosComponent implements OnInit {

  isLoading: boolean = false;

  //Tabla
  displayedColumnsTabla: Array<iDataTableColumns> = [
    { label: 'Nº Pedido', property: 'nPedido' },
    { label: 'Artículos', property: 'articulos' },
    { label: 'Cantidad', property: 'cantidad' },
    { label: 'Fecha', property: 'fecha', align:'center' },
    { label: 'Adjuntos', property: 'adjuntos', align:'center',},
    { label: 'Estado', property: 'estado', align:'center', classList: 'tableEstado tableEstadoPedidos'},
  ];

  dataForTable = [
    { id: 1, nPedido: '789', fecha:'08/05/2023', articulos:'Hucha', cantidad:'100', adjuntos: '1', estado: 'pte de recibir', },
    { id: 2, nPedido: '789', fecha:'08/05/2023', articulos:'Hucha', cantidad:'100', adjuntos: '1', estado: 'pte de recibir', },
    { id: 3, nPedido: '789', fecha:'08/05/2023', articulos:'Hucha', cantidad:'100', adjuntos: '1', estado: 'pte de recibir', },
    { id: 4, nPedido: '789', fecha:'08/05/2023', articulos:'Hucha', cantidad:'100', adjuntos: '1', estado: 'pte de recibir', },
    { id: 5, nPedido: '789', fecha:'08/05/2023', articulos:'Hucha', cantidad:'100', adjuntos: '1', estado: 'pte de recibir', },
    { id: 6, nPedido: '789', fecha:'08/05/2023', articulos:'Hucha', cantidad:'100', adjuntos: '1', estado: 'pte de recibir', },
    { id: 7, nPedido: '789', fecha:'08/05/2023', articulos:'Hucha', cantidad:'100', adjuntos: '1', estado: 'pte de recibir', },
  ]

  optionsTabla: iDataTableOptions = {
    isSelectable: true,
    canEdit: true,
    canSee: true,
    moreIconsHead:[{icon:'download',callback:()=>{console.log('download')}}],
    canDelete: true,
    isHovered: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc'
  }


  constructor(private staticUtilitiesSE: StaticUtilitiesService) { }

  ngOnInit() {
    this.isLoading = true;
    setTimeout(() => {
      this.isLoading = false;
      
    }, 1000);
  }


}
