import { Component, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { iUnsubscribeDestroy, PopupService } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { DocumentosPopupComponent } from 'src/app/Popups/Documentos-Popup/Documentos-Popup.component';
import { EditarPedidoClientePopupComponent } from 'src/app/Popups/EditarPedidoClientePopup/EditarPedidoClientePopup.component';
import { NuevoClientePopupComponent } from 'src/app/Popups/NuevoCliente-Popup/NuevoCliente-Popup.component';
import { SubirDocumentoPopupComponent } from 'src/app/Popups/SubirDocumento-Popup/SubirDocumento-Popup.component';
import { OrderService } from 'src/app/Services/Api/Order.service';
import { StaticDataHandlerService } from 'src/app/Services/Utils/StaticDataHandler.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'CPD-FichaPedido',
  templateUrl: './CPD-FichaPedido.component.html',
  styleUrls: ['./CPD-FichaPedido.component.scss']
})
export class CPDFichaPedidoComponent extends iUnsubscribeDestroy implements OnInit {

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.resize();
  }

  @Input() data: any = []
  updateData:any = {
    id:''
  }
  @Output() refresh: any = new EventEmitter<boolean>();

  
  isActive: boolean = false
  documentos: Array<any> = StaticDataHandlerService.getDocumentos();
  constructor(private popup: PopupService, private popupSE:PopupService, private orderSE:OrderService) {
    super();
  }

  ngAfterViewInit(): void {
    this.resize();
  }

  ngOnInit() {
  }

  ngOnChanges(changes:SimpleChanges){
    if(changes['data']){
      this.isActive = true
      this.updateData.id = changes['data'].currentValue.id;
    }
  }

  /**
   * edit
   */

  edit(section) {
    this.popup.openPopup(EditarPedidoClientePopupComponent, { data: this.data, accion: 'editar', type:'detalle' });
    this.popup.returnData().pipe(takeUntil(this._unsubInd)).subscribe(res => {
      if (!res) { return };
      this.refresh.emit(true);
      this._unsubInd.next('');
    })
  }

  /**
   * FUNCTIONALITIES
   */
  resize() {
    setTimeout(() => {
      (document.querySelector("pill:nth-of-type(2)") as HTMLElement).style.height = ((document.querySelector("pill:first-of-type") as HTMLElement).clientHeight + (document.querySelector("pill:first-of-type") as HTMLElement).style.padding) + "px"
    }, 1000);
  }

  
  /**
   * DOCUMENTOS
   */

  subirDocumento() {
    this.popupSE.openPopup(SubirDocumentoPopupComponent, { formatos: ['image/png', 'image/jpeg', 'application/pdf'], size: 10485760 });
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe((data) => {
      if(!data) return;
      this.updateData.attacheds = data['returnValue'].map(pdf=>pdf.base64);
      this.editDocumento()
      this._unsubInd2.next("")
    })
  }

  verDocumentos(editar: boolean = false){
    this.popup.openPopup(DocumentosPopupComponent, {editar: editar, documentos: this.data.attacheds})
  }

  /**
   * API CALLS
   */

  editDocumento(){
    this.orderSE.update(this.updateData);
    this.orderSE.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe((res) => {
      if(!res){return};
      StaticUtilitiesService.showFeedback('Documento actualizado correctamente')
      this.refresh.emit(true);
      this._unsubInd.next('');
    })
    this.orderSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res) => {
      if(!res){return};
      StaticUtilitiesService.showError('El documento no pudo ser actualizado')
      this.refresh.emit(true);
      this._unsub.next('');
    })
  }

}
