<div class="pipeContainer">
  <pipeLine [pipeList]="pipeList" (pipeItemGroupChange)="changeItem($event)" >
    <ng-template pipeLineCardContent let-item="item">
      <div class="pipeLineContent"  [attr.disabled]="isLoadingElem(item)">
        <div class="loadingElem" *ngIf="isLoadingPipe" ><loaderIcon></loaderIcon></div>
        <p [ngClass]="{opacity30: isLoadingPipe}"><b>{{item.cliente}}</b></p>
        <p [ngClass]="{opacity30: isLoadingPipe}">{{item.provincia}}</p>
        <p [ngClass]="{opacity30: isLoadingPipe}" class="pipeUbicacion">{{item.contacto}}</p>
      </div>
    </ng-template>
    <ng-template pipeLinePreview let-item="item">
      Concepto
    </ng-template>
  </pipeLine>
</div>