import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { iDataTableColumns, iDataTableOptions, iDate, iUnsubscribeDestroy, PopupService, slideNavigationTypes } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { ConfirmPopupComponent } from 'src/app/Popups/ConfirmPopup/ConfirmPopup.component';
import { NuevaFormulaPopupComponent } from 'src/app/Popups/NuevaFormula-Popup/NuevaFormula-Popup.component';
import { NuevoClientePopupComponent } from 'src/app/Popups/NuevoCliente-Popup/NuevoCliente-Popup.component';
import { FormulaService } from 'src/app/Services/Api/Formula.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';
import * as XLSX from 'xlsx';


@Component({
  selector: 'ERP-PP-Formulas',
  templateUrl: './ERP-PP-Formulas.component.html',
  styleUrls: ['./ERP-PP-Formulas.component.scss']
})
export class ERPPPFormulasComponent extends iUnsubscribeDestroy implements OnInit {

  isLoading: boolean = false;

  @Input() filtroFamilia:any = null;
  @Input() filtroSubfamilia:any = null;
  @Input() filtroBusqueda:any = null;
  @Input() flagRefresh:boolean = false;

  //Tabla
  displayedColumnsTabla: Array<iDataTableColumns> = [
    { label: 'Código', property: 'code' },
    { label: 'Nombre', property: 'name', },
    { label: 'Familia', property: 'familyName', align: 'center' },
    { label: 'Subfamilia', property: 'subfamilyName', align: 'center' },
    { label: 'Fecha de fórmula', property: 'dateFormula', align: 'center' },
  ];

  dataForTable = [ 
    {code:'3423', name:'Dried Moss - 14-0626', familyName:'Pintura', subfamilyName:'Líquida', dateFormula:'07/11/2022'},
    {code:'6545', name:'Lotus - 14-1905', familyName:'Pintura', subfamilyName:'Líquida', dateFormula:'07/25/2022'},
    {code:'1543', name:'Foliage - 16-0237', familyName:'Pintura', subfamilyName:'Polvo', dateFormula:'08/12/2022'},
    {code:'8562', name:'Tawny Orange - 17-1341', familyName:'Pintura', subfamilyName:'Líquida', dateFormula:'06/21/2022'},
    {code:'8452', name:'Cornsilk - 13-0932', familyName:'Pintura', subfamilyName:'Polvo', dateFormula:'09/10/2022'},
    {code:'9552', name:'Diluyente - E512', familyName:'Aditivo', subfamilyName:'Catalizador', dateFormula:'09/05/2022'},
    {code:'5237', name:'Endurecedor - 655', familyName:'Aditivo', subfamilyName:'Endurecedor', dateFormula:'05/08/2022'},
    {code:'9535', name:'Living Coral - 16-1546', familyName:'Pintura', subfamilyName:'Polvo', dateFormula:'06/27/2022'},
    {code:'3237', name:'Ultra Violet - 18-3838', familyName:'Aditivo', subfamilyName:'Catalizador', dateFormula:'07/16/2022'},
    {code:'1645', name:'True Red - 19-1664', familyName:'Pintura', subfamilyName:'Líquida', dateFormula:'05/05/2022'},
   ]

  optionsTabla: iDataTableOptions = {
    isSelectable: true,
    canEdit: true,
    moreIconsHead: [{ callback: (event) => { this.exportexcel() }, icon: "download" }],
    canDelete: false,
    isHovered: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc'
  }
  filter: any = {
    num_devoluciones: 20,
    num_pagina: 1,
  }
  totalPaginacion: number = 10;

  constructor(private router: Router, private staticUtilitiesSE: StaticUtilitiesService, private popupSE: PopupService, private formulaSE: FormulaService) {
    super();
  }

  ngOnInit() {
    this.getAllFormulas()
  }

  ngOnChanges(changes:SimpleChanges) {
    if(changes['filtroFamilia']){
      if(changes['filtroFamilia'].currentValue){
        this.filter.family = changes['filtroFamilia'].currentValue;
        this.getAllFormulas();
      }else{
        delete this.filter.family;
        this.getAllFormulas();
      }
    }
    if(changes['filtroSubfamilia']){
      if(changes['filtroSubfamilia'].currentValue){
        this.filter.subfamily = changes['filtroSubfamilia'].currentValue;
        this.getAllFormulas();
      }else{
        delete this.filter.subfamily;
        this.getAllFormulas();
      }
    }
    if(changes['filtroBusqueda']){
      if(changes['filtroBusqueda'].currentValue){
        this.filter.genericFilter = changes['filtroBusqueda'].currentValue;
        this.getAllFormulas();
      }else{
        delete this.filter.genericFilter;
        this.getAllFormulas();
      }
    }
    if(changes['flagRefresh']){
      if(changes['flagRefresh'].currentValue){
        this.getAllFormulas();
      }
    }
  }

  /**
* DESCARGA DE PARTES EN EXCEL
*/

exportexcel(): void {
  let table = document.createElement("table")
  table.append((document.querySelector("dataTable .tableHeader thead") as HTMLElement).cloneNode(true))
  table.querySelector("thead .iconsActions")?.remove();
  table.querySelector("thead .checkboxContainer")?.remove();
  let rows = Array.from(document.querySelectorAll("dataTable .tableBody tbody tr "));
  let filteredRows = rows.filter((row) => {
    return row.querySelector('.checkboxContainer mat-checkbox ')!.getAttribute('ng-reflect-checked') === "true"
  })
  if (filteredRows.length > 0) {
    filteredRows.forEach((row) => {
      let newRow = row.cloneNode(true) as HTMLElement;
      newRow.querySelector('td:last-of-type')?.remove();
      newRow.querySelector('td:first-of-type')?.remove();
      table.append(newRow)
    })
  } else {
    table.append((document.querySelector("dataTable .tableBody tbody") as HTMLElement).cloneNode(true))
    table.querySelectorAll("tbody tr td:last-of-type").forEach(element => {
      element.remove();
    })
    table.querySelectorAll("tbody tr td:first-of-type").forEach(element => {
      element.remove();
    })
  }
  /* pass here the table id */
  const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table);

  /* generate workbook and add the worksheet */
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  /* save to file */
  XLSX.writeFile(wb, 'Formulas.xlsx');

}


  /**
 * Edit
 */

  edit(event) {
        this.popupSE.openPopup(NuevaFormulaPopupComponent, { data: event, accion: 'editar' }, 'fullSizePopup')
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
      if (!res) { return };
      this._unsubInd2.next('');
    })
  }

  /**
   * Delete
   */

  delete(event, key) {
    switch (key) {
      case 'simple':
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'la fórmula' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
          if (!res) { return };
          this._unsubInd2.next('');
        })
        break;
      case 'all':
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'todas las fórmulas' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
          if (!res) { return };
          this._unsubInd2.next('');
        })

        break;
      case 'selected':
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'las fórmulas seleccionadas' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
          if (!res) { return };
          this._unsubInd2.next('');
        })
        break;
      default:
        break;
    }
  }

  /**
   * See cliente
   */

  see(event) {
    this.staticUtilitiesSE.goTo('/erp/partesproduccion/formula', slideNavigationTypes.slideToTop, { formula: event.id })

  }

  /**
 * PAGINATION
 */

  changePagination(event: any) {
    if (event === 'next') {
      this.filter.num_pagina++;
      this.getAllFormulas();
    } else {
      this.filter.num_pagina--;
      this.getAllFormulas();
    }
  }

  /**
   * API CALLS
   */

  getAllFormulas() {
    this.isLoading = true;
    this.formulaSE.getFromulaTabla(this.filter);
    this.formulaSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(res => {
      if (!res) { return };
      let { data, total } = res.data;
      this.totalPaginacion = total;
      this.dataForTable = data?.map((item: any) => {return { ...item, dateFormula: iDate.javascriptConvert(new Date(item.dateFormula)).toStringDate('EU')}});
      this.isLoading = false;
      this._unsubInd.next('');
    })
  }

}
