import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-ConfirmPopup',
  templateUrl: './ConfirmPopup.component.html',
  styleUrls: ['./ConfirmPopup.component.scss']
})
export class ConfirmPopupComponent implements OnInit {

  crm = false;

  constructor(public dialogRef: MatDialogRef<ConfirmPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any) { }

  ngOnInit() {
    this.setter()
  }

  /**
  * FUNCTIONALITY
  */
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }
  /**
   * SETTER
   */
  setter(){
    if(this.modalData.crm){
      this.crm = true;
    }
  }

}
