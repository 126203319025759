import { Component, OnInit } from '@angular/core';
import { iDataTableColumns, iDataTableOptions, iUnsubscribeDestroy, PopupService, slideNavigationTypes } from '@quasar_dynamics/basic-designsystem';
import { Subject, takeUntil } from 'rxjs';
import { ConfirmPopupComponent } from 'src/app/Popups/ConfirmPopup/ConfirmPopup.component';
import { NuevaCarpetaPopupComponent } from 'src/app/Popups/NuevaCarpeta-Popup/NuevaCarpeta-Popup.component';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'app-CRM-Documentos',
  templateUrl: './CRM-Documentos.component.html',
  styleUrls: ['./CRM-Documentos.component.scss']
})
export class CRMDocumentosComponent extends iUnsubscribeDestroy implements OnInit {

  _reloadTableWidth = new Subject()
  isLoading: boolean = false;

  //Tabla
  displayedColumnsTabla: Array<iDataTableColumns> = [
    { label: 'Nombre', property: 'nombre' },
    { label: 'Notas', property: 'notas'},

  ];

  dataForTable = [
    { id: 1,nombre: "Documentos GENERALES", notas: "Carpeta dedicada a los documentos",},
    { id: 2,nombre: "Documentos comerciales", notas: "Carpeta dedicada a los documentos",},
    { id: 3,nombre: "Documentos de clientes", notas: "Carpeta dedicada a los documentos", },
  ]

  optionsTabla: iDataTableOptions = {
    isSelectable: true,
    isHovered: true,
    canEdit: true,
    canDelete: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc',

  }
  constructor(private popupSE: PopupService, private staticUtilitiesSE: StaticUtilitiesService) {
    super();
  }

  ngOnInit() {
    this.isLoading = true;

    setTimeout(() => {
      this.isLoading = false;
      setTimeout(() => {
        this._reloadTableWidth.next(true)
      }, 1);
    }, 1000);
  }

  /**
   * FUNCTIONALITIES
   */

  edit(event: any) {
    this.popupSE.openPopup(NuevaCarpetaPopupComponent, { data: event, accion: "editar" });
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd)).subscribe((data) => {
      StaticUtilitiesService.showFeedback("Se ha editado la carpeta correctamente")
      this._unsubInd.next("")
    })
  }
  create() {
    this.popupSE.openPopup(NuevaCarpetaPopupComponent, { data: null, accion: "crear" });
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd)).subscribe((data) => {
      StaticUtilitiesService.showFeedback("Se ha creado la carpeta correctamente")
      this._unsubInd.next("")
    })
  }
  see(event: any) {
    localStorage.setItem("nombreCarpeta", event.nombre);
    this.staticUtilitiesSE.goTo('crm/documentos/carpeta', slideNavigationTypes.slideToTop, {carpeta: 1})

    // this.popupSE.openPopup(NuevoAgentePopupComponent, { data: event, accion: "ver" });

  }
  delete(event: any, type: String) {
    switch (type) {
      case "single":
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'la carpeta' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd)).subscribe((data) => {
          StaticUtilitiesService.showFeedback("Se ha eliminado la carpeta correctamente")
          this._unsubInd.next("")
        })
        break;
      case "selected":
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'las carpetas seleccionadas' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd)).subscribe((data) => {
          StaticUtilitiesService.showFeedback("Se han eliminado las carpetas correctamente")
          this._unsubInd.next("")
        })
        break;
      case "all":
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'todas las carpetas' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd)).subscribe((data) => {
          StaticUtilitiesService.showFeedback("Se han eliminado las carpetas correctamente")
          this._unsubInd.next("")
        })
        break;
    }
  }
}
