import { iBaseObject } from "@quasar_dynamics/basic-designsystem";

export interface iIncident {
    id?: number,
    name?: string,
    description?: string,
}

export class iIncident extends iBaseObject {
    static convertFromCollection(ob: any): Array<iIncident> {
        let iIncidentCollection: Array<iIncident> = [];
        ob.forEach((element: any) => {
            iIncidentCollection.push(this.convertFromObject(element));
        });
        return iIncidentCollection;
    }

    static convertFromObject(ob: any): iIncident {
        let iIncidentObj = new iIncident();
        iIncidentObj.id = ob.id;
        iIncidentObj.name = ob.name;
        iIncidentObj.description = ob.description;
        return iIncidentObj;
    }

    static createEmptyObject(): iIncident {
        let iIncidentObj = new iIncident();
        iIncidentObj.name = '';
        iIncidentObj.description = '';
        return iIncidentObj;
    }
}

export interface iIncidentFilter {
    num_devoluciones: number;
    num_pagina: number;
    genericFilter?: string;
    name?: string,
    description?: string,
}