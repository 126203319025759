import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { iDataTableColumns, iDataTableOptions, iUnsubscribeDestroy, PopupService } from '@quasar_dynamics/basic-designsystem';
import { Subject, takeUntil } from 'rxjs';
import { ConfirmPopupComponent } from 'src/app/Popups/ConfirmPopup/ConfirmPopup.component';
import { NuevaFormaPagoPopupComponent } from 'src/app/Popups/NuevaFormaPago-Popup/NuevaFormaPago-Popup.component';
import { NuevoTipoIvaPopupComponent } from 'src/app/Popups/NuevoTipoIva-Popup/NuevoTipoIva-Popup.component';
import { PaymentMethodService } from 'src/app/Services/Api/PaymentMethod.service';
import { WayToPayService } from 'src/app/Services/Api/WayToPay.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';
import { iPaymentMethodFilter } from 'src/app/Shared/Interfaces/iPaymentMethod';
import * as XLSX from 'xlsx';


@Component({
  selector: 'erpMantenimientosDetallesFormasPago',
  templateUrl: './ERP-Mantenimientos-Detalles-FormasPago.component.html',
  styleUrls: ['./ERP-Mantenimientos-Detalles-FormasPago.component.scss']
})
export class ERPMantenimientosDetallesFormasPagoComponent extends iUnsubscribeDestroy implements OnInit {

  isLoading: boolean = false;
  _reloadTableWidth = new Subject()


  //Tabla
  displayedColumnsTabla: Array<iDataTableColumns> = [
    { label: 'Código', property: 'code' },
    { label: 'Descripción', property: 'description' },
    { label: 'Días', property: 'days', align: "center" },
  ];

  dataForTable = []
  filter:iPaymentMethodFilter={
    num_devoluciones:20,
    num_pagina:1
  }
  totalPaginacion: number = 0;

  optionsTabla: iDataTableOptions = {
    isSelectable: true,
    isHovered: true,
    canSee: true,
    canEdit: true,
    canDelete: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc',
    moreIconsHead: [{ callback: (event) => { this.exportexcel() }, icon: "download" }],

  }
  dataForTableFiltered: Array<any> = this.dataForTable;

  constructor(private router:Router, private staticUtilitiesSE: StaticUtilitiesService, private popupSE: PopupService, private wayToPaySE:WayToPayService) {
    super()
  }

  ngOnInit() {
    this.getAllPaymentMethods();


  }

  /**
   * FUNCTIONALITIES
   */
  edit(event:any){
    this.popupSE.openPopup(NuevaFormaPagoPopupComponent, {data: event, accion: "editar"});
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe((data) => {
      if(!data){return}
      this.getAllPaymentMethods()
      this._unsubInd2.next("")
    })
  }
  create(){
    this.popupSE.openPopup(NuevaFormaPagoPopupComponent, {data: null, accion: "crear"});
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe((data) => {
      if(!data){return}
      this.getAllPaymentMethods()
      this._unsubInd2.next("")
    })
  }
  see(event: any){
    this.popupSE.openPopup(NuevaFormaPagoPopupComponent, {data: event, accion: "ver"});

  }
  delete(event:any, type:String){
    let ids:number[] = []
    switch (type) {
      case "single":
        ids = [event.id]
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'la forma de pago' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe((data) => {
          if(!data){return}
          this.deleteFormaDePago(ids)
          this._unsubInd2.next("")
            })
        break;
      case "selected":
        ids = event.map((e:any) => e.id)
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'las formas de pago seleccionadas' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe((data) => {
          if(!data){return}
          this.deleteFormaDePago(ids)
          this._unsubInd2.next("")
            })
        break;
      case "all":
        ids = this.dataForTable.map((e:any) => e.id)
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'todas las formas de pago' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe((data) => {
          if(!data){return}
          this.deleteFormaDePago(ids)
          this._unsubInd2.next("")
            })
        break;
    }
  }
  changePagination(event: any) {
    if (event === 'next') {
      this.filter.num_pagina++;
      this.getAllPaymentMethods();
    } else {
      this.filter.num_pagina--;
      this.getAllPaymentMethods();
    }
  }
  search(event){
    if(!event){delete this.filter.genericFilter; this.getAllPaymentMethods() ;return}
    this.filter.genericFilter = event;
    this.getAllPaymentMethods()
  }

    /**
* DESCARGA DE PARTES EN EXCEL
*/

exportexcel(): void {
  let table = document.createElement("table")
  table.append((document.querySelector("dataTable .tableHeader thead") as HTMLElement).cloneNode(true))
  table.querySelector("thead .iconsActions")?.remove();
  table.querySelector("thead .checkboxContainer")?.remove();
  let rows = Array.from(document.querySelectorAll("dataTable .tableBody tbody tr "));
  let filteredRows = rows.filter((row) => {
    return row.querySelector('.checkboxContainer mat-checkbox ')!.getAttribute('ng-reflect-checked') === "true"
  })
  if (filteredRows.length > 0) {
    filteredRows.forEach((row) => {
      let newRow = row.cloneNode(true) as HTMLElement;
      newRow.querySelector('td:last-of-type')?.remove();
      newRow.querySelector('td:first-of-type')?.remove();
      table.append(newRow)
    })
  } else {
    table.append((document.querySelector("dataTable .tableBody tbody") as HTMLElement).cloneNode(true))
    table.querySelectorAll("tbody tr td:last-of-type").forEach(element => {
      element.remove();
    })
    table.querySelectorAll("tbody tr td:first-of-type").forEach(element => {
      element.remove();
    })
  }
  /* pass here the table id */
  const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table);

  /* generate workbook and add the worksheet */
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  /* save to file */
  XLSX.writeFile(wb, 'FromasDePago.xlsx');

}

  /**
   * CALLS TO API
   */

  getAllPaymentMethods(){
    this.isLoading = true;
    this.wayToPaySE.getAll(this.filter);
    this.wayToPaySE.getResult().pipe(takeUntil(this._unsubInd)).subscribe((res) => {
      if(!res){return};
      let {data, total} = res.data;
      this.dataForTable = data;
      this.totalPaginacion = total;
      this.dataForTableFiltered = this.dataForTable;
      setTimeout(() => {
        this._reloadTableWidth.next(true)
      }, 1);
      this.isLoading = false;
      this._unsubInd.next('')
    })
  }
  deleteFormaDePago(ids:number[]){
    this.wayToPaySE.delete(ids);
    this.wayToPaySE.getResultDelete().pipe(takeUntil(this._unsubInd3)).subscribe((res) => {
      if(!res){return};
      StaticUtilitiesService.showFeedback('Forma de pago eliminada correctamente')
      this.getAllPaymentMethods();
      this._unsubInd3.next('')
    })
    this.wayToPaySE.getResultDeleteError().pipe(takeUntil(this._unsub)).subscribe((res) => {
      if(!res){return};
      StaticUtilitiesService.showError(res.message)
      this._unsub.next('')
    })
  }


}
