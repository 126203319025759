import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { iDataTableColumns, iDataTableOptions, iOptionsSelector, iUnsubscribeDestroy, PopupService } from '@quasar_dynamics/basic-designsystem';
import { Subject, takeUntil } from 'rxjs';
import { ConfirmPopupComponent } from 'src/app/Popups/ConfirmPopup/ConfirmPopup.component';
import { NuevoEnvasePopupComponent } from 'src/app/Popups/NuevoEnvase-Popup/NuevoEnvase-Popup.component';
import { ContainerService } from 'src/app/Services/Api/Container.service';
import { MeasurementUnitService } from 'src/app/Services/Api/MeasurementUnit.service';
import { TypeProductService } from 'src/app/Services/Api/TypeProduct.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';
import { iContainerFilter } from 'src/app/Shared/Interfaces/iContainer';
import * as XLSX from 'xlsx';

@Component({
  selector: 'erpMantenimientoDetallesEnvases',
  templateUrl: './ERP-Mantenimiento-Detalles-Envases.component.html',
  styleUrls: ['./ERP-Mantenimiento-Detalles-Envases.component.scss']
})
export class ERPMantenimientoDetallesEnvasesComponent extends iUnsubscribeDestroy implements OnInit {

  _reloadTableWidth = new Subject()
  opcionesUdVenta: iOptionsSelector = {
    items: [],
    clearable: true,
    search: true,
    placeholder: "Ud.Venta",
    bindValue: "id",
    bindLabel: "unit",
    append: true
  }
  udVenta: string | null = null;
  filter: iContainerFilter = {
    num_devoluciones: 20,
    num_pagina: 1,

  }

  totalPaginacion: number = 0;

  opcionesTipos: iOptionsSelector = {
    items: [],
    clearable: true,
    search: true,
    bindLabel: "description",
    bindValue: "id",
    placeholder: "Tipo",
    append: true
  }
  tipo: string | null = null;

  isLoading: boolean = false;

  //Tabla
  displayedColumnsTabla: Array<iDataTableColumns> = [
    { label: 'Código', property: 'code' },
    { label: 'Descripción', property: 'description' },
    { label: 'KG', property: 'occKg', align: "center" },
    { label: 'L', property: 'occLiters', align: "center", format: "liters" },
    { label: 'Coste envase', property: 'cost', align: "center", format: "currency" },
    { label: 'Ud. Venta', property: 'measurament', align: "center" },
    { label: 'Tipo', property: 'type', align: "center" },
    { label: 'Notas', property: 'note', align: "center" },
  ];

  dataForTable = []

  optionsTabla: iDataTableOptions = {
    isSelectable: true,
    isHovered: true,
    canSee: true,
    canEdit: true,
    canDelete: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc',
    moreIconsHead: [{ callback: (event) => { this.exportexcel() }, icon: "download" }],

  }
  dataForTableFiltered: Array<any> = this.dataForTable;

  constructor(private router: Router, private staticUtilitiesSE: StaticUtilitiesService, private popupSE: PopupService, private containerSE: ContainerService, private typeProductSE: TypeProductService, private meassurementSE: MeasurementUnitService) {
    super()
  }

  ngOnInit() {
    this.getAllContainers()
    this.getAllTipoProductos()
    this.getMeassurement()
  }
  /**
   * GETTERS & SETTERS
   */
  getSelector(event, key: string) {
    if (!event) {delete this.filter[key]; this.getAllContainers(); return }
    this.filter[key] = event
    this.getAllContainers()
  }
  search(event) {
    if (!event) { this.filter.genericFilter = ''; this.getAllContainers(); return }
    this.filter.genericFilter = event
    this.getAllContainers()
  }

  /**
  * FUNCTIONALITIES
  */
  edit(event: any) {
    this.popupSE.openPopup(NuevoEnvasePopupComponent, { data: event, accion: "editar" });
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe((data) => {
      if (!data) { return }
      this.ngOnInit();
      this._unsubInd2.next("")
    })
  }
  create() {
    this.popupSE.openPopup(NuevoEnvasePopupComponent, { data: null, accion: "crear" });
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe((data) => {
      if(!data) { return }
      this.ngOnInit();
      this._unsubInd2.next("")
    })
  }
  see(event: any) {
    this.popupSE.openPopup(NuevoEnvasePopupComponent, { data: event, accion: "ver" });

  }
  delete(event: any, type: String) {
    let ids: any = []
    switch (type) {
      case "single":
        ids = [event.id]
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'el envase' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe((data) => {
          this.deleteContainer(ids)
          this._unsubInd2.next("")
        })
        break;
      case "selected":
        ids = event.map((item) => { return item.id })
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'los envases seleccionadas' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe((data) => {
          this.deleteContainer(ids)
          this._unsubInd2.next("")
        })
        break;
      case "all":
        ids = this.dataForTable.map((item: any) => { return item.id })
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'todas los envases' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe((data) => {
          this.deleteContainer(ids)
          this._unsubInd2.next("")
        })
        break;
    }
  }
  changePagination(event: any) {
    if (event === 'next') {
      this.filter.num_pagina++;
      this.getAllContainers();
    } else {
      this.filter.num_pagina--;
      this.getAllContainers();
    }
  }
  /**
* DESCARGA DE PARTES EN EXCEL
*/

  exportexcel(): void {
    let table = document.createElement("table")
    table.append((document.querySelector("dataTable .tableHeader thead") as HTMLElement).cloneNode(true))
    table.querySelector("thead .iconsActions")?.remove();
    table.querySelector("thead .checkboxContainer")?.remove();
    let rows = Array.from(document.querySelectorAll("dataTable .tableBody tbody tr "));
    let filteredRows = rows.filter((row) => {
      return row.querySelector('.checkboxContainer mat-checkbox ')!.getAttribute('ng-reflect-checked') === "true"
    })
    if (filteredRows.length > 0) {
      filteredRows.forEach((row) => {
        let newRow = row.cloneNode(true) as HTMLElement;
        newRow.querySelector('td:last-of-type')?.remove();
        newRow.querySelector('td:first-of-type')?.remove();
        table.append(newRow)
      })
    } else {
      table.append((document.querySelector("dataTable .tableBody tbody") as HTMLElement).cloneNode(true))
      table.querySelectorAll("tbody tr td:last-of-type").forEach(element => {
        element.remove();
      })
      table.querySelectorAll("tbody tr td:first-of-type").forEach(element => {
        element.remove();
      })
    }
    /* pass here the table id */
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, 'envases.xlsx');

  }

  /**
   * CALLS TO API
   */

  getAllContainers() {
    this.isLoading = true;
    this.containerSE.getAll(this.filter);
    this.containerSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe((res) => {
      if (!res) { return }
      let { data, total } = res.data;
      this.totalPaginacion = total;
      this.dataForTable = data.map((item) => {
        return { ...item, measurament: item.measurementUnit.unit, type: item.typeProduct.description }
      });
      this.dataForTableFiltered = this.dataForTable;
      this._reloadTableWidth.next(true)
      this.isLoading = false;
      this._unsubInd.next("")
    })
  }
  deleteContainer(ids: number[]) {
    this.containerSE.delete(ids);
    this.containerSE.getResultDelete().pipe(takeUntil(this._unsubInd3)).subscribe((res) => {
      if (!res) { return }
      StaticUtilitiesService.showFeedback("Envase eliminado correctamente")
      this.getAllContainers();
      this._unsubInd3.next("")
    })
    this.containerSE.getResultDeleteError().pipe(takeUntil(this._unsub)).subscribe((res) => {
      if (!res) { return }
      StaticUtilitiesService.showError(res.message)
      this.getAllContainers();
      this._unsub.next("")
    })
  }
  getAllTipoProductos() {
    this.typeProductSE.getAll({ num_devoluciones: 5000, num_pagina: 1 });
    this.typeProductSE.getResult().pipe(takeUntil(this._unsubInd2)).subscribe((res) => {
      if (!res) { return }
      let { data } = res.data;
      this.opcionesTipos.items = data;
      this._unsubInd2.next("")
    })
  }
  getMeassurement() {
    this.meassurementSE.getAll({ num_devoluciones: 500, num_pagina: 1 });
    this.meassurementSE.getResult().pipe(takeUntil(this._unsubInd3)).subscribe((res: any) => {
      if (!res) { return }
      let { data } = res.data;
      this.opcionesUdVenta.items = data
      this._unsubInd3.next('')
    })
  }
}

