import {
  AfterViewChecked,
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'CustomInput',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomInputComponent),
      multi: true,
    },
  ],
  templateUrl: './CustomInput.component.html',
  styleUrls: ['./CustomInput.component.scss'],
})
export class CustomInputComponent
  implements ControlValueAccessor, OnInit, AfterViewChecked
{
  @Input() placeholder: any = '';
  @Input() type: string = 'text';
  @Input() label = '';
  @Input() classList: Array<string> | string = '';
  @Input() extraAtributes: Array<{ attr: string; value: string }> = [];
  @Input() maxlength: string | number = '';
  @Input() readonly: boolean = false;
  @Input() min: number = 0;
  @Input() max: number = 10000000;
  @Input() patternNumber: boolean = false;
  @Input() required: boolean = false;

  @ViewChild('input') input!: ElementRef;

  //INTERNAL VALUES
  private _onChange = (_: any) => {};
  private _onTouched = () => {};
  isDisabled: boolean = false;
  model = '';
  _type = '';

  constructor() {}
  writeValue(obj: any): void {
    this.model = obj;
  }
  registerOnChange(fn: any): void {
    this._onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onModelChange($event: any) {
    this.model = $event;
    this._onChange($event);
  }

  ngOnInit() {
    this._type = this.type;
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['max']) {
    }
  }

  ngAfterViewChecked(): void {
    this.extraAtributes.map((e) => {
      this.input.nativeElement.setAttribute(e.attr, e.value);
    });
  }

  changeVisbility() {
    if (this._type == 'password') {
      this._type = 'text';
    } else {
      this._type = 'password';
    }
  }
}
