<div class="popupGeneralContainer">
  <div class="header">
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
    <p *ngIf="modalData.accion=='editar'">Editar documento</p>
    <p *ngIf="modalData.accion=='crear'">Crear documento</p>
    <p *ngIf="modalData.accion=='ver'">Ver documento</p>
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
  </div>
  <div class="body">
    <CustomInput [readonly]="modalData.accion=='ver'" classList="fullWidth tall special" placeholder="Nombre documento"
      label="Nombre documento" [(ngModel)]="data.nombre" (change)="checkIsCompleted()"></CustomInput>
    <div class="customContainer">
      <label for="Proveedor">Tipo de documento</label>
      <selector [class.readonly]="modalData.accion=='ver'" [options]="opcionesTipoDocumento"
        (change)=" checkIsCompleted()" [(ngModel)]="data.tipoDocumento"></selector>
    </div>
    <div class="customContainer">
      <label for="Fecha pago">Fecha documento</label>
      <datepicker [class.readonly]="modalData.accion=='ver'" (dataFromPicker)="data.fechaDoc = $event"></datepicker>
    </div>
    <div class="customContainer">
      <label for="Fecha pago">Fecha caducidad</label>
      <datepicker  [class.readonly]="modalData.accion=='ver'"(dataFromPicker)="data.fechaCaducidad = $event"></datepicker>
    </div>
    <div class="customContainer">
      <label for="">Estado</label>
      <div class="estados">
        <span   [class.readonly]="modalData.accion=='ver'" (click)="data.estado = estado" *ngFor="let estado of estados" [class.pendiente] = "estado.id == 1" [class.realizada] = "estado.id == 2" [class.descartada] = "estado.id == 3" [class.active]="estado == data.estado">
          {{estado.estado}}
        </span>
      </div>
    </div>
    <br>
    <div class="customContainer">
      <label for="Añadir documento">Añadir documento</label>
      <button class="generalbutton" [multiple]="false" fileInput
        [accept]="['image/png', 'image/jpeg', 'application/pdf']" [(ngModel)]="document"
        (change)="addDocument(document)"><mat-icon>attach_file</mat-icon>AÑADIR DOCUMENTO </button>
    </div>

    <div class="center">
      <p>{{documentLoaded}}</p>
    </div>

  </div>
  <div class="footer" *ngIf="modalData.accion!='ver'">

    <btn classList="secundario" (click)="forceClosePopup()">
      <ng-container>
        Cancelar
      </ng-container>
    </btn>
    <btn classList="secundaryButton" (click)="closePopup()" [class.disabled]="!isCompleted">
      <ng-container *ngIf="modalData.accion=='crear'">
        Crear
      </ng-container>
      <ng-container *ngIf="modalData.accion=='editar'">
        Editar
      </ng-container>
    </btn>
  </div>
</div>
