import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { iDataTableColumns, iDataTableOptions, slideNavigationTypes } from '@quasar_dynamics/basic-designsystem';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'ClientesClientes',
  templateUrl: './ClientesClientes.component.html',
  styleUrls: ['./ClientesClientes.component.scss']
})
export class ClientesClientesComponent implements OnInit {

  isLoading: boolean = false;

  //Tabla
  displayedColumnsTabla: Array<iDataTableColumns> = [
    { label: 'Código', property: 'codigo' },
    { label: 'Nombre', property: 'nombre', align:'center' },
    { label: 'NIF', property: 'nif' },
    { label: 'Comunidad Autónoma', property: 'comunidadAutonoma', align:'center' },
    { label: 'Provincia', property: 'provincia', align: 'center' },
  ];

  dataForTable = [
    { id: 1, codigo: '0001', nombre:'Marcos Lopez SL', nif: '7845145H', comunidadAutonoma: 'Comunidad Valenciana', provincia: 'Valencia' },
    { id: 2, codigo: '0002', nombre:'Marcos Lopez SL', nif: '7845145H', comunidadAutonoma: 'Comunidad Valenciana', provincia: 'Valencia' },
    { id: 3, codigo: '0003', nombre:'Marcos Lopez SL', nif: '7845145H', comunidadAutonoma: 'Comunidad Valenciana', provincia: 'Valencia' },
    { id: 4, codigo: '0004', nombre:'Marcos Lopez SL', nif: '7845145H', comunidadAutonoma: 'Comunidad Valenciana', provincia: 'Valencia' },
    { id: 5, codigo: '0005', nombre:'Marcos Lopez SL', nif: '7845145H', comunidadAutonoma: 'Comunidad Valenciana', provincia: 'Valencia' },
    { id: 6, codigo: '0006', nombre:'Marcos Lopez SL', nif: '7845145H', comunidadAutonoma: 'Comunidad Valenciana', provincia: 'Valencia' },
    { id: 7, codigo: '0007', nombre:'Marcos Lopez SL', nif: '7845145H', comunidadAutonoma: 'Comunidad Valenciana', provincia: 'Valencia' },
  ]

  optionsTabla: iDataTableOptions = {
    isSelectable: true,
    canEdit: true,
    canSee: true,
    canDelete: true,
    isHovered: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc'
  }


  constructor(private router:Router, private staticUtilitiesSE: StaticUtilitiesService) { }

  ngOnInit() {
    this.isLoading = true;
    setTimeout(() => {
      this.isLoading = false;
      
    }, 1000);
  }

  seeCliente(event){
    this.staticUtilitiesSE.goTo('crm/clientes/'+event.id, slideNavigationTypes.slideToTop)
  }

}
