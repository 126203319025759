import { iBaseObject } from "@quasar_dynamics/basic-designsystem";

export interface iCategoryNotice {
    id: number,
    category?: string,
}

export class iCategoryNotice extends iBaseObject {
    static convertFromCollection(ob: any): Array<iCategoryNotice> {
        let iCategoryNoticeCollection: Array<iCategoryNotice> = [];
        ob.forEach((element: any) => {
            iCategoryNoticeCollection.push(this.convertFromObject(element));
        });
        return iCategoryNoticeCollection;
    }

    static convertFromObject(ob: any): iCategoryNotice {
        let iCategoryNoticeObj = new iCategoryNotice();
        iCategoryNoticeObj.id = ob.id;
        iCategoryNoticeObj.category = ob.category;
        return iCategoryNoticeObj;
    }

    static createEmptyObject(): iCategoryNotice {
        let iCategoryNoticeObj = new iCategoryNotice();
        iCategoryNoticeObj.category = '';
        return iCategoryNoticeObj;
    }
}

export interface iCategoryNoticeFilter {
    num_devoluciones: number;
    num_pagina: number;
    genericFilter?: string;
    category?: string,
}