import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { iDataTableColumns, iDataTableOptions, iDate, iUnsubscribeDestroy, PopupService, slideNavigationTypes } from '@quasar_dynamics/basic-designsystem';
import { Subject, takeUntil } from 'rxjs';
import { AsignarProductoProveedorPopupComponent } from 'src/app/Popups/AsignarProductoProveedor-Popup/AsignarProductoProveedor-Popup.component';
import { ConfirmPopupComponent } from 'src/app/Popups/ConfirmPopup/ConfirmPopup.component';
import { NuevaMateriaPrimaPopupComponent } from 'src/app/Popups/NuevaMateriaPrima-Popup/NuevaMateriaPrima-Popup.component';
import { RawMaterialCompanyService } from 'src/app/Services/Api/RawMaterialCompany.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';
import { iRawMaterialCompanyFilter } from 'src/app/Shared/Interfaces/iRawMaterialCompany';
import * as XLSX from 'xlsx';

@Component({
  selector: 'erpProveedoresDetallesFichaProductos',
  templateUrl: './ERP-Proveedores-Detalles-FichaProductos.component.html',
  styleUrls: ['./ERP-Proveedores-Detalles-FichaProductos.component.scss']
})
export class ERPProveedoresDetallesFichaProductosComponent extends iUnsubscribeDestroy implements OnInit {
  isLoading: boolean = false;
  _reloadTableWidth = new Subject();

  @Input() data: any = [];
  @Output() refreshData = new EventEmitter<string>();


  //Tabla
  displayedColumnsTabla: Array<iDataTableColumns> = [
    { label: 'Artículo', property: 'name' },
    { label: 'Precio', property: 'precio', align: 'center', format: "currency" },
    { label: 'Unidad', property: 'unidad', align: 'center', },
    { label: 'Fecha oferta', property: 'fecha', align: 'center', },
  ];

  filter: iRawMaterialCompanyFilter = {
    num_devoluciones: 20,
    num_pagina: 1,

  }

  dataForTable = []
  proveedorId: number = 0;

  optionsTabla: iDataTableOptions = {
    isSelectable: true,
    moreIconsHead: [{ callback: (event) => { this.exportexcel() }, icon: "download" }],
    canDelete: true,
    isHovered: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc'
  }


  constructor(private router: Router, private staticUtilitiesSE: StaticUtilitiesService, private popupSE: PopupService, private staticUtilities: StaticUtilitiesService, private rawMaterialsCompanySE: RawMaterialCompanyService) {
    super();
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data']) {
      if (Object.keys(changes['data'].currentValue).length > 0) {
        this.data = changes['data'].currentValue;
        this.proveedorId = this.data.id;
        this.getRawMaterialsProveedor()
      }
    }
  }

  /**
     * FUNCTIONALITIES
     */


  asignarProducto() {
    this.popupSE.openPopup(AsignarProductoProveedorPopupComponent, { proveedorId: this.proveedorId });
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe((data) => {
      if (!data) return;
      this.refreshData.emit("refresh")
      StaticUtilitiesService.showFeedback("Se ha asignado el producto correctamente")
      this._unsubInd2.next("")
    })
  }

  see(event) {
    this.staticUtilities.goTo("/erp/proveedores/detalles-materia-prima", slideNavigationTypes.slideToTop, { materiaPrima: event.id })

  }
  delete(event: any, type: String) {
    switch (type) {
      case "single":
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'el producto' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd)).subscribe((data) => {
          StaticUtilitiesService.showFeedback("Se ha eliminado el producto correctamente")
          this._unsubInd.next("")
        })
        break;
      case "selected":
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'los productos seleccionadas' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd)).subscribe((data) => {
          StaticUtilitiesService.showFeedback("Se han eliminado los productos correctamente")
          this._unsubInd.next("")
        })
        break;
      case "all":
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'todas los productos' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd)).subscribe((data) => {
          StaticUtilitiesService.showFeedback("Se han eliminado los productos correctamente")
          this._unsubInd.next("")
        })
        break;
    }
  }

  search(event){
    if(!event){delete this.filter.genericFilter;this.getRawMaterialsProveedor(); return }
    this.filter.genericFilter = event;
    this.getRawMaterialsProveedor();
  }

  /**
* DESCARGA DE PARTES EN EXCEL
*/

  exportexcel(): void {
    let table = document.createElement("table")
    table.append((document.querySelector("dataTable .tableHeader thead") as HTMLElement).cloneNode(true))
    table.querySelector("thead .iconsActions")?.remove();
    table.querySelector("thead .checkboxContainer")?.remove();
    let rows = Array.from(document.querySelectorAll("dataTable .tableBody tbody tr "));
    let filteredRows = rows.filter((row) => {
      return row.querySelector('.checkboxContainer mat-checkbox ')!.getAttribute('ng-reflect-checked') === "true"
    })
    if (filteredRows.length > 0) {
      filteredRows.forEach((row) => {
        let newRow = row.cloneNode(true) as HTMLElement;
        newRow.querySelector('td:last-of-type')?.remove();
        newRow.querySelector('td:first-of-type')?.remove();
        table.append(newRow)
      })
    } else {
      table.append((document.querySelector("dataTable .tableBody tbody") as HTMLElement).cloneNode(true))
      table.querySelectorAll("tbody tr td:last-of-type").forEach(element => {
        element.remove();
      })
      table.querySelectorAll("tbody tr td:first-of-type").forEach(element => {
        element.remove();
      })
    }
    /* pass here the table id */
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, 'fichaDeProductos.xlsx');

  }


  /**
   * API CALLS
   */

  getRawMaterialsProveedor() {
    this.isLoading = true;
    this.rawMaterialsCompanySE.getAllPerCompany(this.filter, this.proveedorId);
    this.rawMaterialsCompanySE.getResult().pipe(takeUntil(this._unsubInd)).subscribe((res) => {
      if (!res) return;
      let { data } = res
      this.dataForTable = data.map((item) => { 
        let unit = item.measurementUnit === null ? '-' : item.measurementUnit.unit;
        return { ...item, fecha: iDate.javascriptConvert(new Date(item.rawMaterialCompanies[0].offerDate)).toStringDate('EU'), precio: item.rawMaterialCompanies[0].price, unidad: unit } });
      this.isLoading = false;
      this._unsubInd.next('')
    })
  }
}
