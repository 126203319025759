<div class="fullAcordeon">
  <filterActions>
    <ng-container filterContent>
      <div class="containerSelectores">
        <div>
          <selector></selector>
        </div>
        <div>
          <selector></selector>
        </div>
        <div>
          <selector></selector>
        </div>
        <div>
          <selector></selector>
        </div>
      </div>
    </ng-container>
  </filterActions>
  <pill class="tablePil" classList="tablePill">
    <dataTable [data]="dataForTable" [isLoading]="isLoading" [options]="optionsTabla"
      [displayedColumns]="displayedColumnsTabla"></dataTable>
  </pill>
</div>