import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { iOptionsSelector, iUnsubscribeDestroy } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { AddressService } from 'src/app/Services/Api/Address.service';
import { StaticDataHandlerService } from 'src/app/Services/Utils/StaticDataHandler.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'app-NuevaDireccionDeEnvioAlternativa',
  templateUrl: './NuevaDireccionDeEnvioAlternativa.component.html',
  styleUrls: ['./NuevaDireccionDeEnvioAlternativa.component.scss']
})
export class NuevaDireccionDeEnvioAlternativaComponent extends iUnsubscribeDestroy implements OnInit {
  isCompleted = false;
  nombre: string = "";
  direccion: string = "";
  pais: string | null = null;
  provincia: string | null = null;
  municipio: string = "";
  cp: string = "";
  number: number | null = null;
  telefono: string = "";
  company: number = 0;
  opcionesProvincias: iOptionsSelector = {
    items: StaticDataHandlerService.getProvincias(),
    clearable: true,
    search: true,
    placeholder: "Provincia",
    append: true,
  }
  opcionesPaises: iOptionsSelector = {
    items: StaticDataHandlerService.getPaises(),
    clearable: true,
    search: true,
    placeholder: "País",
    append: true,
  }

  updateObject:any = {
    id: 0,
  }

  constructor(public dialogRef: MatDialogRef<NuevaDireccionDeEnvioAlternativaComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any, private addresSE: AddressService) {
    super();
  }

  ngOnInit() {
    if (this.modalData.accion === 'crear') {
      this.company = Number(this.modalData.data);
    }
    if (this.modalData.accion === 'editar') {
      this.getAddresById()
    }
  }

  editInput(event,key){
    if(key === 'phones'){
      this.updateObject[key] = [event];
      this.checkIsCompleted();
      this.editAddress()
      return;
    }
    this.updateObject[key] = event;
    this.checkIsCompleted();
    this.editAddress()
  }

  setter(data) {
    this.nombre = data.contact;
    this.direccion = data.street;
    this.pais = data.country;
    this.provincia = data.province;
    this.municipio = data.municipality;
    this.cp = data.postalCode;
    this.number = data.number;
    this.telefono = data.phones[0];
    this.updateObject.id = data.id;
  }
  /**
* FUNCTIONALITY
*/
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }

  checkIsCompleted() {
    if ( this.direccion && this.pais && this.provincia && this.municipio && this.cp && this.telefono && this.number) {
      this.isCompleted = true;
    } else {
      this.isCompleted = false;
    }
  }

  create() {
    let objectToPass: any = {
      name: this.nombre,
      street: this.direccion,
      country: this.pais,
      province: this.provincia,
      municipality: this.municipio,
      postalCode: this.cp,
      phones: [this.telefono],
      company: this.company,
      number: this.number,

    }
    this.addresSE.create(objectToPass);
    this.addresSE.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe((res) => {
      if (!res) return
      StaticUtilitiesService.showFeedback('Dirección creada correctamente')
      this.closePopup(res);
      this._unsubInd.next('');
    })
    this.addresSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res) => {
      if (!res) return
      StaticUtilitiesService.showError('La direccion no pudo ser creada')
      this._unsub.next('');
    })
  }

  getAddresById() {
    this.addresSE.getSingle(this.modalData.data.id);
    this.addresSE.getResultIndividual().pipe(takeUntil(this._unsubInd2)).subscribe((res) => {
      if (!res) return
      let { data } = res;
      this.setter(data)
      this._unsubInd2.next('');
    })
  }

  editAddress(){
    this.addresSE.update(this.updateObject);
    this.addresSE.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe((res) => {
      if (!res) return
      StaticUtilitiesService.showFeedback('Dirección editada correctamente')
      
      this._unsubInd.next('');
    })
    this.addresSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res) => {
      if (!res) return
      StaticUtilitiesService.showError('La direccion no pudo ser editada')
      this._unsub.next('');
    })

  }

}
