import { iBaseObject } from "@quasar_dynamics/basic-designsystem";
import { iCompany } from "./iCompany";
import { iWayToPay } from "./iWayToPay";
import { iTypesIva } from "./iTypesIva";

export interface iPaymentMethod {
    id?: number,
    iban?: string,
    bic?: string,
    surcharge?: number,
    maxRisk?: number,
    irpf?: number,
    tarifa?: number,
    banco?: string,
    company?: iCompany | [],
    wayToPay?: iWayToPay,
    typeIva?: iTypesIva,
}

export class iPaymentMethod extends iBaseObject {
    static convertFromCollection(ob: any): Array<iPaymentMethod> {
        let iPaymentMethodCollection: Array<iPaymentMethod> = [];
        ob.forEach((element: any) => {
            iPaymentMethodCollection.push(this.convertFromObject(element));
        });
        return iPaymentMethodCollection;
    }

    static convertFromObject(ob: any): iPaymentMethod {
        let iPaymentMethodObj = new iPaymentMethod();
        iPaymentMethodObj.id = ob.id;
        iPaymentMethodObj.iban = ob.iban;
        iPaymentMethodObj.bic = ob.bic;
        iPaymentMethodObj.surcharge = ob.surcharge;
        iPaymentMethodObj.maxRisk = ob.maxRisk;
        iPaymentMethodObj.irpf = ob.irpf;
        iPaymentMethodObj.tarifa = ob.tarifa;
        iPaymentMethodObj.banco = ob.banco;
        iPaymentMethodObj.company = ob.company ? iCompany.convertFromObject(ob.company) :new iCompany();
        iPaymentMethodObj.wayToPay = ob.wayToPay ? iWayToPay.convertFromObject(ob.wayToPay) : iWayToPay.createEmptyObject();
        iPaymentMethodObj.typeIva = ob.typeIva ? iTypesIva.convertFromObject(ob.typeIva) : iTypesIva.createEmptyObject();
        return iPaymentMethodObj;
    }

    static createEmptyObject(): iPaymentMethod {
        let iPaymentMethodObj = new iPaymentMethod();
        iPaymentMethodObj.iban = '';
        iPaymentMethodObj.bic = '';
        iPaymentMethodObj.surcharge = -1;
        iPaymentMethodObj.maxRisk = -1;
        iPaymentMethodObj.irpf = -1;
        iPaymentMethodObj.tarifa = -1;
        iPaymentMethodObj.banco = '';
        iPaymentMethodObj.company = [];
        iPaymentMethodObj.wayToPay = iWayToPay.createEmptyObject();
        iPaymentMethodObj.typeIva = iTypesIva.createEmptyObject();
        return iPaymentMethodObj;
    }
}

export interface iPaymentMethodFilter {
    num_devoluciones: number;
    num_pagina: number;
    genericFilter?: string;
    iban?: string,
    bic?: string,
    surcharge?: number,
    maxRisk?: number,
    irpf?: number,
    tarifa?: number,
    banco?: string,
    company?: number,
    wayToPay?: number,
    typeIva?: number,
}