import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { iUnsubscribeDestroy } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { FungibleService } from 'src/app/Services/Api/Fungible.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'app-NuevoFungible-Popup',
  templateUrl: './NuevoFungible-Popup.component.html',
  styleUrls: ['./NuevoFungible-Popup.component.scss']
})
export class NuevoFungiblePopupComponent extends iUnsubscribeDestroy implements OnInit {

  descripcion: string = "";
  notas: string = ""
  code: string = "";
  updateObject = {
    id: "",
  }
  isCompleted: boolean = false;
  constructor(public dialogRef: MatDialogRef<NuevoFungiblePopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any, private fungibleSE:FungibleService) {
    super();
  }

  ngOnInit() {
    if(this.modalData.accion === 'editar' || this.modalData.accion === 'ver'){
      this.setter()
    }
   }

  /**
  * FUNCTIONALITY
  */
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }

  /**
   * GETTERS && SETTERS
   */
  editSecction(event,key:string){
    if(!event){return}
    this.updateObject[key] = event;
    this.checkIsCompleted();
    this.edit()
  }
  setter(){
    this.descripcion = this.modalData.data.description;
    this.notas = this.modalData.data.note;
    this.code = this.modalData.data.code;
    this.updateObject.id = this.modalData.data.id;
  }

  /**
   * VALIDATIONS
   */
  checkIsCompleted() {
    this.descripcion && this.notas ? this.isCompleted = true : this.isCompleted = false
  }

  edit(){
    this.fungibleSE.update(this.updateObject);
    this.fungibleSE.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe((res)=>{
      if(!res){return}
      StaticUtilitiesService.showFeedback('Fungible actualizado correctamente');
      this._unsubInd.next('');
    })
    this.fungibleSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res)=>{
      if(!res){return}
      StaticUtilitiesService.showError('Error al actualizar fungible');
      this._unsub.next('');
    })
  }
  create(){
    let data = {description:this.descripcion,note:this.notas,code:this.code}
    this.fungibleSE.create(data);
    this.fungibleSE.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe((res)=>{
      if(!res){return}
      StaticUtilitiesService.showFeedback('Fungible creado correctamente');
      this.closePopup('create');
      this._unsubInd.next('');
    })
    this.fungibleSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res)=>{
      if(!res){return}
      StaticUtilitiesService.showFeedback('No se pudo crear el fungible');
      this._unsub.next('');
    })
  }

}
