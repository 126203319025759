import {
  Component,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
} from '@angular/core';
import {
  iUnsubscribeDestroy,
  PopupService,
} from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { DocumentosPopupComponent } from 'src/app/Popups/Documentos-Popup/Documentos-Popup.component';
import { NuevoClientePopupComponent } from 'src/app/Popups/NuevoCliente-Popup/NuevoCliente-Popup.component';
import { SubirDocumentoPopupComponent } from 'src/app/Popups/SubirDocumento-Popup/SubirDocumento-Popup.component';
import { OrderService } from 'src/app/Services/Api/Order.service';
import { StaticDataHandlerService } from 'src/app/Services/Utils/StaticDataHandler.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'erpPedidosDetallesFicha',
  templateUrl: './ERP-Pedidos-Detalles-Ficha.component.html',
  styleUrls: ['./ERP-Pedidos-Detalles-Ficha.component.scss'],
})
export class ERPPedidosDetallesFichaComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.resize();
  }

  @Output() orderName = new EventEmitter<string>();
  pedidoId: number = 0;
  data: any = [];

  documentos: Array<any> = [];
  constructor(
    private popup: PopupService,
    private popupSE: PopupService,
    private staticUtilitiesSE: StaticUtilitiesService,
    private orderSE: OrderService
  ) {
    super();
  }

  ngAfterViewInit(): void {
    this.resize();
  }

  ngOnInit() {
    this.pedidoId = this.staticUtilitiesSE.getParams().pedido;
    this.getOrderId();
  }

  /**
   * edit
   */

  edit(section) {
    this.popup.openPopup(NuevoClientePopupComponent, {
      data: 'data',
      accion: 'editar',
      section: section,
    });
    this.popup
      .returnData()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        this._unsubInd.next('');
      });
  }

  /**
   * FUNCTIONALITIES
   */
  resize() {
    setTimeout(() => {
      (
        document.querySelector('pill:nth-of-type(2)') as HTMLElement
      ).style.height =
        (document.querySelector('pill:first-of-type') as HTMLElement)
          .clientHeight +
        (document.querySelector('pill:first-of-type') as HTMLElement).style
          .padding +
        'px';
    }, 1000);
  }

  /**
   * DOCUMENTOS
   */

  subirDocumento() {
    this.popupSE.openPopup(SubirDocumentoPopupComponent, {
      formatos: ['image/png', 'image/jpeg', 'application/pdf'],
      size: 10485760,
    });
    this.popupSE
      .returnData()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((data) => {
        this.editDocumento(data['returnValue'].map((item) => item['base64']));
        this._unsubInd.next('');
      });
  }

  verDocumentos(editar: boolean = false) {
    this.popupSE.openPopup(DocumentosPopupComponent, {
      editar: editar,
      documentos: this.documentos,
      pedidoId: this.pedidoId,
    });
    this.popupSE
      .returnData()
      .pipe(takeUntil(this._unsubInd3))
      .subscribe((data) => {
        setTimeout(() => {
          this.getOrderId();
        }, 500);
        this._unsubInd3.next('');
      });
  }

  /**
   * API CALLS
   */

  getOrderId() {
    this.orderSE.getSingle(this.pedidoId);
    this.orderSE
      .getResultIndividual()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        let { data } = res;
        this.orderName.emit(data.company.name);
        this.data = data;
        this.documentos = [...data.attacheds];
        this._unsubInd.next('');
      });
  }
  editDocumento(data) {
    this.orderSE.update({ id: this.pedidoId, attacheds: data });
    this.orderSE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        this.getOrderId();
        StaticUtilitiesService.showFeedback(
          'Se ha subido el documento correctamente'
        );
        this._unsubInd2.next('');
      });
    this.orderSE
      .getResultUpdateError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        this.getOrderId();
        StaticUtilitiesService.showError('No se ha podido subir el documento');
        this._unsub.next('');
      });
  }
}
