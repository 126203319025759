import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { MatCalendarCellClassFunction } from '@angular/material/datepicker';
import { iDate } from '@quasar_dynamics/basic-designsystem';


@Component({
  selector: 'datepicker',
  templateUrl: './DatePicker.component.html',
  styleUrls: ['./DatePicker.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DatePickerComponent implements OnInit, OnChanges {

  @Output() dataFromPicker = new EventEmitter<any>();
  @Input() placeholder!: Date
  @Input() textoPlaceholder: string = 'Inserte fecha'
  @Input() clearable: boolean = false;
  @Input() filter: boolean = false;
  @Input() readonly: boolean = false;


  date:any = "Seleccione una fecha, mi rey";


  data:any =  this.placeholder;



  constructor() { }


  ngOnInit() {
  }

  ngOnChanges(changes:SimpleChanges){
    if(changes['placeholder']){
      this.data = changes['placeholder'].currentValue
    }
  }

  clear(){
    this.data = ""
    this.dataFromPicker.emit(null)

  }

  dataPickerChange(event:any){
  let data = event.value;
  this.dataFromPicker.emit(iDate.javascriptConvert(new Date(data)).toStringDate('JAP'))
  }


}
