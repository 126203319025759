import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'FullContainer',
  templateUrl: './FullContainer.component.html',
  styleUrls: ['./FullContainer.component.scss']
})
export class FullContainerComponent implements OnInit {

  @Input() backgroundColor = "#F6F6F6";

  constructor( ) { }

  ngOnInit() {
  }

}
