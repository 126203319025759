<div class="polvo">
  <div class="searcherCustomAndPopup">
    <searcherCustom (searchSend)="search($event)" [placeHolder]="'Buscar aquí...'" (change)="search($event)"></searcherCustom>
  </div>
  <Pagination [paginacionTotal]="totalPaginacion" (change)="changePagination($event)"></Pagination>
  <pill class="tablePil" classList="tablePill">
    <dataTable [data]="dataForTableFiltered" [forceUpdateWidth]="_reloadTableWidth" [isLoading]="isLoading"  [options]="optionsTabla" [displayedColumns]="displayedColumnsTabla" (edit)="edit($event)" ></dataTable>
    <div class="promedios" *ngIf="!isLoading">
      <span>PROMEDIO: </span>
      <span>{{promedioCoste | currency:'EUR':'symbol':'1.2-2'}}</span>
      <span>{{promedioTarifa1 | currency:'EUR':'symbol':'1.2-2'}}</span>
      <span>{{promedioTarifa2 | currency:'EUR':'symbol':'1.2-2'}}</span>
      <span [class.red]="aporte<1">{{aporte | currency:'EUR':'symbol':'1.2-2'}}</span>
      <span [class.red]="factor<0.50">{{factor | percent}}</span>
    </div>
  </pill>
</div>
