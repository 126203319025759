import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { iOptionsSelector, iResultHttp, iUnsubscribeDestroy, slideNavigationTypes } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { AddressService } from 'src/app/Services/Api/Address.service';
import { AgentService } from 'src/app/Services/Api/Agent.service';
import { CompanyService } from 'src/app/Services/Api/Company.service';
import { StaticDataHandlerService } from 'src/app/Services/Utils/StaticDataHandler.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';
import { iAgent } from 'src/app/Shared/Interfaces/iAgent';

@Component({
  selector: 'app-NuevoAgente-Popup',
  templateUrl: './NuevoAgente-Popup.component.html',
  styleUrls: ['./NuevoAgente-Popup.component.scss']
})
export class NuevoAgentePopupComponent extends iUnsubscribeDestroy implements OnInit {
  
  opcionesPais: iOptionsSelector = {
    items: StaticDataHandlerService.getPaises(),
    clearable: true,
    search: true,
    placeholder: "País",
    bindValue: "label",
    append: true
  }

  opcionesProvincias: iOptionsSelector = {
    items: StaticDataHandlerService.getProvincias(),
    clearable: true,
    search: true,
    placeholder: "Provincia",
    bindValue: "label",
    append: true
  }
  opcionesClienteAsignados: iOptionsSelector = {
    items: [],
    clearable: true,
    search: true,
    multiple: {
      isMultiple: true,
      checkbox: true,
      multipleLimitReplacer: 2,
      multipleTextReplacer: "Varios clientes seleccionados"
    },

    placeholder: "Clientes asignados",
    append: true
  }
  isCompleted: boolean = false;
  codigo: string = ""
  nombre: string = ""
  nif: string = ""

  email: string = ""
  telefono1: number | null = null;
  telefono2: number | null = null;

  direccion: string = ""
  municipio: string = ""
  codigoPostal: number | null = null;

  comision: number | null = null;
  pais: string | null = "España";
  provincia: string | null = null;
  clientes: any = null;

  updateObject: any = {
    id: '',
  }
  updateObjectAddress: any = {
    id: '',
  }

  constructor(public dialogRef: MatDialogRef<NuevoAgentePopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any, private companySE:CompanyService, private agentSE:AgentService, private addressSE:AddressService, private staticUtilities:StaticUtilitiesService) {
    super();
  }

  ngOnInit() {
     
    if (this.modalData.accion === "editar" || this.modalData.accion === "ver") {
      this.setter();
    }
    this.getAllClients()
  }

  /**
   * GETTERS && SETTERS
   */

  setter() {
    this.codigo = this.modalData.data.code;
    this.nombre = this.modalData.data.name;
    this.nif = this.modalData.data.nif;
    this.email = this.modalData.data.email;
    this.telefono1 = this.modalData.data.address.phones[0];
    this.telefono2 = this.modalData.data.address.phones[1];
    this.direccion = this.modalData.data.address.street;
    this.municipio = this.modalData.data.address.municipality;
    this.codigoPostal = this.modalData.data.address.postalCode;
    this.comision = this.modalData.data.comision;
    this.pais = this.modalData.data.address.country;
    this.provincia = this.modalData.data.address.province;
    this.clientes = this.modalData.data.company.map((item) => { return item.name });
    this.updateObject.id = this.modalData.data.id;
    this.updateObjectAddress.id = this.modalData.data.address.id;
  }
  editSecction(event,key:string){
    if(!event){return};
    this.updateObject[key] = event;
    this.checkIsCompleted()
    this.edit();
  }
  editSectionAddress(event,key:string){
    if(!event){return};
    if(key === 'telefono1' ){
      this.updateObjectAddress.phones = [Number(event),this.telefono2];
       
      this.checkIsCompleted()
      this.editAddress();
      return
    }
    if(key === 'telefono2' ){
      this.updateObjectAddress.phones = [this.telefono1,event];
      this.checkIsCompleted()
      this.editAddress();
      return
    }
    this.updateObjectAddress[key] = event;
    this.checkIsCompleted()
    this.editAddress();
  }
  getSelectorAddress(event,key){
    if(!event){return};
    this.updateObjectAddress[key] = event;
    this.checkIsCompleted()
    this.editAddress();
  }
  getSelectorAgent(event,key){
    if(!event){return};
     
    if(event.length>0){
      this.updateObject[key] = event.map((item)=>{return item.id});
    }else{
      this.updateObject[key] = [];
    }
    this.checkIsCompleted()
    this.edit();
  }

  /**
  * FUNCTIONALITY
  */
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }

  /**
   * VALIDATIONS
   */
  isValidFormat(email: string) {
     return/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
  }
  checkIsCompleted() {
    /*this.codigo &&*/ (this.nombre && this.nif && this.email && this.telefono1 && this.telefono2 && this.direccion && this.municipio && this.codigoPostal && this.comision && this.pais && this.provincia && this.clientes && this.email) ? this.isCompleted = true : this.isCompleted = false;
    
  }

  /**
   * API CALLS
   */

  getAllClients(){
    this.companySE.getClientOrProveedor({num_devoluciones:5000, num_pagina:1, isClient:true});
    this.companySE.getResult().pipe(takeUntil(this._unsubInd)).subscribe((res) => {
      if(!res) return;
      let {data} = res.data;
      this.opcionesClienteAsignados.items = data.map((item) => { return { id: item.id, label: item.name } });
      this._unsubInd.next('');
    })
  }
  edit(){
    this.agentSE.update(this.updateObject);
    this.agentSE.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe((res) => {
      if(!res) return;
      StaticUtilitiesService.showFeedback('Agente editado correctamente')
      this._unsubInd.next('');
    })
    this.agentSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res) => {
      if(!res) return;
      StaticUtilitiesService.showError('No se pudo editar el agente')
      this._unsub.next('');
    })
  }
  editAddress(){
    this.addressSE.update(this.updateObjectAddress);
    this.addressSE.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe((res) => {
      if(!res) return;
      StaticUtilitiesService.showFeedback('Agente editado correctamente')
      this._unsubInd.next('');
    })
    this.addressSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res) => {
      if(!res) return;
      StaticUtilitiesService.showError('No se pudo editar el agente')
      this._unsub.next('');
    })
  }
  create(){
    let data:any ={
      name: this.nombre,
      nif: this.nif,
      email: this.email,
      comision: this.comision,
      code: this.codigo,
      address: {
        street: this.direccion,
        municipality: this.municipio,
        postalCode: this.codigoPostal,
        province: this.provincia,
        country: this.pais,
        phones: [this.telefono1,this.telefono2]
      },
      company: this.clientes!.map((item)=>{return item.id})
    } 
     
    this.agentSE.create(data)
    this.agentSE.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe((res) => {
      if(!res) return;
      StaticUtilitiesService.showFeedback('Agente creado correctamente')
      this.closePopup('create');
      this._unsubInd.next('');
    })
    this.agentSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res) => {
      if(!res) return;
      StaticUtilitiesService.showError(res.message)
      this._unsub.next('');
    })
  }

}
