import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { iOptionsSelector, iUnsubscribeDestroy } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { AddressService } from 'src/app/Services/Api/Address.service';
import { AgentService } from 'src/app/Services/Api/Agent.service';
import { CompanyService } from 'src/app/Services/Api/Company.service';
import { PaymentMethodService } from 'src/app/Services/Api/PaymentMethod.service';
import { PorteService } from 'src/app/Services/Api/Porte.service';
import { TypesIvaService } from 'src/app/Services/Api/TypesIva.service';
import { WayToPayService } from 'src/app/Services/Api/WayToPay.service';
import { StaticDataHandlerService } from 'src/app/Services/Utils/StaticDataHandler.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'app-NuevoCliente-Popup',
  templateUrl: './NuevoCliente-Popup.component.html',
  styleUrls: ['./NuevoCliente-Popup.component.scss'],
})
export class NuevoClientePopupComponent extends iUnsubscribeDestroy implements OnInit {
  codigo: string = '';
  nif: string = '';
  direccion: string = '';
  municipio: string = '';
  numero: string = '';
  cp: string = '';
  provincia: any = null;
  pais: any = null;
  nombre: string = '';
  telefono: string = '';
  telefono2: string = '';
  telefono3: string = '';
  email: string = '';
  email2: string = '';
  email3: string = '';
  orderEmails: string = '';
  formaDePago: any = null;
  iban: string = '';
  bic: string = '';
  tipoIVA: any= null;
  recargoEquivalencia: any  | null= null;
  recargoTarifaAsignada: any = null;
  riesgoMax: string|null = null;
  portes: any = null;
  agente: any = null;
  observaciones: string = '';
  isCompleted: boolean = false;
  objectToSend: any = {};
  opcionesProvincias: iOptionsSelector = {
    items: StaticDataHandlerService.getProvincias(),
    clearable: true,
    search: true,
    placeholder: 'Provincia',
    append: true,
  };
  opcionesPaises: iOptionsSelector = {
    items: StaticDataHandlerService.getPaises(),
    clearable: true,
    search: true,
    placeholder: 'País',
    append: true,
  };
  opcionesFormasPago: iOptionsSelector = {
    items: [],
    clearable: true,
    search: true,
    bindLabel: 'description',
    placeholder: 'Formas de pago',
    append: true,
  };
  opcionesTipoIVA: iOptionsSelector = {
    items: [],
    clearable: true,
    search: true,
    placeholder: 'Tipo de IVA',
    bindLabel: 'description',
    append: true,
  };
  opcionesTarifaAsignada: iOptionsSelector = {
    items: [
      {label:'tarifa 1', id: 1},
      {label:'tarifa 2', id: 2},
    ],
    clearable: true,
    search: true,
    bindValue: 'id',
    placeholder: 'Tarifa Asignada',
    append: true,
  };
  opcionesPortes: iOptionsSelector = {
    items: [],
    clearable: true,
    search: true,
    bindLabel: 'name',
    placeholder: 'Portes',
    append: true,
  };
  opcionesAgente: iOptionsSelector = {
    items: [],
    clearable: true,
    search: true,
    placeholder: 'Agente',
    append: true,
    bindLabel: 'name',
    bindValue: 'id',
    multiple: {
      isMultiple: true,
      multipleLimitReplacer:2,
      multipleTextReplacer: ' Varios agentes seleccionados',
    }
  };
  opcionesRecargoEquivalencia: iOptionsSelector = {
  items: ['Sí', 'No'],
  clearable: true,
  placeholder: 'Recargo'
  };
  updateCompanyObject:any = {
    id: null,
    isClient:true,
  }
  updateCompanyPayment:any = {
    id: null,
  }
  updateCompanyAddress:any = {
    id: null,
  }
  telefonos: any[] = [];
  emails: any[] = [];
  constructor(
    public dialogRef: MatDialogRef<NuevoClientePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any, private companySE: CompanyService, private wayToPaySE:WayToPayService, private typesIvaSE:TypesIvaService, private porteSE:PorteService, private agentSE:AgentService, private addressSE:AddressService, private paymentMethodSE:PaymentMethodService) {
    super();
  }

  ngOnInit() {
     
    if (this.modalData.accion !== 'crear') {
      this.getCompanyId(this.modalData.data.id)
    }
    this.getWayToPayForFilter()
    this.getTypesIvaForFilter()
    this.getPortesForFilter()
    this.getAgentsForFilter()
  }

  /***
   * GETTER AND SETTER
   */

  setter(data) {
    this.codigo = data.code;
    this.nif = data.nif;
    this.direccion = data.billingAddress?.street;
    this.municipio = data.billingAddress?.municipality;
    this.numero = data.billingAddress?.number;
    this.cp = data.billingAddress?.postalCode;
    this.provincia = data.billingAddress?.province;
    this.pais = data.billingAddress?.country;
    this.nombre = data.name;
    this.telefono = data.phones[0];
    this.telefono2 = data.phones[1];
    this.telefono3 = data.phones[2];
    this.email = data.emails[0];
    this.email2 = data.emails[1];
    this.email3 = data.emails[2];
    this.formaDePago = data.paymentMethods[0]?.wayToPay;
    this.iban = data.paymentMethods[0]?.iban;
    this.bic = data.paymentMethods[0]?.bic;
    this.tipoIVA = data.paymentMethods[0]?.typeIva;
    this.recargoEquivalencia = (data.paymentMethods[0]?.surcharge == 0)? 'No' : 'Sí';
    this.orderEmails = data.orderEmails[0];
    this.recargoTarifaAsignada = data.paymentMethods[0]?.tarifa;
    this.riesgoMax = data.paymentMethods[0]?.maxRisk;
    this.portes = data.porte;
    this.agente = data.agents?.map((agent) => agent.id);
    this.observaciones = data.observation;
    this.updateCompanyObject.id = data.id;
    this.updateCompanyPayment.id = data.paymentMethods[0]?.id;
    this.updateCompanyAddress.id = data.billingAddress?.id;
     
  }
  editCompany(event,key){
    if(!event){return};
    if(key === 'telefono1'){
      this.updateCompanyObject['phones'] = [event, this.telefono2, this.telefono3]
      this.checkIsCompleted();
      this.updateCliente();
      return
    }
    if(key === 'telefono2'){
      this.updateCompanyObject['phones'] = [this.telefono, event, this.telefono3]
      this.checkIsCompleted();
      this.updateCliente();
      return
    }
    if(key === 'telefono3'){
      this.updateCompanyObject['phones'] = [this.telefono, this.telefono2, event]
      this.checkIsCompleted();
      this.updateCliente();
      return
    }
    if(key === 'email1'){
      this.updateCompanyObject['emails'] = [event, this.email2, this.email3]
      this.checkIsCompleted();
      this.updateCliente();
      return
    }
    if(key === 'email2'){
      this.updateCompanyObject['emails'] = [this.email, event, this.email3]
      this.checkIsCompleted();
      this.updateCliente();
      return
    }
    if(key === 'email3'){
      this.updateCompanyObject['emails'] = [this.email, this.email2, event]
      this.checkIsCompleted();
      this.updateCliente();
      return
    }
    if(key === 'orderEmails'){
      this.updateCompanyObject['orderEmails'] = [event]
      this.checkIsCompleted();
      this.updateCliente();
      return
    }
    if(key === 'porte'){
      event = event.id;
    }

    this.updateCompanyObject[key] = event;
    this.checkIsCompleted();
    this.updateCliente();
     
  }
  editAddress(event,key){
    if(!event){return};
    this.updateCompanyAddress[key] = event;
    this.checkIsCompleted();
    this.updateAddress();
  }
  editPayment(event,key){
    if(!event){return};
    if(key === 'wayToPay'){
      event = event.id
    }
    if(key === 'typeIva'){
      event = event.id
    }
    if(key === 'surcharge'){
      event = event == 'Sí' ? true : false;
    }


    this.updateCompanyPayment[key] = event;
    this.checkIsCompleted();
    this.updatePaymentMethod();
  }

  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }

  setDataToSend() {
    this.objectToSend = {
      name: this.nombre,
      code: this.codigo,
      isClient: true
    }

    if(this.nif){this.objectToSend['nif'] = this.nif}
    if(this.telefono){this.objectToSend['phones'] = [this.telefono]}
    if(this.telefono2){this.objectToSend['phones'] = [...this.objectToSend['phones'], this.telefono2]}
    if(this.telefono3){this.objectToSend['phones'] = [...this.objectToSend['phones'], this.telefono3]}
    if(this.email){this.objectToSend['emails'] = [this.email]}
    if(this.email2){this.objectToSend['emails'] = [...this.objectToSend['emails'], this.email2]}
    if(this.email3){this.objectToSend['emails'] = [...this.objectToSend['emails'], this.email3]}
    if(this.orderEmails){this.objectToSend['orderEmails'] = [this.orderEmails]}
    if(this.observaciones){this.objectToSend['observation'] = this.observaciones}
    if(this.agente){this.objectToSend['agents'] = this.agente}
    if(this.iban || this.bic || this.formaDePago || this.riesgoMax || this.tipoIVA || this.recargoTarifaAsignada || this.recargoEquivalencia) {
      this.objectToSend['paymentMethod'] = {
        iban: this.iban,
        bic: this.bic,
        wayToPay: this.formaDePago?.id,
        maxRisk: this.riesgoMax,
        typeIva: this.tipoIVA?.id,
        tarifa:this.recargoTarifaAsignada,
        surcharge: this.recargoEquivalencia == 'Sí' ? true : false,
        irpf:false,
      }
    }
    if(this.portes){this.objectToSend['porte'] = this.portes?.id}
    if(this.direccion || this.numero || this.cp || this.provincia || this.pais || this.municipio) {
      this.objectToSend['companyAddress'] = {
        description: 'Cliente '+this.nombre,
        street: this.direccion,
        number: this.numero,
        postalCode:this.cp,
        country: this.pais,
        province: this.provincia,
        municipality: this.municipio,
        phones: [this.telefono,this.telefono2,this.telefono3],
      }
      this.objectToSend['billingAddress'] = {
        description: 'Cliente '+this.nombre,
        street: this.direccion,
        number: this.numero,
        postalCode:this.cp,
        country: this.pais,
        province: this.provincia,
        municipality: this.municipio,
        phones: [this.telefono,this.telefono2,this.telefono3],
      }
    }
  }

  /**
   * VALIDATIONS
   */
  checkIsCompleted() {
    this.codigo &&
    this.nombre 
      ? (this.isCompleted = true)
      : (this.isCompleted = false);
  }

  checkData() {
    if(this.iban || this.formaDePago || this.tipoIVA) {
      if(!this.iban || !this.formaDePago || !this.tipoIVA){
        StaticUtilitiesService.showError('Faltan datos de pago')
        return
      }
    }
    // if(this.direccion || this.numero || this.cp || this.provincia || this.pais || this.municipio) {
    //   if(!this.direccion || !this.numero || !this.cp || !this.provincia || !this.pais || !this.municipio){
    //     StaticUtilitiesService.showError('Faltan datos de dirección')
    //     return
    //   }
    // }
  }

  /**
   * API CALLS
   */

  createCliente(){
    this.checkData();
    this.setDataToSend();
    this.companySE.create(this.objectToSend);
    this.companySE.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe((res) => {
      if(!res){return}
      StaticUtilitiesService.showFeedback('Cliente creado correctamente')
      this.closePopup(res);
      this._unsubInd.next('')
    })
    this.companySE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res) => {
      if(!res){return}
      StaticUtilitiesService.showError('Error al crear el cliente')
      this._unsub.next('')
    })
  }

  getWayToPayForFilter(){
    this.wayToPaySE.getAll({num_devoluciones:2000, num_pagina:1});
    this.wayToPaySE.getResult().pipe(takeUntil(this._unsubInd)).subscribe((res) => {
      if(!res){return}
      let {data} = res.data;
      this.opcionesFormasPago.items = data;
      this._unsubInd.next('')
    })
  }

  getTypesIvaForFilter(){
    this.typesIvaSE.getAll({num_devoluciones:2000, num_pagina:1});
    this.typesIvaSE.getResult().pipe(takeUntil(this._unsubInd2)).subscribe((res) => {
      if(!res){return}
      let {data} = res.data;
      this.opcionesTipoIVA.items = data;
      this._unsubInd2.next('')
    })
  }
  getPortesForFilter(){
    this.porteSE.getAll({num_devoluciones:2000, num_pagina:1});
    this.porteSE.getResult().pipe(takeUntil(this._unsubInd3)).subscribe((res) => {
      if(!res){return}
      let {data} = res.data;
      this.opcionesPortes.items = data;
      this._unsubInd3.next('')
    })
  }
  getAgentsForFilter(){
    this.agentSE.getAll({num_devoluciones:2000, num_pagina:1});
    this.agentSE.getResult().pipe(takeUntil(this._unsubInd4)).subscribe((res) => {
      if(!res){return}
      let {data} = res.data;
      this.opcionesAgente.items = data;
      this._unsubInd4.next('')
    })
  }
  getCompanyId(id){
    this.companySE.getSingle(id);
    this.companySE.getResultIndividual().pipe(takeUntil(this._unsubInd5)).subscribe((res) => {
      if(!res){return}
      let {data} = res;
      this.setter(data);
      this._unsubInd5.next('')
    })
  }
  updateCliente(){
    this.companySE.update(this.updateCompanyObject);
    this.companySE.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe((res) => {
      if(!res){return}
      StaticUtilitiesService.showFeedback('Cliente actualizado correctamente')
      this._unsubInd.next('')
    })
    this.companySE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res) => {
      if(!res){return}
      StaticUtilitiesService.showError('El cliente no pudo ser actualizado')
      this._unsub.next('')
    })
  }
  updateAddress(){
    this.addressSE.update(this.updateCompanyAddress);
    this.addressSE.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe((res) => {
      if(!res){return}
      StaticUtilitiesService.showFeedback('Dirección actualizada correctamente')
      this._unsubInd.next('')
    })
    this.addressSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res) => {
      if(!res){return}
      StaticUtilitiesService.showError('La dirección no pudo ser actualizada')
      this._unsub.next('')
    })
  }
  updatePaymentMethod(){
    this.paymentMethodSE.update(this.updateCompanyPayment)
    this.paymentMethodSE.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe((res) => {
      if(!res){return}
      StaticUtilitiesService.showFeedback('Cliente actualizado correctamente')
      this._unsubInd.next('')
    })
    this.paymentMethodSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res) => {
      if(!res){return}
      StaticUtilitiesService.showError('El cliente no pudo ser actualizado')
      this._unsub.next('')
    })
  }
}
