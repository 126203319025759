import { Component, OnInit } from '@angular/core';
import { StaticDataHandlerService } from 'src/app/Services/Utils/StaticDataHandler.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'app-CRM-Dashboard',
  templateUrl: './CRM-Dashboard.component.html',
  styleUrls: ['./CRM-Dashboard.component.scss']
})
export class CRMDashboardComponent implements OnInit {
  navegationERP = [
    { label: 'Clientes', color: '#009E9E', router: '/crm/clientes',},
    { label: 'Clientes potenciales', color: '#009E9E', router: '/crm/clientes' },
    { label: 'Estadísticas', color: '#FF8000', router: '/crm/estadisticas' },
    { label: 'Alertas', color: '#C50505', router: '/crm/alertas' },
    { label: 'Acciones comerciales', color: '#11AC2F', router: '/crm/acciones-comerciales' },
    { label: 'Documentos', color: '#FF5664', router: '/crm/documentos' },

  ]
  ultimosMovimientos: Array<any> = StaticDataHandlerService.getUltimosMovimientos();

  constructor(private staticUtilitiesSE: StaticUtilitiesService) { }

  ngOnInit() {
  }

  /**
   * FUNCTIONALITIES
   */
  navigateTo(url:string){
    this.staticUtilitiesSE.goTo(url)
  }

}
