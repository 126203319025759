import { Component, OnInit } from '@angular/core';
import { iUnsubscribeDestroy, slideNavigationTypes } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { ArticleService } from 'src/app/Services/Api/Article.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'ERP-Stock-Articulos-DetailView',
  templateUrl: './ERP-Stock-Articulos-DetailView.component.html',
  styleUrls: ['./ERP-Stock-Articulos-DetailView.component.scss']
})
export class ERPStockArticulosDetailViewComponent extends iUnsubscribeDestroy implements OnInit {

  idArticulo: number = 0;
  data: any = {
    code:"Cargando...",
    id:'',
    minStock:'Cargando...',
    name:"Cargando...",
    pendingStock:'Cargando...',
    productionAmount:'Cargando...',
    productionDate:new Date(),
    realStock:'Cargando...',
    reservedStock:'Cargando...',
    virtualStock:'Cargando...',
  };

  constructor(private staticUtilitiesSE: StaticUtilitiesService, private articleSE: ArticleService) {
    super();
  }

  ngOnInit() {
    this.idArticulo = this.staticUtilitiesSE.getParams().articulos
    this.getArticleStockId()
  }

  goBack() {
    this.staticUtilitiesSE.goTo('erp/stock/', slideNavigationTypes.slideToBottom)
  }

  /**
   * API CALLS
   */

  getArticleStockId() {
    this.articleSE.getSingleStock({ num_devoluciones: 202000, num_pagina: 1 }, this.idArticulo);
    this.articleSE.getRresultSingleStock().pipe(takeUntil(this._unsubInd)).subscribe((res) => {
      if (!res) { return }
      let { data } = res
      this.data = data;
      this._unsubInd.next('')
    })
  }

}
