import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultBaseService, iResultHttp, iStaticUtilities } from '@quasar_dynamics/basic-designsystem';
import { BehaviorSubject } from 'rxjs';
import { iAccounting, iAccountingFilter } from 'src/app/Shared/Interfaces/iAccounting';

@Injectable({
  providedIn: 'root'
})
export class AccountingService extends DefaultBaseService {

  res: iResultHttp = new iResultHttp();

  protected _restultGlobalAccounting:BehaviorSubject<any> = new BehaviorSubject<any>(null)
  getResultGlobalAccounting() {return this._restultGlobalAccounting}
  clearResultGlobalAccounting() {this._restultGlobalAccounting.next(null)}
  protected _restultAccountingTableClientOrSupplier:BehaviorSubject<any> = new BehaviorSubject<any>(null)
  getResultAccountingTableClientOrSupplier() {return this._restultAccountingTableClientOrSupplier}
  clearResultAccountingTableClientOrSupplier() {this._restultAccountingTableClientOrSupplier.next(null)}

  constructor(private http: HttpClient) {
    super();
  }

  getAll(queryParams: iAccountingFilter){
    this.http.get(this.urlApi + '/api/accounting' + this.optionsGet(queryParams), this.getHeader()).subscribe({
      next: (data:any) => {
        let normalizedAccounting = iAccounting.convertFromCollection( iStaticUtilities.normalizeNamesCollection(data) );
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: normalizedAccounting});
        this.sendNextResult('result', this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultError', this.res, { method: this.getAll, args: [queryParams] });
        this.checkStatusError(error);
      },
    });
  }
  getGlobalAccounting(){
    this.http.get(this.urlApi + '/api/accounting/global', this.getHeader()).subscribe({
      next: (data:any) => {
        let normalizedAccounting = data ;
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: normalizedAccounting});
        this.sendNextObservable(this._restultGlobalAccounting, this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextObservable(this._restultGlobalAccounting, this.res, true, { method: this.getGlobalAccounting, args: [] });
        this.checkStatusError(error);
      },
    });
  }
  getAccountingTableClientOrSupplier(queryParams:any,id){
    this.http.get(this.urlApi + '/api/accounting/company/'+id +this.optionsGet(queryParams), this.getHeader()).subscribe({
      next: (data:any) => {
         
        let normalizedAccounting = data.data ;
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: {data:normalizedAccounting,total:data.total}});
        this.sendNextObservable(this._restultAccountingTableClientOrSupplier, this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextObservable(this._restultAccountingTableClientOrSupplier, this.res, true, { method: this.getAccountingTableClientOrSupplier, args: [id] });
        this.checkStatusError(error);
      },
    });
  }
  getAllAccountingClient(queryParams: iAccountingFilter){
    this.http.get(this.urlApi + '/api/accounting/client/table' + this.optionsGet(queryParams), this.getHeader()).subscribe({
      next: (data:any) => {
        let normalizedAccounting =  iStaticUtilities.normalizeNamesCollection(data.data) ;
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: {data:normalizedAccounting, total:data.total}});
        this.sendNextResult('result', this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultError', this.res, { method: this.getAllAccountingClient, args: [queryParams] });
        this.checkStatusError(error);
      },
    });
  }
  getAllAccountingSupplier(queryParams: iAccountingFilter){
    this.http.get(this.urlApi + '/api/accounting/supplier/table' + this.optionsGet(queryParams), this.getHeader()).subscribe({
      next: (data:any) => {
        let normalizedAccounting =  iStaticUtilities.normalizeNamesCollection(data.data) ;
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: {data:normalizedAccounting, total:data.total}});
        this.sendNextResult('result', this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultError', this.res, { method: this.getAllAccountingSupplier, args: [queryParams] });
        this.checkStatusError(error);
      },
    });
  }

  getSingle(id: number){
    this.http.get(this.urlApi + '/api/accounting/'+id, this.getHeader()).subscribe({
      next: (data:any) => {
        let normalizedAccounting = iAccounting.convertFromObject( iStaticUtilities.normalizeNames(data) );
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: normalizedAccounting});
        this.sendNextResult('resultIndividual', this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultIndividualError', this.res, { method: this.getSingle, args: [id] });
        this.checkStatusError(error);
      },
    });
  }

  create(accounting:iAccounting){
    this.http.post(this.urlApi+"/api/accounting", accounting, this.getHeader()).subscribe({
      next: (data:any) => {
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: data["data"]});
        this.sendNextResult('resultUpdate', this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultUpdateError', this.res, { method: this.create, args: [accounting] });
        this.checkStatusError(error);
      },
    });
  }

  update(accounting:iAccounting){
    this.http.put(this.urlApi+"/api/accounting/"+accounting.id, accounting, this.getHeader()).subscribe({
      next: (data:any) => {
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: data["data"]});
        this.sendNextResult('resultUpdate', this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultUpdateError', this.res, { method: this.update, args: [accounting] });
        this.checkStatusError(error);
      },
    });
  }

  delete(ids: Array<number|string>){
    let idsObjs = this.convertArrayObject(ids, "ids");
    this.http.delete(this.urlApi+"/api/accounting", this.sendBodyOptions(idsObjs) ).subscribe({
      next: (data:any) => {
        this.res = new iResultHttp({ status: 200, ok: true, message: "", data: data["data"]});
        this.sendNextResult('resultDelete', this.res);
      },
      error: (error:any) => {
        this.res = new iResultHttp({ status: error.status, ok: false, message: error.error.message });
        this.sendNextResult('resultDeleteError', this.res, { method: this.delete, args: [ids] });
        this.checkStatusError(error);
      },
    });
  }

}
