import { Component, OnInit } from '@angular/core';
import {
  iDataTableColumns,
  iDataTableOptions,
  iDate,
  iOptionsSelector,
  iUnsubscribeDestroy,
  PopupService,
} from '@quasar_dynamics/basic-designsystem';
import { Subject, takeUntil } from 'rxjs';
import { NuevoAsientoRawMAterialPopupComponent } from 'src/app/Popups/NuevoAsientoRawMAterial-Popup/NuevoAsientoRawMAterial-Popup.component';
import { HistoricalService } from 'src/app/Services/Api/Historical.service';
import { TypeProductService } from 'src/app/Services/Api/TypeProduct.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';
import { iHistoricalFilter } from 'src/app/Shared/Interfaces/iHistorical';

@Component({
  selector: 'erpMateriasPrimasDetallesHistorico',
  templateUrl: './ERP-MateriasPrimas-Detalles-Historico.component.html',
  styleUrls: ['./ERP-MateriasPrimas-Detalles-Historico.component.scss'],
})
export class ERPMateriasPrimasDetallesHistoricoComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  isLoading: boolean = false;
  _reloadTableWidth = new Subject();
  idProduct: number = 0;
  //Tabla
  displayedColumnsTabla: Array<iDataTableColumns> = [
    { label: 'Fecha', property: 'fecha', align: 'center' },
    { label: 'Tipo', property: 'type', align: 'center' },
    { label: 'Movimiento', property: 'movement' },
    { label: 'Notas', property: 'note' },
    { label: 'Cantidad', property: 'quantity', align: 'center', format: 'kg' },
  ];
  filter: iHistoricalFilter = {
    num_pagina: 1,
    num_devoluciones: 20,
  };
  opcionesTipos: iOptionsSelector = {
    items: [
      { id: 1, description: 'Asiento' },
      { id: 2, description: 'Compra' },
      { id: 3, description: 'Venta' },
      { id: 4, description: 'Producción' },
    ],
    clearable: true,
    search: true,
    bindLabel: 'description',
    bindValue: 'description',
    placeholder: 'Tipo',
    append: true,
  };

  dataForTable: any = [];

  optionsTabla: iDataTableOptions = {
    isHovered: true,
    canEdit: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc',
  };
  constructor(
    private historicalSE: HistoricalService,
    private staticUtilitiesSE: StaticUtilitiesService,
    private popupSE: PopupService
  ) {
    super();
  }

  ngOnInit() {
    this.getHistoricoId();
  }

  /**
   * GETTERS && SETTERS
   */

  getSelector(event, key: string) {
    if (!event) {
      delete this.filter[key];
      this.getHistoricoId();
      return;
    }
    this.filter[key] = event;
    this.getHistoricoId();
  }
  search(event) {
    if (!event) {
      delete this.filter['genericFilter'];
      this.getHistoricoId();
      return;
    }
    this.filter['genericFilter'] = event;
    this.getHistoricoId();
  }

  /**
   * FUNCTIONALITY
   */

  checkCantidad() {
    this.dataForTable.map((element, index) => {
      element.type;
      if (element.type !== 'Asiento') {
        (
          document.querySelector(
            'td.iconsActions.ng-star-inserted > div > i'
          ) as HTMLElement
        ).style.display = 'none';
      }
      if (
        element.type === 'venta' ||
        element.type === 'proveedor' ||
        (element.type === 'Asiento' && element.quantity > 0)
      ) {
        (
          document.querySelectorAll(
            ".tableBodyContainer .tableBody tbody tr td[columntable='quantity']"
          )![index] as HTMLElement
        ).classList.add('positivo');
      } else if (element.type === 'Asiento' && element.quantity < 0) {
        (
          document.querySelectorAll(
            ".tableBodyContainer .tableBody tbody tr td[columntable='quantity']"
          )![index] as HTMLElement
        ).classList.add('negativo');
      } else {
        (
          document.querySelectorAll(
            ".tableBodyContainer .tableBody tbody tr td[columntable='quantity']"
          )![index] as HTMLElement
        ).classList.add('compra');
      }
    });
  }

  newAsiento() {
    this.popupSE.openPopup(NuevoAsientoRawMAterialPopupComponent, {
      accion: 'crear',
      data: { id: this.idProduct },
    });
    this.popupSE
      .returnData()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res) => {
        if (!res) return;
        StaticUtilitiesService.showFeedback('Asiento creado correctamente');
        this.getHistoricoId();
        return;
      });
  }

  edit(event) {
    this.popupSE.openPopup(NuevoAsientoRawMAterialPopupComponent, {
      accion: 'editar',
      data: event,
    });
    this.popupSE
      .returnData()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res) => {
        if (!res) return;
        this.getHistoricoId();
        return;
      });
  }

  /**
   * API CALLS
   */

  getHistoricoId() {
    this.isLoading = true;
    this.idProduct = this.staticUtilitiesSE.getParams().materiaPrima;
    this.historicalSE.getAllProduct(this.filter, this.idProduct);
    this.historicalSE
      .getResult()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (!res) return;
        let { data, total } = res.data;
        this.dataForTable = data.map((element: any) => {
          return { ...element, fecha: element.date.toStringDate('EU') };
        });
        setTimeout(() => {
          this.checkCantidad();
        }, 1);
        setTimeout(() => {
          this._reloadTableWidth.next(true);
        }, 1);
        this.isLoading = false;
        this._unsubInd.next('');
      });
  }
}
