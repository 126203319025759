<div class="subFamilias">
  <filterActions (addNew)="create()" (searchSend)="search($event)">
    <ng-container filterContent>
      <div class="containerSelectores">
        <div>
          <selector [options]="opcionesCodigos" [(ngModel)]="codigo" (change)="getSelector(codigo, 'code')"></selector>
        </div>
        <div>
          <selector [options]="opcionesFamilias" [(ngModel)]="familia" (change)="getSelector(familia, 'family')"></selector>
        </div>
      </div>
    </ng-container>
  </filterActions>
  <Pagination [paginacionTotal]="totalPaginacion" (change)="changePagination($event)"></Pagination>
  <pill class="tablePil" classList="tablePill">
    <dataTable [data]="dataForTableFiltered" [isLoading]="isLoading" (clickRow)="see($event)" [options]="optionsTabla" [displayedColumns]="displayedColumnsTabla" (edit)="edit($event)" (see)="see($event)" (delete)="delete($event,'single')" (deleteAll)="delete($event,'all')" (deleteSelected)="delete($event,'selected')"></dataTable>
  </pill>
</div>
