import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import pdfFonts from "src/assets/Fonts/custom-fonts.js";
import * as pdfMake from "pdfmake/build/pdfmake";
import { iDate } from '@quasar_dynamics/basic-designsystem';
import { borderBottomStyle } from 'html2canvas/dist/types/css/property-descriptors/border-style';
import { style } from '@angular/animations';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;

@Injectable({
  providedIn: 'root'
})
export class GenerateBillService {

  private _pdfGenerated: BehaviorSubject<any> = new BehaviorSubject<any>(null);


  constructor() { }

  getPdf(): Observable<any> {
    return this._pdfGenerated.asObservable();
  }

  async generatePDFFactura(data) {
     
    let filteredData: any = []
    let arrayRow: any = []
    data.delivery_note_details.forEach((item, index) => {
      if (index === 0) {
        arrayRow = ['Nº Pedido', 'Referencia', 'Descripción', 'Cantidad', 'Precio', 'Importe']
        filteredData.push(arrayRow)
        arrayRow = [item.order_item.ordered.id, item.order_item.article.code, item.order_item.article.description, item.amount_sent + ' Unidad/es', item.order_item.price + '€', item.subtotal + '€']
        filteredData.push(arrayRow)
      }
      if (index > 0) {
        arrayRow = [item.order_item.ordered.id, item.order_item.article.code, item.order_item.article.description, item.amount_sent + ' Unidad/es', item.order_item.price + '€', item.subtotal + '€']
        filteredData.push(arrayRow)
      }
    })
     
    const docDefinition = {
      footer: {
        columns: [
          {
            widths: ['25%', '75%'],
            image: await this.getBase64ImageFromURL('/assets/Icons/mopasaLogoRojo.png'),
            width: 100,
            alignment: 'left', // Alinea verticalmente el contenido de la columna al centro
            fit: [25, 25],
            margin: [40, 0, 0, 0],
          },
          {
            stack: [
              { text: 'Mopasa Barnices y Pintura', style: 'textoSmallBoldFooter', margin: [0, 0, 0, 0] },
              { text: 'P.I. El Plà, Parcela 38 · Tel.: 961 240 311 · Fax: 961 230 428', style: 'textoSmallFooter', margin: [0, 0, 0, 0] },
              { text: 'Apartado de Correos 15 · 46290 Alcàsser (Valencia) España', style: 'textoSmallFooter', margin: [0, 0, 0, 0] },
              { text: 'www.mopasa.com', style: 'textoSmallFooter', margin: [0, 0, 0, 0], color: 'red' },
            ], alignment: 'left',
            margin: [-20, 0, 0, 0]
          },
          {
            text: 'PRODUCTOS QUÍMICOS MOPASA S.L., Registro Mercantil de Valencia, folio 101 Libro de Sociedades, Sección 4ª Limitadas, hojas nº 2961. Inscripción 1ª - C.I.F. B46060448',
            alignment: 'left',
            style: 'textoFooterRight',
            margin: [0, 0, 50, 0],
            width: '60%',
          }
        ]
      },
      content: [
        {
          image: await this.getBase64ImageFromURL('/assets/Icons/mopasaLogoRojoCompleto.png'),
          fit: [150, 150],
          width: 100,
          alignment: 'right',
        },
        {
          text: 'Factura',
          fontSize: 18,
          bold: true,
          margin: [0, -35, 0, 10],

        },
        {
          text:
            "Número " + data.id,
          style: "subheader"
        },
        {
          text:
            iDate.javascriptConvert(new Date(data.date)).toStringDate('EU'),
          style: "subheader"
        },
        // {
        //   canvas: [
        //     {
        //       type: 'rect', // Agregamos un rectángulo
        //       x: 0,
        //       y: 10,
        //       w: 250, // El ancho del rectángulo
        //       h: 130, // El alto del rectángulo
        //       r: 10,
        //       color: '#f1f1f0'
        //     }
        //   ]
        // },
        // {
        //   canvas: [
        //     {
        //       type: 'rect', // Agregamos un rectángulo
        //       x: 0,
        //       y: 20,
        //       w: 250, // El ancho del rectángulo
        //       h: 50, // El alto del rectángulo
        //       r: 10,
        //       color: '#f1f1f0'
        //     }
        //   ]
        // },
        // {
        //   canvas: [
        //     {
        //       type: 'rect', // Agregamos un rectángulo
        //       x: 270,
        //       y: -200,
        //       w: 250, // El ancho del rectángulo
        //       h: 130, // El alto del rectángulo
        //       r: 10,
        //       color: '#f1f1f0'
        //     }
        //   ]
        // },
        // Cliente
        {
          text: 'CLIENTE',
          style: 'subheader',
          absolutePosition: { x: 50, y: 120 }
        },
        {
          text: data.ordered[0].company.name,
          style: 'subheader',
          absolutePosition: { x: 50, y: 135 }
        },
        {
          text: data.address.street + ', ' + data.address.number + ', ' + data.address.postalCode,
          style: 'textoNormal',
          absolutePosition: { x: 50, y: 150 }
        },
        {
          text: data.address.municipality + ', ' + data.address.province + ', ' + data.address.country,
          style: 'textoNormal',
          absolutePosition: { x: 50, y: 175 }
        },
        {
          text: 'Tél: ' + data.address.phones[0],
          style: 'textoNormal',
          absolutePosition: { x: 50, y: 190 }
        },
        {
          text: 'NIF: ' + data.ordered[0].company.nif,
          style: 'textoNormal',
          absolutePosition: { x: 50, y: 210 }
        },
        // Direccion de entrega
        {
          text: 'Condiciones de pago',
          style: 'subheader',
          absolutePosition: { x: 320, y: 120 }
        },
        {
          text: data?.ordered[0]?.company?.payment_methods[0]?.way_to_pay?.description + ' - ' + data?.ordered[0]?.company?.payment_methods[0]?.way_to_pay?.days + ' días',
          style: 'textoNormal',
          absolutePosition: { x: 320, y: 135 }
        },
        {
          text: 'Domiciliación Bancaria',
          style: 'subHeader',
          absolutePosition: { x: 320, y: 150 }
        },
        {
          text: data?.ordered[0]?.company?.payment_methods[0]?.banco,
          style: 'textoNormal',
          absolutePosition: { x: 320, y: 175 }
        },
        {
          text: data?.ordered[0]?.company?.payment_methods[0]?.iban,
          style: 'textoNormal',
          absolutePosition: { x: 320, y: 190 }
        },
        {
          margin: [0, 100, 0, 0],
          layout: 'tableLayout',
          table: {
            headerRows: 1,
            fontSize: 8,
            widths: ['*', '*', '*', '*', '*', '*'],
            body:
              filteredData,
            styles: {
              fontSize: 8,
            },
          }
        },
        // {
        //   columns: [
        //     {
        //       stack: [
        //         { text: 'Base imponible', style: 'textoNormal' },
        //         { text: 'IVA (21%)', style: 'textoNormal' },
        //         { text: 'Recargo de equivalencia', style: 'textoNormal' },
        //       ],
        //       alignment: 'right',
        //       width: '85%',
        //     },
        //     {
        //       stack: [
        //         { text: data.base, style: 'textoNormal' },
        //         { text: data.iva, style: 'textoNormal' },
        //         { text: data.surcharge, style: 'textoNormal' },
        //       ],
        //       alignment: 'right',
        //       width: '15%',
        //       border: [false, false, true, true], // [left, top, right, bottom]
        //       borderColor: '#000000', // color in hex format
        //       borderStyle: 'solid' // dotted, dashed, double, groove, ridge, inset, outset, none

        //     }
        //   ],
        //   margin: [0, 20, 0, 0],        
        // },
        // {
        //   columns: [
        //     {
        //       stack: [
        //         { text: 'TOTAL FACTURA:', style: 'subheader' },
        //       ],
        //       alignment: 'right',
        //       width: '85%',
        //     },
        //     {
        //       stack: [
        //         { text: data.total, style: 'subheader' },
        //       ],
        //       alignment: 'right',
        //       width: '15%',
        //     }
        //   ],
        //   margin: [0, 5, 0, 0]
        // },
        {
          table: {
            widths: ['65%', '25%', '10%'],
            body: [
              [
                {
                  stack: [{ text: '', style: 'textoNormal' }],
                  border: [false, false, false, false], // [left, top, right, bottom]

                },

                {
                  stack: [
                    { text: 'Base imponible', style: 'textoNormal' },
                    { text: 'IVA (21%)', style: 'textoNormal' },
                    { text: 'Recargo de equivalencia', style: 'textoNormal' },
                  ],
                  border: [false, false, false, true], // [left, top, right, bottom]
                  borderColor: '#000000', // color in hex format
                  borderStyle: 'solid' // dotted, dashed, double, groove, ridge, inset, outset, none

                },
                {
                  stack: [
                    { text: data.base, style: 'textoNormal' },
                    { text: data.iva, style: 'textoNormal' },
                    { text: data.surcharge, style: 'textoNormal' },
                  ],
                  alignment: 'right',
                  border: [false, false, false, true], // [left, top, right, bottom]
                  borderColor: '#000000', // color in hex format
                  borderStyle: 'solid' // dotted, dashed, double, groove, ridge, inset, outset, none
                }
              ],
              [
                {
                  stack: [{ text: '', style: 'textoNormal' }],
                  border: [false, false, false, false], // [left, top, right, bottom]

                },
                {
                  stack: [{ text: 'TOTAL FACTURA:', style: 'subheader' },],
                  alignment: 'right',
                  border: [false, true, false, false], // [left, top, right, bottom]
                  borderColor: '#000000', // color in hex format
                  borderStyle: 'solid' // dotted, dashed, double, groove, ridge, inset, outset, none

                },
                {
                  stack: [
                    { text: data.total, style: 'subheader' },

                  ],
                  alignment: 'right',
                  border: [false, true, false, false], // [left, top, right, bottom]
                  borderColor: '#000000', // color in hex format
                  borderStyle: 'solid' // dotted, dashed, double, groove, ridge, inset, outset, none

                }
              ]
            ]
          },
          margin: [0, 20, 0, 0],
          alignment: 'right',

        },

        {
          margin: [0, 20, 0, 0],
          text: data.note,
        }
      ],
      styles: {
        header: {
          fontSize: 20,
          bold: true,
          margin: [0, 0, 0, 10]
        },
        subheader: {
          fontSize: 10,
          bold: true,
          margin: [0, 0, 0, 5]
        },
        textoNormal: {
          fontSize: 10,
          margin: [0, 0, 0, 5]
        },
        textoFooterRight: {
          fontSize: 7,
          margin: [0, 0, 0, 5]
        },
        textoGrande: {
          fontSize: 14,
          margin: [0, 0, 0, 5]
        },
        textoSmall: {
          fontSize: 6,
          margin: [0, 0, 0, 5]
        },
        textoSmallFooter: {
          fontSize: 5,
          margin: [0, 0, 0, 5]
        },
        textoSmallBold: {
          bold: true,
          fontSize: 6,
          margin: [0, 0, 0, 5]
        },
        textoSmallBoldFooter: {
          bold: true,
          fontSize: 5,
          margin: [0, 0, 0, 5]
        },
        textoMedium: {
          fontSize: 8,
          margin: [0, 0, 0, 5]
        },
        table: {
          margin: [0, 5, 0, 15]
        },
      }
    };
    pdfMake.tableLayouts = {
      tableLayout: {
        margin: [0, -60, 0, 0],
        fontSize: 10,
        fillColor: function (rowIndex, node, columnIndex) {
          return (rowIndex === 0) ? '#f1f1f0' : null;
        },
        vLineWidth: function (i, node) {
          return 1; // dibujar bordes verticales en todas las celdas
        },
        hLineWidth: function (i, node) {
          return (i === node.table.body.length || i === 0) ? 1 : 0; // dibujar bordes horizontales en todas las celdas
        },
        hLineColor: function (i, node) {
          return '#bfbfbf'; // color del borde horizontal
        },
        vLineColor: function (i, node) {
          return '#bfbfbf'; // color del borde vertical
        },
        paddingLeft: function (i, node) {
          return 10; // espacio interno de la celda izquierda
        },
        paddingRight: function (i, node) {
          return 10; // espacio interno de la celda derecha
        },
        paddingTop: function (i, node) {
          return 5; // espacio interno de la celda superior
        },
        paddingBottom: function (i, node) {
          return 5; // espacio interno de la celda inferior
        },
      }
    };
    pdfMake.createPdf(docDefinition).download("factura.pdf");
    pdfMake.createPdf(docDefinition).getBase64((data) => {
      let pdfBill = ['data:application/pdf;base64,' + data];
       
      this._pdfGenerated.next(pdfBill);
    });

  }
  private getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");

      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;

        var ctx = canvas.getContext("2d");
        ctx!.drawImage(img, 0, 0);

        var dataURL = canvas.toDataURL("image/png");

        resolve(dataURL);
      };
      img.onerror = error => {
        reject(error);
      };
      img.src = url;
    });
  }

}
