<div class="fullContainer">
  <div class="tableHeader">
    <p class="bold">Pedido</p>
    <p class="bold">Referencia</p>
    <p class="bold">Descripción</p>
    <p class="bold">Pdte.Servir</p>
    <p class="bold">Cantidad</p>
    <p class="bold">Precio</p>
    <p class="bold">Importe</p>
  </div>
  <div class="tableBody">
    <div class="tableRow" *ngFor="let linea of arrayLineas; let index = index">
      <p>{{linea.orderId}}</p>
      <p>{{linea.code}}</p>
      <p>{{linea.description}}</p>
      <p>{{linea.pending}}</p>
      <div class="cantidad">

        <CustomInput classList="fullWidth tall special" [(ngModel)]="linea.quantity" (change)="getImporte(linea,index)">
        </CustomInput>
      <p>{{linea.unit}}</p>
      </div>
        <p>{{linea.price | currency:'EUR'}}</p>
      <p>{{linea.subtotal}}</p>
      <ng-container>
        <mat-icon class="close" (click)="deleteRow(index)">close</mat-icon>
      </ng-container>
    </div>
    <div class="lineSelectOrder" *ngIf="displaySelector">
      <div class="selectorPedido">
        <div class="customContainer">
          <selector [options]="opcionesPedido" [(ngModel)]="pedido" (change)="getPedido($event)"></selector>
        </div>
      </div>

    </div>
  </div>
  <div class="accounting" *ngIf="arrayLineas.length > 0">
    <div class="line">
      <p class="bold">Base imponible :</p>
      <p>{{baseImponible | currency:'EUR'}}</p>
    </div>
    <div class="line">
      <p class="bold">IVA ({{valueIva | percent}}) :</p>
      <p>{{iva | currency:'EUR'}}</p>
    </div>
    <div class="line">
      <p class="bold">Recargo de equivalencia *:</p>
        <p>{{recargoEquivalencia | currency:'EUR'}}</p>
    </div>
    <div class="line">
      <p class="bold">TOTAL : </p>
      <p>{{total | currency:'EUR'}}</p>
    </div>
  </div>
  <div class="pesoAndNotes" *ngIf="arrayLineas.length > 0">
    <div class="line">
      <p class="bold">Peso Neto :</p>
      <p>{{peso.toFixed(2)}} kg</p>
    </div>
    <div class="line">
      <p class="bold">Notas :</p>
      <CustomTextArea  placeHolder="Notas" [(ngModel)]="note" name="notas" (change)="setDataSeccion2()"></CustomTextArea>
    </div>
  </div>
</div>