<FullContainer>
  <div class="fullView">
    <div class="headerAndArchivar">
      <div class="header">
        <button class="goBack" (click)="goBack()">
          <ng-container>
            <mat-icon>
              arrow_back
            </mat-icon>
          </ng-container>
        </button>
        <p>STOCK / <span>{{dataClean.name}}</span></p>
      </div>
    </div>

    <div class="pillContainerTopLeft">
      <pill class="minHeightPillsIconosDirecciones">
        <ng-container>
          <div class="iconsAndContent">
            <div class="icons">
              <ng-container>
                <mat-icon class="outline" (click)="editRawMaterial()">
                  edit
                </mat-icon>
              </ng-container>
            </div>
            <div class="content">
              <p><span class="bold">Código : </span> {{dataClean.code}} </p>
              <p><span class="bold">Descripción : </span> {{dataClean.name}} </p>
              <p><span class="bold">Proveedor habitual : </span> {{dataClean.regularSupplier}} </p>
            </div>
          </div>
        </ng-container>
      </pill>
    </div>

    <div class="pillTabla">
      <pill class="minHeightPills">
        <ng-container>
          <div class="pillTablaContent">
            <div class="row">
              <p class="bold fs20px">Stock Real</p>
              <p class="bold fs20px">Stock Reservado</p>
              <p class="bold fs20px">Stock Pte.Producir</p>
              <p class="bold fs20px">Stock Virtual</p>
              <p class="bold fs20px">Stock Mínimo</p>
              <p class="bold fs20px">Cantidad ult. producción</p>
              <p class="bold fs20px">Último Proveedor</p>
            </div>
            <div class="contentRow">
              <p class="bold fs20px">{{dataClean.realStock}}</p>
              <p class="red fs20px">{{dataClean.reservedStock}}</p>
              <p class=" fs20px">{{dataClean.pendingStock}}</p>
              <p class=" fs20px">{{dataClean.virtualStock}}</p>
              <p class=" fs20px">{{dataClean.minStock}}</p>
              <p class=" fs20px">{{dataClean.quantityProduct}}</p>
              <p class=" fs20px">{{dataClean.lastSupplier}} </p>
            </div>
          </div>
        </ng-container>
      </pill>
    </div>

    <div class="archivador">
      <accordion [title]="'HISTÓRICO'">
        <ng-container>
          <ERP-Stock-Materias-Historico [rawMaterialId]="rawMaterialId" class="fullWidth"
            (update)="getarticleStockId()"></ERP-Stock-Materias-Historico>
        </ng-container>
      </accordion>
    </div>


  </div>
</FullContainer>