import { iBaseObject, iDate } from "@quasar_dynamics/basic-designsystem";
import { iArticle } from "./iArticle";
import { iRawMaterial } from "./iRawMaterial";

export interface iHistorical {
    id: number,
    date?: iDate,
    type?: string,
    referenceId?: number,
    note?: string,
    quantity?: number,
    movement?: string,
    value?: number,
    valued:number,
    article?: iArticle,
    rawMaterial?: iRawMaterial,
}

export class iHistorical extends iBaseObject {
    static convertFromCollection(ob: any): Array<iHistorical> {
        let iHistoricalCollection: Array<iHistorical> = [];
        ob.forEach((element: any) => {
            iHistoricalCollection.push(this.convertFromObject(element));
        });
        return iHistoricalCollection;
    }

    static convertFromObject(ob: any): iHistorical {
        let iHistoricalObj = new iHistorical();
        iHistoricalObj.id = ob.id;
        iHistoricalObj.date = iDate.phpConvert(ob.date);
        iHistoricalObj.type = ob.type;
        iHistoricalObj.referenceId = ob.referenceId;
        iHistoricalObj.note = ob.note;
        iHistoricalObj.quantity = ob.quantity;
        iHistoricalObj.movement = ob.movement;
        iHistoricalObj.value = ob.value;
        iHistoricalObj.valued = ob.valued;
        iHistoricalObj.article = ob.article ? iArticle.convertFromObject(ob.article) : iArticle.createEmptyObject();
        iHistoricalObj.rawMaterial = ob.rawMaterial ? iRawMaterial.convertFromObject(ob.rawMaterial) : iRawMaterial.createEmptyObject();
        return iHistoricalObj;
    }

    static createEmptyObject(): iHistorical {
        let iHistoricalObj = new iHistorical();
        iHistoricalObj.date = iDate.javascriptConvert(new Date());
        iHistoricalObj.type = '';
        iHistoricalObj.referenceId = -1;
        iHistoricalObj.note = '';
        iHistoricalObj.quantity = -1;
        iHistoricalObj.movement = '';
        iHistoricalObj.valued = -1;
        iHistoricalObj.value = -1;
        iHistoricalObj.article = iArticle.createEmptyObject();
        iHistoricalObj.rawMaterial = iRawMaterial.createEmptyObject();
        return iHistoricalObj;
    }
}

export interface iHistoricalFilter {
    num_devoluciones: number;
    num_pagina: number;
    genericFilter?: string;
    date?: string,
    type?: string,
    referenceId?: number,
    note?: string,
    quantity?: number,
    movement?: string,
    value?: number,
    valued?: number,
    article?: number,
    rawMaterial?: number,
}