<div class="fullAccordion">

  <div class="pill">
    <div class="row">
      <p class="bold">Descripción</p>
      <p class="bold align-text-center">Valor</p>
    </div>
    <div class="row" *ngFor="let data of data.characteristicManies">
      <p>{{data.characteristic.description}}</p>
      <p class="align-text-center">{{data.value}}</p>
    </div>
  </div>
  
  <div class="pill">
    <div class="row">
      <p class="bold">Descripción</p>
    </div>
    <div class="row" *ngFor="let data of data.fungible">
      <p>{{data.description}}</p>
    </div>
  </div>
</div>