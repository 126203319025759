import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { iOptionsSelector } from '@quasar_dynamics/basic-designsystem';
import { StaticDataHandlerService } from 'src/app/Services/Utils/StaticDataHandler.service';


@Component({
  selector: 'app-EditarInformacionDelCliente-Popup',
  templateUrl: './EditarInformacionDelCliente-Popup.component.html',
  styleUrls: ['./EditarInformacionDelCliente-Popup.component.scss']
})
export class EditarInformacionDelClientePopupComponent implements OnInit {

  codigo: string = "";
  nif: string = "";
  direccion: string = "";
  municipio: string = "";
  cp: string = "";
  provincia: string = "";
  pais: string = "";
  url: string = "";
  isCompleted: boolean = false;
  opcionesProvincias: iOptionsSelector = {
    items: StaticDataHandlerService.getProvincias(),
    clearable: true,
    search: true,
    placeholder: "Provincia",
    append: true,
  }
  opcionesPaises: iOptionsSelector = {
    items: StaticDataHandlerService.getPaises(),
    clearable: true,
    search: true,
    placeholder: "País",
    append: true,
  }
  telefonos: any[] = [
    { telefono: "123456789", tipo: "Fijo" },
    { telefono: "123456789", tipo: "Fijo" },
    { telefono: "123456789", tipo: "Fijo" },
  ]
  emails: any[] = [
    { email:"a@a.com" },
    { email:"a@a.com" },
  ]
  emailsPedidos: any[] = [
    { email:"a@pedidos.com" },
    { email:"a@pedidos.com" },
  ]
  constructor(public dialogRef: MatDialogRef<EditarInformacionDelClientePopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any) { }

  ngOnInit() {}

  /**
  * FUNCTIONALITY
  */
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }

/**
 * VALIDATIONS
 */
checkIsCompleted(){
  this.nif && this.codigo && this.direccion && this.municipio && this.cp && this.url ? this.isCompleted = true : this.isCompleted = false
}
}
