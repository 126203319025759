import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { iDataTableColumns, iDataTableOptions, iUnsubscribeDestroy, PopupService, slideNavigationTypes } from '@quasar_dynamics/basic-designsystem';
import { ConfirmPopupComponent } from 'src/app/Popups/ConfirmPopup/ConfirmPopup.component';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';
import { takeUntil } from 'rxjs';
import { ArticleService } from 'src/app/Services/Api/Article.service';
import { NuevoRateProductoClientePopupComponent } from 'src/app/Popups/NuevoRateProductoCliente-Popup/NuevoRateProductoCliente-Popup.component';
import { RatesCustomerService } from 'src/app/Services/Api/RatesCustomer.service';

@Component({
  selector: 'ERP-Cliente-Detail-FichaDeProductos',
  templateUrl: './ERP-Cliente-Detail-FichaDeProductos.component.html',
  styleUrls: ['./ERP-Cliente-Detail-FichaDeProductos.component.scss']
})
export class ERPClienteDetailFichaDeProductosComponent extends iUnsubscribeDestroy implements OnInit {

  @Input() data: any = [];
  isLoading: boolean = false;
  clienteId: number = 0;
  surcharge = 0

  //Tabla
  displayedColumnsTabla: Array<iDataTableColumns> = [
    { label: 'Producto', property: 'product' },
    { label: 'Envase', property: 'envase', align: 'center' },
    { label: 'Coste', property: 'productionCost', align: 'center', format: 'currency' },
    { label: 'Venta', property: 'price', align: 'center', format: 'currency' },
    { label: 'Aporte', property: 'aporte', align: 'center', format: 'currency' },
    { label: 'Factor', property: 'factor', align: 'center', format:'percentaje'  },
    { label: '24 meses', property: 'cantidad', align: 'center', },
  ];

  dataForTable:any = []

  optionsTabla: iDataTableOptions = {
    isSelectable: true,
    canEdit: true,
    canSee: true,
    moreIconsHead: [
      { icon: 'download', callback: (event) => { console.log(event) } },
    ],
    canDelete: true,
    isHovered: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc'
  }

  filter:any = {
    num_devoluciones: 20,
    num_pagina:1,

  }


  constructor(private router: Router, private staticUtilitiesSE: StaticUtilitiesService, private popupSE: PopupService, private articleSE:ArticleService, private ratesCustomerSE:RatesCustomerService) {
    super();
  }

  ngOnInit() {
    this.clienteId = this.staticUtilitiesSE.getParams().cliente;
    this.getAllProducts()
  }



  /**
 * FUNCTIONALITIES
 */
  calculateData() {
    setTimeout(() => {
      this.dataForTable.map((element, index) => {
        if (element.aporte < 1.40) {
          (document.querySelectorAll(".tableBodyContainer .tableBody tbody tr td[columntable='aporte']")[index] as HTMLElement).style.color = "#ED1A23";
          return
        }
        if (element.coste < 1) {
          (document.querySelectorAll(".tableBodyContainer .tableBody tbody tr td[columntable='coste']")[index] as HTMLElement).style.color = "#ED1A23";
          return
        }
        if (element.venta < 1) {
          (document.querySelectorAll(".tableBodyContainer .tableBody tbody tr td[columntable='venta']")[index] as HTMLElement).style.color = "#ED1A23";
          return
        }
        if (element.factor > 0.65) {
          (document.querySelectorAll(".tableBodyContainer .tableBody tbody tr td[columntable='factor']")[index] as HTMLElement).style.color = "#ED1A23";
          return
        }

      })
    }, 1);
  }
  getFilter(event,key){
    if(!event){ delete this.filter[key]; this.getAllProducts(); return}
    this.filter[key] = event
    this.getAllProducts()
  }

  /**
 * Edit
 */

  edit(event) {
    this.popupSE.openPopup(NuevoRateProductoClientePopupComponent, {accion:'editar', data:event});
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
      if (!res) { return };
      this.getAllProducts()
      this._unsubInd2.next('');
    })
  }

  /**
   * Delete
   */

  delete(event: any, type: String) {
    let ids:any[] = []
    switch (type) {
      case "single":
        ids = [event.id]
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'el polvo ' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe((data) => {
          if(!data){return}
          this.deleteRates(ids)
          this._unsubInd2.next("")
        })
        break;
      case "selected":
        ids = event.map((element) => { return element.id })
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'los polvos ' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe((data) => {
          if(!data){return}
          this.deleteRates(ids)
          this._unsubInd2.next("")
        })
        break;
      case "all":
        ids = this.dataForTable.map((element) => { return element.id })
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'todas los polvos' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe((data) => {
          if(!data){return}
          this.deleteRates(ids)
          this._unsubInd2.next("")
        })
        break;
    }
  }

  /**
   * See cliente
   */

  see(event) {
    // this.staticUtilitiesSE.goTo('/erp/clientes/detalles-cliente', slideNavigationTypes.slideToTop, {cliente: event.id})

  }

  /**
   * add
   */

  create() {
    this.popupSE.openPopup(NuevoRateProductoClientePopupComponent, {accion:'crear', data:this.data});
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
      if (!res) { return };
      this.getAllProducts()
      this._unsubInd2.next('');
    })
   }

  /**
   * API CALLS
   */

  getAllProducts() {
    this.isLoading = true;
    this.articleSE.getArticlesRatesCompanyId(this.filter, this.clienteId);
    this.articleSE.getResultArticlesRatesCompanyId().pipe(takeUntil(this._unsubInd)).subscribe(res => {
      if(!res){return}
      let {data, total } = res.data;
      this.dataForTable = data.map(element => {return{...element, factor: element.factor/100}});
      this.isLoading = true;
      setTimeout(() => {
        this.calculateData();
        this.isLoading = false;
  
      }, 1000);
      this._unsubInd.next('')
    })
  }

  deleteRates(ids:number[]) {
    this.ratesCustomerSE.delete(ids);
    this.ratesCustomerSE.getResultDelete().pipe(takeUntil(this._unsubInd)).subscribe((res) => {
      if(!res){return}
      StaticUtilitiesService.showFeedback("Se ha eliminado la tarifa correctamente")
      this.getAllProducts()
      this._unsubInd.next('')
    })
    this.ratesCustomerSE.getResultDelete().pipe(takeUntil(this._unsub)).subscribe((res) => {
      if (!res) { return }
      StaticUtilitiesService.showError("No se ha podido eliminar la tarifa")
      this.getAllProducts()
      this._unsub.next("")
    })
  }

}
