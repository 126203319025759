<FullContainer>
  <div class="fullView">
    <div class="headerAndArchivar">
      <div class="header">
        <button class="goBack" (click)="goBack()">
          <ng-container>
            <mat-icon>
              arrow_back
            </mat-icon>
          </ng-container>
        </button>
        <p>CLIENTES / <span>INDUSMETAL TORRES, S.L.</span></p>
      </div>
      <div class="buttonArchivar">
        <btn classList="transformarEnCAntiguo" (click)="transformarEnCAntiguo()">Transformar en C.Antiguo</btn>
      </div>
    </div>
    <div class="archivador">
      <accordion [title]="'INFORMACION DEL CLIENTE'">
        <ng-container>
          <CAInformacionDelCliente class="fullWidth"></CAInformacionDelCliente>
        </ng-container>
      </accordion>
    </div>

    <div class="accordionInformacionComercial">
      <accordion class="" [title]="'DIRECCIONES ALTERNATIVAS'">
        <ng-container>
          <CADireccionesAlternativas class="fullWidth"></CADireccionesAlternativas>
        </ng-container>
      </accordion>
    </div>

    <div class="accordionInformacionComercial">
      <accordion class="borderTopBottom" [title]="'INFORMACION COMERCIAL'">
        <ng-container>
          <InformacionComercial class="fullWidth"></InformacionComercial>
        </ng-container>
      </accordion>
    </div>

    <div class="accordionInformacionComercial">
      <accordion class="" [title]="'CONDICIONES ESPECIALES'">
        <ng-container>
          <CACondicionesEspeciales class="fullWidth"></CACondicionesEspeciales>
        </ng-container>
      </accordion>
    </div>

    <div class="accordionInformacionComercial">
      <accordion class="" [title]="'HISTÓRICOS PEDIDOS'">
        <ng-container>
          <CAHistoricoPedidos class="fullWidth"></CAHistoricoPedidos>
        </ng-container>
      </accordion>
    </div>

  </div>
</FullContainer>