import { Component, OnInit } from '@angular/core';
import { iDataTableColumns, iDataTableOptions } from '@quasar_dynamics/basic-designsystem';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'FichaDeProducto',
  templateUrl: './FichaDeProducto.component.html',
  styleUrls: ['./FichaDeProducto.component.scss']
})
export class FichaDeProductoComponent implements OnInit {

  isLoading: boolean = false;

  //Tabla
  displayedColumnsTabla: Array<iDataTableColumns> = [
    { label: 'Artículo', property: 'articulo' },
    { label: 'Precio', property: 'precio', align:'center' },
    { label: 'Unidad', property: 'unidad', align:'center' },
    { label: '%Dtos', property: 'dtos' },
    { label: 'Fecha oferta', property: 'fecha' },
  ];

  dataForTable = [
    { id: 1, fecha: '01/05/01', articulo:'486486 - TINUVIN 1130', precio: '24,29€', unidad: 'KG', },
    { id: 2, fecha: '01/05/02', articulo:'486486 - TINUVIN 1130', precio: '24,29€', unidad: 'KG', },
    { id: 3, fecha: '01/05/03', articulo:'486486 - TINUVIN 1130', precio: '24,29€', unidad: 'KG', },
    { id: 4, fecha: '01/05/04', articulo:'486486 - TINUVIN 1130', precio: '24,29€', unidad: 'KG', },
    { id: 5, fecha: '01/05/05', articulo:'486486 - TINUVIN 1130', precio: '24,29€', unidad: 'KG', },
    { id: 6, fecha: '01/05/06', articulo:'486486 - TINUVIN 1130', precio: '24,29€', unidad: 'KG', },
    { id: 7, fecha: '01/05/07', articulo:'486486 - TINUVIN 1130', precio: '24,29€', unidad: 'KG', },
  ]

  optionsTabla: iDataTableOptions = {
    isSelectable: true,
    canEdit: true,
    canSee: true,
    moreIconsHead:[{icon:'download',callback:()=>{console.log('download')}}],
    canDelete: true,
    isHovered: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc'
  }


  constructor(private staticUtilitiesSE: StaticUtilitiesService) { }

  ngOnInit() {
    this.isLoading = true;
    setTimeout(() => {
      this.isLoading = false;
      
    }, 1000);
  }


}
