import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { iOptionsSelector, iUnsubscribeDestroy } from '@quasar_dynamics/basic-designsystem';
import { ArticleService } from 'src/app/Services/Api/Article.service';
import { HistoricalService } from 'src/app/Services/Api/Historical.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-NuevoStaockArticulos-popup',
  templateUrl: './NuevoStaockArticulos-popup.component.html',
  styleUrls: ['./NuevoStaockArticulos-popup.component.scss']
})
export class NuevoStaockArticulosPopupComponent extends iUnsubscribeDestroy implements OnInit {

  updateObject: any = {
    id: '',
  };
  opcionesArticle: iOptionsSelector = {
    items: [],
    clearable: true,
    search: true,
    placeholder: 'Articulo',
    bindLabel: 'name',
    bindValue: 'id',
    append: true,
  };

  isCompleted: boolean = false;

  data:any ={
    quantity:'',
    note:'',
    article:null,
    type:'Asiento',
  } 
  constructor(public dialogRef: MatDialogRef<NuevoStaockArticulosPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any, private historicalSE:HistoricalService, private articleSE:ArticleService) {
    super();
  }

  ngOnInit() {
     
    if (this.modalData.accion === 'editar' || this.modalData.accion === 'ver') {
      this.setter();
    }
    this.getArticleForSelector();
  }
  /**
   * GETTERS && SETTERS
   */

  setter() {
    this.data.quantity = this.modalData.data.quantity;
    this.data.note = this.modalData.data.note;
    this.updateObject.id = this.modalData.data.id;
   }
   articleSelector(event){
    if(!event){return}
    this.updateObject['article'] = event
   }


  /**
* FUNCTIONALITY
*/
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }
  editSection(event, key: string) {
    if (!event) { this.checkIsCompleted(); return }
    this.updateObject[key] = event;
    this.checkIsCompleted();
    this.update();
  }
  checkIsCompleted() {
     
    this.isCompleted = StaticUtilitiesService.isCompleteObject(this.data)
  }

  /**
 * CALLS TO API
 */

  update() {
    this.historicalSE.update(this.updateObject);
    this.historicalSE.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe((res: any) => {
      if (!res) { return }
      StaticUtilitiesService.showFeedback('Asiento actualizado correctamente')
      this._unsubInd2.next('')
    })
    this.historicalSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res: any) => {
      if (!res) { return }
      StaticUtilitiesService.showError('Error al actualizar el asiento')
      this._unsub.next('')
    })
  }

  create(){
    this.historicalSE.createAsiento(this.data,this.data.article , {isArticle:true});
    this.historicalSE.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe((res: any) => {
      if (!res) { return }
      StaticUtilitiesService.showFeedback('Asiento creado correctamente')
      this.closePopup('create')
      this._unsubInd.next('')
    })
    this.historicalSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res: any) => {
      if (!res) { return }
      StaticUtilitiesService.showError('Error al crear el asiento')
      this._unsub.next('')
    })
  }
    getArticleForSelector(){
      this.articleSE.getAll({num_devoluciones:5000, num_pagina:1});
      this.articleSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe((res: any) => {
        if(!res){return}
        let {data,total} = res.data;
         
        this.opcionesArticle.items = data;
        this._unsubInd.next('')
      })

  }

}
