import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { iDataTableColumns, iDataTableOptions, iUnsubscribeDestroy, PopupService } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { ConfirmPopupComponent } from 'src/app/Popups/ConfirmPopup/ConfirmPopup.component';
import { NuevaCaracteristicaPopupComponent } from 'src/app/Popups/NuevaCaracteristica-Popup/NuevaCaracteristica-Popup.component';
import { CharacteristicService } from 'src/app/Services/Api/Characteristic.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';
import * as XLSX from 'xlsx';


@Component({
  selector: "erpMantenimientosDetallesCaracteristicas",
  templateUrl: './ERP-Mantenimientos-Detalles-Caracteristicas.component.html',
  styleUrls: ['./ERP-Mantenimientos-Detalles-Caracteristicas.component.scss']
})
export class ERPMantenimientosDetallesCaracteristicasComponent extends iUnsubscribeDestroy implements OnInit {

  isLoading: boolean = false;
  filter: any = {
    num_devoluciones: 500,
    num_pagina: 1,
    description: "",
    note: "",
    genericFilter: "",
  }
  totalPaginacion: number = 0;

  //Tabla
  displayedColumnsTabla: Array<iDataTableColumns> = [
    { label: 'Descripción', property: 'description' },
    { label: 'Notas', property: 'note' },
  ];

  dataForTable = []

  optionsTabla: iDataTableOptions = {
    isSelectable: true,
    isHovered: true,
    canSee: true,
    canEdit: true,
    canDelete: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc',
    moreIconsHead: [{ callback: (event) => { this.exportexcel() }, icon: "download" }],
  }
  dataForTableFiltered: Array<any> = this.dataForTable;

  constructor(private router: Router, private staticUtilitiesSE: StaticUtilitiesService, private popupSE: PopupService, private CharacteristicSE: CharacteristicService) {
    super()
  }

  ngOnInit() {
    this.getAllCaracteristicas()
  }

  /**
   * GETTERS && SETTERS
   */

  search(event){
    if(!event){this.filter.genericFilter = ""; this.getAllCaracteristicas(); return}
    this.filter.genericFilter = event;
    this.getAllCaracteristicas();
  
  }

  /**
   * FUNCTIONALITIES
   */
  edit(event: any) {
    this.popupSE.openPopup(NuevaCaracteristicaPopupComponent, { data: event, accion: "editar" });
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe((res) => {
      if (!res || res['returnValue'] === 'create') { return }
      this.ngOnInit()
      this._unsubInd2.next("")
    })
  }
  create() {
    this.popupSE.openPopup(NuevaCaracteristicaPopupComponent, { data: null, accion: "crear" });
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe((res) => {
      if (!res || res['returnValue'] !== 'create') { return }
      this.ngOnInit()
      this._unsubInd2.next("")
    })
  }
  see(event: any) {
    this.popupSE.openPopup(NuevaCaracteristicaPopupComponent, { data: event, accion: "ver" });

  }
  delete(event: any, type: String) {
    let ids:any = []
    switch (type) {
      case "single":
        ids = [event.id]
        this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'la característica' });
        this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe((res) => {
          if(!res){return}
          this.deleteCharacteristic(ids)
          this._unsubInd2.next("")
        })
        break;
        case "selected":
          ids = event.map((e) => e.id)
          this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'las características seleccionadas' });
          this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe((res) => {
            if(!res){return}
            this.deleteCharacteristic(ids)
            this._unsubInd2.next("")
          })
          break;
          case "all":
            ids = this.dataForTableFiltered.map((e) => e.id)
            this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'todas las características' });
            this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe((res) => {
            if(!res){return}
            this.deleteCharacteristic(ids)
            this._unsubInd2.next("")
        })
        break;
    }
  }

  changePagination(event: any) {
    if (event === 'next') {
      this.filter.num_pagina++;
      this.getAllCaracteristicas();
    } else {
      this.filter.num_pagina--;
      this.getAllCaracteristicas();
    }
  }

    /**
   * DESCARGA DE PARTES EN EXCEL
   */

    exportexcel(): void {
      let table = document.createElement("table")
      table.append((document.querySelector("dataTable .tableHeader thead") as HTMLElement).cloneNode(true))
      table.querySelector("thead .iconsActions")?.remove();
      table.querySelector("thead .checkboxContainer")?.remove();
      let rows = Array.from(document.querySelectorAll("dataTable .tableBody tbody tr ") );
      let filteredRows = rows.filter((row) => {
        return row.querySelector('.checkboxContainer mat-checkbox ')!.getAttribute('ng-reflect-checked') === "true"
      })
      if(filteredRows.length > 0){
        filteredRows.forEach((row) => {
          let newRow = row.cloneNode(true) as HTMLElement;
          newRow.querySelector('td:last-of-type')?.remove();
          newRow.querySelector('td:first-of-type')?.remove();
          table.append(newRow)
        })
      }else{
        table.append((document.querySelector("dataTable .tableBody tbody") as HTMLElement).cloneNode(true))
        table.querySelectorAll("tbody tr td:last-of-type").forEach(element => {
          element.remove();
        })
        table.querySelectorAll("tbody tr td:first-of-type").forEach(element => {
          element.remove();
        })
      }
      /* pass here the table id */
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table);
  
      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
      /* save to file */
      XLSX.writeFile(wb, 'Características.xlsx');
  
    }

  /**
   * CALLS TO API
   */

  getAllCaracteristicas() {
    this.isLoading = true;
    this.CharacteristicSE.getAll(this.filter);
    this.CharacteristicSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe((res) => {
      if (!res) { return }
      let { data, total } = res.data;
      this.totalPaginacion = total;
      this.dataForTable = data;
      this.dataForTableFiltered = this.dataForTable;
      this.isLoading = false;
      this._unsubInd.next("")
    })
  }

  deleteCharacteristic(id: number[]) {
    this.CharacteristicSE.delete(id);
    this.CharacteristicSE.getResultDelete().pipe(takeUntil(this._unsubInd3)).subscribe((res) => {
      if (!res) { return }
      StaticUtilitiesService.showFeedback("Se ha eliminado correctamente")
      this.getAllCaracteristicas();
      this._unsubInd3.next("")
    })
    this.CharacteristicSE.getResultDeleteError().pipe(takeUntil(this._unsub)).subscribe((res) => {
      if (!res) { return }
      StaticUtilitiesService.showError("No se ha podido eliminar")
      this.getAllCaracteristicas();
      this._unsub.next("")
    })
  }
}
