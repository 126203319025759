<div class="popupGeneralContainer">
  <div class="infoSteps">
    <div class="line">
      <span class="number left" [ngClass]="{active: selectorMap === 0}">1</span>
      <span class="number medium" [ngClass]="{active: selectorMap === 1}">2</span>
      <span class="number right" [ngClass]="{active: selectorMap === 2}">3</span>
    </div>
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
  </div>
  <div class="header">
    <p>Cerrar Parte de producción</p>
  </div>
  <div class="body">
    <CierreDeFabricacionParteFormula-Popup class="width100" (FormulaParteData)="FormulaParteData($event)" [parteId]="parteId" *ngIf="selectorMap === 0"></CierreDeFabricacionParteFormula-Popup>
    <CierreDeFabricacionParteEnvases-Popup class="width100" (dataEnvases)="dataPRDetail($event)" [parteId]="parteId" *ngIf="selectorMap === 1"></CierreDeFabricacionParteEnvases-Popup>
    <CierreDeFabricacionParteCaracteristicas-Popup class="width100" (isComplete)="isCompleteCaracteristicas($event)" (dataCharacteristics)="dataCharacteristics($event)" [parteId]="parteId" *ngIf="selectorMap === 2"></CierreDeFabricacionParteCaracteristicas-Popup>
  </div>

    <!-- FOOTER -->
    <div class="footer" *ngIf="modalData.accion!='ver'">
      <btn classList="secundario" (click)="forceClosePopup()" *ngIf="selectorMap === 0">
        <ng-container>
          Cancelar
        </ng-container>
      </btn>
      <btn classList="secundario" (click)="previousSection()" *ngIf="selectorMap !== 0">
        <ng-container>
          Previo
        </ng-container>
      </btn>
      <btn classList="secundaryButton" [ngClass]="{disabled: !isCompletedFormulaDetail}" (click)="nextSection()"
        *ngIf=" selectorMap !== 2">
        <ng-container>
          Siguiente
        </ng-container>
      </btn>
      <btn classList="secundaryButton" (click)="create()" [class.disabled]="selectorMap !== 2 || !isCompletedCaracteristicas" *ngIf="selectorMap == 2">
        <ng-container>
          Cerrar Parte
        </ng-container>
      </btn>
    </div>
  
</div>