import { iBaseObject, iDate } from "@quasar_dynamics/basic-designsystem";
import { iMeasurementUnit } from "./iMeasurementUnit";
import { iFungible } from "./iFungible";
import { iSpecification } from "./iSpecification";
import { iContainer } from "./iContainer";
import { iFamily } from "./iFamily";
import { iSubfamily } from "./iSubfamily";

export interface iFormula {
    id?: number,
    code?: string,
    name?: string,
    dateFormula?: iDate,
    density?: number,
    object?: string,
    observation?: string,
        attacheds?: [],
    finalObservation?: string,
    measurementUnit?: iMeasurementUnit,
    fungible?: iFungible[],
    specification?: iSpecification,
    container?: iContainer[],
    family?: iFamily,
    subfamily?: iSubfamily,
    formulaDetails?:[],
    characteristicManies?:[],
}

export class iFormula extends iBaseObject {
    static convertFromCollection(ob: any): Array<iFormula> {
        let iFormulaCollection: Array<iFormula> = [];
        ob.forEach((element: any) => {
            iFormulaCollection.push(this.convertFromObject(element));
        });
        return iFormulaCollection;
    }

    static convertFromObject(ob: any): iFormula {
        let iFormulaObj = new iFormula();
        iFormulaObj.id = ob.id;
        iFormulaObj.code = ob.code;
        iFormulaObj.name = ob.name;
        iFormulaObj.dateFormula = iDate.phpConvert(ob.dateFormula);
        iFormulaObj.density = ob.density;
        iFormulaObj.object = ob.object;
        iFormulaObj.observation = ob.observation;
        iFormulaObj.attacheds = ob.attacheds;
        iFormulaObj.finalObservation = ob.finalObservation;
        iFormulaObj.formulaDetails = ob.formulaDetails;
        iFormulaObj.measurementUnit = ob.measurementUnit ? iMeasurementUnit.convertFromObject(ob.measurementUnit) : iMeasurementUnit.createEmptyObject();
        iFormulaObj.fungible = ob.fungible ? iFungible.convertFromCollection(ob.fungible) : [];
        iFormulaObj.specification = ob.specification ? iSpecification.convertFromObject(ob.specification) : new iSpecification();
        iFormulaObj.container = ob.container ? iContainer.convertFromCollection(ob.container) : [];
        iFormulaObj.family = ob.family ? iFamily.convertFromObject(ob.family) : iFamily.createEmptyObject();
        iFormulaObj.subfamily = ob.subfamily ? iSubfamily.convertFromObject(ob.subfamily) : iSubfamily.createEmptyObject();
        iFormulaObj.characteristicManies = ob.characteristicManies;
        return iFormulaObj;
    }

    static createEmptyObject(): iFormula {
        let iFormulaObj = new iFormula();
        iFormulaObj.code = '';
        iFormulaObj.name = '';
        iFormulaObj.dateFormula = iDate.javascriptConvert(new Date());
        iFormulaObj.density = -1;
        iFormulaObj.object = '';
        iFormulaObj.observation = '';
        iFormulaObj.attacheds = [];
        iFormulaObj.finalObservation = '';
        iFormulaObj.formulaDetails = [];
        iFormulaObj.measurementUnit = iMeasurementUnit.createEmptyObject();
        iFormulaObj.fungible = [];
        iFormulaObj.specification = new iSpecification();
        iFormulaObj.container = [];
        iFormulaObj.family = iFamily.createEmptyObject();
        iFormulaObj.subfamily = iSubfamily.createEmptyObject();
        iFormulaObj.characteristicManies = [];
        return iFormulaObj;
    }
}

export interface iFormulaFilter {
    num_devoluciones: number;
    num_pagina: number;
    genericFilter?: string;
    code?: string,
    name?: string,
    dateFormula?: string,
    density?: number,
    object?: string,
    observation?: string,
    attacheds?: number,
    finalObservation?: string,
    measurementUnit?: number,
    fungible?: number,
    specification?: number,
    container?: number,
    family?: number,
    subfamily?: number,
}