import { Component, OnInit } from '@angular/core';
import {
  iOptionsSelector,
  iUnsubscribeDestroy,
  PopupService,
} from '@quasar_dynamics/basic-designsystem';
import { NuevoClientePopupComponent } from 'src/app/Popups/NuevoCliente-Popup/NuevoCliente-Popup.component';
import { take, takeUntil } from 'rxjs';
import { NuevoClientePedidoComponentPopup } from 'src/app/Popups/NuevoClientePedido-Popup/NuevoClientePedido-Popup.component';
import { NuevaFormulaPopupComponent } from 'src/app/Popups/NuevaFormula-Popup/NuevaFormula-Popup.component';
import { NuevoParteDeProduccionPopupComponent } from 'src/app/Popups/NuevoParteDeProduccion-Popup/NuevoParteDeProduccion-Popup.component';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';
import { iProductionReportFilter } from 'src/app/Shared/Interfaces/iProductionReport';
import { SubfamilyService } from 'src/app/Services/Api/Subfamily.service';
import { FamilyService } from 'src/app/Services/Api/Family.service';

@Component({
  selector: 'ERP-PartesProduccion',
  templateUrl: './ERP-PartesProduccion.component.html',
  styleUrls: ['./ERP-PartesProduccion.component.scss'],
})
export class ERPPartesProduccionComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  selectedList: boolean = true;
  filtroFamilia: any = null;
  filtroSubfamilia: any = null;
  filtroBusqueda: any = null;
  flagRefresh: boolean = false;
  flagRefreshParte: boolean = false;
  filterProductionReport: any = {};
  selected = 0;
  downloadFlagEvent: boolean = false;

  opcionesFamilia: iOptionsSelector = {
    items: [],
    append: true,
    clearable: true,
    search: true,
    bindValue: 'id',
    bindLabel: 'description',
    placeholder: 'Familia',
  };
  opcionesSubfamilia: iOptionsSelector = {
    items: [],
    append: true,
    clearable: true,
    search: true,
    bindValue: 'id',
    bindLabel: 'description',
    placeholder: 'SubFamilia',
  };
  opcionesProductionArea: iOptionsSelector = {
    items: [
      { id: 1, label: 'Ms100' },
      { id: 2, label: 'Ms400' },
    ],
    append: true,
    clearable: true,
    search: true,
    bindValue: 'label',
    bindLabel: 'label',
    placeholder: 'Área de produccion',
  };

  selectores = [
    {
      label: 'Fórmulas',
      icon: 'none',
      image: 'none',
      selected: 0,
      before: 'yes',
      color: 'naranja',
    },
    {
      label: 'Partes de producción',
      icon: 'none',
      image: 'none',
      selected: 1,
      before: 'yes',
      color: 'naranja',
    },
  ];

  constructor(
    private popupSE: PopupService,
    private familySE: FamilyService,
    private subfamilySE: SubfamilyService
  ) {
    super();
  }

  ngOnInit() {
    this.getSubfamilias();
    this.getFamilias();
  }

  /**
   *
   * GETTERS AND SETTERS
   */

  getFilters(event, key) {
    if (key == 'familia') {
      this.filtroFamilia = event;
    }
    if (key == 'subfamilia') {
      this.filtroSubfamilia = event;
    }
    if (key == 'genericFilter') {
      this.filtroBusqueda = event;
    }
  }

  selectedOption(event) {
    this.selected = event;
  }

  selectedListToggle(event) {
    this.selectedList = event;
  }

  getFiltersProductionReport(event, key) {
    if (!event) {
      delete this.filterProductionReport[key];
      this.filterProductionReport = { ...this.filterProductionReport };
      return;
    }
    this.filterProductionReport[key] = event;
    this.filterProductionReport = { ...this.filterProductionReport };
  }

  /**
   * create
   */

  create() {
    if (this.selected == 0) {
      this.popupSE.openPopup(
        NuevaFormulaPopupComponent,
        { data: null, accion: 'crear' },
        'fullSizePopup'
      );
      this.popupSE
        .returnData()
        .pipe(takeUntil(this._unsubInd))
        .subscribe((res) => {
          if (res == null) {
            return;
          }
          this.flagRefresh = true;
          this._unsubInd.next('');
        });
    }
    if (this.selected == 1) {
      this.popupSE.openPopup(NuevoParteDeProduccionPopupComponent, {
        data: null,
        accion: 'crear',
      });
      this.popupSE
        .returnData()
        .pipe(takeUntil(this._unsubInd))
        .subscribe((res) => {
          if (res == null) {
            return;
          }
          this.flagRefreshParte = !this.flagRefreshParte;
          this._unsubInd.next('');
        });
    }
  }

  downloadFlag() {
    window.print();
  }

  /**
   * API CALLS
   */

  getFamilias() {
    this.familySE.getAll({ num_devoluciones: 200000, num_pagina: 1 });
    this.familySE
      .getResult()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        let { data, total } = res.data;
        this.opcionesFamilia.items = data.map((item) => {
          return { ...item, description: item.code + ' - ' + item.description };
        });
        this._unsubInd.next('');
      });
  }
  getSubfamilias() {
    this.subfamilySE.getAll({ num_devoluciones: 200000, num_pagina: 1 });
    this.subfamilySE
      .getResult()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        let { data, total } = res.data;
        this.opcionesSubfamilia.items = data.map((item) => {
          return { ...item, description: item.code + ' - ' + item.description };
        });
        this._unsubInd2.next('');
      });
  }
}
