import { iBaseObject } from "@quasar_dynamics/basic-designsystem";

export interface iTypesIva {
    id: number,
    description?: string,
    value?: number,
}

export class iTypesIva extends iBaseObject {
    static convertFromCollection(ob: any): Array<iTypesIva> {
        let iTypesIvaCollection: Array<iTypesIva> = [];
        ob.forEach((element: any) => {
            iTypesIvaCollection.push(this.convertFromObject(element));
        });
        return iTypesIvaCollection;
    }

    static convertFromObject(ob: any): iTypesIva {
        let iTypesIvaObj = new iTypesIva();
        iTypesIvaObj.id = ob.id;
        iTypesIvaObj.description = ob.description;
        iTypesIvaObj.value = ob.value;
        return iTypesIvaObj;
    }

    static createEmptyObject(): iTypesIva {
        let iTypesIvaObj = new iTypesIva();
        iTypesIvaObj.description = '';
        iTypesIvaObj.value = -1;
        return iTypesIvaObj;
    }
}

export interface iTypesIvaFilter {
    num_devoluciones: number;
    num_pagina: number;
    genericFilter?: string;
    description?: string,
    value?: number,
}

export interface iTypesIvaCreate {
    description?: string,
    value?: number,
}