import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  iOptionsSelector,
  iUnsubscribeDestroy,
} from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { ContainerService } from 'src/app/Services/Api/Container.service';
import { MeasurementUnitService } from 'src/app/Services/Api/MeasurementUnit.service';
import { TypeProductService } from 'src/app/Services/Api/TypeProduct.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'app-NuevoEnvase-Popup',
  templateUrl: './NuevoEnvase-Popup.component.html',
  styleUrls: ['./NuevoEnvase-Popup.component.scss'],
})
export class NuevoEnvasePopupComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  descripcion: string = '';
  notas: string = '';
  codigo: string = '';
  ocupacionKg: number | null = null;
  ocupacionL: number | null = null;
  costeEnvase: number | null = null;
  isKg: boolean = false;
  isL: boolean = false;
  isCompleted: boolean = false;
  updateObject: any = {
    id: '',
  };
  opcionesUnidadesVenta: iOptionsSelector = {
    items: [],
    clearable: true,
    bindLabel: 'unit',
    bindValue: 'id',
    search: true,
    placeholder: 'Unidades venta',
    append: true,
  };
  unidadVenta: any = null;
  opcionesTipo: iOptionsSelector = {
    items: [],
    bindLabel: 'description',
    bindValue: 'id',
    clearable: true,
    search: true,
    placeholder: 'Tipo',
    append: true,
  };
  tipo: string | null = null;

  constructor(
    public dialogRef: MatDialogRef<NuevoEnvasePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private containerSE: ContainerService,
    private measurementSE: MeasurementUnitService,
    private typeProductSE: TypeProductService
  ) {
    super();
  }

  ngOnInit() {
    if (this.modalData.accion === 'editar' || this.modalData.accion === 'ver') {
      this.setter();
      this.checkIsCompleted();
    }
    this.getUdVentas();
    this.getType();
  }

  /**
   * GETTERS && SETTERS
   */

  setter() {
    this.descripcion = this.modalData.data.description;
    this.notas = this.modalData.data.note;
    this.codigo = this.modalData.data.code;
    this.ocupacionKg = this.modalData.data.occKg;
    this.ocupacionL = this.modalData.data.occLiters;
    this.costeEnvase = this.modalData.data.cost;
    this.unidadVenta = this.modalData.data.measurementUnit.id;
    this.tipo = this.modalData.data.typeProduct.id;
    this.updateObject.id = this.modalData.data.id;
  }

  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }
  editSection(event, key: string) {
    if (!event) {
      this.checkIsCompleted();
      return;
    }
    this.updateObject[key] = event;
    this.checkIsCompleted();
    this.edit();
  }

  /**
   * VALIDATIONS
   */
  checkIsCompleted() {
    if (!this.unidadVenta) {
      this.isKg = false;
      this.isL = false;
      return;
    }
    if (this.unidadVenta === 2 || this.unidadVenta === 3) {
      this.isKg = true;
      this.isL = false;
    } else {
      this.isL = true;
      this.isKg = false;
    }

    this.codigo = this.codigo.toUpperCase();
    this.descripcion && this.codigo && this.costeEnvase && this.tipo
      ? (this.isCompleted = true)
      : (this.isCompleted = false);
  }

  /**
   * CALLS TO API
   */
  edit() {
    this.containerSE.update(this.updateObject);
    this.containerSE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        StaticUtilitiesService.showFeedback('Envase editado correctamente');
        this._unsubInd.next('');
      });
    this.containerSE
      .getResultUpdateError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        StaticUtilitiesService.showFeedback(res.message);
        this._unsub.next('');
      });
  }
  create() {
    let data: any = {
      description: this.descripcion,
      note: this.notas,
      code: this.codigo,
      occKg: this.ocupacionKg,
      occLiters: this.ocupacionL,
      cost: this.costeEnvase,
      measurementUnit: this.unidadVenta,
      typeProduct: this.tipo,
    };
    this.containerSE.create(data);
    this.containerSE
      .getResultUpdate()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        StaticUtilitiesService.showFeedback('Envase creado correctamente');
        this.closePopup('create');
        this._unsubInd.next('');
      });
    this.containerSE
      .getResultUpdateError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        StaticUtilitiesService.showError(res.error);
        this._unsub.next('');
      });
  }
  getUdVentas() {
    this.measurementSE.getAll({ num_devoluciones: 500, num_pagina: 1 });
    this.measurementSE
      .getResult()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        let { data } = res.data;
        this.opcionesUnidadesVenta.items = data;
        this._unsubInd2.next('');
      });
  }

  getType() {
    this.typeProductSE.getAll({ num_devoluciones: 500, num_pagina: 1 });
    this.typeProductSE
      .getResult()
      .pipe(takeUntil(this._unsubInd3))
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        let { data } = res.data;
        this.opcionesTipo.items = data;
        this._unsubInd3.next('');
      });
  }
}
