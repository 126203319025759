import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ClientesPotencialesPipeline',
  templateUrl: './ClientesPotencialesPipeline.component.html',
  styleUrls: ['./ClientesPotencialesPipeline.component.scss']
})
export class ClientesPotencialesPipelineComponent implements OnInit {

  isLoadingPipe: boolean = false;
  initialData: any = [];
  pipeList: any = [
    {
      title: 'Contacto web/manual',
      items: [
        { cliente: '4939 - INDUSMETAL TORRES. S.L.', id: 1, provincia: 'Valencia', contacto: 'Contacto Web' },
        { cliente: '4939 - INDUSMETAL TORRES. S.L.', id: 1, provincia: 'Valencia', contacto: 'Contacto Web' },
        { cliente: '4939 - INDUSMETAL TORRES. S.L.', id: 1, provincia: 'Valencia', contacto: 'Contacto Web' },
        { cliente: '4939 - INDUSMETAL TORRES. S.L.', id: 1, provincia: 'Valencia', contacto: 'Contacto Web' },
        { cliente: '4939 - INDUSMETAL TORRES. S.L.', id: 1, provincia: 'Valencia', contacto: 'Contacto Web' },
      ],
    },
    {
      title: 'Contacto hecho',
      items: [
        { cliente: '4939 - INDUSMETAL TORRES. S.L.', id: 1, provincia: 'Valencia', contacto: 'Contacto Web' },
        { cliente: '4939 - INDUSMETAL TORRES. S.L.', id: 1, provincia: 'Valencia', contacto: 'Contacto Web' },
        { cliente: '4939 - INDUSMETAL TORRES. S.L.', id: 1, provincia: 'Valencia', contacto: 'Contacto Web' },
        { cliente: '4939 - INDUSMETAL TORRES. S.L.', id: 1, provincia: 'Valencia', contacto: 'Contacto Web' },
        { cliente: '4939 - INDUSMETAL TORRES. S.L.', id: 1, provincia: 'Valencia', contacto: 'Contacto Web' },
      ],
    },
    {
      title: 'Reunion concertada',
      items: [
        { cliente: '4939 - INDUSMETAL TORRES. S.L.', id: 1, provincia: 'Valencia', contacto: 'Contacto Web' },
        { cliente: '4939 - INDUSMETAL TORRES. S.L.', id: 1, provincia: 'Valencia', contacto: 'Contacto Web' },
        { cliente: '4939 - INDUSMETAL TORRES. S.L.', id: 1, provincia: 'Valencia', contacto: 'Contacto Web' },
        { cliente: '4939 - INDUSMETAL TORRES. S.L.', id: 1, provincia: 'Valencia', contacto: 'Contacto Web' },
        { cliente: '4939 - INDUSMETAL TORRES. S.L.', id: 1, provincia: 'Valencia', contacto: 'Contacto Web' },
      ],
    },
    {
      title: 'Envío muestras',
      items: [
        { cliente: '4939 - INDUSMETAL TORRES. S.L.', id: 1, provincia: 'Valencia', contacto: 'Contacto Web' },
        { cliente: '4939 - INDUSMETAL TORRES. S.L.', id: 1, provincia: 'Valencia', contacto: 'Contacto Web' },
        { cliente: '4939 - INDUSMETAL TORRES. S.L.', id: 1, provincia: 'Valencia', contacto: 'Contacto Web' },
        { cliente: '4939 - INDUSMETAL TORRES. S.L.', id: 1, provincia: 'Valencia', contacto: 'Contacto Web' },
        { cliente: '4939 - INDUSMETAL TORRES. S.L.', id: 1, provincia: 'Valencia', contacto: 'Contacto Web' },
      ],
    },
    {
      title: 'Presupuesto enviado',
      items: [
        { cliente: '4939 - INDUSMETAL TORRES. S.L.', id: 1, provincia: 'Valencia', contacto: 'Contacto Web' },
        { cliente: '4939 - INDUSMETAL TORRES. S.L.', id: 1, provincia: 'Valencia', contacto: 'Contacto Web' },
        { cliente: '4939 - INDUSMETAL TORRES. S.L.', id: 1, provincia: 'Valencia', contacto: 'Contacto Web' },
        { cliente: '4939 - INDUSMETAL TORRES. S.L.', id: 1, provincia: 'Valencia', contacto: 'Contacto Web' },
        { cliente: '4939 - INDUSMETAL TORRES. S.L.', id: 1, provincia: 'Valencia', contacto: 'Contacto Web' },
      ],
    },
  ];
  loadingElements: any = [];

  constructor() { }

  ngOnInit() {
    this.falseLoader();
  }

  falseLoader(){
    this.isLoadingPipe = true;
    setTimeout(() => {
      this.isLoadingPipe = false;
    }, 1000);
  }

  clearPipeList() {
    this.initialData = []
    this.pipeList.map(e => {
      e.items = [];
    })
  }

  isLoadingElem(elem: any) {
    if (this.loadingElements.includes(elem.id)) {
      return true;
    }
    return null;
  }

  async changeItem(elem: any) {

    // let objectToPass = {
    //   id: elem.item.id,
    //   estado: elem.currentColumn.column.title
    // }

    // console.log(objectToPass)
    // this.loadingElements.push(elem.item.id);

    // this.seguimientoSe.update(objectToPass);
    // this.seguimientoSe.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe((res: any) => {
    //   if (res == null) { return }
    //   this.notificationSE.showFeedBack("Estado actualizado correctamente")
    //   this.getSeguimientoTecnicoData()
    //   this._unsubInd.next("")
    // })

  }
}
