import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { iDataTableColumns, iDataTableOptions, iOptionsSelector, iResultHttp, iUnsubscribeDestroy } from '@quasar_dynamics/basic-designsystem';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { OrderService } from 'src/app/Services/Api/Order.service';
import { takeUntil } from 'rxjs';
import { CompanyService } from 'src/app/Services/Api/Company.service';
import { ArticleService } from 'src/app/Services/Api/Article.service';

(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;



@Component({
  selector: 'NuevoClientePedidoPopup',
  templateUrl: './NuevoClientePedido-Popup.component.html',
  styleUrls: ['./NuevoClientePedido-Popup.component.scss']
})
export class NuevoClientePedidoComponentPopup extends iUnsubscribeDestroy implements OnInit {

  isCompleted: boolean = false;
  isFirstSectionCompleted: boolean = false;
  isSecondSectionCompleted: boolean = false;
  selectorMap = 0;
  isClienteSelected: boolean = false;
  notas: string | null = null;
  recargo: number | null = null;
  data: any = {
    nPedido: "",
    date: new Date().toLocaleDateString(),
    company: null,
    address: null,
    state: "Pendiente",
    porte: null,
    note: '',
    orderItem: [],
  }
  maxRisk: number = 0;
  clienteSelected: any = {
    emailPedidos: "",
    formaDePago: "",
    tipoPortes: "",
    tipoIva: 21,
    direccion: [],
    riesgoEquivalente: true,
    recargoDeEquivalencia: true,
  }
  opcionesClientes: iOptionsSelector = {
    items: [],
    clearable: true,
    search: true,
    bindLabel: 'name',
    placeholder: "Cliente",
    append: true,
  }
  opcionesDirecciones: iOptionsSelector = {
    items: [],
    clearable: true,
    search: true,
    placeholder: "Direccion",
    append: true,
  }

  /**
   *
   * Tabla
   *
  */
  isLoading: boolean = false; baseImponible: any = 0;
  plusIva: any = 0;
  recargoEquivalencia: number = 0;
  total: number = 0;
  displayedColumnsTabla: Array<iDataTableColumns> = [
    { label: 'Artículo', property: 'articulo' },
    { label: 'Descripción', property: 'descripcion', },
    { label: 'Cantidad', property: 'cantidad', align: 'center' },
    { label: 'Precio', property: 'precio', align: 'center', format: 'currency' },
    { label: 'Subtotal', property: 'subtotal', align: 'center', format: 'currency' },
  ];
  optionsTabla: iDataTableOptions = {
    isHovered: true,
    minHeight: 250,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc'
  }
  arrayLineasTabla: Array<any> = [
    { articulo: null, descripcion: '', cantidad: '', precio: null, subtotal: 0 },
  ];
  opcionesArticulos: iOptionsSelector = {
    items: [],
    clearable: true,
    search: true,
    bindLabel: 'display',
    placeholder: "Artículos",
    append: true,
  }

  constructor(public dialogRef: MatDialogRef<NuevoClientePedidoComponentPopup>, @Inject(MAT_DIALOG_DATA) public modalData: any, private orderSE: OrderService, private companySE: CompanyService, private articleSE: ArticleService) {
    super();
  }

  ngOnInit() {
    if (this.modalData.accion == 'crear') {
      this.getLastOrder()
    }
    this.getAllClientes()
  }


  /**
* FUNCTIONALITY
*/
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }
  nextSection() {
    if (this.selectorMap < 2)
      this.selectorMap++;
    if (this.selectorMap == 1) {
      this.generateTable()
      this.getAllArticulos()
    }
    if (this.selectorMap == 2) {
      setInterval(() => {
        this.getHeight()
      }, 100)
    }


  }
  previousSection() {
    if (this.selectorMap > 0)
      this.selectorMap--;
    if (this.selectorMap == 1) {
      this.generateTable()
    }
  }

  /**
   * DATE PICKER
   */

  dataFromPicker(event) {
    this.data.fechaPedido = event;
    this.checkFirstSection();
  }

  /**
* VALIDATIONS
*/
  checkIsCompleted() {
    this.isCompleted = StaticUtilitiesService.isCompleteObject(this.data);
  }
  checkFirstSection() {
    let firstSectionValidatorData: any = {
      nPedido: this.data.nPedido,
      fechaPedido: new Date().toLocaleDateString(),
      cliente: this.data.cliente,
      address: this.data.address,
    }
    this.isFirstSectionCompleted = StaticUtilitiesService.isCompleteObject(firstSectionValidatorData);
  }
  /**
   * CLIENTE SELECTOR
   */

  clienteSelector(event) {
    this.getClienteId(event.id)
    event ? this.isClienteSelected = true : this.isClienteSelected = false;
  }
  /**
   * CREATE
   */

  create() {
     
     
    this.data.note = this.notas;
    this.data.orderItem = this.arrayLineasTabla.map((element) => { return { ...element, article: element.articuloId, description: element.descripcion, quantity: element.cantidad, price: element.precio, company: this.data.company } });
    this.data.base = this.baseImponible;
    this.data.iva = this.plusIva;
    this.data.surcharge = this.recargoEquivalencia;
    this.data.total = this.total;
    this.orderSE.create(this.data);
    this.orderSE.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe((res: any) => {
      if (!res) { return }
      StaticUtilitiesService.showFeedback('Pedido creado correctamente')
      this.closePopup('create')
      this._unsubInd.next('');
    })
    this.orderSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res: any) => {
      if (!res) { return }
      StaticUtilitiesService.showError('El pedido no pudo ser creado')

      this._unsub.next('');
    })
  }
  /**
   * TABLE
   */
  getBaseImponible() {
    this.recargoEquivalencia = Number(this.recargoEquivalencia)
    this.baseImponible = 0;
    this.arrayLineasTabla.forEach(element => {
      this.baseImponible += Number(element.subtotal);
      if (this.baseImponible < 0) this.baseImponible = 0;
    });

    this.plusIva = Number(this.baseImponible) * Number(this.clienteSelected.valorIva);
    if (this.clienteSelected.recargoDeEquivalencia) {
      this.total = Number(this.baseImponible) + Number(this.plusIva) + Number(this.recargoEquivalencia) + Number(this.recargo);
      return
    };
    this.total = Number(this.baseImponible) + Number(this.plusIva) + Number(this.recargo);
  }
  generateTable() {
    this.isLoading = true;
    setTimeout(() => {
      this.getBaseImponible()
      this.isLoading = false;
    }, 1000);
  }
  newLine() {
    this.arrayLineasTabla.push({ articulo: null, descripcion: '', cantidad: '', precio: 0, subtotal: 0 });
    this.checkSecondSection()
  }
  deleteLine(index) {
    this.arrayLineasTabla.splice(index, 1);
    this.getBaseImponible()
    this.checkSecondSection()
  }
  sumSubtotal(index) {
    if (this.arrayLineasTabla[index].cantidad && this.arrayLineasTabla[index].precio) {
      this.arrayLineasTabla[index].subtotal = this.arrayLineasTabla[index].cantidad * this.arrayLineasTabla[index].precio;
      this.getBaseImponible()
    }
  }
  getArticulo(event, index) {
     
    if (!event) {
      this.arrayLineasTabla[index].articulo = '';
      this.arrayLineasTabla[index].descripcion = '';
      this.arrayLineasTabla[index].cantidad = '';
      this.arrayLineasTabla[index].precio = 0;
      this.arrayLineasTabla[index].subtotal = 0;
      this.getBaseImponible()
      this.checkSecondSection()
      return;
    };
    // this.getArticulosById(event.id, index)
    this.getArticleByIdAndClientId(event.id,this.data.company,index)
  }
  checkSecondSection() {
    let validator = this.arrayLineasTabla.filter(element => {
      if (!element.articulo && !element.descripcion) {
        return element;
      }
    })
    if (validator.length == 0) {
      this.isSecondSectionCompleted = true;
    } else {
      this.isSecondSectionCompleted = false;
    }
  }

  getHeight() {
    if (this.selectorMap === 1) {
      (document.querySelector("#mat-dialog-0 > nuevoclientepedidopopup > div > div.tablaPedido.ng-star-inserted > div > div.secondPill > pill > div") as HTMLElement).style.height = (document.querySelector("#mat-dialog-0 > nuevoclientepedidopopup > div > div.tablaPedido.ng-star-inserted > div > div.firstPill > pill > div") as HTMLElement).clientHeight + 'px';
    }
  }

  /**
   * API CALLS
   */
  getLastOrder() {
    this.orderSE.getLastOrder();
    this.orderSE.getResultLastOrder().pipe(takeUntil(this._unsubInd)).subscribe((res: any) => {
      if (!res) { return }
      this.data.nPedido = res.data.code + 1;
      this.data.code = res.data.code + 1;
      this._unsubInd.next('')
    })
  }
  getAllClientes() {
    this.companySE.getClientOrProveedor({ num_devoluciones: 2000, num_pagina: 1, isClient: true, isActive: true });
    this.companySE.getResult().pipe(takeUntil(this._unsubInd2)).subscribe((res: any) => {
      if (!res) { return }
      let { data, total } = res.data;
      this.opcionesClientes.items = data;
      this._unsubInd2.next('')
    })
  }
  getClienteId(id) {
    this.companySE.getSingle(id);
    this.companySE.getResultIndividual().pipe(takeUntil(this._unsubInd)).subscribe((res: any) => {
      if (!res) { return }
      let { data } = res;
      this.clienteSelected.direccion = [];
       
      this.clienteSelected.nombre = data?.name;
      this.clienteSelected.emailPedidos = data?.orderEmails[0];
      this.clienteSelected.formaDePago = data?.paymentMethods[0]?.wayToPay?.description
      this.clienteSelected.tipoIva = data?.paymentMethods[0]?.typeIva?.description
      this.clienteSelected.valorIva = data?.paymentMethods[0]?.typeIva?.value
      this.clienteSelected.tipoPortes = data?.porte?.name;
      this.clienteSelected.recargoDeEquivalencia = data?.paymentMethods[0]?.surcharge;
      this.data.company = data?.id;
      this.data.porte = data?.porte?.id
      data?.companyAddress.forEach((data, index) => {
        this.clienteSelected.direccion.push({ ...data, label: data.street })
      });
      this.opcionesDirecciones.items = this.clienteSelected.direccion;
      this.data.address = this.clienteSelected.direccion[0];
      this.maxRisk = data.paymentMethods[0]?.maxRisk;
      this.checkFirstSection()
       
      this._unsubInd.next('')
    })
  }
  getAllArticulos() {
    this.articleSE.getAll({num_devoluciones:5000000, num_pagina:1});
    this.articleSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe((res: any) => {
      if (!res) { return }
      let { data, total } = res.data;
       data.map(article => {
        let display = article.code + ' - ' + article.name;
        article.display = display;
       })
      this.opcionesArticulos.items = data;
      this._unsubInd.next('')
    })
  }

  getArticulosById(id, index) {
    this.articleSE.getSingle(id);
    this.articleSE.getResultIndividual().pipe(takeUntil(this._unsubInd)).subscribe((res: any) => {
      if (!res) { return }
      let { data } = res;
      this.arrayLineasTabla[index].articulo = data.name;
      this.arrayLineasTabla[index].codigo = data.code;
      this.arrayLineasTabla[index].articuloId = data.id;
      this.arrayLineasTabla[index].descripcion = data.description;
      this.arrayLineasTabla[index].precio = data.productionCost;
      this.sumSubtotal(index)
      this.checkSecondSection()
        this._unsubInd.next('')
    })
  }

  getArticleByIdAndClientId(articleId,clientId,index){
    this.articleSE.getArticleByIdAndClientId(articleId,clientId)
    this.articleSE.getResultIndividual().pipe(takeUntil(this._unsubInd)).subscribe((res: any) => {
      if (!res) { return }
      let { data } = res;
      this.arrayLineasTabla[index].articulo = data.name;
      this.arrayLineasTabla[index].codigo = data.code;
      this.arrayLineasTabla[index].articuloId = data.id;
      this.arrayLineasTabla[index].descripcion = data.description;
      this.arrayLineasTabla[index].precio = data.price;
      this.sumSubtotal(index)
      this.checkSecondSection()
      this._unsubInd.next('')
    })
    this.articleSE.getResultIndividualError().pipe(takeUntil(this._unsubInd)).subscribe((res: iResultHttp) => {
      if (!res) { return }
      this.arrayLineasTabla[index].articulo = '';
      this.arrayLineasTabla[index].codigo = '';
      this.arrayLineasTabla[index].descripcion = '';
      this.arrayLineasTabla[index].precio = 0;
      this.arrayLineasTabla[index].cantidad = '';
      StaticUtilitiesService.showError(String(res.message))
        this._unsubInd.next('')
    })
  }



}
