import { Component, OnInit } from '@angular/core';
import { iUnsubscribeDestroy, PopupService, slideNavigationTypes } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { FormulaService } from 'src/app/Services/Api/Formula.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'ERP-PP-Formulas-DV',
  templateUrl: './ERP-PP-Formulas-DV.component.html',
  styleUrls: ['./ERP-PP-Formulas-DV.component.scss']
})
export class ERPPPFormulasDVComponent extends iUnsubscribeDestroy implements OnInit {

  formulaId!:number
  openAccordeon:boolean = false
  data:any = []

  constructor( private staticUtilities:StaticUtilitiesService, private popupSE:PopupService, private formulaSE:FormulaService ) {
    super();
  }

  ngOnInit() {
    this.getParams()
    this.getFormulaById()
  }
  
  getParams(){
    if(!this.staticUtilities.getParams().formula || this.staticUtilities.getParams().formula <= 0){
      StaticUtilitiesService.showError('No se ha encontrado la fórmula')
      this.staticUtilities.goTo('erp/partesproduccion/', slideNavigationTypes.slideToBottom)
      return;
    }
    this.formulaId = this.staticUtilities.getParams().formula
  }
  
  goBack(){
    this.staticUtilities.goTo('erp/partesproduccion/', slideNavigationTypes.slideToBottom)
  }
  reload(event){
    this.getFormulaById()
  }

  /**
   * API CALLS
   */

  getFormulaById(){
    this.formulaSE.getSingle(this.formulaId);
    this.formulaSE.getResultIndividual().pipe(takeUntil(this._unsubInd)).subscribe((res:any)=>{
      if(res==null){return}
      let {data} = res;
      this.data = data
      this._unsubInd.next('')
    })
  }
}