import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { iUnsubscribeDestroy } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { CharacteristicService } from 'src/app/Services/Api/Characteristic.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'app-NuevaCaracteristica-Popup',
  templateUrl: './NuevaCaracteristica-Popup.component.html',
  styleUrls: ['./NuevaCaracteristica-Popup.component.scss']
})
export class NuevaCaracteristicaPopupComponent extends iUnsubscribeDestroy implements OnInit {

  descripcion: string = "";
  notas: string = ""
  isCompleted: boolean = false;
  editObject: any = {
    id: '',
  }
  constructor(public dialogRef: MatDialogRef<NuevaCaracteristicaPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any, private characteristicSE:CharacteristicService) {
    super();
  }

  ngOnInit() {
    this.setter()
  }

  /**
  * FUNCTIONALITY
  */
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }
  editInput(event,key){
    this.editObject[key] = event.target.value;
    this.checkIsCompleted()
    this.edit()
  }

  /**
   * GETTERS && SETTERS
   */

  setter() {
    this.descripcion = this.modalData.data.description;
    this.notas = this.modalData.data.note;
    this.editObject.id = this.modalData.data.id;
    this.checkIsCompleted()
  }

  /**
   * VALIDATIONS
   */
  checkIsCompleted() {
    this.descripcion && this.notas ? this.isCompleted = true : this.isCompleted = false
  }

  /**
   * API CALLS
   */

  edit(){
    this.characteristicSE.update(this.editObject);
    this.characteristicSE.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe((res:any)=>{
      if(res==null){return}
      StaticUtilitiesService.showFeedback('Característica editada correctamente');
      this._unsubInd.next('');
    })
    this.characteristicSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res:any)=>{
      if(res==null){return}
      StaticUtilitiesService.showError(res.error.message);
      this._unsub.next('');
    })
  }

  create(){
    this.characteristicSE.create({description:this.descripcion,note:this.notas});
    this.characteristicSE.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe((res:any)=>{
      if(res==null){return}
      StaticUtilitiesService.showFeedback('Característica creada correctamente');
      this.closePopup('create');
      this._unsubInd.next('');
    })
    this.characteristicSE.getResultUpdateError().pipe(takeUntil(this._unsub)).subscribe((res:any)=>{
      if(res==null){return}
      StaticUtilitiesService.showError(res.error.message);
      this._unsub.next('');
    })
  }
}
