import { Component, OnInit } from '@angular/core';
import { iUnsubscribeDestroy, PopupService, slideNavigationTypes } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { ConfirmPopupComponent } from 'src/app/Popups/ConfirmPopup/ConfirmPopup.component';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'app-ClientesDetailView',
  templateUrl: './ClientesDetailView.component.html',
  styleUrls: ['./ClientesDetailView.component.scss']
})
export class ClientesDetailViewComponent extends iUnsubscribeDestroy implements OnInit {
  



  constructor( private staticUtilitiesSE:StaticUtilitiesService, private popupSE:PopupService) {
    super();
  }

  ngOnInit() {
  }

  goBack(){
    this.staticUtilitiesSE.goTo('crm/clientes/', slideNavigationTypes.slideToBottom)
  }

  transformarEnCAntiguo(){
    this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Transformar', elemento: 'el cliente en Cliente Antiguo', crm: true});
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd)).subscribe(res=>{
      if(!res) {return};
      this._unsubInd.next('');
    })
  }

}
