import { iBaseObject } from "@quasar_dynamics/basic-designsystem";

export interface iProductionArea {
    id: number,
    name?: string,
}

export class iProductionArea extends iBaseObject {
    static convertFromCollection(ob: any): Array<iProductionArea> {
        let iProductionAreaCollection: Array<iProductionArea> = [];
        ob.forEach((element: any) => {
            iProductionAreaCollection.push(this.convertFromObject(element));
        });
        return iProductionAreaCollection;
    }

    static convertFromObject(ob: any): iProductionArea {
        let iProductionAreaObj = new iProductionArea();
        iProductionAreaObj.id = ob.id;
        iProductionAreaObj.name = ob.name;
        return iProductionAreaObj;
    }

    static createEmptyObject(): iProductionArea {
        let iProductionAreaObj = new iProductionArea();
        iProductionAreaObj.name = '';
        return iProductionAreaObj;
    }
}

export interface iProductionAreaFilter {
    num_devoluciones: number;
    num_pagina: number;
    genericFilter?: string;
    name?: string,
}