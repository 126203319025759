<div class="popupGeneralContainer">
  <div class="header">
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
    <p>Subir documento</p>
    <mat-icon (click)="forceClosePopup()">close</mat-icon>
  </div>
  <div class="body">
    <div>
      <button [multiple]="true" fileInput [accept]="modalData.formatos"
      [(ngModel)]="document" (change)="getDocument()"></button>
      <mat-icon>file_upload</mat-icon>
      <span>Subir documento</span>
    </div>
    <main class="namesIcon">
      <span class="line" *ngFor="let name of names">
        <ng-container *ngIf="document"> <mat-icon class="green">check_circle</mat-icon> </ng-container>
        <p >{{name}}</p>

      </span>
    </main>
  </div>
  <div class="footer">
    <btn classList="secundario" (click)="forceClosePopup()">
      <ng-container>
       Cancelar
      </ng-container>
    </btn>
    <btn classList="secundaryButton" (click)="closePopup(document)" [class.disabled]="!isCompleted">
      <ng-container>
        Subir documento
      </ng-container>
    </btn>
  </div>
</div>
