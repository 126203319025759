import { Component, OnInit } from '@angular/core';
import { iUnsubscribeDestroy, slideNavigationTypes } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { CompanyService } from 'src/app/Services/Api/Company.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'app-ERP-Proveedores-Detalles',
  templateUrl: './ERP-Proveedores-Detalles.component.html',
  styleUrls: ['./ERP-Proveedores-Detalles.component.scss']
})
export class ERPProveedoresDetallesComponent extends iUnsubscribeDestroy implements OnInit {
  idProveedor: number  = 0;
  isLoading: boolean = false;
  proveedor:string = 'Cargando'
  dataProveedor:any = [];
  constructor(private staticUtilities: StaticUtilitiesService, private companySE:CompanyService) {
    super();
  }
  ngOnInit() {
    if (this.staticUtilities.getParams().proveedor == undefined) {
      this.staticUtilities.goTo("/erp/proveedores", slideNavigationTypes.slideToBottom);
      StaticUtilitiesService.showError("No se ha encontrado el proveedor");
    } else {
      this.idProveedor = Number(this.staticUtilities.getParams().proveedor);
      this.getProveedorId();
    }
  }
  /**
   * FUNCTIONALITIES
   */
  returnBack() {
    localStorage.removeItem("mantenimiento")
    this.staticUtilities.goTo("/erp/proveedores", slideNavigationTypes.slideToBottom)
  }

  refreshData(event){
    if(event === 'refresh'){
      this.getProveedorId();
    }
  }
  /**
   * API CALLS
   */

  getProveedorId(){
    this.companySE.getSingle(this.idProveedor);
    this.companySE.getResultIndividual().pipe(takeUntil(this._unsubInd)).subscribe((res)=>{
      if(!res){return}
      let {data} = res;
      this.proveedor = data.name;
      this.dataProveedor = data;
      this._unsubInd.next('')
    })
  }

}
