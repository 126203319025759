import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  PopupService,
  iUnsubscribeDestroy,
} from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { NuevaFormulaPopupComponent } from 'src/app/Popups/NuevaFormula-Popup/NuevaFormula-Popup.component';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'ERP-PP-Formulas-DV-Formula',
  templateUrl: './ERP-PP-Formulas-DV-Formula.component.html',
  styleUrls: ['./ERP-PP-Formulas-DV-Formula.component.scss'],
})
export class ERPPPFormulasDVFormulaComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  @Output() reload = new EventEmitter();
  @Input() data: any = [];
  lines: any = [];

  total: number = 0;
  totalKg: number = 0;
  totalLitros: number = 0;
  dataForTable: any = [];

  constructor(private popupSE: PopupService) {
    super();
  }

  ngOnInit() {
    this.getTotal();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data']) {
      if (Object.keys(changes['data'].currentValue).length > 0) {
        this.lines = changes['data'].currentValue.formulaDetails;
        this.getTotal();
      }
    }
  }

  getTotal() {
    this.lines.forEach((element) => {
      this.total += Number(element.quantity);
      if (element.rawMaterial) {
        this.totalKg +=
          Number(element.quantity) * Number(element.rawMaterial.lastPrice);
        this.totalLitros +=
          (Number(element.quantity) * Number(element.rawMaterial.lastPrice)) /
          Number(element.rawMaterial.density);
      }
      if (element.article) {
        this.totalKg +=
          Number(element.quantity) * Number(element.article.productionCost);
        this.totalLitros +=
          (Number(element.quantity) * Number(element.article.productionCost)) /
          Number(element.article.formula.density);
      }
    });
  }

  /**
   * EDIT
   */

  edit() {
    this.popupSE.openPopup(
      NuevaFormulaPopupComponent,
      { data: this.data, accion: 'editar' },
      'fullSizePopup'
    );
    this.popupSE
      .returnData()
      .pipe(takeUntil(this._unsubInd3))
      .subscribe((data) => {
        this.reload.emit(true);
        this._unsubInd3.next('');
      });
  }
}
