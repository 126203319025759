import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { iUnsubscribeDestroy } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { ProductionReportService } from 'src/app/Services/Api/ProductionReport.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'CierreDeFabricacionParteFormula-Popup',
  templateUrl: './CierreDeFabricacionParteFormula-Popup.component.html',
  styleUrls: ['./CierreDeFabricacionParteFormula-Popup.component.scss']
})
export class CierreDeFabricacionParteFormulaPopupComponent extends iUnsubscribeDestroy implements OnInit {

  @Input() parteId!: number;
  @Output() FormulaParteData = new EventEmitter<any>();
  dataForTable: any = []
  total: number = 0;
  observacionesFinales: string = `Cargando...  `
  isCompleted: boolean = false;


  constructor(private productionReportSE: ProductionReportService) {
    super();
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['parteId']) {
      if (changes['parteId'].currentValue) {
        this.getFormulaProductionReport()
      }
    }
  }

  /**
   * FUNCTIONALITY
   */

  checkIsComplete() {
    let zero = this.dataForTable.filter((item: any) => item.batchAmount <= 0);
    if (zero.length > 0 || this.total <= 0) {
      this.isCompleted = false;
    } else {
      this.isCompleted = true;
      this.FormulaParteData.emit({
        realAmount: this.total,
        batch: this.dataForTable.map((item: any) => { return { formulaDetail: item.formulaDetailId, amount: item.batchAmount } }),
      })
    }
  }

  /**
   * API CALLS
   */

  getFormulaProductionReport() {
    this.productionReportSE.getFormulaTableId(this.parteId);
    this.productionReportSE.getResultFormulaTableId().pipe(takeUntil(this._unsubInd)).subscribe((res: any) => {
      if (!res) { return }
      let { data } = res;
      this.total = data.formula[0].ProductionReportTotal;
      this.observacionesFinales = data.formula[0].finalObservation;
      this.dataForTable = data.batchLine.filter((item: any) => !item.indication);
      this.checkIsComplete()
    })
  }
}
