import { iBaseObject } from "@quasar_dynamics/basic-designsystem";
import { iRatesCustomer } from "./iRatesCustomer";
import { iAddress } from "./iAddress";
import { iAgent } from "./iAgent";
import { iAccounting } from "./iAccounting";
import { iPaymentMethod } from "./iPaymentMethod";

export interface iCompany {
    id?: number,
    code?: string,
    nif?: string,
    name?: string,
    isActive?: boolean,
    phones?: any,
    emails?: any,
    orderEmails?: any,
    observation?: string,
    occupation?: string,
    sector?: string,
    managerPurchase?: string,
    isClient?: boolean,
    ratesCustomer?: iRatesCustomer,
    companyAddress?: iAddress[],
    billingAddress?: iAddress,
    agents?: iAgent[],
    accounting?: iAccounting,
}

export class iCompany extends iBaseObject {
    static convertFromCollection(ob: any): Array<iCompany> {
        let iCompanyCollection: Array<iCompany> = [];
         
        ob.forEach((element: any) => {
            iCompanyCollection.push(this.convertFromObject(element));
        });
        return iCompanyCollection;
    }

    static convertFromObject(ob: any): iCompany {
        let iCompanyObj = new iCompany();
        iCompanyObj.id = ob.id;
        iCompanyObj.code = ob.code;
        iCompanyObj.nif = ob.nif;
        iCompanyObj.name = ob.name;
        iCompanyObj.isActive = ob.isActive;
        iCompanyObj.phones = ob.phones;
        iCompanyObj.emails = ob.emails;
        iCompanyObj.orderEmails = ob.orderEmails;
        iCompanyObj.observation = ob.observation;
        iCompanyObj.occupation = ob.occupation;
        iCompanyObj.sector = ob.sector;
        iCompanyObj.managerPurchase = ob.managerPurchase;
        iCompanyObj.isClient = ob.isClient;
        iCompanyObj.ratesCustomer = ob.ratesCustomer ? iRatesCustomer.convertFromObject(ob.ratesCustomer) : new iRatesCustomer();
        iCompanyObj.companyAddress = ob.companyAddress ? iAddress.convertFromCollection(ob.companyAddress) : [];
        iCompanyObj.billingAddress = ob.billingAddress ? iAddress.convertFromObject(ob.billingAddress) : new iAddress();
        iCompanyObj.agents = ob.agents ? iAgent.convertFromCollection(ob.agents) : [];
        iCompanyObj.accounting = ob.accounting ? iAccounting.convertFromObject(ob.accounting) : iAccounting.createEmptyObject();
        return iCompanyObj;
    }

    static createEmptyObject(): iCompany {
        let iCompanyObj = new iCompany();
        iCompanyObj.code = '';
        iCompanyObj.nif = '';
        iCompanyObj.name = '';
        iCompanyObj.isActive = false;
        iCompanyObj.phones = null;
        iCompanyObj.emails = null;
        iCompanyObj.orderEmails = null;
        iCompanyObj.observation = '';
        iCompanyObj.occupation = '';
        iCompanyObj.sector = '';
        iCompanyObj.managerPurchase = '';
        iCompanyObj.isClient = false;
        iCompanyObj.ratesCustomer = new iRatesCustomer();
        iCompanyObj.companyAddress = new Array<iAddress>();
        iCompanyObj.billingAddress = new iAddress();
        iCompanyObj.agents = new Array<iAgent>();
        iCompanyObj.accounting = new iAccounting();
        return iCompanyObj;
    }
}

export interface iCompanyFilter {
    num_devoluciones: number;
    num_pagina: number;
    genericFilter?: string;
    code?: string,
    nif?: string,
    name?: string,
    isActive?: boolean,
    phones?: any,
    emails?: any,
    orderEmails?: any,
    observation?: string,
    occupation?: string,
    sector?: string,
    managerPurchase?: string,
    isClient?: boolean,
    ratesCustomer?: number,
    companyAddress?: number,
    billingAddress?: number,
    agents?: number,
    accounting?: number,
}