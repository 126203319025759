import { Component, OnInit } from '@angular/core';
import {
  iDataTableColumns,
  iDataTableOptions,
  iOptionsSelector,
  iUnsubscribeDestroy,
  PopupService,
  slideNavigationTypes,
} from '@quasar_dynamics/basic-designsystem';
import { Subject, takeUntil } from 'rxjs';
import { ConfirmPopupComponent } from 'src/app/Popups/ConfirmPopup/ConfirmPopup.component';
import { CrearProveedorPopupComponent } from 'src/app/Popups/CrearProveedor-Popup/CrearProveedor-Popup.component';
import { CompanyService } from 'src/app/Services/Api/Company.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';
import { iCompanyFilter } from 'src/app/Shared/Interfaces/iCompany';
import * as XLSX from 'xlsx';

@Component({
  selector: 'erpProveedores',
  templateUrl: './ERP-Proveedores-Proveedores.component.html',
  styleUrls: ['./ERP-Proveedores-Proveedores.component.scss'],
})
export class ERPProveedoresProveedoresComponent
  extends iUnsubscribeDestroy
  implements OnInit
{
  _reloadTableWidth = new Subject();
  isLoading: boolean = false;

  //Tabla
  displayedColumnsTabla: Array<iDataTableColumns> = [
    { label: 'Código', property: 'code' },
    { label: 'Nombre', property: 'name' },
    { label: 'NIF', property: 'nif' },
  ];

  dataForTable: any = [
    { code: '23', name: 'INTERPUR CHEMICALS, S.L.U.', nif: 'B01991272' },
    { code: '45', name: 'MINERALES ROSET, S.L.', nif: 'B61250437' },
    { code: '632', name: 'MAPRYSER, S.L.', nif: 'B60541653' },
    { code: '543', name: 'QUIMICA EUROPA DE RESINAS, S.', nif: 'A01225481' },
    { code: '21', name: 'EASTERN CHEMICALS, S.A.', nif: 'A60716537' },
    { code: '54', name: 'IRISEM, S.L.', nif: 'B96001193' },
    { code: '765', name: 'BRUGUÉS', nif: 'A08041766' },
    { code: '26', name: 'VALRESA COATINGS, S.A.', nif: 'A46030169' },
    { code: '12', name: 'KADION ESPECIALIDAD QUIMICAS, S.', nif: 'B61502001' },
    { code: '6', name: 'OQEMA IBERIA, S.A.', nif: 'A08199168' },
  ];

  optionsTabla: iDataTableOptions = {
    isSelectable: true,
    isHovered: true,
    canEdit: true,
    canDelete: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc',
    moreIconsHead: [
      {
        callback: (event) => {
          this.exportexcel();
        },
        icon: 'download',
      },
    ],
  };
  dataForTableFiltered: Array<any> = this.dataForTable;
  filter: iCompanyFilter = {
    num_devoluciones: 20,
    num_pagina: 1,
    isClient: false,
  };
  totalPaginacion: number = 10;

  constructor(
    private popupSE: PopupService,
    private staticUtilities: StaticUtilitiesService,
    private companySE: CompanyService
  ) {
    super();
  }

  ngOnInit() {
    this.getAllProveedores();
  }

  /**
   * FUNCTIONALITIES
   */
  clickRow(event) {
    this.staticUtilities.goTo(
      '/erp/proveedores/detalles-proveedor',
      slideNavigationTypes.slideToTop,
      { proveedor: event.id }
    );
  }
  create() {
    this.popupSE.openPopup(CrearProveedorPopupComponent, { accion: 'crear' });
    this.popupSE
      .returnData()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((data) => {
        if (!data) {
          return;
        }
        this.getAllProveedores();
        this._unsubInd2.next('');
      });
  }
  edit(event: any) {
    this.popupSE.openPopup(CrearProveedorPopupComponent, {
      accion: 'editar',
      data: event,
    });
    this.popupSE
      .returnData()
      .pipe(takeUntil(this._unsubInd2))
      .subscribe((data) => {
        if (!data) {
          return;
        }
        this.getAllProveedores();
        this._unsubInd2.next('');
      });
  }

  delete(event: any, type: String) {
    let ids: number[] = [];
    switch (type) {
      case 'single':
        ids = [event.id];
        this.popupSE.openPopup(ConfirmPopupComponent, {
          accion: 'Eliminar',
          elemento: 'el proveedor',
        });
        this.popupSE
          .returnData()
          .pipe(takeUntil(this._unsubInd2))
          .subscribe((data) => {
            if (!data) {
              return;
            }
            this.deleteProveedor(ids);
            this._unsubInd2.next('');
          });
        break;
      case 'selected':
        ids = event.map((element) => {
          return element.id;
        });
        this.popupSE.openPopup(ConfirmPopupComponent, {
          accion: 'Eliminar',
          elemento: 'los proveedores seleccionadas',
        });
        this.popupSE
          .returnData()
          .pipe(takeUntil(this._unsubInd2))
          .subscribe((data) => {
            if (!data) {
              return;
            }
            this.deleteProveedor(ids);
            this._unsubInd2.next('');
          });
        break;
      case 'all':
        ids = this.dataForTable.map((element) => {
          return element.id;
        });
        this.popupSE.openPopup(ConfirmPopupComponent, {
          accion: 'Eliminar',
          elemento: 'todas los proveedores',
        });
        this.popupSE
          .returnData()
          .pipe(takeUntil(this._unsubInd2))
          .subscribe((data) => {
            if (!data) {
              return;
            }
            this.deleteProveedor(ids);
            this._unsubInd2.next('');
          });
        break;
    }
  }
  changePagination(event: any) {
    if (event === 'next') {
      this.filter.num_pagina++;
      this.getAllProveedores();
    } else {
      this.filter.num_pagina--;
      this.getAllProveedores();
    }
  }
  search(event: any) {
    if (!event) {
      delete this.filter.genericFilter;
      this.getAllProveedores();
      return;
    }
    this.filter.genericFilter = event;
    this.getAllProveedores();
  }

  /**
   * DESCARGA DE PARTES EN EXCEL
   */

  exportexcel(): void {
    let table = document.createElement('table');
    table.append(
      (
        document.querySelector('dataTable .tableHeader thead') as HTMLElement
      ).cloneNode(true)
    );
    table.querySelector('thead .iconsActions')?.remove();
    table.querySelector('thead .checkboxContainer')?.remove();
    let rows = Array.from(
      document.querySelectorAll('dataTable .tableBody tbody tr ')
    );
    let filteredRows = rows.filter((row) => {
      return (
        row
          .querySelector('.checkboxContainer mat-checkbox ')!
          .getAttribute('ng-reflect-checked') === 'true'
      );
    });
    if (filteredRows.length > 0) {
      filteredRows.forEach((row) => {
        let newRow = row.cloneNode(true) as HTMLElement;
        newRow.querySelector('td:last-of-type')?.remove();
        newRow.querySelector('td:first-of-type')?.remove();
        table.append(newRow);
      });
    } else {
      table.append(
        (
          document.querySelector('dataTable .tableBody tbody') as HTMLElement
        ).cloneNode(true)
      );
      table.querySelectorAll('tbody tr td:last-of-type').forEach((element) => {
        element.remove();
      });
      table.querySelectorAll('tbody tr td:first-of-type').forEach((element) => {
        element.remove();
      });
    }
    /* pass here the table id */
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, 'proveedores.xlsx');
  }

  /**
   * API CALLS
   */

  getAllProveedores() {
    this.isLoading = true;
    this.companySE.getClientOrProveedor(this.filter);
    this.companySE
      .getResult()
      .pipe(takeUntil(this._unsubInd))
      .subscribe((res) => {
        if (!res) return;
        let { data, total } = res.data;
        this.dataForTable = data;
        this.dataForTableFiltered = this.dataForTable;
        this.totalPaginacion = total;
        setTimeout(() => {
          this._reloadTableWidth.next(true);
        }, 1);
        this.isLoading = false;
        this._unsubInd.next('');
      });
  }
  deleteProveedor(ids: number[]) {
    this.companySE.delete(ids);
    this.companySE
      .getResultDelete()
      .pipe(takeUntil(this._unsubInd3))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        StaticUtilitiesService.showFeedback(
          'Proveedor eliminado correctamente'
        );
        this.getAllProveedores();
        this._unsubInd3.next('');
      });
    this.companySE
      .getResultDeleteError()
      .pipe(takeUntil(this._unsub))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        StaticUtilitiesService.showError(res.message);
        this._unsub.next('');
      });
  }
}
