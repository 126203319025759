import { Component, OnInit } from '@angular/core';
import { iOptionsSelector } from '@quasar_dynamics/basic-designsystem';
import { StaticDataHandlerService } from 'src/app/Services/Utils/StaticDataHandler.service';

@Component({
  selector: 'app-CRM-Alertas',
  templateUrl: './CRM-Alertas.component.html',
  styleUrls: ['./CRM-Alertas.component.scss']
})
export class CRMAlertasComponent implements OnInit {
  filtro: any = null;
  opcionesFiltros: iOptionsSelector = {
    items: [],
    clearable: true,
    search: true,
    placeholder: "Seleccionar",
    append: true,
  }
  alertas: Array<any> = StaticDataHandlerService.getUltimosMovimientos();

  constructor() { }

  ngOnInit() {
  }

}
