import { iBaseObject } from "@quasar_dynamics/basic-designsystem";
import { iArticle } from "./iArticle";

export interface iGenericRate {
    id?: number,
    rateOne?: number,
    rateTwo?: number,
    contributionRateTwo?: number,
    factor?: number,
    article?: iArticle,
}

export class iGenericRate extends iBaseObject {
    static convertFromCollection(ob: any): Array<iGenericRate> {
        let iGenericRateCollection: Array<iGenericRate> = [];
        ob.forEach((element: any) => {
            iGenericRateCollection.push(this.convertFromObject(element));
        });
        return iGenericRateCollection;
    }

    static convertFromObject(ob: any): iGenericRate {
        let iGenericRateObj = new iGenericRate();
        iGenericRateObj.id = ob.id;
        iGenericRateObj.rateOne = ob.rateOne;
        iGenericRateObj.rateTwo = ob.rateTwo;
        iGenericRateObj.contributionRateTwo = ob.contributionRateTwo;
        iGenericRateObj.factor = ob.factor;
        iGenericRateObj.article = ob.article ? iArticle.convertFromObject(ob.article) : iArticle.createEmptyObject();
        return iGenericRateObj;
    }

    static createEmptyObject(): iGenericRate {
        let iGenericRateObj = new iGenericRate();
        iGenericRateObj.rateOne = -1;
        iGenericRateObj.rateTwo = -1;
        iGenericRateObj.contributionRateTwo = -1;
        iGenericRateObj.factor = -1;
        iGenericRateObj.article = new iArticle();
        return iGenericRateObj;
    }
}

export interface iGenericRateFilter {
    num_devoluciones: number;
    num_pagina: number;
    genericFilter?: string;
    rateOne?: number,
    rateTwo?: number,
    contributionRateTwo?: number,
    factor?: number,
    article?: number,
}