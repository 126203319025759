import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'DatosDeConsumo',
  templateUrl: './DatosDeConsumo.component.html',
  styleUrls: ['./DatosDeConsumo.component.scss']
})
export class DatosDeConsumoComponent implements OnInit {

  actualYear = [
    {label:'FACTURACIÓN :',value:' 1.000.000€'},
    {label:'FACT. MEDIA MENSUAL :',value:' 1.000.000€'},
  ]
  previousYears = [
    {anual:'FACTURACIÓN ANUAL:',value:'1.000.000€',mensual:'FACT. MEDIA MENSUAL :',valueMensual:'1.000.000€', year:'2021'},
    {anual:'FACTURACIÓN ANUAL:',value:'1.000.000€',mensual:'FACT. MEDIA MENSUAL :',valueMensual:'1.000.000€', year:'2020'},
    {anual:'FACTURACIÓN ANUAL:',value:'1.000.000€',mensual:'FACT. MEDIA MENSUAL :',valueMensual:'1.000.000€', year:'2019'},
  ]

  constructor() { }

  ngOnInit() {
  }

}
