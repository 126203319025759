<div class="fullAccordion">
  <div class="firstSection">

    <div class="whiteBox">
      <div class="icon" (click)="edit('informacionCliente')">
        <ng-container>
          <mat-icon class="outline">
            edit
          </mat-icon>
        </ng-container>
      </div>


      <div class="infoGrid">
        <p><span class="bold">Código : </span> 4939</p>
        <div class="lineaConBubble">
          <p><span class="bold">Teléfonos : </span></p> <span class="bordeado">95753300</span> <span
            class="bordeado">95753300</span> <span class="bordeado">95753300</span>
        </div>
        <p><span class="bold">NIF : </span> B14281471</p>
        <div class="lineaConBubble">
          <p><span class="bold">E-mail : </span></p> <span class="bordeado">ejemplo@gmail.com</span> <span
            class="bordeado">ejemplo@gmail.com</span>
        </div>
        <p><span class="bold">Dirección : </span> C/ Las Pozas, 6</p>
        <div class="lineaConBubble">
          <p><span class="bold">E-mail pedidos : </span></p> <span class="bordeado">ejemplo@gmail.com</span> <span
            class="bordeado">ejemplo@gmail.com</span>
        </div>
        <p><span class="bold">Municipio : </span> Rute</p>
        <p><span class="bold">URL Google Maps : </span> 39.462577668693676, -0.3796194249762021</p>
        <p><span class="bold">Cófigo Postal : </span> 14960</p>
        <br>
        <p><span class="bold">Provincia : </span> Córdoba</p>
        <br>
        <p><span class="bold">País : </span> España</p>
      </div>
    </div>

    <div class="whiteBox2">
      <div class="icon">
        <ng-container>
          <mat-icon class="outline">
            edit
          </mat-icon>
        </ng-container>
      </div>


      <div class="infoGrid">
        <p><span class="bold">Forma de Pago : </span> Pagaré 60 días fecha albarán</p>
        <p><span class="bold">Días fijos : </span> 15; 30</p>
        <p><span class="bold">IBAN : </span> ES66 2100 0418 4012 3456 7891</p>
        <p><span class="bold">BIC : </span> BBVAESMM654</p>
        <p><span class="bold">Física / Jurídica :</span> Jurídica</p>
        <p><span class="bold">Municipio : </span> Rute</p>
        <p><span class="bold">Identificador Único : </span> XXXXXX</p>
        <p><span class="bold">Cuenta contable : </span> XXXXXXX</p>
        <p><span class="bold">Tipo IVA : </span> No exento</p>
      </div>
    </div>






  </div>

  <div class="segundaSeccion">
    <div class="primerApartado">
      <pill class="minHeightPills">
        <ng-container>
          <p><span class="bold">Observaciones : </span> Siguiente lote de envío de verde es OE19007</p>
        </ng-container>
      </pill>
    </div>
    <div class="segundoApartado">
      <pill class="minHeightPillsIconos">
        <ng-container>
          <div class="iconsAndContent">
            <div class="icons">
              <ng-container>
                <mat-icon class="outline">
                  add
                </mat-icon>
              </ng-container>
              <ng-container>
                <mat-icon class="outline">
                  edit
                </mat-icon>
              </ng-container>
            </div>
            <p><span class="bold">Adjuntos : </span> </p>
          </div>
        </ng-container>
      </pill>
    </div>
  </div>
</div>