import { Component, OnInit } from '@angular/core';
import { iDataTableColumns, iDataTableOptions, iUnsubscribeDestroy, PopupService } from '@quasar_dynamics/basic-designsystem';
import { Subject, takeUntil } from 'rxjs';
import { ConfirmPopupComponent } from 'src/app/Popups/ConfirmPopup/ConfirmPopup.component';
import { NuevaAccionComercialPopupComponent } from 'src/app/Popups/NuevaAccionComercial-Popup/NuevaAccionComercial-Popup.component';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'crmAccionesComecialesLista',
  templateUrl: './CRM-AccionesComeciales-Lista.component.html',
  styleUrls: ['./CRM-AccionesComeciales-Lista.component.scss']
})
export class CRMAccionesComecialesListaComponent extends iUnsubscribeDestroy implements OnInit {
  _reloadTableWidth = new Subject()
  isLoading: boolean = false;

  //Tabla
  displayedColumnsTabla: Array<iDataTableColumns> = [
    { label: 'Cliente', property: 'cliente' },
    { label: 'Fecha últ. acción', property: 'fechaAccion', align: "center" },
    { label: 'Fecha prevista', property: 'fechaPrevista', align: "center" },
    { label: 'Tipo cliente', property: 'tipoCliente', align: "center" },
    { label: 'Tipo', property: 'tipo', align: "center"  },
    { label: 'Asignado a', property: 'asignado', align: "center"  },
    { label: 'Estado', property: 'estado', align: "center", classList:'tableEstado tableEstadoAcciones'   },

  ];

  dataForTable = [
    {id: 1, cliente: "4256 - INDUSMETAL TORRES, S.L.", fechaAccion: "16/06/2022", fechaPrevista: "16/09/2022", tipoCliente: "C. Potencial", tipo: "Muestras", asignado: "Vicente Pastor", estado: "Pendiente"},
    {id: 1, cliente: "4256 - INDUSMETAL TORRES, S.L.", fechaAccion: "16/06/2022", fechaPrevista: "16/09/2022", tipoCliente: "C. Potencial", tipo: "Muestras", asignado: "Vicente Pastor", estado: "Pendiente"},
    {id: 1, cliente: "4256 - INDUSMETAL TORRES, S.L.", fechaAccion: "16/06/2022", fechaPrevista: "16/09/2022", tipoCliente: "C. Potencial", tipo: "Muestras", asignado: "Vicente Pastor", estado: "Realizada"},
    {id: 1, cliente: "4256 - INDUSMETAL TORRES, S.L.", fechaAccion: "16/06/2022", fechaPrevista: "16/09/2022", tipoCliente: "C. Potencial", tipo: "Muestras", asignado: "Vicente Pastor", estado: "Pendiente"},
    {id: 1, cliente: "4256 - INDUSMETAL TORRES, S.L.", fechaAccion: "16/06/2022", fechaPrevista: "16/09/2022", tipoCliente: "C. Potencial", tipo: "Muestras", asignado: "Vicente Pastor", estado: "Descartada"},
    {id: 1, cliente: "4256 - INDUSMETAL TORRES, S.L.", fechaAccion: "16/06/2022", fechaPrevista: "16/09/2022", tipoCliente: "C. Potencial", tipo: "Muestras", asignado: "Vicente Pastor", estado: "Pendiente"},
    {id: 1, cliente: "4256 - INDUSMETAL TORRES, S.L.", fechaAccion: "16/06/2022", fechaPrevista: "16/09/2022", tipoCliente: "C. Potencial", tipo: "Muestras", asignado: "Vicente Pastor", estado: "Pendiente"},
    {id: 1, cliente: "4256 - INDUSMETAL TORRES, S.L.", fechaAccion: "16/06/2022", fechaPrevista: "16/09/2022", tipoCliente: "C. Potencial", tipo: "Muestras", asignado: "Vicente Pastor", estado: "Pendiente"},
    {id: 1, cliente: "4256 - INDUSMETAL TORRES, S.L.", fechaAccion: "16/06/2022", fechaPrevista: "16/09/2022", tipoCliente: "C. Potencial", tipo: "Muestras", asignado: "Vicente Pastor", estado: "Pendiente"},
    {id: 1, cliente: "4256 - INDUSMETAL TORRES, S.L.", fechaAccion: "16/06/2022", fechaPrevista: "16/09/2022", tipoCliente: "C. Potencial", tipo: "Muestras", asignado: "Vicente Pastor", estado: "Pendiente"}
  ]

  optionsTabla: iDataTableOptions = {
    isSelectable: true,
    isHovered: true,
    canEdit: true,
    canDelete: true,
    canSee: true,
    minHeight: 350,
    maxHeight: 'calc(100vh - 78px - 4em - 106px - 47px - 4em)',
    maxHeightType: 'calc',
    moreIconsHead: [
      {
        icon: 'download',
        callback(element) {

        },
      }
    ]

  }
  constructor(private popupSE: PopupService) {
    super();
  }

  ngOnInit() {
  }
 /**
   * FUNCTIONALITIES
   */
 edit(event: any) {
  this.popupSE.openPopup(NuevaAccionComercialPopupComponent, { data: event, accion: "editar" });
  this.popupSE.returnData().pipe(takeUntil(this._unsubInd)).subscribe((data) => {
    StaticUtilitiesService.showFeedback("Se ha editado la accion comercial correctamente")
    this._unsubInd.next("")
  })
}

see(event: any) {
  this.popupSE.openPopup(NuevaAccionComercialPopupComponent, { data: event, accion: "ver" });

}
delete(event: any, type: String) {
  switch (type) {
    case "single":
      this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'la accion comercial' });
      this.popupSE.returnData().pipe(takeUntil(this._unsubInd)).subscribe((data) => {
        StaticUtilitiesService.showFeedback("Se ha eliminado la accion comercial correctamente")
        this._unsubInd.next("")
      })
      break;
    case "selected":
      this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'las acciones comerciales seleccionadas' });
      this.popupSE.returnData().pipe(takeUntil(this._unsubInd)).subscribe((data) => {
        StaticUtilitiesService.showFeedback("Se han eliminado las acciones comerciales correctamente")
        this._unsubInd.next("")
      })
      break;
    case "all":
      this.popupSE.openPopup(ConfirmPopupComponent, { accion: 'Eliminar', elemento: 'todas las acciones comerciales' });
      this.popupSE.returnData().pipe(takeUntil(this._unsubInd)).subscribe((data) => {
        StaticUtilitiesService.showFeedback("Se han eliminado las acciones comerciales correctamente")
        this._unsubInd.next("")
      })
      break;
  }
}
}
