import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { iOptionsSelector } from '@quasar_dynamics/basic-designsystem';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'app-NuevaAccionComercial-Popup',
  templateUrl: './NuevaAccionComercial-Popup.component.html',
  styleUrls: ['./NuevaAccionComercial-Popup.component.scss']
})
export class NuevaAccionComercialPopupComponent implements OnInit {

  data: any = {
    cliente: null,
    tipoCliente: null,
    ultimaAccion: null,
    fechaPrevista: null,
    tipoAccion: null,
    asignado: null,
    estado: null,
    notas: ""
  }
  estados: Array<any> = [{id: 1, estado: "Pendiente"}, {id: 2, estado: "Realizada"}, {id: 3, estado: "Descartada"}]
  opcionesCliente: iOptionsSelector = {
    items: ["Cliente test"],
    search: true,
    placeholder: "Cliente",
    append: true,
  }
  opcionesTipoCliente: iOptionsSelector = {
    items: ["Cliente potencial", "Contacto", "Cliente"],
    search: true,
    placeholder: "Tipo de cliente",
    append: true,
  }
  opcionesTipoAccion: iOptionsSelector = {
    items: ["Visita", "Oferta", "LLamada"],
    search: true,
    placeholder: "Tipo de acción",
    append: true,
  }
  opcionesAsignado: iOptionsSelector = {
    items: ["Antonio", "Héctor", "Marcos", "Fernando", "Jaime", "Pedro"],
    search: true,
    placeholder: "Asignar",
    append: true,
  }

  isCompleted: boolean = false;


  constructor(public dialogRef: MatDialogRef<NuevaAccionComercialPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any) { }

  ngOnInit() {}

  /**
  * FUNCTIONALITY
  */
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }

/**
 * VALIDATIONS
 */
checkIsCompleted(){
  StaticUtilitiesService.isCompleteObject(this.data) ? this.isCompleted = true : this.isCompleted = false;
}
}
