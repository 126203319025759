<FullContainer>
  <div class="fullView">
    <div class="headerAndArchivar">
      <div class="header">
        <button class="goBack" (click)="goBack()">
          <ng-container>
            <mat-icon>
              arrow_back
            </mat-icon>
          </ng-container>
        </button>
        <p>DOCUMENTOS / <span>{{nombreCarpeta}}</span></p>
      </div>

    </div>
    <filterActions (addNew)="create()">
      <ng-container filterContent>
        <div class="containerSelectores">
          <div>
            <selector></selector>
          </div>
          <div>
            <datepicker [clearable]="true"></datepicker>
          </div>
          <div>
            <datepicker [clearable]="true"></datepicker>
          </div>
          <div>
            <selector></selector>
          </div>
        </div>
      </ng-container>
    </filterActions>
    <pill class="tablePil" classList="tablePill">
      <dataTable [data]="dataForTable" [forceUpdateWidth]="_reloadTableWidth" [isLoading]="isLoading" (clickRow)="see($event)" [options]="optionsTabla" [displayedColumns]="displayedColumnsTabla" (edit)="edit($event)"(delete)="delete($event,'single')" (see)="see($event)" (deleteAll)="delete($event,'all')" (deleteSelected)="delete($event,'selected')"></dataTable>
    </pill>

  </div>
</FullContainer>
