<div class="polvo">
  <filterActions (addNew)="create()"  (searchSend)="search($event)">
    <ng-container filterContent>
      <div class="containerSelectores">
        <div>
          <selector [options]="opcionesClientes" [(ngModel)]="clientes" (change)="getSelector($event, 'cliente')"></selector>
        </div>
        <div>
          <selector [options]="opcionesProducto" [(ngModel)]="productos" (change)="getSelector($event, 'producto')"></selector>
        </div>
      </div>
    </ng-container>
  </filterActions>
  <Pagination [paginacionTotal]="totalPaginacion" (change)="changePagination($event)"></Pagination>
  <pill class="tablePil" classList="tablePill">
    <dataTable [data]="dataForTableFiltered" [forceUpdateWidth]="_reloadTableWidth" [isLoading]="isLoading"
      [options]="optionsTabla" [displayedColumns]="displayedColumnsTabla" (edit)="edit($event)"
      (delete)="delete($event,'single')" (deleteAll)="delete($event,'all')"
      (deleteSelected)="delete($event,'selected')"></dataTable>
  </pill>
</div>
