import { Component, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { iUnsubscribeDestroy, PopupService } from '@quasar_dynamics/basic-designsystem';
import { ElementRef } from '@angular/core';
import { StaticDataHandlerService } from 'src/app/Services/Utils/StaticDataHandler.service';
import { NuevoClientePopupComponent } from 'src/app/Popups/NuevoCliente-Popup/NuevoCliente-Popup.component';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'ERP-Cliente-Detail-Ficha',
  templateUrl: './ERP-Cliente-Detail-Ficha.component.html',
  styleUrls: ['./ERP-Cliente-Detail-Ficha.component.scss']
})
export class ERPClienteDetailFichaComponent extends iUnsubscribeDestroy implements OnInit {
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.resize();
  }
  @Input() data:any = null 
  @Output() refresh = new EventEmitter<boolean>();

  documentos: Array<any> = StaticDataHandlerService.getDocumentos();
  agentes:any = []
  constructor(private popup: PopupService) {
    super();
  }

  ngAfterViewInit(): void {
    this.resize();
  }

  ngOnInit() {
  }

  ngOnChanges(changes:SimpleChanges){
    if(changes['data']){
      changes['data'].currentValue ? this.data = changes['data'].currentValue : this.data = []
      this.agentes = this.data.agents
    }
  }

  /**
   * edit
   */

  edit(section) {
    this.popup.openPopup(NuevoClientePopupComponent, { data: this.data, accion: 'editar', });
    this.popup.returnData().pipe(takeUntil(this._unsubInd)).subscribe(res => {
      if (!res) { return };
      this.refresh.emit(true);
      this._unsubInd.next('');
    })
  }

  /**
   * FUNCTIONALITIES
   */
  resize() {
    setTimeout(() => {
      (document.querySelector("pill:nth-of-type(2)") as HTMLElement).style.height = ((document.querySelector("pill:first-of-type") as HTMLElement).clientHeight + (document.querySelector("pill:first-of-type") as HTMLElement).style.padding) + "px"
    }, 1000);
  }
}
