import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'searcherCustom',
  templateUrl: './Searcher.component.html',
  styleUrls: ['./Searcher.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearcherComponent),  // replace name as appropriate
      multi: true
    }
  ]
})
export class SearcherComponent implements OnInit {

  @Input() placeHolder: string = ""
  @Output() searchSend = new EventEmitter();

  constructor() { }


  ngOnInit() {
  }

  //INTERNAL VALUES

  private _onChange = (_: any) => { };
  private _onTouched = () => { };
  private _oldData;

  _disabled: boolean = false;
  model;

  writeValue(obj: any): void {
    this.model = obj;
  }

  setDisabledState?(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  onNgModelChange(event) {
    this._oldData = this.model;
    this.model = event;
  }

  onModelChange($event) {
    this.model=$event;
    this._onChange(this.model);
  }

  sendInputVal(){
    this.searchSend.emit(this.model);
  }
}
