import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'app-SubirDocumento-Popup',
  templateUrl: './SubirDocumento-Popup.component.html',
  styleUrls: ['./SubirDocumento-Popup.component.scss']
})
export class SubirDocumentoPopupComponent implements OnInit {

  isCompleted: boolean = false;
  document: any = null;
  names: string[] = [];
  constructor(public dialogRef: MatDialogRef<SubirDocumentoPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any) { }

  ngOnInit() {

  }

  /**
  * FUNCTIONALITY
  */
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }

  /**
   * GETTERS & SETTERS
   */
  getDocument(){
    if (!this.modalData.formatos.includes(this.document[0].file.type)) {
      StaticUtilitiesService.showError("Formato de documento no permitido")
      this.document = null;
      return;
    }
    if (this.document[0].file.size > this.modalData.size) {
      StaticUtilitiesService.showError("Archivo demasiado pesado");
      this.document = null;
      return;
    }
    this.isCompleted = true;
    this.names = this.document.map((x: any) => x.file.name);
    
  }
}
