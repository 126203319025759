<Pagination [paginacionTotal]="totalPaginacion" (change)="changePagination($event)"></Pagination>
<pill class="tablePil" classList="tablePill">
  <dataTable [forceUpdateWidth]="forceUpdateWidth" [data]="dataForTable" [isLoading]="isLoading" [options]="optionsTabla" [displayedColumns]="displayedColumnsTabla" (edit)="edit($event)" (delete)="delete($event,'simple')" (deleteAll)="delete(null,'all')" (deleteSelected)="delete($event,'selected')" (clickRow)="see($event)" (see)="see($event)"></dataTable>

  <div class="cuenta">
    <div class="row">
      <br>
      <p class="bold">Base Imponible</p>
      <p class="bold">IVA</p>
      <p class="bold">Recargo</p>
    </div>
    <div class="row">
      
      <p class="bold">TOTAL :</p>
      <p class="bold">{{total.baseImponible | currency: 'EUR'}}</p>
      <p class="bold">{{total.iva | currency: 'EUR'}}</p>
      <p class="bold">{{total.recargo | currency: 'EUR'}}</p>

    </div>
    <btn classList="transformarEnCAntiguo" class="absolute" (click)="retablesButton($event)">Generar remesas</btn>
  </div>

</pill>
