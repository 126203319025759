import { Component, OnInit } from '@angular/core';
import { iUnsubscribeDestroy, PopupService } from '@quasar_dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { NuevaAccionComercialPopupComponent } from 'src/app/Popups/NuevaAccionComercial-Popup/NuevaAccionComercial-Popup.component';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'CRM-AccionesComerciales',
  templateUrl: './CRM-AccionesComerciales.component.html',
  styleUrls: ['./CRM-AccionesComerciales.component.scss']
})
export class CRMAccionesComercialesComponent extends iUnsubscribeDestroy implements OnInit {
  selected = 0;

  selectores = [
    { label: 'Lista', icon: 'none', image: 'none', selected: 0, before: 'yes', color: 'verde' },
    { label: 'Calendario', icon: 'none', image: 'none', selected: 1, before: 'yes', color: 'verde' },
  ]


  constructor(private popupSE: PopupService) {
    super();
  }

  ngOnInit() {
  }
  create() {
    this.popupSE.openPopup(NuevaAccionComercialPopupComponent, { data: null, accion: "crear" });
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd)).subscribe((data) => {
      StaticUtilitiesService.showFeedback("Se ha creado la accion comercial correctamente")
      this._unsubInd.next("")
    })
  }
}
