import { iBaseObject, iDate } from "@quasar_dynamics/basic-designsystem";
import { iCompany } from "./iCompany";
import { iAddress } from "./iAddress";
import { iPorte } from "./iPorte";
import { iBill } from "./iBill";

export interface iOrder {
    id?: number,
    date?: iDate,
    payDate?: iDate,
    state?: string,
    total?: number,
    note?: string,
    attacheds?: [],
    company?: iCompany,
    address?: iAddress,
    porte?: iPorte,
    bill?: iBill,
}

export class iOrder extends iBaseObject {
    static convertFromCollection(ob: any): Array<iOrder> {
        let iOrderCollection: Array<iOrder> = [];
        ob.forEach((element: any) => {
            iOrderCollection.push(this.convertFromObject(element));
        });
        return iOrderCollection;
    }

    static convertFromObject(ob: any): iOrder {
        let iOrderObj = new iOrder();
        iOrderObj.id = ob.id;
        iOrderObj.date = iDate.phpConvert(ob.date);
        iOrderObj.payDate = iDate.phpConvert(ob.payDate);
        iOrderObj.state = ob.state;
        iOrderObj.total = ob.total;
        iOrderObj.note = ob.note;
        iOrderObj.attacheds = ob.attacheds;
        iOrderObj.company = ob.company ? iCompany.convertFromObject(ob.company) : iCompany.createEmptyObject();
        iOrderObj.address = ob.address ? iAddress.convertFromObject(ob.address) : iAddress.createEmptyObject();
        iOrderObj.porte = ob.porte ? iPorte.convertFromObject(ob.porte) : iPorte.createEmptyObject();
        iOrderObj.bill = ob.bill ? iBill.convertFromObject(ob.bill) : iBill.createEmptyObject();
        return iOrderObj;
    }

    static createEmptyObject(): iOrder {
        let iOrderObj = new iOrder();
        iOrderObj.date = iDate.javascriptConvert(new Date());
        iOrderObj.payDate = iDate.javascriptConvert(new Date());
        iOrderObj.state = '';
        iOrderObj.total = -1;
        iOrderObj.note = '';
        iOrderObj.attacheds = [];
        iOrderObj.company = iCompany.createEmptyObject();
        iOrderObj.address = iAddress.createEmptyObject();
        iOrderObj.porte = iPorte.createEmptyObject();
        iOrderObj.bill = new iBill();
        return iOrderObj;
    }
}

export interface iOrderFilter {
    num_devoluciones: number;
    num_pagina: number;
    genericFilter?: string;
    date?: string,
    payDate?: string,
    state?: string,
    total?: number,
    note?: string,
    attacheds?: number,
    company?: number,
    address?: number,
    porte?: number,
    isClient?: boolean,
    bill?: number,
}