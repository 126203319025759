import { Component, OnInit } from '@angular/core';
import { slideNavigationTypes } from '@quasar_dynamics/basic-designsystem';
import { StaticDataHandlerService } from 'src/app/Services/Utils/StaticDataHandler.service';
import { StaticUtilitiesService } from 'src/app/Services/Utils/StaticUtilities.service';

@Component({
  selector: 'app-ERP-Mantenimientos-Detalles',
  templateUrl: './ERP-Mantenimientos-Detalles.component.html',
  styleUrls: ['./ERP-Mantenimientos-Detalles.component.scss']
})
export class ERPMantenimientosDetallesComponent implements OnInit {

  idMantenimiento: number | null = null;
  nombreMantenimiento: string = localStorage.getItem("mantenimiento")!
  isLoading: boolean = false;
  constructor(private staticUtilities: StaticUtilitiesService) { }

  ngOnInit() {
    if (this.staticUtilities.getParams().mantenimiento == undefined) {
      this.staticUtilities.goTo("/erp/mantenimientos", slideNavigationTypes.slideToBottom);
      StaticUtilitiesService.showError("No se ha encontrado el mantenimiento");
    }else{
      this.idMantenimiento = Number(this.staticUtilities.getParams().mantenimiento);
      if (this.idMantenimiento < 1 || this.idMantenimiento > 10) {
        this.staticUtilities.goTo("/erp/mantenimientos", slideNavigationTypes.slideToBottom);
        StaticUtilitiesService.showError("No se ha encontrado el mantenimiento");
      }else{
        this.isLoading = true;
        setTimeout(() => {
          this.isLoading = false;
        }, 1500);
      }

    }
  }

  /**
   * FUNCTIONALITIES
   */
  returnBack(){
    localStorage.removeItem("mantenimiento")
    this.staticUtilities.goTo("/erp/mantenimientos", slideNavigationTypes.slideToBottom)
  }

}
